<template>
    <Head title="Create Rover Credential" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('vendor-portal-credentials.index')" class="breadcrumb-link">Rover
                Credentials
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Create</span>
        </nav>
    </Teleport>

    <form id="create-vendor-portal-credential-form" class="space-y-8" @submit.prevent="submitForm">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Credential Info</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Login/Password</p>
                </div>
            </legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-6">
                <search-input
                    class="col-span-3 md:col-span-2 lg:col-span-1"
                    @selected="vendorPortalSelected"
                    v-model="form.vendor_portal_id"
                    display-property="vendor_name"
                    label="Rover"
                    route="json.vendor-portals.index"
                    value-property="id"
                    placeholder="Search for a Rover"
                    :errors="errors.vendor_portal_id" mark-as-required>
                </search-input>

                <text-input v-model="form.username" class="col-span-3 md:col-span-2 lg:col-span-1" :label="loginLabels.username" :errors="errors.username" mark-as-required />
                <text-input type="password" class="col-span-3 md:col-span-2 lg:col-span-1" v-model="form.password" :label="loginLabels.password" :errors="errors.password" mark-as-required />

	            <div v-if="! isGatherTrial" class="col-span-4 flex flex-reverse">
                    <toggle-switch-input v-model="form.fetch_latest_invoice_when_subscribing_account" label="Fetch Latest Invoice When Subscribing Accounts?" />
	            </div>
            </div>

            <template v-if="authenticationRequirements.securityQuestions">
                <div class="block relative">
                    <div class="col-start-1 col-span-full">
                        This portal uses Security Questions. Please provide all of your security questions/answers to ensure we can pull your invoice data.
                    </div>

                    <div class="block my-4">
                        <select-input class="w-1/3" @change="securityQuestionSelected" v-model="selectedSecurityQuestion" label="Security Questions" :errors="errors.securityQuestions" mark-as-required>
                            <option :value="null">Select a security question</option>
                            <option v-for="(securityQuestion,index) in sortedAvailableSecurityQuestions" :value="securityQuestion" :key="index">{{ securityQuestion }}</option>
                        </select-input>
                    </div>

                    <div class="block mb-2" v-for="securityQuestion in selectedSecurityQuestions">
                        <div class="flex">

                            <text-input type="password" class="w-1/3"  :label="securityQuestion" v-model="form.securityQuestions[securityQuestion]"  :errors="errors.securityQuestions" mark-as-required/>
                            <button type="button" class="btn btn-red py-0 h-10 self-end ml-3" @click="removeSelectedSecurityQuestion(securityQuestion)">
                                <span>Delete</span>
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </fieldset>

        <div class="w-full flex flex-wrap">
            <loading-button :loading="state === 'saving'" class="btn btn-orange" @click="submitForm">Save</loading-button>
        </div>
    </form>
</template>

<script setup>
    import { ref, inject, onMounted, computed } from 'vue';
    import {router, usePage} from '@inertiajs/vue3';
    import axios from "axios";

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import ToggleSwitchInput from "@/Shared/ToggleSwitchInput.vue";
    import SearchInput from "../../Shared/SearchInput.vue";

    // Injects
    const route = inject('route');
    const toast = inject('toast');
    const page = usePage();

    // Props
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        }
    })

    const isGatherTrial = page.props.tenant_billing_type === 'trial';

    // State
    const form = ref({
        vendor_portal_id: null,
        fetch_latest_invoice_when_subscribing_account: isGatherTrial,
        securityQuestions: {}
    });
    const authenticationRequirements = ref({});
    const availableSecurityQuestions = ref([]);
    const selectedSecurityQuestions = ref([]);
    const selectedSecurityQuestion = ref(null);
    const state = ref('passive');
    const mounted = ref(false);
    const loginLabels = ref({
        username: 'Username',
        password: 'Password',
    })

    onMounted(() => {
        mounted.value = true;
    })

    function submitForm() {
        if (state.value === 'passive') {
            state.value = 'saving';

            router.post(route('vendor-portal-credentials.store'), form.value, {
                onFinish: () => state.value = 'passive'
            });
        }
    }

    function vendorPortalSelected() {
        axios.get(route('json.vendor-portals.show', form.value.vendor_portal_id)).then(response => {
            authenticationRequirements.value = response.data.authRequirements;
            availableSecurityQuestions.value = response.data.authRequirements?.securityQuestions;
            loginLabels.value = response.data.loginLabels;
        })
    }

    function securityQuestionSelected() {
        if (selectedSecurityQuestions.value.includes(selectedSecurityQuestion.value)) {
            return;
        }

        selectedSecurityQuestions.value.push(selectedSecurityQuestion.value);
        availableSecurityQuestions.value.splice(availableSecurityQuestions.value.indexOf(selectedSecurityQuestion.value), 1);
    }

    function removeSelectedSecurityQuestion(selectedQuestion) {
        selectedSecurityQuestions.value.splice(selectedSecurityQuestions.value.indexOf(selectedQuestion), 1);
        availableSecurityQuestions.value.push(selectedQuestion);
        selectedSecurityQuestion.value = null;
    }

   const sortedAvailableSecurityQuestions = computed( () => {
       let securityQuestions = authenticationRequirements.value?.securityQuestions ?? [];

       return securityQuestions.toSorted((a, b) => a.localeCompare(b));
   });
</script>