<template>
    <Head :title="`Client Account - ${clientAccount.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-accounts.index')" class="breadcrumb-link">Client Accounts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ clientAccount.name }}</span>
        </nav>
    </Teleport>

    <!-- Page header -->
    <div
        class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ clientAccount.name }}
                    <span v-if="clientAccount.external_reference_id" class="text-lg font-semi-bold text-orange-300">{{ clientAccount.external_reference_id ?? 'EXT-123ABC' }}</span>
                </h1>

                <p class="text-sm font-medium text-gray-500">
                    <span class="text-gray-900"> Created</span> <time :datetime="clientAccount.created_at">{{ $filters.format_date_time(clientAccount.created_at) }}</time>
                </p>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <inertia-link :href="$route('client-accounts.edit', [clientAccount.id])" class="btn btn-gray">Edit</inertia-link>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Client Account details and records.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Client Company</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link class="link" :href="$route('client-companies.show', [clientAccount.client_company_id])">{{ clientAccount.clientCompany.name }}</inertia-link>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Account #</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <copy-to-clipboard-button :value="clientAccount.account_number">{{ clientAccount.account_number }}</copy-to-clipboard-button>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Primary Contact/Recipient</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientAccount.primary_contact_name }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Billing Address</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientAccount.billing_address }}</dd>
                            </div>

                            <div v-if="clientAccount.primary_telephone_number" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Primary Phone</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_phone(clientAccount.primary_telephone_number) }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Email address</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientAccount.primary_email_address }}</dd>
                            </div>

                            <div v-if="clientAccount.purchase_order" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Purchase Order</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientAccount.purchase_order }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Default Payment Method</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientAccount.default_payment_method }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Payment Terms</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientAccount.payment_terms }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>

            <!-- Billing Details -->
            <section aria-labelledby="billing-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="billing-information-title" class="text-lg leading-6 font-medium text-gray-900">Client Invoice Settings</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Settings that control how client invoices will be billed.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Invoice Template View</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientAccount.invoice_template_view }}</dd>
                            </div>

                            <div v-if="clientAccount.other_invoice_recipients" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Other Recipients</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientAccount.other_invoice_recipients }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Display Account Name in place of company on Invoice</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientAccount.show_account_name_in_invoice  ? 'Yes' : 'No' }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Display Service Location Names on Invoice</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientAccount.show_service_location_names_in_invoice  ? 'Yes' : 'No' }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Single Invoice Per Period</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientAccount.single_invoice_per_period  ? 'Yes' : 'No' }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Exempt From Finance Charge</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientAccount.exempt_from_finance_charge  ? 'Yes' : 'No' }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Bill Unaudited Vendor Invoice Line Items</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientAccount.use_initial_line_items_for_client_billing  ? 'Yes' : 'No' }}</dd>
                            </div>

                            <div class="sm:col-span-2">
                                <dt class="text-sm font-medium text-gray-500">Remittance Text</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <p>{{ clientAccount.invoice_remittance_text ?? 'N/A' }}</p>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Invoices Due Upon Receipt</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientAccount.invoices_due_upon_receipt  ? 'Yes' : 'No' }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <!-- Client Account Fees -->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="client-account-fees-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="client-account-fees-title" class="text-lg leading-6 font-medium text-gray-900">Client Account Fees</h2>
                        </div>

                        <div v-if="clientAccountFees.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('client-accounts.client-account-fees.create', [clientAccount.id])" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="clientAccountFees.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Id</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Billing Style</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Header Label</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Label</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Start Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">End Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Frequency</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Amount</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="fee in clientAccountFees" :key="fee.id">
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-500">{{ fee.id }}</td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium">{{ fee.billing_style }}</td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium">{{ fee.header_label }}</td>
                                                <td class="">{{ fee.label }}</td>
                                                <td class="">{{ $filters.format_date(fee.start_date) }}</td>
                                                <td class="">{{ $filters.format_date(fee.end_date) }}</td>
                                                <td class="">{{ fee.frequency }}</td>
                                                <td class="">{{ fee.billing_style === 'Fixed' ? $filters.format_money(fee.amount) : 'N/A' }}</td>
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('client-account-fees.edit', [fee.id])" class="link">
                                                        Edit<span class="sr-only">, {{ fee.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="file-signature" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Fees</h3>
                            <p class="mt-1 text-sm text-gray-500">Create a new fee.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('client-accounts.client-account-fees.create', [clientAccount.id])" class="btn btn-orange">
                                    <!-- Heroicon name: solid/plus -->
                                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                    </svg>
                                    New Fee
                                </inertia-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- Client Invoices !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="client-invoices-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="client-invoices-title" class="text-lg leading-6 font-medium text-gray-900">Recent Client Invoices</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Recent Client Invoices for this account.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="clientInvoices.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">Invoice #</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Invoice Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Due Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Billed</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Adjustments</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Paid</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Due</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Status</th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="invoice in clientInvoices.slice(0, 12)" :key="invoice.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ invoice.invoice_number }}</td>
                                                <td>{{ $filters.format_date(invoice.invoice_date) }}</td>
                                                <td>{{ $filters.format_date(invoice.due_at) }}</td>
                                                <td>{{ $filters.format_money(invoice.total_amount_billed) }}</td>
                                                <td>
                                                    {{ $filters.format_money(add_money(invoice.total_amount_credit_memo, invoice.total_amount_write_off)) }}
                                                </td>
                                                <td>{{ $filters.format_money(invoice.total_amount_paid) }}</td>
                                                <td>{{ $filters.format_money(invoice.total_amount_due) }}</td>
                                                <td>{{ invoice.status }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="file-invoice" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Client Invoices</h3>
                            <p class="mt-1 text-sm text-gray-500">Discovery hasn't generate any client invoices for this service yet.</p>
                        </div>
                    </div>

                    <div v-if="clientInvoices.length">
                        <inertia-link :href="$route('client-invoices.index', {search: clientAccount.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Client Credit Memos !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section v-if="clientCreditMemos.length" aria-labelledby="client-invoices-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="client-invoices-title" class="text-lg leading-6 font-medium text-gray-900">Recent Client Credit Memos</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Recent Client Credit Memos for this billing group.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">Document #</th>
                                                <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">Document Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Total Amount</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Applied Amount</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Status</th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="creditMemo in clientCreditMemos.slice(0, 12)" :key="creditMemo.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">{{ creditMemo.document_number }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">{{ $filters.format_date(creditMemo.document_date) }}</td>
                                                <td class="text-gray-500">{{ $filters.format_money(creditMemo.total_amount) }}</td>
                                                <td class="text-gray-500">{{ $filters.format_money(creditMemo.applied_amount) }}</td>
                                                <td class="text-gray-500">{{ creditMemo.status }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="clientCreditMemos.length">
                        <inertia-link :href="$route('client-credit-memos.index', {search: clientAccount.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Locations !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="locations-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="locations-title" class="text-lg leading-6 font-medium text-gray-900">Locations</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Locations billed under this account.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="locations.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Name</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Address</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="location in locations.slice(0, 12)" :key="location.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                                    <inertia-link class="link" :href="$route('locations.show', [location.id])">{{ location.name }}</inertia-link>
                                                </td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">{{ location.address.full_address }}</td>

                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('locations.show', [location.id])" class="link">
                                                        View<span class="sr-only">, {{ location.id }}</span>
                                                    </inertia-link>

                                                    <inertia-link :href="$route('locations.edit', [location.id])" class="link ml-3">
                                                        Edit<span class="sr-only">, {{ location.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="map-marker-alt" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No locations</h3>
                            <p class="mt-1 text-sm text-gray-500">Create a location.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('locations.create')" class="btn btn-orange">
                                    <!-- Heroicon name: solid/plus -->
                                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                    </svg>
                                    New Location
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="locations.length">
                        <inertia-link :href="$route('locations.index', {search: clientAccount.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import {add_money} from "@/Shared/Utils/Money.js";
    import CopyToClipboardButton from '@/Shared/CopyToClipboardButton.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            CopyToClipboardButton,
            Head,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({

                })
            },

            clientAccount: {
                type: Object,
                required: true
            },

            clientAccountFees: {
                type: Object,
                required: true
            },

            locations: {
                type: Array,
                required: true
            },

            clientCreditMemos: {
                type: Array,
                required: true
            },

            clientInvoices: {
                type: Array,
                required: true
            },
        },

        data() {
            return {
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            add_money
        }
    }
</script>
