<template>

    <main>
        <slot />
    </main>

</template>

<script setup>
    import { inject, watch } from "vue";
    import { usePage } from "@inertiajs/vue3";

    const toast = inject('toast');
    const page = usePage();

    watch(() => page.props.success_message,
        (newValue, oldValue) => {
            if (newValue) {
                toast.success(newValue);
            }
        },
        { deep: true }
    );

    watch(() => page.props.error_message,
        (newValue, oldValue) => {
            if (newValue) {
                toast.error(newValue);
            }
        },
        { deep: true }
    );
</script>