<template>
    <Head :title="`Edit Location Fee - ${props.location.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">
                Home
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="route('locations.index')" class="breadcrumb-link">
                Locations
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="route('locations.show', [props.location.id])" class="breadcrumb-link">
                {{ props.location.name }}
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit Fee</span>
        </nav>
    </Teleport>

    <div class="flex flex-wrap flex-row-reverse">
        <inertia-link href="" class="btn btn-gray" @click.prevent="remove">Remove</inertia-link>
    </div>

    <form @submit.prevent="submitForm" id="edit-client-service-fee-form" class="space-y-8">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Info</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Basic settings and info for this fee.</p>
                </div>
            </legend>

            <div class="grid grid-cols-2 gap-y-6 gap-x-4">
                <select-input
                    class="col-span-2 md:col-span-1"
                    v-model="form.line_item_category_id"
                    label="Line Item Category"
                    :errors="errors.line_item_category_id"
                    :mark-as-required="true"
                >
                    <option v-for="category in lineItemCategories" :key="category.id" :value="category.id">
                        {{ category.display_name }}
                    </option>
                </select-input>

                <select-input
                    v-model="form.billing_style"
                    label="Billing Style"
                    :mark-as-required="true"
                    :errors="errors.billing_style"
                >
                    <option v-for="billingStyle in billingStyles" :key="billingStyle" :value="billingStyle">
                        {{ billingStyle }}
                    </option>
                </select-input>

                <money-input
                    v-if="form.billing_style === 'Fixed'"
                    :defaultCurrency="location.clientCompany.currency"
                    v-model="form.amount"
                    :mark-as-required="true"
                    label="Amount"
                    :min="null"
                    :errors="errors.amount"
                />
                <text-input
                    v-else
                    v-model="form.formula"
                    :mark-as-required="true"
                    label="Formula"
                    :errors="errors.formula"
                />

                <text-input
                    class="col-span-2 md:col-span-1"
                    label="Header Label"
                    placeholder="Header Label"
                    :mark-as-required="true"
                    v-model="form.header_label"
                    :errors="errors.header_label"
                />

                <text-input
                    class="col-span-2 md:col-span-1"
                    label="Label"
                    :mark-as-required="true"
                    placeholder="Label"
                    v-model="form.label"
                    :errors="errors.label"
                />

                <select-input
                    class="col-span-2 md:col-span-1"
                    v-model="form.frequency"
                    label="Frequency"
                    :mark-as-required="true"
                    :errors="errors.frequency"
                >
                    <option v-for="billingFrequency in frequencies" :key="billingFrequency" :value="billingFrequency">
                        {{ billingFrequency }}
                    </option>
                </select-input>

                <text-input
                    class="col-span-2 md:col-span-1"
                    v-model="form.external_reference_id"
                    label="External Reference Id"
                    :errors="errors.external_reference_id"
                />

                <date-input
                    class="col-span-2 md:col-span-1"
                    v-model="form.start_date"
                    label="First Month Billed"
                    :errors="errors.start_date"
                />

                <div v-if="form.frequency !== 'One Time'" class="col-span-2 md:col-span-1">
                    <date-input v-model="form.end_date" label="End Date" :errors="errors.end_date" />
                    <span class="mt-3 block text-blue-600">{{ frequencyMessage }}</span>
                </div>

                <textarea-input
                    class="col-span-2"
                    label="Internal Notes (not seen by client)"
                    v-model="form.internal_notes"
                    :errors="errors.internal_notes"
                />
            </div>
        </fieldset>

        <div class="flex flex-wrap">
            <inertia-link :href="route('locations.show', [props.locationFee.location_id])" class="btn btn-gray mr-3">
                Cancel
            </inertia-link>

            <loading-button :loading="state.value === 'saving'" class="btn btn-orange">
                Save
            </loading-button>
        </div>
    </form>
</template>

<script setup>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import { differenceInCalendarMonths, parse, differenceInYears } from 'date-fns'
    import { Head, router } from '@inertiajs/vue3';
    import { reactive, ref, inject, watch, computed, onMounted } from 'vue';

    /**
     * Props
     */
    const props = defineProps({
        location: {
            type: Object,
            required: true
        },

        locationFee: {
            type: Object,
            required: true
        },

        billingFrequencies: {
            type: Array,
            required: true
        },

        scheduledFrequencies: {
            type: Array,
            required: true
        },

        billingStyles: {
            type: Array,
            required: true
        },

        lineItemCategories: {
            type: Object,
            required: true
        },

        errors: {
            type: Object,
            default: () => ({})
        }
    });

    /**
     * State
     */
    const route = inject('route');
    const form = reactive({...props.locationFee});
    const mounted = ref(false);
    const state = ref('passive');

    /**
     * Mounted
     */
    onMounted(() => {
        mounted.value = true;
    });

    /**
     * Methods
     */
    function submitForm() {
        if (state.value === 'passive') {
            state.value = 'saving';

            if (form.billing_style !== 'Fixed') {
                form.amount = null;
            }

            if (form.billing_style !== 'Formula') {
                form.formula = null;
            }

            router.put(route('location-fees.update', props.locationFee.id), form, {
                onFinish: () => state.value = 'passive',
            });
        }
    }

    function remove() {
        if (state.value === 'passive') {
            let confirmed = confirm("Are you sure you wish to perform this action?");

            if (confirmed) {
                state.value = 'deleting';

                router.delete(route('location-fees.destroy', props.locationFee.id), null, {
                    onFinish: () => state.value = 'passive',
                });
            }
        }
    }

    /**
     * Watchers
     */
    watch(() => form.line_item_category_id, (newValue) => {
            let filtered = props.lineItemCategories.filter((lineItemCategory) => lineItemCategory.id === newValue)

            if (filtered.length) {
                form.label = filtered[0].display_name
                form.header_label = filtered[0].display_name
            }
        });

    /**
     * Computed
     */
    const calculatedCycles = computed(() => {
        if (!form.start_date || !form.end_date || !form.frequency) {
            return null;
        }

        let startDate = parse(form.start_date, 'yyyy-MM-dd', new Date)
        let endDate = parse(form.end_date, 'yyyy-MM-dd', new Date)

        if (form.frequency === 'Monthly') {
            return 1 + differenceInCalendarMonths(endDate, startDate);
        } else if (form.frequency === 'Annually') {
            return 1 + differenceInYears(endDate, startDate);
        } else if (form.frequency === 'Quarterly') {
            return Math.ceil((1 + differenceInCalendarMonths(endDate, startDate)) / 3);
        }

        return null;
    });

    const frequencyMessage = computed(() => {
        if (form.start_date && !form.end_date) {
            return 'This fee will be billed indefinitely until an end date is set.';
        }

        if (form.start_date && form.end_date) {
            if (form.end_date > form.start_date && form.frequency !== 'Per Client Invoice') {
                return `This fee will be billed ${calculatedCycles.value} time(s)`;
            } else if (form.end_date < form.start_date) {
                return 'Date of last month billed must be after the date of the first month billed';
            } else if (form.end_date === form.start_date) {
                return 'Choose One Time Frequency instead';
            }else if (form.end_date > form.start_date && form.frequency === 'Per Client Invoice') {
                return 'This fee will be billed per client invoice';
            }
        } else {
            return '';
        }
    });

    const frequencies = computed(() => {
        if (form.billing_style === 'Fixed') {
            return props.billingFrequencies;
        } else {
            return props.scheduledFrequencies;
        }
    });
</script>