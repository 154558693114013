<template>

    <Head title="Reset Password" />

    <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
	        <logo class="lg:hidden w-75 h-24 px-6 lg:px-0 mx-auto" name="mark-dark"/>
	        <logo class="hidden lg:block w-75 h-24 px-6 lg:px-0 mx-auto" name="full-dark" />
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form action="#" method="POST" @submit.prevent="submitForm">
                    <div>
                        <h3 class="mb-2 text-xl font-bold text-gray-900">
                            Password Reset
                        </h3>

                        <p class="text-sm mb-6">Enter the email associated with your account below and we'll send you a link to reset your password.</p>

                        <label for="email" class="block text-sm font-medium leading-5 text-gray-700">
                            Email address
                        </label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input id="email" type="email" v-model="form.email" required :class="{ error: errors.email && errors.email.length }" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                            <div v-if="errors.email && errors.email.length" class="form-error">{{ errors.email }}</div>
                        </div>
                    </div>

                    <div class="mt-6">
                        <span class="block w-full rounded-md shadow-sm">
                            <loading-button :loading="submitting" type="submit" class="w-full flex justify-center btn btn-orange">
                                Send Password Reset Link
                            </loading-button>
                        </span>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import BlankLayout from '@/Shared/BlankLayout.vue'
    import { Head } from '@inertiajs/vue3';
    import Logo from "../../../Shared/Logo.vue";

    export default {
        layout: BlankLayout,

        components: {
	        Logo,
            LoadingButton,
            TextInput,
            Head,
        },

        props: {
            errors: {
                type: Object,
                default: {}
            },

            status: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
                form: {
                    email: '',
                },

                submitting: false
            }
        },

        methods: {
            submitForm() {
                this.submitting = true;

                this.$inertia.post('/password/email', this.form, {
                    onFinish: () => this.submitting = false
                });
            }
        }
    }
</script>