<template>
    <div>
        <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select @change="goToPage" id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-d-orange-500 focus:outline-none focus:ring-d-orange-500 sm:text-sm">
                <option :value="$route('gathered-vendor-invoice-files.show', gatheredVendorInvoiceFileId)" :selected="'gathered-invoice' === currentTab">Gathered Invoice</option>
                <option v-if="ocrVendorInvoiceId" :value="$route('ocr-vendor-invoices.edit', ocrVendorInvoiceId)" :selected="'extracted-invoice' === currentTab">Extracted Invoice</option>
                <option v-if="vendorInvoiceId" :value="$route('vendor-invoices.edit', vendorInvoiceId)" :selected="'vendor-invoice' === currentTab">Vendor Invoice</option>
            </select>
        </div>

        <div class="hidden sm:block">
            <div class="border-b border-gray-200">
                <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                    <inertia-link :href="$route('gathered-vendor-invoice-files.show', gatheredVendorInvoiceFileId)" :class="['gathered-invoice' === currentTab ? 'border-d-orange-500 text-d-orange-500' : 'border-transparent text-gray-500 hover:text-d-blue-500 hover:border-d-blue-500', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:border-d-blue-500 focus:text-d-blue-500 focus:outline-none']" :aria-current="'dashboard' === currentTab ? 'page' : undefined">Gathered Invoice</inertia-link>
                    <inertia-link v-if="ocrVendorInvoiceId" :href="$route('ocr-vendor-invoices.edit', ocrVendorInvoiceId)" :class="['extracted-invoice' === currentTab ? 'border-d-orange-500 text-d-orange-500' : 'border-transparent text-gray-500 hover:text-d-blue-500 hover:border-d-blue-500', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:border-d-blue-500 focus:text-d-blue-500 focus:outline-none']" :aria-current="'all' === currentTab ? 'page' : undefined">Extracted Invoice</inertia-link>
                    <inertia-link v-if="vendorInvoiceId" :href="$route('vendor-invoices.edit', vendorInvoiceId)" :class="['vendor-invoice' === currentTab ? 'border-d-orange-500 text-d-orange-500' : 'border-transparent text-gray-500 hover:text-d-blue-500 hover:border-d-blue-500', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:border-d-blue-500 focus:text-d-blue-500 focus:outline-none']" :aria-current="'dashboard' === currentTab ? 'not-approved' : undefined">Vendor Invoice</inertia-link>
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { router } from '@inertiajs/vue3';

    const props = defineProps({
        permissions: {
            type: Object,
            required: true
        },

        currentTab: {
            type: String,
            required: true
        },

        vendorInvoiceId: {
            type: String,
            required: false
        },

        ocrVendorInvoiceId: {
            type: String,
            required: false
        },

        gatheredVendorInvoiceFileId: {
            type: String,
            required: true
        },
    });

    function goToPage(e) {
        router.visit(e.target.value);
    }
</script>
