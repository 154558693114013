<template>
    <th>
        <inertia-link class="inline-flex items-center whitespace-nowrap hover:text-black focus:text-black" :href="$route(routeName, queryParams)">
            <slot></slot>
            <icon class="ml-2 inline icon-color fill-current w-3 h-3" :name="sorting.current_icon"/>
        </inertia-link>
    </th>
</template>

<script>

import Icon from '@/Shared/Icon.vue';
import {omit} from 'lodash-es'
import {has_search_filters} from '@/Shared/Utils/Filters.js';

export default {
    components: {
        Icon
    },
    
    props: {
        field: {
            type: String,
            required: true
        },

        routeName: {
            type: String,
            required: true
        },

        filters: {
            type: Object,
            required: true
        }
    },

    computed: {
        sorting: function() {
            if (this.filters['sort_by'] === this.field && this.filters['sort_direction'] === 'asc') {
                return {
                    current_icon: 'sort-up',
                    next_direction: 'desc',
                }
            } else if (this.filters['sort_by'] === this.field && this.filters['sort_direction'] === 'desc') {
                return {
                    current_icon: 'sort-down',
                    next_direction: null,
                }
            } else {
                return {
                    current_icon: 'sort',
                    next_direction: 'asc',
                }
            }
        },

        queryParams() {
            let filters = {...this.filters}
            let urlParams  = new URLSearchParams(window.location.search)

            if (urlParams.has('page')) {
                filters.page = urlParams.get('page')
            }

            if (this.sorting.next_direction) {
                return {...filters,  sort_by: this.field, sort_direction: this.sorting.next_direction}
            } else {
                let omitted = omit(filters, ['sort_by', 'sort_direction'])

                return has_search_filters(omitted) ? omitted : {remember: 'forget'}
            }
        }
    }
}
</script>