<template>
    <Head title="Profit Margin by Client Company" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('reports.index')" class="breadcrumb-link">Reports</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Profit Margin by Client Company</span>
        </nav>
    </Teleport>

    <form id="filter-report-data-form" action="" class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4 my-2 mb-4" @submit.prevent>
        <month-input v-model="form.fiscal_start" label="Fiscal Period Start" />
        <month-input v-model="form.fiscal_end" label="Fiscal Period End" />
        <text-input v-model="form.search" placeholder="Search for a Client Company" label="Search" />
        <toggle-switch-input v-model="form.show_fiscal_periods" label="Show Fiscal Periods" />

        <div class="flex flex-wrap">
            <inertia-link :href="$route('reports.profit-margin-by-client-company')" class="mt-6 btn btn-gray mr-2">Clear</inertia-link>

            <loading-button :loading="state ==='exporting'" class="mt-6 btn btn-gray" @click="exportData">
                Export to CSV
            </loading-button>
        </div>
    </form>

    <div v-if="reportData.data.length != 0">
        <reporting-table :reportData="reportData.data" :ignore="['client_company_id']">
            <template v-slot:client_company_name="data">
                <inertia-link target="_blank" class="link" :href="$route('client-companies.show', data.row.client_company_id)">
                    {{ data.row.client_company_name }}
                </inertia-link>
            </template>
        </reporting-table>
        <pagination v-if="reportData" :links="reportData.links" />
    </div>
    <div v-else>
        <h5>No results found, please adjust your filters.</h5>
    </div>
</template>

<script>
import DropdownLink from '@/Shared/DropdownLink.vue';
import Pagination from '@/Shared/Pagination.vue';
import Icon from '@/Shared/Icon.vue';
import MonthInput from '@/Shared/MonthInput.vue';
import DateInput from '@/Shared/DateInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import TextInput from '@/Shared/TextInput.vue';
import { debounce, pickBy, groupBy } from 'lodash-es';
import ReportingTable from '@/Shared/ReportingTable.vue';
import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import { Head } from '@inertiajs/vue3';

export default {
    components: {
        Icon,
        DropdownLink,
        MonthInput,
        DateInput,
        SelectInput,
        Pagination,
        ReportingTable,
        TextInput,
        ToggleSwitchInput,
        LoadingButton,
        Head,
    },

    props: {
        reportData: {
            type: Object,
            required: true
        },

        filters: Object
    },

    data() {
        return {
            form: { ...this.filters},
            state: 'passive',
            mounted: false,
        }
    },

    mounted() {
        this.mounted = true;
    },

    methods: {
        exportData() {
            this.state = 'exporting';

            this.$inertia.post(this.$route('csv.profit-margin-by-client-company'), {...this.form}, {
                onFinish: () => { this.saving = 'passive'; }
            });
        }
    },

    watch: {
        form: {
            handler: debounce(function() {
                let query = pickBy(this.form);
                query = Object.keys(query).length ? query : {remember: 'forget'};

                this.$inertia.get(this.$route("reports.profit-margin-by-client-company"), query, {preserveState: true});
            }, 500),

            deep: true
        }
    }
}
</script>