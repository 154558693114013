<template>
  <div>
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select @change="goToPage" id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-d-orange-500 focus:outline-none focus:ring-d-orange-500 sm:text-sm">
        <option v-for="tab in tabs" :key="tab.key" :value="tab.route" :selected="tab.key === currentTab">{{ tab.name }}</option>
      </select>
    </div>

    <div class="hidden sm:block">
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
          <inertia-link v-for="tab in tabs" :key="tab.key" :href="tab.route" :class="[tab.key === currentTab ? 'border-d-orange-500 text-d-orange-500' : 'border-transparent text-gray-500 hover:text-d-blue-500 hover:border-d-blue-500', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:border-d-blue-500 focus:text-d-blue-500 focus:outline-none']" :aria-current="tab.key === currentTab ? 'page' : undefined">
            {{ tab.name }}
          </inertia-link>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { ref, inject } from 'vue';

const route = inject('route');

const props = defineProps({
  currentTab: {
    type: String,
    required: true
  },

  incidentId: {
    type: String,
    required: true
  }
});

const tabs = ref([
  {
    key: 'edit',
    name: 'Incidents',
    route: route('incidents.edit', props.incidentId),
  },
  {
    key: 'internal_comments',
    name: 'Internal Comments',
    route: route('incidents.internal-comments.index', props.incidentId),
  },
  {
    key: 'comments',
    name: 'Comments',
    route: route('incidents.comments.index', props.incidentId),
  },
]);

function goToPage(e) {
  router.visit(e.target.value);
}
</script>
