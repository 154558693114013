<style>

</style>

<template>
    <div>
        <div v-if="isWritable">
            <div>{{ $filters.format_money(calculatedAmount) }}</div>

            <!-- Users can adjust the unit cost of the fee as desired until the calculated amount is where it needs to be !-->
            <inline-money-input v-model="fee_unit_cost" :filters="['format_money']" :defaultCurrency="$page.props.currency" @update:modelValue="$emit('allocatable-fee-was-modified', model.line_item_fee_id, calculatedAmount)"/>
        </div>

        <div v-else>
            <div>{{ $filters.format_money(calculatedAmount) }}</div>
            <div>{{ $filters.format_money(fee_unit_cost) }}</div>
        </div>
    </div>
</template>

<script>
    import InlineMoneyInput from '@/Shared/InlineTextInput.vue';

    export default {
        emits: ['allocatable-fee-was-modified'],

        components: {
            InlineMoneyInput
        },
        
        props: {
            model: {
                type: Object,
                required: true
            },

            chargeQuantity: {
                type: Number,
                required: true
            },

            isWritable: {
                type: Boolean,
                default: false
            }
        },

        data() {
            let modified = false;
            
            let fee_unit_cost = {
                amount: parseInt(this.model.amount.amount) / parseFloat(this.chargeQuantity),
                currency: this.model.amount.currency
            };

            return {
                modified,
                fee_unit_cost,
            };
        },

        methods: {
            recalculateFeeUnitCost() {
                this.fee_unit_cost.amount = parseInt(this.model.amount.amount) / parseFloat(this.chargeQuantity);
            },
        },

        computed: {
            /**
             * The calculated value for a unit-based allocated value is always the unit cost of the fee * the (float) quantity of the
             * base charge that the value was derived from.
             */
            calculatedAmount() {
                return {
                    amount: this.fee_unit_cost.amount * parseFloat(this.chargeQuantity),
                    currency: this.model.amount.currency
                };
            },
        },

        watch: {
            'chargeQuantity': {
                handler: function() {
                    this.recalculateFeeUnitCost();
                }
            },

            'model.amount.amount': {
                handler: function() {
                    this.recalculateFeeUnitCost();
                }
            },
        }
    }
</script>
