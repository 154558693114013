<template>
    <Head title="Vendor Service Fees" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            Vendor Service Fees
        </nav>
    </Teleport>

    <index-search-form
        v-model:search="form.search"
        v-model:per-page="form.per_page"
        v-model:selected-headers="form.selected_headers"
        :headers="headers"
        :search-id="'search-vendor-service-fees'"
        :filters-in-use="filtersInUse"
        :excluded-headers="excludedHeaders"
        :per-page-options="[15, 25, 50, 100, 200]"
        :clear-filters-route="$route('vendor-service-fees.index', {remember: 'forget'})"
        @show-filters="$refs.searchFilterSlideOver.show()"
    />

    <div class="w-full my-4">
        <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="vendorServiceFees.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">Showing {{ vendorServiceFees.from }} - {{ vendorServiceFees.to }} out of {{ vendorServiceFees.total }} Records</div>
        </div>

        <div>
            <div class="w-full overflow-x-auto">
                <table class="table table-condensed" ref="table">
                    <thead>
                        <tr>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">ID</th>
                            <th v-show="form.selected_headers.includes('Name')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Name</th>
                            <th v-show="form.selected_headers.includes('Percentage')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Percentage</th>
                            <th v-show="form.selected_headers.includes('Amount')" scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Amount</th>
                            <th v-show="form.selected_headers.includes('Restriction')" scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Restriction</th>
                            <th scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="vendorServiceFee in vendorServiceFees.data" :key="vendorServiceFee.id">
                            <td>
                                <inertia-link :href="$route('vendor-service-fees.edit', vendorServiceFee.id)" class="link" target="_blank">{{ vendorServiceFee.id }}</inertia-link>
                            </td>
                            <td v-show="form.selected_headers.includes('Name')">{{ vendorServiceFee.name }}</td>
                            <td v-show="form.selected_headers.includes('Percentage')">
                                {{ vendorServiceFee.percentage ? $filters.format_percentage(vendorServiceFee.percentage) : '-' }}
                            </td>
                            <td v-show="form.selected_headers.includes('Amount')" class="text-right">
                                {{ vendorServiceFee.amount ? $filters.format_money(vendorServiceFee.amount) : '-' }}
                            </td>
                            <td v-show="form.selected_headers.includes('Restriction')" class="text-right">
                                {{ vendorServiceFee.restriction ?? 'N/A' }}
                            </td>
                            <td class="text-right">
                                <div>
                                    <inertia-link :href="$route('vendor-service-fees.edit', vendorServiceFee.id)" class="link" title="Edit This Record">
                                        Edit
                                    </inertia-link>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <pagination :links="vendorServiceFees.links" />
        </div>

        <div v-if="!vendorServiceFees.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="file-signature" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Vendor Service Fees Found</span>
        </div>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #header="{close}">
            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <form @submit.prevent id="vendor-service-fees-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">

                <select-input id="restrictions" name="restrictions" class="col-span-1" v-model="form.restrictions" label="Restriction">
                    <option value="all">All</option>
                    <option value="unit">$ Per Unit</option>
                    <option value="occurrence">$ Per Occurence</option>
                    <option value="service_charge">% of Service Charges</option>
                    <option value="uncapped">Uncapped</option>
                    <option value="not_allowed">Not Allowed</option>
                </select-input>

            </form>
        </template>
    </slide-over>
</template>

<script>
    import { throttle } from 'lodash-es';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';

    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';
    import SlideOver from '@/Shared/SlideOver.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ColumnSelector from '@/Shared/ColumnSelector.vue';
    import IndexSearchForm from '@/Shared/IndexSearchForm.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';

    export default {
        components: {
            Head,
            Icon,
            SlideOver,
            Pagination,
            SearchInput,
            SelectInput,
            LoadingButton,
            ColumnSelector,
            IndexSearchForm,
            SortableTableHeader,
        },

        props: {
            vendorServiceFees: {
                type: Object,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    per_page: this.filters.per_page,
                    selected_headers: this.filters.selected_headers,
                    restrictions: this.filters.restrictions ?? 'all',
                },
                state: 'passive',
                headers: [],
                excludedHeaders: ['Id','Actions'],
                mounted: false,
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements

                this.headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('vendor-service-fees.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = { ...this.form}
                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.restrictions !== 'all' ? form.restrictions : null,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>

<style scoped>
    .search-icon {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
    }
    .search-animation {
        animation-name: search-animation;
        animation-timing-function: ease;
    }
    @keyframes search-animation {
        0%   { transform: translateY(0); }
        30%  { transform: translateY(-10px); }
        50%  { transform: translateY(0) translateX(5px); }
        100% { transform: translateY(0) translateX(0); }
    }
</style>
