<template>
    <Head title="Monitor Impact by Work Order" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('reports.index')" class="breadcrumb-link">Reports</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Monitor Impact by Work Order</span>
        </nav>
    </Teleport>

    <form id="filter-report-data-form" action="" class="grid grid-cols-4 gap-x-4 gap-y-2 my-2 mb-4">
        <text-input class="col-span-2" v-model="form.search" placeholder="Search for account number, invoice number or vendor name" label="Search" />
        <date-input v-model="form.most_accurate_event_date_start" label="Most Accurate Event Date Start" />
        <date-input v-model="form.most_accurate_event_date_end" label="Most Accurate Event Date End" />

        <div class="col-span-4 md:col-span-2 lg:col-span-1 flex flex-wrap">
            <inertia-link :href="$route('reports.monitor-impact-by-work-order')" class="mt-6 btn btn-gray mr-2">Clear</inertia-link>

            <loading-button :loading="state === 'exporting'" class="mt-6 btn btn-gray" @click="exportData">
                Export to CSV
            </loading-button>
        </div>
    </form>

    <div v-if="reportData.data.length != 0">
        <reporting-table :reportData="reportData.data"></reporting-table>
        <pagination v-if="reportData" :links="reportData.links" />
    </div>
    <div v-else>
        <h5>No results found, please adjust your filters.</h5>
    </div>
</template>

<script>
    import DropdownLink from '@/Shared/DropdownLink.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import Icon from '@/Shared/Icon.vue';
    import MonthInput from '@/Shared/MonthInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import { debounce, pickBy } from 'lodash-es';
    import ReportingTable from '@/Shared/ReportingTable.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            DropdownLink,
            MonthInput,
            DateInput,
            SelectInput,
            Pagination,
            ReportingTable,
            TextInput,
            ToggleSwitchInput,
            LoadingButton,
            Head,
        },

        props: {
            reportData: {
                type: Object,
                required: true
            },

            filters: Object
        },

        data() {
            return {
                form: { ...this.filters},
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            exportData() {
                this.state = 'exporting';

                this.$inertia.post(this.$route('csv.monitor-impact-by-work-order'), {...this.form}, {
                    onFinish: () => { this.state = 'passive'; }
                });
            }
        },

        watch: {
            form: {
                handler: debounce(function() {
                    let query = pickBy(this.form);
                    query = Object.keys(query).length ? query : {remember: 'forget'};

                    this.$inertia.get(this.$route("reports.monitor-impact-by-work-order"), query, {preserveState: true});
                }, 500),

                deep: true
            },
        }
    }
</script>