<template>
    <Head :title="`Compactor Monitor Configuration - ${compactorMonitorConfiguration.id}`"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('compactor-monitor-configurations.index')" class="breadcrumb-link">Compactor Monitor Configurations</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ compactorMonitorConfiguration.device_service_id }}</span>
        </nav>
    </Teleport>

    <!-- Page header -->
    <div
        class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div class="flex-shrink-0">
                <icon name="cogs" class="h-14 w-14" />
            </div>
            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ compactorMonitorConfiguration.device_service_id }}
                </h1>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <inertia-link :href="$route('compactor-monitor-configurations.edit', compactorMonitorConfiguration.id)" class="btn btn-gray">Edit</inertia-link>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="record-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="record-information-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Compactor monitor configuration details.</p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl v-if="compactorMonitorConfiguration" class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Create New Work Orders?</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ compactorMonitorConfiguration.create_work_orders ? 'Yes' : 'No' }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Pickup Management Start Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <template v-if="compactorMonitorConfiguration.pickup_management_start_date">
                                        {{ $filters.format_date(compactorMonitorConfiguration.pickup_management_start_date) }}
                                    </template>

                                    <template v-else>
                                        N/A
                                    </template>
                                </dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Minimum Days Between Pickups</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ compactorMonitorConfiguration.min_days_between_pickups }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Maximum Days Between Pickups</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ compactorMonitorConfiguration.max_days_between_pickups }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Create Work Order X Days Prior to Projection</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ compactorMonitorConfiguration.create_work_order_x_days_prior_to_projection }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Tonnage Capacity</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ compactorMonitorConfiguration.tonnage_capacity }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Target Percentage Full</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ compactorMonitorConfiguration.target_percentage_full }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Automatically Send External Notifications?</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ compactorMonitorConfiguration.send_external_notifications ? 'Yes' : 'No' }}</dd>
                            </div>
                            <div v-if="compactorMonitorConfiguration.send_external_notifications" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Notify Vendor Notification Contacts?</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ compactorMonitorConfiguration.notify_vendor_notification_contacts ? 'Yes' : 'No' }}</dd>
                            </div>
	                        <div v-if="compactorMonitorConfiguration.send_external_notifications" class="sm:col-span-1">
		                        <dt class="text-sm font-medium text-gray-500">Notify Client Notification Contacts?</dt>
		                        <dd class="mt-1 text-sm text-gray-900">{{ compactorMonitorConfiguration.notify_client_notification_contacts ? 'Yes' : 'No' }}</dd>
	                        </div>

                            <div class="col-span-2 border-t"></div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Pre-Monitor Avg Hauls Per Month</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ compactorMonitorConfiguration.pre_monitor_avg_hauls_per_month ?? 'N/A' }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Pre-Monitor Avg Tons Per Month</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ compactorMonitorConfiguration.pre_monitor_avg_tons_per_month ?? 'N/A' }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>

        </div>
	    <!-- Vendor Contacts !-->
	    <div class="space-y-6 lg:col-start-1 lg:col-span-3">
		    <section aria-labelledby="vendor-contacts-title">
			    <div class="bg-white shadow sm:rounded-lg">
				    <div class="flex flex-wrap justify-between">
					    <div class="px-4 py-5 sm:px-6">
						    <h2 id="vendor-contacts-title" class="text-lg leading-6 font-medium text-gray-900">Vendor Contacts</h2>
						    <p class="mt-1 max-w-2xl text-sm text-gray-500">Vendor contacts selected for external notifications.</p>
					    </div>

					    <div v-if="compactorMonitorConfiguration?.vendorContacts.length > 0" class="px-4 py-5 sm:px-6">
						    <inertia-link :href="$route('vendor-contacts.create', {vendor_id: compactorMonitorConfiguration.compactorMonitor.monitoredService.vendor.id})" class="btn btn-orange">New</inertia-link>
					    </div>
				    </div>

				    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
					    <div v-if="compactorMonitorConfiguration.vendorContacts.length > 0" class="flex flex-col">
						    <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
							    <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
								    <table class="table">
									    <thead>
									    <tr>
										    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Name</th>
										    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Title</th>
										    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Email</th>
										    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Phone</th>
										    <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
											    <span class="sr-only">Edit</span>
										    </th>
									    </tr>
									    </thead>

									    <tbody class="divide-y divide-gray-200 bg-white">
									    <tr v-for="contact in compactorMonitorConfiguration.vendorContacts.slice(0, 12)" :key="contact.id">

										    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">
											    <inertia-link class="link"
											                  :href="$route('vendor-contacts.show', [contact.id])">
												    {{ contact.name }}
											    </inertia-link>
										    </td>
										    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{ contact.title }}</td>
										    <td class="text-gray-500">{{ contact.email }}</td>
										    <td class="text-gray-500">{{ $filters.format_phone(contact.primary_phone_number) }}</td>
										    <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
											    <inertia-link :href="$route('vendor-contacts.show', [contact.id])" class="link mr-3">
												    View<span class="sr-only">, {{ contact.id }}</span>
											    </inertia-link>

											    <inertia-link :href="$route('vendor-contacts.edit', [contact.id])" class="link">
												    Edit<span class="sr-only">, {{ contact.id }}</span>
											    </inertia-link>
										    </td>
									    </tr>
									    </tbody>
								    </table>
							    </div>
						    </div>
					    </div>

					    <!-- This example requires Tailwind CSS v2.0+ -->
					    <div v-else class="text-center">
						    <icon name="address-book" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

						    <h3 class="mt-2 text-sm font-medium text-gray-900">No contacts</h3>
						    <p class="mt-1 text-sm text-gray-500">Create a contact for this vendor.</p>
						    <div class="mt-6">
							    <inertia-link :href="$route('vendor-contacts.create', {vendor_id: compactorMonitorConfiguration.compactorMonitor.monitoredService.vendor.id})" class="btn btn-orange">
								    <!-- Heroicon name: solid/plus -->
								    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									    <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
								    </svg>
								    New Contact
							    </inertia-link>
						    </div>
					    </div>
				    </div>
			    </div>
		    </section>
	    </div>

	    <!-- Client Contacts !-->
	    <div class="space-y-6 lg:col-start-1 lg:col-span-3">
		    <section aria-labelledby="client-contacts-title">
			    <div class="bg-white shadow sm:rounded-lg">
				    <div class="flex flex-wrap justify-between">
					    <div class="px-4 py-5 sm:px-6">
						    <h2 id="client-contacts-title" class="text-lg leading-6 font-medium text-gray-900">Client Contacts</h2>
						    <p class="mt-1 max-w-2xl text-sm text-gray-500">Client contacts selected for external notifications.</p>
					    </div>

					    <div v-if="compactorMonitorConfiguration?.clients.length > 0" class="px-4 py-5 sm:px-6">
						    <inertia-link :href="$route('clients.create', {client_company_id: compactorMonitorConfiguration.compactorMonitor.monitoredService.location.client_company_id})" class="btn btn-orange">New</inertia-link>
					    </div>
				    </div>

				    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
					    <div v-if="compactorMonitorConfiguration?.clients.length > 0" class="flex flex-col">
						    <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
							    <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
								    <table class="table">
									    <thead>
									    <tr>
										    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Name</th>
										    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Title</th>
										    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Email</th>
										    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Phone</th>
										    <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
											    <span class="sr-only">Edit</span>
										    </th>
									    </tr>
									    </thead>

									    <tbody class="divide-y divide-gray-200 bg-white">
									    <tr v-for="contact in compactorMonitorConfiguration?.clients.slice(0, 12)" :key="contact.id">

										    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">
											    <inertia-link class="link"
											                  :href="$route('vendor-contacts.show', [contact.id])">
												    {{ contact.name }}
											    </inertia-link>
										    </td>
										    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{ contact.title }}</td>
										    <td class="text-gray-500">{{ contact.email }}</td>
										    <td class="text-gray-500">{{ $filters.format_phone(contact.primary_phone_number) }}</td>
										    <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
											    <inertia-link :href="$route('vendor-contacts.show', [contact.id])" class="link mr-3">
												    View<span class="sr-only">, {{ contact.id }}</span>
											    </inertia-link>

											    <inertia-link :href="$route('vendor-contacts.edit', [contact.id])" class="link">
												    Edit<span class="sr-only">, {{ contact.id }}</span>
											    </inertia-link>
										    </td>
									    </tr>
									    </tbody>
								    </table>
							    </div>
						    </div>
					    </div>

					    <!-- This example requires Tailwind CSS v2.0+ -->
					    <div v-else class="text-center">
						    <icon name="address-book" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

						    <h3 class="mt-2 text-sm font-medium text-gray-900">No contacts</h3>
						    <p class="mt-1 text-sm text-gray-500">Create a contact for this vendor.</p>
						    <div class="mt-6">
							    <inertia-link :href="$route('clients.create', {client_company_id: compactorMonitorConfiguration.compactorMonitor.monitoredService.location.client_company_id})" class="btn btn-orange">
							    <!-- Heroicon name: solid/plus -->
								    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									    <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
								    </svg>
								    New Contact
							    </inertia-link>
						    </div>
					    </div>
				    </div>
			    </div>
		    </section>
	    </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3'

    export default {
        components: {
            Icon,
            Head,
        },

        props: {
            compactorMonitorConfiguration: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },
    }
</script>