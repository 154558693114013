<template>
    <Head title="Create Bulk Import" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.bulk-imports.index')" class="breadcrumb-link">Bulk Imports</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <div class="container mx-auto">
        <form class="flex my-4" id="bulk-import-form">
            <div class="w-1/2">
              <select-input v-model="form.type" label="Type" :errors="errors.type">
                <option value="Insert">Insert</option>
                <option value="Update">Update</option>
              </select-input>
                <div class="my-2">
                    <text-input type="file" multiple ref="pendingFileUpload" @change="addPendingUpload" label="Name" :errors="errors.import_file" />
                </div>

                <div class="mt-6">
                    <inertia-link :href="$route('tenant-settings.bulk-imports.index')" class="btn btn-gray mr-4">
                        Cancel
                    </inertia-link>
                    <button type="submit" class="btn btn-orange" @click.prevent="submitForm">
                        Save
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';

    export default {
        components: {
            Head,
            Icon,
            SelectInput,
            TextInput
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({})
            }
        },

        data() {
            return {
                form: {
                    import_file: null,
                    type: null,
                },
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            addPendingUpload(e) {
                this.form.import_file = e.target.files[0];
            },

            submitForm() {
                this.$inertia.post(this.$route('tenant-settings.bulk-imports.store'), this.form, {
                    forceFormData: true
                });
            }
        },
    }
</script>