<template>
    <Head title="Notifications" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('user-settings.index')" class="breadcrumb-link">My Account</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Notifications</span>
        </nav>
    </Teleport>

    <div class="py-6">
        <horizontal-sub-nav currentTab="notifications" />

        <form @submit.prevent="submitForm">
            <div class="px-4 py-5">
                <div class="divide-y divide-gray-200">

                    <div class="pb-6">
                        <div>
                            <h3 class="text-lg leading-6 font-medium text-gray-900">Notifications</h3>

                            <p class="mt-1 text-sm leading-5 text-gray-600">
                                We'll always let you know about important changes, but you pick what else you want to hear about.
                            </p>
                        </div>

                        <div class="mt-5 " role="group" aria-labelledby="label-mail">
                            <div class="grid grid-cols-12 gap-4 mt-4">
                                <div class="col-span-6 lg:col-span-10">
                                    <div class="text-base font-medium text-gray-800">
                                        Notify me when...
                                    </div>
                                </div>

                                <div class="text-right col-span-3 lg:col-span-1 truncate">
                                    Discovery
                                </div>

                                <div class="text-right col-span-3 lg:col-span-1 truncate">
                                    Email
                                </div>

                                <div class="col-span-6 lg:col-span-10">
                                    <label class="text-gray-700">A data export is finished</label>
                                </div>

	                            <div class="col-span-3 lg:col-span-1 text-right">
		                            <toggle-switch-input v-model="form.discovery.employee_data_export_finished"/>
	                            </div>

	                            <div class="col-span-3 lg:col-span-1 text-right">
	                                <toggle-switch-input v-model="form.mail.employee_data_export_finished"/>
                                </div>

                                <div class="col-span-6 lg:col-span-10">
                                    <label class="text-gray-700">An incident is assigned to me</label>
                                </div>

	                            <div class="col-span-3 lg:col-span-1 text-right">
                                    <toggle-switch-input v-model="form.discovery.incident_assigned_to_employee"/>
	                            </div>

	                            <div class="col-span-3 lg:col-span-1 text-right">
                                    <toggle-switch-input v-model="form.mail.incident_assigned_to_employee"/>
	                            </div>

                                <div class="col-span-6 lg:col-span-10">
                                    <label class="text-gray-700">An incident is created by a client user</label>
                                </div>

	                            <div class="col-span-3 lg:col-span-1 text-right">
	                                <toggle-switch-input v-model="form.discovery.incident_created_by_client"  />
                                </div>
	                            <div class="col-span-3 lg:col-span-1 text-right">
	                                <toggle-switch-input v-model="form.mail.incident_created_by_client" />
                                </div>

                                <div class="col-span-6 lg:col-span-10">
                                    <label class="text-gray-700">An incident is created by an employee user</label>
                                </div>

	                            <div class="col-span-3 lg:col-span-1 text-right">
	                                <toggle-switch-input v-model="form.discovery.incident_created_by_employee" />
	                            </div>

	                            <div class="col-span-3 lg:col-span-1 text-right">
	                                <toggle-switch-input v-model="form.mail.incident_created_by_employee"/>
                                </div>

                                <div class="col-span-6 lg:col-span-10">
                                    <label class="text-gray-700">A work order is created for compactor monitors</label>
                                </div>

	                            <div class="col-span-3 lg:col-span-1 text-right">
	                                <toggle-switch-input v-model="form.discovery.work_order_created_by_monitor"/>
                                </div>

	                            <div class="col-span-3 lg:col-span-1 text-right">
	                                <toggle-switch-input v-model="form.mail.work_order_created_by_monitor"/>
	                            </div>

	                            <div class="col-span-6 lg:col-span-10">
		                            <label class="text-gray-700">A work order is scheduled for compactor monitors</label>
	                            </div>

	                            <div class="col-span-3 lg:col-span-1 text-right">
		                            <toggle-switch-input v-model="form.discovery.work_order_scheduled_for_monitor"/>
	                            </div>

	                            <div class="col-span-3 lg:col-span-1 text-right">
		                            <toggle-switch-input v-model="form.mail.work_order_scheduled_for_monitor"/>
	                            </div>

                                <div class="col-span-6 lg:col-span-10">
                                    <label class="text-gray-700">A monitor has not detected any runs in X days</label>
                                </div>

                                <div class="col-span-3 lg:col-span-1 text-right">
                                    <toggle-switch-input v-model="form.discovery.no_monitor_runs_detected_alert"/>
                                </div>

                                <div class="col-span-3 lg:col-span-1 text-right">
                                    <toggle-switch-input v-model="form.mail.no_monitor_runs_detected_alert"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pt-6">
                        <div>
                            <h3 class="text-lg leading-6 font-medium text-gray-900">Daily Summaries</h3>

                            <p class="mt-1 text-sm leading-5 text-gray-600">
                                Receive a daily summarized list of services that have unresolved issues.
                            </p>
                        </div>

                        <div class="mt-5" role="group">
                            <div class="grid grid-cols-12 gap-4 mt-4">
                                <div class="col-span-6 lg:col-span-12">
                                    <div class="text-base font-medium text-gray-800">
                                        Send me a daily summary email for...
                                    </div>
                                </div>

	                            <div class="lg:hidden text-right col-span-3 lg:col-span-1 truncate">
		                            Discovery
	                            </div>

	                            <div class="lg:hidden text-right col-span-3 lg:col-span-1 truncate">
		                            Email
	                            </div>

                                <div class="col-span-6 lg:col-span-10">
                                    <label class="text-gray-700">Unresolved Rover issues</label>
                                </div>

	                            <div class="col-span-3 lg:col-span-1 text-right">
	                                <toggle-switch-input v-model="form.discovery.vendor_portal_error_summary" />
	                            </div>
	                            <div class="col-span-3 lg:col-span-1 text-right">
		                            <toggle-switch-input v-model="form.mail.vendor_portal_error_summary"/>
	                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-8 border-t border-gray-200 pt-5 px-4">
                <div class="flex justify-start">
                    <span class="ml-3 inline-flex shadow-sm">
                        <loading-button type="submit" :loading="state === 'saving'" class="btn btn-orange">Save</loading-button>
                    </span>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup>
    import { reactive, ref, inject, onMounted } from 'vue';
    import { router } from '@inertiajs/vue3';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import HorizontalSubNav from "../HorizontalSubNav.vue";

    const route = inject('route');

    /**
     * Props
     */
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },
        
        notificationSettings: {
            type: Object,
            required: true
        },
    });
    
    /**
     * State
     */
    const form = reactive({ ...props.notificationSettings });
    const state = ref('passive');
    const mounted = ref(false);

    onMounted(() => {
        mounted.value = true;
    })

    /**
     * Methods
     */
    function submitForm() {
        state.value = 'saving';

        router.put(route('user-settings.notification-settings.update'), form, {
            preserveScroll: true,
            onFinish: () => state.value = 'passive'
        });
    }

    function changeNavigation(e) {
        router.get(e.target.value);
    }
</script>