<template>
    <Head title="Email Settings" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.edit')" class="breadcrumb-link">General</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Email Settings</span>
        </nav>
    </Teleport>

    <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
        <sub-nav current-tab="email-settings" />

        <div class="divide-y divide-gray-200 lg:col-span-9">
            <div class="py-6 px-4 sm:p-6 lg:pb-8">
                <div>
                    <h2 class="text-xl font-medium text-gray-900">Email Settings</h2>
                    <p class="mt-1 text-gray-500">
                        Setup and verify your email settings. Note that until your domain is verified, all client invoices will appear to be sent from <code>discoveryapp.cc</code>. If you want your emails to appear to come from your configured email, then you will need to <b class="font-bold">complete the verification</b> process below.
                    </p>
                </div>

                <form v-if="!domainExists" action="" method="post" @submit.prevent="updateEmailSettings">
                    <div class="py-6 px-6">
                        <div class="grid grid-cols-12 gap-6">
                            <text-input class="col-span-6" v-model="form.billing_email_address" label="Billing Email Address" :errors="errors['billing_email_address']"></text-input>
                            <text-input class="col-span-6" v-model="form.work_order_external_notification_email_address" label="Work Order External Notification Email Address" :errors="errors['work_order_external_notification_email_address']"></text-input>
                            <div class="col-span-12">
                                <button class="btn btn-orange">Update and Check Verification</button>
                            </div>
                        </div>
                    </div>
                </form>

                <div v-else-if="dnsRecords" class="">
                    <div>
                        <div v-if="dnsRecords.state === 'active' && dnsRecords.valid" class="mt-8">
                            <div class="rounded-md bg-green-50 p-4">
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <!-- Heroicon name: mini/check-circle -->
                                        <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <div class="ml-3">
                                        <h3 class="text-sm font-medium text-green-800">Domain Verified</h3>
                                        <div class="mt-2 text-sm text-green-700">
                                            <p>It looks like your domain has been verified and is ready to send emails!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="dnsRecords.state === 'active' && (!dnsRecords.mx1.valid || !dnsRecords.mx2.valid)" class="mt-8">
                            <div class="rounded-md bg-yellow-50 p-4">
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <!-- Heroicon name: mini/exclamation-triangle -->
                                        <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <div class="ml-3">
                                        <h3 class="text-sm font-medium text-yellow-800">Domain Partially Verified - MX Records Missing</h3>
                                        <div class="mt-2 text-sm text-yellow-700">
                                            <p>
                                                Your domain is partially verified, but is missing recommended MX records.
                                                Please follow the steps below in order to verify your domain and ensure email deliverability.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="mt-8">
                            <div class="rounded-md bg-yellow-50 p-4">
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <!-- Heroicon name: mini/exclamation-triangle -->
                                        <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <div class="ml-3">
                                        <h3 class="text-sm font-medium text-yellow-800">Domain Not Verified</h3>
                                        <div class="mt-2 text-sm text-yellow-700">
                                            <p>Please follow the steps below in order to verify your domain. Until you do this, all outgoing client invoice emails will be sent from discoveryapp.cc.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- step 1-->
                        <div class="my-4">
                            <div class="hover:cursor-pointer flex items-center justify-between w-full font-medium text-left text-gray-600 border border-solid p-4" @click="accordion[0] = !accordion[0]">
                                 <span class="text-lg">1. Follow these steps to verify your domain</span>
                                 <div>
                                    <span class="pr-2 text-xs">{{ accordion[0] ? 'Collapse' : 'Expand' }}</span>
                                    <icon :class="{'rotate-180': accordion[0]}" class="w-4 h-4 shrink-0 inline fill-current" name="chevron-down" />
                                </div>
                            </div>

                            <div v-if="accordion[0]" class="col-span-12 text-gray-700">
                                <p class="py-4 text-gray-600">Go to the DNS provider that you use to manage {{ dnsRecords.domain }} and add the following DNS records.
                                    Need a step-by-step walk through? <a href="https://help.mailgun.com/hc/en-us/articles/360026833053" class="link" target="_blank">Click here.</a>
                                    Prefer to watch a video? <a href="https://www.mailgun.com/videos/getting-started-with-mailgun" class="link" target="_blank">We got you covered.</a> Common providers include:</p>
                                <ul class="px-8">
                                    <li><a href="https://www.godaddy.com/help/add-a-txt-record-19232" target="_blank" class="link">GoDaddy</a></li>
                                    <li><a href="https://www.hostgator.com/help/article/changing-dns-records" target="_blank" class="link">HostGator</a></li>
                                    <li><a href="https://www.namecheap.com/support/knowledgebase/article.aspx/579/2237/which-record-type-option-should-i-choose-for-the-information-im-about-to-enter" target="_blank" class="link">NameCheap</a></li>
                                    <li><a href="http://www.networksolutions.com/support/how-to-manage-advanced-dns-records/" target="_blank" class="link">Network Solutions</a></li>
                                    <li><a href="https://support.rackspace.com/how-to/set-up-dns-records-for-cloud-office-email-and-skype-for-business/" target="_blank" class="link">Rackspace Email & Apps</a></li>
                                    <li><a href="https://docs.rackspace.com/support/how-to/cloud-dns/" target="_blank" class="link">Rackspace Cloud DNS</a></li>
                                    <li><a href="http://docs.aws.amazon.com/Route53/latest/DeveloperGuide/R53Console.html" target="_blank" class="link">Amazon Route 53</a></li>
                                </ul>
                            </div>
                        </div>

                        <!-- step 2-->
                        <div class="my-4">
                            <div class="hover:cursor-pointer flex items-center justify-between w-full font-medium text-left text-gray-600 border border-solid p-4" @click="accordion[1] = !accordion[1]">
                                 <span class="text-lg">2. Add DNS records for sending</span>
                                 <div>
                                    <span class="pr-2 text-xs">{{ accordion[1] ? 'Collapse' : 'Expand' }}</span>
                                    <icon :class="{'rotate-180': accordion[1]}" class="w-4 h-4 shrink-0 inline fill-current" name="chevron-down" />
                                </div>
                            </div>

                            <div v-if="accordion[1]" class="col-span-12">
                                <div class="text-gray-600 py-4">TXT records (known as SPF & DKIM) are required to send and receive email. MX records are strongly recommended to ensure email deliverability.</div>

                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Status</th>
                                            <th>Type</th>
                                            <th>Hostname</th>
                                            <th>Enter this value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ dnsRecords.spf.valid ? 'Verified' : 'Not verified'}}</td>
                                            <td>{{ dnsRecords.spf.record_type }}</td>
                                            <td>{{ dnsRecords.spf.name }}</td>
                                            <td>
                                                <div class="flex items-center justify-between text-left w-full">
                                                    <text-input disabled="disabled" class="w-full border-t-4 border-gray-300" v-model="dnsRecords.spf.value"></text-input>
                                                    <div class="mx-2"><copy-to-clipboard-button v-model="dnsRecords.spf.value" /></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ dnsRecords.dkim.valid ? 'Verified' : 'Not verified'}}</td>
                                            <td>{{ dnsRecords.dkim.record_type }}</td>
                                            <td>{{ dnsRecords.dkim.name }}</td>
                                            <td>
                                                <div class="flex items-center justify-between text-left w-full">
                                                    <textarea-input rows="8" disabled="disabled" class="w-full border-t-4 border-gray-300" v-model="dnsRecords.dkim.value"></textarea-input>
                                                    <div class="mx-2"><copy-to-clipboard-button v-model="dnsRecords.dkim.value" /></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ dnsRecords.dmarc.valid ? 'Verified' : 'Not verified'}}</td>
                                            <td>{{ dnsRecords.dmarc.record_type }}</td>
                                            <td>{{ dnsRecords.dmarc.name }}</td>
                                            <td>
                                                <div class="flex items-center justify-between text-left w-full">
                                                    <textarea-input rows="8" disabled="disabled" class="w-full border-t-4 border-gray-300" v-model="dnsRecords.dmarc.value"></textarea-input>
                                                    <div class="mx-2"><copy-to-clipboard-button v-model="dnsRecords.dmarc.value" /></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ dnsRecords.mx1.valid ? 'Verified' : 'Not verified'}}</td>
                                            <td>{{ dnsRecords.mx1.record_type }}</td>
                                            <td>{{ dnsRecords.spf.name }}</td>
                                            <td>
                                                <div class="flex items-center justify-between text-left w-full">
                                                    <textarea-input rows="8" disabled="disabled" class="w-full border-t-4 border-gray-300" v-model="dnsRecords.mx1.value"></textarea-input>
                                                    <div class="mx-2"><copy-to-clipboard-button v-model="dnsRecords.mx1.value" /></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ dnsRecords.mx2.valid ? 'Verified' : 'Not verified'}}</td>
                                            <td>{{ dnsRecords.mx2.record_type }}</td>
                                            <td>{{ dnsRecords.spf.name }}</td>
                                            <td>
                                                <div class="flex items-center justify-between text-left w-full">
                                                    <textarea-input rows="8" disabled="disabled" class="w-full border-t-4 border-gray-300" v-model="dnsRecords.mx2.value"></textarea-input>
                                                    <div class="mx-2"><copy-to-clipboard-button v-model="dnsRecords.mx2.value" /></div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <!-- step 3 -->
                        <div class="my-4">
                            <div class="hover:cursor-pointer flex items-center justify-between w-full font-medium text-left text-gray-600 border border-solid p-4" @click="accordion[2] = !accordion[2]">
                                 <span class="text-lg">3. Add DNS records for tracking (optional)</span>
                                 <div>
                                    <span class="pr-2 text-xs">{{ accordion[2] ? 'Collapse' : 'Expand' }}</span>
                                    <icon :class="{'rotate-180': accordion[2]}" class="w-4 h-4 shrink-0 inline fill-current" name="chevron-down" />
                                </div>
                            </div>
                            <div v-if="accordion[2]" class="p-4">
                                <p class="text-gray-600 py-4">The CNAME record is necessary for tracking opens, clicks, and unsubscribes (recommended).</p>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Status</th>
                                            <th>Type</th>
                                            <th>Hostname</th>
                                            <th>Enter this value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ dnsRecords.tracking.valid ? 'Verified' : 'Not verified'}}</td>
                                            <td>{{  dnsRecords.tracking.record_type }}</td>
                                            <td>{{ dnsRecords.tracking.name }}</td>
                                            <td>
                                                <div class="flex items-center justify-between text-left w-full">
                                                    <text-input disabled="disabled" class="w-full border-t-4 border-gray-300" v-model="dnsRecords.tracking.value"></text-input>
                                                    <div class="mx-2"><copy-to-clipboard-button v-model="dnsRecords.tracking.value" /></div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <!-- step 4 -->
                        <div class="my-4">
                            <div class="hover:cursor-pointer flex items-center justify-between w-full font-medium text-left text-gray-600 border border-solid p-4" @click="accordion[3] = !accordion[3]">
                                 <span class="text-lg">4. Wait for your domain to verify</span>
                                 <div>
                                    <span class="pr-2 text-xs">{{ accordion[3] ? 'Collapse' : 'Expand' }}</span>
                                    <icon :class="{'rotate-180': accordion[3]}" class="w-4 h-4 shrink-0 inline fill-current" name="chevron-down" />
                                </div>
                            </div>
                            <div v-if="accordion[3]" class="p-4">
                                <p class="text-gray-600 pb-8">
                                    Once you make the above DNS changes it can take up to 48 hours for those changes to propagate. You can revisit this page to check the status of your domain. If after 48 hours your domain is still not verified, please double check your DNS record settings to confirm they are all correct. And as always, please contact our support if you run into any issues.
                                </p>

                                <!-- <button class="btn btn-orange" @click="verifyBillingEmailAddress">Verify</button> -->
                            </div>
                        </div>


                        <!-- step 5 -->
                        <div class="my-4">
                            <div class="hover:cursor-pointer flex items-center justify-between w-full font-medium text-left text-gray-600 border border-solid p-4" @click="accordion[4] = !accordion[4]">
                                 <span class="text-lg">Other Options</span>
                                 <div>
                                    <span class="pr-2 text-xs">{{ accordion[4] ? 'Collapse' : 'Expand' }}</span>
                                    <icon :class="{'rotate-180': accordion[4]}" class="w-4 h-4 shrink-0 inline fill-current" name="chevron-down" />
                                </div>
                            </div>
                            <div v-if="accordion[4]" class="p-4">
                                <h3 class="font-bold">Change your email settings</h3>
                                <p class="text-gray-600 pb-8">
                                    <i class="font-italtics">Want to change your email settings?</i> You can enter new email addresses below.
                                    Once you click <b class="font-bold">'Update Email Settings'</b> you will
                                    need to re-verify the new domain (if it is changing) in order for emails to be sent via
                                    those email addresses.
                                </p>

                                <div class="grid grid-cols-12 gap-6">
                                    <text-input label="Billing Email Address" v-model="form.billing_email_address" class="col-span-6" placeholder="Please enter the new billing email address" :errors="errors['billing_email_address']" />
                                    <text-input label="Work Order External Notification Email Address" v-model="form.work_order_external_notification_email_address" class="col-span-6" placeholder="Please enter the new work order external notification email address" :errors="errors['work_order_external_notification_email_address']" />
                                    <div class="col-span-6">
                                        <button class="mt-6 btn btn-red col-span-6" @click="updateEmailSettings">
                                            Update Email Settings
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="py-6 px-4 sm:p-6 lg:pb-8">
                        <h2>Something has gone wrong. You shouldn't see this. Please contact support.</h2>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import CopyToClipboardButton from '@/Shared/CopyToClipboardButton.vue';
    import SubNav from '../SubNav.vue';

    export default {
        components: {
            Head,
            Icon,
            TextInput,
            TextareaInput,
            SelectInput,
            ToggleSwitchInput,
            CopyToClipboardButton,
            LoadingButton,
            SubNav
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            tenant: {
                type: Object,
                required: true,
            },

            domainExists: {
                type: Boolean,
                required: true,
            },

            dnsRecords: Object,
        },

        data() {

            return {
                form: {
                    billing_email_address: this.tenant.billing_email_address,
                    work_order_external_notification_email_address: this.tenant.work_order_external_notification_email_address,
                },
                accordion: [
                    this.dnsRecords?.state !== 'active',
                    this.dnsRecords?.state !== 'active',
                    false,
                    false,
                    false
                ],
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            updateEmailSettings() {
                this.saving = true;

                this.$inertia
                    .put(this.$route('tenant-settings.email-settings.update'), this.form, {
                        preserveScroll: true,
                        onFinish: () => this.saving = false
                    });
            },
        },
    }
</script>
