<template>
    <button ref="clickToCopy" v-if="value" type="button" :class="$attrs.class" @click.prevent="copyToClipboard">
        <slot></slot>
    </button>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import { v4 as uuid } from 'uuid';
    import { useTippy } from 'vue-tippy';

    export default {
        components: {
            Icon
        },

        inheritAttrs: false,

        props: {
            id: {
                type: String,
                default() {
                    return `copy-to-clipboard-button-${uuid()}`;
                },
            },

            value: [String, Number],
        },

        mounted() {
            useTippy(this.$refs.clickToCopy, {
                content: 'Click to Copy',
                placement: 'top',
                onHidden(instance) {
                    instance.setContent('Click to Copy');
                },
            });
        },

        methods: {
            copyToClipboard() {
                navigator.clipboard.writeText(this.value);
                this.$refs.clickToCopy._tippy.setContent('Copied to Clipboard');
                this.$refs.clickToCopy._tippy.show();
            }
        },
}
</script>
