<template>
    <Head title="Create Vendor Contact" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendor-contacts.index')" class="breadcrumb-link">Vendor Contacts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <div class="py-6">
        <form id="create-vendor-contact-form" class="space-y-8" @submit.prevent="submitForm">
            <fieldset class="space-y-8 sm:space-y-5">
                <legend class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Info</h3>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Name, Company, Title, etc.</p>
                    </div>
                </legend>

                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <text-input id="first-name" v-model="form.first_name" class="col-span-6 sm:col-span-3" placeholder="John/Jane" label="First Name" :errors="errors.first_name" mark-as-required />
                    <text-input id="last-name" v-model="form.last_name" class="col-span-6 sm:col-span-3" placeholder="Doe" label="Last Name" :errors="errors.last_name" mark-as-required />
                    <text-input id="title" v-model="form.title" class="col-span-6 sm:col-span-3" placeholder="Client Success Champion" label="Title" :errors="errors.title" />
                    <select-input id="type" v-model="form.type" class="col-span-6 sm:col-span-3" label="Type" :errors="errors.type" mark-as-required>
                        <option v-for="type in types" :key="type">{{ type }}</option>
                    </select-input>
                    <text-input id="external-reference-id" v-model="form.external_reference_id" class="col-span-6 sm:col-span-3" placeholder="EXT-ABCD-12343" label="External Reference Id" :errors="errors.external_reference_id" />
                    <search-input @selected="selectedVendor" v-model="form.vendor_id" class="col-span-6 sm:col-span-3" label="Vendor" :errors="errors.vendor_id" route="json.vendors.index" :default-value="vendor" value-property="id" display-property="name" mark-as-required>
                        <template #default="obj">
                            <span class="block">{{ obj.result.name }}</span>
                        </template>
                    </search-input>
                    <toggle-switch-input id="is-decision-maker" v-model="form.is_decision_maker" label="Decision Maker" />
                    <textarea-input id="notes" v-model="form.notes" label="Notes" class="col-span-6 sm:col-span-3" :errors="errors.notes" autosize />
                </div>
            </fieldset>

            <fieldset class="space-y-8 sm:space-y-5">
                <legend class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Contact Info</h3>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Email, phone, etc.</p>
                    </div>
                </legend>

                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <text-input type="email" id="email" v-model="form.email" class="sm:col-span-3" placeholder="johndoe@unknown.com" label="Email Address" :errors="errors.email" />
                    <text-input type="text" id="primary-phone-number" v-model="form.primary_phone_number" class="sm:col-span-3" label="Primary Phone" :errors="errors.primary_phone_number" />
                    <text-input type="text" id="alternative-phone-number" v-model="form.alternative_phone_number" class="sm:col-span-3" label="Alternative Phone" :errors="errors.alternative_phone_number" />
                    <text-input type="text" id="mobile-phone-number" v-model="form.mobile_phone_number" class="sm:col-span-3" label="Mobile Phone" :errors="errors.mobile_phone_number" />
                    <text-input type="text" id="office-phone-number" v-model="form.office_phone_number" class="sm:col-span-3" label="Office Phone" :errors="errors.office_phone_number" />
                    <text-input type="text" id="fax-phone-number" v-model="form.fax_phone_number" class="sm:col-span-3" label="Fax Number" :errors="errors.fax_phone_number" />
                    <toggle-switch-input v-model="form.send_sms" :disabled="!form.mobile_phone_number" class="sm:col-span-3" label="Send SMS (Mobile Phone Required)" :errors="errors.send_sms" />
                </div>
            </fieldset>

            <div class="mt-8">
                <div class="flex justify-start">
                    <loading-button :loading="state === 'saving'" type="submit" class="btn btn-orange">Save</loading-button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import DateInput from '@/Shared/DateInput.vue';

    export default {
        components: {
            Head,
            Icon,
            SelectInput,
            LoadingButton,
            TextInput,
            SearchInput,
            TextareaInput,
            ToggleSwitchInput,
            DateInput
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            vendorContact: {
                type: Object,
                required: true
            },

            types: {
                type: Array,
                required: true
            },

            // Default vendor if a vendor_id was passed via URL query string.
            vendor: {
                type: Object,
                required: false,
            },
        },

        data() {
            return {
                form: {
                    ...this.vendorContact,
                    is_decision_maker: false,
                },
                saving: false,
                vendors: [],
                selectedVendor: null,
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm() {
                this.state = 'saving';

                this.$inertia.post(this.$route('vendor-contacts.store'), this.form, {
                    preserveScroll: true,
                    onFinish: () => this.state = 'passive'
                });
            },
        },

	    watch: {
		    'form.mobile_phone_number': function (value) {
			    if (!value) {
				    this.form.send_sms = false;
			    }
		    },
	    }

    }
</script>