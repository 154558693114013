<template>
    <div class="mt-2">
        <div v-if="!allFiltersValid" class="flex font-bold">
            Some of the filters below are invalid. Please adjust any filters showing a warning icon (<icon name="exclamation-triangle" class="inline fill-current h-4 w-4 text-red-500 " />) before saving this report.
        </div>

        <div v-if="filters.length > 0" class="w-full grid grid-cols-12 px-3 py-2 gap-4">
            <div class="col-start-2 col-span-10 grid grid-cols-10">
                <label :id="`${id}-mode-label`" class="hidden lg:inline">
                    <span class="text-red-500">*</span> Mode
                </label>

                <label :id="`${id}-entity-label`" class="col-span-3 hidden lg:inline">
                    <span class="text-red-500">*</span> Entity
                </label>

                <label :id="`${id}-property-label`" class="col-span-2 hidden lg:inline">
                    <span class="text-red-500">*</span> Property
                </label>

                <label :id="`${id}-operator-label`" class="col-span-2 hidden lg:inline">
                    <span class="text-red-500">*</span> Operator
                </label>

                <label :id="`${id}-value-label`" class="col-span-2 hidden lg:inline">
                    <span class="text-red-500">*</span> Value
                </label>
            </div>

            <label :id="`${id}-move-up-label`" class="hidden">
                Move Up
            </label>

            <label :id="`${id}-move-down-label`" class="hidden">
                Move Down
            </label>

            <label :id="`${id}-remove-label`" class="hidden">
                Remove
            </label>
        </div>

        <div v-for="(filter, index) in filters">
            <div
                :id="`${id}-${index}-before`"
                class="py-2"
                @dragenter="dragEnter($event, filter.sortOrder - 0.1)"
                @dragover.prevent
                @drop="drop($event)"></div>
            <fieldset
                :id="filter.key"
                :key="filter.key"
                draggable="true"
                @dragstart="dragStart($event, filter)"
                @dragend="dragEnd"
                class="grid grid-cols-12 cursor-move px-3 py-2 border my-1 bg-gray-300 gap-4 align-items-center">

                <div class="mx-2 my-auto">
                    <icon name="grip-vertical" class="inline fill-current h-6 w-6" />
                    <icon v-if="!filterIsValid(filter)" name="exclamation-triangle" class="inline fill-current h-6 w-6 text-red-500 " />
                </div>

                <div class="w-full col-span-10 grid grid-cols-10">
                    <div class="lg:flex col-span-10 lg:col-span-1 mr-1 my-1">
                        <label :id="`${id}-${filter.key}-mode-label-sm`" class="flex px-2 my-auto lg:hidden">
                            <span class="text-red-500 pr-1">*</span> Mode
                        </label>

                        <select-input :id="`${filter.key}-mode`" v-model="filter.mode" class="w-full" :aria-labelledby="`${id}-mode-label ${id}-${filter.key}-mode-label-sm`">
                            <option v-for="mode in filterModes" :key="mode" :value="mode">{{ mode }}</option>
                        </select-input>
                    </div>

                    <div class="lg:flex col-span-10 lg:col-span-3 mx-1 my-1">
                        <label :id="`${id}-${filter.key}-entity-label-sm`" class="flex px-2 my-auto lg:hidden">
                            <span class="text-red-500 pr-1">*</span> Entity
                        </label>

                        <select-input-2 :id="`${filter.key}-entity`" v-model="filters[index].property.entity" class="w-full" :aria-labelledby="`${id}-entity-label ${id}-${filter.key}-entity-label-sm`" @selected="entityChanged(filter)" :options="getAvailableEntities(filter)">
                            <template #option-search="entity">{{ entity.pathDisplayName }}</template>
                            <template #option-label="entity">{{ entity.pathDisplayName }}</template>
                            <template #option-display="{option, selected}">
                                <div :class="{'font-semibold': selected}" class="block">
                                    <div>{{ option.pathDisplayName }}</div>
                                </div>
                                <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                                    <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                                </span>
                            </template>
                        </select-input-2>
                    </div>

                    <div class="lg:flex col-span-10 lg:col-span-2 mx-1 my-1">
                        <label :id="`${id}-${filter.key}-property-label-sm`" class="flex px-2 my-auto lg:hidden">
                            <span class="text-red-500 pr-1">*</span> Property
                        </label>

                        <select-input-2 :id="`${filter.key}-property`" v-model="filters[index].property.entity_property" class="w-full" :aria-labelledby="`${id}-property-label ${id}-${filter.key}-property-label-sm`"  @selected="propertyChanged(filter)" :options="getAvailableProperties(filter)">
                            <template #option-search="property">{{ property.default_display_name }}</template>
                            <template #option-label="property">{{ property.default_display_name }}</template>
                            <template #option-display="{option, selected}">
                                <div :class="{'font-semibold': selected}" class="block">
                                    <div>{{ option.default_display_name }}</div>
                                </div>
                                <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                                    <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                                </span>
                            </template>
                        </select-input-2>
                    </div>

                    <div class="lg:flex col-span-10 lg:col-span-2 mr-1 my-1">
                        <label :id="`${id}-${filter.key}-operator-label-sm`" class="flex px-2 my-auto lg:hidden">
                            <span class="text-red-500">*</span> Operator
                        </label>

                        <select-input :id="`${filter.key}-operator`" v-model="filters[index].operator" class="w-full" v-on:change="updateFilterInputType(filter)" :aria-labelledby="`${id}-operator-label ${id}-${filter.key}-operator-label-sm`">
                            <option v-for="(operator) in getAvailableOperators(filter)" :key="operator.key" :value="operator.key">{{ operator.text }}</option>
                        </select-input>
                    </div>

                    <div class="lg:flex col-span-10 lg:col-span-2 mr-1 my-1">
                        <label :id="`${id}-${filter.key}-value-label-sm`" class="flex px-2 my-auto lg:hidden">
                            <span class="text-red-500">*</span> Value
                        </label>

                        <select-input v-if="filter.inputType === inputTypes.boolean" :id="`${filter.key}-value`" v-model="filters[index].value" class="w-full" :aria-labelledby="`${id}-value-label ${id}-${filter.key}-value-label-sm`">
                            <option key="true" value="true">True</option>
                            <option key="false" value="false">False</option>
                        </select-input>

                        <date-input v-else-if="filter.inputType === inputTypes.date" :id="`${filter.key}-value`" v-model="filters[index].value" class="w-full" :aria-labelledby="`${id}-value-label ${id}-${filter.key}-value-label-sm`" draggable="true" v-on:dragstart="preventDrag"/>

                        <date-time-local-input v-else-if="filter.inputType === inputTypes.dateTime" :id="`${filter.key}-value`" v-model="filters[index].value" class="w-full" :aria-labelledby="`${id}-value-label ${id}-${filter.key}-value-label-sm`" draggable="true" v-on:dragstart="preventDrag"/>

                        <text-input v-else-if="filter.inputType === inputTypes.number" :id="`${filter.key}-value`" type="number" v-model="filters[index].value" class="w-full" :aria-labelledby="`${id}-value-label ${id}-${filter.key}-value-label-sm`" draggable="true" v-on:dragstart="preventDrag"/>

                        <text-input v-else :id="`${filter.key}-value`" v-model="filters[index].value" class="w-full" :aria-labelledby="`${id}-value-label ${id}-${filter.key}-value-label-sm`" draggable="true" v-on:dragstart="preventDrag"/>
                    </div>
                </div>

                <div class="grid grid-cols-1 lg:grid-cols-3">
                    <button @click.prevent="moveFilter(filter.key, filter.sortOrder - 1.1)" class="mx-auto lg:pr-1 my-auto " :aria-labelledby="`${id}-move-up-label`">
                        <icon v-if="index > 0" name="chevron-up" class="inline fill-current h-7 w-7"/>
                    </button>
                    <button @click.prevent="moveFilter(filter.key, filter.sortOrder + 1.1)" class="mx-auto lg:px-1 my-auto" :aria-labelledby="`${id}-move-down-label`">
                        <icon v-if="index < filters.length - 1" name="chevron-down" class="inline fill-current h-7 w-7"/>
                    </button>
                    <button @click.prevent="removeFilter(index)" class="ml-1 my-auto" :aria-labelledby="`${id}-remove-label`">
                        <icon name="trash" class="inline fill-current h-7 w-7 text-red-500"/>
                    </button>
                </div>
            </fieldset>
            <div
                v-if="index === filters.length - 1"
                :id="`${id}-${index}-after`"
                class="py-2"
                @dragenter="dragEnter($event, filter.sortOrder + 0.1)"
                @dragover.prevent
                @drop="drop($event)"></div>
        </div>

        <div v-if="errors" class="form-error">{{ errors }}</div>

        <button :disabled="!mainEntity" @click.prevent="addFilter()" class="my-2 btn btn-gray float-right">
            <icon name="plus" class="inline fill-current h-5 w-5"/>Add Filter
        </button>
    </div>
</template>

<script>
import {cloneDeep} from 'lodash-es';
import DateInput from '@/Shared/DateInput.vue';
import DateTimeLocalInput from '@/Shared/DateTimeLocalInput.vue';
import Icon from '@/Shared/Icon.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import SelectInput2 from "@/Shared/SelectInput2.vue";
import TextInput from "@/Shared/TextInput.vue";
import {v4 as uuid} from 'uuid';
import axios from "axios";

export default {
    components: {
        DateInput,
        DateTimeLocalInput,
        Icon,
        SelectInput,
        SelectInput2,
        TextInput
    },

    inheritAttrs: false,

    props: {
        id: {
            type: String,
            default() {
                return `repeatable-field-set-${uuid()}`;
            },
        },

        label: String,
        mainEntity: String,

        filterGroupKey: {
            type: String,
            required: true
        },

        entities: {
            type: Array,
            required: true
        },

        initialProperties: {
            type: Object,
        },

        savedFilters: {
            type: Array,
            default: () => []
        },

        errors: {
            type: String,
            default: '',
        }
    },

    data() {
        return {
            counter: 0,
            filters: [],
            draggedFilter: {key: null, sortOrder: null, dragging: false},
            inputTypes: {
                boolean: 'boolean',
                date: 'date',
                dateTime: 'dateTime',
                number: 'number',
                text: 'text',
            },
            availableProperties: {},
            filterModes: [
                'AND',
                'OR'
            ]
        };
    },

    methods: {
        getAvailableEntities(filter) {
            if (filter.property.entity?.isPlaceholder) {
                return [
                    filter.property.entity,
                    ...this.entities
                ];
            }

            return this.entities;
        },

        getAvailableProperties(filter) {
            if (!filter.property.entity) {
                return [];
            }

            if (filter.property.entity_property?.isPlaceholder) {
                return [
                    filter.property.entity_property,
                    ...this.availableProperties[filter.property.entity?.entityClass]?.length ? this.availableProperties[filter.property.entity?.entityClass] : []
                ];
            }

            return this.availableProperties[filter.property.entity?.entityClass]?.length ? this.availableProperties[filter.property.entity?.entityClass] : [];
        },

        filterIsValid(filter) {
            if (!filter.property.entity || !filter.property.entity_property) {
                return true;
            }

            return !filter.property.entity.isPlaceholder && !filter.property.entity_property.isPlaceholder;
        },

        addFilter(filter = null) {
            if (!filter) {
                filter = {
                    key: `${this.id}-filter-${this.counter}`,
                    entity: null,
                    property: {
                        entity: null,
                        entity_property: null
                    },
                    operator: null,
                    value: null,
                    mode: 'AND',
                    inputType: null,
                    sortOrder: null
                };
            }

            filter.sortOrder = this.filters.length;
            filter.inputType = this.getInputTypeForFilter(filter);

            this.filters.push(filter);
            this.counter++;
        },

        removeFilter(index) {
            this.filters.splice(index, 1);

            if (this.filters.length === 0) {
                this.$emit('last-filter-removed', this.filterGroupKey);
            }
        },

        dragStart(e, filter) {
            e.dataTransfer.dropEffect = 'move';
            e.dataTransfer.effectAllowed = 'move';

            this.draggedFilter.key = filter.key;
            this.draggedFilter.sortOrder = filter.sortOrder;
            this.draggedFilter.dragging = true;
        },

        dragEnter(e, sortOrder) {
            this.draggedFilter.sortOrder = sortOrder;
            this.moveFilter(this.draggedFilter.key, this.draggedFilter.sortOrder);
        },

        drop(e) {
            e.preventDefault();     // prevent default action (open as link for some elements)
        },

        dragEnd() {
            this.draggedFilter.key = null;
            this.draggedFilter.sortOrder = null;
            this.draggedFilter.dragging = false;
        },

        getEntityFromSavedFilter(filter) {
            return this.entities.find((entity) => entity.path === filter.property.entity_path) ?? this.createPlaceholderEntityFromSavedFilter(filter);
        },

        getEntityPropertyFromSavedFilter(filter) {
            return this.availableProperties[filter.property.entity]?.find((property) => property.name === filter.property.name) ?? this.createPlaceholderPropertyFromSavedFilter(filter);
        },

        createPlaceholderEntityFromSavedFilter(filter) {
            return {
                path: filter.property.entity_path,
                pathDisplayName: this.getPlaceholderEntityPathDisplayName(filter.property.entity_path),
                isPlaceholder: true
            }
        },

        getPlaceholderEntityPathDisplayName(entityPath) {
            let pathParts = entityPath.split('.');

            pathParts = pathParts
                .map(part => part.slice(0, 1).toUpperCase() + part.slice(1))
                .map(part => part.replace(/([a-z])([A-Z])/g, '$1 $2'));
            return pathParts.join(' > ');
        },

        createPlaceholderPropertyFromSavedFilter(filter) {
            return {
                name: filter.property.name,
                display_name: filter.property.display_name,
                default_display_name: filter.property.default_display_name,
                type: 'Text',
                isPlaceholder: true,
            }
        },

        preventDrag(e) {
            e.preventDefault();
            e.stopPropagation();
        },

        moveFilter(filterKey, sortOrder) {
            const filter = this.filters.find(filter => filter.key === filterKey);
            filter.sortOrder = sortOrder;

            this.sortFilters();
        },

        sortFilters() {
            this.filters.sort(function (a, b) {
                if (a.sortOrder > b.sortOrder) {
                    return 1;
                } else if (a.sortOrder === b.sortOrder) {
                    return 0;
                } else {
                    return -1;
                }
            })

            for (const key of this.filters.keys()) {
                this.filters[key].sortOrder = key;
            }
        },

        updateFilterProperties(filter) {
            const entity = filter.property.entity.entityClass;
            const path = filter.property.entity.path;

            if (!entity || this.availableProperties[entity]) {
                return;
            }

            axios.get(this.$route('json.custom-reports.get-entity-properties', {entityName: entity}))
                .then(response => {

                    this.availableProperties[entity] = response.data.properties.map(property => {
                        return {
                            key: `${path}.${property.name}`,
                            entity: property.entity,
                            name: property.name,
                            display_name: property.display_name,
                            default_display_name: property.default_display_name,
                            type: property.type
                        };
                    });
                });

            if (this.availableProperties[entity]) {
                this.availableProperties[entity] = this.availableProperties[entity].sort(function (a, b) {
                    if (a.default_display_name === null) {
                        return -1;
                    }

                    if (a.default_display_name > b.default_display_name) {
                        return 1;
                    } else if (a.default_display_name === b.default_display_name) {
                        return 0;
                    } else {
                        return -1;
                    }
                });
            }
        },

        updateFilterInputType(filter) {
            let newInputType = this.getInputTypeForFilter(filter);

            if (!this.newInputTypeIsCompatibleWithOldInputType(newInputType, filter.inputType)) {
                filter.value = '';
            }

            filter.inputType = newInputType;
        },

        getInputTypeForFilter(filter) {
            switch(filter?.operator) {
                case 'IS_EMPTY':
                    return this.inputTypes.boolean;
            }

            switch(filter?.property?.entity_property?.type) {
                case 'Boolean':
                    return this.inputTypes.boolean;
                case 'Date':
                    return this.inputTypes.date;
                case 'DateTime':
                    return this.inputTypes.dateTime;
                case 'Integer':
                case 'Real Number':
                    return this.inputTypes.number;
                default:
                    return this.inputTypes.text;
            }
        },

        newInputTypeIsCompatibleWithOldInputType(newInputType, oldInputType) {
            return newInputType === oldInputType || newInputType === this.inputTypes.text;
        },

        getAvailableOperators(filter) {
            if (!filter.property?.entity_property?.type) {
                return [];
            }

            switch (filter.property.entity_property.type){
                case 'Boolean':
                    return [
                        {key: '=', text: 'Equal To'},
                        {key: '!=', text: 'Not Equal To'},
                        {key: 'IS_EMPTY', text: 'Is Empty'}
                    ];
                default :
                    return [
                        {key: '=', text: 'Equal To'},
                        {key: '!=', text: 'Not Equal To'},
                        {key: 'ILIKE', text: 'Contains'},
                        {key: '<', text: 'Less Than'},
                        {key: '<=', text: 'Less Than or Equal To'},
                        {key: '>', text: 'Greater Than'},
                        {key: '>=', text: 'Greater Than or Equal To'},
                        {key: 'IS_EMPTY', text: 'Is Empty'}
                    ];
            }
        },

        entityChanged(filter) {
            this.updateFilterProperties(filter);
            filter.property.entity_property = null;
            filter.property.selectedProperty = null;
            filter.value = null;
            filter.operator = null;
        },

        propertyChanged(filter) {
            this.updateFilterInputType(filter);
            filter.value = null;
            filter.operator = null;
        },
    },

    watch: {
        mainEntity() {
            this.filters = [];
        },

        filters: {
            handler(newValue, oldValue) {
                this.$emit('update:modelValue', newValue.map(filter => {
                    return {
                        property: {
                            entity_path: filter.property.entity?.path,
                            name: filter.property.entity_property?.name
                        },
                        operator: filter.operator,
                        value: filter.value,
                        mode: filter.mode
                    };
                }));
            },
            deep: true
        }
    },

    computed: {
        propertyKeys() {
            return Object.values(this.availableProperties).flat().map(property => property.key);
        },

        allFiltersValid() {
            if (this.propertyKeys.length === 0) {
                return true;
            }

            for (const filter of this.filters) {
                if (filter.property && filter.property.key && !this.propertyKeys.includes(filter.property.key)) {
                    return false;
                }
            }

            return true;
        }
    },

    mounted() {
        this.availableProperties = { ...this.initialProperties };

        for (const savedFilter of this.savedFilters) {
            let filter = cloneDeep(savedFilter);

            filter.key = `${this.id}-filter-${this.counter}`;
            filter.property = {
                entity: this.getEntityFromSavedFilter(savedFilter),
                entity_property: this.getEntityPropertyFromSavedFilter(savedFilter)
            }

            this.addFilter(filter);
        }

        if (this.filters.length === 0) {
            this.addFilter();
        }
    }
}
</script>
