<template>
    <Head :title="`Rover Account - ${vendorPortalAccount.account_number}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendor-portal-accounts.index')" class="breadcrumb-link">Rover Accounts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ vendorPortalAccount.vendorPortalCredential.username }} - {{ vendorPortalAccount.account_number }}</span>
        </nav>
    </Teleport>

    <!-- Page header -->
    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div class="flex-shrink-0">
                <div class="relative">
                    <span class="inline-flex items-center justify-center h-14 w-14 rounded-full bg-gray-400 mr-2">
                        <span class="text-lg font-medium leading-none text-white uppercase"></span>
                    </span>
                </div>
            </div>
            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ vendorPortalAccount.vendorPortalCredential.username }} - {{ vendorPortalAccount.account_number }}
                </h1>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <div v-if="otherUsers.length > 0" class="flex -space-x-2 overflow-hidden mb-1">
                <template v-for="user in otherUsers" :key="user.id">
                    <img v-if="user.avatar_thumbnail_url" :src="user.avatar_thumbnail_url" class="inline-block h-10 w-10 rounded-full ring-2 ring-white" :alt="user.name">
                    <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                    <span class="text-lg font-medium leading-none text-white uppercase">{{ user.first_name[0] }}{{ user.last_name[0] }}</span>
                </span>
                </template>
            </div>

            <loading-button v-if="$can('subscribe', vendorPortalAccount)" :loading="submitting" @click="subscribe" class="btn btn-orange">Subscribe</loading-button>
            <loading-button v-else-if="$can('unsubscribe', vendorPortalAccount)" :loading="submitting" @click="unsubscribe" class="btn btn-blue">Unsubscribe</loading-button>
            <inertia-link v-if="$can('update', vendorPortalAccount)" :href="$route('vendor-portal-accounts.edit', vendorPortalAccount.id)" class="btn btn-gray">Edit</inertia-link>
            <loading-button v-if="$can('archive', vendorPortalAccount)" :loading="archiving" @click="archive" class="btn btn-red">Archive</loading-button>
            <loading-button v-else-if="$can('unarchive', vendorPortalAccount)" :loading="archiving" @click="unarchive" class="btn btn-red">Restore</loading-button>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div v-if="vendorPortalAccount.vendorPortalCredential.vendorPortal.deprecated" class="flex items-start p-3 bg-red-100 border-l-4 border-red-500 text-red-800 lg:col-start-1 lg:col-span-3">
            <icon name="exclamation-triangle" class="h-5 w-5 text-red-500 fill-current mr-2" />
            <p>The portal that this account belongs to has been deprecated.</p>
        </div>

        <div v-if="!vendorPortalAccount.vendorPortalCredential.vendorPortal.enabled && !vendorPortalAccount.vendorPortalCredential.vendorPortal.deprecated" class="flex items-start p-3 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-800 lg:col-start-1 lg:col-span-3">
            <icon name="exclamation-triangle" class="h-5 w-5 text-yellow-500 fill-current mr-2" />
            <p>The portal this account belongs to has been temporarily disabled.</p>
        </div>

        <div v-if="isArchived" class="flex items-start p-3 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-800 lg:col-start-1 lg:col-span-3">
            <icon name="exclamation-triangle" class="h-5 w-5 text-yellow-500 fill-current mr-2" />
            <p>This account has been archived and is no longer gathering invoices. Please click the "restore" button if you wish to activate this account again.</p>
        </div>

        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Account details and records.</p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Status</dt>
                                <dd class="mt-1 text-sm text-gray-900" v-if="!isArchived">{{ vendorPortalAccount.status }}</dd>
                                <dd class="mt-1 text-sm text-gray-900" v-else>Archived</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Rover Credential</dt>
                                <dd class="mt-1 text-sm">
                                    <inertia-link v-if="vendorPortalAccount.vendorPortalCredential" :href="$route('vendor-portal-credentials.show', vendorPortalAccount.vendorPortalCredential.id)" class="link">
                                        {{ vendorPortalAccount.vendorPortalCredential.vendorPortal.vendor_name }} - {{ vendorPortalAccount.vendorPortalCredential.username }}
                                    </inertia-link>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Vendor Account</dt>
                                <dd class="mt-1 text-sm">
                                    <inertia-link v-if="vendorPortalAccount.vendorAccount" :href="$route('vendor-accounts.show', vendorPortalAccount.vendorAccount.id)" class="link">
                                        {{ vendorPortalAccount.vendorAccount.account_number }}
                                    </inertia-link>

                                    <template v-else>
                                        Unmatched
                                    </template>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">{{ vendorPortalAccount.subscribed_at ? 'Subscribed' : 'Unsubscribed' }}</dt>
                                <dd class="mt-1 text-sm">
                                    <span v-if="vendorPortalAccount.subscribed_at">
                                        <span class="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                                            <svg class="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                                              <circle cx="3" cy="3" r="3" />
                                            </svg>
                                            {{ $filters.format_date_time(vendorPortalAccount.subscribed_at) }}
                                        </span>
                                        <span v-if="vendorPortalAccount.subscribedBy">
                                            by {{ vendorPortalAccount.subscribedBy.first_name }} {{ vendorPortalAccount.subscribedBy.last_name }}
                                        </span>
                                    </span>

                                    <template v-else-if="vendorPortalAccount.unsubscribed_at">
                                        <span class="inline-flex items-center gap-x-1.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                                            <svg class="h-1.5 w-1.5 fill-red-500" viewBox="0 0 6 6" aria-hidden="true">
                                              <circle cx="3" cy="3" r="3" />
                                            </svg>
                                            {{ $filters.format_date_time(vendorPortalAccount.unsubscribed_at) }}
                                        </span>
                                        <span v-if="vendorPortalAccount.unsubscribedBy">
                                            by {{ vendorPortalAccount.unsubscribedBy.first_name }} {{ vendorPortalAccount.unsubscribedBy.last_name }}
                                        </span>
                                    </template>

                                    <template v-else>
                                        Not Subscribed Yet
                                    </template>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Last Searched For Invoices At</dt>
                                <dd class="flex items-center mt-1 text-sm">
                                    <span v-if="vendorPortalAccount.last_searched_for_invoices_at">
                                        {{ $filters.format_date_time(vendorPortalAccount.last_searched_for_invoices_at) }}
                                    </span>

                                    <template v-else>
                                        Undiscovered
                                    </template>

                                    <button v-if="$can('rediscover', vendorPortalAccount)" @click="discoverInvoices" type="button" class="ml-2 inline-flex items-center rounded-full border border-transparent bg-d-blue-600 p-1 text-white shadow-sm hover:bg-d-blue-500 focus:outline-none focus:ring-2 focus:ring-d-blue-500 focus:ring-offset-2">
                                        <!-- Font Awesome: sync -->
                                        <Icon name="sync" fill="currentColor" class="h-4 w-4 p-0.5" :class="discovering ? 'spin' : ''" />
                                    </button>
                                </dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Rover Account</dt>
                                <dd class="flex items-center mt-1 text-sm">
                                    <span v-if="vendorPortalAccount.account_number">
                                        {{ vendorPortalAccount.account_number }}
                                    </span>
                                </dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Most Recent Invoice Date</dt>
                                <dd class="flex items-center mt-1 text-sm">
                                    <span v-if="vendorPortalAccount.most_recent_invoice_date">
                                        {{ $filters.format_date(vendorPortalAccount.most_recent_invoice_date) }}
                                    </span>
                                    <span v-else>
                                        N/A
                                    </span>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <!-- Rover Invoices -->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="services-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="services-title" class="text-lg leading-6 font-medium text-gray-900">Invoices</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Select invoices to fetch.</p>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500"><icon name="ban" class="inline fill-current h-4 w-4" /> = gathered invoice file was deleted.</p>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="vendorPortalInvoices.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div class="relative overflow-hidden md:rounded-lg">
                                        <div v-if="selectedInvoices.length > 0" class="absolute bg-white top-1.5 left-12 flex h-8 items-center space-x-3 sm:left-16">
                                            <button v-if="$can('bulkFetch')" type="button" @click="fetchInvoices" class="inline-flex items-center rounded border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-d-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">Fetch</button>
                                        </div>

                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="relative !w-12 !px-6 sm:!w-16 sm:!px-8">
                                                        <input v-if="$can('bulkFetch')" type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :checked="indeterminate || selectedInvoices.length === vendorPortalInvoices.length" :indeterminate="indeterminate" @change="selectedInvoices = $event.target.checked ? vendorPortalInvoices.map((vendorPortalInvoice) => vendorPortalInvoice.id) : []" />
                                                    </th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900 w-auto sm:w-16">PDF</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900 w-auto min-w-32">Invoice #</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900 w-auto">Status</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900 w-auto">Date</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900 w-auto">Amount</th>
                                                    <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6 w-auto sm:w-16">
                                                        <span class="sr-only">View</span>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody class="divide-y divide-gray-200 bg-white">
                                                <tr v-for="vendorPortalInvoice in vendorPortalInvoices.slice(0, 12)" :key="vendorPortalInvoice.id">
                                                    <td class="relative !w-12 !px-6 sm:!w-16 sm:!px-8">
                                                        <template v-if="$can('bulkFetch') && ['Found', 'Fetch Failed'].includes(vendorPortalInvoice.status)">
                                                            <div v-if="selectedInvoices.includes(vendorPortalInvoice.id)" class="absolute inset-y-0 left-0 w-0.5 bg-d-orange-600"></div>
                                                            <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :value="vendorPortalInvoice.id" v-model="selectedInvoices" />
                                                        </template>
                                                    </td>
                                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">
                                                        <a v-if="vendorPortalInvoice.status === 'Fetched' && vendorPortalInvoice.gatheredVendorInvoiceFile" :href="$route('documents.preview', {path: vendorPortalInvoice.gatheredVendorInvoiceFile.document_path})" class="flex items-center text-2xl" target="_blank">
                                                            <icon name="file-pdf" class="inline fill-current text-red-500 hover:text-red-500 w-5 h-5" />
                                                        </a>

                                                        <template v-else-if="vendorPortalInvoice.status === 'Fetched' && !vendorPortalInvoice.gatheredVendorInvoiceFile">
                                                            <icon name="ban" class="fill-current h-5 w-5 mr-3" />
                                                        </template>

                                                        <template v-else-if="fetching.includes(vendorPortalInvoice.id)">
                                                            <icon name="spinner" class="animate-spin fill-current h-5 w-5 mr-3" />
                                                        </template>

                                                        <template v-else>
                                                            -
                                                        </template>
                                                    </td>
                                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">
                                                        <inertia-link class="link" :href="$route('vendor-portal-invoices.show', [vendorPortalInvoice.id])">{{ vendorPortalInvoice.invoice_number || 'N/A' }}</inertia-link>
                                                    </td>
                                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ vendorPortalInvoice.status }}</td>
                                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ $filters.format_date(vendorPortalInvoice.invoice_date) }}</td>
                                                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ $filters.format_money(vendorPortalInvoice.amount) }}</td>
                                                    <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <inertia-link :href="$route('vendor-portal-invoices.show', [vendorPortalInvoice.id])" class="link mr-3">
                                                            View<span class="sr-only">, {{ vendorPortalInvoice.id }}</span>
                                                        </inertia-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="file-alt" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Invoices</h3>
                        </div>
                    </div>

                    <div v-if="vendorPortalInvoices.length">
                        <inertia-link :href="$route('vendor-portal-invoices.index', {search: vendorPortalAccount.account_number})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
    import { ref, inject, computed, onMounted, onUnmounted } from 'vue';
    import { router } from '@inertiajs/vue3';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';

    // Injects
    const route = inject('route');
    const toast = inject('toast');

    const props = defineProps({
        vendorPortalAccount: {
            type: Object,
            required: true
        },

        vendorPortalInvoices: {
            type: Array,
            required: true
        },

        errors: {
            type: Object,
            default: () => {}
        },
    });

    const otherUsers = ref([]);
    const selectedInvoices = ref([]);
    const fetching = ref([]);
    const submitting = ref(false);
    const archiving = ref(false);
    const discovering = ref(false);
    const presenceChannel = `vendor_portal.account.${props.vendorPortalAccount.id}`;
    const mounted = ref(false);

    const isArchived = computed(() => props.vendorPortalAccount.archived_at !== null);

    Echo.join(presenceChannel)
        .here((users) => {
            otherUsers.value = users;
        })
        .joining((user) => {
            otherUsers.value.push(user);
        })
        .leaving((user) => {
            otherUsers.value = otherUsers.value.filter(otherUser => otherUser.id !== user.id);
        })

        // Search Invoices in Account
        .listen('.App\\Events\\VendorPortal\\SearchInvoicesForAccountJobHasStarted', (e) => {
            discovering.value = true;
        })
        .listen('.App\\Events\\VendorPortal\\SearchInvoicesForAccountJobHasFinished', (e) => {
            router.reload({
                preserveScroll: true,
                onSuccess: () => {
                    discovering.value = false;
                    toast.success('Invoice refresh finished');
                },
            });
        })

        // Fetch Invoices
        .listen('.App\\Events\\VendorPortal\\FetchInvoiceJobHasStarted', (e) => {
            if (!fetching.value.includes(e.vendor_portal_invoice_id)) {
                fetching.value.push(e.vendor_portal_invoice_id);
            }
        })
        .listen('.App\\Events\\VendorPortal\\FetchInvoiceJobHasFinished', (e) => {
            if (fetching.value.includes(e.vendor_portal_invoice_id)) {
                fetching.value.splice(fetching.value.indexOf(e.vendor_portal_invoice_id), 1);
            }
            
            router.reload({
                preserveScroll: true,
                only: ['vendorPortalInvoices'],
            });
        });

    onMounted(() => {
        mounted.value = true;
    });

    onUnmounted(() => {
        Echo.leave(presenceChannel);
    });

    // Computed
    const indeterminate = computed(() => {
        return selectedInvoices.value.length > 0 && selectedInvoices.value.length < props.vendorPortalInvoices.length;
    });

    // Methods
    function accountAction(action, route, btnRef) {
        btnRef.value = true;

        router.patch(route, {}, {
            preserveScroll: true,
            onFinish: () => { btnRef.value = false; }
        })
    }

    function subscribe() {
        accountAction('subscribe', route('vendor-portal-accounts.subscribe', props.vendorPortalAccount.id), submitting);
    }

    function unsubscribe() {
        accountAction('unsubscribe', route('vendor-portal-accounts.unsubscribe', props.vendorPortalAccount.id), submitting);
    }

    function archive() {
        accountAction('archive', route('vendor-portal-accounts.archive', props.vendorPortalAccount.id), archiving);
    }

    function unarchive() {
        accountAction('unarchive', route('vendor-portal-accounts.unarchive', props.vendorPortalAccount.id), archiving);
    }

    function fetchInvoices() {
        router.post(route('vendor-portal-invoices.fetch-multiple'), {
            vendor_portal_invoice_ids: selectedInvoices.value,
        }, {
            preserveScroll: true,
            onSuccess: () => {
                selectedInvoices.value = []
                toast.success('Invoices are being fetched.'); // Temporary until a better solution is found
            },
            onError: () => {
                if (props.errors.trial_limits) {
                    toast.error(props.errors.trial_limits);
                }
            }
        });
    }

    function discoverInvoices() {
        router.post(route('vendor-portal-accounts.rediscover-invoices'), {
            vendor_portal_account_id: props.vendorPortalAccount.id,
            vendor_portal_credential_id: props.vendorPortalAccount.vendorPortalCredential.id
        }, {
            preserveScroll: true,
            onError: () => {
                toast.error('An error occurred while discovering invoices.');
            },
            onFinish: () => {
                selectedInvoices.value = [];
            }
        });
    }
</script>