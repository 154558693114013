<template>
    <Head :title="`Rover Credential - ${vendorPortalCredential.username}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendor-portal-credentials.index')" class="breadcrumb-link">Rover Credentials</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ vendorPortalCredential.vendorPortal.vendor_name }} - {{ vendorPortalCredential.username }}</span>
        </nav>
    </Teleport>
        
    <!-- Page header -->
    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div class="flex-shrink-0">
                <div class="relative">
                    <span class="inline-flex items-center justify-center h-14 w-14 rounded-full bg-gray-400 mr-2">
                        <span class="text-lg font-medium leading-none text-white uppercase"></span>
                    </span>
                </div>
            </div>
            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ vendorPortalCredential.vendorPortal.vendor_name }} - {{ vendorPortalCredential.username }}
                </h1>
                <p class="text-sm font-medium text-gray-500">
                    Login URL:
                    <a :href="vendorPortalCredential.vendorPortal.login_url" target="_blank" class="link">
                        {{ vendorPortalCredential.vendorPortal.login_url }}
                    </a>
                </p>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <div v-if="otherUsers.length > 0" class="flex -space-x-2 overflow-hidden mb-1">
                <template v-for="user in otherUsers" :key="user.id">
                    <img v-if="user.avatar_thumbnail_url" :src="user.avatar_thumbnail_url" class="inline-block h-10 w-10 rounded-full ring-2 ring-white" :alt="user.name">
                    <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                    <span class="text-lg font-medium leading-none text-white uppercase">{{ user.first_name[0] }}{{ user.last_name[0] }}</span>
                </span>
                </template>
            </div>

            <template v-if="!portalDeprecated">
                <inertia-link v-if="$can('update', vendorPortalCredential) && credentialValid" :href="$route('vendor-portal-credentials.edit', [vendorPortalCredential.id])" class="btn btn-gray">Edit</inertia-link>
                <button v-if="$can('delete', vendorPortalCredential)" @click="deleteCredential" class="btn btn-red">Delete</button>
                <button v-if="$can('deactivate', vendorPortalCredential) && hasAccounts" @click="deactivateCredential" class="btn btn-red">Deactivate</button>
                <button v-if="$can('activate', vendorPortalCredential)" @click="activateCredential" class="btn btn-blue">Activate</button>
            </template>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">

        <div v-if="credentialInactive" class="flex items-start p-3 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-800 lg:col-start-1 lg:col-span-3">
            <icon name="exclamation-triangle" class="h-5 w-5 text-yellow-500 fill-current mr-2" />
            <p>This credential has been deactivated. Click activate to allow this credential to be searched again.</p>
        </div>

        <div v-if="portalDeprecated" class="flex items-start p-3 bg-red-100 border-l-4 border-red-500 text-red-800 lg:col-start-1 lg:col-span-3">
            <icon name="exclamation-triangle" class="h-5 w-5 text-red-500 fill-current mr-2" />
            <p>This portal has been deprecated. If one hasn't been created already, please create a new credential with the updated portal.</p>
        </div>

        <div v-if="!portalEnabled && !portalDeprecated" class="flex items-start p-3 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-800 lg:col-start-1 lg:col-span-3">
            <icon name="exclamation-triangle" class="h-5 w-5 text-yellow-500 fill-current mr-2" />
            <p>This portal has been temporarily disabled. Once re-enabled, your portal will resume normal operations.</p>
        </div>

        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Credential details and records.</p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div>
                                <dt class="text-sm font-medium text-gray-500">Status</dt>
                                <dd class="inline-flex items-center mt-1 text-sm text-gray-900">
                                    {{ vendorPortalCredential.status }} <Icon v-if="credentialValidating" name="sync" fill="currentColor" class="h-4 w-4 p-0.5 ml-1 spin" />
                                </dd>
                            </div>
                            <div>
                                <dt class="text-sm font-medium text-gray-500">Rover</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendorPortalCredential.vendorPortal.vendor_name }}</dd>
                            </div>
                            <div>
                                <dt class="text-sm font-medium text-gray-500">Username</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendorPortalCredential.username }}</dd>
                            </div>
                            <div>
                                <dt class="text-sm font-medium text-gray-500">Fetch Latest Invoice When Subscribing Accounts?</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendorPortalCredential.fetch_latest_invoice_when_subscribing_account ? 'Yes' : 'No' }}</dd>
                            </div>
                            <div>
                                <dt class="text-sm font-medium text-gray-500">Last Searched For Accounts At</dt>
                                <dd class="flex items-center mt-1 text-sm text-gray-900">
                                    <span v-if="vendorPortalCredential.last_searched_for_accounts_at">
                                        {{ $filters.format_date_time(vendorPortalCredential.last_searched_for_accounts_at) }}
                                    </span>
                                    <template v-else>
                                        Initializing
                                    </template>

                                    <button v-if="canRefreshVendorPortalAccount(vendorPortalCredential)" @click="fetchVendorPortalAccounts()" type="button" class="ml-2 inline-flex items-center rounded-full border border-transparent bg-d-blue-600 p-1 text-white shadow-sm hover:bg-d-blue-500 focus:outline-none focus:ring-2 focus:ring-d-blue-500 focus:ring-offset-2">
                                        <Icon name="sync" fill="currentColor" class="h-4 w-4 p-0.5" :class="fetching ? 'spin' : ''" />
                                    </button>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <section aria-labelledby="timeline-title" class="lg:col-start-3 lg:col-span-1">
            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Summary</h2>

                <dl>
                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                        Subscribed Accounts
                    </dt>
                    <dd class="mt-1 text-3xl leading-9 font-semibold text-gray-900 mb-3">
                        {{ vendorPortalCredential.subscribedAccountsCount }}
                    </dd>

                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                        Unsubscribed Accounts
                    </dt>
                    <dd class="mt-1 text-3xl leading-9 font-semibold text-gray-900 mb-3">
                        {{ vendorPortalCredential.unsubscribedAccountsCount }}
                    </dd>

                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                        Archived Accounts
                    </dt>
                    <dd class="mt-1 text-3xl leading-9 font-semibold text-gray-900 mb-3">
                        {{ vendorPortalCredential.archivedAccountsCount }}
                    </dd>
                </dl>
            </div>
        </section>

        <!-- Rover Accounts !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="vendor-portal-accounts-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="vendor-portal-accounts-title" class="text-lg leading-6 font-medium text-gray-900">Rover Accounts</h2>
                        <p v-if="$can('bulkSubscribe', vendorPortalCredential)" class="mt-1 max-w-2xl text-sm text-gray-500">Select accounts to subscribe/unsubscribe.</p>
                    </div>

                    <template v-if="hasAccounts">
                        <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <div class="flex flex-col">
                                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <div class="relative overflow-hidden md:rounded-lg">
                                            <div v-if="selectedVendorPortalAccounts.length > 0" class="absolute bg-white top-1.5 left-12 flex h-8 items-center space-x-2 sm:left-12">
                                                <button v-if="$can('bulkSubscribe', vendorPortalCredential)" type="button" @click="subscribeSelectedVendorPortalAccounts" class="inline-flex items-center rounded border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-d-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">Subscribe</button>
                                                <button v-if="$can('bulkUnsubscribe', vendorPortalCredential)" type="button" @click="unsubscribeSelectedVendorPortalAccounts" class="inline-flex items-center rounded border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-d-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">Unsubscribe</button>
                                            </div>

                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" class="relative px-7 sm:!w-12 sm:!px-6">
                                                            <input type="checkbox" v-if="portalEnabled && credentialValid" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :checked="indeterminate || selectedVendorPortalAccounts.length === vendorPortalAccounts.length" :indeterminate="indeterminate" @change="selectedVendorPortalAccounts = $event.target.checked ? vendorPortalAccounts.map((vendorPortalAccount) => vendorPortalAccount.id) : []" />
                                                        </th>
                                                        <th scope="col" class="whitespace-nowrap font-semibold text-left text-sm text-gray-900 w-auto min-w-40">Rover Account</th>
                                                        <th scope="col" class="whitespace-nowrap font-semibold text-left text-sm text-gray-900 w-auto">Status</th>
                                                        <th scope="col" class="whitespace-nowrap font-semibold text-left text-sm text-gray-900 w-auto">Most Recent Invoice Date</th>
                                                        <th scope="col" class="whitespace-nowrap font-semibold text-left text-sm text-gray-900 w-auto">Subscribed At</th>
                                                        <th scope="col" class="whitespace-nowrap font-semibold text-left text-sm text-gray-900 w-auto">Vendor Account</th>
                                                        <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6 w-auto sm:w-16">
                                                            <span class="sr-only">Edit</span>
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody class="divide-y divide-gray-200 bg-white">
                                                    <tr v-for="vendorPortalAccount in vendorPortalAccounts.slice(0, 12)" :key="vendorPortalAccount.id" :class="{'italic': vendorPortalAccount.archived_at}">
                                                        <td class="relative px-7 sm:!w-12 sm:!px-6">
                                                            <template v-if="credentialValid && portalEnabled && !vendorPortalAccount.archived_at">
                                                                <div v-if="selectedVendorPortalAccounts.includes(vendorPortalAccount.id)" class="absolute inset-y-0 left-0 w-0.5 bg-d-orange-600"></div>
                                                                <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :value="vendorPortalAccount.id" v-model="selectedVendorPortalAccounts" />
                                                            </template>
                                                        </td>

                                                    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{ vendorPortalAccount.account_number }}</td>
                                                    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                        <template v-if="searching === vendorPortalAccount.id">
                                                            <Icon name="sync" fill="currentColor" class="h-4 w-4 p-0.5 spin" />
                                                        </template>
                                                        <template v-else-if="vendorPortalAccount.archived_at">
                                                            Archived
                                                        </template>
                                                        <template v-else>
                                                            {{ vendorPortalAccount.status }}
                                                        </template>
                                                    </td>

                                                        <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                            <template v-if="vendorPortalAccount.most_recent_invoice_date">
                                                                {{ $filters.format_date(vendorPortalAccount.most_recent_invoice_date) }}
                                                            </template>

                                                            <template v-else>
                                                                -
                                                            </template>
                                                        </td>


                                                        <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                            <template v-if="vendorPortalAccount.subscribed_at">
                                                                {{ $filters.format_date_time(vendorPortalAccount.subscribed_at) }}
                                                            </template>

                                                            <template v-else>
                                                                Not Subscribed
                                                            </template>
                                                        </td>

                                                        <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                            <template v-if="vendorPortalAccount.vendorAccount">
                                                                <inertia-link :href="$route('vendor-accounts.show', vendorPortalAccount.vendorAccount.id)" class="link">{{ vendorPortalAccount.vendorAccount.account_number }}</inertia-link>
                                                            </template>

                                                            <template v-else>
                                                                Unmatched
                                                            </template>
                                                        </td>

                                                        <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                            <inertia-link :href="$route('vendor-portal-accounts.show', vendorPortalAccount.id)" class="link">View</inertia-link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <inertia-link :href="$route('vendor-portal-accounts.index', {search: vendorPortalCredential.id})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                        </div>
                    </template>
                    <template v-else>
                        <div class="p-12">
                            <div v-if="!fetching" class="text-center">
                                <icon name="info-circle" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>
                                <h3 class="mt-2 text-sm font-semibold text-gray-900">No Accounts Found</h3>
                                <p class="mt-1 text-sm text-gray-500">Please make sure the credential is valid and has accounts associated with it.</p>
                            </div>
                            <div v-else class="text-center">
                                <Icon name="sync" class="mx-auto h-8 w-8 text-gray-400 spin fill-current"/>
                                <h3 class="mt-4 text-lg font-semibold text-gray-900">Searching for accounts...</h3>
                            </div>
                        </div>
                    </template>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
    import {ref, inject, computed, onMounted, onUnmounted, getCurrentInstance} from 'vue';
    import { router } from '@inertiajs/vue3';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';

    // Injects
    const route = inject('route');
    const toast = inject('toast');

    const page = getCurrentInstance();

    // Props
    const props = defineProps({
        vendorPortalCredential: {
            type: Object,
            required: true
        },

        vendorPortalAccounts: {
            type: Array,
            required: true
        },
    });

    // State
    const mounted = ref(false);
    const otherUsers = ref([]);
    const checked = ref(false);
    const selectedVendorPortalAccounts = ref([]);
    const authenticating = ref(false);
    const fetching = ref(false);
    const presenceChannel = `vendor_portal.credential.${props.vendorPortalCredential.id}`;

    onMounted(() => {
        mounted.value = true;
    });

    // Load
    Echo.join(presenceChannel)
        .here((users) => {
            otherUsers.value = users;
        })
        .joining((user) => {
            otherUsers.value.push(user);
        })
        .leaving((user) => {
            otherUsers.value = otherUsers.value.filter(otherUser => otherUser.id !== user.id);
        })

        // Authentication Events
        .listen('.App\\Events\\VendorPortal\\AuthenticateWithVendorPortalJobHasStarted', (e) => {
            authenticating.value = true;
        })
        .listen('.App\\Events\\VendorPortal\\AuthenticateWithVendorPortalJobHasFinished', (e) => {
            router.reload({
                preserveScroll: true,
                only: ['vendorPortalCredential'],
                onSuccess: () => {
                    authenticating.value = false;
                    toast.success('Authentication finished');
                },
            });
        })

        // Account Events
        .listen('.App\\Events\\VendorPortal\\SearchAccountsForCredentialJobHasStarted', (e) => {
            fetching.value = true;
        })
        .listen('.App\\Events\\VendorPortal\\SearchAccountsForCredentialJobHasFinished', (e) => {
            router.reload({
                preserveScroll: true,
                onSuccess: () => {
                    fetching.value = false;
                    toast.success('Account refresh finished');
                },
            });
        });

    // Unload
    onUnmounted(() => {
        Echo.leave(presenceChannel);
    });

    // Computed
    const indeterminate = computed(() => {
        return selectedVendorPortalAccounts.length > 0 && selectedVendorPortalAccounts.length < props.vendorPortalAccounts.length;
    });

    const credentialValid = computed(() => {
        return props.vendorPortalCredential.status === 'Valid';
    });

    const credentialInactive = computed(() => {
        return props.vendorPortalCredential.status === 'Inactive';
    });

    const portalEnabled = computed(() => {
        return props.vendorPortalCredential.vendorPortal.enabled;
    });

    const portalDeprecated = computed(() => {
        return props.vendorPortalCredential.vendorPortal.deprecated;
    });

    const hasAccounts = computed(() => {
        return props.vendorPortalAccounts.length > 0;
    });

    const hasNoAccounts = computed(() => {
        return !hasAccounts.value;
    });

    const credentialValidating = computed(() => {
        return props.vendorPortalCredential.status === 'Validating';
    });

    // Methods
    function subscribeSelectedVendorPortalAccounts() {
        router.post(route('vendor-portal-accounts.subscribe-accounts'), {
            vendor_portal_account_ids: selectedVendorPortalAccounts.value,
        }, {
            onSuccess: () => selectedVendorPortalAccounts.value = []
        });
    }

    function unsubscribeSelectedVendorPortalAccounts() {
        router.post(route('vendor-portal-accounts.unsubscribe-accounts'), {
            vendor_portal_account_ids: selectedVendorPortalAccounts.value,
        }, {
            onSuccess: () => selectedVendorPortalAccounts.value = []
        });
    }

    function deleteCredential() {
        if (window.confirm('Are you sure you want to delete this credential? This cannot be undone.')) {
            router.delete(route('vendor-portal-credentials.destroy', props.vendorPortalCredential.id), {
                onSuccess: (response) => {
                    toast.success('Rover Credential deleted successfully.');
                },
                onError(response) {
                    toast.error('An error occurred while deleting the credential.');
                }
            });
        }
    }

    function deactivateCredential() {
        if (window.confirm('Deactivating this credential will auto-unsubscribe any associated Rover Accounts and no longer fetch invoices. Do you wish to continue?')) {
            router.put(route('rover-credentials.deactivate', props.vendorPortalCredential.id), {id: props.vendorPortalCredential.id});
        }
    }

    function activateCredential() {
        router.put(route('rover-credentials.activate', props.vendorPortalCredential.id), {id: props.vendorPortalCredential.id});
    }

    function fetchVendorPortalAccounts() {
        router.post(route('vendor-portal-credentials.rediscover-accounts'), {
            vendor_portal_credential_id: props.vendorPortalCredential.id,
        }, {
            onError() {
                toast.error('An error occurred while fetching accounts.');
            },
            onFinish: () => {
                fetching.value = true;
                selectedVendorPortalAccounts.value = [];
            }
        });
    }

    function canRefreshVendorPortalAccount(vendorPortalCredential) {
      let notAllowedStatuses = [
        'Validating', 'Authentication Failed - Invalid Credentials', 'Vendor Portal Deprecated', 'Inactive'
      ];

      return vendorPortalCredential.vendorPortal.enabled
          && ! notAllowedStatuses.includes(vendorPortalCredential.status);
    }
</script>