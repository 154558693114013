<template>
    <Head title="Billing Dashboard" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Billing Dashboard</span>
        </nav>
    </Teleport>

    <div class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="billing-dashboard" />

                <div class="lg:col-span-9 pt-5">

                    <div class="sm:px-6">
                        <div class="flex justify-between items-start">
                            <div>
                                <h2 class="text-lg leading-6 font-medium text-gray-900">Current Plan</h2>
                                <h3 class="text-3xl mt-1">
                                    <span class="uppercase">Gather</span> (Commitment)
                                </h3>
                            </div>

                            <div class="flex flex-col justify-end">
                                <button @click="stripeCustomerPortal" class="btn btn-orange">
                                    Manage Billing <icon name="external-link-alt" class="inline fill-current text-white h-4 ml-2" />
                                </button>
                            </div>
                        </div>

                        <div class="mt-8">
                            <div class="relative pt-1">
                                <div class="flex mb-2 items-center justify-between">
                                    <div>
                                        <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                                            Usage
                                        </span>
                                    </div>
                                    <div class="text-right">
                                        <span v-if="props.currentCycleUsage <= props.currentPrePurchaseAmount" class="text-xs font-semibold inline-block text-blue-600">
                                            {{ props.currentCycleUsage }} / {{ props.currentPrePurchaseAmount }}
                                        </span>
                                        <span v-else class="text-xs font-semibold inline-block text-red-600">
                                           {{ props.currentCycleUsage }} / {{ props.currentPrePurchaseAmount }}
                                        </span>
                                    </div>
                                </div>
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                                    <div :style="{ width: percentUsed + '%' }" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
                                </div>
                                <div class="flex justify-between text-s text-gray-600">
                                    <div>
                                        Current billing cycle ends on {{ props.currentBillingCycleEndsOn }}
                                    </div>

                                    <div>
                                        Subscription ends on {{ props.subscriptionEndsOn }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="mt-10 flex items-center gap-x-4">
                                <h4 class="flex-none text-sm font-semibold leading-6 text-d-blue-600">Available Plans</h4>
                                <div class="h-px flex-auto bg-gray-100" />
                            </div>

                            <div v-for="(details, plan) in plans" :key="plan" class="flex flex-col p-8 border md:border-0 md:ring-1 md:ring-gray-900/10 md:shadow-md md:rounded-md mt-8 mb-16" :class="plan === 'Gather' ? 'ring-d-orange-500/50' : ''">
                                <div class="flex flex-col items-center justify-between md:flex-row cursor-pointer"  @click="expandPlan(plan)">
                                    <div class="w-full md:w-auto md:mr-4 space-y-2">
                                        <h3 class="text-3xl font-bold uppercase" :class="plan === 'Gather' ? 'text-d-orange-500' : ''">{{ plan }}</h3>
                                        <p class="p-0 m-0 text-sm leading-6 text-gray-600">{{ details.description }}</p>
                                    </div>
                                    <div class="w-full md:w-1/2 flex items-center justify-between space-x-4">
                                        <ul class="mt-1 space-y-1">
                                            <li v-for="(name, key) in details.features" class="flex items-center rounded-full text-xs font-medium text-gray-800">
                                                <icon name="check" v-if="features[key]" class="inline fill-current text-green-600 w-3 h-3 mr-2" />
                                                <icon name="lock" v-else class="inline fill-current text-red-600 w-3 h-3 mr-2" />
                                                {{ name }}
                                            </li>
                                        </ul>
                                        <div class="flex-shrink-0 mx-8 lg:mx-0">
                                            <div v-if="!details.pricing.length" class="link text-sm underline">
                                                <a href="https://www.discoveryapp.io/request-a-demo" target="_blank">Contact Sales</a>
                                            </div>
                                            <div v-else>
                                                <div class="flex items-center justify-center bg-gray-100 rounded-full w-10 h-10">
                                                    <icon v-if="openPlan === plan" name="chevron-down" class="inline fill-current text-gray-500 h-6" />
                                                    <icon v-else name="chevron-right" class="inline fill-current text-gray-500 h-6" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="details.pricing.length && openPlan === plan">
                                    <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl">
                                        <div class="rounded-3xl p-8 ring-1 ring-gray-200" v-for="catalogItem in details.pricing" :key="catalogItem.id">
                                            <h3 id="tier-hobby" class="text-lg font-semibold leading-8 text-gray-900">
                                                {{ catalogItem.tier }}
                                            </h3>
                                            <p class="mt-4 text-sm leading-6 text-gray-600">
                                                {{ JSON.parse(catalogItem.display_settings).description }}
                                            </p>
                                            <p class="mt-6 flex items-baseline gap-x-1">
                                                <!-- Price, update based on frequency toggle state -->
                                                <span class="text-4xl font-bold tracking-tight text-gray-900">
                                                    ${{ $filters.format_decimal(catalogItem.base_price, 0) }}
                                                </span>
                                                <!-- Payment frequency, update based on frequency toggle state -->
                                                <span v-if="catalogItem.frequency === 'Monthly'" class="text-sm font-semibold leading-6 text-gray-600">/{{ catalogItem.frequency }}</span>
                                                <span v-else class="text-sm font-semibold leading-6 text-gray-600"> To Get Started</span>
                                            </p>
                                            <span v-if="catalogItem.frequency === 'Monthly'" class="text-xs">
                                                Annual contract
                                            </span>
                                            <span v-else class="text-xs">
                                                No contract
                                            </span>
                                            <button type="button" v-if="tierIsUpgrade(catalogItem, details.pricing)" @click="upgradeSubscription(catalogItem.tier, catalogItem.id)" aria-describedby="tier-hobby" class="appearance-none mt-6 block w-full rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-d-orange-600 text-d-orange-600 ring-1 ring-inset ring-d-orange-200 hover:ring-d-orange-300 hover:bg-d-orange-100">
                                                Upgrade
                                            </button>
                                            <div v-if="tierIsDowngrade(catalogItem, details.pricing)" class="appearance-none mt-6 block w-full rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-gray-600 ring-1 ring-inset ring-gray-200">
                                                Not Available
                                            </div>
                                            <div v-if="tierIsCurrent(catalogItem)" class="appearance-none mt-6 block w-full rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-gray-600 ring-1 ring-inset ring-gray-200">
                                                Current Plan
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    /** @TODO logic below needs simplified, we aren't a trail user... we don't need to worry about that stuff **/

    import {ref, onMounted, inject, getCurrentInstance, onUnmounted, computed} from 'vue';

    // Components
    import {Head, router, usePage} from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SubNav from '../SubNav.vue';

    const page = usePage();
    const instance = getCurrentInstance();
    const toast = inject('toast');
    const route = inject('route');

    // Props
    const props = defineProps({
        tenant: Object,
        features: Object,
        plans: Object,
        pricingPlans: Object,
        billingConfig: Object,
        restrictions: Object,
        currentCycleUsage: Number,
        currentPrePurchaseAmount: Number,
        currentBillingCycleEndsOn: Object,
        subscriptionEndsOn: Object,
        errors: {
            type: Object,
            default: () => ({})
        },
    });

    // State
    const mounted = ref(false);
    const saving = ref(false);
    const openPlan = ref('');

    // sort features by true/false value, then by name
    const sortedFeatures = Object.fromEntries(
        Object.entries(props.features).sort(([,a],[,b]) => b-a)
    );

    Echo.join(`billing_dashboard.${props.billingConfig.tenant_id}`)
        .listen('.App\\Events\\TenantBilling\\UpgradeSubscriptionJobHasFinished', () => {
            router.reload({
                preserveScroll: true,
                onSuccess: () => {
                    saving.value = false;
                    toast.success('Your subscription has been upgraded.');
                },
            })
        });

    onMounted(() => {
        expandPlan('Gather');

        mounted.value = true;
    });

    onUnmounted(() => {
        Echo.leave(`billing_dashboard.${props.billingConfig.tenant_id}`);
    });

    // Comparison
    // NOTE: These may need some work, including the buttons themselves to use the appropriate route
    function tierIsCurrent(catalogItem) {
        return props.billingConfig.tenant_billing_catalog_id === catalogItem.id;
    }
    function tierIsDowngrade(catalogItem, catalogItems) {
        let currentCatalogItem = catalogItems.find(item => item.id === props.billingConfig.tenant_billing_catalog_id) ?? 0;

        return currentCatalogItem.base_price > catalogItem.base_price;
    }
    function tierIsUpgrade(catalogItem, catalogItems) {
        let currentCatalogItem = catalogItems.find(item => item.id === props.billingConfig.tenant_billing_catalog_id) ?? 0;

        return currentCatalogItem.base_price < catalogItem.base_price;
    }

    // Toggles
    function expandPlan(plan) {
        if(openPlan.value === plan) {
            openPlan.value = '';
        } else {
            openPlan.value = plan;
        }
    }

    // Buttons
    function upgradeSubscription(tier, pricingId) {
        let confirmed = confirm('Are you sure you want to upgrade?  The balance due will be prorated on your following invoice.');

        if (confirmed) {
            router.post(route('tenant-settings.subscriptions.upgrade', pricingId));
        }
    }

    function stripeCustomerPortal() {
        router.post(route('tenant-settings.subscriptions.portal'));
    }

    // Formatting
    function featureName(feature) {
        return toTitleCase(feature.replace(/_/g, ' '));
    }

    function toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
            }
        );
    }

    const percentUsed = computed(() => {
        let percentage =  (props.currentCycleUsage / props.currentPrePurchaseAmount) * 100;

        if (percentage <= 0) {
            return 0;
        }

        if (percentage >= 100) {
            return 100;
        }

        return percentage;
    });
</script>
