<template>
    <Head :title="`Edit ${service.id}`"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="data.mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>
            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>
            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>
            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>
            <inertia-link :href="$route('services.show', service.id)" class="breadcrumb-link">{{ service.id }}</inertia-link>
            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>
            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="mx-auto text-base lg:text-lg">

        <div class="flex flex-col gap-12 lg:flex-row">

            <div class="flex-grow px-4 py-8 sm:px-6 lg:px-8">
                <div v-if="data.currentStep === 1" id="type">
                    <div class="font-medium text-xl">{{ steps[data.currentStep].description }}</div>

                    <div class="grid gap-x-4 gap-y-6 grid-cols-3 my-4">
                        <div class="col-span-3 md:col-span-1 relative rounded-lg border bg-white px-6 py-5 flex items-center space-x-3 hover:opacity-100 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-d-orange-500" :class="[data.who === 'vendor' ? 'border-d-orange-500 opacity-100 shadow-lg' : 'border-gray-400 opacity-80']">
                            <div class="flex-shrink-0">
                                <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-d-blue-500 mr-2">
                                    <span class="text-lg font-medium leading-none text-white uppercase">{{ service.vendor?.name.substring(0,2) }}</span>
                                </span>
                            </div>

                            <div class="flex-1 min-w-0 text-base">
                                <a @click.prevent="selectRequester('vendor')" href="#" class="focus:outline-none">
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                    <p class="font-medium text-gray-900">{{ service.vendor?.name ?? 'Vendor' }}</p>
                                    <p class="text-gray-500">The vendor requested changes to service.</p>
                                </a>
                            </div>
                        </div>

                        <div class="col-span-3 md:col-span-1 relative rounded-lg border bg-white px-6 py-5 flex items-center space-x-3 hover:opacity-100 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-d-orange-500" :class="[data.who === 'client' ? 'border-d-orange-500 opacity-100 shadow-lg' : 'border-gray-400 opacity-80']">
                            <div class="flex-shrink-0">
                                <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-d-blue-500 mr-2">
                                    <span class="text-lg font-medium leading-none text-white uppercase">{{ service.location?.clientCompany?.name.substring(0,2) }}</span>
                                </span>
                            </div>
                            <div class="flex-1 min-w-0 text-base">
                                <a @click.prevent="selectRequester('client')" href="#" class="focus:outline-none">
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                    <p class="font-medium text-gray-900">{{ service.location?.clientCompany?.name ?? 'Client' }}</p>
                                    <p class="text-gray-500">The client requested changes to service.</p>
                                </a>
                            </div>
                        </div>

                        <div class="col-span-3 md:col-span-1 relative rounded-lg border bg-white px-6 py-5 flex items-center space-x-3 hover:opacity-100 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-d-orange-500" :class="[data.who === 'broker' ? 'border-d-orange-500 opacity-100 shadow-lg' : 'border-gray-400 opacity-80']">
                            <div class="flex-shrink-0">
                                <img
                                    v-if="current_tenant_thumbnail_url"
                                    :src="current_tenant_thumbnail_url"
                                    class="inline border border-gray-400 rounded-full mr-2 h-8 w-8"
                                    alt="Tenant Thumbnail"
                                />
                                <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-d-blue-500 mr-2">
                                    <span class="text-lg font-medium leading-none text-white uppercase">{{ page?.props?.authUser?.tenant_name.substring(0,2) }}</span>
                                </span>
                            </div>
                            <div class="flex-1 min-w-0 text-base">
                                <a @click.prevent="selectRequester('broker')" href="#" class="focus:outline-none">
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                    <p class="font-medium text-gray-900">{{ $page?.props?.authUser?.tenant_name ?? 'Our Company' }}</p>
                                    <p class="text-gray-500">Our company is proposing the change.</p>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div v-if="data.who">
                        <div v-if="data.who === 'vendor'" class="font-medium text-xl pt-6">
                            What type of change is the vendor <strong>{{ service?.vendor?.name ?? '' }}</strong> performing?
                        </div>

                        <div v-if="data.who === 'client'" class="font-medium text-xl pt-6">
                            What type of change is the client <strong>{{ service?.location?.clientCompany?.name ?? '' }}</strong> requesting?
                        </div>

                        <div v-if="data.who === 'broker'" class="font-medium text-xl pt-6">
                            What type of change are <strong>you</strong> entering?
                        </div>

                        <div class="my-4">
                            <ul role="list" class="divide-y divide-gray-200">
                                <li class="group flex justify-between items-center p-4 cursor-pointer hover:bg-gray-50" v-for="typeId in allowedChangeTypes"  @click="selectType(typeId)">
                                    <div class="flex flex-col">
                                        <div class="font-medium group-hover:text-d-orange-500">
                                            {{ changeTypes[typeId].title }}
                                        </div>
                                        <p class="text-base text-gray-500">{{ changeTypes[typeId].description }}</p>
                                    </div>
                                    <div>
                                        <icon name="chevron-right" class="size-5 fill-current text-gray-400 group-hover:text-d-orange-500"/>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- wizarding step 2 -->
                <div v-if="data.currentStep === 2" class="max-w-screen-lg mx-auto">
                    <div class="mb-8">
                        <button @click="returnToStart()" type="button" class="btn btn-gray gap-x-1">
                            <icon name="chevron-left" class="size-3 fill-current" /> Change Type
                        </button>
                    </div>

                    <div class="font-medium text-xl mb-8">
                        <template v-if="data.who === 'broker'">
                            <strong>You</strong> are proposing a <strong>{{ requestedChangeType() }}</strong> for this {{ service.type }} service. Please fill in the details.
                        </template>
                        <template v-else>
                            The <strong>{{ data.who }}</strong> is requesting a <strong>{{ requestedChangeType() }}</strong> for this {{ service.type }} service. Please fill in the details.
                        </template>
                    </div>

                    <client-billing-change
                        v-if="data.change_type === 'client-billing-change'"
                        :client-billing-frequencies="clientBillingFrequencies"
                        :errors="errors"
                        :service="service"
                        :who="data.who"
                        :earliest-service-activity="earliestServiceActivity"
                    />

                    <client-contract-change
                        v-if="data.change_type === 'client-contract-change'"
                        :client-billing-frequencies="clientBillingFrequencies"
                        :client-contracts="initialAvailableClientContracts"
                        :errors="errors"
                        :service="service"
                        :who="data.who"
                        :earliest-service-activity="earliestServiceActivity"
                    />

                    <level-of-service-change
                        v-if="data.change_type === 'level-of-service-change'"
                        :who="data.who"
                        :vendor-accounts="initialAvailableVendorAccounts"
                        :vendor-contracts="initialAvailableVendorContracts"
                        :client-contracts="initialAvailableClientContracts"
                        :bin-types="binTypes"
                        :material-types="materialTypes"
                        :asset-owners="assetOwners"
                        :service="service"
                        :errors="errors"
                        :earliest-service-activity="earliestServiceActivity"
                    />


                    <pickup-day-change
                        v-if="data.change_type === 'pickup-day-change'"
                        :errors="errors"
                        :service="service"
                        :who="data.who"
                        :earliest-service-activity="earliestServiceActivity"
                    />

                    <terminating-service
                        v-if="data.change_type === 'terminating-service'"
                        :errors="errors"
                        :service="service"
                        :who="data.who"
                        :earliest-service-activity="earliestServiceActivity"
                    />

                    <vendor-account-change
                        v-if="data.change_type === 'vendor-account-change'"
                        :errors="errors"
                        :service="service"
                        :vendor-accounts="initialAvailableVendorAccounts"
                        :who="data.who"
                        :earliest-service-activity="earliestServiceActivity"
                    />

                    <vendor-contract-change
                        v-if="data.change_type === 'vendor-contract-change'"
                        :errors="errors"
                        :vendor-contracts="initialAvailableVendorContracts"
                        :service="service"
                        :who="data.who"
                        :earliest-service-activity="earliestServiceActivity"
                    />

                    <vendor-pricing-change
                        v-if="data.change_type === 'vendor-pricing-change'"
                        :errors="errors"
                        :service="service"
                        :who="data.who"
                        :earliest-service-activity="earliestServiceActivity"
                    />
                </div> <!-- end step 2-->
            </div>
        </div>
    </div>
</template>

<script setup>
    /**
     * This page is a Wizard to help user along with service changes
     */
    import Icon from '@/Shared/Icon.vue';
    import ClientBillingChange from './Edit/ClientBillingChange.vue';
    import ClientContractChange from './Edit/ClientContractChange.vue';
    import LevelOfServiceChange from './Edit/LevelOfServiceChange.vue';
    import PickupDayChange from './Edit/PickupDayChange.vue';
    import TerminatingService from './Edit/TerminatingService.vue';
    import VendorAccountChange from './Edit/VendorAccountChange.vue';
    import VendorContractChange from './Edit/VendorContractChange.vue';
    import VendorPricingChange from './Edit/VendorPricingChange.vue';
    import {reactive, inject, computed, onMounted} from 'vue';
    import {Head, usePage} from '@inertiajs/vue3';

    /**
     * Props
     */
    const props = defineProps({
        assetOwners: {
            type: Array,
            required: true
        },

        clientBillingFrequencies: {
            type: Array,
            required: true
        },

        binTypes: {
            type: Array,
            required: true
        },

        clientServiceFeeFrequencies: {
            type: Array,
            required: true
        },

        earliestServiceActivity: {
            type: Object,
            required: true
        },

        initialAvailableVendorAccounts: {
            type: Array,
            default: []
        },

        initialAvailableVendorContracts: {
            type: Array,
            default: []
        },

        initialAvailableClientContracts: {
            type: Array,
            default: []
        },

        initialClients: {
            type: Array,
            default: []
        },

        locations: {
            type: Array,
            default: []
        },

        lineItemCategories: {
            type: Array,
            required: true
        },

        materialTypes: {
            type: Array,
            required: true
        },

        errors: {
            type: Object,
            default: () => ({})
        },

        service: {
            type: Object,
            required: true
        },

        serviceAccountTypes: {
            type: Array,
            required: true
        },

        serviceScheduleTypes: {
            type: Array,
            required: true
        }
    });

    /**
     * Injected
     */
    const route = inject('route');
    const toast = inject('toast');
    const page = usePage();

    onMounted(() => {
        if(window.location.hash) {
            let splitHash = window.location.hash.substring(1).split('|');

            if(Object.keys(changeTypes).includes(splitHash[1])) {
                selectType(splitHash[1], splitHash[0]);
            } else {
                selectRequester(splitHash[0]);
            }
        }

        data.mounted = true;
    });

    /**
     * Data
     */
    const data = reactive({
        currentStep: 1,
        who: '',
        change_type: '',
        mounted: false
    });

    const steps = reactive({
        1: {
            description: 'Who has requested this change?'
        },
        2: {
            description: 'What is the change?'
        }
    });

    const changeTypes = reactive( {
        'client-billing-change': {
            title: 'Client Billing Change',
            description: 'Example: The new price is charged to the client or billing frequency is changing.',
            allowedToChange: ['broker'],
            requirements: page.props.permissions.accessRevenueManagement,
        },
        'client-contract-change': {
            title: 'Client Contract Change',
            description: 'Example: terms have been re-negotiated between us and client.',
            allowedToChange: ['broker', 'client'],
            requirements: page.props.permissions.accessRevenueManagement,
        },
        'level-of-service-change': {
            title: 'Level of Service Change',
            description: 'Example: more/less trash is being disposed.',
            allowedToChange: ['client', 'vendor'],
            requirements: null,
        },
        'pickup-day-change': {
            title: 'Pickup Days Change',
            description: 'Example: A waste hauler is picking trash up on Tuesday instead of Monday now.',
            allowedToChange: ['client', 'vendor'],
            requirements: props.service.serviceType?.enabled_type_fields.includes('service_schedule_info') && props.service.service_schedule_type === 'Scheduled'
        },
        'terminating-service': {
            title: 'Terminating Service',
            sentenceTerm: 'service termination change',
            description: 'Example: Service terminates next month',
            allowedToChange: ['client', 'vendor', 'broker'],
            requirements: null,
        },
        'vendor-account-change': {
            title: 'Vendor Account Change',
            description: 'Example: the vendor has changed the account number for this service',
            allowedToChange: ['client', 'vendor', 'broker'],
            requirements: props.service.has_vendor_account,
        },
        'vendor-contract-change': {
            title: 'Vendor Contract Change',
            description: 'Example: contract for this service has been re-negotiated.',
            allowedToChange: ['client', 'vendor', 'broker'],
            requirements: null,
        },
        'vendor-pricing-change': {
            title: 'Vendor Pricing Change',
            description: 'Example: vendor wants to charge more/less for this service',
            allowedToChange: ['vendor'],
            requirements: null,
        }
    });

    /**
     * Computed
     */
    const current_tenant_thumbnail_url = computed(() => {
        return page.props
            .authUser
            .employeesTenants
            .filter(tenant => tenant.id === page.props.authUser.current_tenant_id)
            .brand_mark_thumbnail_url ?? '';
    });

    const allowedChangeTypes = computed(() => {
        let allowedByRequester = Object.keys(changeTypes).filter(type => changeTypes[type].allowedToChange.includes(data.who));
        let allowedByRequirement = Object.keys(changeTypes).filter(type => changeTypes[type].requirements === null || changeTypes[type].requirements === true);

        return allowedByRequester.filter(type => allowedByRequirement.includes(type));
    });

    /**
     * Methods
     */
    function returnToStart() {
        data.currentStep = 1;
        window.location.hash = 'type';
    }

    function selectRequester(who) {
        data.who = who;
        window.location.hash = who;
    }

    function selectType(typeId, whoFromHash = null) {
        if(!validateRequirements(typeId)) {
            toast.error('An error occurred attempting to select the change type. Please try again.');
            returnToStart();
            return;
        }

        if(whoFromHash) {
            data.who = whoFromHash;
        }

        data.change_type = typeId;
        data.currentStep = 2;
        window.location.hash = data.who + '|' + typeId;
    }

    function requestedChangeType() {
        return changeTypes[data.change_type].sentenceTerm ?? changeTypes[data.change_type].title.toLowerCase();
    }

    function validateRequirements(typeId) {
        if(changeTypes[typeId].requirements === null) {
            return true;
        }

        return changeTypes[typeId].requirements;
    }
</script>
