<template>
    <Head title="Reports" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Reports</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Standard Reports</span>
        </nav>
    </Teleport>

	<horizontal-sub-nav current-tab="standard_reports" />

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="rounded-md bg-blue-50 p-4 my-3">
        <div class="flex">
            <div class="flex-shrink-0">
                <!-- Heroicon name: solid/information-circle -->
                <svg class="h-5 w-5 text-d-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-sm text-d-blue-500">Please note you may view your CSV data exports <a :href="$route('user-settings.data-exports.index')">here</a>.</p>
                <p class="mt-3 text-sm md:mt-0 md:ml-6">
                    <a :href="$route('user-settings.data-exports.index')" class="whitespace-nowrap font-medium text-d-blue-500 hover:text-blue-600">Details <span aria-hidden="true">&rarr;</span></a>
                </p>
            </div>
        </div>
    </div>

    <div v-if="$page.props.permissions.accessSustainabilityReporting" class="bg-white px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-2">
            Environmental &amp; Sustainability
        </h3>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.gresb')" class="">Gresb</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Landfill, recycling, hazardous and non hazardous disposal amounts for locations over time in the <a class="link" href="https://gresb.com/" target="_blank">GRESB</a> format</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.energy-star-properties')" class="">EnergyStar Properties</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Property locations to upload to <a class="link" href="https://portfoliomanager.energystar.gov/pm/login.html" target="_blank">EnergyStar portfolio</a>. Each location in Discovery is treated as a property for the EnergyStar custom template bulk property uploader.</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.energy-star-meters')" class="">EnergyStar Meters</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Meters to upload to <a class="link" href="https://portfoliomanager.energystar.gov/pm/login.html" target="_blank">EnergyStar portfolio</a>. Each service in Discovery is treated as a meter for the EnergyStar custom template bulk meter uploader.</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.energy-star-meter-usage')" class="">EnergyStar Meter Usage</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Meter usage to upload to <a class="link" href="https://portfoliomanager.energystar.gov/pm/login.html" target="_blank">EnergyStar portfolio</a>. The monthly activity for each service in Discovery is used for the custom template bulk meter bills uploader.</p>
                </div>
            </div>
        </div>
    </div>

    <div v-if="$page.props.permissions.accessRevenueManagement" class="bg-white px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-2">
            Revenue &amp; Clients
        </h3>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.client-invoices-summary')" class="">Client Invoices Summary</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Your invoices to your Clients, ordered by date</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.client-invoices-with-associated-vendor-invoices')" class="">Client Invoices with Associated Vendor Invoices</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Shows the relationship of a client invoice to any vendor invoice(s) that originated a charge contained on the client invoice.</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.client-line-item-details')" class="">Client Line Item Details</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Shows detailed information about individual Client Line Items.</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.sales-tax-liabilities')" class="">Sales Tax Liabilities</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Shows detailed information about sales taxes charged to your clients.</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.profit-margin-by-client-company', {'fiscal_start': lastMonth, 'fiscal_end': lastMonth})" class="">Profit Margin by Client Company</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Shows revenue, vendor costs, and profit, broken down by Client Company.</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.profit-margin-by-location', {'fiscal_start': lastMonth, 'fiscal_end': lastMonth})" class="">Profit Margin by Location</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Shows revenue, vendor costs, and profit, broken down by Location.</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.profit-margin-by-service', {'fiscal_start': lastMonth, 'fiscal_end': lastMonth})" class="">Profit Margin by Service</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Shows revenue, vendor costs, and profit, broken down by Service.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-white px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-2">
            Weight, Volume, & Cost
        </h3>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.client-weight-by-service-and-month')" class="">Client Weight by Service and Month</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Client Weight by Service and Month</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.compactor-monitor-efficiency-improvement')" class="">Compactor Monitor Efficiency Improvement</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Savings from hauls avoided due to compactor monitor installations.</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.contract-savings-by-service')" class="">Contract Savings by Service</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Your savings from contract changes by service</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.monitor-impact-by-work-order')" class="">Monitor Impact by Work Order</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Monitor impact on Work Order efficiency</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.service-activity-savings-by-location')" class="">Service Activity Savings by Location</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Your savings from activities and changes to services by location</p>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-white px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-2">
            Vendor &amp; Expenses
        </h3>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.audited-vendor-invoice-details', {'begin_date': lastMonth})" class="">Audited Vendor Invoice Details</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Shows line item details for audited vendor invoices. Overall auditing cost savings can be deduced from this report.</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.audited-vendor-invoice-summary')" class="">Audited Vendor Invoice Summary</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Shows summary for each month to see total savings after audits.</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.expiring-vendor-contracts', {'is_franchised': 'false', 'contract_expiration_end_date': fiveMonthsFromNow, 'last_bid_older_than': fourMonthsAgo})" class="">Expiring Vendor Contracts</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Displays vendor contracts that are expiring soon</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.vendor-invoices-by-account-per-month', {'begin_date': lastMonth})" class="">Vendor Invoices By Account Per Month</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Search for missing vendor invoices by account number and fiscal period.</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.extracted-invoices-by-account', {'begin_date': oneMonthAgo})" class="">Extracted Invoices By Account</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Search for missing extracted invoices by account number for a given time period.</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.ocr-invoice-details', {'invoice_begin_date': oneMonthAgo})" class="">Extracted Invoice Details</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Extraction of vendor invoice data and line items from pdf documents and images.</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.duplicate-ocr-vendor-invoices', {'start_date': oneMonthAgo})" class="">Duplicate Extracted Invoices</inertia-link>
                </h3>

                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Extraction of vendor invoice data and line items from pdf documents and images.</p>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.excess-and-contaminated-charges')" class="">Excess and Contaminated Charges</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Shows a filtered list of excess and contaminated line items sourced from vendor invoice line items.</p>
                </div>
            </div>
        </div>
    </div>

    <div v-if="$page.props.permissions.accessTemporaryReports" class="bg-white px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-2">
            Temporary Reports
        </h3>

        <div v-if="$page.props.permissions.accessSpendByFiscalPeriodReport" class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.spend-by-fiscal-period')" class="">Spend By Fiscal Period</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>Shows spend by fiscal period. This report will be removed when the Custom Report Builder is capable of producing a report with this information.</p>
                </div>
            </div>
        </div>

        <div v-if="$page.props.permissions.accessInDevelopmentReports" class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.gresb-v2')" class="">GRESB V2</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>For testing purposes only.</p>
                </div>
            </div>
        </div>

        <div v-if="$page.props.permissions.accessInDevelopmentReports" class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.energy-star-properties-v2')" class="">EnergyStar Properties V2</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>For testing purposes only.</p>
                </div>
            </div>
        </div>

        <div v-if="$page.props.permissions.accessInDevelopmentReports" class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.energy-star-meters-v2')" class="">EnergyStar Meters V2</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>For testing purposes only.</p>
                </div>
            </div>
        </div>

        <div v-if="$page.props.permissions.accessInDevelopmentReports" class="bg-white shadow sm:rounded-lg m-2">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <inertia-link :href="$route('reports.energy-star-meter-usage-v2')" class="">EnergyStar Meter Usage V2</inertia-link>
                </h3>
                <div class="mt-2 text-sm leading-5 text-gray-500">
                    <p>For testing purposes only.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import { addMonths, subMonths, format } from "date-fns";
    import { Head } from '@inertiajs/vue3';
    import HorizontalSubNav from "./HorizontalSubNav.vue";

    export default {
        components: {
	        HorizontalSubNav,
            Icon,
            Head,
        },

        data() {
            return {
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        computed: {
            lastMonth() {
                return format(subMonths(new Date, 1), 'yyyy-MM')
            },

            oneMonthAgo() {
                return format(subMonths(new Date, 1), 'yyyy-MM-dd')
            },

            fourMonthsAgo() {
                return format(subMonths(new Date, 4), 'yyyy-MM-dd')
            },

            fiveMonthsFromNow() {
                return format(addMonths(new Date, 5), 'yyyy-MM-dd')
            }
        },

        methods: {
            adjustDateMonths(date, months) {
                return format(addMonths(date, months), 'yyyy-MM-dd')
            }
        },
    }
</script>