<template>

    <Head title="Login 2FA" />

    <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <logo class="lg:hidden w-75 h-24 px-6 lg:px-0 mx-auto" name="mark-dark"/>
            <logo class="hidden lg:block w-75 h-24 px-6 lg:px-0 mx-auto" name="full-dark" />

            <h2 class="mt-6 text-center text-3xl leading-9 font-semibold text-gray-900">
                Two Factor Challenge
            </h2>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form :action="$route('two-factor-challenge.attempt')" method="POST" v-if="!showingRecoveryCodeForm" class="space-y-8">
                    <input type="hidden" name="_token" :value="csrfToken" />

                    <p class="text-sm mb-6">Please confirm access to your account by entering the authentication code provided by your authenticator application. </p>

                    <text-input name="code" v-model="form.code" label="Code" :errors="errors.code" />
                    
                    <div class="text-red-600" v-if="$page.props.error_message">
                        {{ $page.props.error_message }}
                    </div>

                    <div class="">
                        <div class="flex items-center">
                            <input id="remember_me" name="remember_me" type="checkbox" v-model="form.remember_me" class="form-checkbox h-4 w-4" />
                            <label for="remember_me" class="ml-2 block text-sm leading-5 text-gray-900">
                                Remember me?
                            </label>
                        </div>
                        <span class="text-xs">(This will trust this computer so you don't need to use login with 2FA as often)</span>
                    </div>

                    <div class="">
                        <a href="#" class="link" @click="showRecoveryCodeForm">Use a recovery code</a>
                        
                        <loading-button :loading="submitting" type="submit" class="w-full flex justify-center btn btn-orange col-span-2">
                            Submit
                        </loading-button>
                    </div>
                </form>

                <form :action="$route('two-factor-challenge.recovery-code')" method="POST" v-if="showingRecoveryCodeForm" class="space-y-8">
                    <input type="hidden" name="_token" :value="csrfToken" />

                    <p class="text-sm mb-6">
                        Enter a recovery code if you have lost access to your authenticator app. Please note that a recovery code is only good for one time use. If you do not have your recovery codes or authenticator app then please contact Discovery support for help.
                    </p>

                    <text-input name="code" v-model="form.recovery_code" label="Recovery Code" :errors="errors.code" />

                    <div class="text-red-600" v-if="$page.props.error_message">
                        {{ $page.props.error_message }}
                    </div>

                    <div class="mt-6">
                        <a href="#" class="link" @click="showTwoFactorCodeForm">Use a 2FA code</a>                        
                        <loading-button :loading="submitting" type="submit" class="w-full flex justify-center btn btn-orange col-span-2">
                            Submit
                        </loading-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import BlankLayout from "@/Shared/BlankLayout.vue";
    import { Head } from '@inertiajs/vue3';
    import Logo from "@/Shared/Logo.vue";

    export default {
        layout: BlankLayout,

        components: {
            Logo,
            LoadingButton,
            TextInput,
            Head,
        },

        props: {
            csrfToken: String,

            errors: {
                type: Object,
                default: () => ({})
            },
        },

        mounted() {
            if (this.$page.props.success_message) {
                this.$toast.success(this.$page.props.success_message);
            }
        },

        data() {
            return {
                form: {
                    recovery_code: null,
                    code: null,
                    remember_me: false,
                },

                showingRecoveryCodeForm: false,
            }
        },

        methods: {
            showRecoveryCodeForm() {
                this.code = null;
                this.showingRecoveryCodeForm = true;
            },

            showTwoFactorCodeForm() {
                this.recovery_code = null;
                this.showingRecoveryCodeForm = false;
            },
        }
    }
</script>