<style>

</style>

<template>
    <div>
        <div v-if="isWritable">
            <div>{{ $filters.format_money(calculatedAmount) }}</div>

            <!-- Users can adjust the allocated percentage of the fee as desired until the calculated amount is where it needs to be !-->
            <inline-text-input v-model="percentage_of_charge" :filters="['format_percentage']" :sanitizers="[this.stripCharacters]" @update:modelValue="$emit('allocatable-fee-was-modified', model.line_item_fee_id, calculatedAmount)" />
        </div>

        <div v-else>
            <div>{{ $filters.format_money(calculatedAmount) }}</div>
            <div>{{ $filters.format_percentage(percentage_of_charge) }}</div>
        </div>
    </div>
</template>

<script>
    import InlineTextInput from '@/Shared/InlineTextInput.vue';

    export default {
        emits: ['allocatable-fee-was-modified'],

        components: {
            InlineTextInput
        },
        
        props: {
            model: {
                type: Object,
                required: true
            },
            
            chargeCostBasis: {
                type: Object,
                required: true
            },

            isWritable: {
                type: Boolean,
                default: false
            }
        },

        data() {
            let modified = false;
            let percentage_of_charge = 0.00;

            if (this.chargeCostBasis.amount != 0) {
                percentage_of_charge = (this.model.amount.amount * 100) / parseInt(this.chargeCostBasis.amount);
            }

            return {
                modified,
                percentage_of_charge
            };
        },

        methods: {
            stripCharacters(value) {
                return value.replace(/[^\d.-]/g, '');
            },

            recalculatePercentageOfCharge() {
                let percentage_of_charge = 0.00;

                if (parseInt(this.chargeCostBasis.amount, 10) !== 0) {
                    percentage_of_charge = (parseInt(this.model.amount.amount) * 100) / parseInt(this.chargeCostBasis.amount);
                }

                this.percentage_of_charge = percentage_of_charge;
            }
        },

        computed: {
            /**
             * The calculated value for a percentage-based value is always the cost basis * the percentage of the
             * base charge that the fee should be allocated.
             */
            calculatedAmount() {
                return {
                    amount: (parseInt(this.chargeCostBasis.amount) * (this.percentage_of_charge / 100)).toFixed(),
                    currency: this.chargeCostBasis.currency,
                };
            }
        },

        watch: {
            'chargeCostBasis.amount': {
                handler: function() {
                    this.recalculatePercentageOfCharge();
                }
            },

            'model.amount.amount': {
                handler: function() {
                    this.recalculatePercentageOfCharge();
                }
            },
        }
    }
</script>
