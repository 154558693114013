<template>
    <Head :title="`Edit Vendor Contract - ${vendorContract.display_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendor-contracts.index')" class="breadcrumb-link">Vendor Contracts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendor-contracts.show', [vendorContract.id])" class="breadcrumb-link">{{ vendorContract.display_id }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <form id="edit-vendor-contract-form" class="space-y-8" @submit.prevent="submitForm">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Information</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Contract settings and info.</p>
                </div>
            </legend>

            <div class="grid grid-cols-3 gap-6">
                <div class="col-span-3 md:col-span-1">
                    <span class="form-label">Vendor</span>

                    <inertia-link :href="this.$route('vendors.show', vendorContract.vendor_id)" class="link">
                        {{ vendorContract.vendor.name ?? '' }}
                    </inertia-link>
                </div>

                <div class="col-span-3 md:col-span-1">
                    <span class="form-label">Expiration Date</span>

                    {{ $filters.format_date(vendorContract.expiration_date) }}
                </div>

                <select-input
                    v-model="form.status"
                    class="col-span-3 md:col-span-1"
                    label="Contract Status"
                    :help-text="helpTextOn ? 'The current status of this contract' : ''"
                    :errors="errors.status"
                    mark-as-required>

                    <option v-for="status in statuses" :value="status" :key="status">{{ status }}</option>
                </select-input>

                <date-input class="col-span-3 md:col-span-1"
                    label="Contract Effective Date"
                    placeholder="2012-01-01"
                    v-model="form.effective_date"
                    :help-text="helpTextOn ? 'The date the contract becomes effective.' : ''"
                    :errors="errors.effective_date"
                    mark-as-required
                />

                <date-input
                    class="col-span-3 md:col-span-1"
                    label="Contract Termination Date"
                    placeholder="2022-01-01"
                    v-model="form.termination_date"
                    :help-text="helpTextOn ? 'The date the contract was terminated.' : ''"
                    :errors="errors.termination_date"
                    markAsRequired
                />

                <select-input v-model="form.is_franchised"
                    class="col-span-3 md:col-span-1"
                    label="Is Franchised"
                    :help-text="helpTextOn ? 'Indicates this vendor is designated the exclusive service provider for the region by the local government.' : ''"
                    :errors="errors.is_franchised">

                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                </select-input>

                <select-input v-model="form.is_exclusive_to_vendor"
                    class="col-span-3 md:col-span-1"
                    label="Exclusive to Vendor"
                    :help-text="helpTextOn ? 'Indicates that the vendor is your exclusive service provider, but this exclusivity is not part of any local government franchise system, etc.' : ''"
                    :errors="errors.is_exclusive_to_vendor">

                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                </select-input>

                <text-input
                    type="number"
                    class="col-span-3 md:col-span-1"
                    label="Payment Days until Remittance"
                    placeholder="30"
                    v-model="form.payment_days"
                    :help-text="helpTextOn ? 'The number of days until payment must be remitted for bills received from the vendor on this contract.' : ''"
                    :errors="errors.payment_days"
                    min="0"
                    markAsRequired
                />

                <div class="col-span-3 md:col-span-1">
                    <text-input
                        type="file"
                        name="contract-document"
                        id="contract-document"
                        label="Signed Contract"
                        :help-text="helpTextOn ? 'The file for this contract' : ''"
                        @change="addPendingContractDocumentUpload"
                        :errors="errors.contract_document"
                    />
                    <div v-if="contract_document && vendorContract.contract_document_path" class="text-red-600 text-sm px-4">
                        WARNING: this new file will replace existing contract
                        <p class="truncate">
                            <a class="link" target="_blank" :href="$route('vendor-contracts.pdf', vendorContract.id)">{{ vendorContract.contract_document_name ?? 'file' }}</a>
                        </p>
                    </div>
                </div>
            </div>
        </fieldset>

        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Auto Renewal</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Contract auto renew settings and info.</p>
                </div>
            </legend>

            <div class="grid grid-cols-3 gap-6">
                <div>
                    <text-input
                        class="col-span-3 md:col-span-1"
                        type="file"
                        name="auto-renew-cancellation-document"
                        id="auto-renew-cancellation-document"
                        label="Auto Renew Cancellation Document"
                        :help-text="helpTextOn ? 'A copy of the document mailed to the vendor requesting any auto renewals be cancelled' : ''"
                        @change="addPendingAutoRenewCancellationDocument"
                        :errors="errors.auto_renew_document"
                    />
                    <div class="text-red-600 text-sm px-4" v-if="auto_renew_document && vendorContract.auto_renew_document_path">
                        WARNING: this new file will replace existing auto renew document
                        <p class="truncate"><a class="link" target="_blank" :href="$route('vendor-contracts.auto-renew-pdf', vendorContract.id)">{{ vendorContract.auto_renew_document_name ?? 'file' }}</a></p>
                    </div>
                </div>

                <div>
                    <text-input
                        class="col-span-3 md:col-span-1"
                        type="file"
                        name="auto-renewal-cancellation-proof"
                        id="auto-renewal-cancellation-proof"
                        label="Auto Renew Cancellation Mail Receipt Proof"
                        :help-text="helpTextOn ? 'A copy of the certified mail receipt or other valid proof that the vendor received your Auto Renew Cancellation Document' : ''"
                        @change="addPendingAutoRenewMailReceiptProof"
                        :errors="errors.auto_renew_mail_receipt_proof_document"
                    />
                    <div class="text-red-600 text-sm px-4" v-if="auto_renew_mail_receipt_proof_document && vendorContract.auto_renew_mail_receipt_proof_document_path">
                        WARNING: this new file will replace existing auto renew cancellation receipt
                        <p class="truncate"><a class="link" target="_blank" :href="$route('vendor-contracts.auto-renew-pdf', vendorContract.id)">{{ vendorContract.auto_renew_mail_receipt_proof_document_name ?? 'file' }}</a></p>
                    </div>
                </div>

                <date-time-local-input
                    class="col-span-3 lg:col-span-1"
                    v-model="form.auto_renew_cancellation_notice_sent_at"
                    label="Auto Renew Cancellation Notice Sent On"
                    :help-text="helpTextOn ? 'The date your Auto Renew Document was sent and/or postmarked. A valid date mustfall within the Auto Renew Cancellation Window, which is determined by the next Expiration Date, less the minimum and maximum days to notify' : ''"
                    :errors="errors.auto_renew_cancellation_notice_sent_at"
                />

                <text-input type="number"
                    class="col-span-3 md:col-span-1"
                    label="Maximum days to notify"
                    placeholder="Maximum days to canceal auto renewal"
                    v-model="form.days_to_notify_max"
                    :help-text="helpTextOn ? 'The expiration date MINUS this value creates the start of the Cancellation of Auto Renewal Window.' : ''"
                    :errors="errors.days_to_notify_max"
                    mark-as-required
                    min="0"
                />

                <text-input type="number"
                    class="col-span-3 md:col-span-1"
                    label="Minimum days to notify"
                    placeholder="Minimum days to cancel auto renewal days"
                    v-model="form.days_to_notify_min"
                    :help-text="helpTextOn ? 'The expiration date MINUS this value creates the end of the Cancellation of Auto Renewal Window.' : ''"
                    :errors="errors.days_to_notify_min"
                    mark-as-required
                    min="0"
                />

                <text-input type="number"
                    class="col-span-3 md:col-span-1"
                    label="Contract Term Length"
                    placeholder="Contract term length (in months)"
                    v-model="form.term_months"
                    :help-text="helpTextOn ? 'The number of months the contract will be active for. This will determine the expiration date of the contract.' : ''"
                    :errors="errors.term_months"
                    mark-as-required
                    min="0"
                />

                <text-input type="number"
                    class="col-span-3 md:col-span-1"
                    label="Auto Renewal Term"
                    placeholder="Auto Renewal Term (in months)"
                    v-model="form.auto_renewal_term_months"
                    :help-text="helpTextOn ? 'The number of months the contract will be renewed for if an auto-renewal takes place. A value of 1 means the contract is auto-renewing monthly and the client isn\'t locked in.' : ''"
                    :errors="errors.auto_renewal_term_months"
                    mark-as-required
                    min="0"
                />
            </div>
        </fieldset>

        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Signing / Procurement</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Contract auto renew settings and info.</p>
                </div>
            </legend>

            <div class="grid grid-cols-3 gap-6">
                <select-input v-model="form.signing_party"
                    class="col-span-3 md:col-span-1"
                    label="Signing Party"
                    :help-text="helpTextOn ? 'The entity that signs for and assumes the rights and liabilities of the agreement.' : ''"
                    :errors="errors.signing_party">

                    <option v-for="(value, key) in signingParties" :value="key" :key="key">{{ value }}</option>
                </select-input>

                <select-input v-model="form.procuring_party"
                    class="col-span-3 md:col-span-1"
                    label="Procuring Party"
                    :help-text="helpTextOn ? 'The entity that procured the terms and conditions for the contract. Used to differentiate agreements that were negotiated by the Client vs agreements your team negotiated.' : ''"
                    :errors="errors.procuring_party">

                    <option v-for="(value, key) in signingParties" :value="key" :key="key">{{ value }}</option>
                </select-input>

                <select-input v-model="form.signed_by_vendor"
                    class="col-span-3 md:col-span-1"
                    label="Signed by Vendor"
                    :help-text="helpTextOn ? 'Has this contract been signed by the vendor?' : ''"
                    :errors="errors.signed_by_vendor">

                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                </select-input>
            </div>
        </fieldset>

        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Misc.</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Miscellaneous settings and info.</p>
                </div>
            </legend>

            <div class="grid grid-cols-3 gap-6">
                <textarea-input
                    v-model="form.manual_terms"
                    class="col-span-3"
                    label="Manual Terms"
                    :help-text="helpTextOn ? 'Any additional terms and conditions you\'d like to include' : ''"
                    :errors="errors.manual_terms"
                />

                <text-input type="number"
                    v-model="form.annual_price_increase_at_integration"
                    class="col-span-3 md:col-span-1"
                    label="API Before Integration %"
                    placeholder="%"
                    :help-text="helpTextOn ? 'The Annual Price Increase (percentage) before integration.' : ''"
                    :errors="errors.annual_price_increase_at_integration"
                    min="0"
                />

                <select-input
                    class="col-span-3 md:col-span-1"
                    v-model="form.minimum_tonnage_cost_hidden_on_invoice"
                    label="Minimum Tonnage Cost Hidden On Invoice"
                    :help-text="helpTextOn ? 'Indicates if the vendor going to hide their minimum rolloff tonnage cost on their invoices (minium tonnage cost is often hidden within the haul charge).' : ''"
                    :errors="errors.minimum_tonnage_cost_hidden_on_invoice">

                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                </select-input>

                <text-input type="text"
                    v-model="form.external_reference_id"
                    class="col-span-3 md:col-span-1"
                    label="External Reference ID"
                    placeholder=""
                    :help-text="helpTextOn ? 'An external id you may wish to associate with this contract.' : ''"
                    :errors="errors.external_reference_id"
                />
            </div>
        </fieldset>

        <div class="mt-4">
            <loading-button :loading="state === 'saving'" class="btn btn-orange">
                Save Changes
            </loading-button>
        </div>
    </form>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import DateTimeLocalInput from '@/Shared/DateTimeLocalInput.vue';

    export default {
        components: {
            Head,
            Icon,
            SelectInput,
            LoadingButton,
            TextInput,
            TextareaInput,
            DateInput,
            DateTimeLocalInput
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            vendorContract: {
                type: Object,
                required: true
            },

            vendors: Array,
            signingParties: Object,
            statuses: Array,
        },

        data() {
            return {
                form: {... this.vendorContract},
                contract_document: null,
                auto_renew_document: null,
                auto_renew_mail_receipt_proof_document: null,
                state: 'passive',
                helpTextOn: false,
                mounted: false
            }
        },

        mounted() {
            this.mounted = true
        },

        methods: {
            addPendingContractDocumentUpload(e) {
                if (e.target.files.length > 0) {
                    this.contract_document = e.target.files[0];
                }
            },

            addPendingAutoRenewCancellationDocument(e) {
                if (e.target.files.length > 0) {
                    this.auto_renew_document = e.target.files[0];
                }
            },

            addPendingAutoRenewMailReceiptProof(e) {
                if (e.target.files.length > 0) {
                    this.auto_renew_mail_receipt_proof_document = e.target.files[0];
                }
            },

            submitForm() {
                if (this.state === 'passive') {
                    let formData = new FormData()

                    // https://github.com/laravel/framework/issues/13457
                    // laravel bug that hadn't been fixed, which causes PUT
                    // requests to ignore form-encoded data, workaround is to
                    // use a POST with _method=PUT
                    formData.append('_method', 'PUT');

                    if (this.auto_renew_document) {
                        formData.append('auto_renew_document', this.auto_renew_document);
                    }

                    if (this.contract_document) {
                        formData.append('contract_document', this.contract_document);
                    }

                    if (this.auto_renew_mail_receipt_proof_document) {
                        formData.append('auto_renew_mail_receipt_proof_document', this.auto_renew_mail_receipt_proof_document);
                    }

                    Object.keys(this.form).forEach((key) => {
                        let value = this.form[key];
                        value = (value === null) ? '' : value;
                        value = (value === true) ? 1 : value;
                        value = (value === false) ? 0 : value;
                        formData.append(key, value)
                    });

                    let route = this.$route('vendor-contracts.update', this.vendorContract.id);

                    this.state = 'saving';

                    this.$inertia.post(route, formData, {
                        preserveScroll: true,
                        onFinish: () => {
                            this.state = 'passive';
                        }
                    });
                }
            }
        }
    }
</script>
