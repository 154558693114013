<template>
    <div>
        <div class="relative">
            <button @click="toggleNotifications" @blur="notificationsShown = false">
                <icon name="bell" class="inline h-6 w-6 text-gray-100 fill-current" />

                <span v-if="unreadCount > 0" class="absolute -top-2 -right-0.5 inline-flex items-center px-1 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    <span v-if="unreadCount < 10">
                        {{ unreadCount }}
                    </span>

                    <span v-else>
                        9+
                    </span>
                </span>
            </button>
        </div>

        <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0">
        <div v-show="notificationsShown" class="origin-top-right absolute max-h-full overflow-y-auto right-0 w-1/3 mt-2 bg-white p-2 z-50 shadow-lg">
                <div v-if="currentNotifications.length === 0">
                    <div class="flex items-start">
                        <div class="ml-3 text-md font-medium">
                            You have no notifications currently.
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="mr-2 mb-2 text-right">
                        <inertia-link :href="$route('notifications.index')" class="link">
                            View All Notifications
                        </inertia-link>
                    </div>

                    <div v-for="notification in currentNotifications" :key="notification.id">
                        <hr>

                        <div class="p-4 ml-2">
                            <inertia-link :href="notification.data.url" class="link text-sm font-medium">
                                {{ notification.data.title }}
                            </inertia-link>

                            <p class="mt-1 text-sm text-gray-500">
                                {{ truncate(notification.data.body, { length: 130 }) }}
                            </p>

                            <p class="mt-1 text-sm font-medium">
                                {{ $filters.diff_for_humans(notification.created_at) }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import { truncate } from 'lodash-es';
    import axios from 'axios';

    export default {
        components: {
            Icon
        },

        props: {
            notifications: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                currentNotifications: [...this.notifications],
                notificationsShown: false
            }
        },

        methods: {
            truncate,

            toggleNotifications() {
                if (this.notificationsShown) {
                    this.notificationsShown = false;

                    return;
                }

                this.notificationsShown = true;

                if (this.unreadCount > 0) {
                    // Fire off request to mark all notifications as read
                    axios.post('/notifications/mark-all-read', {})
                        .then(notifications => this.currentNotifications = notifications.data);
                }
            }
        },

        computed: {
            unreadCount: function () {
                return this.currentNotifications.filter(notification => !notification.read_at).length;
            }
        }
    }
</script>