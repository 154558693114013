<template>
    <Head title="Client Invoices with Associated Vendor Invoices" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('reports.index')" class="breadcrumb-link">Reports</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Client Invoices with associated Vendor Invoices
                <small class="">(showing {{ reportData.data.length }} of {{ reportData.total }})</small>
            </span>
        </nav>
    </Teleport>

    <form id="filter-report-data-form" action="" class="grid grid-cols-4 gap-4 my-2 mb-4" @submit.prevent>
        <select-input class="col-span-4 md:col-span-2 lg:col-span-1" v-model="form.client_company_id" label="Client Company">
            <option :value="null">All</option>
            <option v-for="clientCompany in clientCompanyOptions" :value="clientCompany.id" :key="clientCompany.id">{{ clientCompany.name }}</option>
        </select-input>

        <select-input class="col-span-4 md:col-span-2 lg:col-span-1" v-model="form.client_invoice_status" label="Client Invoice Status">
            <option :value="null">All</option>
            <option v-for="status in clientInvoiceStatuses" :key="status">{{ status }}</option>
        </select-input>

        <select-input class="col-span-4 md:col-span-2 lg:col-span-1" v-model="form.vendor_invoice_status" label="Vendor Invoice Status">
            <option :value="null">All</option>
            <option v-for="(status, index) in vendorInvoiceStatuses" :value="index" :key="index">{{ status }}</option>
        </select-input>

        <text-input class="col-span-4 md:col-span-2 lg:col-span-1" v-model="form.account_number" placeholder="Search for account number" label="Account Number" />
        <date-input class="col-span-4 md:col-span-2 lg:col-span-1" v-model="form.client_invoice_date" label="Client Invoice Date" />
        <date-input class="col-span-4 md:col-span-2 lg:col-span-1" v-model="form.vendor_invoice_date" label="Vendor Invoice Date" />

        <div class="col-span-4 md:col-span-2 lg:col-span-1 flex flex-wrap">
            <inertia-link :href="$route('reports.client-invoices-with-associated-vendor-invoices', {remember: 'forget'})" class="mt-6 btn btn-gray mr-2">Clear</inertia-link>

            <loading-button :loading="saving" class="mt-6 btn btn-gray" @click="exportData">
                Export to CSV
            </loading-button>
        </div>
    </form>

    <div v-if="reportData.data.length != 0">
        <reporting-table :reportData="reportData.data"></reporting-table>
        <pagination v-if="reportData" :links="reportData.links" />
    </div>
    <div v-else>
        <h5>No results found, please adjust your filters.</h5>
    </div>
</template>

<script>
    import DropdownLink from '@/Shared/DropdownLink.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import Icon from '@/Shared/Icon.vue';
    import MonthInput from '@/Shared/MonthInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import { debounce, pickBy } from 'lodash-es';
    import ReportingTable from '@/Shared/ReportingTable.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            DropdownLink,
            MonthInput,
            DateInput,
            SelectInput,
            Pagination,
            ReportingTable,
            LoadingButton,
            TextInput,
            Head,
        },

        props: {
            clientCompanies: {
                type: Array,
                required: true
            },

            clientInvoiceStatuses: {
                type: Array,
                required: true,
            },

            vendorInvoiceStatuses: {
                type: Object,
                required: true,
            },

            reportData: {
                type: Object,
                required: true
            },

            filters: Object
        },

        data() {
            var selectedCompany = this.clientCompanies.filter((clientCompany) => clientCompany.id == this.filters.client_company_id)

            return {
                clientCompany: selectedCompany ? selectedCompany : null,
                form: { ...this.filters},
                saving: false,
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            exportData() {
                this.saving = true;

                this.$inertia.post(this.$route('csv.client-invoices-with-associated-vendor-invoices'), {...this.form}, {
                    onFinish: () => { this.saving = false; }
                });
            }
        },

        watch: {
            form: {
                handler: debounce(function() {
                    let query = pickBy(this.form);
                    query = Object.keys(query).length ? query : {remember: 'forget'};

                    this.$inertia.get(this.$route("reports.client-invoices-with-associated-vendor-invoices"), query, {preserveState: true});
                }, 500),

                deep: true
            },
            clientCompany: {
                handler: function(clientCompany) {
                    this.form.client_company_id = clientCompany ? clientCompany.id : null
                },
                deep: true
            }
        },

        computed:{
            clientCompanyOptions() {
                return this.clientCompanies
                    .sort((a, b) => a.name.localeCompare(b.name));
            }
        }
    }
</script>