<template>
    <Head :title="`Edit Pickup Days Change - ${serviceActivity.service_id}`"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">
                {{ serviceActivity.service_id }}
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Pickup Days Change</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="mt-6 mb-12 text-base lg:text-lg">
        <div class="md:px-12">
            <div class="flex justify-between items-center my-6">
                <h1 class="text-2xl font-bold">Edit Pickup Days Change</h1>

                <div class="flex items-center gap-x-4">
                    <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="btn btn-gray gap-x-2">
                        Cancel Edit
                    </inertia-link>
                    <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
                </div>
            </div>

            <fieldset class="border-t border-gray-300">
                <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Change Details
                </legend>

                <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-3 px-4">
                    <revert-input v-model="form.change_requester" label="Requested By" mark-as-required>
                        <select-input v-model="form.change_requester" :errors="errors['change_requester']">
                            <option value="vendor">{{ serviceActivity.serviceSnapshot.vendor.name ?? '' }}</option>
                            <option value="client">{{ serviceActivity.serviceSnapshot.location.clientCompany.name }}</option>
                            <option value="broker">{{ $page.props.authUser.tenant_name }}</option>
                        </select-input>
                    </revert-input>

                    <revert-input v-model="form.actual_date" label="Effective Date" mark-as-required>
                        <date-input :min="serviceCreationActivity.actual_date" v-model="form.actual_date" help-text="Date of pickup schedule change" :errors="errors['actual_date']"/>
                    </revert-input>

                    <revert-input v-model="form.change_reason" label="Why are you making this change?" mark-as-required>
                        <select-input v-model="form.change_reason">
                            <option>Other</option>
                        </select-input>
                    </revert-input>
                </div>
            </fieldset>

            <fieldset class="border-t border-gray-300 mt-10">
                <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Pickups
                </legend>

                <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 px-4">
                    <revert-input
                        v-if="serviceHasScheduleInfo && (serviceActivity.serviceSnapshot.service_schedule_type === 'Scheduled')"
                        v-model="form.pickup_rrule"
                        class="col-span-1 md:col-span-6"
                        label="Schedule"
                        :mark-as-required="serviceActivity.serviceSnapshot.service_schedule_type === 'Scheduled'"
                    >
                        <div class="flex flex-col items-start gap-6 lg:gap-12 mt-2 md:flex-row">
                            <div class="md:flex-shrink-0">
                                <div class="flex flex-col gap-3">
                                    <span>{{ rrule_description }}</span>
                                    <div v-if="errors['pickup_rrule'] || errors['average_pickups_per_week']" class="text-red-500">
                                        The pickup schedule is invalid
                                    </div>
                                    <a class="link cursor-pointer text-base inline-flex items-center gap-1" @click.prevent="showUpdatePickupRRuleModal">
                                        Edit Schedule <icon name="edit" class="inline fill-current w-5 h-6"></icon>
                                    </a>
                                </div>
                            </div>

                            <div class="w-full md:flex-1" v-if="form?.pickup_rrule?.freq === 'WEEKLY'">
                                <div class="w-full overflow-hidden rounded-lg grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7">
                                    <div
                                        v-if="form.pickup_rrule.byday"
                                        v-for="(day, id) in daysOfWeek" :key="id" class="w-full group relative text-center px-2"
                                    >
                                        <div class="text-base border-b pb-1">{{ day }}</div>
                                        <div class="flex justify-center items-center p-4 pb-8 lg:pb-0">
                                            <icon name="check" class="text-green-500 fill-current size-4" v-if="form.pickup_rrule.byday.includes(id)"/>
                                            <icon name="times" class="text-gray-300 fill-current size-4" v-else />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </revert-input>
                </div>
            </fieldset>

            <div class="relative mt-12">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-start">
                    <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Other</span>
                </div>
            </div>

            <div class="px-4 mt-4 mb-8">
                <revert-input v-model="form.internal_change_notes" label="Internal Change Notes">
                    <textarea-input rows="4" v-model="form.internal_change_notes"/>
                </revert-input>
            </div>

            <loading-button class="btn btn-orange" :loading="saving" @click="submitChange" :disabled="!form.actual_date || !form.change_reason" >
                Submit Change
            </loading-button>
        </div>

        <!-- Pickup RRule Modal -->
        <modal
            v-if="serviceHasScheduleInfo"
            class="max-w-2xl"
            ref="updatePickupRRuleModal"
            tabindex="-1"
            role="dialog"
        >
            <template #modal-header="{close}">
                <div class="p-4 border-b border-gray-400 flex justify-between">
                    <div class="my-1 text-2xl">
                        Update Schedule
                    </div>

                    <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                        <icon name="times" class="fill-current inline w-6 h-6"/>
                    </button>
                </div>
            </template>

            <template #modal-body>
                <div class="p-4">
                    <r-rule-input
                        ref="rRuleInput"
                        v-model:modelValue="form.pickup_rrule"
                        v-model:weeklyAverage="form.average_pickups_per_week"
                        v-model:description="rrule_description"
                        start-date-label="First Pickup"
                        weekly-average-label="Avg. Pickups Per Week"
                    />
                </div>
            </template>

            <template #modal-footer="{close}">
                <div class="p-4">
                    <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script setup>
/**
 * This page is a Wizard to help user along with service changes
 */
import DateInput from '@/Shared/DateInput.vue';
import Icon from '@/Shared/Icon.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import RevertInput from '@/Shared/RevertInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import TextareaInput from '@/Shared/TextareaInput.vue';
import {format, startOfToday} from "date-fns";
import Modal from "@/Shared/Modal.vue";
import RRuleInput from "@/Shared/RRuleInput.vue";
import {reactive, ref, inject, computed, onMounted} from 'vue';
import {Head, router, usePage} from '@inertiajs/vue3';

/**
 * Props
 */
const props = defineProps({
    errors: {
        type: Object,
        default: () => ({})
    },

    serviceActivity: {
        type: Object,
        required: true
    },

    serviceCreationActivity: {
        type: Object,
        required: true
    },
});

/**
 * Injected
 */
const route = inject('route');
const page = usePage();

onMounted(() => {
    mounted.value = true;
});

/**
 * Data
 */
const saving = ref(false);
const today = format(startOfToday(), 'yyyy-MM-dd');
const mounted = ref(false);
const rrule_description = ref(null);
const updatePickupRRuleModal = ref(null);
const form = reactive({
    actual_date: props.serviceActivity.actual_date,
    change_reason: props.serviceActivity.reason,
    change_requester: props.serviceActivity.change_requester,
    internal_change_notes: props.serviceActivity.internal_change_notes,
    pickup_rrule: props.serviceActivity.serviceSnapshot.pickup_rrule,
    average_pickups_per_week: props.serviceActivity.serviceSnapshot.average_pickups_per_week,
});

const daysOfWeek = reactive({
    'SU': 'Sunday',
    'MO': 'Monday',
    'TU': 'Tuesday',
    'WE': 'Wednesday',
    'TH': 'Thursday',
    'FR': 'Friday',
    'SA': 'Saturday',
});

/**
 * Methods
 */
function submitChange() {
    saving.value = true;

    router.put(route('services.pickup-days-changes.update', [props.serviceActivity.service_id, props.serviceActivity.id]), {
        ...form,
    }, {
        onFinish: () => saving.value = false
    });
}

function showUpdatePickupRRuleModal() {
    updatePickupRRuleModal.value.show();
}

function deleteServiceChange() {
    if (window.confirm('Are you sure you want to delete this pickup days change?')) {
        router.delete(route('services.pickup-days-changes.destroy', {
            serviceId: props.serviceActivity.service_id,
            serviceActivityId: props.serviceActivity.id
        }));
    }
}

/**
 * Computed
 */
const serviceHasScheduleInfo = computed(() => {
    return props.serviceActivity.service.serviceType.enabled_type_fields.includes('service_schedule_info');
});
</script>
