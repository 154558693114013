<template>
    <div v-if="mounted" class="relative">
        <div class="mb-3 md:mb-0">
            <div class="inset-x-0 overflow-y-auto max-h-96 border">
                <div v-for="option in options" @click="addOrRemoveSelection(option)" :key="option.id" class="flex items-center cursor-pointer py-2 px-3 hover:bg-gray-100" :class="{ 'text-d-orange-500': isOptionSelected(option.id)}">
                    <slot name="option" :option="option">
                        <span>{{ option.id }}</span>
                    </slot>
                    <icon v-if="isOptionSelected(option.id)" name="check" class="ml-auto w-4 h-4 text-d-orange-500 fill-current"/>
                </div>
                <div class="flex items-center justify-center" v-if="options.length === 0">
                    <div class="text-gray-500 p-2">
                        No options found
                    </div>
                </div>
            </div>
            <div v-if="selections.length > 0">
                <button type="button" @click.prevent="clearSelections" class="block w-full text-base font-medium border -mt-px bg-gray-50 text-d-blue-500 p-2 hover:bg-gray-100 hover:text-d-blue-800">
                    Clear Selections
                </button>
            </div>
        </div>
        <div class="ml-0 md:ml-2">
            <div v-for="selection in selections" :key="selection.id" class="badge badge-orange">
                <span>{{ selection.name }}</span>
                <button type="button" @click.prevent="removeOption(selection)" class="badge-button">
                    <icon name="times" />
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {ref, onMounted} from 'vue';
    import Icon from "./Icon.vue";

    const props = defineProps({
        options: {
            type: Array,
            default: () => [],
        },
        selectedOptions: {
            type: Array,
            default: () => [],
        },
        errors: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        markAsRequired: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    });

    const mounted = ref(false);
    const selections = ref(props.selectedOptions);

    const emit = defineEmits(['updated']);

    onMounted(() => {
        mounted.value = true;
    });

    const isOptionSelected = (id) => {
        return selections.value.find((option) => option.id === id);
    };

    const addOrRemoveSelection = (option) => {
        if (selections.value.find((opt) => opt.id === option.id)) {
            removeOption(option);
        } else {
            selections.value = [...selections.value, option];
        }

        emit('updated', selections.value);
    };

    const removeOption = (option) => {
        selections.value = selections.value.filter((opt) => opt.id !== option.id);
    };

    const clearSelections = () => {
        if(confirm('Are you sure you want to remove all of your selections?')) {
            selections.value = [];
        }
    };
</script>
