<template>
    <Head title="Edit Prior Period Adjustment" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-invoices.index')" class="breadcrumb-link">Client Invoices </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link v-if="fiscalPeriod" :href="$route('revenue.prior-period-adjustments', fiscalPeriod)" class="breadcrumb-link">Billable Revenue</inertia-link>
            <inertia-link v-else :href="$route('revenue.index')" class="breadcrumb-link">Billable Revenue</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit Prior Period Adjustment</span>
        </nav>
    </Teleport>

    <form id="create-prior-period-adjustment-form" class="space-y-8 divide-y divide-gray-200" @submit.prevent="submitForm">
        <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">Prior Period Adjustment</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">A prior period adjustment allows users to offset client line items when services are started/terminated outside the normal operating period.</p>
        </div>

        <div class="grid lg:grid-cols-4 gap-5 grid-cols-1">
            <div>
                <label class="form-label">
                    Client Service
                </label>
                <a class="link" :href="$route('services.show', priorPeriodAdjustment.service_id)">{{ priorPeriodAdjustment.service_id }}</a>
            </div>

            <select-input label="Line Item Category" v-model="form.category" :errors="errors.category">
                <option v-for="lineItemCategory in lineItemCategoryOptions" :key="lineItemCategory.name" :value="lineItemCategory.name">{{ lineItemCategory.name }}</option>
            </select-input>
            <money-input label="Amount" :min="null" :defaultCurrency="$page.props.currency" v-model="form.amount" :errors="errors.amount"></money-input>
            <div></div>
            <date-input label="Start Date" v-model="form.start_date" :errors="errors.start_date" :help-text="dateWarning"></date-input>
            <date-input label="End Date" v-model="form.end_date" :errors="errors.end_date"></date-input>
            <textarea-input class="lg:col-span-2" label="Description" v-model="form.description" :errors="errors.description"></textarea-input>
        </div>

        <div class="pt-5">
            <div class="flex float-left">
                <loading-button :disabled="state !== 'passive'" :loading="state === 'deleting'" :class="{'bg-gray-600': state === 'deleting', 'text-white': state === 'deleting', 'hover:bg-gray-600': state === 'deleting'}" class="btn btn-gray" @click.stop.prevent="removePriorPeriodAdjustment">
                    <icon v-if="state != 'deleting'" class="h-4 w-4 mr-2 fill-current inline" name="trash"></icon>
                    Delete
                </loading-button>
            </div>
            <div class="flex justify-end">
                <inertia-link v-if="fiscalPeriod" :href="$route('revenue.prior-period-adjustments', [fiscalPeriod.id])" class="btn btn-gray mr-3">Cancel</inertia-link>
                <inertia-link v-else :href="$route('revenue.index')" class="btn btn-gray mr-3">Cancel</inertia-link>
                <loading-button :disabled="state !== 'passive'" type="submit" :loading="state === 'saving'" class="btn btn-orange">Save</loading-button>
            </div>
        </div>
    </form>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            TextInput,
            TextareaInput,
            DateInput,
            SelectInput,
            MoneyInput,
            LoadingButton,
            Head,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            priorPeriodAdjustment: Object,
            lineItemCategories: Array,
            fiscalPeriod: null|Object
        },

        data() {
            return {
                form: {
                    ...this.priorPeriodAdjustment
                },
                state: 'passive',
                dateWarning: null,
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm() {
                this.state = 'saving'

                this.$inertia.put(this.$route('prior-period-adjustments.update', [this.priorPeriodAdjustment.id]), this.form, {
                    preserveScroll: true,
                    onFinish: () => this.state = 'passive',
                });
            },

            removePriorPeriodAdjustment() {
                this.state = 'deleting'

                this.$inertia.delete(this.$route('prior-period-adjustments.destroy', [this.priorPeriodAdjustment.id]), {}, {
                    preserveScroll: true,
                    onFinish: () => this.state = 'passive',
                });
            }
        },

        watch: {
            'form.start_date'() {
                if (this.form.start_date != this.priorPeriodAdjustment.start_date) {
                    this.dateWarning = "Changing the date will alter this prior period adjustment's fiscal period.";
                } else {
                    this.dateWarning = null
                }
            }
        },

        computed:{
            lineItemCategoryOptions() {
                return this.lineItemCategories
                    .sort((a, b) => a.name.localeCompare(b.name));
            }
        }
    }
</script>