<template>
    <Head :title="`Edit Vendor - ${vendor.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendors.index')" class="breadcrumb-link">Vendors</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendors.show', [vendor.id])" class="breadcrumb-link">
                {{ vendor.name }}
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <form id="edit-vendor-form" class="space-y-8" @submit.prevent="submitForm">
        <div class="mt-8 mb-12">
            <fieldset class="space-y-8 sm:space-y-5">
                <div class="space-y-6">
                    <div :key="vendorTemplatesFiltered" class="grid grid-cols-1 sm:grid-cols-3 gap-x-4 gap-y-5 ">
                        <search-input v-model="form.vendor_template_id"
                                      display-property="name"
                                      route="json.vendor-templates.index"
                                      :route-params="{parent_vendor_company_id: form.parent_vendor_company_id, state_code: stateCode,  vendor_id: vendor.id}"
                                      :errors="errors.vendor_template_id"
                                      :default-value="selectedVendorTemplate"
                                      :disabled="form.vendor_template_id"
                                      label="Vendor">
                            <template #searchResult="obj">
                                <span class="block mb-1">{{ obj.result.name }}</span>
                                <span class="block text-sm font-bold"> {{
                                        JSON.parse(obj.result.physical_address).city
                                    }}</span>
                                <span class="text-sm">{{ JSON.parse(obj.result.physical_address).state }}</span>
                            </template>
                        </search-input>
                    </div>
                    <button v-if="form.vendor_template_id" @click="removeVendorTemplate" class="btn btn-red">Remove</button>
                </div>

                <div class="space-y-6">
                    <fieldset class="grid lg:grid-cols-4">
                        <legend class="text-lg leading-6 font-medium text-gray-900">Basic Info</legend>
                        <div class="m-2 my-4 gap-y-6">
                            <Combobox as="div" v-model="form.service_type_offering_ids" multiple>
                                <ComboboxLabel class="form-label" for="service_type_offering_ids">Service
                                    Offerings
                                </ComboboxLabel>
                                <div class="relative">
                                    <ComboboxInput id="service_type_offering_ids" name="service_type_offering_ids"
                                                   class="form-input"
                                                   @change="serviceTypeOfferingComboBoxQuery = $event.target.value"
                                                   :display-value="comboDisplayValue" autocomplete="off"
                                    />
                                    <ComboboxButton
                                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                        <!-- Heroicon name: solid/selector -->
                                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                                  clip-rule="evenodd"/>
                                        </svg>
                                    </ComboboxButton>

                                    <ComboboxOptions v-if="filteredServiceTypeOfferings.length > 0"
                                                     class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        <ComboboxOption v-for="offering in filteredServiceTypeOfferings"
                                                        :key="offering.id" :value="offering.id" as="template"
                                                        v-slot="{ active, selected }">
                                            <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                            <span :class="['block truncate', selected && 'font-semibold']">
                                                {{ offering.name }}
                                            </span>
                                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
                                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clip-rule="evenodd"/>
                                                </svg>
                                            </span>
                                            </li>
                                        </ComboboxOption>
                                    </ComboboxOptions>
                                </div>
                            </Combobox>
                        </div>

                        <text-input
                            v-model="form.name" label="Name" placeholder="Name" class="m-2 my-4 gap-y-6"
                            :errors="errors.name" mark-as-required></text-input>

                        <select-input class="m-2 my-4 gap-y-6"
                                      v-model="form.has_gps_tracking_on_trucks" label="GPS Tracking on Trucks?"
                                      :errors="errors.has_gps_tracking_on_trucks">
                            <option :value="null">Unsure</option>
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select-input>
                    </fieldset>

                    <fieldset class="grid lg:grid-cols-4">
                        <legend class="text-lg leading-6 font-medium text-gray-900">Contact Information</legend>
                        <text-input class="m-2 my-4 gap-y-6"
                                    v-model="form.general_email_address" label="General Email Address"
                                    placeholder="general_email_address" :errors="errors.general_email_address"/>
                        <text-input class="m-2 my-4 gap-y-6"
                                    v-model="form.general_phone_number" label="General Phone #"
                                    placeholder="general_phone_number" :errors="errors.general_phone_number"/>
                        <text-input class="m-2 my-4 gap-y-6"
                                    v-model="form.general_fax_phone_number" label="General Fax Phone #"
                                    placeholder="general_fax_phone_number"
                                    :errors="errors.general_fax_phone_number"/>
                        <text-input
                            v-model="form.check_remittance_address"
                            label="Check Remittance Address"
                            class="m-2 my-4 gap-y-6"
                            placeholder="check_remittance_address"
                            :errors="errors.check_remittance_address"
                        />

                        <text-input
                            v-model="form.legal_mailing_address"
                            label="Legal Mailing Address"
                            class="m-2 my-4 gap-y-6"
                            placeholder="legal_mailing_address"
                            :errors="errors.legal_mailing_address"
                        />
                    </fieldset>

                    <fieldset class="">
                        <legend class="text-lg leading-6 font-medium text-gray-900">Other Information</legend>
                        <div class="grid md:grid-cols-4 grid-cols-1">
                            <text-input v-model="form.external_reference_id" label="External Reference Id"
                                        class="m-2 my-4 " :errors="errors.external_reference_id"/>
                            <select-input v-model="form.external_accounting_id" label="External Accounting Id"
                                          :errors="errors.external_accounting_id"
                                          placeholder="Please select an external accounting vendor"
                                          class="m-2 my-4 ">
                                <option v-for="externalAccountingVendor in sortedExternalAccountingVendors"
                                        :key="externalAccountingVendor.id"
                                        :value="externalAccountingVendor.external_accounting_id">
                                    {{ externalAccountingVendor.name }}
                                </option>
                            </select-input>
                            <text-input class="m-2 my-4 "
                                        v-model="form.work_order_request_email_address"
                                        label="Work Order Request Email Address"
                                        placeholder="Work Order Request Email Address"
                                        :errors="errors.work_order_request_email_address"/>
                        </div>
                    </fieldset>

                    <fieldset class="m-0">
                        <address-picker
                            v-model="form.physical_address"
                            legend="Physical Address"
                            description="The physical address of this vendor (including lat/long information if available)."
                            class="mt-6"
                            :errors="$filterErrorsByPrefix(errors, 'physical_address')"
                        />
                    </fieldset>
                </div>
            </fieldset>
        </div>

        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Certificate of Insurance</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Certificate of Insurance documents for this
                        vendor.</p>
                </div>
            </legend>

            <div class="grid gap-x-6 gap-y-8 grid-cols-2">
                <div class="col-span-1">
                    <label class="form-label">Attach New (COI)</label>

                    <input ref="coi_document" @change="coiDocumentSelected=true" type="file">
                    <div v-if="errors.coiDocument?.coi_document" class="form-error">
                        {{ errors.coiDocument?.coi_document }}
                    </div>
                </div>

                <div class="col-span-2 md:col-span-1">
                    <label class="form-label">Certificate of Insurance Valid Until</label>
                    <date-input v-model="form.coi_valid_until_date"
                                :errors="errors.coiDocument?.coi_valid_until_date"/>
                </div>

                <div class="">
                    <label class="form-label mb-2">Attach New (COI)</label>
                    <input class="" ref="coi_document" @change="coiDocumentSelected=true" type="file">
                    <div v-if="errors.coiDocument?.coi_document" class="form-error">
                        {{ errors.coiDocument?.coi_document }}
                    </div>
                </div>


                <div class="">
                    <loading-button
                        :loading="state === 'attaching-document'"
                        class="btn btn-gray"
                        :disabled="!(coiDocumentSelected && form.coi_valid_until_date)"
                        @click="attachCoiDocument">
                        Attach Document
                    </loading-button>
                </div>
            </div>

            <div class="w-full py-6">
                <span class="font-semibold">COI Document History</span>

                <table v-if="vendor.certificate_of_insurance_history.length" class="mt-6 table table-striped">
                    <thead>
                    <th>File</th>
                    <th>Uploaded at</th>
                    <th>Uploaded by</th>
                    <th>Valid until</th>
                    </thead>

                    <tbody>
                    <tr v-for="coi in vendor.certificate_of_insurance_history"
                        :key="`${coi.uploaded_at}_${coi.document_name}`">
                        <td>
                            <a target="_blank" :href="'/documents/preview?path=' + coi.document_path" class="link">
                                <icon name="file-pdf"
                                      class="inline fill-current text-red-500 hover:text-red-600 w-6 h-6"/>
                                {{ coi.document_name }}
                            </a>
                        </td>
                        <td>{{ $filters.format_date_time(coi.uploaded_at) }}</td>
                        <td>{{ coi.uploaded_by }}</td>
                        <td>{{ $filters.format_date(coi.valid_until_date) }}</td>
                    </tr>
                    </tbody>
                </table>

                <p v-else class="text-center text-xl text-gray-900 my-6">No COI documents on file for this
                    vendor.</p>
            </div>
        </fieldset>
        <div class="w-full flex flex-wrap">
            <loading-button :loading="state === 'saving'" class="btn btn-orange">
                Save Changes
            </loading-button>
        </div>
    </form>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import AddressPicker from '@/Shared/AddressPicker.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Modal from '@/Shared/Modal.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from'@/Shared/TextareaInput.vue';
    import QuillEditor from '@/Shared/QuillEditor.vue';
    import ToggleSwitchInput from "../../Shared/ToggleSwitchInput.vue";
    import axios from "axios";
    import SearchInput from "../../Shared/SearchInput.vue";

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Icon,
            AddressPicker,
            DateInput,
            LoadingButton,
            Modal,
            Pagination,
            SelectInput,
            TextInput,
            TextareaInput,
            QuillEditor,
            Head,
            SearchInput,
            ToggleSwitchInput,

            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({
                    vendor: {},
                    coiDocument: {}
                })
            },

            vendor: {
                type: Object,
                required: true
            },

            externalAccountingVendors: {
                type: Array,
                required: true
            },

            serviceTypeOfferings: {
                type: Array,
                required: true
            },

            parentVendorCompanies: {
                type: Array,
                required: true
            },

            vendorTemplate: {
                type: Object,
                required: false
            },
        },

        data() {
            const {offerings, ...vendor} = this.vendor;
            let states = [];
            let vendorTemplates = [];

            axios.get(this.$route('json.vendor-templates.states', {parent_vendor_company_id: this.vendorTemplate?.parent_vendor_company_id ?? null})).then((response) => {
                this.states = response.data.map((key, value) => key.state_code);
            });

            return {
                form: {
                    ...vendor,
                    service_type_offering_ids: this.vendor.offerings.map(offering => offering.id),
                },
                state: 'passive',
                coiDocumentSelected: false,
                serviceTypeOfferingComboBoxQuery: '',
                selectedVendorTemplate: {...this.vendorTemplate},
                vendorCompanyVendorTemplates: this.vendorTemplates,
                stateCode: this.vendorTemplate ? JSON.parse(this.vendorTemplate.physical_address).state_code : null,
                vendorTemplates: vendorTemplates,
                states: states,
                parentVendorCompanyId: this.vendor.parent_vendor_company_id,
                vendorTemplatesFiltered: 0,
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm() {
                if (this.state === 'passive') {
                    let confirmed = true;

                    if (this.vendor.name !== this.form.name) {
                        confirmed = confirm("You're attempting to change the vendor's name. This action will update the description for services attached to this vendor, affecting both current and historical data. Are you sure you want to proceed?");
                    }

                    if (confirmed) {
                        this.state = 'saving';

                        this.$inertia
                            .put(this.$route('vendors.update', this.vendor.id), this.form, {
                                onFinish: () => this.state = 'passive'
                            });
                    }
                }
            },

            attachCoiDocument() {
                if (this.state === 'passive') {
                    this.state = 'attaching-document';
                
                    let formData = new FormData();

                    if (this.$refs.coi_document && this.$refs.coi_document.files[0] && this.form.coi_valid_until_date) {
                        formData.append('coi_document', this.$refs.coi_document.files[0]);
                        formData.append('coi_valid_until_date', this.form.coi_valid_until_date);
                    }

                    this.$inertia
                        .post(this.$route('vendors.attach-coi-document', this.vendor.id), formData, {
                            errorBag: 'coiDocument',
                            preserveState: false,
                            preserveScroll: true,
                            onFinish: () => this.state = 'passive'
                        });
                }
            },

            comboDisplayValue(options) {
                if (Array.isArray(options)) {
                    if (options.length > 1) {
                        return `${options.length} offerings selected`;
                    } else if (options.length === 1) {
                        return '1 offering selected';
                    } else {
                        return 'No offerings selected';
                    }
                }
                return options ?? 'No offerings selected';
            },

            removeVendorTemplate() {
                if (window.confirm('Are you sure you want to unattach this vendor? This will not affect current data.')) {
                    this.form.vendor_template_id = null;
                    this.selectedVendorTemplate = null;
                    this.stateCode = null;
                    this.vendorTemplatesFiltered++;
                }
            },
        },

        computed: {
            filteredServiceTypeOfferings() {
                return this.serviceTypeOfferingComboBoxQuery === ''
                    ? this.serviceTypeOfferings
                    : this.serviceTypeOfferings.filter((offering) => {
                        return offering.name.toLowerCase().includes(this.serviceTypeOfferingComboBoxQuery.toLowerCase());
                    });
            },

            sortedExternalAccountingVendors() {
                return this.externalAccountingVendors?.sort((a,b) => a.name.localeCompare(b.name));
            },
        }
    }
</script>
