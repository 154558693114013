<template>
    <div>
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 px-4">
            <div class="md:col-span-3">
                <date-input v-model="actual_date" label="Date of Change" helpText="When should this change happen?" :errors="errors['actual_date']" :markAsRequired="true" />
            </div>

            <div class="md:col-span-3" v-if="actual_date && actual_date !== today">
                <div class="rounded-md bg-yellow-50 p-4" v-if="actual_date && actual_date < today">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <icon name="info-circle" class="size-5 text-yellow-400 fill-current" />
                        </div>
                        <div class="ml-3 flex-1">
                            <p class="text-base text-yellow-700">
                                NOTE: This will insert a historical record that something was incorrect and will update various reporting data. This correction will also show up in the historical time line of the service which may help with auditing. This correction will retroactively alter the service, but will not alter any related data (such as old invoice line items).
                            </p>
                        </div>
                    </div>
                </div>
                <div class="rounded-md bg-blue-50 p-4" v-if="actual_date && actual_date > today">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <icon name="info-circle" class="size-5 text-blue-400 fill-current" />
                        </div>
                        <div class="ml-3 flex-1">
                            <p class="text-base text-blue-700">
                                NOTE: This will insert a scheduled change that will not affect the service until the chosen date. You may cancel this change at any time prior to the chosen date.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div :class="[actual_date && actual_date === today ? 'md:col-span-3' : 'col-span-1 md:col-span-6']">
                <select-input v-model="change_reason" label="Reason for Change" :errors="errors['change_reason']" :markAsRequired="true">
                    <option>Savings</option>
                    <option>Other</option>
                </select-input>
            </div>

            <div class="col-span-1 md:col-span-3">
                <select-input v-model="form.service_account_type" label="Service Account Type" :errors="errors['service_account_type']" :markAsRequired="true" >
                    <option>Permanent</option>
                    <option>Temporary</option>
                </select-input>
            </div>

            <div class="col-span-1 md:col-span-3">
                <select-input
                    v-if="service.serviceType.enabled_type_fields?.includes('service_schedule_info')"
                    v-model="form.service_schedule_type"
                    label="Service Schedule Type"
                    :errors="errors['service_schedule_type']"
                    markAsRequired
                >
                    <option>Scheduled</option>
                    <option>On Call</option>
                    <option v-if="form.service_account_type === 'Temporary'">One Time</option>
                </select-input>
            </div>

            <div v-if="service.serviceType.enabled_type_fields.includes('service_schedule_info') && form.service_schedule_type === 'Scheduled'" class="col-span-1 md:col-span-6">
                <label for="pickup-schedule" class="block font-medium leading-6 text-gray-900">
                    <span class="text-red-500">*</span> Schedule
                </label>
                <div class="flex flex-col items-start gap-6 lg:gap-12 mt-2 md:flex-row">
                    <div class="md:flex-shrink-0" :class="[form?.pickup_rrule?.freq === 'WEEKLY' ? 'md:max-w-48' : 'md:max-w-full']">
                        <div class="flex flex-col gap-3">
                            <span>{{ rrule_description }}</span>
                            <div v-if="errors['pickup_rrule'] || errors['average_pickups_per_week']" class="text-red-500">
                                The pickup schedule is invalid
                            </div>
                            <a class="link cursor-pointer text-base inline-flex items-center gap-1" @click.prevent="showUpdatePickupRRuleModal">
                                Edit Schedule <icon name="edit" class="inline fill-current w-5 h-6"></icon>
                            </a>
                        </div>
                    </div>
                    <div class="w-full md:flex-1" v-if="form?.pickup_rrule?.freq === 'WEEKLY'">
                        <div class="w-full overflow-hidden rounded-lg grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7">
                            <div
                                v-if="form.pickup_rrule.byday"
                                v-for="(day, id) in daysOfWeek" :key="id" class="w-full group relative text-center px-2"
                            >
                                <div class="text-base border-b pb-1">{{ day }}</div>
                                <div class="flex justify-center items-center p-4 pb-8 lg:pb-0">
                                    <icon name="check" class="text-green-500 fill-current size-4" v-if="form.pickup_rrule.byday.includes(id)"/>
                                    <icon name="times" class="text-gray-300 fill-current size-4" v-else />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-10">
            <div class="relative mb-4">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-start">
                    <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Billing</span>
                </div>
            </div>

            <div class="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 px-4">
                <div class="col-span-1 md:col-span-2">
                    <select-input v-model="form.vendor_account_id" label="Vendor Account" :errors="errors['vendor_account_id']">
                        <option v-if="!vendorAccounts.length" :value="null" selected>No accounts available for this Vendor</option>
                        <option v-if="vendorAccounts.length" :value="null" selected>No account</option>
                        <option v-for="vendorAccount in vendorAccounts" :value="vendorAccount.id">
                            {{ vendorAccount.account_number + " - " + vendorAccount.status }}
                        </option>
                    </select-input>
                </div>

                <div class="col-span-1 md:col-span-2">
                    <select-input v-model="form.vendor_contract_selection" label="Vendor Contract" :errors="errors['vendor_contract_selection']">
                        <option :value=null>No contract</option>
                        <option value='missing-contract'>Missing contract</option>
                        <option v-for="vendorContract in vendorContracts" :value="vendorContract.id" :key="vendorContract.id">
                            {{ vendorContract.display_id + " - " + vendorContract.status + " - Effective " + vendorContract.effective_date ?? " - " }}
                        </option>
                    </select-input>
                </div>

                <div class="col-span-1 md:col-span-2">
                    <select-input v-if="$page.props.permissions.accessRevenueManagement" v-model="form.client_contract_id" label="Client Contract" :errors="errors['client_contract_id']">
                        <option v-if="!clientContracts.length" :value="null">No contracts available for this Client</option>
                        <option v-if="clientContracts.length" :value="null" selected>No contract</option>
                        <option v-for="clientContract in clientContracts" :key="clientContract.id" :value="clientContract.id">
                            {{ clientContract.id + " - Integration " + clientContract.integration_date ?? " - " }}
                        </option>
                    </select-input>
                </div>

                <money-input
                    v-if="serviceHasBaseCharge"
                    v-model="form.vendor_base_charge"
                    class="col-span-1 md:col-span-2"
                    label="Vendor Base Charge"
                    :defaultCurrency="$page.props.currency"
                    :errors="errors['vendor_base_charge']"
                    markAsRequired
                />

                <money-input
                    v-if="serviceHasPerUnitCharge"
                    v-model="form.vendor_per_unit_charge"
                    class="col-span-1 md:col-span-2"
                    label="Vendor Per Unit Charge"
                    :helpText="perUnitHelpText"
                    :defaultCurrency="$page.props.currency"
                    :errors="errors['vendor_per_unit_charge']"
                    markAsRequired
                />
                <money-input
                    v-if="serviceHasPerOccurrenceCharge"
                    v-model="form.vendor_per_occurrence_charge"
                    class="col-span-1 md:col-span-2"
                    label="Vendor Per Occurrence Charge"
                    :helpText="perOccurrenceHelpText"
                    :defaultCurrency="$page.props.currency"
                    :errors="errors['vendor_per_occurrence_charge']"
                    markAsRequired
                />

                <text-input
                    type="number"
                    min="0"
                    v-if="service.serviceType?.enabled_type_fields.includes('units_included_per_occurrence')"
                    v-model="form.units_included_per_occurrence"
                    class="col-span-1 md:col-span-2"
                    label="Units Included Per Occurrence"
                    :helpText="unitsIncludedPerOccurrenceHelpText"
                    :errors="errors['units_included_per_occurrence']"
                    markAsRequired
                />

                <template v-if="$page.props.permissions.accessRevenueManagement && service.is_pass_through === false && service.client_billing_frequency !== 'Not Billable'">
                    <money-input
                        v-if="serviceHasBaseCharge"
                        v-model="form.client_base_charge"
                        class="col-span-1 md:col-span-2"
                        label="Client Base Charge"
                        :defaultCurrency="$page.props.currency"
                        :errors="errors['client_base_charge']"
                        markAsRequired
                    />
                    <money-input
                        v-if="serviceHasPerUnitCharge"
                        v-model="form.client_per_unit_charge"
                        class="col-span-1 md:col-span-2"
                        label="Client Per Unit Charge"
                        :helpText="perUnitHelpText"
                        :defaultCurrency="$page.props.currency"
                        :errors="errors['client_per_unit_charge']"
                        markAsRequired
                    />
                    <money-input
                        v-if="serviceHasPerOccurrenceCharge"
                        v-model="form.client_per_occurrence_charge"
                        class="col-span-1 md:col-span-2"
                        label="Client Per Occurrence Charge"
                        :helpText="perOccurrenceHelpText"
                        :defaultCurrency="$page.props.currency"
                        :errors="errors['client_per_occurrence_charge']"
                        markAsRequired
                    />
                </template>
            </div>

            <template v-if="showPriorPeriodAdjustment">
                <div class="mt-12 px-4">
                    <div class="rounded-md bg-yellow-50 p-4 mt-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <icon name="exclamation-triangle" class="size-5 text-yellow-400 fill-current" />
                            </div>
                            <div class="ml-3 flex-1 text-base">
                                <h3 class="font-medium text-yellow-800">Prior Period Adjustment</h3>
                                <div class="mt-2 text-yellow-700">
                                    <div class="pr-2 space-y-2">
                                        <p>
                                            You are changing the price from
                                            <span class="font-bold">{{ $filters.format_money(service.client_base_charge) }}</span> to
                                            <span class="font-bold">{{ $filters.format_money(form.client_base_charge) }}</span> effective
                                            <span class="font-bold">{{ $filters.format_date(actual_date) }}</span>.
                                        </p>
                                        <p v-if="priorPeriodAdjustment.lastLineItemBilled">
                                            This service has already been billed through the end of
                                            <span class="font-bold">{{ $filters.format_date(priorPeriodAdjustment.lastLineItemBilled.origin_date) }}</span>
                                        </p>
                                        <p>If you do not wish to bill a prior period adjustment for this change, please enter in $0.00 below and no charge will be made.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid grid-cols-2 gap-6 px-4 mt-8">
                    <money-input class="col-span-1" label="Prorated Amount" :defaultCurrency="$page.props.currency" v-model="priorPeriodAdjustment.proratedAmount"></money-input>
                    <textarea-input class="col-span-2" label="Line Item Description" v-model="priorPeriodAdjustment.description"></textarea-input>
                </div>
            </template>
        </div>

        <div v-if="service.serviceType?.enabled_type_fields.includes('bin')" class="mt-10">
            <div class="relative mb-4">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-start">
                    <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Bin</span>
                </div>
            </div>

            <div class="w-full mt-4 px-4">
                <text-input v-model="form.label" maxlength="50" class="md:w-1/3" :errors="errors['label']" label="Equipment Label"/>
            </div>

            <div class="mt-4 px-4">
                <bin v-model="form.bin"
                    :property-type="service.location.property_type"
                    :bin-types="binTypes"
                    :material-types="materialTypes"
                    :asset-owners="assetOwners"
                    :fields-are-required="true"
                    :errors="errors"
                />
            </div>
        </div>

        <div class="relative mt-10 mb-4">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-start">
                <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Other</span>
            </div>
        </div>
        <div class="px-4">
            <textarea-input rows="4" v-model="internal_change_notes" label="Internal Change Note" :errors="errors['internal_change_notes']" />
        </div>

        <loading-button class="rounded-md bg-d-orange-500 px-3 py-2 mt-12 text-base font-semibold text-white shadow-sm hover:bg-d-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-d-orange-500 disabled:opacity-50" :loading="saving" @click="submitChange" :disabled="!actual_date || !change_reason">
            Submit Change
        </loading-button>

        <!-- Pickup RRule Modal -->
        <modal v-if="service.serviceType?.enabled_type_fields.includes('service_schedule_info')" class="max-w-2xl text-base" ref="updatePickupRRuleModal" tabindex="-1" role="dialog">
            <template #modal-header="{close}">
                <div class="p-4 border-b border-gray-200 flex justify-between">
                    <div class="my-1 text-2xl">
                        Update Schedule
                    </div>

                    <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                        <icon name="times" class="fill-current inline w-6 h-6"/>
                    </button>
                </div>
            </template>

            <template #modal-body>
                <div class="p-4">
                    <r-rule-input
                        ref="rRuleInput"
                        v-model:modelValue="form.pickup_rrule"
                        v-model:weeklyAverage="form.average_pickups_per_week"
                        v-model:description="rrule_description"
                        start-date-label="First Pickup"
                        weekly-average-label="Avg. Pickups Per Week"
                        :start-with-schedule="true"
                    />
                </div>
            </template>

            <template #modal-footer="{close}">
                <div class="p-4 flex justify-end">
                    <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script setup>
    import axios from 'axios';
    import { format, startOfToday } from "date-fns";
    import { router, usePage } from '@inertiajs/vue3';
    import { reactive, ref, inject, computed, onMounted, watch } from 'vue';

    import Icon from '@/Shared/Icon.vue';
    import Modal from '@/Shared/Modal.vue';
    import Bin from '@/Shared/Services/Bin.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import RRuleInput from '@/Shared/RRuleInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';

    /**
     * Props
     */
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },

        binTypes: {
            type: Array,
            required: true
        },

        materialTypes: {
            type: Array,
            required: true
        },

        assetOwners: {
            type: Array,
            required: true
        },

        service: {
            type: Object,
            required: true
        },

        vendorAccounts: {
            type: Array,
            required: true
        },

        vendorContracts: {
            type: Array,
            required: true
        },

        clientContracts: {
            type: Array,
            default: []
        },

        who: {
            type: String,
            required: true
        },

        earliestServiceActivity: {
            type: Object,
            required: true
        }
    });

    /**
     * Injected
     */
    const route = inject('route');
    const page = usePage();

    /**
     * Data
     */
    const saving = ref(false);
    const actual_date = ref(format(startOfToday(), 'yyyy-MM-dd'));
    const change_reason = ref(null);
    const change_requester = ref(props.who);
    const internal_change_notes = ref(null);
    const today = ref(format(startOfToday(), 'yyyy-MM-dd'));
    const rrule_description = ref(null);
    const updatePickupRRuleModal = ref(null);
    const priorPeriodAdjustment = ref(null);
    const form = reactive({
        ...props.service,
    });

    const daysOfWeek = reactive({
        'SU': 'Sunday',
        'MO': 'Monday',
        'TU': 'Tuesday',
        'WE': 'Wednesday',
        'TH': 'Thursday',
        'FR': 'Friday',
        'SA': 'Saturday',
    });

    const perUnitHelpText = props.service.serviceType.waste_generation_type === 'metered' ? "(Disposal Amount Per Ton)" : null;
    const perOccurrenceHelpText = props.service.serviceType.waste_generation_type === 'metered' ? "(Haul Amount)" : null;
    const unitsIncludedPerOccurrenceHelpText = props.service.serviceType.waste_generation_type === 'metered' ? "(Tons Included Per Haul)" : null;

    const showPriorPeriodAdjustment = computed(() => {
        return suggestPriorPeriodAdjustment()
            && priorPeriodAdjustment.value?.proratedAmount?.amount
            && priorPeriodAdjustment.value?.proratedAmount?.amount != 0;
    });

    const serviceHasBaseCharge = computed(() => {
        return props.service.serviceType.enabled_type_fields.includes('base_charges');
    });

    const serviceHasPerUnitCharge = computed(() => {
        return props.service.serviceType.enabled_type_fields.includes('per_unit_charges');
    });

    const serviceHasPerOccurrenceCharge = computed(() => {
        return props.service.serviceType.enabled_type_fields.includes('per_occurrence_charges');
    });

    onMounted(() => {
        if (suggestPriorPeriodAdjustment()) {
            updatePriorPeriodAdjustmentDetails();
        }
    });

    /**
     * Methods
     */
     function suggestPriorPeriodAdjustment() {
        const conditions = [
            page.props.permissions.accessRevenueManagement,
            !props.service.is_pass_through,
            ['Monthly', 'Quarterly', 'Yearly'].includes(props.service.client_billing_frequency),
            form.service_schedule_type !== 'One Time',
            serviceHasBaseCharge.value,
            props.service.client_base_charge !== form.client_base_charge,
        ];

        return conditions.every(condition => condition === true);
    }

    function updatePriorPeriodAdjustmentDetails() {
        const adjusted_amount = form.client_base_charge.amount / 100;
        const change_date = actual_date.value;
        const service_id = props.service.id;

        let url = `/json/services/${service_id}/prior-period-adjustments/create-from-price-change?change_date=${change_date}&adjusted_amount=${adjusted_amount}`;
        axios.get(url).then(response => {
            priorPeriodAdjustment.value = response.data;
        });
    }

    function showUpdatePickupRRuleModal() {
        updatePickupRRuleModal.value.show();
    }

    function submitChange() {
        saving.value = true;

        router.post(route(`services.level-of-service-changes.store`, [props.service.id]), {
            ...form,
            actual_date: actual_date.value,
            change_reason: change_reason.value,
            change_requester: props.who,
            internal_change_notes: internal_change_notes.value,
            ppa_amount: showPriorPeriodAdjustment ? priorPeriodAdjustment.value?.proratedAmount : null,
            ppa_description: showPriorPeriodAdjustment ? priorPeriodAdjustment.value?.description : null,
        }, {
            onFinish: () => saving.value = false
        });
    }

    watch(form, (newValue, oldValue) => {
        if (suggestPriorPeriodAdjustment()) {
            updatePriorPeriodAdjustmentDetails();
        }
    }, { deep: true });

    watch(actual_date, () => {
        if (suggestPriorPeriodAdjustment()) {
            updatePriorPeriodAdjustmentDetails();
        }
    });
</script>
