<template>
    <Head :title="`Edit Incident Type - ${incidentType.name} (${incidentType.id})`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.incident-types.index')" class="breadcrumb-link">Incident Types</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{incidentType.name}} ({{incidentType.id}})</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <form id="edit-incident-type-form" class="grid grid-cols-2 gap-4">
        <text-input v-model="form.name" class="col-span-2 md:col-span-1" id="name" name="name" label="Name" :errors="errors.name" />

        <div class="col-span-2 md:col-span-1">
            <Combobox as="div" v-model="form.reasons" multiple>
                <ComboboxLabel class="form-label" for="type">Reason</ComboboxLabel>

                <div class="relative">
                    <ComboboxInput id="type" name="type" class="form-input" @change="comboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />

                    <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <!-- Heroicon name: solid/selector -->
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </ComboboxButton>

                    <ComboboxOptions v-if="incidentReasons.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ComboboxOption v-for="reason in filteredReasonOptions" :key="reason" :value="reason" as="template" v-slot="{ active, selected }">
                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                            <span :class="['block truncate', selected && 'font-semibold']">
                            {{ reason.name }}
                            </span>

                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                <!-- Heroicon name: solid/check -->
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                </div>
            </Combobox>
        </div>

        <div class="col-span-2 flex flex-wrap">
            <inertia-link :href="$route('tenant-settings.incident-types.index', incidentType.id)" class="btn btn-gray mr-4">
                Cancel
            </inertia-link>

            <loading-button :loading="state === 'saving'" class="btn btn-orange mr-4" @click.prevent="updateIncidentType">
                Save
            </loading-button>

            <button class="action-link" @click.prevent="remove">
                <icon name="trash" class="w-4 h-4 text-red-500 fill-current mr-4" />
                Remove
            </button>
        </div>
    </form>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Head,
            Icon,
            SelectInput,
            TextInput,
            LoadingButton,
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            incidentType: {
                type: Object,
                required: true
            },

            incidentReasons: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                form: {
                    ...this.incidentType,
                    reasons: this.incidentReasons.filter(reason => this.incidentType.reasons.find(incidentTypeReason => incidentTypeReason.id === reason.id))
                },
                comboBoxQuery: '',
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            updateIncidentType() {
                this.state = 'saving';

                this.$inertia.patch(this.$route('tenant-settings.incident-types.update', this.incidentType.id), this.form, {
                    onFinish: () => this.state = 'passive'
                });
            },

            remove() {
                let confirmed = confirm('Are you sure you want to remove this incident type?');

                if (confirmed) {
                    this.$inertia.delete(this.$route('tenant-settings.incident-types.destroy', [this.form.id]));
                }
            },
            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} reasons selected`;
                    } else if (option.length === 1) {
                        return '1 reason selected';
                    } else {
                        return 'No reasons selected';
                    }
                }

                return option ?? 'No reasons selected';
            },
        },

        computed: {
            filteredReasonOptions() {
                return this.comboBoxQuery === ''
                    ? this.incidentReasons
                    : this.incidentReasons.filter((type) => {
                        return type.toLowerCase().includes(this.comboBoxQuery.toLowerCase());
                    });
            },
        }
    }
</script>