<template>
	<Head title="Reset Password"/>

	<div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
		<div class="sm:mx-auto sm:w-full sm:max-w-md">
			<logo name="mark-dark" class="w-auto h-24 mx-auto"/>
		</div>

		<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
			<div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
				<form v-if="!passwordReset" action="#" method="POST" @submit.prevent="submitForm">
					<div class="grid grid-cols-1 gap-2">
						<text-input label="New Password" type="password" class="col-span-2 md:col-span-1" v-model="form.password" :errors="errors.password" required/>
					</div>

					<div class="mt-6">
                        <span class="block w-full rounded-md shadow-sm">
                            <loading-button :loading="submitting" type="submit" class="w-full flex justify-center btn btn-orange">
                                Reset Password
                            </loading-button>
                        </span>
					</div>
				</form>
				<div v-else>
					<div class="text-center">
						<p class="text-lg text-gray-700">Your password has been reset.</p>
						<a class="btn btn-orange mt-6" :href="clientPortalLink">Continue To Client Portal</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LoadingButton from '@/Shared/LoadingButton.vue';
import TextInput from '@/Shared/TextInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import BooleanCheckboxInput from "@/Shared/BooleanCheckboxInput.vue";
import BlankLayout from "@/Shared/BlankLayout.vue";
import {Head} from '@inertiajs/vue3'
import Logo from "../../Shared/Logo.vue";

export default {
	layout: BlankLayout,

	components: {
		Logo,
		BooleanCheckboxInput,
		LoadingButton,
		TextInput,
		SelectInput,
		Head,
	},

	props: {
		email: {
			type: String,
			required: true
		},

		clientId: {
			type: String,
			required: true,
		},

		storeRoute: {
			type: String,
			required: true
		},

		clientPortalLink: {
			type: String,
			required: true,
		},

		errors: {
			type: Object,
			default: () => ({})
		},
	},

	data() {
		return {
			form: {
				password: ''
			},
			submitting: false,
			passwordReset: false,
		}
	},

	methods: {
		submitForm() {
			this.submitting = true;

			this.$inertia.post(this.storeRoute, {...this.form, 'client_id': this.clientId}, {
				onFinish: () => {
					this.submitting = false;
					this.passwordReset = true;
				}
			});
		}
	}
}
</script>