function add_money(addend1, addend2)
{
    let sum = { ...addend1 };

    if (addend1.currency !== addend2.currency) {
        return addend1;
    }

    sum.amount = parseInt(sum.amount) + parseInt(addend2.amount);

    return sum;
}

function subtract_money(minuend, subtrahend)
{
    let difference = { ...minuend };

    if (minuend.currency !== subtrahend.currency) {
        return minuend;
    }

    difference.amount = parseInt(difference.amount) - parseInt(subtrahend.amount)

    return difference;
}

function isSameCurrency(currency1, currency2)
{
    return currency1.code === currency2.code;
}

export {add_money, subtract_money};