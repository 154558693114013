<template>
    <Head :title="`Edit Compactor Monitor Configuration - ${compactorMonitorConfiguration.id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('compactor-monitors.index')" class="breadcrumb-link">Compactor Monitors</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('compactor-monitors.overview', compactorMonitorConfiguration.compactorMonitor.id)" class="breadcrumb-link">{{ compactorMonitorConfiguration.compactorMonitor.id }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />
			<span>Configuration</span>
	        <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />
            <span>Edit</span>
        </nav>
    </Teleport>

	<legend class=" space-y-8 pt-8 pb-1 sm:pt-10">
		<span class="block lg:hidden mt-1 max-w-xl text-4xl text-gray-600">Pioneer AutoPilot Settings</span>
	</legend>
    <form id="edit-compactor-monitor-configuration-form" class="mt-4 space-y-8 lg:grid lg:grid-cols-3 lg:gap-x-14 lg:w-full" @submit.prevent="submitForm">
	    <div class="hidden lg:block col-span-1">
		    <section aria-labelledby="monitor-information-title" class=" sticky top-7 space-y-8 sm:space-y-5">
			    <h2 class="text-2xl md:text-3xl leading-6 font-medium text-gray-900">Pioneer AutoPilot Settings</h2>

			    <div class="bg-white mx-auto shadow sm:rounded-lg ">
				    <div class="px- py-4 lg:px-6">
					    <h2 id="timeline-title" class="text-lg leading-6 font-medium text-gray-900">Service</h2>
				    </div>
				    <div class="border-t border-gray-200 px-4 pb-4 sm:px-6">
					    <div class="mt-6 flow-root">
						    <dl class="grid grid-cols-1 gap-x-4 gap-y-8 lg:grid-cols-2">
							    <div>
								    <dt class="text-sm font-medium text-gray-500">Location</dt>
								    <dd class="mt-1 text-sm ">
									    <inertia-link :href="$route('locations.show', compactorMonitorConfiguration.compactorMonitor.monitoredService.location.id)" class="link">{{ compactorMonitorConfiguration.compactorMonitor.monitoredService.location.name }} </inertia-link>
									    <span class="flex"><i>{{ compactorMonitorConfiguration.compactorMonitor.monitoredService.label }}</i></span>
								    </dd>
							    </div>
							    <div>
								    <dt class="text-sm font-medium text-gray-500">Monitored Service</dt>
								    <dd class="mt-1 text-sm link">
									    <inertia-link :href="$route('services.show', compactorMonitorConfiguration.compactorMonitor.monitoredService.id)">{{ compactorMonitorConfiguration.compactorMonitor.monitoredService.id }} </inertia-link>
								    </dd>
							    </div>
							    <div>
								    <dt class="text-sm font-medium text-gray-500">Vendor</dt>
								    <dd class="mt-1 text-sm link">
									    <inertia-link :href="$route('vendors.show', compactorMonitorConfiguration.compactorMonitor.monitoredService.vendor.id)">{{ compactorMonitorConfiguration.compactorMonitor.monitoredService.vendor.name }} </inertia-link>
								    </dd>
							    </div>
							    <div class="col-span-2">
								    <dt class="text-sm font-medium text-gray-500">Bin </dt>
								    <dd class="mt-1 text-sm ">
									    {{ compactorMonitorConfiguration.compactorMonitor.monitoredService.bin.size }}
									    {{ compactorMonitorConfiguration.compactorMonitor.monitoredService.bin.size_metric}}
									    {{ compactorMonitorConfiguration.compactorMonitor.monitoredService.bin.type }}
									    {{ compactorMonitorConfiguration.compactorMonitor.monitoredService.bin.material_type }}
								    </dd>
							    </div>

						    </dl>
					    </div>
				    </div>
			    </div>
			    <div class="w-full flex">
				    <loading-button type="submit" :loading="state === 'saving'" class="btn btn-orange">
					    Save Changes
				    </loading-button>
			    </div>
		    </section>
	    </div>

	    <div class="lg:col-span-2">
		    <h3 class="text-xl md:text-3xl leading-6 font-medium text-d-orange-500 flex my-5">
			    <toggle-switch-input class="col-span-2 md:col-span-1 mr-2" v-model="form.create_work_orders"  mark-as-required />
			    Predictive Pickup Scheduling
			    <tooltip-icon class="mt-1" tooltip="The monitor sends scheduling alerts based on compactor fullness."/>
		    </h3>
	        <div class="md:shadow-md my-5 py-5 md:py-10 md:px-5 lg:p-10 lg:grid lg:grid-cols-2">
		        <fieldset class="col-span-1 space-y-8 sm:space-y-5 ">
			        <legend class="py-3">
				        <h3 class="text-xl md:text-2xl leading-6 font-medium text-d-orange-500 flex">Scheduling Parameters </h3>
			        </legend>

	                <div class="grid gap-x-4 mt-4 gap-y-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
		                <date-input :tooltip="['This date is for reporting purposes', 'It\'s used in scenarios when there is a vendor change']"
		                            class="col-span-2 lg:col-span-1" tooltipType="list" v-model="form.pickup_management_start_date" label="Pickup Scheduling Start Date" :errors="errors.pickup_management_start_date" :mark-as-required="form.create_work_orders"/>
		                <div class="hidden md:block col-span-2 mb-4">
			                <span>
				                <label class="form-label mb-2 flex" :for="id" :aria-required="markAsRequired">
					                <span class="text-red-500">*</span> Target Percentage Fullness at Pickup
					                <tooltip-icon tooltip="Ideal tonnage at pickup"/>
				                </label>
			                </span>

			                <div class="grid grid-cols-2 text-sm items-center mx-8 ">
				                <div class="text-left flex items-center">{{ form.target_percentage_full }}% of
					                <text-input tooltip="Maximum capacity of the compactor"  type="number" class="ml-2 w-1/3" :min="1" max="16" step="0.01" @blur.native="roundTonnageCapacity"  v-model="roundedTonnageCapacity" :errors="errors.tonnage_capacity" mark-as-required/>
					                <span class="ml-1"> tons Maximum</span>
				                </div>
				                <p class="hidden md:block text-right text-sm">{{ estimatedTonnage }}</p>
				                <p class="block md:hidden text-right text-sm truncate">{{ estimatedTonnage }}</p>
			                </div>

			                <div class="relative mb-4 mx-8">
				                <input ref="input"  :min="25" :step="5" class="w-full accent-d-orange-500 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer" type="range" @input="updateTargetPercentageFull" :value="form.target_percentage_full">
				                <span class="text-sm text-gray-500 dark:text-gray-400 absolute md:mr-3 start-0 -bottom-4">25%</span>
				                <span class="text-sm text-gray-500 dark:text-gray-400 absolute md:ml-2 start-1/3 -translate-x-1/2 rtl:translate-x-1/2 -bottom-4">50%</span>
				                <span class="text-sm text-gray-500 dark:text-gray-400 absolute md:ml-1 start-2/3 pr-2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-4">75%</span>
				                <span class="text-sm text-gray-500 dark:text-gray-400 absolute md:ml- end-0 -bottom-4">100%</span>
			                </div>

		                </div>
		                <text-input label="Target Percentage Fullness at Pickup" class="block md:hidden col-span-2" v-model="form.target_percentage_full" mark-as-required/>
		                <text-input label="Maximum Tonnage Capacity" :min="1" :max="16" class="block md:hidden col-span-2" v-model="roundedTonnageCapacity" mark-as-required/>
		                <text-input :tooltip="['Business days prior to the projected pickup date that a scheduling request will be sent', '<i class=\'text-xs\'><b>Example:</b> Compactor is predicting pickup on Friday. The customer needs an alert 3 days prior, so an alert is generated on Tuesday.</i>']"
		                            type="number"  class="col-span-2" min="0" tooltipType="list" v-model="form.create_work_order_x_days_prior_to_projection" label="Pickup Request Lead Time (business days)" :errors="errors.create_work_order_x_days_prior_to_projection" mark-as-required />
		                <text-input tooltip="Minimum days since last haul before monitor can generate another work order"
		                            type="number"  class="col-span-2" min="0"  v-model="form.min_days_between_pickups" label="Never request pickups MORE frequently than (calendar days)" :errors="errors.min_days_between_pickups" mark-as-required />
	                    <text-input tooltip="Maximum number of days since last haul before monitor must generate another work order"
	                                type="number"  class="col-span-2" min="0"  v-model="form.max_days_between_pickups" label="Never request pickups LESS frequently than (calendar days)" :errors="errors.max_days_between_pickups" />
	                </div>
	            </fieldset>
		        <hr class="block md:hidden my-10 w-full">
		        <fieldset :key="notificationChange" class="col-span-1 space-y-8 sm:space-y-5 lg:border-l-2 lg:pl-10 lg:ml-10  lg:bg-white ">
			        <div class="w-full grid grid-cols-2 gap-4" >
				        <legend class="py-3 col-span-2">
					        <h3 class="text-xl md:text-2xl leading-6 font-medium text-d-orange-500 flex">External Notifications (optional) </h3>
				        </legend>
						<span class="col-span-2 text-md mt-4 flex">Scheduling
					        <tooltip-icon tooltip="Recipients can schedule a haul from the request"/>
						</span>
				        <!--	                Vendor Contacts Work Order Confirmation-->
				        <div class="col-span-2 md:col-span-1 lg:col-span-2 2xl:col-span-1">
					        <Combobox as="div" v-model="form.work_order_confirmation_vendor_contacts" multiple>
						        <ComboboxLabel class="form-label" for="requested_by">Vendor</ComboboxLabel>

						        <div class="relative">
							        <ComboboxInput id="work_order_confirmation_vendor_contacts" name="work_order_confirmation_vendor_contacts" class="form-input" @focus="$event.target.select()" @change="searchVendorContacts($event)" :display-value="comboDisplayValue" autocomplete="off"/>

							        <ComboboxButton  class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
								        <!-- Heroicon name: solid/selector -->
								        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
								        </svg>
							        </ComboboxButton>

							        <ComboboxOptions v-if="vendorContacts.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								        <ComboboxOption v-for="vendorContact in sortedVendorWorkOrderConfirmation" :key="vendorContact.id" :value="vendorContact.id" as="template" v-slot="{ active, selected }">
									        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
				                                    <span :class="['block truncate', selected && 'font-semibold']">
				                                        {{ vendorContact.display_name }}
				                                    </span>
										            <span :class="['block truncate opacity-70', selected && 'font-semibold']">
				                                        {{ vendorContact.email }}
				                                    </span>

										        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
	                                        <!-- Heroicon name: solid/check -->
			                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
			                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
			                                        </svg>
	                                            </span>
									        </li>
								        </ComboboxOption>
							        </ComboboxOptions>
						        </div>
					        </Combobox>
				        </div>

				        <!--	                Clients  Work Order Confirmation-->
				        <div class="col-span-2 md:col-span-1 lg:col-span-2 2xl:col-span-1">
					        <Combobox as="div" v-model="form.work_order_confirmation_clients" multiple>
						        <ComboboxLabel class="form-label" for="requested_by">Client</ComboboxLabel>

						        <div class="relative">
							        <ComboboxInput id="work_order_confirmation_clients" name="work_order_confirmation_clients" class="form-input" @focus="$event.target.select()"  @change="searchClients($event)" :display-value="comboDisplayValue" autocomplete="off"/>

							        <ComboboxButton  class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
								        <!-- Heroicon name: solid/selector -->
								        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
								        </svg>
							        </ComboboxButton>

							        <ComboboxOptions v-if="clientContacts.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								        <ComboboxOption v-for="client in sortedClientWorkOrderConfirmation" :key="client.id" :value="client.id" as="template" v-slot="{ active, selected }">
									        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
				                                    <span :class="['block truncate', selected && 'font-semibold']">
				                                        {{ client.name }}
				                                    </span>
										        <span :class="['block truncate opacity-70', selected && 'font-semibold']">
				                                        {{ client.email }}
				                                    </span>

										        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
	                                        <!-- Heroicon name: solid/check -->
			                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
			                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
			                                        </svg>
	                                            </span>
									        </li>
								        </ComboboxOption>
							        </ComboboxOptions>
						        </div>
					        </Combobox>
				        </div>

				        <span class="col-span-2 text-md mt-4 flex">Alerts
					        <tooltip-icon tooltip="Recipients will receive a non-actionable notification"/>
				        </span>

				        <!--	                Vendor Contacts Work Order Readonly-->
				        <div class="col-span-2 md:col-span-1 lg:col-span-2 2xl:col-span-1">
					        <Combobox as="div" v-model="form.work_order_readonly_vendor_contacts" multiple>
						        <ComboboxLabel class="form-label" for="requested_by">Vendor</ComboboxLabel>

						        <div class="relative">
							        <ComboboxInput id="work_order_readonly_vendor_contacts" name="work_order_readonly_vendor_contacts" class="form-input" @focus="$event.target.select()" @change="searchVendorContacts($event)" :display-value="comboDisplayValue" autocomplete="off"/>

							        <ComboboxButton  class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
								        <!-- Heroicon name: solid/selector -->
								        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
								        </svg>
							        </ComboboxButton>

							        <ComboboxOptions v-if="vendorContacts.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								        <ComboboxOption v-for="vendorContact in sortedVendorWorkOrderReadonly" :key="vendorContact.id" :value="vendorContact.id" as="template" v-slot="{ active, selected }">
									        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
				                                    <span :class="['block truncate', selected && 'font-semibold']">
				                                        {{ vendorContact.display_name }}
				                                    </span>
										        <span :class="['block truncate opacity-70', selected && 'font-semibold']">
				                                        {{ vendorContact.email }}
				                                    </span>

										        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
	                                        <!-- Heroicon name: solid/check -->
			                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
			                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
			                                        </svg>
	                                            </span>
									        </li>
								        </ComboboxOption>
							        </ComboboxOptions>
						        </div>
					        </Combobox>
				        </div>

				        <!--	                Clients Work Order Readonly-->
				        <div class="col-span-2 md:col-span-1 lg:col-span-2 2xl:col-span-1">
					        <Combobox as="div" v-model="form.work_order_readonly_clients" multiple>
						        <ComboboxLabel class="form-label" for="requested_by">Client</ComboboxLabel>


						        <div class="relative">
							        <ComboboxInput id="work_order_readonly_clients" name="work_order_readonly_clients" class="form-input" @focus="$event.target.select()" @change="searchVendorContacts($event)" :display-value="comboDisplayValue" autocomplete="off"/>

							        <ComboboxButton  class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
								        <!-- Heroicon name: solid/selector -->
								        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
								        </svg>
							        </ComboboxButton>

							        <ComboboxOptions v-if="clientContacts.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								        <ComboboxOption v-for="clientContact in sortedClientWorkOrderReadonly" :key="clientContact.id" :value="clientContact.id" as="template" v-slot="{ active, selected }">
									        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
				                                    <span :class="['block truncate', selected && 'font-semibold']">
				                                        {{ clientContact.name }}
				                                    </span>
										        <span :class="['block truncate opacity-70', selected && 'font-semibold']">
				                                        {{ clientContact.email }}
				                                    </span>

										        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
	                                        <!-- Heroicon name: solid/check -->
			                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
			                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
			                                        </svg>
	                                            </span>
									        </li>
								        </ComboboxOption>
							        </ComboboxOptions>
						        </div>
					        </Combobox>
				        </div>
			        </div>
		        </fieldset>
	        </div>
		    <hr class="block md:hidden my-10 w-full">

		    <h3 class="text-xl md:text-3xl leading-6 md:py-3 font-medium text-d-orange-500 flex items-center">
			    <toggle-switch-input class="self-center col-span-2 md:col-span-1 mr-2" v-model="form.create_preventative_maintenance_work_orders"  mark-as-required />
			    <span>Preventative Maintenance</span>
			    <tooltip-icon class="mt-1" tooltip="The monitor alerts customers when they should schedule maintenance for the compactor"/>
		    </h3>
	        <div class="md:shadow-md my-5 py-5 md:py-10 md:px-5 lg:p-10 lg:grid lg:grid-cols-2">
		        <fieldset class="col-span-1 space-y-8 sm:space-y-5">
			        <legend class="py-3">
				        <h3 class="text-xl md:text-2xl leading-6 font-medium text-d-orange-500 flex">Scheduling Parameters </h3>
			        </legend>
			        <div class="grid gap-x-4 mt-4 gap-y-6 md:grid-cols-2">
				        <date-input tooltip="Date maintenance was last performed"
				                    tooltipType="text" class="lg:col-span-1"  v-model="form.latest_preventative_maintenance_occurred_on" label="Most Recent Maintenance" mark-as-required :errors="errors.latest_preventative_maintenance_occurred_on"/>
				        <text-input tooltip="Number of cycles compactor will complete until maintenance needs to be performed"
					                type="number" class="col-span-2 " min="0" step="1"  v-model="form.preventative_maintenance_cycle_count_threshold" label="Request Preventative Maintenance After (cycles)" :errors="errors.preventative_maintenance_cycle_count_threshold" mark-as-required/>
				        <text-input :tooltip="['Time (in seconds) it takes for the ram fully extends and retracts', '<i class=\'text-xs\'><b>Example:</b> A 74-second run with an average cycle time of 37 will log 2 cycles.</i>']"
				                    tooltip-type="list" type="number" class="col-span-2 "  v-model="form.average_time_per_cycle" label="Approximate Cycle Time (seconds)" :errors="errors.average_time_per_cycle" mark-as-required/>
			        </div>
		        </fieldset>
		        <hr class="block md:hidden my-10 w-full">
		        <fieldset :key="notificationChange" class="col-span-1 space-y-8 sm:space-y-5 lg:border-l-2 lg:pl-10 lg:ml-10  lg:bg-white">
			        <div class="col-span-5 w-full grid grid-cols-2 gap-4" >
				        <span class="py-3 col-span-2">
					        <h3 class="text-xl md:text-2xl leading-6 font-medium text-d-orange-500 flex">External Notifications (optional) </h3>
				        </span>
				        <span class="col-span-2 text-md mt-4 flex">Scheduling
					        <tooltip-icon tooltip="Recipients can schedule maintenance from the request"/>
						</span>
<!--	                Vendor Contacts Preventative Maintenance Confirmation-->
				        <div class="col-span-2 md:col-span-1 lg:col-span-2 2xl:col-span-1">
					        <Combobox as="div" v-model="form.preventative_maintenance_confirmation_vendor_contacts" multiple>
						        <ComboboxLabel class="form-label" for="requested_by">Vendor</ComboboxLabel>

						        <div class="relative">
							        <ComboboxInput id="work_order_confirmation_vendor_contacts" name="work_order_confirmation_vendor_contacts" class="form-input" @focus="$event.target.select()" @change="searchVendorContacts($event)" :display-value="comboDisplayValue" autocomplete="off"/>

							        <ComboboxButton  class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
								        <!-- Heroicon name: solid/selector -->
								        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
								        </svg>
							        </ComboboxButton>

							        <ComboboxOptions v-if="vendorContacts.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								        <ComboboxOption v-for="vendorContact in sortedVendorPreventativeMaintenanceConfirmation" :key="vendorContact.id" :value="vendorContact.id" as="template" v-slot="{ active, selected }">
									        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
			                                    <span :class="['block truncate', selected && 'font-semibold']">
			                                        {{ vendorContact.display_name }}
			                                    </span>
										        <span :class="['block truncate opacity-70', selected && 'font-semibold']">
			                                        {{ vendorContact.email }}
			                                    </span>

										        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
		                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
		                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
		                                        </svg>
                                            </span>
									        </li>
								        </ComboboxOption>
							        </ComboboxOptions>
						        </div>
					        </Combobox>
				        </div>

<!--	                Clients Preventative Maintenance Confirmation-->
				        <div class="col-span-2 md:col-span-1 lg:col-span-2 2xl:col-span-1">
					        <Combobox as="div" v-model="form.preventative_maintenance_confirmation_clients" multiple>
						        <ComboboxLabel class="form-label" for="requested_by">Client</ComboboxLabel>

						        <div class="relative">
							        <ComboboxInput id="preventative_maintenance_confirmation_clients" name="preventative_maintenance_confirmation_clients" class="form-input" @focus="$event.target.select()"  @change="searchClients($event)" :display-value="comboDisplayValue" autocomplete="off"/>

							        <ComboboxButton  class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
								        <!-- Heroicon name: solid/selector -->
								        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
								        </svg>
							        </ComboboxButton>

							        <ComboboxOptions v-if="clientContacts.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								        <ComboboxOption v-for="client in sortedClientPreventativeMaintenanceConfirmation" :key="client.id" :value="client.id" as="template" v-slot="{ active, selected }">
									        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
			                                    <span :class="['block truncate', selected && 'font-semibold']">
			                                        {{ client.name }}
			                                    </span>
										        <span :class="['block truncate opacity-70', selected && 'font-semibold']">
			                                        {{ client.email }}
			                                    </span>

										        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
		                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
		                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
		                                        </svg>
                                            </span>
									        </li>
								        </ComboboxOption>
							        </ComboboxOptions>
						        </div>
					        </Combobox>
				        </div>

				        <span class="col-span-2 text-md mt-4 flex">Alerts
					        <tooltip-icon tooltip="Recipients will receive a non-actionable notification"/>
				        </span>
<!--	                Vendor Contacts Preventative Maintenance Readonly-->
				        <div class="col-span-2 md:col-span-1 lg:col-span-2 2xl:col-span-1">
					        <Combobox as="div" v-model="form.preventative_maintenance_readonly_vendor_contacts" multiple>
						        <ComboboxLabel class="form-label" for="requested_by">Vendor</ComboboxLabel>

						        <div class="relative">
							        <ComboboxInput id="work_order_readonly_vendor_contacts" name="work_order_readonly_vendor_contacts" class="form-input" @focus="$event.target.select()" @change="searchVendorContacts($event)" :display-value="comboDisplayValue" autocomplete="off"/>

							        <ComboboxButton  class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
								        <!-- Heroicon name: solid/selector -->
								        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
								        </svg>
							        </ComboboxButton>

							        <ComboboxOptions v-if="vendorContacts.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								        <ComboboxOption v-for="vendorContact in sortedVendorPreventativeMaintenanceReadonly" :key="vendorContact.id" :value="vendorContact.id" as="template" v-slot="{ active, selected }">
									        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
			                                    <span :class="['block truncate', selected && 'font-semibold']">
			                                        {{ vendorContact.display_name }}
			                                    </span>
										        <span :class="['block truncate opacity-70', selected && 'font-semibold']">
			                                        {{ vendorContact.email }}
			                                    </span>

										        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
		                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
		                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
		                                        </svg>
                                            </span>
									        </li>
								        </ComboboxOption>
							        </ComboboxOptions>
						        </div>
					        </Combobox>
				        </div>

<!--	                Clients Preventative Maintenance Readonly-->
				        <div class="col-span-2 md:col-span-1 lg:col-span-2 2xl:col-span-1">
					        <Combobox as="div" v-model="form.preventative_maintenance_readonly_clients" multiple>
						        <ComboboxLabel class="form-label" for="requested_by">Client</ComboboxLabel>

						        <div class="relative">
							        <ComboboxInput id="preventative_maintenance_readonly_clients" name="preventative_maintenance_readonly_clients" class="form-input" @focus="$event.target.select()" @change="searchVendorContacts($event)" :display-value="comboDisplayValue" autocomplete="off"/>

							        <ComboboxButton  class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
								        <!-- Heroicon name: solid/selector -->
								        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
								        </svg>
							        </ComboboxButton>

							        <ComboboxOptions v-if="clientContacts.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								        <ComboboxOption v-for="clientContact in sortedClientPreventativeMaintenanceReadonly" :key="clientContact.id" :value="clientContact.id" as="template" v-slot="{ active, selected }">
									        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
			                                    <span :class="['block truncate', selected && 'font-semibold']">
			                                        {{ clientContact.name }}
			                                    </span>
										        <span :class="['block truncate opacity-70', selected && 'font-semibold']">
			                                        {{ clientContact.email }}
			                                    </span>

										        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
		                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
		                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
		                                        </svg>
                                            </span>
									        </li>
								        </ComboboxOption>
							        </ComboboxOptions>
						        </div>
					        </Combobox>
				        </div>
			        </div>
		        </fieldset>
	        </div>
	        <hr class="block md:hidden py-4">
	        <div class="lg:shadow-md md:px-5 lg:p-10">
	            <fieldset class="space-y-8 sm:space-y-5">
	                <legend>
	                    <h3 class="text-lg leading-6 font-medium text-gray-900 flex">Pre-Monitor Value Override
		                    <tooltip-icon tooltip="These values are used in reporting to override data that does not depict the compactor prior to the monitor"/>
	                    </h3>
	                    <span class="block mt-1 max-w-2xl text-sm text-gray-500">These values are used in reports and are system generated. You <i>may</i> override these values, but this is <b>optional</b>.</span>
	                </legend>

	                <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
	                    <text-input tooltip="Average Hauls per month before the monitor"
	                                type="number" class="col-span-2 md:col-span-1 form-input-with-buttons" min="0" step="0.01" v-model="form.pre_monitor_avg_hauls_per_month" label="Pre-Monitor Avg Hauls Per Month" :errors="errors.pre_monitor_avg_hauls_per_month"/>
	                    <text-input tooltip="Average Tons before the monitor"
	                                type="number" class="col-span-2 md:col-span-1" min="0" step="0.01" v-model="form.pre_monitor_avg_tons_per_month" label="Pre-Monitor Avg Tons Per Month" :errors="errors.pre_monitor_avg_tons_per_month" />
	                </div>
	            </fieldset>
	        </div>
        </div>
	    <loading-button type="submit" :loading="state === 'saving'" class="flex items-center justify-center w-full md:w-auto block lg:hidden btn btn-orange text-lg md:text-sm">
		    Save Changes
	    </loading-button>
    </form>
</template>

<style>
	.form-input-with-buttons input::-webkit-outer-spin-button{
		display: block;
	}

	.form-input-with-buttons input::-webkit-inner-spin-button {
		display: block;
	}
</style>

<script setup>
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import { Head, router } from '@inertiajs/vue3';
    import {reactive, ref, computed, onMounted, inject} from 'vue';
    import { debounce } from "lodash-es";

    /**
     * Combobox Components
     */
    import {
	    Combobox,
	    ComboboxButton,
	    ComboboxInput,
	    ComboboxLabel,
	    ComboboxOption,
	    ComboboxOptions
    } from "@headlessui/vue";
    import TooltipIcon from "../../Shared/TooltipIcon.vue";

    /**
     * PROPS
     */
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },

        compactorMonitorConfiguration: {
            type: Object,
            required: true
        },

        clientContacts: {
            type: Array,
            default: () => []
        },

	    workOrderConfirmationClients: {
		    type: Array,
		    default: () => []
	    },

	    workOrderReadonlyClients: {
		    type: Array,
		    default: () => []
	    },

	    preventativeMaintenanceConfirmationClients: {
		    type: Array,
		    default: () => []
	    },

	    preventativeMaintenanceReadonlyClients: {
		    type: Array,
		    default: () => []
	    },

	    workOrderConfirmationVendorContacts: {
			type: Array,
		    default: () => []
	    },

	    workOrderReadonlyVendorContacts: {
		    type: Array,
		    default: () => []
	    },

	    preventativeMaintenanceConfirmationVendorContacts: {
		    type: Array,
		    default: () => []
	    },

	    preventativeMaintenanceReadonlyVendorContacts: {
		    type: Array,
		    default: () => []
	    },

        vendorContacts: {
            type: Array,
            default: () => []
        },
    });

    /**
     * STATE
     */
    const state = ref('passive');
    const route = inject('route');

    const mounted = ref(false);
    const notificationChange = ref(0);
    const filteredClients = ref(props.clientContacts ?? []);
    const filteredVendorContacts = ref(props.vendorContacts ?? []);
    const originalForm = reactive({
	    ...props.compactorMonitorConfiguration,
	    // clients
	    work_order_confirmation_clients: props.workOrderConfirmationClients
		    .map(contact => contact.id),
	    work_order_readonly_clients: props.workOrderReadonlyClients
		    .map(contact => contact.id),
	    preventative_maintenance_confirmation_clients: props.preventativeMaintenanceConfirmationClients
		    .map(contact => contact.id),
	    preventative_maintenance_readonly_clients: props.preventativeMaintenanceReadonlyClients
		    .map(contact => contact.id),
		// vendor contacts
	    work_order_confirmation_vendor_contacts: props.workOrderConfirmationVendorContacts
		    .map(contact => contact.id),
	    work_order_readonly_vendor_contacts: props.workOrderReadonlyVendorContacts
		    .map(contact => contact.id),
	    preventative_maintenance_confirmation_vendor_contacts: props.preventativeMaintenanceConfirmationVendorContacts
		    .map(contact => contact.id),
	    preventative_maintenance_readonly_vendor_contacts: props.preventativeMaintenanceReadonlyVendorContacts
		    .map(contact => contact.id),
    })

    const form = reactive({...originalForm});
    const roundedTonnageCapacity = ref(props.compactorMonitorConfiguration.tonnage_capacity);


    const searchClients = debounce((event) => {
        filteredClients.value = props.clientContacts.filter(contact => {
            return contact.name.toLowerCase().includes(event.target.value.toLowerCase()) || contact.email.toLowerCase().includes(event.target.value.toLowerCase());
        });
    }, 800);

    const searchVendorContacts = debounce((event) => {
        filteredVendorContacts.value = props.vendorContacts.filter(contact => {
            return contact.name.toLowerCase().includes(event.target.value.toLowerCase()) || contact.email.toLowerCase().includes(event.target.value.toLowerCase());
        });
    }, 800);

    /**
     * MOUNTED
     */
    onMounted(() => {
        mounted.value = true;
    });

    /**
     * FUNCTIONS
     */
    function submitForm() {
        if (state.value === 'passive') {
            state.value = 'saving';

            router.put(route('compactor-monitor-configurations.update', props.compactorMonitorConfiguration.id), {...form, tonnage_capacity: roundedTonnageCapacity.value}, {
				preserveScroll: true,
                onFinish: () => state.value = 'passive'
            });
        }
    }

	function updateTargetPercentageFull(event) {
		return form.target_percentage_full = event.target.value;
	}

	function updateTonnageCapacity(event) {
		form.tonnage_cacity = event.target.value;
	}

    const roundTonnageCapacity = () => {
	    roundedTonnageCapacity.value = Number(roundedTonnageCapacity.value ?? 0).toFixed(2);
    };

    function comboDisplayValue(options) {
	    if (Array.isArray(options)) {
		    if (options.length > 1) {
			    return `${options.length} contacts selected`;
		    } else if (options.length === 1) {
			    return '1 contact selected';
		    } else {
			    return 'No contacts selected';
		    }
	    }
    }

    const estimatedTonnage = computed(() => {
	    return (Math.round(((form.target_percentage_full / 100 * roundedTonnageCapacity.value) + Number.EPSILON) * 100) / 100).toFixed(2) + ' tons per pickup avg. ';
	})

    const sortedClientWorkOrderConfirmation = computed(() => {
	    return filteredClients.value.sort((a, b) => {
		    let a_hash = (props.workOrderConfirmationClients.some(client => client.id === a.id) ? 0 : 1) + "_" + a.last_name + "_" + a.first_name;
		    let b_hash = (props.workOrderConfirmationClients.some(client => client.id === b.id) ? 0 : 1) + "_" + b.last_name + "_" + b.first_name;

		    return a_hash.localeCompare(b_hash);
	    });
	});

	const sortedClientWorkOrderReadonly = computed(() => {
		return filteredClients.value.sort((a, b) => {
			let a_hash = (props.workOrderReadonlyClients.some(client => client.id === a.id) ? 0 : 1) + "_" + a.last_name + "_" + a.first_name;
			let b_hash = (props.workOrderReadonlyClients.some(client => client.id === b.id) ? 0 : 1) + "_" + b.last_name + "_" + b.first_name;

			return a_hash.localeCompare(b_hash);
		});
	});

	const sortedClientPreventativeMaintenanceConfirmation = computed(() => {
		return filteredClients.value.sort((a, b) => {
			let a_hash = (props.preventativeMaintenanceConfirmationClients.some(client => client.id === a.id) ? 0 : 1) + "_" + a.last_name + "_" + a.first_name;
			let b_hash = (props.preventativeMaintenanceConfirmationClients.some(client => client.id === b.id) ? 0 : 1) + "_" + b.last_name + "_" + b.first_name;

			return a_hash.localeCompare(b_hash);
		});
	});

	const sortedClientPreventativeMaintenanceReadonly = computed(() => {
		return filteredClients.value.sort((a, b) => {
			let a_hash = (props.preventativeMaintenanceReadonlyClients.some(client => client.id === a.id) ? 0 : 1) + "_" + a.last_name + "_" + a.first_name;
			let b_hash = (props.preventativeMaintenanceReadonlyClients.some(client => client.id === b.id) ? 0 : 1) + "_" + b.last_name + "_" + b.first_name;

			return a_hash.localeCompare(b_hash);
		});
	});

	const sortedVendorWorkOrderConfirmation = computed(() => {
	    return filteredVendorContacts.value.sort((a, b) => {
		    let a_hash = (props.workOrderConfirmationVendorContacts.some(client => client.id === a.id) ? 0 : 1) + "_" + a.last_name + "_" + a.first_name;
		    let b_hash = (props.workOrderConfirmationVendorContacts.some(client => client.id === b.id) ? 0 : 1) + "_" + b.last_name + "_" + b.first_name;

		    return a_hash.localeCompare(b_hash);
	    });
	});

	const sortedVendorWorkOrderReadonly = computed(() => {
	    return filteredVendorContacts.value.sort((a, b) => {
		    let a_hash = (props.workOrderReadonlyVendorContacts.some(client => client.id === a.id) ? 0 : 1) + "_" + a.last_name + "_" + a.first_name;
		    let b_hash = (props.workOrderReadonlyVendorContacts.some(client => client.id === b.id) ? 0 : 1) + "_" + b.last_name + "_" + b.first_name;

		    return a_hash.localeCompare(b_hash);
	    });
	});

	const sortedVendorPreventativeMaintenanceConfirmation = computed(() => {
	    return filteredVendorContacts.value.sort((a, b) => {
		    let a_hash = (props.preventativeMaintenanceConfirmationVendorContacts.some(client => client.id === a.id) ? 0 : 1) + "_" + a.last_name + "_" + a.first_name;
		    let b_hash = (props.preventativeMaintenanceConfirmationVendorContacts.some(client => client.id === b.id) ? 0 : 1) + "_" + b.last_name + "_" + b.first_name;

		    return a_hash.localeCompare(b_hash);
	    });
	});

	const sortedVendorPreventativeMaintenanceReadonly = computed(() => {
	    return filteredVendorContacts.value.sort((a, b) => {
		    let a_hash = (props.preventativeMaintenanceReadonlyVendorContacts.some(client => client.id === a.id) ? 0 : 1) + "_" + a.last_name + "_" + a.first_name;
		    let b_hash = (props.preventativeMaintenanceReadonlyVendorContacts.some(client => client.id === b.id) ? 0 : 1) + "_" + b.last_name + "_" + b.first_name;

		    return a_hash.localeCompare(b_hash);
	    });
	});
</script>