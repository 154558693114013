<template>
    <Head title="Create Client Payment" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-payments.index')" class="breadcrumb-link">Client Payments</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <div class="flex flex-wrap my-4">
        <div class="w-1/5 px-4">
            <select-input v-model="form.payment_method" :errors="errors.payment_method" label="Payment Method:">
                <option :value="null">Select a Payment Method</option>
                <option value="ach">ACH</option>
                <option value="cash">Cash</option>
                <option value="check">Check</option>
                <option value="credit-card">Credit Card</option>
            </select-input>
        </div>

        <div class="w-1/5 px-4">
            <select-input v-model="form.receipt_location" label="Receipt Location" :errors="errors.receipt_location">
                <option :value="null">Select a Receipt Location</option>
                <option v-for="receiptLocation in receiptLocations" :value="receiptLocation.id" :key="receiptLocation.id">{{ receiptLocation.name }}</option>
            </select-input>
        </div>

        <div class="w-1/5 px-4">
            <date-input v-model="form.receipt_date" :errors="errors.receipt_date" label="Receipt Date" />
        </div>

        <div class="w-1/5 px-4">
            <text-input v-model="form.batch_name" :errors="errors.batch_name" label="Batch Name" />
        </div>

        <div class="w-1/5 px-4">
            <label class="form-label">&nbsp;</label>
            <loading-button :loading="saving" :disabled="!form.payment_method || !form.receipt_location" @click="createBatchPayment" class="btn btn-orange float-right">Apply Payments</loading-button>
        </div>
    </div>

    <div v-show="form.payment_method && form.receipt_location" class="flex m-4">
        <div class="w-1/3">
            <h3 class="text-lg font-bold">Total Payment Amount: ${{ total_payment_amount  }}</h3>
        </div>

        <div class="w-1/3">
            <h3 class="text-lg font-bold">Total Number of Unique Payments: {{ number_of_unique_payments }}</h3>
        </div>
    </div>

    <div v-show="form.payment_method && form.receipt_location" ref="spreadsheet"></div>
    <div v-show="!form.payment_method || !form.receipt_location">
         <h3 class="text-xl text-center mt-16">Please select a payment method and receipt location to get started</h3>
    </div>
</template>

<script>
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import { format } from 'date-fns';
    import { sumBy, debounce, chain } from 'lodash-es';
    import jspreadsheet from 'jspreadsheet-ce';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            DateInput,
            Icon,
            Pagination,
            SelectInput,
            TextInput,
            LoadingButton,
            Head,
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({})
            },
            clientCompanies: Array,
            clientAccounts: Array,
            unpaidInvoices: Array,
            receiptLocations: Array,
            remitterNames: Object,
            batchName: {
                type: String,
                required: true
            },
        },

        mounted() {
            this.makeSpreadsheet();
            this.mounted = true;
        },

        data() {
            return {
                saving: false,
                spreadsheet: null,
                columnHeaders: [],
                spreadsheetData: [],
                spreadsheetEditable: true,
                total_payment_amount : 0,
                number_of_unique_payments: 0,
                form: {
                    batch_name: this.batchName,
                    payment_method: null,
                    receipt_date: format(new Date, 'yyyy-LL-dd'),
                    receipt_location: null,
                    records: []
                },
                mounted: false,
            }
        },

        watch: {
            'form.payment_method': {
                handler() {
                    this.spreadsheetData = []
                    this.spreadsheet.destroy()
                    this.makeSpreadsheet()
                }
            }
        },

        methods: {
            spreadsheetChanged(spreadsheet, cell, columnIndex, rowIndex, value, oldValue) {
                let columnName = this.spreadsheet.getHeader(columnIndex);
                var isNumber = /^\d{1,3}(,?\d{3})*?(.\d{2})?$/g;

                // this is used to populate the client account and client company whenever an invoice is selected
                if (columnName == 'Invoice Number') {
                    let selectedInvoice = this.unpaidInvoices.filter(function(invoice) {
                        return invoice.invoice_number == value
                    })

                    if (selectedInvoice.length > 0) {
                        spreadsheet.jexcel.setValueFromCoords(this.columnHeaders['Client Account'], rowIndex, selectedInvoice[0].client_account_id)
                        spreadsheet.jexcel.setValueFromCoords(this.columnHeaders['Client Company'], rowIndex, selectedInvoice[0].client_company_id)
                    } else {
                        spreadsheet.jexcel.setValueFromCoords(this.columnHeaders['Client Account'], rowIndex, '')
                        spreadsheet.jexcel.setValueFromCoords(this.columnHeaders['Client Company'], rowIndex, '')
                    }
                }

                if (columnName == 'Total Amount' || columnName == 'Check Amount' || columnName == 'Applied Amount') {
                    let isNumber = /^(\d+|\d{1,3}(,\d{3})*)(\.\d{1,2})?$/g;
                    let newvalue = value.replace('$', '').trim();

                    if (newvalue != '' && !isNumber.test(newvalue)) {
                        // blank out invalid numbers
                        this.spreadsheet.setValueFromCoords(columnIndex, rowIndex, '')
                    } else if (newvalue != '' && newvalue.replace(/,/g, '') != newvalue) {
                        // remove commas from the number
                        this.spreadsheet.setValueFromCoords(columnIndex, rowIndex, newvalue.replace(/,/g, ''))
                    } else if (newvalue != value) {
                        // no commas but dollar signs/whitespace needs removed
                        this.spreadsheet.setValueFromCoords(columnIndex, rowIndex, newvalue)
                    }
                }

                // update payment information
                this.updatePaymentInformation();
            },

            updatePaymentInformation: debounce(function() {
                let payments = chain(this.createRecordsFromSpreadsheet()).map((record) => {
                    if (this.form.payment_method == 'ach' || this.form.payment_method == 'cash') {
                        return { 'uuid': record['Payment Sequence #'], 'total': parseFloat(record['Total Amount']) };
                    } else if (this.form.payment_method == 'credit-card') {
                        return { 'uuid': record['Transaction Number'], 'total': parseFloat(record['Total Amount']) };
                    } else {
                        return { 'uuid': record['Check Number'] + ':' + (record['Check Amount'] * 100) + ':' + record['Check Date'] + ':' + record['Remitter Name'], 'total': parseFloat(record['Check Amount']) };
                    }
                })
                .filter((record) =>  { return !isNaN(record.total); })
                .groupBy('uuid')
                .map((records) => {  return { 'uuid': records[0].uuid, 'total': Math.round(records[0].total * 100) / 100 }})
                .value();

                this.total_payment_amount = sumBy(payments, 'total').toFixed(2);
                this.number_of_unique_payments = payments.length;
            }, 1000),

            createRecordsFromSpreadsheet() {
                return this.spreadsheet.getData().map((row, rowIndex) => {
                    let record = {};
                    record['RowIndex'] = rowIndex
                    record['isEmpty'] = row.join('') == ''
                    row.forEach((value, index) => record[this.spreadsheet.getHeader(index)] = value);
                    return record;
                }).filter((row) => { return !row.isEmpty });
            },
            
            createBatchPayment() {

                this.saving = true;
                this.spreadsheetData = this.spreadsheet.getData()
                this.form.records = this.createRecordsFromSpreadsheet()

                // clear out all *comments* on the spreadsheet, btw this is a comment. :)
                this.spreadsheet.getData().forEach((row, rowIndex) => {
                    row.forEach((column, columnIndex) => {
                        this.spreadsheet.setComments([columnIndex, rowIndex], '')
                    })
                });

                this.$inertia
                    .post(this.$route(`client-payments.store-${this.form.payment_method}-payment-batch`), this.form, {
                        onError: (errors) => {
                            console.error(errors);

                            if (Object.keys(errors).length > 0) {
                                let rowNumbers = [];

                                Object.keys(errors).forEach((key) => {
                                    let keys = key.split('.')

                                    if (keys[0] == 'records') {
                                        let rowIndex = this.form.records[keys[1]].RowIndex;
                                        let columnIndex = this.columnHeaders[keys[2]]
                                        let errorMessage = errors[key].replace('records.' + rowIndex + '.', '')

                                        rowNumbers.push(rowIndex + 1);
                                        this.spreadsheet.setComments([columnIndex, rowIndex], errorMessage);
                                    }
                                })

                                this.$toast.error('Please check the spreadsheet below for errors on rows: ' + rowNumbers.join(', '));
                            } else {
                                this.$toast.error('An unhandled error happened. We have been alerted about this and will look into it. If you have any questions please contact us with the help beacon below.');
                            }
                        },
                        onSuccess: (response) => {
                            if (this.$page.props.success_message) {
                                this.spreadsheetData = []
                                this.spreadsheet.destroy()
                                this.makeSpreadsheet()
                                this.updatePaymentInformation()
                            }
                        },
                        onFinish: () => {
                            this.saving = false
                        }
                    });

            },

            makeSpreadsheet() {
                let vm = this;

                let invoiceDropdownFilter = function(instance, cell, columnIndex, rowIndex, unpaidInvoices) {
                    let remitterName = instance.jexcel.getValueFromCoords(this.columnHeaders['Remitter Name'], rowIndex)
                    let remitterClientCompanyId = this.remitterNames[remitterName.toLowerCase()]
                    let clientCompanyId = instance.jexcel.getValueFromCoords(this.columnHeaders['Client Company'], rowIndex)
                    let clientAccountId =  instance.jexcel.getValueFromCoords(this.columnHeaders['Client Account'], rowIndex)

                    if (remitterClientCompanyId) {
                        return unpaidInvoices.filter((invoice) => {
                            return invoice.client_company_id == remitterClientCompanyId
                        });
                    }
                    else if (clientAccountId) {
                        return unpaidInvoices.filter((invoice) => {
                            return invoice.client_account_id == clientAccountId
                        });
                    }
                    else if (clientCompanyId) {
                        return unpaidInvoices.filter((invoice) => {
                            return invoice.client_company_id == clientCompanyId
                        });
                    }

                    return unpaidInvoices;
                }.bind(this)


                let clientAccountDropdownFilter = function(instance, cell, columnIndex, rowIndex, clientAccounts) {
                    let clientCompanyId = instance.jexcel.getValueFromCoords(this.columnHeaders['Client Company'], rowIndex)
                    let selectedInvoiceNumber = instance.jexcel.getValueFromCoords(this.columnHeaders['Invoice Number'], rowIndex)
                    let selectedInvoice = this.unpaidInvoices.filter(function(invoice) {
                        return invoice.invoice_number == selectedInvoiceNumber
                    })

                    if (selectedInvoice.length > 0) {
                        return clientAccounts.filter((clientAccount) => {
                            return clientAccount.id == selectedInvoice[0].client_account_id
                        });
                    }
                    else if (clientCompanyId) {
                        return clientAccounts.filter((clientAccount) => {
                            return clientAccount.client_company_id == clientCompanyId
                        });
                    }

                    return clientAccounts;
                }.bind(this)


                let clientCompanyDropdownFilter = function(instance, cell, columnIndex, rowIndex, clientCompanies) {
                    let remitterName = instance.jexcel.getValueFromCoords(this.columnHeaders['Remitter Name'], rowIndex)
                    let remitterClientCompanyId = this.remitterNames[remitterName.toLowerCase()]

                    let selectedClientAccountId =  instance.jexcel.getValueFromCoords(this.columnHeaders['Client Account'], rowIndex)
                    let selectedClientAccount = this.clientAccounts.filter(function(clientAccount) {
                        return clientAccount.id == selectedClientAccountId
                    })

                    let selectedInvoiceNumber = instance.jexcel.getValueFromCoords(this.columnHeaders['Invoice Number'], rowIndex)
                    let selectedInvoice = this.unpaidInvoices.filter(function(invoice) {
                        return invoice.invoice_number == selectedInvoiceNumber
                    })

                    if (selectedInvoice.length > 0) {
                        return clientCompanies.filter((clientCompany) => {
                            return clientCompany.id == selectedInvoice[0].client_company_id
                        });
                    }
                    else if (remitterClientCompanyId) {
                        return clientCompanies.filter((clientCompany) => {
                            return clientCompany.id == remitterClientCompanyId
                        });
                    }
                    else if (selectedClientAccount.length > 0) {
                        return clientCompanies.filter((clientCompany) => {
                            return clientCompany.id == selectedClientAccount[0].client_company_id
                        });
                    }

                    return clientCompanies;
                }.bind(this)

                let columns = [];

                if (this.form.payment_method == 'ach') {
                    columns = [
                        {title: 'Payment Sequence #'}, 
                        {title: 'Account # (Last Digits)', width: 200}, 
                        {title: 'Remitter Name' }, 
                        {title: 'Total Amount' },
                    ]
                } else if (this.form.payment_method == 'cash') {
                    columns = [
                        { title: 'Payment Sequence #' }, 
                        { title: 'Remitter Name' },
                        { title: 'Total Amount' },
                    ]
                } else if (this.form.payment_method == 'check') {
                    columns = [
                        { title: 'Check Number' },
                        { title: 'Check Amount' },
                        { title: 'Check Date' },
                        { title: 'Remitter Name' },
                    ]
                } else if (this.form.payment_method == 'credit-card') {
                    columns = [
                        { title: 'Transaction Number' }, 
                        { title: 'Credit Card # (Last Digits)', width: 200 }, 
                        { title: 'Remitter Name' }, 
                        { title: 'Total Amount' },
                    ]
                }

                let defaultColumns = [
                    { title: 'Invoice Number', width: 250, type: 'autocomplete', filter: invoiceDropdownFilter, source: this.unpaidInvoices },
                    { title: 'Applied Amount' },
                    { title: 'Client Company', type:'autocomplete', width:'250', source: this.clientCompanies, filter: clientCompanyDropdownFilter },
                    { title: 'Client Account', type:'autocomplete', width:'250', source: this.clientAccounts, filter: clientAccountDropdownFilter }
                ];

                this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, {
                    data: this.spreadsheetData,
                    editable: this.spreadsheetEditable,
                    defaultColWidth: 140,
                    minDimensions: [7, 25],
                    allowInsertColumn: false,
                    allowRenameColumn: false,
                    allowDeleteColumn: false,
                    columns: [...columns, ...defaultColumns],
                    onchange: this.spreadsheetChanged
                });

                this.columnHeaders = this.spreadsheet.getConfig().columns.reduce(function(carry, column, index) { 
                    carry[column.title] = index; 
                    return carry 
                }, {});
            }
        }
    }
</script>