<template>
    <Head :title="`Edit Location - ${location.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('locations.index')" class="breadcrumb-link">Locations</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('locations.show', [location.id])" class="breadcrumb-link">{{ location.name }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <form role="form" id="edit-location-form" class="space-y-8" @submit.prevent="submitForm">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Information</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Location details.</p>
                </div>
            </legend>

            <div class="grid grid-cols-6 gap-4">
                <text-input id="name" v-model="form.name" class="col-span-6 xs:col-span-4 md:col-span-2" placeholder="name" label="Name" :errors="errors.name" mark-as-required />

                <div class="col-span-6 xs:col-span-4 md:col-span-2 form-label">
                    Client Company
                    <div class="py-3">{{ location.clientCompany.name }}</div>
                </div>

                <select-input v-model="form.client_account_id" class="col-span-6 xs:col-span-4 md:col-span-2" placeholder="billing group" label="Client Account" :errors="errors.client_account_id">
                    <option :value="null" class="font-semibold">Unassigned</option>
                    <option v-for="clientAccount in sortedClientAccountOptions" :key="clientAccount.id" :value="clientAccount.id">{{ clientAccount.name }}</option>
                </select-input>

                <select-input v-model="form.property_type" class="col-span-6 xs:col-span-4 md:col-span-2" placeholder="Property Type" label="Property Type" :errors="errors.property_type">
                    <option v-for="propertyType in propertyTypes" :key="propertyType" :value="propertyType">{{ propertyType }}</option>
                </select-input>

                <text-input
                    v-model="form.external_reference_id"
                    label="External Reference Id"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.external_reference_id"
                />

                <address-picker v-model="form.address" class="col-span-6" label="Address" :errors="$filterErrorsByPrefix(errors, 'address')" />
            </div>
        </fieldset>

        <fieldset v-if="metaFields.length > 0" class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Metadata</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Location metadata.</p>
                </div>
            </legend>

            <table class="my-4 table">
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="field in metaFields" :key="field.key">
                        <td class="capitalize">{{ field.key.replace(/_/g, ' ') }}</td>
                        <td v-if="field.options">
                            <select-input :errors="errors[`metadata.${field.key}`]"  v-model="form.metadata[field.key]">
                                <option value="">Not Selected</option>
                                <option v-for="option in field.options" :key="option" :value="option">{{ option }}</option>
                            </select-input>
                        </td>
                        <td v-else>
                            <text-input :type="field.type" :errors="errors['metadata.' + field.key]" v-model="form.metadata[field.key]" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </fieldset>

        <div>
            <loading-button :loading="state === 'saving'" type="submit" class="btn btn-orange">Save Changes</loading-button>
        </div>
    </form>

    <section class="mt-8" aria-labelledby="location-fees-title" v-if="$page.props.permissions.accessRevenueManagement">
        <div class="bg-white shadow sm:rounded-lg">
            <div class="flex flex-wrap justify-between">
                <div class="px-4 py-5 sm:px-6">
                    <h2 id="location-fees-title" class="text-lg leading-6 font-medium text-gray-900">Fees</h2>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Fees charged to your clients serviced at this location.</p>
                </div>

                <div v-if="location.fees.length > 0" class="px-4 py-5 sm:px-6">
                    <inertia-link :href="$route('locations.location-fees.create', [location.id])" class="btn btn-orange">New</inertia-link>
                </div>
            </div>

            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <div v-if="location.fees.length" class="flex flex-col">
                    <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Id</th>
                                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Header Label</th>
                                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Label</th>
                                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Start Date</th>
                                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">End Date</th>
                                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Frequency</th>
                                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Amount</th>
                                        <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span class="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr v-for="fee in location.fees" :key="fee.id">
                                        <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-500">{{ fee.id }}</td>
                                        <td class="whitespace-nowrap px-2 py-2 text-sm font-medium">{{ fee.header_label }}</td>
                                        <td class="">{{ fee.label }}</td>
                                        <td class="">{{ $filters.format_date(fee.start_date) }}</td>
                                        <td class="">{{ $filters.format_date(fee.end_date) }}</td>
                                        <td class="">{{ fee.frequency }}</td>
                                        <td class="">
                                            <template v-if="fee.amount">
                                                {{ $filters.format_money(fee.amount) }}
                                            </template>

                                            <template v-else>
                                                N/A
                                            </template>
                                        </td>
                                        <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            <inertia-link :href="$route('location-fees.edit', [fee.id])" class="link">
                                                Edit<span class="sr-only">, {{ fee.id }}</span>
                                            </inertia-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- This example requires Tailwind CSS v2.0+ -->
                <div v-else class="text-center">
                    <icon name="file-signature" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                    <h3 class="mt-2 text-sm font-medium text-gray-900">No Fees</h3>
                    <p class="mt-1 text-sm text-gray-500">Create a new fee.</p>
                    <div class="mt-6">
                        <inertia-link :href="$route('locations.location-fees.create', [location.id])" class="btn btn-orange">
                            <!-- Heroicon name: solid/plus -->
                            <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                            </svg>
                            New Fee
                        </inertia-link>
                    </div>
                </div>
            </div>

            <div v-if="location.fees.length">
                <inertia-link :href="$route('location-fees.index', {search: location.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
            </div>
        </div>
    </section>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import AddressPicker from '@/Shared/AddressPicker.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import QuillEditor from '@/Shared/QuillEditor.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            AddressPicker,
            LoadingButton,
            QuillEditor,
            SelectInput,
            TextInput,
            MoneyInput,
            DateInput,
            Head,
        },

        props: {
            propertyTypes: {
                type: Array,
                required: true
            },

            location: {
                type: Object,
                required: true
            },

            metaFields: {
                type: Array,
                required: true,
            },

            errors: {
                type: Object,
                default: () => ({})
            }
        },

        data() {
            return {
                form: {
                    ...this.location,
                    address: {...this.location.address},
                    metadata: {...this.location.metadata}
                },
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm(form) {
                if (this.state === 'passive') {
                    this.state = 'saving';

                    this.$inertia.put(this.$route('locations.update', this.location.id), this.form, {
                        preserveScroll: true,
                        onFinish: () => this.state = 'passive',
                    });
                }
            },
        },

        computed:{
            sortedClientAccountOptions() {
                return this.location.clientCompany.clientAccounts
                    .sort((a, b) => a.name.localeCompare(b.name));
            }
        }
    }
</script>
