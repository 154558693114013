<template>
    <Head title="Sessions" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('user-settings.index')" class="breadcrumb-link">My Account</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Sessions</span>
        </nav>
    </Teleport>

    <div class="py-6">
        <horizontal-sub-nav currentTab="sessions" />

        <div class="divide-y divide-gray-200">
            <div class="px-4 py-5">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Browser Sessions
                </h3>
                <p class="mt-1 text-sm leading-5 text-gray-600">
                    If necessary, you may logout of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.
                </p>

                <!-- Other Browser Sessions -->
                <div class="mt-5 space-y-6" v-if="sessions.length > 0">
                    <div class="flex items-center" v-for="(session, i) in sessions" :key="i">
                        <div>
                            <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor" class="w-8 h-8 text-gray-500" v-if="session.agent.is_desktop">
                                <path d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" class="w-8 h-8 text-gray-500" v-else>
                                <path d="M0 0h24v24H0z" stroke="none"></path><rect x="7" y="4" width="10" height="16" rx="1"></rect><path d="M11 5h2M12 17v.01"></path>
                            </svg>
                        </div>

                        <div class="ml-3">
                            <div class="text-sm text-gray-600">
                                {{ session.agent.platform }} - {{ session.agent.browser }}
                            </div>

                            <div>
                                <div class="text-xs text-gray-500">
                                    {{ session.ip_address }},

                                    <span class="text-green-500 font-semibold" v-if="session.is_current_device">This device</span>
                                    <span v-else>Last active {{ session.last_active }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex items-center mt-5">
                    <loading-button @click="showConfirmationModal" class="btn btn-red">
                        Logout Other Browser Sessions
                    </loading-button>
                </div>
            </div>
        </div>
    </div>

    <modal ref="confirmationModal" role="dialog" class="max-w-xl" @closed="emptyModal">
        <template #modal-header="{close}">
            <div class="px-4 py-5">
                <h3 class="font-medium text-xl">Logout Other Browser Sessions</h3>
                <p class="mt-4">
                    Please enter your password to confirm you would like to logout of your other browser sessions across all o your devices.
                </p>
            </div>
        </template>

        <template #modal-body>
            <div class="px-4 py-5">
                <form role="form" @submit.prevent="submitForm">
                    <div class="grid grid-cols-3 gap-y-6 gap-x-4">
                        <text-input type="password" id="password" v-model="form.password" class="col-span-2" placeholder="password" :errors="errors.password" />
                    </div>
                </form>
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="px-4 py-5 flex justify-between">
                <div>
                    <button type="button" class="btn btn-gray mr-2" @click="close">Nevermind</button>
                </div>

                <div>
                    <loading-button :loading="state === 'saving'" class="btn btn-blue" @click="submitForm">Logout Other Browser Sessions</loading-button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script setup>
    import { reactive, ref, inject, watch, onMounted } from 'vue';
    import { router } from '@inertiajs/vue3';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Modal from '@/Shared/Modal.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import HorizontalSubNav from '../HorizontalSubNav.vue';

    const route = inject('route');
    
    /**
     * Props
     */
    const props = defineProps({
        sessions: {
            type: Array,
            required: true
        },

        errors: {
            type: Object,
            default: {},
        }
    });

    
    /**
     * State
     */
    const state = ref('passive');
    const mounted = ref(false);

    const form = reactive({
        password: ''
    });

    const confirmationModal = ref(null);

    onMounted(() => {
        mounted.value = true;
    });

    /**
     * Methods
     */
    function showConfirmationModal() {
        confirmationModal.value.show();
    }

    function submitForm() {
        state.value = 'saving';

        router.delete(route('user-settings.other-browser-sessions.destroy', form), {
            preserveScroll: true,
            onSuccess: () => confirmationModal.value.close(),
            onFinish: () => state.value = 'passive'
        });
    }

    function emptyModal() {
        form.password = '';
        props.errors.password = '';
    }
</script>
