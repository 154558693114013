<style></style>

<template>
    <div class="p-2">
        <h3 class="my-3 p-1 text-d-navy-500 font-medium text-xl">{{ title }}</h3>

        <div class="">
            <div id="gathered-vendor-invoices-by-month-container">

            </div>
        </div>
    </div>
</template>

<script>
    import Highcharts from 'highcharts';
    import {format} from 'date-fns';

    Highcharts.setOptions({
        lang: {
          decimalPoint: '.',
          thousandsSep: ','
        }
    });

    export default {
        props: {
            title: {
                type: String,
                default: 'Gathered Vendor Invoices by Month'
            },

            series: {
                type: Array,
                required: true
            },

            dateRange: {
                type: Array,
                required: true
            }
        },

        mounted() {
            this.drawChart();
        },

        methods: {
            drawChart() {
	            Highcharts.setOptions({
		            colors: ['#012639', '#075076', '#5fa3ec', '#fc9776', '#f4c326']
	            });
                Highcharts.chart('gathered-vendor-invoices-by-month-container', {
                    chart: {
                        type: 'column',
                    },

                    title: {
                        text: null
                    },

                    xAxis: {
                        categories: this.series.map(data => data.month)
                    },

                    yAxis: {
                        min: 0,
                        title: {
                            text: null
                        },
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold',
                                color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                            },
                            format: "${total:,.2f}"
                        }
                    },

                    legend: {
                        enabled: false
                    },

                    tooltip: {
                        pointFormatter() {

                            let increaseOverPrevious = 0
                            if (this.series.data[this.x - 1]) {
                                increaseOverPrevious = this.y - this.series.data[this.x - 1].y;
                                if (increaseOverPrevious > 0) {
                                    increaseOverPrevious = '<span class="text-green-500">+' + increaseOverPrevious + '</span>';
                                } else if (increaseOverPrevious < 0) {
                                    increaseOverPrevious = '<span class="text-red-500">' + increaseOverPrevious + '</span>';
                                } else {
                                    increaseOverPrevious = ''
                                }
                            }
                            return 'Invoices Gathered: ' + this.y + '<br>' + (increaseOverPrevious === 0 ? '' : 'Increase: ' + increaseOverPrevious);
                        }
                    },

                    series: [{
                        data: this.series.map(function (element) {
                           return element.count
                        }),
                        maxPointWidth: 50
                    }]

                });
            }
        },

        watch: {
            series: {
                handler(val) {
                    this.drawChart();
                },
                deep: true
            }
        }
    }
</script>