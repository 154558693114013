<template>
    <Head :title="`Comments - Incident ${incident.id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('incidents.index')" class="breadcrumb-link">Incidents</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <horizontal-sub-nav current-tab="comments" :incident-id="incident.id" />

    <div class="w-full my-4">
        <span class="text-xl font-medium text-blue-700">Comments</span>

        <div class="grid grid-cols-4 gap-y-6 gap-x-4 my-2 mb-6">
            <div class="col-span-4 sm:col-span-3">
                <textarea v-model="form.newComment" class="form-textarea whitespace-pre-wrap" @keyup.shift.enter="addNewComment"></textarea>
            </div>

            <div class="col-span-4 sm:col-span-1">
                <loading-button :loading="saving" class="btn btn-blue ml-auto" @click="addNewComment">
                    <icon name="plus" class="w-4 h-4 fill-current mr-2" /> Add Comment
                </loading-button>
            </div>
        </div>

        <div v-if="form.comments && form.comments.length > 0">
            <comment v-for="comment in orderedComments" :key="comment.id" :comment="comment" @comment-was-updated="updateComment" @comment-was-removed="removeComment" class="my-4 mb-6" />
        </div>

        <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="comment-lines" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">There are no comments for this incident.</span>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Comment from '../Comment.vue';
    import { cloneDeep } from 'lodash-es';
    import HorizontalSubNav from '../HorizontalSubNav.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            HorizontalSubNav,
            Icon, 
            TextareaInput,
            LoadingButton,
            Comment,
            Head,
        },

        props: {
            incident: {
                type: Object,
                required: true
            }
        },

        data() {
           return {
               form: {
                   comments: cloneDeep(this.incident.comments),
                   newComment: ''
               },
               saving: false,
               mounted: false,
           }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            addNewComment() {
                this.saving = true;
                
                this.$inertia
                    .post(this.$route('incidents.comments.store', this.incident.id), {comment: this.form.newComment}, {
                        preserveScroll: true,
                        onSuccess: () => this.form.newComment = '',
                        onFinish: () => this.saving = false
                    });
            },
            
            updateComment(comment) {
                this.$inertia
                    .put(this.$route('incidents.comments.update', comment.id), comment, {
                        preserveState: true, 
                        preserveScroll: true
                    });
            },

            removeComment(comment) {
                let confirmed = confirm('Are you sure you wish to perform this action?');
                
                if (confirmed) {
                    this.$inertia.delete(this.$route('incidents.comments.destroy', comment.id), {
                        rememberState: true, 
                        preserveScroll: true
                    });
                }
            },
        },

        computed: {
            orderedComments() {
                return this.form.comments.sort((comment1, comment2) => new Date(comment2.created_at) - new Date(comment1.created_at));
            },
        },

        watch: {
            incident(newValue, oldValue) {
                this.form.comments = cloneDeep(newValue.comments);
            }
        }
    }
</script>