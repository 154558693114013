<template>
    <Head title="General Settings" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>General</span>
        </nav>
    </Teleport>

    <div class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="general" />

                <form class="divide-y divide-gray-200 lg:col-span-9 pt-8" action="" method="post" @submit.prevent="updateSettings">
                    <!-- General Tenant Settings -->
                    <div class="lg:px-6 pb-8">
                        <div>
                            <h2 class="text-lg leading-6 font-medium text-gray-900">General</h2>
                            <p class="mt-1 text-sm text-gray-500">
                                General information about your account that can be used throughout Discovery.
                            </p>
                        </div>

                        <div class="mt-6 grid grid-cols-12 gap-6">
                            <text-input label="Name" name="name" v-model="form.name" class="col-span-12 sm:col-span-6" :errors="errors.name" />
                            <text-input label="Website URL" name="website_url" v-model="form.website_url" class="col-span-12 sm:col-span-6" :errors="errors.website_url" />
                            <text-input label="Support Phone Number" name="support_phone_number" v-model="form.support_phone_number" class="col-span-12 sm:col-span-6" :errors="errors.support_phone_number" />
                            <text-input label="Support Email Address" name="support_email_address" v-model="form.support_email_address" class="col-span-12 sm:col-span-6" :errors="errors.support_email_address" />
                            <text-input label="Billing Phone Number" name="billing_phone_number" v-model="form.billing_phone_number" class="col-span-12 sm:col-span-6" :errors="errors.billing_phone_number" />
                            <text-input label="Billing Fax Number" name="billing_fax_number" v-model="form.billing_fax_number" class="col-span-12 sm:col-span-6" :errors="errors.billing_fax_number" />
                        </div>
                    </div>

                    <!-- Billing Information -->
                    <div class="lg:px-6 py-8">
                        <div>
                            <h2 class="text-lg leading-6 font-medium text-gray-900">Billing Information</h2>
                            <p class="mt-1 text-sm text-gray-500">
                                General billing settings for your account.
                            </p>
                        </div>

                        <div class="mt-6 grid grid-cols-12 gap-6">
                            <div class="col-span-12">
                                <h3 class="block text-base font-medium text-gray-700 border-b border-gray-50">Billing Email</h3>
                                <span class="px-4" v-if="form.billing_email_address">{{  form.billing_email_address }}</span>
                                <span class="px-4" v-else>Not Setup</span>

                                <inertia-link :href="$route('tenant-settings.email-settings.edit')" class="mt-4 btn btn-gray">Manage email</inertia-link>
                                <div v-if="form.email_domain_status === 'verified'" class="p-2 mt-4 text-sm bg-blue-100 border border-blue-300 text-blue-700">
                                    Your domain is verified.
                                </div>
                                <div v-else-if="form.email_domain_status === 'partially-verified-missing-mx-records'" class="p-2 mt-4 text-sm bg-yellow-100 border border-yellow-300 text-yellow-700">
                                    NOTE: Your domain is partially verified, but is missing recommended MX records. This may change the deliverability of your emails (emails sent via Discovery may end up in the spam folder). Click Manage Email to complete verification.
                                </div>
                                <div v-else class="p-2 mt-4 text-sm bg-yellow-100 border border-yellow-300 text-yellow-700">
                                    NOTE: Your domain is not verified. <span v-if="$page.props.permissions.accessRevenueManagement">Client invoices will originate from <code class="text-red-400 px-0.5 bg-gray-50">mg.discoveryapp.io</code> until this domain is verified.</span> This may change the deliverability of your emails (emails sent via Discovery may end up in the spam folder). Click Manage Email to complete verification.
                                </div>
                            </div>

                            <div class="col-span-12">
                                <h3 class="block text-base font-medium text-gray-700 border-b border-gray-50">Billing Address</h3>
                                <address-picker v-model="form.billing_address" :errors="errors" field-name="billing_address" />
                            </div>
                        </div>
                    </div>

                    <!-- Tenant Branding -->
                    <div class="lg:px-6 py-8">
                        <div>
                            <h2 class="text-lg leading-6 font-medium text-gray-900">Branding</h2>

                            <p class="mt-1 text-sm text-gray-500">
                                Upload your brand logos and marks, set your primary brand color.
                            </p>
                        </div>

                        <div class="mt-6 grid grid-cols-12 gap-6">
                            <text-input type="color" label="Primary Brand Color" name="brand_primary_color" v-model="form.brand_primary_color" class="col-span-4" :errors="errors.brand_primary_color" />

                            <div class="col-span-12 sm:col-span-6 sm:col-start-1">
                                <label for="photo" class="block text-sm leading-5 font-medium text-gray-700">
                                    Brand Logo
                                </label>

                                <div class="mt-2 flex items-center sm:mt-0 sm:col-span-2 ">
                                    <img v-if="logoPreview" :src="logoPreview" class="h-12 w-12 rounded-full" alt="">

                                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 text-gray-300" fill="currentColor" viewBox="0 0 448 512">
                                        <path d="M112 192a48 48 0 1 0-48-48 48 48 0 0 0 48 48zm0-64a16 16 0 1 1-16 16 16 16 0 0 1 16-16zm304-96H32A32 32 0 0 0 0 64v384a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32zm0 416H32v-80h384zM85.2 336l52-69.33 40 53.33-12 16zm120 0l76-101.33 76 101.33zm210.8 0h-18.8L294 198.41c-6.06-8.07-19.56-8.07-25.62 0l-71.19 94.91L150 230.41c-6.06-8.07-19.56-8.07-25.62 0L45.18 336H32V64h384z"/>
                                    </svg>

                                    <label class="cursor-pointer ml-5 mr-2 py-2 px-3 border border-gray-300 text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                                        Change
                                        <input type='file' class="hidden" @change="previewLogo" />
                                    </label>

                                    <div v-if="errors.brand_logo" class="form-error">{{ errors.brand_logo }}</div>
                                </div>
                            </div>

                            <div class="col-span-12 sm:col-span-6">
                                <label for="photo" class="block text-sm leading-5 font-medium text-gray-700">
                                    Brand Mark
                                </label>

                                <div class="mt-2 flex items-center sm:mt-0 sm:col-span-2">
                                    <img v-if="markPreview" :src="markPreview" class="h-12 w-12 rounded-full" alt="">

                                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 text-gray-300" fill="currentColor" viewBox="0 0 448 512">
                                        <path d="M112 192a48 48 0 1 0-48-48 48 48 0 0 0 48 48zm0-64a16 16 0 1 1-16 16 16 16 0 0 1 16-16zm304-96H32A32 32 0 0 0 0 64v384a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32zm0 416H32v-80h384zM85.2 336l52-69.33 40 53.33-12 16zm120 0l76-101.33 76 101.33zm210.8 0h-18.8L294 198.41c-6.06-8.07-19.56-8.07-25.62 0l-71.19 94.91L150 230.41c-6.06-8.07-19.56-8.07-25.62 0L45.18 336H32V64h384z"/>
                                    </svg>

                                    <label class="cursor-pointer ml-5 mr-2 py-2 px-3 border border-gray-300 text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                                        Change
                                        <input type='file' class="hidden" @change="previewMark" />
                                    </label>

                                    <div v-if="errors.brand_mark" class="form-error">{{ errors.brand_mark }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Vendor Invoice Settings -->
                    <div class="lg:px-6 py-8">
                        <div>
                            <div>
                                <h2 class="text-lg leading-6 font-medium text-gray-900">Invoice Settings</h2>
                                <p class="mt-1 text-sm text-gray-500">
                                    Configure the settings used for bulk processing of invoices.
                                </p>
                            </div>
                            <ul class="mt-2 divide-y divide-gray-200">
                                <li v-if="$page.props.permissions.extractVendorInvoices" class="py-4 flex items-center justify-between">
                                    <div class="flex flex-col">
                                        <p id="privacy-option-label-1" class="text-sm font-medium text-gray-900">
                                            Auto Process Extracted Invoices Into Vendor Invoices
                                        </p>
                                        <p id="privacy-option-description-1" class="text-sm text-gray-500">
                                            Automatically process extracted invoices into vendor invoices when saving.
                                        </p>
                                    </div>

                                    <toggle-switch-input v-model="form.vendor_invoice_settings.auto_process_into_vendor_invoice" />
                                </li>

                                <li v-if="$page.props.permissions.auditVendorInvoices" class="py-4 flex items-center justify-between">
                                    <div class="flex flex-col">
                                        <p id="privacy-option-label-1" class="text-sm font-medium text-gray-900">
                                            Allocate Vendor Invoices After Match
                                        </p>
                                        <p id="privacy-option-description-1" class="text-sm text-gray-500">
                                            Automatically allocate vendor fees across service charges after line item matching.
                                        </p>
                                    </div>

                                    <toggle-switch-input v-model="form.vendor_invoice_settings.allocate_after_match" />
                                </li>

                                <li v-if="$page.props.permissions.auditVendorInvoices" class="py-4 flex items-center justify-between">
                                    <div class="flex flex-col">
                                        <p id="privacy-option-label-2" class="text-sm font-medium text-gray-900">
                                            Audit Vendor Invoices After Allocation
                                        </p>
                                        <p id="privacy-option-description-2" class="text-sm text-gray-500">
                                            Automatically perform an audit and revise charges after allocation.
                                        </p>
                                    </div>

                                    <toggle-switch-input v-model="form.vendor_invoice_settings.audit_after_allocation" />
                                </li>

                                <li v-if="$page.props.permissions.accessRevenueManagement" class="py-4 flex items-center justify-between">
                                    <div class="flex flex-col">
                                        <p id="privacy-option-label-3" class="text-sm font-medium text-gray-900">
                                            Strict Cash Flow Protection
                                        </p>
                                        <p id="privacy-option-description-3" class="text-sm text-gray-500">
                                            Match to revenue checks will always fail unless a matching client invoice has been sent AND paid.
                                        </p>
                                    </div>

                                    <toggle-switch-input v-model="form.vendor_invoice_settings.strict_cash_flow_protection" />
                                </li>

                                <li v-if="tenant.features.vendor_invoice_auto_approval" class="py-4 flex items-center justify-between">
                                    <div class="flex flex-col">
                                        <p id="privacy-option-label-4" class="text-sm font-medium text-gray-900">
                                            Auto Approval Vendor Invoice Total Tolerance
                                        </p>
                                        <p id="privacy-option-description-4" class="text-sm text-gray-500">
                                            Auto approve vendor invoices within a percentage of the total amount on the previous invoice.
                                        </p>
                                        <p v-if="errors['vendor_invoice_settings.auto_approval_allowed_percent_difference']" class="text-red-500 text-sm mt-1">
                                            {{ errors['vendor_invoice_settings.auto_approval_allowed_percent_difference'] }}
                                        </p>
                                    </div>

                                    <text-input
                                        v-model="form.vendor_invoice_settings.auto_approval_allowed_percent_difference"
                                        class="w-12"
                                        type="number"
                                        min="0"
                                        max="100"
                                    />
                                </li>
                            </ul>

                            <div v-if="$page.props.permissions.gatherVendorInvoices" class="mt-6 grid grid-cols-12 gap-6">
                                <text-input disabled label="Vendor Invoice Gather Email" name="vendor_invoice_data_extraction_email" v-model="form.vendor_invoice_data_extraction_email" class="col-span-12 sm:col-span-6" :errors="errors.vendor_invoice_data_extraction_email" />
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-start px-10 py-4">
                        <loading-button  :loading="saving" class="btn btn-orange" @click="updateSettings">Save All Settings</loading-button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import AddressPicker from '@/Shared/AddressPicker.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import OAuth from '@/Shared/Utils/OAuth.js';
    import SubNav from './SubNav.vue';
    import QuillEditor from "../../Shared/QuillEditor.vue";

    export default {
        components: {
            QuillEditor,
            Head,
            Icon,
            TextInput,
            SelectInput,
            ToggleSwitchInput,
            AddressPicker,
            LoadingButton,
            SubNav
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },
            tenant: Object,
            accountingSystems: Object,
            authorizationUrl: String,
            syncingVendors: Boolean,
            hasToken: Boolean,
            accountListing: Array,
            needToRefreshAccess: Boolean,
            currencies: Array,
            weightUnits: Array,
            features: Object,
        },

        data() {
            return {
                saving: false,
                form: {... this.tenant },
                toggleVariableList: false,
                pendingAvatar: null,
                logoPreview: this.tenant.brand_logo_thumbnail_url ? this.tenant.brand_logo_thumbnail_url : null,
                markPreview: this.tenant.brand_mark_thumbnail_url ? this.tenant.brand_mark_thumbnail_url : null,
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            updateSettings() {
                this.saving = true;

                // This needs to be switched over to use a form data object.
                let formData = new FormData();

                formData.append('name', this.form.name);
                formData.append('billing_email_address', this.form.billing_email_address);
                formData.append('website_url', this.form.website_url);
                formData.append('billing_phone_number', this.form.billing_phone_number ? this.form.billing_phone_number : '');
                formData.append('billing_fax_number', this.form.billing_fax_number ? this.form.billing_fax_number : '');

                formData.append('billing_address[city]', this.form.billing_address.city);
                formData.append('billing_address[country]', this.form.billing_address.country);
                formData.append('billing_address[country_code]', this.form.billing_address.country_code);
                formData.append('billing_address[full_address]', this.form.billing_address.full_address);
                formData.append('billing_address[latitude]', this.form.billing_address.latitude);
                formData.append('billing_address[longitude]', this.form.billing_address.longitude);
                formData.append('billing_address[postal_code]', this.form.billing_address.postal_code);
                formData.append('billing_address[state]', this.form.billing_address.state);
                formData.append('billing_address[state_code]', this.form.billing_address.state_code);
                formData.append('billing_address[street_address]', this.form.billing_address.street_address);

                formData.append('brand_primary_color', this.form.brand_primary_color);

                formData.append('vendor_invoice_settings[allocate_after_match]', this.form.vendor_invoice_settings.allocate_after_match);
                formData.append('vendor_invoice_settings[audit_after_allocation]', this.form.vendor_invoice_settings.audit_after_allocation);
                formData.append('vendor_invoice_settings[strict_cash_flow_protection]', this.form.vendor_invoice_settings.strict_cash_flow_protection);
                formData.append('vendor_invoice_settings[auto_approval_allowed_percent_difference]', this.form.vendor_invoice_settings.auto_approval_allowed_percent_difference ? this.form.vendor_invoice_settings.auto_approval_allowed_percent_difference : '');
                formData.append('vendor_invoice_settings[auto_process_into_vendor_invoice]', this.form.vendor_invoice_settings.auto_process_into_vendor_invoice);

                formData.append('support_phone_number', this.form.support_phone_number ?? '');
                formData.append('support_email_address', this.form.support_email_address ?? '');

                formData.append('_method', 'PUT');

                if (this.pendingLogo) {
                    formData.append('brand_logo', this.pendingLogo);
                }

                if (this.pendingMark) {
                    formData.append('brand_mark', this.pendingMark);
                }

                this.$inertia
                    .post(this.$route('tenant-settings.update'), formData, {
                        preserveScroll: true,
                        onFinish: () => this.saving = false
                    });
            },

            oauthLogin() {
                let oauth = new OAuth(this.authorizationUrl)
                oauth.loginPopup()
            },

            previewLogo(e) {
                let file = e.target.files[0];
                let reader = new FileReader();

                if (file && file.type.startsWith('image/')) {
                    this.pendingLogo = file;
                    reader.addEventListener('load', () => this.logoPreview = reader.result, false);
                    reader.readAsDataURL(file);
                }
            },

            previewMark(e) {
                let file = e.target.files[0];
                let reader = new FileReader();

                if (file && file.type.startsWith('image/')) {
                    this.pendingMark = file;
                    reader.addEventListener('load', () => this.markPreview = reader.result, false);
                    reader.readAsDataURL(file);
                }
            },
        },
    }
</script>
