<template>
    <Head title="Vendor Contacts" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Vendor Contacts</span>
        </nav>
    </Teleport>

    <form @submit.prevent id="vendor-contact-search-form" class="grid grid-cols-6 gap-x-4 gap-y-6 my-4 items-center">
        <div class="col-span-6 sm:col-span-2">
            <label for="search-vendor-contacts" class="form-label">Search</label>

            <div class="input-group">
                <span class="input-group-item">
                    <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                </span>

                <input type="search" name="search-vendor-contacts" id="search-vendor-contacts" v-model="form.search" placeholder="Search" class="input-group-field">
            </div>
        </div>

        <select-input class="col-span-6 md:col-span-1" name="per_page" id="per-page" v-model="form.per_page" placeholder="15" label="Results / Page">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
        </select-input>

        <div class="col-span-6 md:col-span-1 md:pt-6">
            <div class="flex items-center">
                <button type="button" class="mr-2 inline-flex divide-x divide-gray-400 text-gray-800 bg-white font-semibold items-center border border-gray-400 text-sm leading-5 rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray" @click="$refs.searchFilterSlideOver.show()">
                    <span class="inline-flex px-3 py-2">
                        <icon name="filter" class="w-5 h-5 text-gray-400 fill-current" />
                        <span class="ml-2">Filters</span>
                    </span>

                    <span class="relative px-3 py-2 inline-flex items-center bg-d-accent-100 text-sm font-semibold text-d-accent-900 rounded-r-md">
                        {{ filtersInUse }}
                    </span>
                </button>

                <column-selector
                    :headers="headers"
                    :excludedHeaders="excludedHeaders"
                    v-model="form.selected_headers"
                />

                <inertia-link :href="$route('vendor-contacts.index', {remember: 'forget'})" class="btn btn-gray">Clear</inertia-link>
            </div>
        </div>
    </form>

    <div class="w-full my-4">
        <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="vendorContacts.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">Showing {{ vendorContacts.from }} - {{ vendorContacts.to }} out of {{ vendorContacts.total }} Records</div>

            <div class="flex col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end xl:col-span-1 xl:col-end-5">
                <inertia-link :href="$route('vendor-contacts.create')" class="btn btn-blue mr-2 my-1">
                    New
                </inertia-link>

                <loading-button :loading="state === 'exporting'" class="btn btn-gray my-1" @click="exportData" :disabled="!vendorContacts.data.length">
                    Export to CSV
                </loading-button>
            </div>
        </div>

        <div>
            <div class="overflow-x-auto">
                <table class="table table-condensed" ref="table">
                    <thead>
                        <tr>
                            <sortable-table-header field="first_name" :filters="filters" route-name="vendor-contacts.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">First Name</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Last Name')" field="last_name" :filters="filters" route-name="vendor-contacts.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Last Name</sortable-table-header>
                            <th v-show="form.selected_headers.includes('Vendor')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Vendor</th>
                            <sortable-table-header v-show="form.selected_headers.includes('Email')" field="email" :filters="filters" route-name="vendor-contacts.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Email</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Title')" field="title" :filters="filters" route-name="vendor-contacts.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Title</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Decision Maker')" field="is_decision_maker" :filters="filters" route-name="vendor-contacts.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Decision Maker</sortable-table-header>
                            <th scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="vendorContact in vendorContacts.data" :key="vendorContact.id">
                            <td>{{ vendorContact.first_name }}</td>
                            <td v-show="form.selected_headers.includes('Last Name')">
                                {{ vendorContact.last_name }}
                            </td>
                            <td v-show="form.selected_headers.includes('Vendor')">
                                <inertia-link :href="$route('vendors.show', [vendorContact.vendor_id])" class="link">
                                    {{ vendorContact.vendor?.name ?? '' }}
                                </inertia-link>
                            </td>
                            <td v-show="form.selected_headers.includes('Email')">{{ vendorContact.email }}</td>
                            <td v-show="form.selected_headers.includes('Title')">{{ vendorContact.title }}</td>
                            <td v-show="form.selected_headers.includes('Decision Maker')">{{ vendorContact.is_decision_maker ? 'Yes' : 'No'}}</td>
                            <td class="text-right">
                                <inertia-link :href="$route('vendor-contacts.show', [vendorContact.id])" class="link mr-3" title="View Vendor Contact Details">
                                    View
                                </inertia-link>
                                <inertia-link :href="$route('vendor-contacts.edit', [vendorContact.id])" class="link" title="Edit This Vendor Contact">
                                    Edit
                                </inertia-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <pagination :links="vendorContacts.links" />
        </div>

        <div v-if="!vendorContacts.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="users" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Client Contacts Found</span>
        </div>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #header="{close}">
            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <form @submit.prevent id="vendor-contacts-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
                <select-input class="col-span-1" v-model="form.is_decision_maker" label="Decision Maker">
                    <option :value="null">Any</option>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                </select-input>
            </form>
        </template>
    </slide-over>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import { throttle } from 'lodash-es';
    import ColumnSelector from "@/Shared/ColumnSelector.vue";
    import SlideOver from "@/Shared/SlideOver.vue";

    export default {
        components: {
            Head,
            Icon,
            SelectInput,
            LoadingButton,
            Pagination,
            SortableTableHeader,
            ColumnSelector,
            SlideOver,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            vendorContacts: {
                type: Object,
                required: true
            },

            filters: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    per_page: this.filters.per_page,
                    is_decision_maker: this.filters.is_decision_maker,
                    selected_headers: this.filters.selected_headers,
                },
                state: 'passive',
                headers: [],
                excludedHeaders: ['Actions', 'First Name'],
                mounted: false,
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.state = 'exporting';

                this.$inertia.post(this.$route('csv.vendor-contacts.index'), this.queryFilters, {
                    onFinish: () => { this.state = 'passive'; }
                });
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));

                this.headers = headers;
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('vendor-contacts.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = {...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.is_decision_maker,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>