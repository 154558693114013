<template>
    <Head :title="`Scheduled ${serviceScheduledChanges.change_type} for Service ${serviceScheduledChanges.service_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.show', [serviceScheduledChanges.service_id])" class="breadcrumb-link">{{ serviceScheduledChanges.service_id }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Scheduled {{ serviceScheduledChanges.change_type }}</span>
        </nav>
    </Teleport>

    <div class="mt-6 mb-12 text-base lg:text-lg max-w-screen-lg mx-auto">
        <div>
            <div class="flex justify-between items-center my-6">
                <div class="flex flex-row gap-x-2">
                    <h1 class="text-2xl font-bold">{{serviceScheduledChanges.change_type}}</h1>
                    <div class="self-center inline-flex items-center rounded-md px-2 py-1 text-sm font-medium ring-1 ring-inset bg-blue-50 text-blue-500 ring-blue-600/20">
                        Pending
                    </div>
                </div>

                <div class="flex items-center gap-x-4">
                    <inertia-link :href="$route('services.show', serviceScheduledChanges.service_id)" class="btn btn-gray gap-x-2">
                        Back to Service
                    </inertia-link>
                    <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
                </div>
            </div>
        </div>

        <div class="mt-10">
            <div class="relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-start">
                    <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Details</span>
                </div>
            </div>

            <div class="grid gap-x-4 gap-y-6 grid-cols-3 my-4 px-4">
                <div>
                    <label class="form-label">Change Requester</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.change_requester }}</div>
                </div>

                <div>
                    <label class="form-label">Scheduled Date</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.event_payload.actual_date }}</div>
                </div>

                <div>
                    <label class="form-label">Change Reason</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.event_payload.change_reason }}</div>
                </div>
            </div>
        </div>

        <div class="mt-10" v-if="changeTypeVendor">
            <div class="relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-start">
                    <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Vendor</span>
                </div>
            </div>

            <div class="grid gap-x-4 gap-y-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-4 my-4">
                <div v-if="serviceScheduledChanges.changes.vendor_account_id">
                    <label class="form-label">Vendor Account</label>
                    <div class="text-base mt-2">{{ vendorAccount.account_number }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.vendor_contract_id">
                    <label class="form-label">Vendor Contract</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.vendor_contract_id }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.service_account_type">
                    <label class="form-label">Service Account Type</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.service_account_type }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.service_schedule_type">
                    <label class="form-label">Service Schedule Type</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.service_schedule_type }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.pickup_rrule">
                    <label class="form-label">Schedule</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.pickup_rrule ? parseRRule(serviceScheduledChanges.changes.pickup_rrule) : 'No pickup schedule set' }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.vendor_base_charge">
                    <label class="form-label">Vendor Base Charge</label>
                    <div class="text-base mt-2">{{ $filters.format_money(serviceScheduledChanges.changes.vendor_base_charge) }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.vendor_per_unit_charge">
                    <label class="form-label">Vendor Per Unit Charge</label>
                    <div class="text-base mt-2">{{ $filters.format_money(serviceScheduledChanges.changes.vendor_per_unit_charge) }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.vendor_per_occurrence_charge">
                    <label class="form-label">Vendor Per Occurrence Charge (Haul Amount)</label>
                    <div class="text-base mt-2">{{ $filters.format_money(serviceScheduledChanges.changes.vendor_per_occurrence_charge) }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.units_included_per_occurrence">
                    <label class="form-label">Units Included Per Occurrence (Tons Included Per Haul)</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.units_included_per_occurrence }}</div>
                </div>
            </div>
        </div>

        <div class="mt-10" v-if="changeTypeClient">
            <div class="relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-start">
                    <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Client</span>
                </div>
            </div>

            <div class="grid gap-x-4 gap-y-6 grid-cols-4 my-4 px-4">
                <div v-if="serviceScheduledChanges.changes.client_contract_id">
                    <label class="form-label">Client Contract</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.client_contract_id }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.client_base_charge">
                    <label class="form-label">Client Base Charge</label>
                    <div class="text-base mt-2">{{ $filters.format_money(serviceScheduledChanges.changes.client_base_charge) }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.client_per_unit_charge">
                    <label class="form-label">Client Per Unit Charge</label>
                    <div class="text-base mt-2">{{ $filters.format_money(serviceScheduledChanges.changes.client_per_unit_charge) }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.client_per_occurrence_charge">
                    <label class="form-label">Client Per Occurrence Charge (Haul Amount)</label>
                    <div class="text-base mt-2">{{ $filters.format_money(serviceScheduledChanges.changes.client_per_occurrence_charge) }}</div>
                </div>
            </div>
        </div>

        <div class="mt-10" v-if="serviceScheduledChanges.changes.bin">
            <div class="relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-start">
                    <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Bin</span>
                </div>
            </div>

            <div class="grid gap-x-4 gap-y-6 grid-cols-2 sm:grid-cols-3 my-4">
                <div v-if="serviceScheduledChanges.changes.bin.quantity">
                    <label class="form-label">Quantity</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.quantity }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.size">
                    <label class="form-label">Size</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.size }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.size_metric">
                    <label class="form-label">Size Metric</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.size_metric }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.material_type">
                    <label class="form-label">Material Type</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.material_type }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.disposal_method">
                    <label class="form-label">Disposal Method</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.disposal_method }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.type">
                    <label class="form-label">Bin Type</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.type }}</div>
                </div>
            </div>
        </div>

        <div v-if="serviceScheduledChanges.changes.bin" class="mt-4 px-8">
            <div class="block w-full">
                <button class="w-full bg-gray-50 font-semibold text-d-blue-400 py-3 flex items-center justify-center gap-x-2" :class="collapsed ? 'rounded-lg' : 'rounded-t-lg'" @click="changeCollapse">
                    {{ collapsed ? 'Show' : 'Hide' }} Advanced Bin Options
                    <icon class="inline h-4 w-4 fill-current cursor-pointer" :name="collapsed ? 'chevron-right' : 'chevron-down'" />
                </button>
            </div>

            <div v-if="!collapsed" class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 px-8 py-4 bg-gray-50 rounded-b-lg border-t">
                <div v-if="serviceScheduledChanges.changes.bin.owner">
                    <label class="form-label">Asset Owner</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.owner }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.has_casters">
                    <label class="form-label">Has Casters</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.has_casters }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.push_out_service">
                    <label class="form-label">Push Out Service</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.push_out_service }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.push_out_service_description">
                    <label class="form-label">Push Out Service Description</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.push_out_service_description }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.enclosure">
                    <label class="form-label">Enclosure</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.enclosure }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.has_locks">
                    <label class="form-label">Has Lock</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.has_locks }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.has_lids">
                    <label class="form-label">Lids</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.has_lids }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.side_door_to_bin">
                    <label class="form-label">Side Door To Bin</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.side_door_to_bin }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.secured_access">
                    <label class="form-label">Secured Access</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.secured_access }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.keys">
                    <label class="form-label">Requires Keys</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.keys }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.requires_overhead_instructions">
                    <label class="form-label">Overhead Instructions</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.requires_overhead_instructions }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.latitude">
                    <label class="form-label">Bin Latitude</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.latitude }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.longitude">
                    <label class="form-label">Bin Longitude</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.longitude }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.container_access_times">
                    <label class="form-label">Container Access Times</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.container_access_times }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.area_description">
                    <label class="form-label">Area Description</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.area_description }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.configuration">
                    <label class="form-label">Configuration</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.configuration }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.is_compacted_or_baled">
                    <label class="form-label">Is Compacted Or Baled</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.is_compacted_or_baled }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.compaction_method">
                    <label class="form-label">Compaction Method</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.compaction_method }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.compaction_ratio">
                    <label class="form-label">Compaction Ratio</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.compaction_ratio }}</div>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.pounds_per_yard">
                    <label class="form-label">Pounds Per Yard</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.changes.bin.pounds_per_yard }}</div>
                </div>
            </div>
        </div>

        <div class="relative mt-10">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-start">
                <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Other</span>
            </div>
        </div>

        <div class="mt-4 px-8">
            <div class="grid gap-x-4 gap-y-6 grid-cols-1 my-4">
                <div>
                    <label class="form-label">Internal Change Notes</label>
                    <div class="text-base mt-2">{{ serviceScheduledChanges.event_payload.internal_change_notes }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { router, Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import { rrulestr } from "rrule";


    export default {
        components: {
            Icon,
            Head,
        },

        props: {
            serviceScheduledChanges: {
                type: Object,
                required: true
            },

            vendorAccount: {
                type: Object,
                required: false
            }
        },

        methods: {
            deleteScheduledChange() {
                if (window.confirm('Are you sure you want to remove this scheduled revision?')) {
                    router.delete(this.$route('service-scheduled-changes.destroy', [this.serviceScheduledChanges.id]));
                }
            },

            parseRRule(rRule) {
                return rRule.rfcstring ? rrulestr(rRule.rfcstring).toText() : '';
            },

            changeCollapse() {
                this.collapsed = !this.collapsed;
            },
        },

        data() {
            return {
                collapsed: true,
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        computed: {
            changeTypeVendor() {
                return this.serviceScheduledChanges.change_type === 'Level of Service Change' ||
                       this.serviceScheduledChanges.change_type === 'Vendor Pricing Change' ||
                       this.serviceScheduledChanges.change_type === 'Vendor Contract Change' ||
                       this.serviceScheduledChanges.change_type === 'Pickup Days Change';
            },
            changeTypeClient() {
                return this.serviceScheduledChanges.change_type === 'Level of Service Change' ||
                       this.serviceScheduledChanges.change_type === 'Client Billing Change' ||
                       this.serviceScheduledChanges.change_type === 'Client Contract Change';
            },
        },
    }
</script>
