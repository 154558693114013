<template>
    <Head :title="`Pickup - ${pickup.id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('pickups.index')" class="breadcrumb-link">Pickups</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>{{ pickup.id }}</span>
        </nav>
    </Teleport>

    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div class="flex-shrink-0">
                <icon name="truck" class="h-14 w-14" />
            </div>

            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ pickup.id }}
                    <span v-if="pickup.external_reference_id" class="text-lg font-semi-bold text-orange-300">{{ pickup.external_reference_id }}</span>
                </h1>

                <p class="text-sm font-medium text-gray-500">
                    <span class="text-gray-900"> Pickup</span> added <time :datetime="pickup.created_at">{{ $filters.format_date_time(pickup.created_at) }}</time>
                </p>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <inertia-link :href="$route('pickups.edit', [pickup.id])" class="btn btn-gray">Edit</inertia-link>
            <button @click.prevent="deletePickup" class="btn btn-gray">Delete</button>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Pickup details and records.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Client Company</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link :href="$route('client-companies.show', [pickup.location.clientCompany.id])" class="link">
                                        {{ pickup.location.clientCompany.name }}
                                    </inertia-link>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Location</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link :href="$route('locations.show', [pickup.location.id])" class="link">
                                        {{ pickup.location.name }}
                                    </inertia-link>
                                </dd>
                            </div>

                            <div v-if="pickup.service" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Service</dt>
                                <dd class="mt-1 text-sm text-gray-900 flex">
	                                <pioneer-indicator v-if="pickup.service.compactorMonitor" :compactor-monitor="pickup.service.compactorMonitor" />
                                    <inertia-link :href="$route('services.show', [pickup.service_id])" class="link">
                                        {{ pickup.service.id }}
                                    </inertia-link>
                                </dd>
                            </div>

	                        <div v-if="pickup?.service?.label" class="sm:col-span-1">
								<dt class="text-sm font-medium text-gray-500">Equipment Label</dt>
								<dd class="mt-1 text-sm text-gray-900">{{ pickup.service.label}}</dd>
	                        </div>

                            <div v-if="pickup.work_order_id" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Work Order</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link :href="$route('work-orders.show', [pickup.work_order_id])" class="link">
                                        {{ pickup.work_order_id }}
                                    </inertia-link>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Material Type</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ pickup.materialType.name }}</dd>
                            </div>

                            <div v-if="pickup.modifiedByEmployee" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Last Modified By</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ pickup.modifiedByEmployee.first_name + " " + pickup.modifiedByEmployee.last_name }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Source</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ pickup.source }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Start Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date(pickup.start_date) }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">End Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date(pickup.end_date) }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Weight Value</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ pickup.weight_value.toFixed(2).replace(/\.0+$/, '') }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Weight Unit</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ pickup.weight_unit }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Weight Value is Estimated?</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ pickup.weight_value_is_estimated ? 'Yes' : 'No' }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Times Emptied</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ pickup.times_emptied.toFixed(2).replace(/\.0+$/, '') }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Cost</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ pickup.cost ? $filters.format_money(pickup.cost) : null }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Estimated Percent Full</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ pickup.estimated_percent_full ? pickup.estimated_percent_full + '%' : null }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Container Size</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ pickup.container_size }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Disposal Method</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ pickup.disposal_method }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="vendor-invoice-line-items-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="vendor-invoice-line-items-title" class="text-lg leading-6 font-medium text-gray-900">Vendor Invoice Line Items</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Vendor Invoice Line Items attached to this Pickup.</p>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="pickup.vendorInvoiceLineItems.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Line Item Id</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Type</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Description</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Quantity</th>
                                            <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span class="sr-only">Detach</span>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="lineItem in pickup.vendorInvoiceLineItems" :key="pickup.vendorInvoiceLineItems.id">
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">
                                                <inertia-link class="link" :href="$route('vendor-invoices.edit', [lineItem.vendor_invoice_id])">{{ lineItem.id }}</inertia-link>
                                            </td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ lineItem.type }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ lineItem.description }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ lineItem.quantity }}</td>
                                            <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <button @click.prevent="detachVendorInvoiceLineItem(lineItem)" class="link mr-3">
                                                    Detach<span class="sr-only">, {{ lineItem.id }}</span>
                                                </button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="dumpster" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Vendor Invoice Line Items</h3>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3'
    import Logo from "../../Shared/Logo.vue";
    import PioneerIndicator from "../../Shared/PioneerIndicator.vue";

    export default {
        components: {
	        PioneerIndicator,
	        Logo,
            Icon,
            Head,
        },

        props: {
            pickup: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            deletePickup() {
                let confirmed = confirm('Are you sure you want to remove this pickup?');

                if (confirmed) {
                    this.$inertia.delete(this.$route('pickups.destroy', this.pickup.id));
                }
            },

            detachVendorInvoiceLineItem(lineItem) {
                let confirmed = confirm('Are you sure you want to detach this vendor invoice line item?');

                if (confirmed) {
                    this.$inertia.delete(this.$route('pickups.detach-line-item', [this.pickup.id, lineItem.id]));
                }
            },
        }
    }
</script>
