<template>
    <div class="mt-2">
        <div v-if="filterGroups.length > 0" class="w-full grid grid-cols-12">
            <label :id="`${id}-remove-label`" class="hidden">
                Remove
            </label>
        </div>

        <div v-for="(filterGroup, index) in filterGroups" class="mt-8">
            <fieldset
                :id="filterGroup.key"
                :key="filterGroup.key"
                class="px-3 py-2 border my-1 bg-gray-100 gap-4">

                <div class="grid my-2">
                    <div class="flex">
                        <label :id="`${id}-${filterGroup.key}-mode-label-sm`" class="flex px-2 ml-auto my-auto">
                            <span class="text-red-500 pr-1">*</span> Filter Group Mode
                        </label>

                        <select-input :id="`${filterGroup.key}-mode`" class="mx-4" v-model="filterGroup.mode" :aria-labelledby="`${id}-${filterGroup.key}-mode-label-sm`">
                            <option v-for="mode in filterModes" :key="mode" :value="mode">{{ mode }}</option>
                        </select-input>

                        <button @click.prevent="removeFilterGroup(index)" class="my-auto" :aria-labelledby="`${id}-remove-label`">
                            <icon name="trash" class="inline fill-current h-7 w-7 mr-2 text-red-500 float-right"/>
                        </button>
                    </div>

                    <repeatable-custom-report-filter
                        v-model="filterGroup.filters"
                        :mainEntity="mainEntity"
                        :filterGroupKey="filterGroup.key"
                        :entities="entities"
                        :initial-properties="initialProperties"
                        :savedFilters="filterGroup.filters"
                        :errors="errors.filters"
                    />

                    <repeatable-custom-report-filter-group
                        v-model="filterGroup.nested_filter_groups"
                        :mainEntity="mainEntity"
                        :entities="entities"
                        :initialProperties="initialProperties"
                        :savedFilterGroups="filterGroup.nested_filter_groups"
                        :depth="depth+1"
                        :maxDepth="maxDepth"
                        :errors="errors.filter_groups"
                    />
                </div>
            </fieldset>
        </div>

        <div v-if="errors" class="form-error">{{ errors }}</div>

        <button v-if="depth <= maxDepth" :disabled="!mainEntity" @click.prevent="addFilterGroup()" class="my-2 btn btn-gray float-right">
            <icon name="plus" class="inline fill-current h-5 w-5"/>Add Filter Group
        </button>
    </div>
</template>

<script>
import {cloneDeep} from 'lodash-es';
import Icon from '@/Shared/Icon.vue';
import RepeatableCustomReportFilter from '@/Shared/CustomReports/RepeatableCustomReportFilter.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import TextInput from "@/Shared/TextInput.vue";
import { v4 as uuid } from 'uuid';

export default {
    components: {
        Icon,
        RepeatableCustomReportFilter,
        SelectInput,
        TextInput
    },

    inheritAttrs: false,

    props: {
        id: {
            type: String,
            default() {
                return `repeatable-custom-report-filter-group-${uuid()}`;
            },
        },

        label: String,
        mainEntity: String,

        entities: {
            type: Array,
            required: true
        },

        depth: {
            type: Number,
            default: 1
        },

        initialProperties: {
            type: Object,
        },

        maxDepth: {
            type: Number,
            required: true
        },

        savedFilterGroups: {
            type: Array,
            default: () => []
        },

        errors: {
            type: String,
            default: '',
        }
    },

    name: 'repeatable-custom-report-filter-group',

    data() {
        return {
            counter: 0,
            filterGroups: [],
            filterModes: [
                'AND',
                'OR'
            ]
        };
    },

    methods: {
        addFilterGroup(filterGroup = null) {
            if (!filterGroup) {
                filterGroup = {
                    key: `${this.id}-filter-group-${this.counter}`,
                    mode: 'AND',
                    filters: [],
                    nested_filter_groups: [],
                    sortOrder: null
                };
            }

            filterGroup.sortOrder = this.filterGroups.length;

            this.filterGroups.push(filterGroup);

            this.counter++;
        },

        removeFilterGroup(index) {
            this.filterGroups.splice(index, 1);
        },
    },

    watch: {
        mainEntity() {
            this.filterGroups = [];
        },

        filterGroups: {
            handler(newValue, oldValue) {
                this.$emit('update:modelValue', newValue);
            },
            deep: true
        }
    },

    mounted() {
        for (const savedFilterGroup of this.savedFilterGroups) {
            let filterGroup = {
                key: `${this.id}-filter-group-${this.counter}`,
                mode: savedFilterGroup.mode,
                filters: cloneDeep(savedFilterGroup.filters),
                nested_filter_groups: cloneDeep(savedFilterGroup.nested_filter_groups),
                sortOrder: savedFilterGroup.sortOrder
            };
            this.addFilterGroup(filterGroup);
        }
    }
}
</script>
