<template>
	<Head title="Create Pickup"/>

	<Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
		<nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('pickups.index')" class="breadcrumb-link">Pickups</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <form role="form" id="edit-pickup-form" class="space-y-8" @submit.prevent="submitForm">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Information</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Pickup details.</p>
                </div>
            </legend>

            <div class="grid grid-cols-6 gap-4">
                <search-input
                    v-model="form.location_id"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    label="Location"
                    display-property="name"
                    route="json.locations.index"
                    :route-params="{always_include: form.location_id, sort_by: 'name', sort_direction: 'asc', per_page: 50}"
                    :default-value="form.location"
                    :errors="errors['location_id']"
                    mark-as-required>
                </search-input>

                <select-input-2 label="Service" v-model="form.service_id" class="col-span-6 xs:col-span-4 md:col-span-2" :options="services" :errors="errors['service_id']">
                    <template #option-display="{option, selected}">
                        <div :class="{'font-semibold': selected}" class="block">
                            <div>{{ option.id }}</div>
                            <div class="text-xs truncate">{{ option.status }}</div>
                        </div>
                        <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                            <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                        </span>
                    </template>
                </select-input-2>

                <select-input-2 label="Work Order" v-model="form.work_order_id" class="col-span-6 xs:col-span-4 md:col-span-2" :options="workOrders" :errors="errors['work_order_id']">
                    <template #option-display="{option, selected}">
                        <div :class="{'font-semibold': selected}" class="block">
	                        <div>{{ option.display_id }} | {{ option.requested_action}} | {{ option.source }}</div>
                        </div>
                        <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                            <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                        </span>
                    </template>
                </select-input-2>

                <select-input-2 label="Material Type" v-model="form.material_type_id" class="col-span-6 xs:col-span-4 md:col-span-2" :options="materialTypes" :errors="errors['material_type_id']" mark-as-required>
                    <template #option-display="{option, selected}">
                        <div :class="{'font-semibold': selected}" class="block">
                            <div>{{ option.name }}</div>
                        </div>

                        <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                            <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                        </span>
                    </template>
                </select-input-2>

                <date-input v-model="form.start_date" class="col-span-6 xs:col-span-4 md:col-span-2" label="Start Date" :errors="errors['start_date']" mark-as-required />
                <date-input v-model="form.end_date" class="col-span-6 xs:col-span-4 md:col-span-2" label="End Date" :errors="errors['end_date']" />

                <text-input type="number" step="any" v-model="form.weight_value" class="col-span-6 xs:col-span-4 md:col-span-1" label="Weight Value" :errors="errors['weight_value']" mark-as-required />

                <select-input id="weight_unit" class="col-span-6 sm:col-span-1" v-model="form.weight_unit" label="Weight Unit" :errors="errors['weight_unit']" mark-as-required>
                    <option v-for="weightUnit in weightUnits" :key="weightUnit" :value="weightUnit">{{ weightUnit }}</option>
                </select-input>

                <div class="col-span-6 md:col-span-1 flex flex-grow">
                    <toggle-switch-input
                        v-model="form.weight_value_is_estimated"
                        label="Weight Value is Estimated"
                        :errors="errors['weight_value_is_estimated']"
                    />
                </div>

                <text-input type="number" v-model="form.times_emptied" class="col-span-6 xs:col-span-4 md:col-span-1" label="Times Emptied" :errors="errors['times_emptied']" />

                <money-input class="col-span-6 xs:col-span-4 md:col-span-1" v-model="form.cost" label="Cost" :errors="errors['cost']" :defaultCurrency="$attrs.currency" />

                <select-input id="estimated_percent_full" class="col-span-6 sm:col-span-1" v-model="form.estimated_percent_full" label="Estimated Percent Full" :errors="errors['estimated_percent_full']">
                    <option key="" value=""></option>
                    <option v-for="value in allowedEstimatedPercentFullValues" :key="value" :value="value">{{ value }}%</option>
                </select-input>

                <text-input v-model="form.container_size" class="col-span-6 xs:col-span-4 md:col-span-1" label="Container Size" :errors="errors['container_size']" />

                <select-input-2 label="Disposal Method" v-model="form.disposal_method" class="col-span-6 xs:col-span-4 md:col-span-1" :options="disposalMethods" :errors="errors['disposal_method']" mark-as-required >
                    <template #option-display="{option, selected}">
                        <div :class="{'font-semibold': selected}" class="block">
                            <div>{{ option }}</div>
                        </div>
                    <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                            <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                        </span>
                    </template>
                </select-input-2>

                <text-input v-model="form.external_reference_id" class="col-span-6 xs:col-span-4 md:col-span-1" label="External Reference Id" :errors="errors['external_reference_id']"/>
            </div>
        </fieldset>
        <div>
            <loading-button :loading="state === 'saving'" type="submit" class="btn btn-orange">Save</loading-button>
        </div>
    </form>
</template>

<script>
    import AddressPicker from '@/Shared/AddressPicker.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import SearchInput from "@/Shared/SearchInput.vue";
    import SelectInput from '@/Shared/SelectInput.vue';
    import SelectInput2 from '@/Shared/SelectInput2.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import axios from "axios";
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            AddressPicker,
            DateInput,
            Icon,
            LoadingButton,
            MoneyInput,
            SearchInput,
            SelectInput,
            SelectInput2,
            TextInput,
            ToggleSwitchInput,
            Head,
        },

        props: {
            pickup: {
                type: Object,
                required: true
            },

            allowedEstimatedPercentFullValues: {
                type: Array,
                required: true
            },

            disposalMethods: {
                type: Array,
                required: true
            },

            materialTypes: {
                type: Array,
                required: true
            },

            sources: {
                type: Array,
                required: true,
            },

            weightUnits: {
                type: Array,
                required: true
            },

            errors: {
                type: Object,
                default: () => ({})
            }
        },

        data() {
            return {
                form: {
                    ...this.pickup,
	                weight_unit: this.$page.props.default_weight_unit
                },
                locations: [],
                services: [],
                workOrders: [],
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;

            if (this.pickup.location_id) {
                axios.get(this.$route('json.services.index', {location_id: this.pickup.location_id, sort_by: 'services.id', sort_direction: 'asc', per_page: 50})).then(response => {
                    this.services = response.data.data;
                })
            }

            if (this.pickup.service_id) {
                axios.get(this.$route('json.work-orders.index', {service_id: this.pickup.service_id})).then(response => {
                    this.workOrders = response.data.data;
                })
            }
        },

        watch: {
            'form.location_id'() {
                this.form.service_id = null;

                if (this.form.location_id) {
                    axios.get(this.$route('json.services.index', {location_id: this.form.location_id, sort_by: 'services.id', sort_direction: 'asc', per_page: 50})).then(response => {
                        this.services = response.data.data;
                    })
                } else {
                    this.services = [];
                }
            },

            'form.service_id'() {
                this.form.work_order_id = null;

                if (this.form.service_id) {
                    axios.get(this.$route('json.work-orders.index', {service_id: this.form.service_id})).then(response => {
                        this.workOrders = response.data.data;
                    })

                    let theService = this.services.find(service => service.id === this.form.service_id);
                    if (theService) {
                        if (theService.bin.material_type) {
                            let theMaterialType = this.materialTypes.find(materialType => materialType.name === theService.bin.material_type);
                            if (theMaterialType) {
                                this.form.material_type_id = theMaterialType.id;
                            }
                        }

                        if (theService.bin.disposal_method) {
                            this.form.disposal_method = theService.bin.disposal_method;
                        }
                    }
                } else {
                    this.workOrders = [];
                }
            },
        },

        methods: {
            submitForm() {
                if (this.state === 'passive') {
                    this.state = 'saving';

                    this.$inertia.post(this.$route('pickups.store'), this.form, {
                        preserveScroll: true,
                        onFinish: () => this.state = 'passive',
                    });
                }
            },
        },

        computed: {
            dayMax() {
                if (!this.form.year || !this.form.month) {
                    return 31;
                }

                if (this.form.month === 2 && this.form.year % 4 === 0) {
                    return 29;
                }

                if (this.form.month === 2) {
                    return 28;
                }

                if ([4, 6, 9, 11].includes(this.form.month)) {
                    return 30;
                }

                return 31;
            }
        }
    }
</script>
