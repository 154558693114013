<template>
    <div class="overflow-x-auto">
        <table class="table table-hover">
            <thead>
                <tr v-if="reportData?.length > 0">
                    <th v-for="(header, key) in headers" :key="key" class="font-semibold">{{ header }}</th>
                </tr>
                <tr v-else>
                    <th>No Records Found. Please adjust your search filters</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in rows" :key="index">
                    <td v-for="(columnName, header) in headers" :key="header">
                        <slot :name="header" v-bind:reportData="row[header]" v-bind:row="row">{{ row[header] }}</slot>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {startCase, camelCase} from 'lodash-es';

export default {
    components: {

    },

    props: {
        reportData: Array,

        ignore: {
            type: Array,
            required: false,
            default() {
                return []
            }
        }
    },

    computed: {
        headers() {
            var headers = {};
            let first = this.reportData[0]

            Object.keys(first).forEach((key) => {
                if (this.ignore.indexOf(key) === -1) {
                    headers[key] = startCase(camelCase(key))
                }
            });

            return headers;
        },

        rows() {
            let rows = [];

            // this.reportData.forEach((row) => {
            //     row.map((field, key) => ));

            return this.reportData
        }
    },

    methods: {
        startCase, camelCase
    }
}
</script>