<template>
    <Head :title="`Edit Client Payment - ${clientPayment.id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-payments.index')" class="breadcrumb-link">Client Payments</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="max-w-screen-2xl mx-auto">
        <div class="my-12 md:px-6 md:flex md:items-center md:justify-between">
            <div class="flex items-start space-x-5">
                <div class="flex-shrink-0">
                    <icon name="file-invoice-dollar" class="h-10 w-04 text-gray-600 fill-current" />
                </div>
                <div>
                    <h1 class="text-2xl font-bold text-gray-900 leading-6">
                        Client Payment #{{ clientPayment.id }}
                    </h1>

                    <p v-if="clientPayment.entry_date" class="text-sm leading-5 mt-1 font-medium text-gray-500">
                        Entered on <time :datetime="clientPayment.entry_date">{{ $filters.format_date(clientPayment.entry_date) }} by {{ clientPayment.entered_by }}</time>
                    </p>
                </div>
            </div>
            <div v-if="!clientPayment.voided_date && !clientPayment.refunded_at" class="mt-6 flex flex-col justify-stretch space-y-6 sm:flex-row sm:space-y-0 sm:space-x-6 md:mt-0 md:space-x-4">
                <button class="sm:flex-grow md:flex-grow-0 rounded-md bg-white px-4 py-3 text-lg sm:px-3 sm:py-2 sm:text-base md:px-3 md:py-2 md:text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click.prevent="refundAlert">
                    Refund
                </button>
                <button class="sm:flex-grow md:flex-grow-0 rounded-md bg-white px-4 py-3 text-lg sm:px-3 sm:py-2 sm:text-base md:px-3 md:py-2 md:text-sm font-semibold text-red-800 shadow-sm ring-1 ring-inset ring-red-300/50 hover:bg-red-50" @click.prevent="showVoidModal">
                    Void
                </button>
            </div>
        </div>

        <div class="md:px-6 grid grid-cols-1 gap-6 lg:grid-cols-3 lg:grid-rows-1">

            <!-- Payment Summary Card -->
            <div class="max-w-screen-sm mx-auto w-5/6 sm:w-3/4 lg:w-auto lg:mx-0 lg:col-start-3">
                <h2 class="sr-only">Payment Summary</h2>

                <div class="rounded bg-slate-50 shadow">
                    <dl class="flex flex-col text-center gap-5 p-6 sm:text-left sm:gap-0 sm:flex-row lg:p-4">
                        <div class="flex-auto">
                            <dt class="text-base font-semibold leading-6 text-gray-900">
                                Total
                            </dt>
                            <dd class="mt-1 text-lg font-semibold leading-6 text-gray-900">
                                {{ $filters.format_money(clientPayment.total_amount) }}
                            </dd>
                        </div>
                        <div class="flex-auto">
                            <dt class="text-base font-semibold leading-6 text-gray-900">
                                Applied
                            </dt>
                            <dd class="mt-1 text-lg font-semibold leading-6 text-gray-900">
                                {{ $filters.format_money(clientPayment.applied_amount) }}
                            </dd>
                        </div>
                        <div class="sm:self-end uppercase flex-none">
                            <dt class="sr-only">Status</dt>
                            <dd v-if="!clientPayment.voided_date && !clientPayment.refunded_at && !amountsAreEqual()" class="inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">
                                Incomplete
                            </dd>

                            <dd v-else-if="!clientPayment.voided_date && !clientPayment.refunded_at && amountsAreEqual()" class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                Complete
                            </dd>

                            <dd v-else-if="clientPayment.refunded_at" class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                                Refunded
                            </dd>

                            <dd v-else-if="clientPayment.voided_date" class="inline-flex items-center rounded-md bg-red-50 px-3 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                                Void
                            </dd>

                            <dd v-else class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                                Invalid
                            </dd>
                        </div>
                    </dl>

                    <dl class="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-2 lg:grid-cols-1 p-6 border-t border-gray-900/5">
                        <div class="flex w-full flex-none gap-x-4">
                            <dt class="flex-none">
                                <span class="sr-only">Remitter Name</span>
                                <icon name="user-circle" class="h-6 w-5 text-gray-500 fill-current" title="Remitter Name" aria-hidden="true" />
                            </dt>
                            <dd class="text-sm leading-6 font-medium text-gray-900">
                                {{ clientPayment.remitter_name }}
                            </dd>
                        </div>
                        <div class="flex items-center w-full flex-none gap-x-4">
                            <dt class="flex-none">
                                <span class="sr-only">Payment Date</span>
                                <icon v-if="clientPayment.payment_method === 'Check'" name="money-check-edit-alt" class="h-6 w-5 text-gray-500 fill-current" title="Receipt Date" aria-hidden="true" />
                                <icon v-else name="receipt-paper" class="h-5 w-5 text-gray-500 fill-current" title="Receipt Date" aria-hidden="true" />
                            </dt>
                            <dd class="text-sm leading-6 text-gray-600">
                                <time v-if="clientPayment.payment_method === 'Check'" :datetime="clientPayment.check_date">
                                    {{ $filters.format_date(clientPayment.check_date) }}
                                </time>
                                <time v-else :datetime="clientPayment.receipt_date">
                                    {{ $filters.format_date(clientPayment.receipt_date) || 'N/A' }}
                                </time>
                            </dd>
                        </div>
                        <div class="flex w-full flex-none gap-x-4">
                            <dt class="flex-none">
                                <span class="sr-only">Payment Receipt</span>
                                <icon v-if="clientPayment.payment_method === 'Credit Card'" name="credit-card" class="h-6 w-5 text-gray-500 fill-current" title="Payment Method" aria-hidden="true" />
                                <icon v-else-if="clientPayment.payment_method === 'Check'" name="money-check-alt" class="h-6 w-5 text-gray-500 fill-current" title="Payment Method" aria-hidden="true" />
                                <icon v-else name="money" class="h-6 w-5 text-gray-500 fill-current" title="Payment Method" aria-hidden="true" />
                            </dt>
                            <dd class="text-sm leading-6 text-gray-600">
                                {{ clientPayment.payment_method }}
                                <template v-if="clientPayment.payment_method === 'ACH' && clientPayment.ach_account_number_last_digits">
                                    (Account #{{ clientPayment.ach_account_number_last_digits }})
                                </template>
                                <template v-if="clientPayment.payment_method === 'Check'">
                                    #{{clientPayment.check_number || 'N/A'}}
                                </template>
                                <template v-if="clientPayment.payment_method === 'Credit Card'">
                                    (Ending in {{ clientPayment.credit_card_number_last_digits || 'N/A'}})
                                </template>
                            </dd>
                        </div>
                    </dl>

                    <dl class="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-2 p-6 border-t border-gray-900/5">
                        <div class="col-span-1">
                            <dt class="text-sm font-medium text-gray-500">Client Company</dt>
                            <dd class="mt-1 text-sm text-gray-900">
                                <inertia-link :href="$route('client-companies.show', clientPayment.clientCompany.id)" class="link">
                                    {{ clientPayment.clientCompany.name }}
                                </inertia-link>
                            </dd>
                        </div>
                        <div class="col-span-1">
                            <dt class="text-sm font-medium text-gray-500">Receipt Location</dt>
                            <dd class="mt-1 text-sm text-gray-900">
                                {{ clientPayment.receipt_location }}
                            </dd>
                        </div>
                        <div class="col-span-1">
                            <dt class="text-sm font-medium text-gray-500">Receipt Date</dt>
                            <dd class="mt-1 text-sm text-gray-900">
                                {{ $filters.format_date(clientPayment.receipt_date) }}
                            </dd>
                        </div>
                        <div v-if="clientPayment.check_date" class="col-span-1">
                            <dt class="text-sm font-medium text-gray-500">Check Date</dt>
                            <dd class="mt-1 text-sm text-gray-900">
                                {{ $filters.format_date(clientPayment.check_date) }}
                            </dd>
                        </div>
                    </dl>

                    <div v-if="clientPayment.voided_date" class="gap-4 border-t border-gray-900/5 px-4 py-4 bg-red-50 rounded-b-lg">
                        <dl class="grid grid-cols-1 sm:grid-cols-2 gap-6">
                            <div class="">
                                <dt class="text-sm font-medium text-red-900/60">Voided By</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ clientPayment.voided_by }}
                                </dd>
                            </div>

                            <div class="">
                                <dt class="text-sm font-medium text-red-900/60">Voided At</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ $filters.format_date_time(clientPayment.voided_by_user_at) }}
                                </dd>
                            </div>

                            <div class="">
                                <dt class="text-sm font-medium text-red-900/60">Void Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ $filters.format_date(clientPayment.voided_date) }}
                                </dd>
                            </div>

                            <div class="">
                                <dt class="text-sm font-medium text-red-900/60">Reason</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ clientPayment.voided_reason }}
                                </dd>
                            </div>
                        </dl>
                    </div>

                    <div v-if="clientPayment.refunded_at" class="gap-4 border-t border-gray-900/5 px-4 py-4 bg-sky-50 rounded-b-lg">
                        <dl class="grid grid-cols-1 sm:grid-cols-2 gap-6">
                            <div class="">
                                <dt class="text-sm font-medium text-sky-900/60">Refunded By</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ clientPayment.refunded_by }}
                                </dd>
                            </div>

                            <div class="">
                                <dt class="text-sm font-medium text-sky-900/60">Refunded On</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ $filters.format_date(clientPayment.refunded_at) }}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <!-- Payments Applied Section -->
            <div class="mt-4 px-4 py-6 sm:px-6 lg:px-8 col-span-1 shadow sm:mx-0 rounded-lg lg:mt-0 lg:row-start-1 lg:col-start-1 lg:col-span-2 overflow-hidden">
                <div class="mb-6">
                    <h2 class="text-lg leading-6 font-medium text-gray-900">Payment Application Details</h2>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Invoices that this payment has applied to.</p>
                </div>
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="sm:ring-1 sm:ring-gray-300 sm:rounded-lg mb-0 min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th class="text-left p-3 whitespace-nowrap">Client Invoice</th>
                                    <th class="text-left p-3 whitespace-nowrap">Client Account</th>
                                    <th class="text-left p-3">Applied Amount (${{ totalAmountApplied }})</th>
                                    <th class="text-left p-3 whitespace-nowrap">Applied Date</th>
                                    <th class="w-8"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="applied in clientPayment.applied" :key="applied.id">
                                    <td class="p-3 border-b">
                                        <inertia-link v-if="applied.client_invoice_id" :href="$route('client-invoices.index', { search: applied.clientInvoice.invoice_number })" class="link">
                                            {{ applied.clientInvoice.invoice_number }}
                                        </inertia-link>
                                    </td>
                                    <td class="p-3 border-b">
                                        <inertia-link v-if="applied.client_account_id" :href="$route('client-accounts.show', applied.client_account_id)" class="link">
                                            {{ applied.clientAccount.name }}
                                        </inertia-link>
                                    </td>
                                    <td class="p-3 border-b">{{ $filters.format_money(applied.applied_amount) }}</td>
                                    <td class="p-3 border-b">{{ $filters.format_date(applied.applied_date) }}</td>
                                    <td class="p-3 border-b">
                                        <button @click.prevent="removeAppliedPayment(applied)" type="button" class="danger-link text-lg p-1" title="Remove This Applied Payment Amount">
                                            <icon name="times" class="inline text-red-600 fill-current h-5 w-5 mb-1" />
                                        </button>
                                    </td>
                                </tr>
                                <!-- No payments, show voided status -->
                                <tr v-if="clientPayment.voided_date" class="bg-gray-200">
                                    <td class="text-center text-2xl font-medium text-gray-800 py-8" colspan="5">
                                        <span>No payments applied. Payment was voided.</span>
                                    </td>
                                </tr>
                                <!-- No payments, show refunded status -->
                                <tr v-else-if="clientPayment.refunded_at" class="bg-gray-200">
                                    <td class="text-center text-2xl font-medium text-gray-800 py-8" colspan="5">
                                        <span>No payments applied. Payment was refunded.</span>
                                    </td>
                                </tr>
                                <!-- Add new applied payment row -->
                                <tr v-else class="bg-gray-50 rounded-b-lg">
                                    <td class="py-5 px-3 rounded-bl-lg">
                                        <text-input v-if="form.client_account_id === null" v-model="form.client_invoice_number" placeholder="Client Invoice Number" :errors="errors.client_invoice_number" />
                                    </td>
                                    <td class="py-5 px-3">
                                        <select-input v-if="form.client_invoice_number === ''" v-model="form.client_account_id" :errors="errors.client_account_id">
                                            <option :value="null">Select a Client Account</option>
                                            <option v-for="clientAccount in clientAccounts" :key="clientAccount.id" :value="clientAccount.id">
                                                {{ clientAccount.name }}
                                            </option>
                                        </select-input>
                                    </td>
                                    <td class="py-5 px-3"><money-input v-model="form.applied_amount" :defaultCurrency="clientPayment.clientCompany.currency" :errors="errors['applied_amount.amount']" /></td>
                                    <td class="py-5 px-3"><date-input v-model="form.applied_date" :errors="errors.applied_date" /></td>
                                    <td class="py-5 px-3 rounded-br-lg">
                                        <button @click.prevent="addAppliedPayment()" type="button" class="text-blue text-lg p-1" title="Add New Applied Amount">
                                            <icon name="plus" class="inline text-blue-600 fill-current h-5 w-5 mb-1" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal for Refund -->
    <modal class="max-w-lg" ref="voidPaymentModal" tabindex="-1" role="dialog">
        <template #modal-body>
            <div class="p-8">
                <h2 class="text-lg font-semibold leading-7 text-gray-900">Void Payment #{{clientPayment.id}}</h2>
                <p class="mt-1 text-base leading-6 text-gray-600">Submit this form to void the payment.</p>

                <form action="" id="void-payment-form" class="mt-10 grid grid-cols-1 gap-x-4 gap-y-6 my-4 items-center">
                    <div class="">
                        <select-input v-model="voidIsNSF" label="Is NSF?">
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select-input>
                    </div>

                    <div v-if="!voidIsNSF" class="">
                        <textarea-input v-model="form.voided_reason" :errors="errors.voided_reason" label="Void Reason" />
                    </div>

                    <div class="">
                        <date-input v-model="form.voided_date" :errors="errors.voided_date" label="Void Date" />
                    </div>

                    <div class="flex flex-row-reverse">
                        <button @click.prevent="voidClientPayment()" type="button" class="rounded-md bg-red-600 px-3.5 py-2.5 ml-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Void Payment</button>
                        <button @click.prevent="$refs.voidPaymentModal.close()" type="button" class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Cancel</button>
                    </div>
                </form>
            </div>
        </template>

        <template #modal-footer="{close}">
            <div></div>
        </template>
    </modal>
</template>

<script>
    import SlideOver from "@/Shared/SlideOver.vue";
    import Modal from "@/Shared/Modal.vue";
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import { format } from 'date-fns';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            SlideOver,
            Modal,
            DateInput,
            Icon,
            MoneyInput,
            Pagination,
            SelectInput,
            TextareaInput,
            TextInput,
            Head,
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            clientPayment: {
                type: Object,
                required: true
            },

            clientAccounts: Array,

            tenant:{
                type: Object,
                required: true
            }
        },

        data() {
            return {
                unsavedChanges: false,
                error_messages: [],

                form: {
                    client_invoice_number: '',
                    client_account_id: null,
                    billing_group_name: null,
                    applied_date: format(new Date, 'yyyy-LL-dd'),
                    applied_amount: {amount: 0, currency: this.tenant.currency},
                    voided_date: format(new Date, 'yyyy-LL-dd'),
                    voided_reason: 'NSF'
                },
                searchInvoiceModal: {
                    searching: true,
                    results: []
                },
                showRefundSection: false,
                showVoidSection: false,
                voidIsNSF: true,
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            addAppliedPayment() {
                this.$inertia.post(this.$route('client-payments.applied-client-payments.store', this.clientPayment.id), this.form, {
                    preserveScroll: true,
                    preserveState: false,
                    onFinish: () => {
                        if (this.$page.props.success_message) {
                            this.$toast.success(this.$page.props.success_message);
                        }

                        if (this.$page.props.error_message) {
                            this.$toast.error(this.$page.props.error_message);
                        }
                    }
                });
            },

            removeAppliedPayment(appliedClientPayment) {
                let confirmed = confirm('Are you sure you want to remove this applied payment?');

                if (confirmed) {
                    this.$inertia.delete(this.$route('client-payments.applied-client-payments.destroy', [this.clientPayment.id, appliedClientPayment.id]));
                }
            },
            
            refundClientPayment() {
                this.$inertia.post(this.$route('client-payments.refund', this.clientPayment.id), {}, {
                    onFinish: () => {
                        if (this.$page.props.success_message) {
                            this.$toast.success(this.$page.props.success_message);
                            this.showRefundSection = false;
                        } else if (this.$page.props.error_message) {
                            this.$toast.error(this.$page.props.error_message);
                        }
                    }
                });
            },

            voidClientPayment() {
                this.$inertia.post(this.$route('client-payments.void', this.clientPayment.id), this.form, {
                    onFinish: () => {
                        if (this.$page.props.success_message) {
                            this.showVoidSection = false;
                        }
                    }
                });
            },

            getCurrentClientAccountCurrency() {
                let clientAccount = this.clientAccounts.find(clientAccount => clientAccount.id == this.form.client_account_id);

                if (clientAccount) {
                    return clientAccount.currency;
                }

                return this.tenant.currency;
            },

            amountsAreEqual() {
                return this.$filters.format_money(this.clientPayment.total_amount) === this.$filters.format_money(this.clientPayment.applied_amount)
            },

            refundAlert() {
                if (window.confirm('This will create a journal entry in the external accounting software to refund this payment. Do you want to continue?')) {
                    // we clicked "ok"
                    this.refundClientPayment();
                }
            },

            showVoidModal() {
                this.$refs.voidPaymentModal.show();
            }
        },

        computed: {
            totalAmountApplied() {
                let amount = this.clientPayment.applied.reduce((carry, applied) => {
                    return carry + parseInt(applied.applied_amount.amount) 
                }, 0);

                return this.$filters.format_decimal(amount);
            }
        },

        watch: {
            voidIsNSF: function() {
                if (this.voidIsNSF) {
                    this.form.voided_date = format(new Date, 'yyyy-LL-dd');
                    this.form.voided_reason = 'NSF';
                } else {
                    this.form.voided_date = this.clientPayment.receipt_date;
                    this.form.voided_reason = '';
                }
            },
            'form.client_account_id': function() {
                this.currency = this.getCurrentClientAccountCurrency();
            }
        }
    }
</script>