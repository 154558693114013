<style>
    
</style>

<template>
    <div class="cursor-pointer">
        <slot name="label">
            <label v-if="label" class="form-label" :for="id" :aria-required="markAsRequired">
                <span v-if="markAsRequired" class="text-red-500">*</span> {{ label }}
            </label>
        </slot>

        <input
            v-bind="{...$attrs, class: null}"
            v-show="editing" 
            type="text" 
            class="form-input" 
            :value="modelValue" 
            :disabled="disabled" 
            @keyup.enter="$event.target.blur()" 
            @blur="updateField($event)" 
            ref="inlineInput"
        >

        <span v-show="!editing" @click="toggleEdit($event)">{{ displayValue }}</span>
    </div>
</template>

<script setup>
    import { filters } from '@/Shared/Utils/Filters.js';
    import { computed, ref, watch, nextTick } from 'vue';
    import { v4 as uuid } from 'uuid';

    const emit = defineEmits(['update:modelValue']);

    /**
     * Props
     */
    const props = defineProps({
        id: {
            type: String,
            default() {
                return `inline-text-input-${uuid()}`;
            },
        },

        type: {
            type: String,
            default: 'text',
        },

        modelValue: [String, Number],

        label: String,

        helpText: {
            type: String,
            default: ''
        },

        errors: {
            type: String,
            default: '',
        },

        markAsRequired: {
            type: Boolean,
            default: false
        },

        required: {
            default: true
        },

        filters: {
            type: Array,

            default: function() {
                return [];
            }
        },

        sanitizers: {
            type: Array,

            default: function() {
                return [];
            }
        },

        disabled: {
            type: Boolean,
            default: false
        }
    });

    /**
     * State
     */
    const editing = ref(false);
    const inlineInput = ref(null);
        
    /**
     * Methods
     */
    function updateField(event) {
        if (isValid(event.target.value)) {
            let value = props.sanitizers.reduce((carry, sanitizer) => {
                carry = sanitizer(carry);

                return carry;
            }, event.target.value);

            emit('update:modelValue', value);
        }

        toggleEdit();
    }

    function isValid(value) {
        if (props.required == true && !value) {
            return false;
        }

        return true;
    }

    function toggleEdit() {
        editing.value = !editing.value;

        if (editing.value) {
            nextTick(() => {
                inlineInput.value.focus();
            });
        }
    }

    /**
     * Computed
     */
    const displayValue = computed(() => {
        return props.filters.reduce((carry, filter) => {
            carry = filters[filter](carry);

            return carry;
        }, props.modelValue);
    });
</script>