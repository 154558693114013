<template>
    <Head title="Client Line Item Details" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('reports.index')" class="breadcrumb-link">Reports</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Client Line Item Details</span>
        </nav>
    </Teleport>

    <form id="filter-report-data-form" action="" class="grid grid-cols-4 gap-4 my-2 mb-4" @submit.prevent>
        <text-input id="" class="col-span-4 md:col-span-2 lg:col-span-1" v-model="form.client_company_name" placeholder="Client Company Name" label="Client Company Name"></text-input>

        <text-input class="col-span-4 md:col-span-2 lg:col-span-1" v-model="form.location_name" placeholder="Location Name" label="Location Name"></text-input>

        <text-input class="col-span-4 md:col-span-2 lg:col-span-1" v-model="form.billing_group_name" placeholder="Client Account Name" label="Client Account Name"></text-input>

        <text-input class="col-span-4 md:col-span-2 lg:col-span-1" v-model="form.vendor_name" placeholder="Vendor Name" label="Vendor Name"></text-input>

        <text-input class="col-span-4 md:col-span-2 lg:col-span-1" v-model="form.billing_group_account_number" placeholder="bg-1234-abcd" label="Client Account Account Number"></text-input>

        <text-input class="col-span-4 md:col-span-2 lg:col-span-1" v-model="form.client_invoice_or_credit_memo_number" placeholder="ci-1234-abcd" label="Invoice / Memo Number"></text-input>

        <date-input class="col-span-4 md:col-span-2 lg:col-span-1" v-model="form.client_invoice_or_credit_memo_date_start" label="Invoice / Memo Date Start"></date-input>

        <date-input class="col-span-4 md:col-span-2 lg:col-span-1" v-model="form.client_invoice_or_credit_memo_date_end" label="Invoice / Memo Date End"></date-input>

        <div class="col-span-4 md:col-span-2 lg:col-span-1">
            <Combobox as="div" v-model="form.client_line_item_origin_types" multiple>
                <ComboboxLabel class="form-label" for="origin-types">Client Line Item Origin Types</ComboboxLabel>

                <div class="relative">
                    <ComboboxInput id="origin-types" name="origin_types" class="form-input" @change="originTypeComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />

                    <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <!-- Heroicon name: solid/selector -->
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </ComboboxButton>

                    <ComboboxOptions v-if="clientLineItemOriginTypes.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ComboboxOption v-for="type in filteredOriginTypes" :key="type" :value="type" as="template" v-slot="{ active, selected }">
                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                            <span :class="['block truncate', selected && 'font-semibold']">
                            {{ type }}
                            </span>

                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                <!-- Heroicon name: solid/check -->
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                </div>
            </Combobox>
        </div>

        <div class="col-span-4 md:col-span-2 lg:col-span-1 flex flex-wrap">
            <inertia-link :href="$route('reports.client-line-item-details', {remember: 'forget'})" class="mt-6 btn btn-gray mr-2">Clear</inertia-link>

            <loading-button :loading="state === 'exporting'" class="mt-6 btn btn-gray" @click="exportData">
                Export to CSV
            </loading-button>
        </div>
    </form>

    <div v-if="reportData.data.length !== 0">
        <reporting-table :reportData="reportData.data"></reporting-table>
        <pagination v-if="reportData" :links="reportData.links" />
    </div>
    <div v-else>
        <h5>No results found, please adjust your filters.</h5>
    </div>
</template>

<script>
    import DropdownLink from '@/Shared/DropdownLink.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import Icon from '@/Shared/Icon.vue';
    import MonthInput from '@/Shared/MonthInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import { debounce, pickBy } from 'lodash-es';
    import ReportingTable from '@/Shared/ReportingTable.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import { Head } from '@inertiajs/vue3';

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Icon,
            DropdownLink,
            MonthInput,
            DateInput,
            SelectInput,
            Pagination,
            ReportingTable,
            TextInput,
            ToggleSwitchInput,
            LoadingButton,
            Head,
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            reportData: {
                type: Object,
                required: true
            },

            clientLineItemOriginTypes: {
                type: Array,
                required: true
            },

            filters: Object
        },

        data() {
            return {
                form: { ...this.filters},
                originTypeComboBoxQuery: '',
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            exportData() {
                this.state = 'exporting';

                this.$inertia.post(this.$route('csv.client-line-item-details'), {...this.form}, {
                    onFinish: () => { this.state = 'passive'; }
                });
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected'; 
            },
        },

        computed: {
            filteredOriginTypes() {
                return this.originTypeComboBoxQuery === ''
                    ? this.clientLineItemOriginTypes
                    : this.clientLineItemOriginTypes.filter((type) => {
                        return type.toLowerCase().includes(this.originTypeComboBoxQuery.toLowerCase());
                    });
            },
        },

        watch: {
            form: {
                handler: debounce(function() {
                    let query = pickBy(this.form);
                    query = Object.keys(query).length ? query : {remember: 'forget'};

                    this.$inertia.get(this.$route("reports.client-line-item-details"), query, {preserveState: true});
                }, 500),

                deep: true
            },
        }
    }
</script>