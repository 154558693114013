<template>
	<icon name="pioneer" class="hidden md:block inline fill-current h-4 w-4 self-center text-gray-500" v-tippy="{...tippyOptions, content: getMonitorData()}"/>
</template>
<script>
import Icon from "./Icon.vue"
import {addBusinessDays, format} from "date-fns";
import 'tippy.js/animations/shift-away.css'

export default {
	name: 'pioneer-indicator',
	components: {Icon},
	props: {
		compactorMonitor: {
			type: Object,
			required: true,
		},

	},

	data() {
		return {
			tippyOptions: {
				allowHTML: true,
				animation: 'shift-away',
				maxWidth: 225,
			},
			statuses: {
				'online': 'animate-pulse text-green-400 bg-green-400/20',
				'offline': 'animate-pulse text-white-400 bg-rose-400/20',
				'inactive': 'text-white bg-gray-400/20',
			},
		};
	},

	methods: {
		getMonitorData() {
			return `
	        <div class="w-full">
				<div class="inline"><b>Status</b>:
				   <span class="inline-block">
				      <span class="${this.statuses[this.compactorMonitor.connectivity_status]} flex-none inline p-1 rounded-full h-3 w-3 bg-current">${this.compactorMonitor.connectivity_status}</span>
				   </span>
				</div>
				<div>
					${this.getNeedsAttention()}
				</div>

				<div class=" inline-block"><b>Fullness</b>: ${this.getFullnessData()}</div>

				<div><b>Last Pickup</b>: ${this.getLastPickup()}</div>
			</div>
      `;
		},

		getFullnessData() {
			return this.compactorMonitor.monitorConfiguration?.percentage_full ? `${this.compactorMonitor.monitorConfiguration.percentage_full}%` : 'N/A';
		},

		getLastPickup() {
			return this.compactorMonitor.monitorConfiguration?.last_picked_up ? `${this.$filters.format_date(this.compactorMonitor.monitorConfiguration.last_picked_up)}` : 'N/A';
		},

		getNeedsAttention() {
			const dateFormat = 'yyyy-MM-dd';
			const twoBusinessDaysAgo = addBusinessDays(new Date(), -2);

			const runDate = format(new Date(this.compactorMonitor.monitorConfiguration?.latest_run_detected_on), dateFormat);
			const compareDate = format(new Date(twoBusinessDaysAgo), dateFormat);
			if (runDate < compareDate) {
				// needs attention if the latest run is older than 2 business days
				return `
					<span class="px-0 flex text-wrap" v-tippy="'No runs within the past 2 business days!'">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="text-rose-400 fill-current h-6 w-6 mr-2 self-center">
					        <path d="M270.2 160h35.5c3.4 0 6.1 2.8 6 6.2l-7.5 196c-.1 3.2-2.8 5.8-6 5.8h-20.5c-3.2 0-5.9-2.5-6-5.8l-7.5-196c-.1-3.4 2.6-6.2 6-6.2zM288 388c-15.5 0-28 12.5-28 28s12.5 28 28 28 28-12.5 28-28-12.5-28-28-28zm281.5 52L329.6 24c-18.4-32-64.7-32-83.2 0L6.5 440c-18.4 31.9 4.6 72 41.6 72H528c36.8 0 60-40 41.5-72zM528 480H48c-12.3 0-20-13.3-13.9-24l240-416c6.1-10.6 21.6-10.7 27.7 0l240 416c6.2 10.6-1.5 24-13.8 24z"/>
					    </svg>
					    <span class="text-wrap ">
					        No run detected within the past 2 business days
					    </span>
                    </span>
				`;
			} else {
				return '';
			}
		}
	}
}
</script>