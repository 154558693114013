<template>
    <Head title="Expiring Vendor Contracts" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('reports.index')" class="breadcrumb-link">Reports</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Expiring Vendor Contracts</span>
            <span v-if="reportData.data.length">
                <small class=""> (Showing {{ reportData.from }} - {{ reportData.to }} out of {{ reportData.total }} Rows)</small>
            </span>
        </nav>
    </Teleport>

    <form id="filter-report-data-form" action="" class="grid grid-cols-4 gap-4 my-2 mb-4" @submit.prevent>
        <text-input v-model="form.client_company_name" placeholder="Client Company Name" label="Client Company Name"></text-input>
        <text-input v-model="form.location_name" placeholder="Location Name" label="Location Name"></text-input>

        <select-input v-model="form.is_franchised" label="Is Franchised">
            <option key="" value="">Any</option>
            <option key="true" value="true">Yes</option>
            <option key="false" value="false">No</option>
        </select-input>

        <div class="col-span-4 md:col-span-2 lg:col-span-1">
            <Combobox as="div" v-model="form.contract_statuses" multiple>
                <ComboboxLabel class="form-label" for="statuses">Statuses</ComboboxLabel>

                <div class="relative">
                    <ComboboxInput id="statuses" name="statuses" class="form-input" @change="statusComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />

                    <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <!-- Heroicon name: solid/selector -->
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </ComboboxButton>

                    <ComboboxOptions v-if="contractStatuses.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ComboboxOption v-for="status in filteredStatusOptions" :key="status" :value="status" as="template" v-slot="{ active, selected }">
                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                            <span :class="['block truncate', selected && 'font-semibold']">
                            {{ status }}
                            </span>

                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                <!-- Heroicon name: solid/check -->
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                </div>
            </Combobox>
        </div>

        <div class="col-span-4 md:col-span-2 lg:col-span-1">
            <Combobox as="div" v-model="form.service_types" multiple>
                <ComboboxLabel class="form-label" for="service-types">Service Type</ComboboxLabel>

                <div class="relative">
                    <ComboboxInput id="service-types" name="service_types" class="form-input" @change="serviceTypeComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />

                    <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <!-- Heroicon name: solid/selector -->
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </ComboboxButton>

                    <ComboboxOptions v-if="serviceTypes.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ComboboxOption v-for="type in filteredServiceTypeOptions" :key="type" :value="type" as="template" v-slot="{ active, selected }">
                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                            <span :class="['block truncate', selected && 'font-semibold']">
                            {{ type }}
                            </span>

                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                <!-- Heroicon name: solid/check -->
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                </div>
            </Combobox>
        </div>

        <div class="col-span-4 md:col-span-2 lg:col-span-1">
            <Combobox as="div" v-model="form.service_account_types" multiple>
                <ComboboxLabel class="form-label" for="service-account-types">Service Account Type</ComboboxLabel>

                <div class="relative">
                    <ComboboxInput id="service-account-types" name="service_account_types" class="form-input" @change="serviceAccountTypeComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />

                    <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <!-- Heroicon name: solid/selector -->
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </ComboboxButton>

                    <ComboboxOptions v-if="serviceAccountTypes.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ComboboxOption v-for="accountType in filteredServiceAccountTypeOptions" :key="accountType" :value="accountType" as="template" v-slot="{ active, selected }">
                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                            <span :class="['block truncate', selected && 'font-semibold']">
                            {{ accountType }}
                            </span>

                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                <!-- Heroicon name: solid/check -->
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                </div>
            </Combobox>
        </div>

        <div class="col-span-4 md:col-span-2 lg:col-span-1">
            <Combobox as="div" v-model="form.service_schedule_types" multiple>
                <ComboboxLabel class="form-label" for="service-schedule-types">Service Schedule Type</ComboboxLabel>

                <div class="relative">
                    <ComboboxInput id="service-schedule-types" name="service_schedule_types" class="form-input" @change="serviceScheduleTypeComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />

                    <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <!-- Heroicon name: solid/selector -->
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </ComboboxButton>

                    <ComboboxOptions v-if="serviceScheduleTypes.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ComboboxOption v-for="scheduleType in filteredServiceScheduleTypeOptions" :key="scheduleType" :value="scheduleType" as="template" v-slot="{ active, selected }">
                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                            <span :class="['block truncate', selected && 'font-semibold']">
                            {{ scheduleType }}
                            </span>

                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                <!-- Heroicon name: solid/check -->
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                </div>
            </Combobox>
        </div>

        <date-input v-model="form.contract_expiration_begin_date" label="Contract Expiration Begin Date" />
        <date-input v-model="form.contract_expiration_end_date" label="Contract Expiration End Date" />

        <div class="col-span-4 md:col-span-2 lg:col-span-1 flex flex-wrap">
            <inertia-link :href="$route('reports.expiring-vendor-contracts', {'is_franchised': 'false', 'contract_expiration_end_date': fiveMonthsFromNow})" class="mt-6 btn btn-gray mr-2">Clear</inertia-link>

            <loading-button :loading="state === 'exporting'" class="mt-6 btn btn-gray" @click="exportData">
                Export to CSV
            </loading-button>
        </div>
    </form>

    <div v-if="reportData.data.length !== 0">
        <reporting-table :reportData="reportData?.data"></reporting-table>
        <pagination v-if="reportData" :links="reportData.links" />
    </div>
    <div v-else>
        <h5>No results found, please adjust your filters.</h5>
    </div>
</template>

<script>
    import DropdownLink from '@/Shared/DropdownLink.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import Icon from '@/Shared/Icon.vue';
    import MonthInput from '@/Shared/MonthInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import { debounce, pickBy } from 'lodash-es';
    import ReportingTable from '@/Shared/ReportingTable.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import { addMonths, format, subMonths } from "date-fns";
    import { Head } from '@inertiajs/vue3';

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Icon,
            DropdownLink,
            MonthInput,
            DateInput,
            SelectInput,
            Pagination,
            ReportingTable,
            TextInput,
            ToggleSwitchInput,
            LoadingButton,
            Head,
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            reportData: {
                type: Object,
                required: true
            },

            serviceTypes: {
                type: Array,
                required: true
            },

            contractStatuses: {
                type: Array,
                required: true
            },

            serviceAccountTypes: {
                type: Array,
                required: true
            },

            serviceScheduleTypes: {
                type: Array,
                required: true
            },

            filters: Object
        },

        data() {
            return {
                form: { ...this.filters},
                statusComboBoxQuery: '',
                serviceTypeComboBoxQuery: '',
                serviceAccountTypeComboBoxQuery: '',
                serviceScheduleTypeComboBoxQuery: '',
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            exportData() {
                this.state = 'exporting';

                this.$inertia.post(this.$route('csv.expiring-vendor-contracts'), {...this.form}, {
                    onFinish: () => { this.state = 'passive'; }
                });
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected'; 
            },
        },

        computed: {
            fourMonthsAgo() {
                return format(subMonths(new Date, 4), 'yyyy-MM-dd')
            },

            fiveMonthsFromNow() {
                return format(addMonths(new Date, 5), 'yyyy-MM-dd')
            },

            filteredStatusOptions() {
                return this.statusComboBoxQuery === ''
                    ? this.contractStatuses
                    : this.contractStatuses.filter((status) => {
                        return status.toLowerCase().includes(this.statusComboBoxQuery.toLowerCase());
                    });
            },

            filteredServiceTypeOptions() {
                return this.serviceTypeComboBoxQuery === ''
                    ? this.serviceTypes
                    : this.serviceTypes.filter((status) => {
                        return status.toLowerCase().includes(this.serviceTypeComboBoxQuery.toLowerCase());
                    });
            },

            filteredServiceAccountTypeOptions() {
                return this.serviceAccountTypeComboBoxQuery === ''
                    ? this.serviceAccountTypes
                    : this.serviceAccountTypes.filter((serviceAccountType) => {
                        return serviceAccountType.toLowerCase().includes(this.serviceAccountTypeComboBoxQuery.toLowerCase());
                    });
            },

            filteredServiceScheduleTypeOptions() {
                return this.serviceScheduleTypeComboBoxQuery === ''
                    ? this.serviceScheduleTypes
                    : this.serviceScheduleTypes.filter((serviceScheduleType) => {
                        return serviceScheduleType.toLowerCase().includes(this.serviceScheduleTypeComboBoxQuery.toLowerCase());
                    });
            },
        },

        watch: {
            form: {
                handler: debounce(function() {
                    let query = pickBy(this.form);
                    query = Object.keys(query).length ? query : {remember: 'forget'};

                    this.$inertia.get(this.$route("reports.expiring-vendor-contracts"), query, {preserveState: true});
                }, 500),

                deep: true
            },
        },
    }
</script>
