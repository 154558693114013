<template>
    <Head title="Service Types" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Service Types</span>
        </nav>
    </Teleport>

    <main class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="service_types" />

                <div class="lg:col-span-9 px-4 sm:px-6">
                    <div class="flex flex-row items-center mb-6">
                        <div class="w-1/2">
                            <div>
                                <div class="input-group mt-0">
                                    <span class="input-group-item-left">
                                        <icon name="search" class="size-4 text-gray-400 fill-current" />
                                    </span>

                                    <input type="text" v-model="form.search" placeholder="Search" class="input-group-field">
                                </div>
                            </div>
                        </div>
                        <div class="ml-auto">
                            <inertia-link :href="$route('tenant-settings.service-types.create')" class="btn btn-orange">
                                New
                            </inertia-link>
                        </div>
                    </div>

                    <div v-if="serviceTypes.data.length">
                        <div class="my-6">
                            (Showing <span class="font-semibold text-gray-900">{{ serviceTypes.data.length }}</span> out of <span class="font-semibold text-gray-900">{{ serviceTypes.total }}</span>)
                        </div>

                        <div class="overflow-x-auto">
                            <table class="table table-condensed">
                                <thead>
                                    <tr>
                                        <sortable-table-header field="name" class="font-semibold" :filters="filters" route-name="tenant-settings.service-types.index">Type Name</sortable-table-header>
                                        <sortable-table-header field="service_id_prefix" class="font-semibold" :filters="filters" route-name="tenant-settings.service-types.index">service_id_prefix</sortable-table-header>
                                        <sortable-table-header field="type_description" class="font-semibold" :filters="filters" route-name="tenant-settings.service-types.index">Type Description</sortable-table-header>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="serviceType in serviceTypes.data" :key="serviceType.id" :class="{'bg-gray-50 text-gray-400': serviceType.is_archived}">
                                        <td :class="{'italic': serviceType.is_archived}">
                                            {{ serviceType.name }}
                                            <span v-if="serviceType.is_archived">(Archived)</span>
                                        </td>
                                        <td>{{ serviceType.service_id_prefix }}</td>
                                        <td>{{ serviceType.type_description }}</td>
                                        <td>
                                            <span class="inline-block gap-2">
                                                <inertia-link :href="$route('tenant-settings.service-types.show', [serviceType.id])" :class="{'text-gray-400 hover:text-gray-800': serviceType.is_archived, 'link': !serviceType.is_archived}" title="View Service Type">
                                                    View
                                                </inertia-link>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <pagination :links="serviceTypes.links" />
                    </div>

                    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                        <icon name="receipt" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Service Types Found</span>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script setup>
    import { router, Head } from '@inertiajs/vue3';
    import {reactive, ref, inject, onMounted, watch} from 'vue';

    // Components
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import SubNav from '../SubNav.vue';
    import { throttle, pickBy } from 'lodash-es';

    // Inject
    const route = inject('route');

    // Props
    const props=defineProps({
        serviceTypes: {
            type: Object,
            required: true
        },

        filters: Object
    });

    const form=reactive({
        search: props.filters.search
    });
    const mounted=ref(false);

    // Mount
    onMounted(() => {
        mounted.value = true;
    });

    // Watchers
    watch(() => form,
        throttle(function() {
            let query = pickBy(form);
            query = Object.keys(query).length ? query : {remember: 'forget'};

            router.get(route('tenant-settings.service-types.index'), query, {preserveState: true});
        }, 150),

        { deep: true, }
    )
</script>
