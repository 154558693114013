<template>
    <inertia-link :href="props.route" class="group inline-flex items-center gap-2 text-base font-normal text-gray-500 transform hover:gap-3 hover:text-gray-700 hover:underline hover:scale-105 hover:translate-x-1 transition-all duration-200">
        <icon name="long-arrow-left" class="size-4 fill-current"/>
        {{ props.label }}
    </inertia-link>
</template>

<script setup>
    // Components
    import Icon from '@/Shared/Icon.vue';

    // Props
    const props = defineProps({
        route: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    });
</script>
