<template>
    <Head title="Create Sales Tax" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Tenant Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.sales-taxes.index')" class="breadcrumb-link">Sales Taxes</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <div class="container mx-auto">
        <div class="my-4">
            <div class="w-full">
                <div class="grid grid-cols-2 gap-6">
                    <text-input v-model="form.name" label="Name" :errors="errors.name" mark-as-required/>

                    <text-input v-model="form.display_name" label="Display Name (on client invoices)" :errors="errors.display_name" mark-as-required />

                    <text-input v-model="form.default_rate" label="Default Tax Rate" :errors="errors.default_rate" placeholder="Example: 6.9" />

                    <text-input v-model="form.external_reference_id" label="External Reference Id" :errors="errors.external_reference_id" />

                    <select-input v-model="form.gl_code" label="GL Account" :errors="errors.gl_code">
                        <option v-for="account in accountsList" :key="account.id" :value="account.external_accounting_id">{{ account.name }}</option>
                    </select-input>

                    <textarea-input v-model="form.internal_notes" label="Internal Notes" :errors="errors.internal_notes"></textarea-input>
                </div>

                <div class="mt-6">
                    <inertia-link :href="$route('tenant-settings.sales-taxes.index')" class="btn btn-gray mr-4">
                        Cancel
                    </inertia-link>
                    <button class="btn btn-orange" @click.prevent="addSalesTax">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { router } from '@inertiajs/vue3';
    import { ref, reactive, inject, onMounted } from 'vue';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';

    // Inject
    const route = inject('route');

    // Props
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },
        salesTax: {
            type: Object,
            required: true
        },
        accountsList: {
            type: Array,
            required: true
        }
    });

    // State
    const form = reactive({...props.salesTax});
    const mounted = ref(false);

    // Mount
    onMounted(() => {
        mounted.value = true;
    });

    // Methods
    function addSalesTax() {
        router.post(route('tenant-settings.sales-taxes.store'), form);
    }
</script>