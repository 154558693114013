<template>
    <div>
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 px-4">
            <div class="md:col-span-3">
                <date-input
                    v-model="actual_date"
                    label="Date of Change"
                    helpText="When should this change happen?"
                    :min="earliestServiceActivity.actual_date"
                    :errors="errors['actual_date']"
                    mark-as-required
                />
            </div>

            <div class="md:col-span-3" v-if="actual_date && actual_date !== today">
                <div class="rounded-md bg-yellow-50 p-4" v-if="actual_date && actual_date < today">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <icon name="info-circle" class="size-5 text-yellow-400 fill-current" />
                        </div>
                        <div class="ml-3 flex-1">
                            <p class="text-base text-yellow-700">
                                NOTE: This will insert a historical record that something was incorrect and will update various reporting data. This correction will also show up in the historical time line of the service which may help with auditing. This correction will retroactively alter the service, but will not alter any related data (such as old invoice line items).
                            </p>
                        </div>
                    </div>
                </div>
                <div class="rounded-md bg-blue-50 p-4" v-if="actual_date && actual_date > today">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <icon name="info-circle" class="size-5 text-blue-400 fill-current" />
                        </div>
                        <div class="ml-3 flex-1">
                            <p class="text-base text-blue-700">
                                NOTE: This will insert a scheduled change that will not affect the service until the chosen date. You may cancel this change at any time prior to the chosen date.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Ignore this for now unless other options are needed -->
            <!-- <div :class="[actual_date && actual_date === today ? 'md:col-span-3' : 'col-span-1 md:col-span-6']">
                <select-input v-model="change_reason" label="Reason for Change" :errors="errors['change_reason']" :markAsRequired="true">
                    <option>Other</option>
                </select-input>
            </div> -->

            <div v-if="service.serviceType.enabled_type_fields.includes('service_schedule_info')" class="col-span-1 md:col-span-6">
                <label for="pickup-schedule" class="block font-medium leading-6 text-gray-900">
                    <span class="text-red-500">*</span> Pickup Schedule
                </label>

                <div class="flex flex-col items-start gap-6 lg:gap-12 mt-2 md:flex-row">
                    <div class="md:flex-shrink-0" :class="[form?.pickup_rrule?.freq === 'WEEKLY' ? 'md:max-w-48' : 'md:max-w-full']">
                        <div class="flex flex-col gap-3">
                            <span>{{ rrule_description }}</span>
                            <div v-if="errors['pickup_rrule'] || errors['average_pickups_per_week']" class="text-red-500">
                                The pickup schedule is invalid
                            </div>
                            <a class="link cursor-pointer text-base inline-flex items-center gap-1" @click.prevent="showUpdatePickupRRuleModal">
                                Edit Schedule <icon name="edit" class="inline fill-current w-5 h-6"></icon>
                            </a>
                        </div>
                    </div>
                    <div class="w-full md:flex-1" v-if="form?.pickup_rrule?.freq === 'WEEKLY'">
                        <div class="w-full overflow-hidden rounded-lg grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7">
                            <div
                                v-if="form.pickup_rrule.byday"
                                v-for="(day, id) in daysOfWeek" :key="id" class="w-full group relative text-center px-2"
                            >
                                <div class="text-base border-b pb-1">{{ day }}</div>
                                <div class="flex justify-center items-center p-4 pb-8 lg:pb-0">
                                    <icon name="check" class="text-green-500 fill-current size-4" v-if="form.pickup_rrule.byday.includes(id)"/>
                                    <icon name="times" class="text-gray-300 fill-current size-4" v-else />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="border-t border-gray-300 mt-8 pt-8 px-4">
            <textarea-input rows="4" v-model="internal_change_notes" label="Internal Change Note" :errors="errors['internal_change_notes']" />
        </div>

        <loading-button class="rounded-md bg-d-orange-500 px-3 py-2 mt-12 text-base font-semibold text-white shadow-sm hover:bg-d-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-d-orange-500 disabled:opacity-50" :loading="saving" @click="submitChange" :disabled="!actual_date || !change_reason">
            Submit Change
        </loading-button>

        <!-- Pickup RRule Modal -->
        <modal
            v-if="service.serviceType.enabled_type_fields.includes('service_schedule_info')"
            class="max-w-2xl"
            ref="updatePickupRRuleModal"
            tabindex="-1"
            role="dialog"
        >
            <template #modal-header="{close}">
                <div class="p-4 border-b border-gray-400 flex justify-between">
                    <div class="my-1 text-2xl">
                        Update Schedule
                    </div>

                    <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                        <icon name="times" class="fill-current inline w-6 h-6"/>
                    </button>
                </div>
            </template>

            <template #modal-body>
                <div class="p-4">
                    <r-rule-input
                        ref="rRuleInput"
                        v-model:modelValue="form.pickup_rrule"
                        v-model:weeklyAverage="form.average_pickups_per_week"
                        v-model:description="rrule_description"
                        start-date-label="First Pickup"
                        weekly-average-label="Average Pickups Per Week"
                        :start-with-schedule="true"
                    />
                </div>
            </template>

            <template #modal-footer="{close}">
                <div class="p-4 flex">
                    <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script setup>
    import { format, startOfToday } from 'date-fns';
    import { router, usePage } from '@inertiajs/vue3';
    import { reactive, ref, inject } from 'vue';

    import Icon from '@/Shared/Icon.vue';
    import Modal from '@/Shared/Modal.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import RRuleInput from '@/Shared/RRuleInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';

    /**
     * Props
     */
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },

        service: {
            type: Object,
            required: true
        },

        who: {
            type: String,
            required: true
        },

        earliestServiceActivity: {
            type: Object,
            required: true
        }
    });

    /**
     * Injected
     */
    const route = inject('route');
    const page = usePage();

    /**
     * Data
     */
    const saving = ref(false);
    const actual_date = ref(format(startOfToday(), 'yyyy-MM-dd'));
    const change_reason = ref('Other');
    const change_requester = ref(props.who);
    const internal_change_notes = ref(null);
    const today = ref(format(startOfToday(), 'yyyy-MM-dd'));
    const rrule_description = ref(null);
    const updatePickupRRuleModal = ref(null);
    const form = reactive({
        average_pickups_per_week: props.service.average_pickups_per_week ?? null,
        pickup_rrule: props.service.pickup_rrule ?? null,
    });

    const daysOfWeek = reactive({
        'SU': 'Sunday',
        'MO': 'Monday',
        'TU': 'Tuesday',
        'WE': 'Wednesday',
        'TH': 'Thursday',
        'FR': 'Friday',
        'SA': 'Saturday',
    });

    /**
     * Methods
     */
    function submitChange() {
        saving.value = true;

        router.post(route('services.pickup-days-changes.store', [props.service.id]), {
            ...form,
            'actual_date': actual_date.value,
            'change_reason': change_reason.value,
            'change_requester': props.who,
            'internal_change_notes': internal_change_notes.value,
        }, {
            onFinish: () => saving.value = false
        });
    }

    function showUpdatePickupRRuleModal() {
        updatePickupRRuleModal.value.show();
    }
</script>
