<style>

</style>

<template>
    <div>
        <label v-if="label" class="form-label" :for="id">
            <span v-if="markAsRequired" class="text-red-500">*</span> {{ label }}
        </label>

        <p v-if="helpText" class="mt-2 text-sm text-gray-600">{{ helpText }}</p>

         <div v-if="userAgent === 'firefox'" class="grid grid-cols-2" >
            <div  class="inline">
                <select class="form-input" v-model="selectedMonth" :class="{error :errors}">
                    <option :value="null">Select a Month</option>
                    <option v-for="month in months" :key="month">{{ month }}</option>
                </select>
            </div>
            <div  class="inline">
                <select v-model="selectedYear" class="form-input" :class="{error :errors}">
                    <option :value="null">Select a Year</option>
                    <option v-for="year in years" :key="year">{{ year }}</option>
                </select>
            </div>
        </div>

        <input v-else :id="id" ref="input" v-bind="$attrs" class="form-input" :class="{ error: errors }" type="month" :value="modelValue" :disabled="disabled" @input="$emit('update:modelValue', $event.target.value)">
        
        <div v-if="errors" class="form-error">{{ errors }}</div>
    </div>
</template>

<script>
    import { v4 as uuid } from 'uuid';

    export default {
        props: {
            id: {
                type: String,
                default() {
                    return `month-input-${uuid()}`;
                },
            },

            modelValue: {
                required: true
            },

            label: String,

            helpText: {
                type: String,
                default: ''
            },

            errors: {
                type: String,
                default: '',
            },

            markAsRequired: {
                type: Boolean,
                default: false
            },

            disabled: {
                type: Boolean,
                default: false
            }
        },

        data () {
            let separatorIndex = this?.modelValue?.indexOf('-') ?? null;

            return {
                months: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
                years: [...Array(50).keys()].map(x => x + 2000),
                selectedYear: this.modelValue?.substring(0, separatorIndex) ?? null,
                selectedMonth: this.modelValue?.substring(separatorIndex + 1) ?? null
            };
        },

        methods: {
            focus() {
                this.$refs.input.focus()
            },

            select() {
                this.$refs.input.select()
            },
            setMonthYear() {
                if (this.selectedMonth !== null && this.selectedYear !== null) {
                  this.$emit('update:modelValue', `${this.selectedYear}-${this.selectedMonth}` ?? null);
                }
            }
        },

        watch: {
          modelValue(newValue, oldValue) {
              this.$emit('change', newValue);
          },
          selectedYear(newValue, oldValue) {
              if (newValue !== null) {
                  this.setMonthYear();
              }
          },
          selectedMonth(newValue, oldValue) {
              if (newValue !== null) {
                  this.setMonthYear();
              }
          }
        },
        computed: {
          userAgent() {
            let userAgent;
            if (navigator.userAgent.indexOf("Firefox") !== -1) {
              userAgent = 'firefox';
            }
            return userAgent;
          }
        }
    }
</script>