<template>
    <Head title="Billing Dashboard"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Billing Dashboard</span>
        </nav>
    </Teleport>

    <div class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="billing-dashboard"/>

                <div class="lg:col-span-9 pt-5">
                    <div class="sm:px-6">
                        <div class="flex justify-between items-start">
                            <div>
                                <h2 class="text-lg leading-6 font-medium text-gray-900">Current Plan</h2>
                                <h3 class="text-3xl mt-1">
                                    <span class="uppercase">Gather</span>
                                    (Trial)
                                </h3>
                            </div>
                        </div>

                        <div>
                            <div class="mt-10 flex items-center gap-x-4">
                                <h4 class="flex-none text-sm font-semibold leading-6 text-d-blue-600">
                                    Available Plans
                                </h4>

                                <div class="h-px flex-auto bg-gray-100"/>
                            </div>

                            <div
                                v-for="(details, plan) in plans" :key="plan"
                                class="flex flex-col p-8 border md:border-0 md:ring-1 md:ring-gray-900/10 md:shadow-md md:rounded-md mt-8 mb-16"
                                :class="isGatherTrial && plan === 'Gather' ? 'ring-d-orange-500/50' : ''"
                            >
                                <div class="flex flex-col items-center justify-between md:flex-row cursor-pointer"
                                     @click="expandPlan(plan)">
                                    <div class="w-full md:mr-4 space-y-2">
                                        <h3 class="text-3xl font-bold uppercase"
                                            :class="isGatherTrial && plan === 'Gather' ? 'text-d-orange-500' : ''">
                                            {{ plan }}</h3>
                                        <p class="p-0 m-0 text-sm leading-6 text-gray-600">{{ details.description }}</p>
                                    </div>
                                    <div class="w-full md:w-1/2 flex items-center justify-between space-x-4">
                                        <ul class="mt-1 space-y-1">
                                            <li v-for="(name, key) in details.features"
                                                class="flex items-center rounded-full text-xs font-medium text-gray-800">
                                                <icon name="check" v-if="features[key]"
                                                      class="inline fill-current text-green-600 w-3 h-3 mr-2"/>
                                                <icon name="lock" v-else
                                                      class="inline fill-current text-red-600 w-3 h-3 mr-2"/>
                                                {{ name }}
                                            </li>
                                        </ul>
                                        <div class="flex-shrink-0 mx-8 lg:mx-0">
                                            <div v-if="!details.pricing.length" class="link text-sm underline">
                                                <a href="https://www.discoveryapp.io/request-a-demo" target="_blank">Contact
                                                    Sales</a>
                                            </div>
                                            <div v-else>
                                                <div
                                                    class="flex items-center justify-center bg-gray-100 rounded-full w-10 h-10">
                                                    <icon v-if="openPlan === plan" name="chevron-down"
                                                          class="inline fill-current text-gray-500 h-6"/>
                                                    <icon v-else name="chevron-right"
                                                          class="inline fill-current text-gray-500 h-6"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="details.pricing.length && openPlan === plan">
                                    <div class="isolate mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl">
                                        <div class="rounded-3xl p-8 ring-1 ring-gray-300" v-for="pricing in details.pricing" :key="pricing.id">
                                            <h3 id="tier-hobby" class="text-lg font-semibold leading-8 text-gray-900">
                                                {{ pricing.tier }}
                                            </h3>

                                            <p class="mt-4 text-sm leading-6 text-gray-600">
                                                {{ JSON.parse(pricing.display_settings).description }}
                                            </p>

                                            <p class="mt-6 flex items-baseline gap-x-1">
                                                <!-- Price, update based on frequency toggle state -->
                                                <span class="text-4xl font-bold tracking-tight text-gray-900">
                                                    ${{ $filters.format_decimal(pricing.base_price, 0) }}
                                                </span>
                                                <!-- Payment frequency, update based on frequency toggle state -->
                                                <span
                                                    v-if="pricing.frequency === 'Monthly'"
                                                    class="text-sm font-semibold leading-6 text-gray-600">
                                                    /{{ pricing.frequency }}
                                                </span>
                                                <span v-else class="text-sm font-semibold leading-6 text-gray-600"> To Get Started</span>
                                            </p>

                                            <span v-if="pricing.frequency === 'Monthly'" class="text-xs">
                                                Annual contract
                                            </span>
                                            <span v-else class="text-xs">
                                                No contract
                                            </span>

                                            <button
                                                v-if="isGatherTrial" type="button"
                                                @click="purchase(pricing.tier, pricing.id)"
                                                aria-describedby="tier-hobby"
                                                class="appearance-none mt-6 block w-full rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-d-orange-600 text-d-orange-600 ring-1 ring-inset ring-d-orange-200 hover:ring-d-orange-300 hover:bg-d-orange-100"
                                            >
                                                Purchase
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Balance Modal -->
    <modal class="max-w-2xl" ref="balanceModal" tabindex="-1" role="dialog">
        <template #modal-body>
            <form class="container mx-auto p-8">
                <div>
                    <div v-if="billingConfig?.current_prepaid_balance" class="mb-8">
                        <h2 class="text-lg leading-6 font-medium text-gray-900">Current Balance</h2>
                        <h3 class="text-3xl mt-1">{{ filters.format_money(billingConfig.current_prepaid_balance) }}</h3>
                    </div>

                    <label for="floor" class="block font-medium leading-6 text-gray-900">When balance reaches</label>
                    <div class="relative mt-1 rounded-md shadow-sm w-40">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span class="text-gray-500 sm"></span>
                        </div>
                        <money-input
                            :min="props.restrictions.floor"
                            :max="floorMax"
                            step="1"
                            v-model="form.floor"
                            name="floor"
                            id="floor"
                            aria-required="true"
                            :defaultCurrency="props.restrictions.currency"
                            :forceWholeNumbers="true"
                            @updated="ceilingMinChanged"
                        />
                    </div>
                    <div v-if="errors.floor" class="form-error">{{ errors.floor }}</div>
                    <p class="mt-1 leading-6 text-gray-600">Enter an amount between ${{ parseInt(props.restrictions.floor / 100) }} and ${{ parseInt(floorMax / 100) }}</p>
                </div>

                <div class="mt-6">
                    <label for="ceiling" class="block font-medium leading-6 text-gray-900">Bring my balance back up
                        to</label>
                    <div class="relative mt-1 rounded-md shadow-sm w-40">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span class="text-gray-500 sm">$</span>
                        </div>
                        <money-input
                            :min="ceilingMin"
                            :max="props.restrictions.ceiling"
                            step="1"
                            v-model="form.ceiling"
                            name="ceiling"
                            id="ceiling"
                            aria-required="true"
                            :defaultCurrency="props.restrictions.currency"
                            :forceWholeNumbers="true"
                        />
                    </div>
                    <div v-if="errors.ceiling" class="form-error">{{ errors.ceiling }}</div>
                    <p class="mt-1 leading-6 text-gray-600">Enter an amount between ${{ ceilingMin / 100 }} and ${{ parseInt(props.restrictions.ceiling / 100) }}</p>
                </div>
            </form>
        </template>

        <template #modal-footer>
            <div class="flex justify-end bg-gray-50 p-2 gap-4 rounded-b-lg">
                <button class="flex-no-shrink text-white py-2 px-4 rounded-lg text-base bg-gray-500 hover:bg-gray-700" @click="closeModal">
                    Cancel
                </button>

                <loading-button type="submit" @click="submitForm" :loading="saving" class="btn btn-orange text-base py-2 px-4">
                    Go To Checkout
                </loading-button>
            </div>
        </template>
    </modal>
</template>

<script setup>
    import { ref, onMounted, inject, getCurrentInstance, onUnmounted } from 'vue';
    import { filters } from '@/Shared/Utils/Filters.js';

    // Components
    import { Head, router, usePage } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SubNav from '../SubNav.vue';
    import Modal from '@/Shared/Modal.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';

    const page = usePage();
    const instance = getCurrentInstance();
    const toast = inject('toast');
    const route = inject('route');

    // Props
    const props = defineProps({
        tenant: Object,
        features: Object,
        plans: Object,
        pricingPlans: Object,
        billingConfig: Object,
        restrictions: Object,
        errors: {
            type: Object,
            default: () => ({})
        },
    });

    // State
    const mounted = ref(false);
    const saving = ref(false);
    const otherUsers =  ref([]);
    const openPlan = ref('');
    const form = ref({
        floor:
            props.billingConfig.prepaid_balance_floor ?
            props.billingConfig.prepaid_balance_floor :
            {amount:(Number(props.restrictions.floor)), currency:props.restrictions.currency},
        ceiling:
            props.billingConfig.prepaid_balance_ceiling ?
            props.billingConfig.prepaid_balance_ceiling :
            {amount:(Number(props.restrictions.floor + props.restrictions.delta)), currency:props.restrictions.currency},
        replenish: !props.billingConfig.prepaid_auto_replenish_disabled_at,
    });

    const isGatherTrial = ref(instance.attrs.tenant_billing_type === 'trial');
    const floorMax = props.restrictions.ceiling - props.restrictions.delta;
    const ceilingMin = ref(Number(form.value.floor.amount) + Number(props.restrictions.delta));

    Echo.join(`billing_dashboard.${props.billingConfig.tenant_id}`)
        .listen('.App\\Events\\TenantBilling\\FinalizePayAsYouGoSignupJobHasFinished', () => {
            router.reload({
                preserveScroll: true,
                onSuccess: () => {
                    saving.value = false;
                    toast.success('Your Pay As You Go account has finished processing.');
                },
            })
        })
        .listen('.App\\Events\\TenantBilling\\FinalizeSubscriptionSignupJobHasFinished', () => {
            router.reload({
                preserveScroll: true,
                onSuccess: () => {
                    saving.value = false;
                    toast.success('Your Pay As You Go account has been created.');
                },
            })
        });

    onMounted(() => {
        if (isGatherTrial) {
            expandPlan('Gather');
        }

        mounted.value = true;
    });

    onUnmounted(() => {
        Echo.leave(`billing_dashboard.${props.billingConfig.tenant_id}`);
    });

    // Toggles
    function expandPlan(plan) {
        if (openPlan.value === plan) {
            openPlan.value = '';
        } else {
            openPlan.value = plan;
        }
    }

    // Buttons
    function purchase(tier, pricingId) {
        switch (tier) {
            case 'Pay As You Go':
                showModal();
                break;
            default:
                saving.value = true;
                router.post(route('tenant-settings.subscriptions.new', pricingId));
        }
    }

    // Modal
    function showModal() {
        instance.refs.balanceModal.show();
    }

    function closeModal() {
        instance.refs.balanceModal.close();
    }

    function submitForm() {
        saving.value = true;
        router.post(route('tenant-settings.pay-as-you-go.process'), form.value);

        closeModal();
    }

    function ceilingMinChanged() {
        ceilingMin.value = Number(form.value.floor.amount) + Number(props.restrictions.delta);

        if (Number(form.value.ceiling.amount) < Number(ceilingMin.value)) {
            form.value.ceiling = {amount:ceilingMin.value, currency:form.value.ceiling.currency};
        }
    }
</script>
