<template>
    <Head title="Excess and Contaminated Charges" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('reports.index')" class="breadcrumb-link">Reports</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Excess and Contaminated Charges</span>
        </nav>
    </Teleport>

    <div class="w-full my-4">
        <form @submit.prevent id="ocr-vendor-invoice-search-form" class="grid grid-cols-9 gap-x-4 gap-y-6 my-4">
            <div class="col-span-9 sm:col-span-3">
                <label for="excess-and-contamination-charges-search-form" class="form-label">Search</label>

                <div class="input-group">
                    <span class="input-group-item">
                        <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                    </span>
                    <input type="search" name="search" id="search" v-model="form.search" placeholder="Search" class="input-group-field">
                </div>
            </div>

            <div class="col-span-9 sm:col-span-2">
                <date-input label="After Start Date" v-model="form.after_start_date"></date-input>
            </div>

            <div class="col-span-9 sm:col-span-2">
                <date-input label="Before Start Date" v-model="form.before_start_date"></date-input>
            </div>

            <div class="col-span-9 sm:col-span-2">
                <select-input v-model="form.fee_types" label="Fee Types">
                    <option :value="[4, 7]">Excess and Contamination</option>
                    <option :value="[4]">Only Excess</option>
                    <option :value="[7]">Only Contamination</option>
                </select-input>
            </div>
        </form>

        <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="reportData.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">
                Showing {{ reportData.from }} - {{ reportData.to }} out of {{ reportData.total }} Records
            </div>

            <div class="col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end">
                <div class="flex items-center">
                    <inertia-link :href="$route('reports.excess-and-contaminated-charges', {remember: 'forget'})" class="btn btn-gray mx-2">
                        Clear
                    </inertia-link>

                    <loading-button :loading="state === 'Exporting'" class="btn btn-gray" @click="exportData" :disabled="!reportData.data.length">
                        Export to CSV
                    </loading-button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="reportData.data.length !== 0">
        <reporting-table :reportData="reportData.data" :ignore=ignore></reporting-table>
        <pagination v-if="reportData" :links="reportData.links" />
    </div>
    <div v-else>
        <h5>No results found.</h5>
    </div>
</template>

<script>
    import DropdownLink from '@/Shared/DropdownLink.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import { debounce, pickBy } from 'lodash-es';
    import ReportingTable from '@/Shared/ReportingTable.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            DropdownLink,
            DateInput,
            SelectInput,
            Pagination,
            LoadingButton,
            ReportingTable,
            Head,
        },

        props: {
            reportData: {
                type: Object,
                required: true
            },

            filters: Object
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    fee_types: this.filters.fee_types,
                    after_start_date: this.filters.after_start_date,
                    before_start_date: this.filters.before_start_date,
                },
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            exportData() {
                this.state = 'saving';

                this.$inertia.post(this.$route('csv.excess-and-contaminated-charges'), {...this.form}, {
                    onFinish: () => { this.state = 'passive'; }
                });
            },
        },

        watch: {
            form: {
                handler: debounce(function() {
                    let query = pickBy(this.form);
                    query = Object.keys(query).length ? query : {remember: 'forget'};

                    this.$inertia.get(this.$route("reports.excess-and-contaminated-charges"), query, {preserveState: true});
                }, 500),

                deep: true
            },
        },

        computed: {
            ignore() {
                if (! this.$page.props.permissions.accessRevenueManagement) {
                    return ['Client Company Id', 'Client Line Item - Total Amount'];
                } else{
                    return ['Client Company Id'];
                }
            },
        }
    }
</script>