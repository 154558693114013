<template>
    <div class="p-2">
	    <h3 class="my-3 p-1 w-full text-d-navy-500 font-medium text-xl">Tonnage</h3>
	    <div id="compactor-monitor-work-order-tonnage-column-chart-container"></div>
    </div>
</template>

<script>
    import Highcharts from 'highcharts/highstock';
    import HighchartsMore from 'highcharts/highcharts-more';
    import autosize from "autosize/dist/autosize";

    HighchartsMore(Highcharts);

    export default {
        props: {
           summaries: {
			   type: Array,
               required: true
           },

           baselineData:{
			   type: Object,
			   required: true
		   },
        },

        data() {
            return {
                chart: null
            }
        },

        mounted() {
            window.addEventListener('resize', () => {
                this.drawChart();
            });

			if (this.summaries.length > 0) {
				this.drawChart();
			}
        },

        methods: {
            drawChart() {
	            Highcharts.setOptions({
		            colors: ['#012639', '#075076', '#5fa3ec', '#fc9776', '#f4c326']
	            });
                this.chart = Highcharts.stockChart('compactor-monitor-work-order-tonnage-column-chart-container', {
                    chart: {
                        type: 'column',
                        zoomType: 'x',
                        reflow: true,
                    },
                    legend: {
                        enabled: true
                    },
                    plotOptions: {
                        series: {
                            animation: true
                        },

                        column: {
                            pointPlacement: 'on',
                            pointPadding: 0,
                            borderWidth: 0
                        }
                    },
	                credits: {
						enabled:false,
	                },
                    loading: {
                        labelStyle: {
                            color: 'white'
                        },
                        style: {
                            backgroundColor: 'gray'
                        }
                    },
                    title: 'Compactor Fullness',
                    xAxis: {
                        type: 'datetime',
	                    dateTimeLabelFormats: {
		                    millisecond: '%Y-%m',
		                    second: '%Y-%m',
		                    minute: '%Y-%m',
		                    hour: '%Y-%m',
		                    day: '%Y-%m',
		                    week: '%Y-%m',
		                    month: '%Y-%m',
		                    year: '%Y-%m'
	                    },
                    },
                    yAxis: {
                        min: 0,
                        // softMax: 100,
                        opposite: false,
                        showLastLabel: true,
                        // tickPositions: [0, 25, 50, 75, 100],
                        plotLines: [{
                            color: '#FF0000',
                            width: 1,
                            value: this.baselineData.avg_hauls_per_month,
                            label: {
								text: 'Baseline Average Hauls Per Month',
	                            align:'right',
                                style: {
									color: '#00000',
	                                fontSize: '15px',
	                                fontWeight: 'semibold',
                                    opacity: 0.5,
                                },
	                            x: 0,
                            },
                            zIndex: 5
                        }]
                    },
                    tooltip: {
                        shared: true
                    },
                    series: [
	                    {
		                    name: 'Tonnage',
		                    data: this.summaries.map(summary => {
			                    let [year, month] = summary.month.split('-');
			                    let dateObject = new Date(+year, +month - 1);
			                    let utcTimestamp = Date.UTC(dateObject.getUTCFullYear(), dateObject.getUTCMonth());
			                    let dataPoint = {
				                    x: utcTimestamp,
				                    y: Number(summary.avg_tons_per_haul)
			                    };

			                    // When summary is considered as estimated, assign the color blue to the data point
			                    if (summary.rate_calculation === 'Estimated') {
				                    dataPoint.color = '#075076';
			                    } else if (summary.rate_calculation === 'Actual') {
				                    dataPoint.color = '#fc521d';
			                    }

			                    return dataPoint;
		                    }).sort((a, b) => a.x - b.x),
		                    crisp: true,
		                    showInLegend:true,
		                    color: '#fc521d',
	                    },
                    ],
	                rangeSelector: {
		                labelStyle: {
			                display: 'none'
		                },
		                inputEnabled: false,
		                selected: 'all',
		                allButtonsEnabled: true,
		                buttons: [
			                {
				                type: 'month',
				                count: 3,
				                text: '3m'
			                },
			                {
				                type: 'month',
				                count: 6,
				                text: '6m'
			                },
			                {
				                type: 'month',
				                count: 9,
				                text: '9m'
			                },
			                {
				                type: 'year',
				                count: 1,
				                text: '1y'
			                },
			                {
				                type: 'all',
				                text: 'All'
			                }
		                ],
	                },
                    navigator: {
                        enabled: false
                    },
                    scrollbar: {
                        enabled: false
                    },
                    time: {
                        useUTC: false
                    },
                });
            }
        },

        watch: {
            loading: function(value) {
                if (this.chart) {
                    value ? this.chart.showLoading('Fetching Fullness...') : this.chart.hideLoading();
                }

                if (!value) {
                    this.drawChart();
                }
            }
        },
    }
</script>