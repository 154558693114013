<template>
    <div :class="$attrs.class">
        <label v-if="label" class="form-label mb-2.5" :for="id">
            <span v-if="markAsRequired" class="text-red-500">*</span> {{ label }}
        </label>

        <p v-if="helpText" class="mt-2 text-sm text-gray-600">{{ helpText }}</p>

        <!-- On: "bg-blue-500", Off: "bg-gray-200" -->
        <button type="button" @click="toggle" v-bind="$attrs" :disabled="disabled" aria-pressed="true" aria-labelledby="privacy-option-label-1" aria-describedby="privacy-option-description-1" :class="backgroundClass" class="toggle-switch">
            <span class="sr-only">Use setting</span>
            <!-- On: "translate-x-5", Off: "translate-x-0" -->
            <span aria-hidden="true" :class="indicatorClass" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
        </button>

        <div v-if="errors" class="form-error">{{ errors }}</div>
    </div>
</template>

<script>
    import { v4 as uuid } from 'uuid';

    export default {
        inheritAttrs: false,

        props: {
            id: {
                type: String,
                default() {
                    return `toggle-switch-input-${uuid()}`;
                },
            },

            modelValue: Boolean,

            label: String,

            helpText: {
                type: String,
                default: ''
            },

            errors: {
                type: String,
                default: '',
            },

            markAsRequired: {
                type: Boolean,
                default: false
            },

            disabled: {
                type: Boolean,
                default: false
            }
        },

        methods: {
            toggle() {
                this.$emit('update:modelValue', !this.modelValue)
            }
        },

        computed: {
            backgroundClass() {
                return this.modelValue ? 'bg-d-orange-500' : 'bg-gray-200';
            },

            indicatorClass() {
                return this.modelValue ? 'translate-x-5' : 'translate-x-0';
            }
        }
    };
</script>