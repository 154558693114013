<style></style>

<template>
    <div class="p-2 overflow-x-auto ">
        <table class="table table-striped">
            <thead>
            <tr class="mb-2">
                <th class="w-1/4 font-italic text-lg">Cost over time</th>
                <th class="font-semibold text-right">3m</th>
                <th class="font-semibold text-right">6m</th>
                <th class="font-semibold text-right">1y</th>
                <th class="font-semibold text-right">3y</th>
                <th class="font-semibold text-right">5y</th>
                <th class="font-semibold text-right">All</th>
            </tr>
            </thead>

            <tbody>
                <tr class="">
                    <td class="text-sm font-semibold">Per cubic yard</td>
                    <td class="text-right">$5.44</td>
                    <td class="text-right"> $5.32 </td>
                    <td class="text-right"> $2.22 </td>
                    <td class="text-right">$3.21</td>
                    <td class="text-right">$2.12</td>
                    <td class="text-right">$2.10</td>
                </tr>
                <tr class="">
                    <td class="text-sm font-semibold">Per pound</td>
                    <td class="text-right">$15.44</td>
                    <td class="text-right"> $15.32 </td>
                    <td class="text-right"> $12.22 </td>
                    <td class="text-right">$13.21</td>
                    <td class="text-right">$12.12</td>
                    <td class="text-right">$12.10</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {

    },
}
</script>