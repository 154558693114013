<template>
    <Head :title="`Edit Vendor Contract Fee - ${ vendorContractFee.name }`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendor-contracts.index')" class="breadcrumb-link">Vendor Contracts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendor-contracts.show', [vendorContractFee.vendor_contract_id])" class="breadcrumb-link">{{ vendorContract.display_id }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit Vendor Contract Fee</span>
        </nav>
    </Teleport>

    <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <inertia-link :href="$route('vendor-contracts.vendor-contract-fees.create', [vendorContractFee.vendor_contract_id])" class="btn btn-gray">New</inertia-link>
        <inertia-link @click.prevent="remove" class="btn btn-gray">Remove</inertia-link>
    </div>

    <form id="edit-vendor-contract-fee-form" class="space-y-8 divide-y divide-gray-200" @submit.prevent="submitForm">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Fee Information</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">This is the configuration information for this vendor contract fee.</p>
                </div>

                <div class="space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <span class="label">Vendor Fee</span>
                        <span>{{ vendorContractFee.vendorFee.name }}</span>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="restriction" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            <span class="text-red-500">*</span> Restriction
                        </label>

                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <select-input v-model="form.restriction" v-if="form.name" :errors="errors.restriction">
                                <option :value="null">-</option>
                                <option v-for="restriction in restrictionsForSelectedFee" :key="restriction" :value="restriction">
                                    {{ restriction }}
                                </option>
                            </select-input>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            <span class="text-red-500">*</span> Name
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <text-input v-model="form.name" :errors="errors.name" type="text" name="name" id="name" autocomplete="fee-name" class="max-w-lg w-full" />
                        </div>
                    </div>

                    <div v-if="form.restriction === '$ per occurrence' || form.restriction === '$ per unit'" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="amount" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            <span class="text-red-500">*</span> Amount
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <money-input  v-model="form.amount" :defaultCurrency="vendorContractFee.vendorContract.vendor.currency" :errors="errors.amount" name="amount" id="amount" autocomplete="family-name" class="max-w-lg w-full" />
                        </div>
                    </div>

                    <div v-if="form.restriction === '% of service charges'" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="percentage" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            <span class="text-red-500">*</span> Percentage
                        </label>
                        <text-input type="number" min="0" max="100" step="0.001" placeholder="0.25" v-model="form.percentage" :errors="errors.percentage" />
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-5">
            <div class="flex justify-end">
                <inertia-link :href="$route('vendor-contracts.show', [vendorContractFee.vendor_contract_id])" class="btn btn-gray mr-3">Cancel</inertia-link>
                <loading-button type="submit" :loading="state === 'saving'" class="btn btn-orange">Save</loading-button>
            </div>
        </div>
    </form>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';

    export default {
        components: {
            Head,
            Icon,
            TextInput,
            SelectInput,
            MoneyInput,
            LoadingButton,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },
            vendorContract: Object,
            vendorContractFee: Object,
            vendorFeeLabels: Object,
        },

        data() {
            return {
                state: 'passive',
                mounted: false,
                form: {
                    ...this.vendorContractFee,
                }
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm() {
                this.state = 'saving'

                if (this.form.restriction !== '$ per occurrence' && this.form.restriction !== '$ per unit') {
                    this.form.amount = null;
                }

                if (this.form.restriction !== '% of service charges') {
                    this.form.percentage = null;
                }

                this.$inertia.put(this.$route('vendor-contract-fees.update', [this.vendorContractFee.id]), this.form, {
                    onFinish: () => {
                        this.state = 'passive';
                    }
                });
            },

            remove() {
                let confirmed = confirm('Are you sure you wish to perform this action?');

                if (confirmed) {
                    this.$inertia.delete(this.$route('vendor-contract-fees.destroy', [this.vendorContractFee.id]));
                }
            },
        },

        computed: {
            restrictionsForSelectedFee() {
                let selectedVendorFee = this.vendorContractFee.vendorFee;

                return [
                    'Uncapped',
                    'Not Allowed',
                    selectedVendorFee.allow_per_unit ? '$ per unit' : false,
                    selectedVendorFee.allow_per_occurrence ? '$ per occurrence' : false,
                    selectedVendorFee.allow_percentage_amount ? '% of service charges' : false
                ].filter(val => val !== false);
            }
        },
    }
</script>