<style>

</style>

<template>
    <tr :class="{'bg-gray-100': modified}">
        <td>{{ allocation.service_description ? allocation.service_description : allocation.description }}</td>

        <td v-if="isWritable">
            <inline-text-input v-model="allocation.quantity" @update:modelValue="chargeQuantityWasModified" :sanitizers="[this.digitsOnly]" />
        </td>
        <td v-else>{{ allocation.quantity }}</td>

        <td v-if="isWritable">
            <inline-money-input v-model="allocation.amount" :defaultCurrency="$page.props.currency" @update:modelValue="chargeAmountWasModified" :filters="['format_money']" />
        </td>
        <td v-else>{{ $filters.format_money(allocation.amount) }}</td>

        <td>{{ $filters.format_money(allocation.cost_basis) }}</td>

        <td>{{ $filters.format_percentage(proportionOfCostBasis * 100) }}</td>

        <td v-for="(allocatedValue, index) in allocation.allocatedValues" :key="index">
            <div v-if="allocatedValue.type == 'unit'">
                <unit-based-allocated-value
                    :model="allocatedValue"
                    :is-writable="isWritable"
                    :charge-quantity="allocation.quantity"
                    :data-vendor_fee_id="allocatedValue.line_item_fee_id"
                    @allocatable-fee-was-modified="updateAllocatableFeeValue" />
                
            </div>

            <div v-if="allocatedValue.type == 'percentage'">
                <percentage-based-allocated-value
                    :model="allocatedValue"
                    :is-writable="isWritable"
                    :charge-cost-basis="allocation.cost_basis"
                    @allocatable-fee-was-modified="updateAllocatableFeeValue" />
            </div>

            <div v-if="allocatedValue.type == 'fixed'">
                <fixed-value
                    :model="allocatedValue"
                    :is-writable="isWritable"
                    @allocatable-fee-was-modified="updateAllocatableFeeValue" />
            </div>
        </td>

        <td v-if="hasSalesTaxes">{{ $filters.format_money(allocation.sales_tax_total) }}</td>

        <td>{{ $filters.format_money(allocation.all_in_cost) }}</td>
    </tr>
</template>

<script>
    import InlineMoneyInput from '@/Shared/InlineMoneyInput.vue';
    import InlineTextInput from '@/Shared/InlineTextInput.vue';
    import UnitBasedAllocatedValue from './UnitBasedAllocatedValue.vue';
    import PercentageBasedAllocatedValue from './PercentageBasedAllocatedValue.vue';
    import FixedValue from './FixedValue.vue';
    import {EventBus} from '@/Shared/EventBus.js';
    import {subtract_money} from "@/Shared/Utils/Money.js";

    export default {
        emits: ['allocatable-fee-was-modified', 'charge-quantity-was-modified', 'charge-amount-was-modified'],

        components: {
            InlineMoneyInput,
            InlineTextInput,
            UnitBasedAllocatedValue,
            PercentageBasedAllocatedValue,
            FixedValue
        },
        
        props: {
            model: {
                type: Object,
                required: true
            },

            isWritable: {
                type: Boolean,
                default: false
            },

            hasSalesTaxes: {
                type: Boolean,
                default: false
            },

            totalCostBasis: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                modified: false,
                allocation: {...this.model},
            };
        },

        created() {
            EventBus.on('userRevisionsWereApplied', updatedState => {
                this.modified = false;
            });
        },

        methods: {
            /**
             * Perform an update for the value of one of the allocatable fee values on this line item.
             *
             * 1. Fetch the allocated value for the given fee.
             * 2. Calculate the difference between the current allocted value and the updated value.
             * 3. Emit an event containing the allocatable fee and the updated value.
             */
            updateAllocatableFeeValue(feeId, newValue) {
                let allocatedValue = this.model
                    .allocatedValues
                    .find(allocatedValue => allocatedValue.line_item_fee_id === feeId);
               
                let difference = subtract_money(allocatedValue.amount, newValue);
                
                this.modified = true;

                this.$emit('allocatable-fee-was-modified', allocatedValue, difference, newValue);
            },

            digitsOnly(value) {
                let digitsOnly = value.replace(/[a-zA-Z]/g, '');

                return digitsOnly.length > 0 ? parseFloat(digitsOnly) : 0.00;
            },

            /**
             * Emit an event containing the charge line item and the updated value for its amount.
             */
             chargeAmountWasModified(newValue, oldValue) {
                this.modified = true;
                this.$emit('charge-amount-was-modified', this.model.id, this.allocation.amount);
            },

            /**
             * Emit an event containing the charge line item and the updated value for its quantity.
             */
            chargeQuantityWasModified(newValue, oldValue) {
                this.modified = true;
                this.$emit('charge-quantity-was-modified', this.model.id, this.allocation.quantity);
            },
        },

        computed: {
            proportionOfCostBasis() {
                return (this.totalCostBasis && this.totalCostBasis.amount != 0) ? this.model.cost_basis.amount / this.totalCostBasis.amount : 0;
            }
        },

        watch: {
            'model': function(newValue, oldValue) {
                this.allocation = {...newValue};
            },
        }
    }
</script>
