<template>
    <Head title="Line Item Categories" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Line Item Categories</span>
        </nav>
    </Teleport>

    <main class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="line-item-categories" />

                <div class="lg:col-span-9 px-4 sm:px-6">

	                <horizontal-sub-nav current-tab="categories" />

                    <div class="flex flex-row items-center mb-6">
                        <div class="w-1/2">
                            <div>
                                <div class="input-group mt-0">
                                    <span class="input-group-item-left">
                                        <icon name="search" class="size-4 text-gray-400 fill-current" />
                                    </span>

                                    <input type="text" v-model="form.search" placeholder="Search" class="input-group-field">
                                </div>
                            </div>
                        </div>

                        <div class="ml-auto">
                            <inertia-link :href="$route('tenant-settings.line-item-categories.create')" class="btn btn-orange">
                                New
                            </inertia-link>
                        </div>
                    </div>

                    <div v-if="lineItemCategories.data.length">
                        <div class="my-6">
                            (Showing <span class="font-semibold text-gray-900">{{ lineItemCategories.data.length }}</span> out of <span class="font-semibold text-gray-900">{{ lineItemCategories.total }}</span>)
                        </div>

                        <div class="overflow-x-auto">
                            <table class="table table-condensed">
                                <thead>
                                    <tr>
                                        <sortable-table-header field="name" class="font-semibold" :filters="filters" route-name="tenant-settings.line-item-categories.index">Name</sortable-table-header>
                                        <sortable-table-header field="display_name" class="font-semibold" :filters="filters" route-name="tenant-settings.line-item-categories.index">Display Name</sortable-table-header>
                                        <sortable-table-header field="cost_gl_code" class="font-semibold" :filters="filters" route-name="tenant-settings.line-item-categories.index">Cost GL Account</sortable-table-header>
                                        <sortable-table-header field="revenue_gl_code" class="font-semibold" :filters="filters" route-name="tenant-settings.line-item-categories.index">Revenue GL Account</sortable-table-header>
                                        <th class="font-semibold"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="lineItemCategory in lineItemCategories.data" :key="lineItemCategory.id">
                                        <td>{{ lineItemCategory.name }}</td>
                                        <td>{{ lineItemCategory.display_name }}</td>
                                        <td>{{ lineItemCategory.cost_gl_code }}</td>
                                        <td>{{ lineItemCategory.revenue_gl_code }}</td>
                                        <td>
                                            <span class="inline-block">
                                                <inertia-link :href="$route('tenant-settings.line-item-categories.edit', [lineItemCategory.id])" class="link" title="Edit Cost Classification">
                                                    Edit
                                                </inertia-link>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <pagination :links="lineItemCategories.links" />
                    </div>

                    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                        <icon name="tags" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Categories Found</span>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import SubNav from '../SubNav.vue';
    import { throttle, pickBy } from 'lodash-es';
    import HorizontalSubNav from "../../TenantSettings/LineItemCategories/HorizontalSubNav.vue";

    export default {
        components: {
	        HorizontalSubNav,
            Head,
            Icon,
            Pagination,
            SortableTableHeader,
            SubNav
        },

        props: {
            lineItemCategories: {
                type: Object,
                required: true
            },

            filters: Object
        },

        data() {
            return {
                form: {
                    search: this.filters.search
                },
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            changeNavigation(e) {
                this.$inertia.get(e.target.value);
            }
        },

        watch: {
            form: {
                handler: throttle(function() {
                    let query = pickBy(this.form);
                    query = Object.keys(query).length ? query : {remember: 'forget'};

                    this.$inertia.get(this.$route('tenant-settings.line-item-categories.index'), query, {preserveState: true});
                }, 150),

                deep: true,
            },
        }
    }
</script>
