<template>
    <Head :title="`Edit Work Order - ${workOrder.display_id}`" />

    <Teleport to="[data-slot='breadcrumbs']"  v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('work-orders.index')" class="breadcrumb-link">Work Orders</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('work-orders.show', [workOrder.id])" class="breadcrumb-link">{{ workOrder.display_id }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

     <div class="flex flex-wrap justify-between items-center my-4">
        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <button v-if="!workOrder.is_complete" class="btn btn-gray" :disabled="isClosed" @click.prevent="cancelWorkOrder">
                <icon name="ban" class="inline w-4 h-4 mr-2" />
                Cancel Work Order
            </button>

            <button v-if="workOrder.decorated_status === 'New'" class="btn btn-gray" :disabled="!requestedCompletionDateIsInFuture" @click.prevent="showRequestSchedulingModal">
                Request Scheduling
            </button>

            <button v-if="!workOrder.is_complete && workOrder.decorated_status !== 'New'" href="" class="btn btn-gray" @click.prevent="showCompleteWorkOrderModal">
                <icon name="check" class="inline w-4 h-4 mr-2" />
                Complete Work Order
            </button>

            <button v-if="isClosed" href="" @click.prevent="reopenWorkOrder" class="btn btn-gray">
                <icon name="repeat-alt" class="inline w-4 h-4 mr-2" />
                Re-open
            </button>
        </div>

        <div v-if="otherUsers.length > 0" class="flex -space-x-2 overflow-hidden mb-1">
            <template v-for="user in otherUsers" :key="user.id">
                <img v-if="user.avatar_thumbnail_url" :src="user.avatar_thumbnail_url" class="inline-block h-10 w-10 rounded-full ring-2 ring-white" :alt="user.name">
                <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                    <span class="text-lg font-medium leading-none text-white uppercase">{{ user.first_name[0] }}{{ user.last_name[0] }}</span>
                </span>
            </template>
        </div>
     </div>

    <form id="edit-work-order-form" class="space-y-8" @submit.prevent="submitForm">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Work Order Service Details</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Service Details for <inertia-link :href="$route('services.show', [workOrder.service_id])" class="link">
                        {{ workOrder.service_id }}</inertia-link>
                    </p>
                </div>
            </legend>

            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Status</dt>
                        <dd class="mt-1 text-sm" :class="[workOrder.service.status === 'Active' ? 'text-green-600' : 'text-gray-500']">{{ workOrder.service.status }}</dd>
                    </div>

                    <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Vendor</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                            <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                                <span class="text-lg font-medium leading-none text-white uppercase"></span>
                            </span>
                            <inertia-link class="link" :href="$route('vendors.show', [workOrder.vendor_id])">{{ workOrder.vendor.name ?? '' }}</inertia-link>
                        </dd>
                    </div>

                    <div class="sm:col-span-1 md:col-span-2">
                        <dt class="text-sm font-medium text-gray-500">Description</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                            <p>{{ workOrder.service.description }}</p>
                        </dd>
                    </div>

	                <div class="sm:col-span-1">
		                <dt class="text-sm font-medium text-gray-500">Equipment Label</dt>
		                <dd class="mt-1 text-sm text-gray-900">
			                <p>{{ workOrder.service.label }}</p>
                        </dd>
	                </div>

                    <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Vendor Account</dt>
                        <dd v-if="workOrder.service.vendorAccount?.account_number" class="mt-1 text-sm text-gray-900">
                            <inertia-link class="link" :href="$route('vendor-accounts.show', [workOrder.service.vendor_account_id])">{{ workOrder.service.vendorAccount.account_number }}</inertia-link>
                        </dd>
                        <dd v-else class="mt-1 text-sm text-gray-900">
                            Not Set
                        </dd>
                    </div>

                    <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Vendor Contact Info</dt>
                        <dd v-if="workOrder.vendor.general_email_address || workOrder.vendor.general_phone_number" class="mt-1 text-sm text-gray-900">
                            <div>{{ workOrder.vendor.general_email_address }}</div>
                            <div>{{ workOrder.vendor.general_phone_number }}</div>
                        </dd>
                        <dd v-else>No Available</dd>
                    </div>

                    <div class="sm:col-span-1 md:col-span-2">
                        <dt class="text-sm font-medium text-gray-500">Location</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                            <p>
                                <inertia-link :href="$route('locations.show', [workOrder.service.location_id])" class="link">{{ workOrder.service.location.name }}</inertia-link>
                                <br />{{ workOrder.service.location.address.full_address }}
                            </p>
                        </dd>
                    </div>
                </dl>
            </div>
        </fieldset>

        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Scheduling Info</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Details regarding how this Work Order is scheduled.</p>
                </div>
            </legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <div class="col-span-1" :class="{'text-red-700' : workOrder.is_over_due}">
                    <span class="form-label">
                        Status
                    </span>
                    {{ workOrder.decorated_status }}
                </div>

                <div class="col-span-1" :class="{'text-red-700' : workOrder.is_over_due}">
                    <span class="form-label">
                        Requested Completion Date
                    </span>
                    {{ $filters.format_date(workOrder.requested_completion_date) }} (<span>{{ $filters.diff_for_humans(workOrder.requested_completion_date) }}</span>)
                </div>

                <date-input
                    v-model="form.scheduled_with_vendor_date"
                    class="col-span-1"
                    label="Scheduled with Vendor for"
                    name="scheduled_with_vendor_date"
                    :disabled="isClosed"
                    :errors="errors.scheduled_with_vendor_date"
                />

                <date-time-local-input
                    v-model="form.occurred_at"
                    class="col-span-1"
                    label="Occurred At"
                    name="occurred_at"
                    disabled
                    :errors="errors.occurred_at"
                />

                <select-input v-model="form.source" name="source" label="Source" :disabled="isClosed" mark-as-required>
                    <option :value="null">Please Select</option>
                    <option v-for="source in sources" :key="source" :value="source">{{ source }}</option>
                </select-input>

                <text-input
                    v-model="form.hauler_reference_number"
                    class="col-span-1"
                    label="Hauler Reference Number"
                    name="hauler_reference_number"
                    :disabled="isClosed"
                    :errors="errors.hauler_reference_number"
                />

                <text-input
                    class="col-span-1"
                    v-model="form.external_reference_id"
                    label="External Reference Id"
                    name="external_reference_id"
                    :errors="errors.external_reference_id"
                />

                <select-input v-model="form.requested_action" class="col-span-1" label="Requested Action" name="requested_action" :disabled="isClosed" :errors="errors.requested_action">
                    <option v-for="(requestedAction, index) in sortedRequestedActions" :key="index" :value="requestedAction">{{ requestedAction }}</option>
                </select-input>

                <text-input
                    v-model="form.confirmed_quantity"
                    class="col-span-1"
                    name="confirmed_quantity"
                    label="Confirmed Quantity"
                    type="number"
                    step="0.01"
                    :disabled="isClosed"
                    :errors="errors.confirmed_quantity"
                />
            </div>
        </fieldset>

        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Contact Info.</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Who's opening this work order.</p>
                </div>
            </legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <select-input v-model="form.requested_by_id" name="requested_by_id" label="Requested By" :disabled="isClosed" :errors="errors.requested_by_id">
                    <option :value="null">Unknown</option>
                    <option v-for="contact in sortedContacts" :key="contact.id" :value="contact.id">{{ contact.name }}</option>
                </select-input>

                <text-input v-model="form.on_site_contact_number" label="On-Site Contact Number" :disabled="isClosed" :errors="errors.on_site_contact_number" />

                <text-input v-model="form.on_site_contact_name" label="On-Site Contact Name" :disabled="isClosed" :errors="errors.on_site_contact_name" />
            </div>
        </fieldset>

        <div class="pt-8">
            <loading-button type="submit" :loading="state === 'saving'" class="btn btn-orange">
                Save Changes
            </loading-button>
        </div>
    </form>

    <modal class="max-w-2xl" ref="completeWorkOrderModal" role="dialog" @closed="emptyCompleteWorkOrderModal">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl flex items-center">
                    <icon name="check" class="inline fill-current h-6 w-6 mr-2" />
                    Complete Work Order
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon aria-hidden="true" name="times" class="w-6 h-6 text-gray-500" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="p-4">
                <form action="" class="w-full" method="post">
                    <div class="flex -mx-4">
                        <div class="px-4 w-full lg:w-1/2">
                            <select-input name="confirmed_by" id="confirmed_by" v-model="completeWorkOrderModalForm.confirmed_by" :errors="errors.confirmed_by">
                                <option value="vendor">Vendor</option>
                                <option value="client">Client</option>
                            </select-input>
                        </div>

                        <div class="px-4 w-full lg:w-1/2">
                            <date-input name="occurred_at" v-model="completeWorkOrderModalForm.occurred_at" placeholder="Occurred At" :errors="errors.occurred_at" />
                        </div>
                    </div>
                </form>
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4 flex">
                <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                <loading-button :loading="state === 'saving'" class="btn btn-orange" @click="completeWorkOrder">Confirm</loading-button>
            </div>
        </template>
    </modal>

    <modal class="max-w-2xl" ref="requestSchedulingModal" role="dialog">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl flex items-center">
                    <icon name="envelope" class="inline fill-current h-6 w-6 mr-2" />
                    Request Scheduling
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon aria-hidden="true" name="times" class="w-6 h-6 text-gray-500" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="px-4 py-2">
                <form action="" class="w-full" method="post">
                    <div class="flex -mx-4">
                        <div class="px-4 w-full">
                            <text-input v-model="requestSchedulingModalForm.recipients" label="Recipients" help-text="Multiple emails can be sent by separating email addresses with semicolons ;" :errors="errors.recipients" mark-as-required />

                            <text-input v-model="requestSchedulingModalForm.subject" label="Subject" class="my-4" :errors="errors.subject" mark-as-required />

                            <div>
                                <quill-editor id="modal-body" v-model="requestSchedulingModalForm.body" label="Body" :errors="errors.body" mark-as-required aria-required="true" class="mt-1" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="p-2 float-right">
                <loading-button v-if="workOrder.decorated_status === 'New'" :loading="state === 'sending-scheduling-request'" class="btn btn-orange mx-2" @click="sendSchedulingRequest">Send Request</loading-button>
            </div>
        </template>
    </modal>

    <modal class="max-w-2xl" ref="eventHistoryModal" role="dialog">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl flex items-center">
                    External Event History
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon aria-hidden="true" name="times" class="w-6 h-6 text-gray-500" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="p-4">
                <form action="" class="w-full" method="post">
                    <div class="flex -mx-4">
                        <div class="px-4 w-full">
                            <div v-for="event in workOrder.external_event_history">
                                <template v-if="event.event === 'Email Sent'">
                                    Sent to {{ event.email }} at {{ $filters.format_date_time(event.when) }}
                                </template>

                                <template v-else-if="['Viewed', 'Scheduled'].includes(event.event)">
                                    {{ event.email }} {{ event.event.toLowerCase() }} at {{ $filters.format_date_time(event.when) }}
                                </template>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </template>

        <template #modal-footer="{close}"></template>
    </modal>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import CopyToClipboardButton from '@/Shared/CopyToClipboardButton.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import DateTimeLocalInput from '@/Shared/DateTimeLocalInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Modal from '@/Shared/Modal.vue';
    import QuillEditor from '@/Shared/QuillEditor.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import { endOfDay, parse } from 'date-fns';

    export default {
        components: {
            Head,
            CopyToClipboardButton,
            DateInput,
            DateTimeLocalInput,
            Icon,
            LoadingButton,
            Modal,
            QuillEditor,
            SelectInput,
            TextInput
        },

        props: {
            errors: {
                type: Object,
                default: () => ({}),
            },

            workOrder: {
                type: Object,
                required: true
            },

            clientCompany: {
                type: Object,
                required: true
            },
            
            sources: {
                type: Array,
                required: true
            },

            requestedActions: {
                type: Array,
                required: true
            },

            deviceService: {
                type: Object,
                default: () => ({})
            },

            compactorNotificationEmails: {
                type: String,
                default: ''
            },

            schedulingRequestSubject: {
                type: String,
                required: true
            },

            schedulingRequestBody: {
                type: String,
                required: true
            },
        },

        data() {
            return {
                form: {
                    ...this.workOrder,
                },
                state: 'passive',
                completeWorkOrderModalForm: {
                    confirmed_by: null,
                    occurred_at: null
                },
                requestSchedulingModalForm: {
                    recipients: this.concatenateEmails(),
                    subject: this.schedulingRequestSubject,
                    body: this.schedulingRequestBody
                },
                otherUsers: [],
                mounted: false,
            }
        },

        created() {
            Echo.join(`work_orders.${this.workOrder.id}`)
                .here((users) => {
                    this.otherUsers = users;
                })
                .joining((user) => {
                    this.otherUsers.push(user);
                })
                .leaving((user) => {
                    this.otherUsers = this.otherUsers.filter(otherUser => otherUser.id !== user.id);
                });
        },

        mounted() {
            this.mounted = true;
        },

        unmounted() {
            Echo.leave(`work_orders.${this.workOrder.id}`);
        },

        methods: {
            submitForm() {
                if (this.state !== 'passive') {
                    return;
                }

                this.state = 'saving';

                this.$inertia.put(this.$route('work-orders.update', this.workOrder.id), this.form, {
                    preserveScroll: true,
                    onFinish: () => this.state = 'passive'
                });
            },

            completeWorkOrder() {
                if (this.state !== 'passive') {
                    return;
                }

                this.state = 'completing';

                this.$inertia.put(this.$route('work-orders.complete', this.workOrder.id), {
                    confirmed_by: this.completeWorkOrderModalForm.confirmed_by,
                    occurred_at: this.completeWorkOrderModalForm.occurred_at
                }, {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.form = { ...this.workOrder };
                        this.$refs.completeWorkOrderModal.close();
                    },
                    onFinish: () => {
                        this.state = 'passive';
                    }
                });
            },

            reopenWorkOrder() {
                if (this.state !== 'passive') {
                    return;
                }

                this.state = 'opening';

                this.$inertia.post(this.$route('work-orders.re-open', this.workOrder.id), {}, {
                    preserveScroll: true,
                    onSuccess: () => this.form = { ...this.workOrder },
                    onFinish: () => this.state = 'passive'
                });
            },

            cancelWorkOrder() {
                if (this.state !== 'passive') {
                    return;
                }

                this.state = 'cancelling';

                this.$inertia.delete(this.$route('work-orders.cancel-work-order', this.workOrder.id), {}, {
                    preserveScroll: true,
                    onSuccess: () => this.form = { ...this.workOrder },
                    onFinish: () => this.state = 'passive'
                });
            },

            showCompleteWorkOrderModal() {
                if (JSON.stringify(this.form) !== JSON.stringify(this.workOrder)) {
                    if (confirm("The work order has unsaved changes. Would you like to save them?")) {
                        this.submitForm();
                    }
                }

                if (this.workOrder.acknowledged_by_id) {
                    return;
                }

                this.completeWorkOrderModalForm = {
                    confirmed_by: null,
                    occurred_at: null
                };

                this.$refs.completeWorkOrderModal.show();
            },

            emptyCompleteWorkOrderModal() {
                this.completeWorkOrderModalForm = {};
            },

            showRequestSchedulingModal() {
                if (!this.requestedCompletionDateIsInFuture) {
                    return;
                }

                this.$refs.requestSchedulingModal.show();
            },

            markAsRequested() {
                this.state = 'marking-as-requested';

                this.$inertia.put(this.$route('work-orders.mark-as-requested', this.workOrder.id), {}, {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.form = { ...this.workOrder };
                    },
                    onFinish: () => this.state = 'passive'
                });
            },

            sendSchedulingRequest() {
                if (this.state !== 'passive') {
                    return;
                }

                this.state = 'sending-scheduling-request';

                this.$inertia.post(this.$route('work-orders.external-events.send-scheduling-request', this.workOrder.id), this.requestSchedulingModalForm, {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.form = { ...this.workOrder };
                        this.$refs.requestSchedulingModal.close();
                    },
                    onFinish: () => this.state = 'passive'
                });
            },

            concatenateEmails() {
                let emails = '';

                emails = emails + (this.workOrder.vendor.work_order_request_email_address ?? '');

                if (this.compactorNotificationEmails.length) {
                    if (emails) {
                        emails = emails + ';';
                    }

                    emails = `${emails}${this.compactorNotificationEmails}`;
                }

                return emails;
            },

            showEventHistoryModal() {
                this.$refs.eventHistoryModal.show();
            }
        },

        computed: {
            isClosed: function () {
                return this.workOrder.decorated_status === 'Cancelled by Client'
                    || this.workOrder.decorated_status === 'Cancelled by Vendor'
                    || this.workOrder.decorated_status === 'Cancelled (duplicate work order)'
                    || this.workOrder.is_complete;
            },

            mapOptions: function () {
                return {
                    mapTypeId: 'satellite',
                    zoom: 18,
                    maxZoom: 20,
                    minZoom: 15,
                    tilt: 0,
                    streetViewControl: false
                }
            },

            requestedCompletionDateIsInFuture: function () {
                return endOfDay(parse(this.workOrder.requested_completion_date, 'yyyy-MM-dd', new Date)) >= new Date
            },

            sortedContacts: function () {
                return this.clientCompany.contacts?.sort((a,b) => a.name.localeCompare(b.name));
            },

            sortedRequestedActions: function () {
                return this.requestedActions.toSorted();
            }
        }
    }
</script>
