<template>
    <Head :title="`Edit Incident - ${incident.display_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('incidents.index')" class="breadcrumb-link">Incidents</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ incident.display_id }}</span>
        </nav>
    </Teleport>

    <main class="flex-1">
        <div class="py-8 xl:py-10">
            <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 xl:max-w-5xl xl:grid xl:grid-cols-3">
                <div class="xl:col-span-2 xl:pr-8 xl:border-r xl:border-gray-200">
                    <div>
                        <div>
                            <div class="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                                <div>
                                    <h1 class="text-2xl font-bold text-gray-900">{{ incident.reason.name }}</h1>
                                    <p class="mt-2 text-sm text-gray-500">
                                        #{{ incident.display_id }} opened by
                                        <a href="#" class="font-medium text-gray-900">{{ incident.filed_by.first_name }} {{ incident.filed_by.last_name }}</a>
                                        in
                                        <a href="#" class="font-medium text-gray-900">Customer Portal</a>
                                    </p>
                                </div>

                                <div class="mt-4 flex space-x-3 md:mt-0">
                                    <inertia-link :href="$route('incidents.edit', [incident.id])" class="btn btn-gray">
                                        Edit
                                    </inertia-link>

                                    <inertia-link :href="$route('incidents.create')" class="btn btn-orange">
                                        New
                                    </inertia-link>
                                </div>
                            </div>

                            <aside class="mt-8 xl:hidden">
                                <h2 class="sr-only">Details</h2>

                                <div class="space-y-5">
                                    <div v-if="activeStatuses.includes(incident.status)" class="flex items-center space-x-2">
                                        <icon name="lock-open" class="h-5 w-5 text-green-500 fill-current" />
                                        <span class="text-green-700 text-sm font-medium">Open Issue</span>
                                    </div>

                                    <div v-else class="flex items-center space-x-2">
                                        <icon name="lock-closed" class="h-5 w-5 text-gray-500 fill-current" />
                                        <span class="text-gray-700 text-sm font-medium">Closed Issue</span>
                                    </div>

                                    <div class="flex items-center space-x-2">
                                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd" />
                                        </svg>
                                        <span v-if="incident.comments.length === 1" class="text-gray-900 text-sm font-medium">1 comment</span>
                                        <span v-else class="text-gray-900 text-sm font-medium">{{ incident.comments.length }} comments</span>
                                    </div>

                                    <div class="flex items-center space-x-2">
                                        <icon name="building" class="fill-current h-5 w-5 text-gray-400" />
                                        <inertia-link :href="$route('locations.show', [incident.location_id])" class="link font-medium">
                                            {{ incident.location.name }}
                                        </inertia-link>
                                    </div>

                                    <div class="flex items-center space-x-2">
                                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                                        </svg>
                                        <span class="text-gray-900 text-sm font-medium">
                                            Created on <time :datetime="incident.created_at">{{ $filters.format_date_time(incident.created_at) }}</time>
                                        </span>
                                    </div>
                                </div>

                                <div v-if="incident.assigned_to_id" class="mt-6 space-y-8 border-t border-b border-gray-200 py-6">
                                    <div>
                                        <h2 class="text-sm font-medium text-gray-500">Assigned To</h2>

                                        <ul role="list" class="mt-3 space-y-3">
                                            <li class="flex justify-start">
                                                <a href="#" class="flex items-center space-x-3">
                                                    <div class="flex-shrink-0">
                                                        <!-- <img class="h-5 w-5 rounded-full" src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80" alt=""> -->
                                                        <img v-if="incident.assignedTo?.avatar_thumbnail_url" :src="incident.assignedTo?.avatar_thumbnail_url" class="inline border border-gray-400 rounded-full mr-4 h-7 w-7" />

                                                        <span v-else class="inline-flex items-center justify-center h-7 w-7 rounded-full bg-gray-400 mr-2">
                                                            <span class="text-sm font-medium leading-none text-white uppercase">{{ incident.assignedTo?.first_name[0] }}{{ incident.assignedTo?.last_name[0] }}</span>
                                                        </span>
                                                    </div>
                                                    <div class="text-sm font-medium text-gray-900">{{ incident.assignedTo?.name ?? 'Unassigned' }}</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div v-if="incident.service_id">
                                        <h2 class="text-sm font-medium text-gray-500">Service</h2>

                                        <inertia-link :href="$route('services.show', [incident.service_id])" class="link font-medium">{{ incident.service_id }}</inertia-link>
                                        <p class="text-sm text-gray-500 font-medium mt-3">{{ incident.service.description }}</p>
                                    </div>
                                </div>
                            </aside>

                            <div class="py-3 xl:pt-6 xl:pb-0">
                                <h2 class="sr-only">Description</h2>
                                <div class="prose max-w-none">
                                    <p>{{ incident.description }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <aside class="hidden xl:block xl:pl-8">
                    <h2 class="sr-only">Details</h2>
                    <div class="space-y-5">
                        <div v-if="activeStatuses.includes(incident.status)" class="flex items-center space-x-2">
                            <icon name="lock-open" class="h-5 w-5 text-green-500 fill-current" />
                            <span class="text-green-700 text-sm font-medium">Open Issue</span>
                        </div>
                        <div v-else class="flex items-center space-x-2">
                            <icon name="lock" class="h-5 w-5 text-gray-400 fill-current" />
                            <span class="text-gray-700 text-sm font-medium">Closed Issue</span>
                        </div>

                        <div class="flex items-center space-x-2">
                            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd" />
                            </svg>
                            <span v-if="incident.comments.length !== 0 && incident.comments.length > 1" class="text-gray-900 text-sm font-medium">{{ incident.comments.length }} comments</span>
                            <span v-else class="text-gray-900 text-sm font-medium">{{ incident.comments.length }} comment</span>
                        </div>

                        <div class="flex items-center space-x-2">
                            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                            </svg>
                            <span class="text-gray-900 text-sm font-medium">
                                <!-- Created on <time datetime="2020-12-02">Dec 2, 2020</time> -->
                                Created on <time :datetime="incident.created_at">{{ $filters.format_date_time(incident.created_at) }}</time>
                            </span>
                        </div>
                    </div>
                </aside>

                <!-- Checklist Items !-->
                <div>
                    <h2 class="text-sm font-medium text-gray-500">Checklist Items</h2>
                    <div class="mt-6 flow-root">
                        <ul role="list" class="-my-5 divide-y divide-gray-200">
                            <li v-for="checklistItem in incident.checklistItems" :key="checklistItem.id" class="py-5">
                                <div class="relative focus-within:ring-2 focus-within:ring-indigo-500">
                                    <h3 v-if="checklistItem.completed_by_id" class="text-sm font-medium text-gray-800">
                                        <!-- Extend touch target to entire panel -->
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                        <icon name="check" class="text-green-500 fill-current w-4 h-4 mr-2 inline" />
                                        Marked Complete by {{ checklistItem.completedBy.name }}
                                    </h3>
                                    <p class="mt-1 text-sm text-gray-600 line-clamp-2">
                                        {{ checklistItem.text }}
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import InlineTextInput from '@/Shared/InlineTextInput.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            LoadingButton,
            InlineTextInput,
            Icon,
            Head,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({}),
            },
            
            incident: {
                type: Object,
                required: true
            },

            activeStatuses: {
                type: Array,
                required: true,
            },
        },

        data() {
           return {
               mounted: false,
           }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {},

        computed: {},
    }
</script>