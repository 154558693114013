<template>
    <Head title="Data Exports" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('user-settings.index')" class="breadcrumb-link">My Account</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Data Exports</span>
        </nav>
    </Teleport>

    <div class="py-6">
        <horizontal-sub-nav currentTab="data-exports" />

        <div class="divide-y divide-gray-200">
            <div class="px-4 py-5">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Data Exports
                </h3>

                <p class="mt-1 text-sm leading-5 text-gray-600">
                    These are your most recent data exports.
                </p>

                <div class="mt-4 overflow-x-auto">
                    <table class="table-condensed table-striped min-w-full">
                        <thead>
                            <tr>
                                <th class="font-semibold">Export Type</th>
                                <th class="font-semibold">Status</th>
                                <th class="font-semibold">File</th>
                                <th class="font-semibold">Queued At</th>
                                <th class="font-semibold">Expires At</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="dataExport in dataExports" :key="dataExport.id">
                                <td>{{ dataExport.export_type }}</td>
                                <td>{{ dataExport.status }}</td>
                                <td>{{ dataExport.file_path ? getFileName(dataExport.file_path) : 'N/A' }}</td>
                                <td>{{ $filters.format_date_time(dataExport.created_at) }}</td>
                                <td>{{ dataExport.expires_at ? dataExport.expires_at : 'N/A' }}</td>
                                <td>
                                    <a v-if="dataExport.file_path" :href="$route('user-settings.data-exports.download', { id: dataExport.id })" class="link text-lg" title="Download File">
                                        <icon name="download-arrow" class="inline fill-current text-gray-600 hover:text-gray-700 w-5 h-5" />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, inject, onMounted } from 'vue';
    import { router } from '@inertiajs/vue3';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import HorizontalSubNav from '../HorizontalSubNav.vue';

    const route = inject('route');

    const props = defineProps({
        dataExports: {
            type: Array,
            required: true
        },

        errors: {
            type: Object,
            default: {},
        }
    });

    const mounted = ref(false);

    onMounted(() => {
        mounted.value = true;
    });

    function changeNavigation(e) {
        router.get(e.target.value);
    }

    function getFileName(filePath) {
        if (!filePath) {
            return '';
        }

        const lastSlashIndex = filePath.lastIndexOf('/');
        return filePath.substring(lastSlashIndex + 1);
    }
</script>