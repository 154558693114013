<template>
    <div>
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 px-4">
            <div class="md:col-span-3">
                <date-input
                    v-model="actual_date"
                    label="Date of Change"
                    helpText="When should this change happen?"
                    :min="earliestServiceActivity.actual_date"
                    :errors="errors['actual_date']"
                    mark-as-required
                />
            </div>

            <div class="md:col-span-3" v-if="actual_date && actual_date !== today">
                <div class="rounded-md bg-yellow-50 p-4" v-if="actual_date && actual_date < today">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <icon name="info-circle" class="size-5 text-yellow-400 fill-current" />
                        </div>
                        <div class="ml-3 flex-1">
                            <p class="text-base text-yellow-700">
                                NOTE: This will insert a historical record that something was incorrect and will update various reporting data. This correction will also show up in the historical time line of the service which may help with auditing. This correction will retroactively alter the service, but will not alter any related data (such as old invoice line items).
                            </p>
                        </div>
                    </div>
                </div>
                <div class="rounded-md bg-blue-50 p-4" v-if="actual_date && actual_date > today">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <icon name="info-circle" class="size-5 text-blue-400 fill-current" />
                        </div>
                        <div class="ml-3 flex-1">
                            <p class="text-base text-blue-700">
                                NOTE: This will insert a scheduled change that will not affect the service until the chosen date. You may cancel this change at any time prior to the chosen date.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div :class="[actual_date && actual_date === today ? 'md:col-span-3' : 'col-span-1 md:col-span-3']">
                <select-input v-model="change_reason" label="Reason for Change" :errors="errors['change_reason']" markAsRequired>
                    <option>Savings</option>
                    <option>Other</option>
                </select-input>
            </div>

            <div :class="[actual_date && actual_date === today ? 'md:col-span-6' : 'col-span-1 md:col-span-3']">
                <select-input v-model="form.client_contract_id" label="Client Contract" :errors="errors['client_contract_id']" mark-as-required >
                    <option v-if="clientContracts.length === 0" :value="null">No contracts available for this Client</option>
                    <option v-else :value="null">Select a Contract</option>
                    <option v-for="clientContract in clientContracts" :key="clientContract.id" :value="clientContract.id">
                    {{ clientContract.id + " - Integration " + clientContract.integration_date ?? " - " }}
                </option>
                </select-input>
            </div>

            <div class="md:col-span-2">
                <select-input v-model="form.is_pass_through" label="Is Pass Through?" :errors="errors['is_pass_through']" mark-as-required >
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                </select-input>
            </div>

            <div class="md:col-span-2">
                <select-input
                    v-if="form.is_pass_through === false"
                    v-model="form.client_billing_frequency"
                    label="Client Billing Frequency"
                    :errors="errors['client_billing_frequency']"
                    mark-as-required
                >
                    <option v-for="clientBillingFrequency in clientBillingFrequencies" :key="clientBillingFrequency" :value="clientBillingFrequency">
                        {{ clientBillingFrequency }}
                    </option>
                </select-input>
            </div>

            <template v-if="form.is_pass_through === false && form.client_billing_frequency !== 'Not Billable'">
                <div class="md:col-span-2" v-if="serviceHasClientBaseCharge">
                    <money-input
                        v-model="form.client_base_charge"
                        label="Client Base Charge"
                        :defaultCurrency="service.location.clientCompany.currency"
                        :errors="errors['client_base_charge']"
                        mark-as-required
                    />
                </div>

                <div class="md:col-span-2" v-if="serviceHasClientPerUnitCharge">
                    <money-input
                        v-model="form.client_per_unit_charge"
                        label="Client Per Unit Charge"
                        :helpText="perUnitHelpText"
                        :defaultCurrency="service.location.clientCompany.currency"
                        :errors="errors['client_per_unit_charge']"
                        mark-as-required
                    />
                </div>

                <div class="md:col-span-2" v-if="serviceHasClientPerOccurrenceCharge">
                    <money-input
                        v-model="form.client_per_occurrence_charge"
                        label="Client Per Occurrence Charge"
                        :helpText="perOccurrenceHelpText"
                        :defaultCurrency="service.location.clientCompany.currency"
                        :errors="errors['client_per_occurrence_charge']"
                        mark-as-required
                    />
                </div>
            </template>
        </div>

        <template v-if="showPriorPeriodAdjustment">
            <div class="my-10 px-4">
                <div class="rounded-md bg-yellow-50 p-4">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <icon name="exclamation-triangle" class="size-5 text-yellow-400 fill-current" />
                        </div>
                        <div class="ml-3 flex-1 text-base">
                            <h3 class="font-medium text-yellow-800">Prior Period Adjustment</h3>
                            <div class="mt-2 text-yellow-700">
                                <div class="pr-2">
                                    You are changing the price from
                                    <span class="font-bold">{{ $filters.format_money(service.client_base_charge) }}</span> to
                                    <span class="font-bold">{{ $filters.format_money(form.client_base_charge) }}</span> effective
                                    <span class="font-bold">{{ $filters.format_date(actual_date) }}</span>.
                                    <span v-if="priorPeriodAdjustment.lastBilledThru !== null">
                                        This service has already been billed through the end of
                                        <span class="font-bold">{{ priorPeriodAdjustment.lastBilledThru }}</span>.
                                    </span>
                                    <p class="mt-1">If you do not wish to bill a prior period adjustment for this change, please enter in $0.00 below and no charge will be made.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="my-4">
                    <money-input v-model="priorPeriodAdjustment.proratedAmount" label="Prorated Amount" :defaultCurrency="$page.props.currency" />
                </div>

                <div class="my-4">
                    <textarea-input v-model="priorPeriodAdjustment.description" label="Line Item Description" />
                </div>
            </div>
        </template>

        <div class="border-t border-gray-300 mt-8 pt-8 px-4">
            <textarea-input rows="4" v-model="internal_change_notes" label="Internal Change Note" :errors="errors['internal_change_notes']" />
        </div>

        <loading-button class="rounded-md bg-d-orange-500 px-3 py-2 mt-12 text-base font-semibold text-white shadow-sm hover:bg-d-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-d-orange-500 disabled:opacity-50" :loading="saving" @click="submitChange" :disabled="!actual_date || !change_reason">
            Submit Change
        </loading-button>
    </div>
</template>

<script setup>
    import axios from 'axios';
    import { format, startOfToday } from "date-fns";
    import { router, usePage } from '@inertiajs/vue3';
    import { reactive, ref, inject, watch, computed } from 'vue';

    import Icon from '@/Shared/Icon.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';

    /**
     * Props
     */
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },

        service: {
            type: Object,
            required: true
        },

        clientBillingFrequencies: {
            type: Array,
            required: true
        },

        clientContracts: {
            type: Array,
            required: true
        },

        who: {
            type: String,
            required: true
        },

        earliestServiceActivity: {
            type: Object,
            required: true
        }
    });

    /**
     * Injected
     */
    const page = usePage();
    const route = inject('route');

    /**
     * Data
     */
    const saving = ref(false);
    const actual_date = ref(format(startOfToday(), 'yyyy-MM-dd'));
    const change_reason = ref(null);
    const internal_change_notes = ref(null);
    const today = ref(format(startOfToday(), 'yyyy-MM-dd'));
    const priorPeriodAdjustment = ref(null);
    const form = reactive({
        client_contract_id: props.service.client_contract_id,
        is_pass_through: props.service.is_pass_through,
        client_billing_frequency: props.service.client_billing_frequency,
        client_base_charge: props.service.client_base_charge ?? null,
        client_per_unit_charge: props.service.client_per_unit_charge ?? null,
        client_per_occurrence_charge: props.service.client_per_occurrence_charge ?? null,
    });

    const perUnitHelpText = props.service.serviceType.waste_generation_type === 'metered' ? "(Disposal Amount Per Ton)" : null;
    const perOccurrenceHelpText = props.service.serviceType.waste_generation_type === 'metered' ? "(Haul Amount)" : null;

    /**
     * Methods
     */
    function submitChange() {
        saving.value = true;

        router.post(route('services.client-contract-changes.store', [props.service.id]), {
            ...form,
            actual_date: actual_date.value,
            change_reason: change_reason.value,
            change_requester: props.who,
            internal_change_notes: internal_change_notes.value,
            ppa_amount: showPriorPeriodAdjustment ? priorPeriodAdjustment.value?.proratedAmount : null,
            ppa_description: showPriorPeriodAdjustment ? priorPeriodAdjustment.value?.description : null,
        }, {
            onFinish: () => saving.value = false
        });
    }

    function suggestPriorPeriodAdjustment() {
        const conditions = [
            page.props.permissions.accessRevenueManagement,
            !form.is_pass_through,
            ['Monthly', 'Quarterly', 'Yearly'].includes(form.client_billing_frequency),
            props.service.service_schedule_type !== 'One Time',
            serviceHasClientBaseCharge.value,
            form.client_base_charge !== props.service.client_base_charge,
        ];

        return conditions.every(condition => condition === true);
    }

    function updatePriorPeriodAdjustmentDetails() {
        const adjusted_amount = form.client_base_charge.amount / 100;
        const change_date = actual_date.value;
        const service_id = props.service.id;

        let url = `/json/services/${service_id}/prior-period-adjustments/create-from-price-change?change_date=${change_date}&adjusted_amount=${adjusted_amount}`;
        axios.get(url).then(response => {
            priorPeriodAdjustment.value = response.data;
        });
    }

    /**
     * Computed
     */
    const serviceHasClientBaseCharge = computed(() => {
        return props.service.serviceType.enabled_type_fields.includes('base_charges');
    });

    const serviceHasClientPerUnitCharge = computed(() => {
        return props.service.serviceType.enabled_type_fields.includes('per_unit_charges');
    });

    const serviceHasClientPerOccurrenceCharge = computed(() => {
        return props.service.serviceType.enabled_type_fields.includes('per_occurrence_charges');
    });

    const showPriorPeriodAdjustment = computed(() => {
        return suggestPriorPeriodAdjustment()
            && priorPeriodAdjustment.value?.proratedAmount?.amount
            && priorPeriodAdjustment.value?.proratedAmount?.amount != 0;
    });

    /**
     * Watchers
     */
    watch(form, (newValue, oldValue) => {
        if (suggestPriorPeriodAdjustment()) {
            updatePriorPeriodAdjustmentDetails();
        }
    }, { deep: true });

    watch(actual_date, () => {
        if (suggestPriorPeriodAdjustment()) {
            updatePriorPeriodAdjustmentDetails();
        }
    });

    watch(() => form.is_pass_through, (newValue) => {
        if (newValue === true) {
            form.client_billing_frequency = null;
            form.client_base_charge = null;
            form.client_per_unit_charge = null;
            form.client_per_occurrence_charge = null;
            return;
        }

        if (props.service.is_pass_through === false) {
            form.client_billing_frequency = props.service.client_billing_frequency;
            form.client_base_charge = props.service.client_base_charge;
            form.client_per_unit_charge = props.service.client_per_unit_charge;
            form.client_per_occurrence_charge = props.service.client_per_occurrence_charge;
            return;
        }

        if (! serviceHasClientBaseCharge.value) {
            form.client_billing_frequency = 'Per Vendor Invoice';
        }
    });

    watch(() => form.client_billing_frequency, (newValue, oldValue) => {
        if (newValue === 'Not Billable') {
            form.client_base_charge = null;
            form.client_per_unit_charge = null;
            form.client_per_occurrence_charge = null;
            return;
        }

        if (newValue !== null && oldValue === 'Not Billable') {
            form.client_base_charge = props.service.client_base_charge;
            form.client_per_unit_charge = props.service.client_per_unit_charge;
            form.client_per_occurrence_charge = props.service.client_per_occurrence_charge;
        }
    });
</script>
