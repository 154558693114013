<template>
    <Head :title="`Rover Invoice - ${vendorPortalInvoice.invoice_number || 'N/A'}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendor-portal-invoices.index')" class="breadcrumb-link">Rover Invoices</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ vendorPortalInvoice.vendorPortalAccount.account_number }} - {{ vendorPortalInvoice.invoice_number || 'N/A' }}</span>
        </nav>
    </Teleport>

    <!-- Page header -->
    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div class="flex-shrink-0">
                <div class="relative">
                    <span class="inline-flex items-center justify-center h-14 w-14 mr-2">
                        <icon name="file-invoice" class="w-8 h-8 fill-current" />
                    </span>
                </div>
            </div>
            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ vendorPortalInvoice.invoice_number || 'N/A' }}
                </h1>
            </div>
        </div>

        <div v-if="otherUsers.length > 0" class="flex -space-x-2 overflow-hidden mb-1 mt-4 md:mt-0">
            <template v-for="user in otherUsers" :key="user.id">
                <img v-if="user.avatar_thumbnail_url" :src="user.avatar_thumbnail_url" class="inline-block h-10 w-10 rounded-full ring-2 ring-white" :alt="user.name">
                <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                    <span class="text-lg font-medium leading-none text-white uppercase">{{ user.first_name[0] }}{{ user.last_name[0] }}</span>
                </span>
            </template>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div v-if="vendorPortalInvoice.vendorPortalAccount.vendorPortalCredential.vendorPortal.deprecated" class="flex items-start p-3 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-800 lg:col-start-1 lg:col-span-3">
            <icon name="exclamation-triangle" class="h-5 w-5 text-yellow-500 fill-current mr-2" />
            <p>Note The portal this invoice was found on has been deprecated.</p>
        </div>

        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Invoice details and records.</p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Status</dt>
                                <dd class="inline-flex items-center mt-1 text-sm text-gray-900">
                                    <template v-if="fetching">
                                        Fetching <icon name="spinner" class="animate-spin fill-current h-5 w-5 p-0.5 ml-1" />
                                    </template>
                                    <template v-else>
                                        {{ vendorPortalInvoice.status }}
                                    </template>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Invoice File</dt>
                                <dd class="mt-1 text-sm">
                                    <a v-if="vendorPortalInvoice.status === 'Fetched' && vendorPortalInvoice.gatheredVendorInvoiceFile" :href="$route('documents.preview', {path: vendorPortalInvoice.gatheredVendorInvoiceFile.document_path})" class="flex items-center text-2xl" target="_blank">
                                        <icon name="file-pdf" class="inline fill-current text-red-500 hover:text-red-500 w-5 h-5" />
                                    </a>

                                    <template v-else-if="vendorPortalInvoice.status === 'Fetched' && !vendorPortalInvoice.gatheredVendorInvoiceFile">
                                        <icon name="ban" class="fill-current h-5 w-5 mr-3" />
                                    </template>

                                    <template v-else>
                                        Not fetched
                                    </template>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Rover Account</dt>
                                <dd class="mt-1 text-sm">
                                    <inertia-link :href="$route('vendor-portal-accounts.show', vendorPortalInvoice.vendorPortalAccount.id)" class="link">
                                        {{ vendorPortalInvoice.vendorPortalAccount.account_number }}
                                    </inertia-link>
                                    <span v-if="vendorPortalInvoice.vendorPortalAccount.archived_at" class="italic text-sm">
                                        (Archived)
                                    </span>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Gathered Vendor Invoice File</dt>
                                <dd class="mt-1 text-sm">
                                    <inertia-link v-if="vendorPortalInvoice.status === 'Fetched' && vendorPortalInvoice.gatheredVendorInvoiceFile" :href="$route('gathered-vendor-invoice-files.show', vendorPortalInvoice.gathered_vendor_invoice_file_id)" class="link">
                                        {{ vendorPortalInvoice.gathered_vendor_invoice_file_id }}
                                    </inertia-link>

                                    <template v-else-if="vendorPortalInvoice.status === 'Fetched' && !vendorPortalInvoice.gatheredVendorInvoiceFile">
                                        Invoice File Deleted
                                    </template>

                                    <template v-else>
                                        Not fetched
                                    </template>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
    import { ref, inject, onMounted, onUnmounted } from 'vue';
    import { router } from '@inertiajs/vue3';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';

    // Injects
    const route = inject('route');
    const toast = inject('toast');

    const props = defineProps({
        vendorPortalInvoice: {
            type: Object,
            required: true
        },
    });

    const otherUsers = ref([]);
    const fetching = ref(false);
    const presenceChannel = `vendor_portal.invoice.${props.vendorPortalInvoice.id}`;
    const mounted = ref(false);

    Echo.join(presenceChannel)
        .here((users) => {
            otherUsers.value = users;
        })
        .joining((user) => {
            otherUsers.value.push(user);
        })
        .leaving((user) => {
            otherUsers.value = otherUsers.value.filter(otherUser => otherUser.id !== user.id);
        })
        .listen('.App\\Events\\VendorPortal\\FetchInvoiceJobHasStarted', (e) => {
            fetching.value = true;
        })
        .listen('.App\\Events\\VendorPortal\\FetchInvoiceJobHasFinished', (e) => {
            fetching.value = false;
            toast.success('Invoice fetched successfully.');
            router.reload();
        });

    onMounted(() => {
        mounted.value = true;
    });

    onUnmounted(() => {
        Echo.leave(presenceChannel);
    });
</script>