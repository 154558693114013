<template>
    <Head title="Tenants" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('user-settings.index')" class="breadcrumb-link">My Account</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Tenants</span>
        </nav>
    </Teleport>

    <div class="py-6">
        <horizontal-sub-nav currentTab="tenants" />

        <div class="divide-y divide-gray-200">
            <div class="px-4 py-5">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Tenants
                </h3>

                <p class="mt-1 text-sm leading-5 text-gray-600">
                    This page displays a list of your available tenants. Click the arrow button to change to another tenant.
                </p>

                <div class="mt-4 overflow-x-auto">
                    <table class="table-condensed table-striped min-w-full">
                        <thead>
                            <tr>
                                <th class="font-semibold">Name</th>
                                <th class="font-semibold">Role</th>
                                <th class="font-semibold"></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="employeeTenant in employeesTenants" :key="employeeTenant.id">
                                <td>
                                    <img v-if="employeeTenant.tenantLogo" :src="employeeTenant.tenantLogo" class="inline border border-gray-400 rounded-full mr-2 h-8 w-8" />

                                    <span v-else class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-400 mr-2">
                                        <span class="text-lg font-medium leading-none text-white uppercase">{{ getTenantInitials(employeeTenant.tenantName, 2) }}</span>
                                    </span>

                                    <span class="mr-2">
                                        {{ employeeTenant.tenantName }}
                                    </span>
                                </td>

                                <td>
                                    {{ employeeTenant.employeeRoleName }}
                                </td>

                                <td>
                                    <svg v-if="employeeTenant.tenant_id === $page.props.authUser.current_tenant_id" class="inline-block h-5 w-5 text-green-400" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24">
                                        <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                    </svg>

                                    <a v-else href="" @click.prevent="switchToTenant(employeeTenant)" class="link text-lg" title="Switch Tenant">
                                        <icon name="arrow-alt-circle-right" class="inline fill-current text-gray-600 hover:text-gray-700 w-5 h-5" />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, inject, onMounted } from 'vue';
    import { router, usePage } from '@inertiajs/vue3';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import HorizontalSubNav from "../HorizontalSubNav.vue";

    const route = inject('route');

    /**
     * Props
     */
    const props = defineProps({
        employeesTenants: {
            type: Array,
            required: true
        },

        errors: {
            type: Object,
            default: {},
        }
    });

    const mounted = ref(false);

    onMounted(() => {
        mounted.value = true;
    });

    /**
     * Methods
     */
    function getTenantInitials(name, maxLength) {
        let nameParts = name.split(' ');
        let initials = '';

        if (maxLength > nameParts.length) {
            maxLength = nameParts.length;
        }

        for (let i = 0; i < maxLength; i++) {
            initials += nameParts[i][0];
        }

        return initials;
    }

    function switchToTenant(tenant) {
        router.put(route('current-tenant.update'), {tenant_id: tenant.tenant_id}, {preserveState: false});
    }
</script>