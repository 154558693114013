<template>
    <Head title="Journal Entries - QB Desktop" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.quickbooks-desktop.accounting-exports.index')" class="breadcrumb-link">Quickbooks Desktop</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Vendor Invoices</span>
        </nav>
    </Teleport>

    <main class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="quickbooks-desktop" />

                <div class="lg:col-span-9 px-4 sm:px-6">
                  <horizontal-sub-nav current-tab="journal_entries" />
                  <div class="flex my-4 items-center">
                        <div class="sm:flex sm:items-center">
                            <div class="sm:flex-auto">
                                <h1 class="text-xl font-semibold text-gray-900">Journal Entry Exports</h1>
                                <p class="mt-2 text-sm text-gray-700">
                                    Manage your journal entries. This includes client payments, client invoices, and client credit memos.
                                </p>
                            </div>
                            <div class="w-1/2 pt-6">
                                <loading-button @click.prevent="createjournalEntryForPostedJournalEntries" type="button" v-if="journalEntries.total > 0" :loading="state === 'saving'" class="btn btn-blue float-right">
                                    Create New Export ({{ journalEntries.total }})
                                </loading-button>
                            </div>
                        </div>
                    </div>

                    <div v-if="journalEntries.data.length" class="-mx-4 mt-8 overflow-x-auto shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                        <table class="table table-condensed">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Journal Entry Number
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Source Type
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Date
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        GL Account
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Description
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                        Debit
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                        Credit
                                    </th>
                                </tr>
                            </thead>

                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="journalEntry in journalEntries.data" :key="journalEntry.id">
                                    <td class="">
                                        {{ journalEntry.document_number }}
                                    </td>
                                    <td>
                                        {{ journalEntry.document_type }}
                                    </td>
                                    <td>
                                        {{ journalEntry.transaction_date }}
                                    </td>
                                    <td>
                                        {{ journalEntry.gl_code }}
                                    </td>
                                    <td>
                                        {{ journalEntry.description }}
                                    </td>
                                    <td>
                                        <div v-if="journalEntry.posting_type == 'Debit'" class="text-right">{{ $filters.format_money(journalEntry.amount) }}</div>
                                        <div v-else class="text-center">-</div>
                                    </td>
                                    <td>
                                        <div v-if="journalEntry.posting_type == 'Credit'" class="text-right">{{ $filters.format_money(journalEntry.amount) }}</div>
                                        <div v-else class="text-center">-</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination :links="journalEntries.links" />
                    </div>
                    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                        <icon name="file-invoice" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Journal Entries Found</span>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SubNav from '../SubNav.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import { Head } from '@inertiajs/vue3';
    import HorizontalSubNav from "./HorizontalSubNav.vue";

    export default {
        components: {
          HorizontalSubNav,
            Pagination,
            Icon,
            LoadingButton,
            SelectInput,
            SubNav,
            Head,
        },

        props: {
            journalEntries: {
                type: Object,
                required: true
            }
        },

         data() {
            return {
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            createjournalEntryForPostedJournalEntries() {
                if (this.state === 'passive') {
                    this.state = 'syncing';

                    this.$inertia
                        .post(this.$route('tenant-settings.quickbooks-desktop.export-journal-entries'), {}, {
                            onFinish: () => this.state = 'passive'
                        });
                }
            }
        },
    }
</script>