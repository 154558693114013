<template>
    <Head title="Create Webhook" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.webhooks.index')" class="breadcrumb-link">Webhooks</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <form id="create-webhook" class="grid grid-cols-2 gap-4">
        <select-input v-model="form.event" label="Type" :errors="errors['type']" markAsRequired>
            <option v-for="type in eventTypes" :value="type" :key="type">{{ type }}</option>
        </select-input>

        <text-input v-model="form.url" label="Url" :errors="errors.url" mark-as-required />

        <div class="col-span-2">
            <loading-button class="btn btn-orange" :loading="state === 'saving'" :disabled="state !== 'passive'" @click.prevent="storeWebhook">
                Save
            </loading-button>
        </div>
    </form>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { ref, reactive, inject, onMounted} from 'vue';

// Components
import { Head } from '@inertiajs/vue3';
import Icon from '@/Shared/Icon.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import TextInput from '@/Shared/TextInput.vue';

// Inject
const route = inject('route');
const state = ref('passive');

// Props
const props = defineProps({
    webhook: {
        type: Object,
        required: true
    },

    eventTypes: {
        type: Object,
        required: true
    },

    errors: {
        type: Object,
        default: () => ({})
    },
});

// State
const form = reactive({
    ...props.webhook
});
const mounted = ref(false);

// Mount
onMounted(() => {
    mounted.value = true;
});

// Methods
function storeWebhook() {
    if (state.value !== 'passive') {
        return;
    }

    state.value = 'saving';

    router.post(route('tenant-settings.webhooks.store'), form, {
        preserveScroll: true,
        onFinish() {
            state.value = 'passive';
        }
    });
}
</script>