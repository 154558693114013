<template>
    <Head title="Prior Period Adjustments - Create" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-invoices.index')" class="breadcrumb-link">Client Invoices </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link v-if="fiscalPeriod" :href="$route('revenue.prior-period-adjustments', fiscalPeriod)" class="breadcrumb-link">Billable Revenue</inertia-link>
            <inertia-link v-else :href="$route('revenue.index')" class="breadcrumb-link">Billable Revenue</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create Prior Period Adjustment</span>
        </nav>
    </Teleport>

    <form id="create-prior-period-adjustment-form" class="space-y-8 divide-y divide-gray-200" @submit.prevent="submitForm">
        <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">Prior Period Adjustment</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">A prior period adjustment allows users to offset client line items when services are started/terminated outside the normal operating period.</p>
        </div>

        <div class="grid lg:grid-cols-4 gap-5 grid-cols-1">

            <search-input
                route="json.locations.index"
                :route-params="{sort_by: 'name', sort_direction: 'asc', per_page: 50}"
                :markAsRequired="true"
                label="Location"
                v-model="form.location_id"
                display-property="name"
                @selected="selectedLocation">
                <template #searchResult="obj">
                    <div>{{ obj.result.name }}</div>
                    <div class="text-xs truncate">{{ obj.result.address.full_address }}</div>
                </template>
            </search-input>

            <select-input-2 label="Service" v-model="form.service_id" :options="services">
                <template #option-display="{option, selected}">
                    <div :class="{'font-semibold': selected}" class="block">
                        <div>{{ option.id }}</div>
                        <div class="text-xs truncate">{{ option.status }}</div>
                    </div>
                    <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                        <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                    </span>
                </template>
            </select-input-2>

            <select-input label="Line Item Category" v-model="form.category" :errors="errors.category">
                <option v-for="lineItemCategory in lineItemCategoryOptions" :key="lineItemCategory.name" :value="lineItemCategory.name">{{ lineItemCategory.name }}</option>
            </select-input>

            <money-input label="Amount" :min="null" :defaultCurrency="$page.props.currency" v-model="form.amount" :errors="errors.amount"></money-input>
            <date-input label="Start Date" v-model="form.start_date" :errors="errors.start_date" :help-text="dateWarning"></date-input>
            <date-input label="End Date" v-model="form.end_date" :errors="errors.end_date"></date-input>
            <textarea-input class="lg:col-span-2" label="Description" v-model="form.description" :errors="errors.description"></textarea-input>
        </div>

        <div class="pt-5">
            <div class="flex justify-end">
                <inertia-link v-if="fiscalPeriod" :href="$route('revenue.prior-period-adjustments', [fiscalPeriod.id])" class="btn btn-gray mr-3">Cancel</inertia-link>
                <inertia-link v-else :href="$route('revenue.index')" class="btn btn-gray mr-3">Cancel</inertia-link>
                <loading-button :disabled="state !== 'passive'" type="submit" :loading="state === 'saving'" class="btn btn-orange">Save</loading-button>
            </div>
        </div>
    </form>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import SelectInput2 from '@/Shared/SelectInput2.vue';
    import { Head } from '@inertiajs/vue3'

    export default {
        components: {
            Icon,
            TextInput,
            TextareaInput,
            DateInput,
            SelectInput,
            MoneyInput,
            LoadingButton,
            SearchInput,
            SelectInput2,
            Head,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            lineItemCategories: Array,
            fiscalPeriod: null|Object
        },

        data() {
            return {
                form: {
                    location_id: null,
                    service_id: null,
                    category: null,
                    amount: null,
                    start_date: null,
                    end_date: null,
                    description: null,
                },
                state: 'passive',
                locations: [],
                services: [],
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm() {
                this.state = 'saving'

                this.$inertia.post(this.$route('prior-period-adjustments.store'), this.form, {
                    preserveScroll: true,
                    onFinish: () => this.state = 'passive',
                });
            },

            selectedLocation(location) {
                this.services = location.services;
            }
        },

        computed:{
            lineItemCategoryOptions() {
                return this.lineItemCategories
                    .sort((a, b) => a.name.localeCompare(b.name));
            }
        }
    }
</script>