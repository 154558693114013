<template>
    <div class="border-b-2 border-gray-200">
        <span class="font-heading font-semibold block px-3 py-1 text-sm text-gray-600 text-center bg-gray-200">Switch Tenants</span>

        <a href="" data-cy="layout.nav.item.tenant-switcher" v-for="tenant in user.employeesTenants" :key="tenant.id" @click.prevent="switchToTenant(tenant)" class="group flex items-center dropdown-link">
            <img v-if="tenant.brand_mark_thumbnail_url" :src="`${tenant.brand_mark_thumbnail_url}`" class="border border-gray-400 rounded-full mr-2 h-8 w-8" />

            <span v-else class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-400 mr-2">
                <span class="text-lg font-medium leading-none text-white uppercase">{{ getTenantInitials(tenant.name, 2) }}</span>
            </span>

            <span class="w-8/12 truncate text-gray-700 text-sm group-hover:text-d-orange-500" :class="{'text-d-orange-500': tenant.id === user.current_tenant_id}">{{ tenant.name }}</span>

            <svg v-if="tenant.id === user.current_tenant_id" class="ml-2 h-5 w-5 text-d-orange-400" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24">
                <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },

    methods: {
        switchToTenant(tenant) {
            this.$inertia.put(this.$route('current-tenant.update'), {tenant_id: tenant.id}, {preserveState: false});
        },

        getTenantInitials(name, maxLength) {
            let nameParts = name.split(' ');
            let initials = '';

            if (maxLength > nameParts.length) {
                maxLength = nameParts.length;
            }

            for (let i = 0; i < maxLength; i++) {
                initials += nameParts[i][0];
            }

            return initials;
        },

        getTenantShortName(name, maxLength) {
            return name.substring(0, maxLength);
        }
    }
}
</script>