<template>
    <Head title="Create Custom Report" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            Reports

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('custom-reports.index')" class="breadcrumb-link">Custom Reports</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <form action="" class="grid grid-cols-2 gap-x-4 gap-y-6 my-4 items-center md:grid-cols-3" @submit.prevent="saveCustomReport">
        <select-input-2 v-model="form.main_entity" class="w-full" label="Main Entity" :errors="errors.main_entity" @selected="fetchEntityData" :options="availableMainEntities" mark-as-required>
            <template #option-search="entity">{{ entity.entityDisplayName }}</template>
            <template #option-label="entity">{{ entity.entityDisplayName }}</template>
            <template #option-display="{option, selected}">
                <div :class="{'font-semibold': selected}" class="block">
                    <div>{{ option.entityDisplayName }}</div>
                </div>
                <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                    <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                </span>
            </template>
        </select-input-2>

        <text-input name="name" v-model="form.name" label="Report Name" :errors="errors.name" mark-as-required />
        <textarea-input name="description" v-model="form.description" label="Report Description" class="col-span-2" :errors="errors.description" autosize />

        <div v-if="errors['reportReturnsTooManyResults']" class="col-span-2 md:col-span-3 font-bold text-red-500">
            This report returns too many results. Please reduce the number of unique entities in the columns and filter groups or apply additional filters to reduce the number of results.
        </div>

        <div v-if="errors['reportRunsTooLong']" class="col-span-2 md:col-span-3 font-bold text-red-500">
            This report runs too long. Please reduce the number of unique entities in the columns and filter groups or apply additional filters to reduce the number of results.
        </div>

        <div class="col-span-2 md:col-span-3">
            <span class="form-label">
                <span class="text-red-500">*</span> Columns
            </span>

            <repeatable-custom-report-property
                v-model="form.properties"
                :maxPropertyCount="maxPropertyCount"
                :mainEntity="form.main_entity?.entityClassName"
                :entities="entities"
                :errors="errors.properties"
            />
        </div>

        <div class="col-span-2 md:col-span-3">
            <span class="form-label">Filters</span>

            <repeatable-custom-report-filter-group
                v-model="form.filter_groups"
                :mainEntity="form.main_entity?.entityClassName"
                :entities="entities"
                :maxDepth="maxFilterGroupDepth"
                :errors="errors.filter_groups"
            />
        </div>

        <div class="col-span-2 md:col-span-3 sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center py-2">
            <div class="col-span-2">
                <div class="float-right">
                    <inertia-link :href="$route('custom-reports.index')" class="btn btn-gray mr-2">Cancel</inertia-link>

                    <div class="inline-block">
                        <loading-button type="submit" :loading="saving" class="btn btn-orange">Save</loading-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import axios from 'axios';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import RepeatableCustomReportFilterGroup from '@/Shared/CustomReports/RepeatableCustomReportFilterGroup.vue';
    import RepeatableCustomReportProperty from '@/Shared/CustomReports/RepeatableCustomReportProperty.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import SelectInput2 from '@/Shared/SelectInput2.vue';
    import TextareaInput from "@/Shared/TextareaInput.vue";
    import TextInput from "@/Shared/TextInput.vue";
    import { Head } from '@inertiajs/vue3'

    export default {
        components: {
            Icon,
            LoadingButton,
            RepeatableCustomReportFilterGroup,
            RepeatableCustomReportProperty,
            SelectInput,
            SelectInput2,
            TextareaInput,
            TextInput,
            Head,
        },

        props: {
            availableMainEntities: {
                type: Array,
                required: true
            },

            maxPropertyCount: {
                type: Number,
                required: true
            },

            maxFilterGroupDepth: {
                type: Number,
                required: true
            },

            errors: {
                type: Object,
                required: false
            }
        },

        methods: {
            fetchEntityData() {
                if (!this.form.main_entity) {
                    return;
                }

                this.availableProperties = [];
                this.form.properties = [];
                this.entities = [];

                axios.get(this.$route('json.custom-reports.get-entity-relationships', {entityName: this.form.main_entity.entityClassName}))
                    .then(response => {
                        let relationships = response.data.relationships.map(relationship => {
                            return {
                                pathDisplayName: relationship.pathDisplayName,
                                key: relationship.path,
                                path: relationship.path,
                                entityClass: relationship.entityClass,
                                parentEntityClass: relationship.parentEntityClass,
                            };
                        });

                        this.entities.push(...relationships);
                    });
            },

            saveCustomReport() {
                this.saving = true;

                const properties = this.form.properties
                    .map((property) => {
                        return {
                            entity_path: property.entity?.path,
                            name: property.property_name,
                            display_name: property.property_display_name
                        };
                    });

                this.$inertia.post(this.$route('custom-reports.store'), {
                    main_entity: this.form.main_entity?.entityClassName,
                    name: this.form.name,
                    description: this.form.description,
                    properties: properties,
                    filter_groups: this.form.filter_groups
                }, {
                    onFinish: () => this.saving = false
                });
            }
        },

        data() {
            return {
                form: {
                    name: null,
                    description: null,
                    main_entity: null,
                    filter_groups: null,
                    properties: null
                },
                saving: false,
                entities: [],
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },
    }
</script>
