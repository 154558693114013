<template>
    <Head title="Service Activity Savings by Location" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('reports.index')" class="breadcrumb-link">Reports</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Service Activity Savings by Location</span>
        </nav>
    </Teleport>

    <form id="filter-report-data-form" action="" class="grid grid-cols-4 gap-x-4 my-2 mb-4" @submit.prevent>
        <date-input label="Changes From" v-model="form.begin_date" />
        <date-input label="Changes Through" v-model="form.end_date" />

        <Combobox as="div" v-model="form.activity_service_types" multiple>
            <ComboboxLabel for="activity_service_types" class="form-label">Activity Service Types</ComboboxLabel>

            <div class="relative">
                <ComboboxInput
                    name="activity_service_types"
                    id="activity_service_types"
                    class="form-input"
                    @change="activityServiceTypeComboBoxQuery = $event.target.value"
                    :display-value="comboDisplayValue"
                />

                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <!-- Heroicon name: solid/selector -->
                    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </ComboboxButton>

                <ComboboxOptions v-if="filteredActivityServiceTypeOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <ComboboxOption v-for="option in filteredActivityServiceTypeOptions" :key="option.value" :value="option" as="template" v-slot="{ active, selected }">
                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                            <span :class="['block truncate', selected && 'font-semibold']">
                            {{ option }}
                            </span>

                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                <!-- Heroicon name: solid/check -->
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </li>
                    </ComboboxOption>
                </ComboboxOptions>
            </div>
        </Combobox>

        <Combobox as="div" v-model="form.change_types" multiple>
            <ComboboxLabel for="change_types" class="form-label">Change Types</ComboboxLabel>

            <div class="relative">
                <ComboboxInput
                    name="change_types"
                    id="change_types"
                    class="form-input"
                    @change="changeTypeComboBoxQuery = $event.target.value"
                    :display-value="comboDisplayValue"
                />

                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <!-- Heroicon name: solid/selector -->
                    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </ComboboxButton>

                <ComboboxOptions v-if="filteredChangeTypeOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <ComboboxOption v-for="option in filteredChangeTypeOptions" :key="option.value" :value="option" as="template" v-slot="{ active, selected }">
                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                            <span :class="['block truncate', selected && 'font-semibold']">
                            {{ option }}
                            </span>

                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                <!-- Heroicon name: solid/check -->
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </li>
                    </ComboboxOption>
                </ComboboxOptions>
            </div>
        </Combobox>

        <select-input label="Months for Averages" v-model="form.months_for_averages">
            <option v-for="months in availableMonthsForAverages" :key="months">{{ months }}</option>
        </select-input>

        <div class="col-span-4 md:col-span-2 lg:col-span-1 flex flex-wrap">
            <inertia-link :href="$route('reports.service-activity-savings-by-location')" class="mt-6 btn btn-gray mr-2">Clear</inertia-link>

            <loading-button :loading="state === 'exporting'" class="mt-6 btn btn-gray" @click="exportData">
                Export to CSV
            </loading-button>
        </div>
    </form>

    <div v-if="reportData.data.length !== 0">
        <reporting-table :reportData="reportData.data"></reporting-table>
        <pagination v-if="reportData" :links="reportData.links" />
    </div>
    <div v-else>
        <h5>No results found, please adjust your filters.</h5>
    </div>
</template>

<script>
    import DateInput from '@/Shared/DateInput.vue';
    import DropdownLink from '@/Shared/DropdownLink.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import ReportingTable from '@/Shared/ReportingTable.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import { debounce, pickBy, range } from 'lodash-es';
    import { Head } from '@inertiajs/vue3';

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            DateInput,
            DropdownLink,
            Icon,
            LoadingButton,
            Pagination,
            ReportingTable,
            SelectInput,
            TextInput,
            Head,

            //Tailwind Headless UI
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            reportData: {
                type: Object,
                required: true
            },

            changeTypes: {
                type: Array,
                required: true
            },

            serviceTypes: {
                type: Array,
                required: true
            },

            filters: Object
        },

        data() {
            return {
                form: {
                    begin_date: this.filters.begin_date,
                    end_date: this.filters.end_date,
                    activity_service_types: this.filters.activity_service_types,
                    change_types: this.filters.change_types,
                    months_for_averages: this.filters.months_for_averages,
                },
                activityServiceTypeComboBoxQuery: '',
                changeTypeComboBoxQuery: '',
                saving: false,
                state: 'passive',
                availableMonthsForAverages: range(1, 13),
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            exportData() {
                this.saving = 'exporting';

                this.$inertia.post(this.$route('csv.service-activity-savings-by-location'), {...this.form}, {
                    onFinish: () => { this.saving = 'passive'; }
                });
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} Items Selected`;
                    } else if (option.length === 1) {
                        return '1 Item Selected';
                    } else {
                        return 'No items selected';
                    }
                }

                return option ?? 'No items selected';
            },
        },

        watch: {
            form: {
                handler: debounce(function() {
                    let query = pickBy(this.form);
                    query = Object.keys(query).length ? query : {remember: 'forget'};

                    this.$inertia.get(this.$route("reports.service-activity-savings-by-location"), query, {preserveState: true});
                }, 500),

                deep: true
            },
        },

        computed: {
            filteredChangeTypeOptions() {
                return this.changeTypeComboBoxQuery === ''
                    ? this.changeTypes
                    : this.changeTypes.filter((changeType) => {
                        return changeType.toLowerCase().includes(this.changeTypeComboBoxQuery.toLowerCase());
                    });
            },

            filteredActivityServiceTypeOptions() {
                return this.activityServiceTypeComboBoxQuery === ''
                    ? this.serviceTypes
                    : this.serviceTypes.filter((serviceType) => {
                        return serviceType.toLowerCase().includes(this.activityServiceTypeComboBoxQuery.toLowerCase());
                    });
            },
        }
    }
</script>