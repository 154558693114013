<template>
    <Head :title="`Work Order - ${workOrder.display_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('work-orders.index')" class="breadcrumb-link">Work Orders</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ workOrder.display_id }}</span>
        </nav>
    </Teleport>

    <!-- Page header -->
    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="lg:w-3/5 flex items-center space-x-5">
            <div class="flex-shrink-0">
                <icon name="clipboard" class="h-14 w-14" />
            </div>

            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ workOrder.display_id }}
                    <span v-if="workOrder.external_reference_id" class="text-lg font-semi-bold text-orange-300">{{ workOrder.external_reference_id ?? 'EXT-123ABC' }}</span>
                    <p v-if="workOrder.deleted_at !== null" class=" text-2xl font-bold text-red-700">(Deleted)</p>
                </h1>

                <p class="text-sm font-medium text-gray-500">
                    <span class="text-gray-900"> Opened</span> <time :datetime="workOrder.created_at">{{ $filters.format_date_time(workOrder.created_at) }}</time>
                </p>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <inertia-link :href="$route('work-orders.edit', [workOrder.id])" class="btn btn-gray">Edit</inertia-link>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="vendor-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="vendor-information-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Work Order details.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Status</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ workOrder.decorated_status }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Client Requests Completion By</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date(workOrder.requested_completion_date) }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Service</dt>
                                <dd class="mt-1 text-sm text-gray-900 flex">
	                                <pioneer-indicator v-if="workOrder.service.compactorMonitor" :compactor-monitor="workOrder.service.compactorMonitor" />
                                    <inertia-link :href="$route('services.show', [workOrder.service_id])" class="link">
                                        {{ workOrder.service_id }}
                                    </inertia-link>
                                </dd>
                            </div>

                            <div v-if="workOrder.device_service_id" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Device Service</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link :href="$route('services.show', [workOrder.device_service_id])" class="link">
                                        {{ workOrder.device_service_id }}
                                    </inertia-link>
                                </dd>
                            </div>

	                        <div v-if="workOrder.service.label" class="sm:col-span-1">
								<dt class="text-sm font-medium text-gray-500">Equipment Label</dt>
								<dd class="mt-1 text-sm text-gray-900">{{ workOrder.service.label }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Vendor</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link :href="$route('vendors.show', [workOrder.vendor_id])" class="link">{{ workOrder.vendor.name }}</inertia-link>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Client Company</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link :href="$route('client-companies.show', [workOrder.service.location.client_company_id])" class="link">{{ workOrder.service.location.clientCompany.name }}</inertia-link>
                                </dd>
                            </div>

                            <div v-if="workOrder.hauler_reference_number" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Hauler Reference #</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ workOrder.hauler_reference_number }}</dd>
                            </div>

                            <div v-if="workOrder.requestedBy" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Requested By</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ workOrder.requestedBy.name }}</dd>
                            </div>

                            <div v-if="workOrder.acknowledgedBy" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Acknowledged By</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ workOrder.acknowledgedBy.name }}</dd>
                            </div>

                            <div v-if="workOrder.source" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Source</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ workOrder.source }}</dd>
                            </div>

                            <div v-if="workOrder.occurred_at" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Occurred At</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date_time(workOrder.occurred_at) }}</dd>
                            </div>

                            <div v-if="workOrder.requested_action" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Requested Action</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ workOrder.requested_action }}</dd>
                            </div>

                            <div v-if="workOrder.on_site_contact_name" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">On Site Contact Name</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ workOrder.on_site_contact_name }}</dd>
                            </div>

                            <div v-if="workOrder.on_site_contact_number" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">On Site Contact Number</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ workOrder.on_site_contact_number }}</dd>
                            </div>

                            <div v-if="workOrder.estimated_quantity || workOrder.estimated_quantity_calculation_error" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Estimated Quantity</dt>
                                <dd v-if="workOrder.estimated_quantity" class="mt-1 text-sm text-gray-900">{{ workOrder.estimated_quantity }}</dd>
                                <dd v-else class="mt-1 text-sm font-semibold flex" >
	                                Unavailable <tooltip-icon tooltip="Unable to calculate tonnage. Please contact support." />
                                </dd>
                            </div>

                            <div v-if="workOrder.confirmed_quantity" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Confirmed Quantity</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ workOrder.confirmed_quantity }}</dd>
                            </div>

                            <div v-if="workOrder.most_accurate_event_date" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Most Accurate Event Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date(workOrder.most_accurate_event_date) }}</dd>
                            </div>

	                        <div v-if="workOrder.estimated_cost" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Estimated Cost</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_money(workOrder.estimated_cost) }}</dd>
	                        </div>

	                        <div v-if="confirmedCost" class="sm:col-span-1">
								<dt class="text-sm font-medium text-gray-500">Confirmed Cost</dt>
								<dd class="mt-1 text-sm text-gray-900">{{ $filters.format_money(confirmedCost) }}</dd>
	                        </div>

                            <div v-if="workOrder.additional_instructions" class="sm:col-span-2">
                                <dt class="text-sm font-medium text-gray-500">Additional Instructions</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ workOrder.additional_instructions }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <div class="lg:col-start-3 lg:col-span-1 space-y-6">
            <!-- Sticky Notes !-->
            <section aria-labelledby="timeline-title">
                <div class="my-2 mb-6">
                    <div class="col-span-2 sm:col-span-1">
                        <div class="rounded shadow-md px-4 py-5 sm:px-6 bg-yellow-50 space-y-6">
                            <h2 id="sticky-notes-title" class="text-lg leading-6 font-medium text-gray-900">Sticky Notes</h2>

                            <p v-if="workOrder.service.sticky_notes" class="leading-tight italic" v-html="workOrder.service.sticky_notes" />
                            <p v-else class="leading-tight italic">There are no sticky notes for this Work Order's Service.</p>
                        </div>
                    </div>
                </div>
            </section>

            <!-- External Events !-->
            <section aria-labelledby="timeline-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="vendor-information-title" class="text-lg leading-6 font-medium text-gray-900">External Events</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">External sensor notifications for this Work Order.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-for="event in latestEvents" :key="event.id">
                            <template v-if="event.event === 'Email Sent'">
                                Sent to {{ event.email }} at {{ $filters.format_date_time(event.when) }}
                            </template>

                            <template v-else-if="['Viewed', 'Scheduled'].includes(event.event)">
                                {{ event.email }} {{ event.event.toLowerCase() }} at {{ $filters.format_date_time(event.when) }}
                            </template>
                        </div>

                        <div v-if="!workOrder.external_event_history.length" class="text-center">
                            <icon name="tasks" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Exeternal Events.</h3>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- Hauler Charges !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="vendor-contacts-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="vendor-contacts-title" class="text-lg leading-6 font-medium text-gray-900">Hauler Charges</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Hauler Charges matched to this Work Order.</p>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="workOrder.haulerCharges.length > 0" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Invoice #</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Description</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Service Description</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Amount</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="charge in workOrder.haulerCharges.slice(0, 12)" :key="charge.id">
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{ charge.vendor_invoice_id }}</td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{ charge.description }}</td>
                                                <td class="text-gray-500">{{ charge.service_description }}</td>
                                                <td class="text-gray-500">{{ $filters.format_money(charge.amount) }}</td>
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('vendor-invoices.edit', [charge.vendor_invoice_id])" class="link">
                                                        Edit<span class="sr-only">, {{ charge.vendor_invoice_id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="address-book" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No charges associated with this work order (yet).</h3>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, computed } from 'vue';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import PioneerIndicator from "../../Shared/PioneerIndicator.vue";
    import TooltipIcon from "../../Shared/TooltipIcon.vue";

    // Props
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },

        workOrder: {
            type: Object,
            required: true
        },
    })

    // State
    const mounted = ref(false);

    // Mount
    onMounted(() => {
        mounted.value = true;
    });

    // Computed
    const latestEvents = computed(() => {
        return props.workOrder.external_event_history.reverse().slice(0, 3)
    });

    const confirmedCost = computed(() => {
        let filteredPickups = props.workOrder.pickups.filter(pickup => pickup.cost);

        if (!filteredPickups.length) {
            // No pickups with cost attached to this work order. Return null.
            return null;
        }

        let amount = filteredPickups.reduce((acc, pickup) => acc + parseInt(pickup.cost.amount), 0);
        let currencyCode = filteredPickups[0].cost.currency;

        return {amount: `${amount}`, currency: currencyCode};
    });
</script>
