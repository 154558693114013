<template>
    <Head title="API Tokens" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('user-settings.index')" class="breadcrumb-link">My Account</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>API</span>
        </nav>
    </Teleport>

    <main class="relative py-6">
        <horizontal-sub-nav currentTab="api" />

        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 w-full">

                <div class="px-4 sm:px-6">
                    <div class="flex justify-between my-4 items-center">

                            <toggle-switch-input label="Show Client Secrets" v-model="showClientSecrets" />

                        <div class="">
                            <a href="" @click.prevent="showAddOAuthClientModal" class="btn btn-orange">
                                New
                            </a>
                        </div>
                    </div>

                    <div class="my-2">
                        <a href="https://api.discoveryapp.io" class="link" target="_blank">
                            View API Documentation
                        </a>
                    </div>

                    <div v-if="formClients.length" class="px-4 sm:px-6">
                        <div v-for="client in formClients" :key="client.id" class="bg-white shadow overflow-hidden border border-gray-300 my-2">
                            <div class="px-4 py-5 sm:px-6">
                                <div class="w-full flex justify-between">
                                    <div class="text-xl font-medium">
                                        {{ client.name }}
                                    </div>

                                    <div v-if="!client.revoked">
                                    <a href="" @click.prevent="showEditOAuthClientModal(client)" title="Edit OAuth Client">
                                        <icon name="edit" class="inline fill-current text-gray-600 hover:text-gray-700 w-5 h-5 mx-1" />
                                    </a>

                                        <a href="" @click.prevent="revokeOAuthClient(client)" title="Revoke OAuth Client">
                                            <icon name="ban" class="inline fill-current text-gray-600 hover:text-gray-700 w-5 h-5 mx-1" />
                                        </a>
                                    </div>
                                </div>
                                <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:gap-y-4 mt-2">
                                    <div class="sm:col-span-1">
                                        <dt class="text-sm leading-5 font-medium">Client Id</dt>

                                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                                            {{ client.id }}
                                        </dd>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <dt class="text-sm leading-5 font-medium">
                                            Client Secret
                                        </dt>

                                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                                            <span v-if="showClientSecrets">{{ client.secret }}</span>
                                            <span v-else>Hidden</span>
                                        </dd>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <dt class="text-sm leading-5 font-medium">Redirect URL</dt>

                                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                                            {{ client.redirect }}
                                        </dd>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <dt class="text-sm leading-5 font-medium">Access Token URL</dt>

                                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                                            https://manage.discoveryapp.io/oauth/token
                                        </dd>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <dt class="text-sm leading-5 font-medium">Created At</dt>

                                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                                            {{ formatTimestamp(client.created_at) }}
                                        </dd>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <dt class="text-sm leading-5 font-medium">Status</dt>

                                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                                            {{ client.revoked ? 'Revoked' : 'Active' }}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                        <icon name="ban" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No OAuth Clients</span>
                    </div>
                </div>
            </div>
        </div>
    </main>

    <!-- OAuth Client Modal -->
    <modal class="max-w-2xl" ref="OAuthClientModal" tabindex="-1" role="dialog" @closed="emptyModal">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl">
                    {{ modalAction }} OAuth Client
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon name="times" class="fill-current inline w-6 h-6" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="p-4">
                <div class="flex flex-wrap overflow-x-auto">
                    <div class="w-full my-2 px-2">
                        <text-input v-model="form.name" label="Name" placeholder="Name" :errors="modalErrors.name" />
                    </div>

                    <div class="w-full my-2 px-2">
                        <text-input v-model="form.redirect" label="Redirect URL" placeholder="Redirect URL" :errors="modalErrors.redirect" />
                        <small>(only needed for <a href="https://oauth.net/2/grant-types/">authorization code grants</a>)</small>
                    </div>
                </div>
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4 flex">
                <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                <loading-button :loading="state === 'saving'" class="btn btn-orange" @click="modalCallback">Save</loading-button>
            </div>
        </template>
    </modal>
</template>

<script setup>
    import axios from 'axios';
    import { format, parseJSON } from 'date-fns';
    import { reactive, ref, inject, onMounted } from 'vue';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Modal from '@/Shared/Modal.vue';
    import HorizontalSubNav from '../HorizontalSubNav.vue';

    const route = inject('route');
    const toast = inject('toast');

    /**
     * Props
     */
    const props = defineProps({
        clients: {
            type: Array,
            required: true
        }
    });

    /**
     * State
     */
    const modalAction = ref('');
    const modalCallback = ref(() => {});
    const formClients = ref([ ...props.clients ]);
    const form = reactive({name: '', redirect: ''});
    const modalErrors = ref({name: null, redirect: null});
    const state = ref('passive');
    const showClientSecrets = ref(false);
    const OAuthClientModal = ref(null);
    const mounted = ref(false);

    onMounted(() => {
        mounted.value = true;
    });

    /**
     * Methods
     */
     function showAddOAuthClientModal() {
        modalAction.value = 'Add';
        modalCallback.value = storeOAuthClient;
        OAuthClientModal.value.show();
    }

    function storeOAuthClient() {
        state.value = 'saving';

        axios.post(route('user-settings.oauth-clients.store'), form)
            .then(response => {
                formClients.value.push(response.data);
                OAuthClientModal.value.close();
            })
            .catch(errors => {
                modalErrors.value = {
                    name: errors.response.data.errors?.name?.[0] ?? null,
                    redirect: errors.response.data.errors?.redirect?.[0] ?? null,
                }
            })
            .finally(() => {
                state.value = 'passive';
            })
    }

    function showEditOAuthClientModal(client) {
        modalAction.value = 'Edit';
        modalCallback.value = updateOAuthClient;
        
        Object.assign(form, {
            id: client.id,
            name: client.name,
            redirect: client.redirect
        });

        OAuthClientModal.value.show();
    }

    function updateOAuthClient() {
        state.value = 'saving';
        modalErrors.value = {};

        axios.put(route('user-settings.oauth-clients.update', form.id), form)
            .then(response => {
                let index = formClients.value.findIndex(formClient => formClient.id === form.id);
                formClients.value.splice(index, 1, response.data);
                OAuthClientModal.value.close();
            })
            .catch(errors => {
                modalErrors.value = {
                    name: errors.response.data.errors?.name?.[0] ?? null,
                    redirect: errors.response.data.errors?.redirect?.[0] ?? null,
                }
            })
            .finally(() => {
                state.value = 'passive';
            })
    }

    function revokeOAuthClient(client) {
        if (confirm('Are you sure you want to revoke this client?')) {
            axios.delete(route('user-settings.oauth-clients.destroy', client.id))
                .then(response => {
                    let index = formClients.value.findIndex(formClient => formClient.id === client.id);
                    formClients.value[index].revoked = true;

                    toast.success(response.data.success_message);
                })
                .catch(error => {
                    toast.error('An error has occurred. Please check the server logs.');
                })
        }
    }

    function regenerateOAuthClientSecret(client) {
        if (confirm('Are you sure you want to regenerate this client secret?')) {
            axios.patch(route('user-settings.oauth-clients.regenerate-secret', client.id))
                .then(response => {
                    let index = formClients.value.findIndex(formClient => formClient.id === client.id);
                    formClients.value[index].secret = response.data.secret;
                    showClientSecrets.value = true;

                    toast.success(response.data.success_message);
                })
                .catch(error => {
                    toast.error('An error has occurred. Please check the server logs.');
                })
        }
    }

    function emptyModal() {
        Object.assign(form, {
            id: null,
            name: '',
            redirect: ''
        });

        modalErrors.value = {
            name: null,
            redirect: null,
        };
    }

    function formatTimestamp(timestamp) {
        return format(parseJSON(timestamp), 'MM/dd/yyyy h:mm a');
    }
</script>