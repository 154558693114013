<template>
    <modal ref="adjustmentModal" class="max-w-md" role="dialog">
        <template #modal-body>
            <div class="w-full">
                <div class="px-4 my-4">
                    <h3>Adjust Client Line Item</h3>
                </div>

                <hr class="separator">

                <div class="px-4">
                    <div v-for="error_message in error_messages" class="text-red">{{ error_message }}</div>
                </div>

                <div class="px-4">
                    <label class="control-label">Adjustment Date</label>
                    <input type="date" v-model="adjustment_date">
                </div>

                <div class="px-4">
                    <label class="control-label">Adjust To Amount</label>
                    <money v-model="adjustment_amount"></money>
                </div>

                <div class="px-4">
                    <label class="control-label">This will create an adjustment for </label>
                    <span>{{ total_amount }}</span>
                </div>
                <div class="px-4">
                    <label class="control-label">Description</label>
                    <textarea v-model="description"></textarea>
                </div>

                <hr class="separator">

                <div class="px-4">
                    <label class="control-label">Internal Notes (not seen by client)</label>
                    <textarea v-model="internal_notes"></textarea>
                </div>

                <div v-if="other_adjustments.length != 0">
                    <hr class="separator">

                    <div class="px-4">
                        <label class="control-label">Other Adjustments For This Line item</label>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Adjustment Date</th>
                                    <th>Total Amount</th>
                                    <th>Description</th>
                                    <th>Created By</th>
                                    <th>Internal Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="adjustment in other_adjustments">
                                    <td>{{ $filters.format_date(adjustment.adjustment_date) }}</td>
                                    <td>{{ $filters.format_money(adjustment.total_amount) }}</td>
                                    <td>{{ adjustment.description }}</td>
                                    <td>{{ adjustment.created_by }}</td>
                                    <td>{{ adjustment.internal_notes }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </template>
        <template #modal-footer="{close}">
            <div class="m-4">
                <button class="flex-no-shrink text-white py-2 px-4 rounded bg-red-500hover:bg-red-600" @click.prevent="createLineItemAdjustment">Create Line Item Adjustment</button>
                <button class="flex-no-shrink text-white py-2 px-4 rounded bg-gray hover:bg-gray-600" @click.prevent="close">Cancel</button>
            </div>
        </template>
    </modal>
</template>

<script>
	import Modal from '@/Shared/Modal.vue';
	import {clone} from 'lodash-es';

	export default {
		components: {
			Modal,
		},

		props: {
			openFiscalPeriods: {
				type: Array,
				required: true,
			}
		},

		data() {
			return {
				adjustment_date: null,
				description: null,
				total_amount: null,
				adjusted_total_amount: 0,
				adjustment_amount: null,
				client_line_item_id: null,
				internal_notes: null,
				min_amount: 0,
				max_amount: 0,
				error_messages: [],
				other_adjustments: [],
			}
		},

		methods: {
			adjustLineItem(clientLineItem) {
				this.original_total = parseInt(clientLineItem.total_amount.amount);
				this.adjusted_total_amount = 0;
				this.total_amount = 0;
				this.client_line_item_id = clientLineItem.id;
				this.adjustment_date = (new Date).toISOString().slice(0, 10);
				this.adjustment_amount = clientLineItem.total_amount;
				this.description = clientLineItem.description;
				this.$refs.adjustmentModal.show();
				this.min_amount = this.original_total < 0 ? this.original_total : 0;
				this.max_amount = this.original_total > 0 ? this.original_total : 0;
				this.loadOtherAdjustments();
			},

			createLineItemAdjustment() {
				this.error_messages = [];
				
				axios.post('/billing/json/client-line-items/adjustments', {
					client_line_item_id: this.client_line_item_id,
					adjustment_date: this.adjustment_date,
					total_amount: this.total_amount,
					description: this.description,
					internal_notes: this.internal_notes,
				}).then((response) => {
					this.$refs.adjustmentModal.close();
					toastr.success('Created client adjustment');
				}).catch((error) => {
					if (error.response && error.response.data && error.response.data.errors) {
						this.error_messages = _(error.response.data.errors).flatMap((errors) => { return errors; }).value();
					} else if (error.response && error.response.data && error.response.data.message) {
						this.error_messages.push(error.response.data.message);
					}

					console.warn('error', error);
				});
			},

			loadOtherAdjustments() {
				axios.get(`/billing/json/client-line-items/${this.client_line_item_id}/adjustments`).then((response) => {
					this.other_adjustments = response.data;

					let otherAdjustmentsTotal = this.other_adjustments.reduce((carry, adjustment) => carry + parseInt(adjustment.total_amount.amount), 0);
					var adjustmentAmount = clone(this.adjustment_amount);

					adjustmentAmount.amount = parseInt(adjustmentAmount.amount) + otherAdjustmentsTotal;

					this.adjusted_total_amount = parseInt(adjustmentAmount.amount);
					this.min_amount = this.original_total < 0 ? this.original_total + otherAdjustmentsTotal : 0;
					this.max_amount = this.original_total > 0 ? this.original_total + otherAdjustmentsTotal : 0;
					this.adjustment_amount = adjustmentAmount;
				}).catch((error) => {
					alert('failure loading other adjustments for this line item');
					console.warn('error', error);
				});
			}
		},

		watch: {
			adjustment_amount() {
				this.error_messages = [];

				if (this.adjustment_amount) {
					if (this.min_amount <= parseInt(this.adjustment_amount.amount) && parseInt(this.adjustment_amount.amount) < this.max_amount) {
						this.total_amount = parseFloat((parseInt(this.adjustment_amount.amount) - this.adjusted_total_amount) / 100, 2);
					} else {
						this.error_messages.push("The adjustment amount must be between " + parseFloat(this.min_amount/100, 2) + " and " + parseFloat(this.max_amount/100, 2));
					}
				}
			},
		}
	}
</script>