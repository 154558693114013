<template>
    <div>
        <div v-if="who === 'broker'" class="my-8"><b>You</b> are entering a <b>sticky notes change</b>. Please fill in the details.</div>
        <div v-else class="my-8">The <b>{{ who }}</b> is requesting a <b>sticky notes change</b>. Please fill in the details.</div>

        <div class="grid gap-x-4 gap-y-6 grid-cols-2 my-4">
            <div>This change will take place <b>immediately</b>.</div>

            <div class="col-span-2">
                <quill-editor class="col-span-2" v-model="form.sticky_notes" label="Sticky Notes" :errors="errors['sticky_notes']" />
            </div>
        </div>

        <loading-button class="btn btn-orange mt-2" :disabled="state !== 'passive'" :loading="state === 'saving'" @click="submitChange">Submit Change</loading-button>
    </div>
</template>

<script>
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import QuillEditor from '@/Shared/QuillEditor.vue';

    export default {
        components: {
            LoadingButton,
            QuillEditor
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            service: {
                type: Object,
                required: true
            },

            who: {
                type: String,
                required: true
            },
        },

        data() {
            return {
                form: {
                    sticky_notes: this.service.sticky_notes,
                },
                state: 'passive'
            }
        },

        methods: {
            submitChange() {
                this.state = 'saving';
                this.$inertia.post(this.$route('services.sticky-notes-changes.store', [this.service.id]), {
                    ...this.form,
                }, {
                    onFinish: () => this.state = 'passive'
                });
            }
        },
    }
</script>