<template>
    <Head :title="`Service Type - ${serviceType.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="state.mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.service-types.index')" class="breadcrumb-link">Service Types</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{serviceType.name}} ({{serviceType.id}})</span>
        </nav>
    </Teleport>

    <div class="mx-auto max-w-screen-lg text-lg">
        <div class="flex items-start justify-between">
            <back-link :route="route('tenant-settings.service-types.index')" label="All Service Types" />

            <div class="flex items-center gap-2">
                <button type="button" class="btn btn-gray" @click.prevent="edit" v-if="!props.serviceType.is_archived">
                    Edit
                </button>

                <button type="button" class="btn btn-gray" @click.prevent="archiveOrUnarchive(props.serviceType.is_archived ? 'unarchive' : 'archive')">
                    <span v-tippy="{content: props.serviceType.is_archived ? 'Unarchiving this service type will allow new services to be created from it.' : 'Archiving this service type will prevent new services from being created from it.'}">
                        {{props.serviceType.is_archived ? 'Unarchive' : 'Archive'}}
                    </span>
                </button>
            </div>
        </div>

        <div v-if="props.serviceType.is_archived" class="rounded-md bg-blue-50 p-4 mt-10 ring-1 ring-blue-200">
            <div class="flex items-center">
                <div class="shrink-0">
                    <icon name="info-circle" class="fill-current size-5 text-blue-700" />
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-base text-blue-700">
                        This service type has been archived. No new services can be created from it.
                    </p>
                </div>
            </div>
        </div>

        <div class="flex justify-between items-center mt-10">
            <div>
                <h3 class="text-2xl font-semibold text-gray-900">{{serviceType.name}}</h3>
                <p class="mt-1 text-lg text-gray-500">{{serviceType.type_description}}</p>
            </div>
            <div class="flex flex-col items-end">
                <span class="text-sm font-normal text-gray-500">Number of Services</span>
                <span class="text-xl font-medium">{{props.serviceCount}}</span>
            </div>
        </div>

        <div class="mt-6 border-t border-gray-100">
            <dl class="divide-y divide-gray-100">
                <div class="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt class="font-medium text-gray-900 flex items-center justify-between gap-1">
                        Service ID Prefix
                        <icon name="info-circle" class="size-4 text-gray-400 fill-current" :content="`This value will be used to prefix the generated Service ID. (Examples: ${serviceType.service_id_prefix}1234, ${serviceType.service_id_prefix}5678)`" v-tippy />
                    </dt>
                    <dd class="mt-1 text-gray-700 sm:col-span-2 sm:mt-0">{{serviceType.service_id_prefix}}</dd>
                </div>
                <div class="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt class="font-medium text-gray-900">Line Item Category</dt>
                    <dd class="mt-1 text-gray-700 sm:col-span-2 sm:mt-0">{{serviceType.lineItemCategory.display_name}}</dd>
                </div>
                <div class="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt class="font-medium text-gray-900">Waste Generation Type</dt>
                    <dd class="mt-1 text-gray-700 sm:col-span-2 sm:mt-0">{{serviceType.decorated_waste_generation_type}}</dd>
                </div>
                <div class="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt class="font-medium text-gray-900 flex items-center justify-between gap-1">
                        Service Description
                        <icon name="info-circle" class="size-4 text-gray-400 fill-current" v-tippy="{content: 'This template is used to generate the service description for each service created from this service type. (Example output shown below the template)'}" />
                    </dt>
                    <dd class="flex flex-col gap-1 text-gray-700 sm:col-span-2 sm:mt-0">
                        <span v-html="prettyFormatTwig(serviceType.service_description)"></span>
                        <span class="italic text-gray-400" v-if="preview.service_description">{{preview.service_description}}</span>
                    </dd>
                </div>
                <div class="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt class="font-medium text-gray-900 flex items-center justify-between gap-1">
                        Client Invoice<br>Service Description
                        <icon v-if="serviceType.client_line_item_header_label" name="info-circle" class="size-4 text-gray-400 fill-current" v-tippy="{content: 'This format will be used when grouping line items on the client invoice. (Example output shown below the template)'}" />
                    </dt>
                    <dd class="flex flex-col gap-1 text-gray-700 sm:col-span-2 sm:mt-0">
                        <span :class="{'italic': !serviceType.client_line_item_header_label}" v-html="prettyFormatTwig(serviceType.client_line_item_header_label)"></span>
                        <span class="italic text-gray-400" v-if="preview.client_line_item_header_label">{{preview.client_line_item_header_label}}</span>
                    </dd>
                </div>
                <div class="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt class="font-medium text-gray-900 flex items-center justify-between gap-1">
                        Client Invoice<br>Base Charge Description
                        <icon v-if="serviceType.client_line_item_description" name="info-circle" class="size-4 text-gray-400 fill-current" v-tippy="{content: 'This format will be used when generating a line item for the base charge on the client invoice. (Example output shown below the template)'}" />
                    </dt>
                    <dd class="flex flex-col gap-1 text-gray-700 sm:col-span-2 sm:mt-0">
                        <span :class="{'italic': !serviceType.client_line_item_description}" v-html="prettyFormatTwig(serviceType.client_line_item_description)"></span>
                        <span class="italic text-gray-400" v-if="preview.client_line_item_description">{{preview.client_line_item_description}}</span>
                    </dd>
                </div>

                <div class="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt class="font-medium text-gray-900">Enabled Settings</dt>
                    <dd class="mt-1 text-gray-700 sm:col-span-2 sm:mt-0">
                        <ul class="list-inside">
                            <li class="flex items-center gap-2" v-for="field in enabledFields" :key="field">
                                <icon name="check" class="size-4 text-d-orange-500 fill-current" /> {{field}}
                            </li>
                        </ul>
                    </dd>
                </div>
            </dl>
        </div>
    </div>
</template>

<script setup>
    // Vue/Inertia Imports
    import { router, Head } from '@inertiajs/vue3';
    import { reactive, inject, onMounted, computed } from 'vue';

    // Components
    import Icon from '@/Shared/Icon.vue';
    import BackLink from '@/Shared/BackLink.vue';

    // Inject global variables
    const route = inject('route');

    // Props
    const props = defineProps({
        serviceType: {
            type: Object,
            required: true
        },
        serviceCount: {
            type: Number,
            required: true
        },
        preview: {
            type: Object,
            required: true
        },
        fields: {
            type: Object,
            required: true
        }
    });

    // Page Data & States
    const state = reactive({
        mounted: false,
    });

    const fieldCommonNameMapping = {
        bin: 'Has a Bin',
        base_charges: 'Has Base Charges',
        service_schedule_info: 'Has a Schedule',
        per_unit_charges: 'Has Per Unit Charges',
        per_occurrence_charges: 'Has Per Occurrence Charges',
        units_included_per_occurrence: 'Has Units Included Per Occurrence',
    };

    // Computed
    const enabledFields = computed(() => {
        return props.serviceType.enabled_type_fields.map(field => {
            return fieldCommonNameMapping[field];
        });
    });

    // Mount
    onMounted(() => {
        state.mounted = true;
    });

    // Methods
    function edit() {
        router.visit(route('tenant-settings.service-types.edit', [props.serviceType.id]));
    }

    // We want to format the Twig stuff slightly differently from normal text
    function prettyFormatTwig(string) {
        if(!string) {
            return 'N/A';
        }

        let output = '';

        // split the string into twig and normal text on each pair of {{ and }}
        const split = string.split(/({{[a-zA-Z._]*}})/g);
        console.log(split);

        // loop through the split array and format the Twig stuff
        // and convert the spaces to non-breaking spaces
        split.forEach(part => {
            if (part.match(/{{[a-zA-Z._]*}}/)) {
                output += `<span class="text-base bg-gray-200 text-gray-700 p-1 rounded-full">${part}</span>`;
            } else if (part.match(/[\S]/)) {
                output += `<span class="text-lg text-gray-900">${part}</span>`;
            } else {
                output += part;
            }
        });

        // return the HTML output
        return output;
    }

    function archiveOrUnarchive(action) {
        if(confirm(`Are you sure you want to ${action} this service type?`)) {
            router.patch(route(`tenant-settings.service-types.${action}`, [props.serviceType.id]));
        }
    }
</script>
