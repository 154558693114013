<template>
    <Head title="Create Vendor Contract Fee" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendor-contracts.index')" class="breadcrumb-link">Vendor Contracts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendor-contracts.show', [vendorContract.id])" class="breadcrumb-link">{{ vendorContract.display_id }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create Vendor Contract Fee</span>
        </nav>
    </Teleport>

    <form id="create-vendor-contract-fee-form" class="space-y-8 divide-y divide-gray-200" @submit.prevent="submitForm">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Fee Information</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">This is the configuration information for this vendor contract fee.</p>
                </div>

                <div class="space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="vendor-fee-id" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            <span class="text-red-500">*</span> Vendor Fee
                        </label>

                        <select-input v-model="form.vendor_fee_id" id="vendor-fee-id" name="vendor_fee_id" :errors="errors.vendor_fee_id">
                            <option v-for="vendorFee in vendorFees" :key="vendorFee.id" :value="vendorFee.id">{{ vendorFee.name }}</option>
                        </select-input>
                    </div>

                    <div v-show="form.vendor_fee_id" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="restriction" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            <span class="text-red-500">*</span> Restriction
                        </label>

                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <select-input v-model="form.restriction" :errors="errors.restriction">
                                <option :value="null">-</option>
                                <option v-for="restriction in restrictionsForSelectedFee" :key="restriction" :value="restriction">
                                    {{ restriction }}
                                </option>
                            </select-input>
                        </div>
                    </div>

                    <div v-if="form.restriction == '$ per occurrence' || form.restriction == '$ per unit'" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="amount" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            <span class="text-red-500">*</span> Amount
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <money-input v-model="form.amount" :errors="errors.amount" :defaultCurrency="vendorContract.vendor.currency" name="amount" id="amount" autocomplete="family-name" class="max-w-lg w-full" />
                        </div>
                    </div>

                    <div v-if="form.restriction == '% of service charges'" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="percentage" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            <span class="text-red-500">*</span> Percentage
                        </label>
                        <text-input type="number" min="0" max="100" step="0.001" placeholder="0.25" v-model="form.percentage" :errors="errors.percentage" />
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-5">
            <div class="flex justify-end">
                <inertia-link :href="$route('vendor-contracts.show', [vendorContract.id])" class="btn btn-gray mr-3">Cancel</inertia-link>
                <loading-button type="submit" :loading="state === 'saving'" class="btn btn-orange">Save</loading-button>
            </div>
        </div>
    </form>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';

    export default {
        components: {
            Head,
            Icon,
            TextInput,
            SelectInput,
            MoneyInput,
            LoadingButton,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            vendorContract: Object,
            vendorFees: Array,
            vendorFeeLabels: Object,
        },

        mounted() {
            this.mounted = true;
        },

        data() {
            return {
                form: {
                    vendor_fee_id: null,
                    name: null,
                    amount: null,
                    restriction: null,
                },
                state: 'passive',
                mounted: false,
            }
        },

        methods: {
            submitForm() {
                this.state = 'saving'

                this.$inertia.post(this.$route('vendor-contracts.vendor-contract-fees.store', [this.vendorContract.id]), this.form, {
                    preserveScroll: true,
                    onFinish: () => this.state = 'passive',
                });
            },
        },

        computed: {
            restrictionsForSelectedFee() {
                let selectedVendorFee = this.vendorFees.filter((vendorFee) => { return vendorFee.id == this.form.vendor_fee_id })

                selectedVendorFee = selectedVendorFee.length > 0 ? selectedVendorFee[0] : {
                    allow_per_occurrence: false,
                    allow_per_unit: false,
                    allow_percentage_amount: false
                }

                return [
                    'Uncapped',
                    'Not Allowed',
                    selectedVendorFee.allow_per_unit ? '$ per unit' : false,
                    selectedVendorFee.allow_per_occurrence ? '$ per occurrence' : false,
                    selectedVendorFee.allow_percentage_amount ? '% of service charges' : false
                ].filter(val => val !== false)
            }
        },

        watch: {
            'form.vendor_fee_id'(vendorFeeId) {
                if (vendorFeeId) {
                    this.form.name = this.vendorFees.find(vendorFee => vendorFee.id === this.form.vendor_fee_id).name;
                } else {
                    this.form.name = null;
                }
            }
        }
    }
</script>