<template>
    <Head title="Billing Dashboard" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Billing Dashboard</span>
        </nav>
    </Teleport>

    <div class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="billing-dashboard" />

                <div class="lg:col-span-9 pt-5">

                    <div class="sm:px-6">
                        <div class="flex justify-between items-start">
                            <div>
                                <h2 class="text-lg leading-6 font-medium text-gray-900">Current Plan</h2>
                                <h3 class="text-3xl mt-1">
                                    <span class="uppercase">Custom</span>
                                </h3>
                            </div>
                        </div>

                        <div>
                            <div class="mt-10 flex items-center gap-x-4">
                                <h4 class="flex-none text-sm font-semibold leading-6 text-d-blue-600">Enabled Feature List</h4>
                                <div class="h-px flex-auto bg-gray-100" />
                            </div>
                            <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-4">
                                <li v-for="(enabled, name) in sortedFeatures" :key="name" class="flex gap-x-3">
                                    <icon name="check" v-if="enabled" class="inline h-6 text-green-600 fill-current" aria-hidden="true" />
                                    <icon name="times" v-else class="inline h-6 text-red-600 fill-current" aria-hidden="true" />
                                    {{ featureName(name) }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {ref, onMounted, inject, getCurrentInstance} from 'vue';

    // Components
    import {Head, usePage} from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SubNav from '../SubNav.vue';

    const page = usePage();
    const instance = getCurrentInstance();
    const toast = inject('toast');
    const route = inject('route');

    // Props
    const props = defineProps({
        tenant: Object,
        features: Object,
        plans: Object,
        pricingPlans: Object,
        billingConfig: Object,
        restrictions: Object,
        errors: {
            type: Object,
            default: () => ({})
        },
    });

    // State
    const mounted = ref(false);

    // sort features by true/false value, then by name
    const sortedFeatures = Object.fromEntries(
        Object.entries(props.features).sort(([,a],[,b]) => b-a)
    );

    onMounted(() => {
        mounted.value = true;
    });

    // Formatting
    function featureName(feature) {
        return toTitleCase(feature.replace(/_/g, ' '));
    }
    function toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
            }
        );
    }
</script>