<template>
    <Head title="Create Incident Reason" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.incident-reasons.index')" class="breadcrumb-link">Incident Reasons</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <div class="container mx-auto">
        <div class="flex my-4">
            <div class="w-1/2">
                <div class="my-2">
                    <text-input v-model="form.name" label="Name" :errors="errors.name" />
                </div>

                <div class="mt-6">
                    <inertia-link :href="$route('tenant-settings.incident-reasons.index')" class="btn btn-gray mr-4">
                        Cancel
                    </inertia-link>
                    <button class="btn btn-orange" @click.prevent="addIncidentReason">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';

    export default {
        components: {
            Head,
            Icon,
            SelectInput,
            TextInput
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({})
            },
            incidentReason: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {...this.incidentReason},
                mounted: false
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            addIncidentReason() {
                this.$inertia
                    .post(this.$route('tenant-settings.incident-reasons.store'), this.form);
            }
        },
    }
</script>