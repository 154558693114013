<template>
    <Head :title="`Edit Custom Report - ${customReport.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('custom-reports.index')" class="breadcrumb-link">Custom Reports</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <form action="" class="grid grid-cols-2 gap-x-4 gap-y-6 my-4 items-center md:grid-cols-3" @submit.prevent="saveCustomReport">
        <select-input name="main_entity" v-model="form.main_entity" label="Main Entity" mark-as-required disabled >
            <option :key="mainEntity" :value="mainEntity">{{ mainEntity }}</option>
        </select-input>

        <text-input name="name" v-model="form.name" label="Report Name" :errors="errors.name" mark-as-required />
        <textarea-input name="description" v-model="form.description" label="Report Description" class="col-span-2" :errors="errors.description" autosize />

        <div v-if="customReport.error_code || errors['reportHasErrorsAndMustBeChanged']" class="col-span-2 md:col-span-3 font-bold text-red-500">
            This report has the error(s) listed below and must be changed before it can be run:
        </div>

        <div v-if="customReport.error_code === 'report_query_timeout' || errors['reportRunsTooLong']" class="col-span-2 md:col-span-3 font-bold text-red-500">
            This report exceeds the maximum amount of time it is allowed to run. Please reduce the number of unique entities in the properties and filter groups or apply additional filters to reduce the number of results.
        </div>

        <div v-if="customReport.error_code === 'too_many_results' || errors['reportReturnsTooManyResults']" class="col-span-2 md:col-span-3 font-bold text-red-500">
            This report exceeds the maximum number of results returned. Please reduce the number of unique entities in the properties and filter groups or apply additional filters to reduce the number of results.
        </div>

        <div class="col-span-2 md:col-span-3">
            <span class="form-label">
                <span class="text-red-500">*</span> Columns
            </span>

            <repeatable-custom-report-property
                v-model="form.properties"
                :maxPropertyCount="maxPropertyCount"
                :mainEntity="form.main_entity"
                :entities="entities"
                :initialProperties="initialAvailableProperties"
                :savedProperties="customReport.definition.properties"
                :errors="errors.properties"
            />
        </div>

        <div class="col-span-2 md:col-span-3">
            <span class="form-label">Filters</span>

            <repeatable-custom-report-filter-group
                v-model="form.filter_groups"
                :mainEntity="form.main_entity"
                :entities="entities"
                :properties="availableProperties"
                :initial-properties="initialAvailableProperties"
                :maxDepth="maxFilterGroupDepth"
                :savedFilterGroups="customReport.definition.filter_groups"
                :errors="errors.filter_groups"
            />
        </div>

        <div class="col-span-2 md:col-span-3 sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center py-2">
            <div class="col-span-2">
                <div class="float-right">
                    <button @click="cancelChanges" :disabled="state !== 'passive'" class="btn btn-gray mr-2">Cancel</button>

                    <div class="inline-block">
                        <loading-button type="submit" :loading="state === 'saving'" :disabled="state !== 'passive'" class="btn btn-orange">Save</loading-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import axios from 'axios';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import RepeatableCustomReportFilterGroup from '@/Shared/CustomReports/RepeatableCustomReportFilterGroup.vue';
    import RepeatableCustomReportProperty from '@/Shared/CustomReports/RepeatableCustomReportProperty.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from "@/Shared/TextareaInput.vue";
    import TextInput from "@/Shared/TextInput.vue";
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            LoadingButton,
            RepeatableCustomReportFilterGroup,
            RepeatableCustomReportProperty,
            SelectInput,
            TextareaInput,
            TextInput,
            Head,
        },

        props: {
            customReport: {
                type: Object,
                required: true
            },

            mainEntity: {
                type: String,
                required: true
            },

            maxPropertyCount: {
                type: Number,
                required: true,
            },

            maxFilterGroupDepth: {
                type: Number,
                required: true
            },

            entities: {
                type: Array,
                required: true
            },

            initialAvailableProperties: {
                type: Object,
                required: true
            },

            errors: {
                type: Object,
                required: false
            }
        },

        methods: {
            fetchEntityData() {
                this.availableProperties = [];
                this.form.properties = [];

                axios.get(this.$route('json.custom-reports.get-entity-properties', {entityName: this.customReport.main_entity}))
                    .then(response => {
                        let properties = response.data.properties.map(property => {
                            return {
                                key: property.entity_path + '.' + property.name,
                                entity: property.entity,
                                name: property.name,
                                display_name: property.display_name,
                                default_display_name: property.default_display_name,
                                type: property.type
                            };
                        });

                        this.availableProperties.push(...properties);
                    });
            },

            saveCustomReport() {
                if (this.state !== 'passive') {
                    return;
                }

                this.state = 'saving';

                const properties = this.form.properties.map((property) => {
                    return {
                        entity_path: property.entity.path,
                        name: property.property_name,
                        display_name: property.property_display_name
                    };
                });

                this.$inertia.put(this.$route('custom-reports.update', this.customReport.id), {
                    name: this.form.name,
                    description: this.form.description,
                    properties: properties,
                    filter_groups: this.form.filter_groups
                }, {
                    onFinish: () => this.state = 'passive'
                });
            },

            cancelChanges() {
                if (this.state !== 'passive') {
                    return;
                }

                this.state = 'cancelling';

                this.$inertia.get(this.$route('custom-reports.show', this.customReport.id), {}, {
                    onFinish: () => this.state = 'passive'
                });
            }
        },

        data() {
            return {
                form: {
                    main_entity: this.mainEntity,
                    name: this.customReport.name,
                    description: this.customReport.description,
                    properties: [],
                    filter_groups: [],
                },
                state: 'passive',
                availableProperties: [],
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
            this.fetchEntityData();

            for (const property of this.customReport.definition.properties) {
                this.form.properties.push({
                    entity: property.entity,
                    key: property.entity_path + '.' + property.name,
                    name: property.name,
                    display_name: property.display_name,
                    default_display_name: property.default_display_name,
                    type: property.type
                });
            }
        }
    }
</script>