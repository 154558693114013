<style scoped>
    @media only screen and (max-width: 640px) {
        td:before {
            content: attr(data-th);
            float: left;
            width: 95%;
            padding:0 0 0 1%;
            margin-left:-100%;
            font-weight: 500;
            text-transform: uppercase;
        }
        td {
            padding-left:50%;
            -moz-box-sizing:border-box;
            -webkit-box-sizing:border-box;
            box-sizing:border-box; 
            border: none;
            border-bottom: 1px solid #eee;
        }
    }
</style>

<template>
    <td class="block px-6 py-4 text-sm text-left md:table-cell" data-th="Date">{{ $filters.format_date(model.start_date) }}</td>
    <td class="block px-6 py-4 text-sm text-left md:table-cell" data-th="Description">
        <span v-if="model.service_description" class="font-medium block">{{ model.service_description }}</span>
        <span class="italic">{{ model.description }}</span>
    </td>
    <td v-if="! isSalesTaxLineItem" class="block px-6 py-4 text-sm text-left md:table-cell" data-th="Qty">
        <template v-if="model.quantity !== initialLineItem.quantity">
            <span class="line-through text-gray-500 mr-2">{{ initialLineItem.quantity }}</span>
            <span class="text-green-600">{{ model.quantity }}</span>
        </template>

        <template v-else>{{ model.quantity }}</template>
    </td>
    <td v-if="! isSalesTaxLineItem" class="block px-6 py-4 text-sm text-left md:table-cell" data-th="Price">{{ $filters.format_money(model.cost_per_unit) }}</td>
    <td class="block px-6 py-4 text-sm text-left md:table-cell" data-th="Total">
        <template v-if="model.amount.amount !== initialLineItem.amount.amount">
            <span class="line-through text-gray-500 mr-2">{{ $filters.format_money(initialLineItem.amount) }}</span>
            <span class="text-green-600">{{ $filters.format_money(model.amount) }}</span>
        </template>

        <template v-else>{{ $filters.format_money(model.amount) }}</template>
    </td>
    <td v-if="! isSalesTaxLineItem" class="block px-6 py-4 text-sm text-left md:table-cell" data-th="Fee">{{ model.vendorFee ? model.vendorFee.label : 'NA' }}</td>
    <td v-if="isSalesTaxLineItem" class="block px-6 py-4 text-sm text-left md:table-cell" data-th="Type">{{ model.vendorSalesTaxType ? model.vendorSalesTaxType.display_name : 'NA' }}</td>
    <td v-if="isSalesTaxLineItem" class="block px-6 py-4 text-sm text-left md:table-cell" data-th="Type">{{ model.vendorSalesTax ? model.vendorSalesTax.display_name : 'NA' }}</td>
    <td v-if="! isSalesTaxLineItem" class="block px-6 py-4 text-sm text-left md:table-cell" data-th="W.O">
        <inertia-link class="link" v-if="model.work_order_id" :href="$route('work-orders.show', [model.work_order_id])">{{ workOrder?.hauler_reference_number ?? model.work_order_id }}</inertia-link>
        <span v-else>NA</span>
    </td>
    <td class="block px-6 py-4 text-sm text-left md:table-cell inline-block" data-th="Actions">
        <span v-if="hasActionsAvailable">
            <a
                v-if="showEditButton"
                href=""
                class="text-lg"
                style="display: inline-block; margin-right: 10px;"
                @click.prevent="$emit('edit-line-item', model)"
                :class="{disabled: disabled}"
                title="Edit Line Item"
            >
                <icon name="edit" class="fill-current w-4 h-4"/>
            </a>

            <span v-if="autoApprovalError"
                  class="text-lg"
                  style="display: inline-block"
                  :title=humanFriendlyError(autoApprovalError)
              >
                <icon name="exclamation-triangle" class="fill-current text-yellow-500 w-4 h-4"/>
            </span>
        </span>

        <span v-else>
            -
        </span>
    </td>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';

    export default {
        components: {
            Icon
        },

        props: {
            model: {
                required: true,
                type: Object
            },

            initialLineItem: {
                required: true,
                type: Object
            },

            workOrder: {
                required: false,
                type: Object
            },

            showEditButton: {
                type: Boolean,
                default: true
            },

            disabled: {
                type: Boolean,
                default: false
            },

            autoApprovalError: {
                type: String,
                default: null
            }
        },

        emits: ['edit-line-item', 'link-to-service-contract'],

        methods: {
            /**
             * a placeholder if we do not like the error message from backend, we can change it...
             */
            humanFriendlyError(errorMessage) {
              return errorMessage
            }
        },

        computed: {
            hasInvalidState() {
                return this.model.type === 'Unknown Line Item'
                    || this.model.type.startsWith('Incomplete')
                    || (this.isCharge && this.model.category == null);
            },

            isCharge() {
                return this.model.type === 'Base Charge'
                    || this.model.type === 'Per Occurrence Charge'
                    || this.model.type === 'Per Unit Charge'
                    || this.model.type === 'Extraneous Charge';
            },

            isSalesTaxLineItem() {
                return this.model.type === 'Sales Tax';
            },

            unitPrice() {
                return this.model.amount.amount / this.model.quantity;
            },

            lastRevision() {
                if (this.model.revisions) {
                    return this.model.revisions.slice(-1)[0];
                }

                return  null;
            },

            hasActionsAvailable() {
                return this.showEditButton || this.autoApprovalError;
            }
        }
    }
</script>
