<template>
    <Head title="Client Account Fees" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-accounts.index')" class="breadcrumb-link">Client Accounts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>
        
    <div class="flex flex-row-reverse my-2">
        <a href="" class="btn btn-blue" @click.prevent="showAddClientAccountFeeModal">
            Add New Client Account Fee
        </a>
    </div>

    <div class="flex my-2">
        <div class="w-full">
            <div v-if="clientAccountFees.total">
                <div v-for="clientAccountFee in clientAccountFees.data" :key="clientAccountFee.id" class="my-2 bg-white shadow overflow-hidden border border-gray-300">
                    <div>
                        <button class="m-4" @click="showEditClientAccountFeeModal(clientAccountFee)">
                            <icon name="edit" class="w-5 h-5 inline fill-current" />
                        </button>
                    </div>

                    <div class="px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3 md:gap-y-4">
                            <div class="sm:col-span-1">
                                <dt class="text-sm leading-5 font-medium link">Header Label</dt>

                                <dd class="mt-1 text-sm leading-5 text-gray-900">
                                    {{ clientAccountFee.header_label }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm leading-5 font-medium link">Label</dt>

                                <dd class="mt-1 text-sm leading-5 text-gray-900">
                                    {{ clientAccountFee.label }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm leading-5 font-medium link">Line Item Category</dt>

                                <dd class="mt-1 text-sm leading-5 text-gray-900">
                                    {{ clientAccountFee.category.name }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm leading-5 font-medium link">Frequency</dt>

                                <dd class="mt-1 text-sm leading-5 text-gray-900">
                                    {{ clientAccountFee.frequency }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm leading-5 font-medium link">Start Date</dt>

                                <dd class="mt-1 text-sm leading-5 text-gray-900">
                                    {{ $filters.format_date(clientAccountFee.start_date) }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm leading-5 font-medium link">End Date</dt>

                                <dd class="mt-1 text-sm leading-5 text-gray-900">
                                    <span v-if="clientAccountFee.end_date">{{ $filters.format_date(clientAccountFee.end_date) }}</span>
                                    <span v-else>Not Set</span>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm leading-5 font-medium link">Amount</dt>

                                <dd class="mt-1 text-sm leading-5 text-gray-900">
                                    {{ $filters.format_money(clientAccountFee.amount) }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm leading-5 font-medium link">Run For X of Billing Cylces</dt>

                                <dd class="mt-1 text-sm leading-5 text-gray-900">
                                    {{ clientAccountFee.total_number_of_billing_cycles || 'Not Set' }}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                <icon name="badge-dollar" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Fees Found</span>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <modal role="dialog" ref="clientAccountFeeModal" class="max-w-2xl" @closed="emptyModal">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-grey-light flex justify-between">
                <div class="my-1 text-2xl flex items-center">
                    <icon :name="modalAction == 'Edit' ? 'edit' : 'plus'" class="w-4 h-4 mr-2" /> {{ modalAction }} Client Account Fee
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <span aria-hidden="true" class="fal fa-times text-lg text-grey"></span>
                </button>
            </div>
        </template>

        <template #modal-body>
            <form role="form" class="p-4">
                <div class="my-3 grid grid-cols-2 gap-y-6 gap-x-4">
                    <text-input label="Header Label" placeholder="Header Label" v-model="clientAccountFeeForm.header_label" :errors="modalErrors.header_label" />

                    <select-input v-model="clientAccountFeeForm.line_item_category_id" label="Line Item Category" :errors="modalErrors.line_item_category_id">
                        <option v-for="category in lineItemCategories" :key="category.id" :value="category.id">{{ category.display_name }}</option>
                    </select-input>

                    <money-input v-model="clientAccountFeeForm.amount" :defaultCurrency="this.clientAccount.clientCompany.currency" label="Amount" :errors="modalErrors.amount" />

                    <select-input v-model="clientAccountFeeForm.frequency" label="Frequency" :errors="modalErrors.Frequency">
                        <option v-for="billingFrequency in billingFrequencies" :key="billingFrequency" :value="billingFrequency">{{ billingFrequency }}</option>
                    </select-input>

                    <text-input type="month" v-model="clientAccountFeeForm.start_date" label="Start Date" :errors="modalErrors.start_date" />

                    <div v-if="clientAccountFeeForm.frequency != 'One Time' && !manually_set_end_date">
                        <text-input type="number"
                            v-model="clientAccountFeeForm.total_number_of_billing_cycles"
                            label="Cycles" :errors="modalErrors.total_number_of_billing_cycles"
                            help-text="Run for x # of billing cylces (leave blank to run forever)"
                        />
                        <span @click="toggleEndDateOverride" class="block text-blue-700 text-sm cursor-pointer">Manually set end date</span>
                    </div>

                    <div v-if="clientAccountFeeForm.frequency != 'One Time' && manually_set_end_date">
                        <date-input  v-model="clientAccountFeeForm.override_end_date" label="End Date" :errors="modalErrors.end_date" />
                        <span @click="toggleEndDateOverride" class="block text-blue-700 text-sm cursor-pointer">Calculate end date from x cycles</span>
                    </div>
                </div>
            </form>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4">
                <div class="inline-block">
                    <button type="button" class="btn btn-grey-inverted" @click="close">Close</button>
                </div>
                <div class="inline-block">
                    <loading-button :loading="saving" class="btn btn-blue" @click="modalCallback">
                        Save
                    </loading-button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import Modal from '@/Shared/Modal.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import { cloneDeep } from 'lodash-es';
    import { format, parse } from 'date-fns';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            Modal,
            TextInput,
            LoadingButton,
            DateInput,
            MoneyInput,
            SelectInput,
            Head,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            clientAccount: {
                type: Object,
                required: true
            },

            clientAccountFees: {
                type: Object,
                required: true
            },

            clientAccountFeeStub: {
                type: Object,
                required: true
            },

            lineItemCategories: {
                type: Array,
                required: true
            },

            billingFrequencies: {
                type: Array,
                required: true,
            }
        },

        data() {
            return {
                saving: false,
                manually_set_end_date: false,
                modalErrors: {},
                modalAction: '',
                modalCallback: () => {},
                clientAccountFeeForm: {... this.clientAccountFeeStub},
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            format_month(value, toDateFormat = 'MM/dd/yyyy', fromDateFormat = 'yyyy-MM-dd') {
                if (!value) {
                    return value;
                }

                return format(parse(value, fromDateFormat, new Date), toDateFormat);
            },

            showAddClientAccountFeeModal() {
                this.clientAccountFeeForm = cloneDeep(this.clientAccountFeeStub);
                this.clientAccountFeeForm.start_date = this.format_month(this.clientAccountFee.start_date, 'yyyy-MM');
                this.modalAction = 'Add';
                this.modalCallback = this.storeClientAccountFee;
                this.manually_set_end_date = false;
                this.$refs.clientAccountFeeModal.show();
            },

            storeClientAccountFee() {
                this.saving = true;
                this.clientAccountFeeForm.client_account_id = this.clientAccount.id;

                this.$inertia.post(this.$route('client-account-fees.store'), this.clientAccountFeeForm, {
                    preserveState: true,
                    preserveScroll: true,
                    onError: (errors) => this.modalErrors = errors,
                    onSuccess: () => this.$refs.clientAccountFeeModal.close(),
                    onFinish: () => this.saving = false,
                });
            },

            showEditClientAccountFeeModal(clientAccountFee) {
                this.modalAction = 'Edit';
                this.modalCallback = this.updateClientAccountFee;
                this.clientAccountFeeForm = cloneDeep(clientAccountFee);
                this.clientAccountFeeForm.start_date = this.format_month(clientAccountFee.start_date, 'yyyy-MM');
                this.manually_set_end_date = false;
                this.$refs.clientAccountFeeModal.show();
            },

            updateClientAccountFee() {
                this.saving = true;
                this.clientAccountFeeForm.client_account_id = this.clientAccount.id;

                this.$inertia.put(this.$route('client-account-fees.update', this.clientAccountFeeForm.id), this.clientAccountFeeForm, {
                    preserveState: true,
                    preserveScroll: true,
                    onError: (errors) => this.modalErrors = errors,
                    onSuccess: () => this.$refs.clientAccountFeeModal.close(),
                    onFinish: () => this.saving = false,
                });
            },

            emptyModal() {
                this.clientAccountFee = {};
                this.modalErrors = {};
            },

            toggleEndDateOverride() {
                this.manually_set_end_date = !this.manually_set_end_date
                this.clientAccountFee.override_end_date = this.manually_set_end_date ? this.clientAccountFee.end_date : null
            }
        }
    }
</script>