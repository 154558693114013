<template>
    <Head title="Security" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('user-settings.index')" class="breadcrumb-link">My Account</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Security</span>
        </nav>
    </Teleport>

    <div class="py-6">
        <horizontal-sub-nav currentTab="security" />

        <form class="divide-y divide-gray-200" @submit.prevent="submitForm">
            <div class="px-4 py-5">
                <div>
                    <div class="grid grid-cols-2">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Change Password
                        </h3>

                        <div>
                            <button v-if="!changingPassword" class="btn btn-gray float-right" @click.prevent="changingPassword = !changingPassword">Change Password</button>
                        </div>

                        <p class="mt-1 text-sm leading-5 text-gray-600">
                            This is the place to be if you want to change your password.
                        </p>
                    </div>

                    <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 sm:mt-5" v-if="changingPassword">
                        <text-input v-model="form.current_password" type="password" class="col-span-6 sm:col-span-3" placeholder="current password" autocomplete="current-password" label="Current Password" help-text="Your current password" :errors="errors.current_password" mark-as-required />
                        <span></span>
                        <text-input v-model="form.new_password" type="password" class="col-span-6 sm:col-span-3" placeholder="new password" autocomplete="new-password" label="New Password" help-text="Minimum of 8 characters - include an uppercase, number, and special character." :errors="errors.new_password" mark-as-required />
                        <text-input v-model="form.new_password_confirmation" type="password" class="col-span-6 sm:col-span-3" placeholder="confirm new password" autocomplete="new-password" label="Confirm Password" help-text="Must match your new password" :errors="errors.new_password_confirmation" mark-as-required />
                    </div>

                    <div class="flex mt-5 justify-end" v-if="changingPassword">
                        <span class="inline-flex shadow-sm">
                            <button type="button"
                                @click.prevent="changingPassword = !changingPassword"
                                class="py-2 px-4 border border-gray-300 text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                                Cancel
                            </button>
                        </span>

                        <span class="ml-3 inline-flex shadow-sm">
                            <loading-button type="submit" :loading="state === 'saving'" class="btn btn-orange">Save</loading-button>
                        </span>
                    </div>
                </div>
            </div>
        </form>

        <hr>

        <div class="px-4 py-5">
            <div class="grid grid-cols-2">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Enable Two Factor Authentication (2FA)
                    </h3>

                    <div class="mt-3 max-w-xl text-sm text-gray-600">
                        <p>
                            When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.
                        </p>
                    </div>
                </div>
                <div>
                    <loading-button v-if="!twoFactorEnabled" type="submit" :loading="state === 'enabling-two-factor-auth'" class="btn btn-gray float-right" @click.prevent="startConfirmingPassword(enableTwoFactorAuthentication)">
                        Enable 2FA
                    </loading-button>

                    <loading-button v-if="twoFactorEnabled" type="submit" :loading="state === 'disabling-two-factor-auth'" class="btn btn-gray float-right" @click.prevent="startConfirmingPassword(disableTwoFactorAuthentication)">
                        Disable 2FA
                    </loading-button>
                </div>
            </div>

            <div v-if="twoFactorActive" class="mt-4 max-w-xl text-sm text-gray-600">
                <p class="font-semibold">
                    Two-Factor Authentication is enabled and active on your account. Please remember to use your authenticator app when logging into Discovery.
                </p>
            </div>

            <div v-if="twoFactorEnabled">
                <div v-if="qrCode && !twoFactorActive">
                    <div class="mt-4 max-w-xl text-sm text-gray-600">
                        <p class="font-semibold">
                            Scan the following QR code using your phone's authenticator application.
                        </p>
                    </div>

                    <div class="mt-4" v-html="qrCode">
                    </div>

                    <div class="max-w-xl text-gray-600">
                        <p class="mt-4 text-sm font-semibold">
                            If you do not have access to a QR code reader, you can also add your account to your authenticator application with the following key:
                        </p>
                        <p class="mt-4 font-bold text-md">
                            <span class="border-2 border-gray-500 p-2">
                            {{ secret }}
                            </span>
                        </p>
                    </div>
                </div>

                <div v-if="twoFactorEnabled && !twoFactorActive">
                    <form class="grid grid-cols-2">
                        <div class="mt-4 max-w-xl text-sm text-gray-600">
                            <p class="font-semibold">
                                Two-Factor Authentication is enabled for your account, but it is not active yet. Please enter the code from your authenticator app below to confirm proper setup and activate your two-factor authentication.
                            </p>
                        </div>

                        <div class="grid grid-cols-1 col-start-1 col-span-2 sm:grid-cols-2 mt-6">
                            <text-input v-model="twoFactorAuthCode" autocomplete="off" label="Two-Factor Authentication Code" :errors="errors.two_factor_authentication_code" mark-as-required />
                            <div>
                                <loading-button type="submit" :loading="state === 'saving'" class="btn btn-gray float-right mt-6" @click.prevent="startConfirmingPassword(confirmTwoFactorAuthentication)">
                                    Activate
                                </loading-button>
                            </div>
                        </div>
                    </form>
                </div>

                <div v-if="twoFactorActive && recoveryCodes.length > 0">
                    <div class="mt-4 max-w-xl text-sm text-gray-600">
                        <p class="font-semibold">
                            Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.
                        </p>
                    </div>

                    <div class="grid gap-1 max-w-xl mt-4 px-4 py-4 font-mono text-sm bg-gray-100 rounded-lg">
                        <div v-for="code in recoveryCodes" :key="code">
                            {{ code }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-5">
                <div v-if="twoFactorActive">
                    <loading-button v-if="twoFactorActive && recoveryCodes.length > 0" type="submit" :loading="regeneratingRecoveryCodes" class="btn btn-orange" @click.prevent="startConfirmingPassword(regenerateRecoveryCodes)">
                        Regenerate Recovery Codes
                    </loading-button>

                    <button v-if="twoFactorActive && recoveryCodes.length === 0" type="submit" class="btn btn-gray" @click.prevent="startConfirmingPassword(showRecoveryCodes)">
                        Show Recovery Codes
                    </button>
                </div>
            </div>
        </div>
    </div>

    <modal class="max-w-xl" ref="passwordConfirmationModal" tabindex="-1" role="dialog" @closed="closePasswordConfirmationModal">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl">
                    Confirm Password
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon name="times" class="fill-current inline w-6 h-6" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="w-full my-2 px-2">
                For your security, please confirm your password to continue.
            </div>

            <div class="w-full my-2 px-2 mt-4">
                <text-input
                    type="password"
                    class="mt-1 block w-3/4"
                    placeholder="Password"
                    v-model="passwordConfirmationForm.password"
                    :errors="modalErrors.password"
                    @keyup.enter="confirmPassword"
                    ref="passwordConfirmationFormPassword"
                />
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4 flex">
                <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                <loading-button :loading="passwordConfirmationForm.processing" class="btn btn-orange" @click="confirmPassword">Confirm</loading-button>
            </div>
        </template>
    </modal>
</template>

<script setup>
    import { reactive, ref, inject, computed, onMounted } from 'vue';
    import { router, useForm } from '@inertiajs/vue3';
    import axios from 'axios';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Modal from '@/Shared/Modal.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import HorizontalSubNav from "../HorizontalSubNav.vue";

    const route = inject('route');
    
    /**
     * Props
     */
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },
        
        user: {
            type: Object,
            required: true
        }
    });

    /**
     * State
     */
    const form = useForm({
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
    });

    const initialPasswordConfirmationState = {
        password: '',
        action: null,
        processing: false,
    };
    const passwordConfirmationForm = reactive({...initialPasswordConfirmationState});

    const passwordConfirmationModal = ref(null);
    const modalErrors = ref({});
    const changingPassword = ref(false);
    const qrCode = ref(null);
    const secret = ref(null);
    const recoveryCodes = ref([]);
    const twoFactorAuthCode = ref('');
    const state = ref('passive');
    const confirmingTwoFactorAuth = ref(false);
    const regeneratingRecoveryCodes = ref(false);
    const mounted = ref(false);

    /**
     * Methods
     */
    function submitForm() {
        state.value = 'saving';

        router.put(route('user-settings.passwords.update'), form, {
            preserveScroll: true,
            onFinish: () => state.value = 'passive',
            onSuccess: () => form.reset()
        });
    }

    function changeNavigation(e) {
        router.get(e.target.value);
    }

    function startConfirmingPassword(action) {
        axios.get(route('password.confirmation')).then(response => {
            if (response.data.confirmed) {
                action();
                return;
            }

            passwordConfirmationForm.action = action;
            passwordConfirmationModal.value.show();
        })
    }

    function confirmPassword() {
        passwordConfirmationForm.processing = true;

        axios.post(route('password.confirm'), {
            password: passwordConfirmationForm.password
        }).then(() => {
            passwordConfirmationForm.action();
            passwordConfirmationModal.value.close();
        }).catch(error => {
            passwordConfirmationForm.processing = false;
            modalErrors.value = {password: error.response.data.errors.password[0]}
        });
    }

    function closePasswordConfirmationModal() {
        Object.assign(passwordConfirmationForm, initialPasswordConfirmationState);
        modalErrors.value = {};
    }

    function enableTwoFactorAuthentication() {
        state.value = 'enabling-two-factor-auth';

        router.post(route('user-settings.passwords.enable-two-factor-authentication'), {}, {
            preserveScroll: true,
            onSuccess: () => Promise.all([
                showQrCode(),
                showRecoveryCodes(),
            ]),
            onFinish: () => (state.value = 'passive'),
        });
    }

    function confirmTwoFactorAuthentication() {
        confirmingTwoFactorAuth.value = true;

        router.post(route('user-settings.passwords.confirm-two-factor-authentication-code'), {two_factor_authentication_code: twoFactorAuthCode.value}, {
            preserveScroll: true,
            onSuccess: () => (twoFactorAuthCode.value = ''),
            onFinish: () => (confirmingTwoFactorAuth.value = false)
        });
    }

    function showQrCode() {
        return axios.get(route('user-settings.passwords.two-factor-qr-code'))
            .then(response => {
                qrCode.value = response.data.svg;
                secret.value = response.data.secret;
            });
    }

    function showRecoveryCodes() {
        return axios.get(route('user-settings.passwords.two-factor-recovery-codes'))
            .then(response => {
                recoveryCodes.value = response.data;
            });
    }

    function regenerateRecoveryCodes() {
        regeneratingRecoveryCodes.value = true;

        axios.post(route('user-settings.passwords.regenerate-two-factor-recovery-codes'))
            .then(response => {
                showRecoveryCodes();
                regeneratingRecoveryCodes.value = false;
            });
    }

    function disableTwoFactorAuthentication() {
        state.value = 'disabling-two-factor-auth';

        router.post(route('user-settings.passwords.disable-two-factor-authentication'), {}, {
            preserveScroll: true,
            onSuccess: () => (twoFactorAuthCode.value = ''),
            onFinish: () => (state.value = 'passive'),
        });
    }

    /**
     * Computed
     */
    const twoFactorEnabled = computed(() => {
        return state.value === 'passive' 
            && (props.user.two_factor_status === 'enabled' || props.user.two_factor_status === 'confirmed');
    });

    const twoFactorActive = computed(() => {
        return state.value === 'passive' && props.user.two_factor_status === 'confirmed';
    });

    /**
     * Lifecycle Hooks
     */
    onMounted(() => {
        mounted.value = true;

        if (props.user.two_factor_status === 'enabled') {
            showQrCode();
        }
    });
</script>