<template>
    <Head title="Compactor Monitor Configurations" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Compactor Monitor Configurations</span>
        </nav>
    </Teleport>

    <form @submit.prevent id="compactor-monitor-search-form" class="grid grid-cols-9 gap-x-4 gap-y-6 my-4 items-center">
        <div class="col-span-6 sm:col-span-3">
            <label for="search-compactor-monitor-configurations" class="form-label">Search</label>

            <div class="input-group">
                <span class="input-group-item">
                    <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                </span>

                <input type="search" name="search-compactor-monitor-configurations" id="search-compactor-monitor-configurations" v-model="form.search" placeholder="Search" class="input-group-field">
            </div>
        </div>

        <select-input class="col-span-6 md:col-span-1" name="per_page" id="per-page" v-model="form.per_page" placeholder="15" label="Results / Page">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
        </select-input>

        <div class="col-span-6 md:col-span-1 md:pt-6">
            <div class="flex items-center">
                <column-selector
                    :headers="headers"
                    :excludedHeaders="excludedHeaders"
                    v-model="form.selected_headers"
                />
                    <inertia-link :href="$route('compactor-monitor-configurations.index', {remember: 'forget'})" class="btn btn-gray">Clear</inertia-link>
            </div>
        </div>
    </form>

    <div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center">
        <div v-if="compactorMonitorConfigurations.data.length" class="col-span-2 sm:col-span-1 font-medium text-lg text-gray-700">Showing {{ compactorMonitorConfigurations.from }} - {{ compactorMonitorConfigurations.to }} out of {{ compactorMonitorConfigurations.total }} Compactor Monitor Configurations</div>
    </div>

    <div>
        <div class="overflow-x-auto">
            <table class="table table-condensed" ref="table">
                <thead>
                    <tr class="h-16">
                        <sortable-table-header field="compactor_monitor_configurations.device_service_id" :filters="filters" route-name="compactor-monitor-configurations.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Device Service</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Monitored Service')" field="compactor_monitor_configurations.monitored_service_id" :filters="filters" route-name="compactor-monitor-configurations.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Monitored Service</sortable-table-header>
	                    <th v-show="form.selected_headers.includes('Equipment Label')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Equipment Label</th>
                        <th v-show="form.selected_headers.includes('Location')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Location</th>
                        <th v-show="form.selected_headers.includes('Tonnage Capacity')" scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Tonnage Capacity</th>
                        <th v-show="form.selected_headers.includes('Target Fullness')" scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Target Fullness</th>
                        <th v-show="form.selected_headers.includes('Creates Work Orders')" scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Creates Work Orders</th>
                        <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="compactorMonitorConfiguration in compactorMonitorConfigurations.data" :key="compactorMonitorConfiguration.id">
                        <td>
                            <div class="flex items-center gap-x-2" :title="compactorMonitorConfiguration.deviceService.status">
                                <div :class="[statuses[compactorMonitorConfiguration.deviceService.status], 'flex-none rounded-full p-1']">
                                    <div class="h-1.5 w-1.5 rounded-full bg-current" />
                                </div>
                                <inertia-link :href="$route('services.show', compactorMonitorConfiguration.device_service_id)" class="link">
                                    {{ compactorMonitorConfiguration.device_service_id }}
                                </inertia-link>
                            </div>
                        </td>
                        <td v-show="form.selected_headers.includes('Monitored Service')">
                            <div class="flex items-center gap-x-2" :title="compactorMonitorConfiguration.compactorMonitor?.monitoredService.status">
                                <div v-if="compactorMonitorConfiguration.compactorMonitor" :class="[statuses[compactorMonitorConfiguration.compactorMonitor?.monitoredService.status], 'flex-none rounded-full p-1']">
                                    <div class="h-1.5 w-1.5 rounded-full bg-current" />
                                </div>

                                <inertia-link v-if="compactorMonitorConfiguration.compactorMonitor" :href="$route('services.show', compactorMonitorConfiguration.compactorMonitor?.monitored_service_id)" class="link">
                                    {{ compactorMonitorConfiguration.compactorMonitor?.monitored_service_id }}
                                </inertia-link>
                                <span v-else>-</span>
                            </div>
                        </td>
                        <td v-show="form.selected_headers.includes('Equipment Label')">
							<span v-if="compactorMonitorConfiguration.compactorMonitor?.monitoredService?.label">
								{{ compactorMonitorConfiguration.compactorMonitor.monitoredService.label }}
							</span>

							<span v-else>
								-
							</span>
	                    </td>
                        <td v-show="form.selected_headers.includes('Location')">
                            <inertia-link v-if="compactorMonitorConfiguration.deviceService" :href="$route('locations.show', compactorMonitorConfiguration.deviceService.location_id)" class="link">
                                {{ compactorMonitorConfiguration.deviceService.location.name }}
                            </inertia-link>

                            <span v-else>
                                -
                            </span>
                        </td>
                        <td v-show="form.selected_headers.includes('Tonnage Capacity')" class="text-right">
                            {{ formatTonnageCapacity(compactorMonitorConfiguration.tonnage_capacity) }}
                        </td>
                        <td v-show="form.selected_headers.includes('Target Fullness')" class="text-right">
                            {{ compactorMonitorConfiguration.target_percentage_full }}%
                        </td>
                        <td v-show="form.selected_headers.includes('Creates Work Orders')" class="text-center">
                            <span :title="compactorMonitorConfiguration.create_work_orders ? 'Creates work orders' : 'Does not create work orders'">
                                <icon v-if="compactorMonitorConfiguration.create_work_orders" name="check" class="inline text-green-600 fill-current h-4 w-4" />
                                <icon v-else name="times" class="inline text-red-600 fill-current h-4 w-4" />
                            </span>
                        </td>
                        <td class="text-right">
                            <inertia-link :href="$route('compactor-monitor-configurations.show', compactorMonitorConfiguration.id)" class="link mr-3" title="View This Record">
                                View
                            </inertia-link>

                            <inertia-link :href="$route('compactor-monitor-configurations.edit', compactorMonitorConfiguration.id)" class="link" title="Edit This Record">
                                Edit
                            </inertia-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <pagination :links="compactorMonitorConfigurations.links" />
    </div>

    <div v-if="!compactorMonitorConfigurations.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
        <icon name="cogs" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Compactor Monitor Configurations Found</span>
    </div>
</template>

<script>
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import Modal from '@/Shared/Modal.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import { throttle } from 'lodash-es';
    import { Head } from '@inertiajs/vue3';
    import ColumnSelector from "@/Shared/ColumnSelector.vue";


    export default {
        components: {
            DateInput,
            Icon,
            Pagination,
            SelectInput,
            SortableTableHeader,
            TextInput,
            Modal,
            LoadingButton,
            Head,
            ColumnSelector,
        },
        
        props: {
            compactorMonitorConfigurations: {
                type: Object,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    selected_headers: this.filters.selected_headers,
                    per_page: this.filters.per_page,
                },
                headers: [],
                excludedHeaders: ['Device Service', 'Actions'],
                mounted: false,
                statuses: {
                    'Active': 'text-green-400 bg-green-400/10',
                    'Pending Activation': 'text-yellow-400 bg-yellow-400/10',
                    'Terminated': 'text-rose-400 bg-rose-400/10',
                }
            }
        },

        methods: {
            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                this.headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));
            },

            formatTonnageCapacity(num) {
                // format the number to always have 2 decimal places
                return num ? num.toFixed(2) : '-';
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('compactor-monitor-configurations.index'), this.queryFilters, {preserveState: true});
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.applyFormWatcher();
        },

        computed: {
            queryFilters() {
                let query = { ...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            }
        }
    }
</script>