<template>
    <Head :title="`Client Invoice - Invoice #${invoice.invoice_number}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('client-invoices.index')" class="breadcrumb-link">Client Invoices</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Invoice #{{ invoice.invoice_number }}</span>
        </nav>
    </Teleport>

    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    <a data-cy="client-invoices.show.id" :href="$route('client-invoices.pdf', invoice.id)" class="action-link" target="_blank">
                    <icon name="file-pdf" class="inline fill-current w-6 h-6 mr-2 text-red-500" />
                        Invoice #{{ invoice.invoice_number }}</a>
                    <span v-if="invoice.external_reference_id" class="text-lg font-semi-bold text-orange-300">{{ invoice.external_reference_id ?? 'EXT-123ABC' }}</span>
                </h1>

                <p class="ml-8 text-sm font-medium text-gray-500">
                    <span class="text-gray-900"> Client Invoice</span> added <time :datetime="invoice.created_at">{{ $filters.format_date_time(invoice.created_at) }}</time>
                </p>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <button v-if="canBePlacedOnHold" type="button" class="btn btn-gray" @click="placeOnHold">Place on Hold</button>

            <button v-if="canBeMovedToUnapproved" type="button" class="btn btn-gray" @click="moveToUnapproved">Move to Unapproved</button>

            <button v-if="canBeApproved" type="button"  class="btn btn-gray" @click="approve">Approve</button>

            <loading-button v-if="canBeEmailed" :loading="state === 'emailing'" type="button"  class="btn btn-gray" @click="sendEmail">Email</loading-button>

            <button v-if="invoice.total_amount_due.amount < 0" type="button" class="btn btn-gray" @click.prevent="moveInvoiceToCreditMemo(invoice)">Move to Credit Memo</button>

            <button v-if="canBeRemoved" type="button" class="btn btn-red" @click="deleteInvoice">Delete</button>

            <inertia-link v-if="canBeReminded" :href="$route('client-invoice-reminders.create', invoice.id)" type="button" class="btn btn-gray">Send Invoice Reminder</inertia-link>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Invoice Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Client Invoice details and records.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Status</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ invoice.status }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Client Name</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ invoice.client_name }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Invoice #</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ invoice.invoice_number }}
                                </dd>
                            </div>

                            <div v-if="invoice.clientAccount" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Client Account</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link :href="$route('client-accounts.show', invoice.client_account_id)" class="link">
                                        {{ invoice.clientAccount.name }}
                                    </inertia-link>
                                </dd>
                            </div>

                            <div v-if="invoice.clientAccount.account_number" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Account #</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link :href="$route('client-invoices.index', {search: invoice.clientAccount.account_number})" class="link">
                                        {{ invoice.clientAccount.account_number }}
                                    </inertia-link>
                                </dd>
                            </div>

                            <div v-if="invoice.invoice_date" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Invoice Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <time :datetime="invoice.invoice_date">
                                        {{ $filters.format_date(invoice.invoice_date) }}
                                    </time>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Due Date</dt>
                                <dd class="mt-1 text-sm text-gray-900" v-if="invoice.due_at">
                                    <time :datetime="invoice.due_at">
                                        {{ $filters.format_date(invoice.due_at) }}
                                    </time>
                                </dd>
                                <dd class="mt-1 text-sm text-gray-900" v-else>
                                    Due Upon Receipt
                                </dd>
                            </div>

                            <div v-if="invoice.settings.primary_email_address" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Primary Email</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ invoice.settings.primary_email_address }}
                                </dd>
                            </div>

                            <div v-if="invoice.settings.other_invoice_recipients" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Other Recipients</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ invoice.settings.other_invoice_recipients }}
                                </dd>
                            </div>

                            <div v-if="invoice.total_amount_billed" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Total Billed</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ $filters.format_money(invoice.total_amount_billed) }}
                                </dd>
                            </div>

                            <div v-if="invoice.total_amount_due" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Total Due</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ $filters.format_money(invoice.total_amount_due) }}
                                </dd>
                            </div>

                            <div v-if="invoice.total_amount_paid" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Total Paid</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ $filters.format_money(invoice.total_amount_paid) }}
                                </dd>
                            </div>

                            <div v-if="invoice.total_amount_credit_memo" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Total Credit Memos Applied</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ $filters.format_money(invoice.total_amount_credit_memo) }}
                                </dd>
                            </div>

                            <div v-if="invoice.settings.payment_terms" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Payment Terms</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ invoice.settings.payment_terms }} Days
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <div class="lg:col-start-3 lg:col-span-1 space-y-6 ">
            <section aria-labelledby="timeline-title">
                <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                    <h2 id="timeline-title" class="text-lg font-medium text-gray-900">History</h2>

                    <!-- Activity Feed -->
                    <template v-if="invoice.history.length">
                        <div class="mt-6 flow-root">
                            <ul role="list" class="-mb-8">
                                <li v-for="(history, index) in invoice.history" :key="history.when">
                                    <div class="relative pb-8">
                                        <span v-if="index !== invoice.history.length - 1" class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>

                                        <div class="relative flex space-x-3">
                                            <div>
                                                <span class="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white"></span>
                                            </div>

                                            <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                <div v-if="history.what == 'Status Changed'">Status changed to {{ history.data.status }} on {{ $filters.format_date_time(history.when) }} by {{ history.who.first_name }} {{ history.who.last_name }}</div>
                                                <div v-else>{{ history.what }} on {{ $filters.format_date_time(history.when) }} by {{ history.who.first_name }} {{ history.who.last_name }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-6 flex flex-col justify-stretch"></div>
                    </template>
                    <template v-else>No history available</template>
                </div>

                <!-- Applied Payments/Credit Memos !-->
                <div class="w-7/8 px-6 my-8">
                    <h2 class="text-md font-medium">Applied Payments/Credit Memos</h2>
                    <table class="table table-striped ">
                        <tbody>
                        <tr v-if="invoice.appliedPayments.length == 0 && invoice.appliedCreditMemos.length == 0">
                            <td colspan="3">
                                <em>No Money Applied Yet</em>
                            </td>
                        </tr>

                        <tr v-for="appliedPayment in invoice.appliedPayments" :key="appliedPayment.id">
                            <td><a target="_blank" :href="$route('client-payments.edit', appliedPayment.client_payment_id)">Payment</a></td>
                            <td>{{ $filters.format_money(appliedPayment.applied_amount) }}</td>
                            <td>{{ $filters.format_date(appliedPayment.applied_date) }}</td>
                        </tr>

                        <tr v-for="appliedCreditMemo in invoice.appliedCreditMemos" :key="appliedCreditMemo.id">
                            <td><a target="_blank" :href="$route('client-credit-memos.edit', appliedCreditMemo.client_credit_memo_id)">Credit Memo</a></td>
                            <td>{{ $filters.format_money(appliedCreditMemo.applied_amount) }}</td>
                            <td>{{ $filters.format_date(appliedCreditMemo.applied_date) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>

        <!--LineItems-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="services-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="lineItems-title" class="text-xl leading-6 font-medium text-gray-900">Line Items</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Line items for this invoice sorted by location.</p>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="invoice.lineItems.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8" v-for="(lineItems, locationId) in groupBy(sortedLineItems, 'location_id')" :key="locationId">
                                <span class="block text-md font-medium text-blue-600 my-2 mx-2 md:mx-6 lg:mx-8">
                                    <inertia-link v-if="typeof(lineItems[0]?.location?.name) != 'undefined' && locationId" :href="$route('locations.show', locationId)" class="">
                                        {{ lineItems[0].location.name }}
                                    </inertia-link>

                                    <span v-else>
                                        No Location
                                    </span>
                                </span>
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Service Date</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Service Description</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Description</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-right text-sm text-gray-900">Unit Cost</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-center text-sm text-gray-900">Unit Count</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Fee ID</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-right text-sm text-gray-900">Total Amount</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-right text-sm text-gray-900">Actions</th>
                                        </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="lineItem in lineItems" :key="lineItem.id">
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ $filters.format_date(lineItem.service_date) }}
                                                <br>
                                                <inertia-link v-if="lineItem.service_id" :href="$route('services.show', lineItem.service_id)" class="link">
                                                    {{ lineItem.service_id }}
                                                </inertia-link>
                                            </td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6" :title="lineItem.service_description">{{ truncate(lineItem.service_description) }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ lineItem.description }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-right sm:pl-6">{{ $filters.format_money(lineItem.unit_cost) }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-center sm:pl-6">{{ lineItem.unit_count }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ lineItem.category }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-right sm:pl-6">{{ $filters.format_money(lineItem.total_amount) }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-right sm:pl-6">
                                                <a v-if="canCreateClientCreditMemo(invoice, lineItem)" href="" @click.prevent="showCreditMemoModal(invoice, lineItem)" title="Add To Client Credit Memo"><icon name="credit-memo" class="inline w-5 h-5" /></a>
                                                <a v-if="canAdjustLineItem(invoice, lineItem)" href="" @click.prevent="showAdjustmentModal(invoice, lineItem)" title="Adjust Client Line Item"><icon name="edit" class="inline w-5 h-5" /></a>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td colspan="5"></td>
                                            <td>Subtotal:</td>
                                            <td>
                                                <div class="float-right text-right">{{
                                                        $filters.format_money({
                                                            'amount': lineItems.reduce(function (carry, lineItem) {
                                                                return carry + parseInt(lineItem.total_amount.amount)
                                                            }, 0), 'currency': lineItems[0].total_amount.currency
                                                        })
                                                    }}
                                                </div>
                                            </td>
                                            <td colspan="1"></td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- Sales Taxes -->
        <div v-if="hasSalesTaxes" class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="sales-taxes-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="sales-taxes-title" class="text-xl leading-6 font-medium text-gray-900">Sales Taxes</h2>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                    Tax
                                                </th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                    Display Name
                                                </th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-right text-sm text-gray-900">
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="tax in salesTaxes" :key="tax.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">
                                                    <inertia-link :href="$route('tenant-settings.sales-taxes.edit', tax.id)" class="link">
                                                        {{ tax.name }}
                                                    </inertia-link>
                                                </td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">
                                                    {{ tax.display_name }}
                                                </td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6 text-right">
                                                    {{ $filters.format_money(tax.amount) }}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colspan="1"></td>
                                                <td class="text-right">Total Sales Taxes:</td>
                                                <td>
                                                    <div class="float-right text-right">{{
                                                            $filters.format_money({
                                                                'amount': salesTaxes.reduce(function (carry, tax) {
                                                                    return carry + parseInt(tax.amount.amount)
                                                                }, 0), 'currency': salesTaxes[0].amount.currency
                                                            })
                                                        }}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- Reminders !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="reminders-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="reminders-title" class="text-xl leading-6 font-medium text-gray-900">Reminders</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Reminders for this invoice.</p>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="invoice.reminders.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col"
                                                class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                Date Sent
                                            </th>
                                            <th scope="col"
                                                class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                Sent To
                                            </th>
                                            <th scope="col"
                                                class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                Subject
                                            </th>
                                            <th scope="col"
                                                class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="reminder in invoice.reminders" :key="reminder.id">
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">
                                                {{ $filters.format_date(reminder.last_emailed_at) }}
                                            </td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">
                                                {{ reminder.email_to }}
                                            </td>
                                            <td>{{ reminder.email_subject }}</td>
                                            <td>
                                                <inertia-link :href="$route('client-invoice-reminders.show', [invoice.id, reminder.id])" class="link">
                                                    View
                                                </inertia-link>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="tasks" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No reminders</h3>
                        </div>
                    </div>
                </div>
            </section>

            <!-- creditMemoModal -->
            <modal ref="creditMemoModal" class="max-w-md" role="dialog">
                <template #modal-body>
                    <div class="p-4">
                        <h4 class="">Invoice #{{ creditMemoModal.invoice.invoice_number }}</h4>

                        <div class="">{{ creditMemoModal.lineItem.service_description }}</div>
                        <div class="">{{ $filters.format_money(creditMemoModal.lineItem.total_amount) }}</div>

                        <label class="">Select Where To Move This Line Item To</label>

                        <select v-model="creditMemoModal.client_credit_memo_id">
                            <option value="new">New Credit Memo</option>
                            <option v-for="openClientCreditMemo in openClientCreditMemos" :key="openClientCreditMemo.id" :value="openClientCreditMemo.id">{{ openClientCreditMemo.document_number }}</option>
                        </select>
                    </div>
                </template>

                <template #modal-footer="{close}">
                    <div class="p-4">
                        <button type="button" class="btn btn-gray" @click="close">Close</button>
                        <button type="button" class="btn btn-blue" @click="moveLineItemToCreditMemo">Move Line Item</button>
                    </div>
                </template>
            </modal>

            <!-- adjustmentModal -->
            <modal ref="adjustmentModal" role="dialog" class="max-w-2xl">
                <template #modal-body>
                    <div class="w-full">
                        <div class="px-2">
                            <h4>Adjust Client Line Item</h4>
                        </div>

                        <div class="">
                            <div v-for="error in errors" :key="error" class="text-red-500 p-2">{{ error }}</div>
                        </div>

                        <div class="p-2">
                            <label>Adjustment Date</label>
                            <input class="form-input" type="date" v-model="adjustmentModal.adjustment_date">
                        </div>

                        <div class="p-2">
                            <label>Adjust To Amount</label>
                            <MoneyInput v-model="adjustmentModal.adjustment_amount"></MoneyInput>
                        </div>

                        <div class="p-2">
                            <label>This will create an adjustment for </label>
                            <span>{{ $filters.format_money(adjustmentModal.total_amount) }}</span>
                        </div>

                        <div class="p-2">
                            <label>Description</label>
                            <textarea class="form-input" v-model="adjustmentModal.description"></textarea>
                        </div>

                        <div class="p-2">
                            <label>Internal Notes (not seen by client)</label>
                            <textarea class="form-input" v-model="adjustmentModal.internal_notes"></textarea>
                        </div>

                        <div v-if="adjustmentModal.lineItem.adjustments.length != 0">
                            <div class="p-2">
                                <label>Other Adjustments For This Line item</label>
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>Adjustment Date</th>
                                        <th>Total Amount</th>
                                        <th>Description</th>
                                        <th>Created By</th>
                                        <th>Internal Notes</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="adjustment in adjustmentModal.lineItem.adjustments" :key="adjustment.id">
                                        <td>{{ $filters.format_date(adjustment.adjustment_date) }}</td>
                                        <td>{{ $filters.format_money(adjustment.total_amount) }}</td>
                                        <td>{{ adjustment.description }}</td>
                                        <td>{{ adjustment.created_by }}</td>
                                        <td>{{ adjustment.internal_notes }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </template>
                <template #modal-footer="{close}">
                    <div class="m-4">
                        <button type="button" class="btn btn-gray" @click.prevent="close">Close</button>
                        <button type="button" class="btn btn-blue" @click.prevent="createLineItemAdjustment">Create Line Item Adjustment</button>
                    </div>
                </template>
            </modal>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import AddressPicker from '@/Shared/AddressPicker.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import MoneyInput from "@/Shared/MoneyInput.vue";
    import Modal from '@/Shared/Modal.vue';
    import { groupBy, truncate } from "lodash-es";
    import { Head } from '@inertiajs/vue3';
    import { format } from 'date-fns';

    export default {
        components: {
            MoneyInput,
            Icon,
            AddressPicker,
            LoadingButton,
            SelectInput,
            TextInput,
            ToggleSwitchInput,
            Modal,
            Head,
        },

        props: {
            invoice: {
                type: Object,
                required: true
            },

            isEmailing: {
                type: Boolean,
                required: true
            },

            sortedLineItems: {
                type: Object,
                required: true
            },

            salesTaxes: {
                type: Object,
                required: true
            },

            hasSalesTaxes: {
                type: Boolean,
                required: true
            },

            errors: {
                type: Object,
                default: () => ({})
            },

            openClientCreditMemos: {
                type: Array,
                required: true,
            },
        },

        data() {
            let blankCreditMemoModal = {
                lineItem: {
                    id: null,
                    service_description: '',
                    total_amount: { amount: 0 },
                },
                invoice: {
                    id: null,
                    invoice_number: null,
                },
                client_credit_memo_id: 'new',
            }

            let blankAdjustmentModal = {
                invoice: {},
                lineItem: {
                    adjustments: []
                },
                adjustment_date: format(new Date, 'yyyy-LL-dd'),
                adjustment_amount: {
                    amount: 0,
                    currency: this.$page.props.currency,
                },
                description: '',
                internal_notes: '',
                total_amount: 0,
            }

           return {
               state: 'passive',
               mounted: false,
               blankAdjustmentModal: blankAdjustmentModal,
               adjustmentModal: Object.assign({}, blankAdjustmentModal),
               blankCreditMemoModal: blankCreditMemoModal,
               allowedClientCreditMemos: [],
               creditMemoModal: Object.assign({}, blankCreditMemoModal),
           }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            groupBy,
            truncate,

            placeOnHold() {
                if (this.state === 'passive') {
                    this.state = 'placing-on-hold';

                    this.$inertia.patch(this.$route('client-invoices.place-on-hold', this.invoice.id),{},  {
                        preserveScroll: true,
                        onFinish: () => {
                            this.state = 'passive';
                        },
                    });
                }
            },

            approve() {
                if (this.state === 'passive') {
                    this.state = 'approving';

                    this.$inertia.patch(this.$route('client-invoices.approve', this.invoice.id), {}, {
                        preserveScroll: true,
                        onFinish: () => {
                            this.state = 'passive';
                        },
                    });
                }
            },

            moveToUnapproved() {
                if (this.state === 'passive') {
                    this.state = 'unapproving';

                    this.$inertia.patch(this.$route('client-invoices.unapprove', this.invoice.id), {}, {
                        preserveScroll: true,
                        onFinish: () => {
                            this.state = 'passive';
                        },
                    });
                }
            },

            sendEmail() {
                if (this.state === 'passive') {
                    this.state = 'emailing';

                    this.$inertia.post(this.$route('client-invoices.email', this.invoice.id), {}, {
                        preserveScroll: true,
                        onFinish: () => {
                            this.state = 'passive';
                        },
                    });
                }
            },

            deleteInvoice() {
                if (this.state === 'passive') {
                    if (window.confirm('Are you sure you want to delete this invoice?')) {
                        this.state = 'deleting';
                        this.$inertia.delete(this.$route('client-invoices.destroy', this.invoice.id), {
                            preserveScroll: true,
                            onFinish: () => {
                                this.state = 'passive';
                            },
                        });
                    }
                }
            },

            moveInvoiceToCreditMemo(invoice) {
                this.$inertia.post(this.$route('client-invoices.move-invoice-to-credit-memo', invoice.id));
            },

            showCreditMemoModal(invoice, lineItem) {
                this.creditMemoModal = Object.assign({}, this.blankCreditMemoModal, {
                    lineItem: lineItem,
                    invoice: invoice,
                });

                this.$refs.creditMemoModal.show()
            },

            showAdjustmentModal(invoice, lineItem) {
                var otherAdjustmentsTotal = lineItem.adjustments.reduce((carry, adjustment) => carry + parseInt(adjustment.total_amount.amount), 0);

                this.adjustmentModal = Object.assign({}, this.blankAdjustmentModal, {
                    lineItem: lineItem,
                    invoice: invoice,
                });

                this.adjustmentModal.client_line_item_id = lineItem.id;
                this.adjustmentModal.adjustment_amount = Object.assign({}, {amount: parseInt(lineItem.total_amount.amount) + otherAdjustmentsTotal, currency: lineItem.total_amount.currency });
                this.adjustmentModal.total_amount = {amount: parseInt(this.adjustmentModal.adjustment_amount.amount) - (parseInt(this.adjustmentModal.lineItem.total_amount.amount) + otherAdjustmentsTotal), currency: lineItem.total_amount.currency };

                this.$refs.adjustmentModal.show()
            },

            canCreateClientCreditMemo(invoice, lineItem) {
                return invoice.status == 'Not Approved' && lineItem.total_amount.amount < 0
            },

            canAdjustLineItem(invoice, lineItem) {
                // if the invoice is not already sent, then we cannot adjust line item
                if (['Not Approved', 'On Hold', 'Approved'].indexOf(invoice.status) != -1) {
                    return false
                }

                // if the invoice came from a pass through vendor invoice, it needs to be adjusted on vendor, not here
                if (lineItem.origin_type == 'Billable Expense' && lineItem.service.is_pass_through) {
                    return false
                }

                // we can adjust this line item
                return true
            },

            moveLineItemToCreditMemo() {
                this.$inertia
                    .post(this.$route('client-invoices.move-line-item-to-credit-memo', this.creditMemoModal.lineItem.id), {'client_credit_memo_id' : this.creditMemoModal.client_credit_memo_id}, {
                        preserveScroll: true,
                        onSuccess: () =>  this.$refs.creditMemoModal.close()
                    });
            },

            createLineItemAdjustment() {
                this.$inertia
                    .post(this.$route('client-line-item-adjustments.store'), this.adjustmentModal, {
                        preserveScroll: true,
                        onSuccess: () =>  this.$refs.adjustmentModal.close()
                    });
            },
        },

        computed: {
            canBeRemoved() {
                return !this.isEmailing && ['Not Approved', 'On Hold'].includes(this.invoice.status);
            },

            canBeReminded() {
                return ['Sent', 'Delinquent'].includes(this.invoice.status);
            },

            canBeApproved() {
                return ['Not Approved', 'On Hold'].includes(this.invoice.status);
            },

            canBeEmailed() {
                return !this.isEmailing && ['Approved'].includes(this.invoice.status);
            },

            canBePlacedOnHold() {
                return !this.isEmailing && ['Not Approved', 'Approved'].includes(this.invoice.status);
            },

            canBeMovedToUnapproved() {
                return !this.isEmailing && ['Approved', 'On Hold'].includes(this.invoice.status);
            },
        },

        watch: {
            'adjustmentModal.adjustment_amount': function() {
                let otherAdjustmentsTotal = this.adjustmentModal
                    .lineItem
                    .adjustments
                    .reduce((carry, adjustment) => carry + parseInt(adjustment.total_amount.amount), 0);

                this.adjustmentModal.total_amount = {amount: parseInt(this.adjustmentModal.adjustment_amount.amount) - (parseInt(this.adjustmentModal.lineItem.total_amount.amount) + otherAdjustmentsTotal), currency: this.adjustmentModal.lineItem.total_amount.currency };
            }
        }
    }
</script>