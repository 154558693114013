<template>
    <Head :title="`Edit Compactor Monitor ${compactorMonitor.id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('compactor-monitors.index')" class="breadcrumb-link">Compactor Monitors</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('compactor-monitors.overview', compactorMonitor.id)" class="breadcrumb-link">{{ compactorMonitor.id }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <form id="edit-compactor-monitor-form" class="space-y-8" @submit.prevent="submitForm">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Information</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Services attached to the compactor monitor.</p>
                </div>
            </legend>

            <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <search-input
                    label="Location"
                    v-model="form.location_id"
                    :default-value="compactorMonitor.monitoredService?.location"
                    class="col-span-6 sm:col-span-2"
                    :errors="errors.location_id"
                    route="json.locations.index"
                    search-filter-name="location_name_search"
                    value-property="id"
                    display-property="name"
                >
                    <template #searchResult="obj">
                        <span class="block">{{ obj.result.name }}</span>
                    </template>
                </search-input>

                <select-input-2
                    label="Monitored Service"
                    v-model="form.monitored_service_id"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :options="monitorableServices"
                    :errors="errors['monitored_service_id']"
                    :disabled="!form.location_id"
                    :mark-as-required="!!form.location_id"
                    @selected="setMonitoredService"
                >
                    <template #option-display="{option, selected}">
                        <span class="font-bold">{{ option.id }}</span>

                        <span v-if="option.label" class="italic">
                            <br />
                            {{ option.label }}
                        </span>

                        <span v-if="(option.bin.size && option.bin.size_metric) || option.bin.material_type || option.bin.type">
                            <br />
                            <span v-if="option.bin.size && option.bin.size_metric">{{ getBinSizeDisplay(option) }}&nbsp;</span>
                            <span v-if="option.bin.material_type">{{ option.bin.material_type }}&nbsp;</span>
                            <span v-if="option.bin.type">{{ option.bin.type }}</span>
                        </span>

                        <span v-if="option.vendor">
                            <br />
                            {{ option.vendor.name }}
                        </span>

                        <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                            <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                        </span>
                    </template>
                </select-input-2>

                <select-input-2
                    label="Device Service"
                    v-model="form.device_service_id"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :options="deviceServices"
                    :errors="errors['device_service_id']"
                    :disabled="!form.location_id"
                    :mark-as-required="!!form.location_id"
                    @selected="setDeviceService"
                >
                    <template #option-display="{option, selected}">
                        <span class="font-bold">{{ option.id }}</span>

                        <span v-if="option.label" class="italic">
                            <br />
                            {{ option.label }}
                        </span>

                        <span v-if="option.vendor">
                            <br />
                            {{ option.vendor.name }}
                        </span>

                        <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                            <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                        </span>
                    </template>
                </select-input-2>
            </div>
        </fieldset>

        <div >
            <loading-button type="submit" :loading="state === 'saving'" class="btn btn-orange">
                Save Changes
            </loading-button>
        </div>
    </form>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import SelectInput2 from "@/Shared/SelectInput2.vue";
    import { Head } from '@inertiajs/vue3'
    import axios from "axios";

    export default {
        components: {
            Icon,
            LoadingButton,
            SearchInput,
            SelectInput2,
            Head,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            compactorMonitor: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    location: this.compactorMonitor.monitoredService?.location,
                    location_id: this.compactorMonitor.monitoredService?.location.id,
                    ...this.compactorMonitor
                },
                state: 'passive',
                mounted: false,
                services: [],
            }
        },

        mounted() {
            this.fetchServices();

            this.mounted = true;
        },

        methods: {
            getBinSizeDisplay(service) {
                if (!service.bin.size || !service.bin.size_metric) {
                    return '';
                }

                let convertedSizeMetric = service.bin.size_metric;

                switch(service.bin.size_metric) {
                    case 'yards':
                        convertedSizeMetric = 'yard';
                        break;
                    case 'feet':
                        convertedSizeMetric = 'foot';
                        break;
                    case 'gallons':
                        convertedSizeMetric = 'gallon';
                        break;
                }

                return `${service.bin.size}-${convertedSizeMetric}`;
            },

            fetchServices() {
                if (this.form.location_id) {
                    axios.get(this.$route('json.services.index', {
                        location_id: this.form.location_id,
                        type: ['Commercial', 'Rolloff', 'Device'],
                        status: ['Active', 'Pending Activation'],
                        with_compactor_monitor: 'except'
                    })).then(response => {
                        this.services = response.data.data;
                    })
                } else {
                    this.services = [];
                }
            },

            setMonitoredService() {
                if (!this.form.monitored_service_id) {
                    this.form.monitoredService = null;
                    return;
                }

                this.form.monitoredService = this.monitorableServices.find(service => service.id === this.form.monitored_service_id);
            },

            setDeviceService() {
                if (!this.form.device_service_id) {
                    this.form.deviceService = null;
                    return;
                }

                this.form.deviceService = this.deviceServices.find(service => service.id === this.form.device_service_id);
            },

            submitForm() {
                if (this.state === 'passive') {
                    this.state = 'saving';

                    this.$inertia
                        .put(this.$route('compactor-monitors.update', this.compactorMonitor.id), this.filteredForm, {
                            preserveScroll: true,
                            onFinish: () => this.state = 'passive',
                            onSuccess: () => this.fetchServices()
                        });
                }
            },
        },

        computed: {
            monitorableServices() {
                let initialServices = this.compactorMonitor.monitoredService?.location && this.compactorMonitor.monitoredService?.location.id === this.form.location_id
                    ? [this.compactorMonitor.monitoredService]
                    : [];

                return initialServices
                    .concat(this.services)
                    .filter(service => service.type === 'Commercial' || service.type === 'Rolloff')
                    .sort((a, b) => a.id.localeCompare(b.id));
            },

            deviceServices() {
                let initialServices = this.compactorMonitor.deviceService?.location && this.compactorMonitor.deviceService?.location.id === this.form.location_id
                    ? [this.compactorMonitor.deviceService]
                    : [];

                return initialServices
                    .concat(this.services)
                    .filter(service => service.type === 'Device')
                    .sort((a, b) => a.id.localeCompare(b.id));
            },

            filteredForm() {
                return {
                    location_id: this.form.location_id,
                    monitored_service_id: this.form.monitored_service_id,
                    device_service_id: this.form.device_service_id,
                }
            }
        },

        watch: {
            'form.location_id'() {
                this.form.monitoredService = null;
                this.form.deviceService = null;
                this.form.monitored_service_id = null;
                this.form.device_service_id = null;

                this.fetchServices();
            }
        }
    }
</script>