<template>
    <Head :title="`Edit Service Document - ${serviceDocument.service_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.show', [serviceDocument.service_id])" class="breadcrumb-link">{{ serviceDocument.service_id }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit Document</span>
        </nav>
    </Teleport>

    <div class="flex flex-wrap flex-row-reverse">
        <inertia-link href="" class="btn btn-red" @click.prevent="remove">Delete</inertia-link>
    </div>

    <form @submit.prevent="submitForm" id="edit-client-service-fee-form" class="space-y-8">
        <fieldset class="space-y-8 sm:space-y-5">
            <div class="grid grid-cols-2 gap-y-6 gap-x-4">
                <text-input class="col-span-2 md:col-span-1" v-model="form.file_name" :mark-as-required="true" label="File Name" :errors="errors.file_name" />

                <textarea-input class="col-span-2" label="Notes" v-model="form.notes" :errors="errors.notes" />
            </div>
        </fieldset>

        <div class="flex flex-wrap">
            <inertia-link :href="$route('services.show', [serviceDocument.service_id])" class="btn btn-gray mr-3">Cancel</inertia-link>
            <loading-button :loading="state === 'saving'" class="btn btn-orange">Save</loading-button>
        </div>
    </form>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import {Head} from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            TextInput,
            TextareaInput,
            LoadingButton,
            Head,
        },

        props: {
            serviceDocument: {
                type: Object,
                required: true
            },

            errors: {
                type: Object,
                default: () => ({})
            }
        },

        data() {
            return {
                form: {...this.serviceDocument},
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm() {
                if (this.state === 'passive') {
                    this.state = 'saving';

                    this.$inertia.put(this.$route('service-documents.update', this.serviceDocument.id), this.form, {
                        onFinish: () => this.state = 'passive',
                    });
                }
            },

            remove() {
                if (this.state === 'passive') {
                    let confirmed = confirm("Are you sure you wish to perform this action?");

                    if (confirmed) {
                        this.state = 'deleting';

                        this.$inertia.delete(this.$route('service-documents.destroy', this.serviceDocument.id), null, {
                            onFinish: () => this.state = 'passive',
                        });
                    }
                }
            },
        }
    }
</script>