<style></style>

<template>
    <div class="p-2 overflow-x-auto ">
        <h3 class="my-3 p-1 text-d-navy-500 font-medium text-xl">{{ title }}</h3>

        <table class="table table-striped">
            <thead>
                <tr class="mb-2">
                    <th class="font-semibold w-1/4">Vendor</th>
                    <th class="font-semibold text-right"># Processed</th>
                    <th class="font-semibold text-right"># Incorrect</th>
                    <th class="font-semibold text-right">Error Rate</th>
                    <th class="font-semibold text-right">Savings</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(record, index) in invoiceSavingsByVendor" :key="index" class="">
                    <td class="text-sm">{{ record.vendor_name }}</td>
                    <td class="text-right">{{ record.total_count }}</td>
                    <td class="text-right">{{ record.incorrect_count }}</td>
                    <td class="text-red-500 font-semibold text-right">{{ $filters.format_percentage(record.error_rate) }}</td>
                    <td class="text-green-500 font-semibold text-right">
                        {{ $filters.format_money(record.savings) }} <span class="text-gray-900">({{ $filters.format_percentage(record.savings_percentage) }})</span>
                    </td>
                </tr>
                <tr>
                    <td class="font-bold text-lg">Total</td>
                    <td class="font-bold text-lg text-right">{{ numberOfInvoicesProcessed }}</td>
                    <td class="font-bold text-lg text-right">{{ numberOfInvoicesWithSavings }}</td>
                    <td class="font-bold text-lg text-right" :class="totalErrorRate > 0 ? 'text-red-700' : 'text-gray-900'">{{ $filters.format_percentage(totalErrorRate) }}</td>
                    <td class="font-bold text-lg text-right" :class="totalSavingsFromAudits.amount > 0 ? 'text-green-700' : 'text-gray-900'">
                        {{ $filters.format_money(totalSavingsFromAudits) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: 'Savings from Audits by Vendor'
            },

            totalSavingsFromAudits: {
                type: Object,
                required: true
            },

            totalErrorRate: {
                type: Number,
                required: true
            },

            numberOfInvoicesProcessed: {
                type: Number,
                required: true
            },

            numberOfInvoicesWithSavings: {
                type: Number,
                required: true
            },

            invoiceSavingsByVendor: {
                type: Array,
                required: true
            },
        },
    }
</script>