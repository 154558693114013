<template>
    <Head :title="`Edit Rover Account - ${vendorPortalAccount.account_number}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('vendor-portal-accounts.index')" class="breadcrumb-link">Rover Accounts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('vendor-portal-accounts.show', vendorPortalAccount.id)" class="breadcrumb-link">{{ vendorPortalAccount.vendorPortalCredential.username }} - {{ vendorPortalAccount.account_number }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Edit</span>
        </nav>
    </Teleport>

    <form id="edit-vendor-portal-account-form" class="space-y-8" @submit.prevent="submitForm">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Edit Account Details</h3>
                </div>
            </legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-6">
                <toggle-switch-input v-model="form.subscribed" label="Subscribed" />
            </div>

            <!-- Matching the Vendor Account to the Rover Account -->
            <SearchInput
                @selected="selectVendorAccount"
                @update:modelValue="removeVendorAccount"
                v-model="form.vendorAccountNumber"
                :default-value="vendorPortalAccount.vendorAccount"
                display-property="account_number"
                value-property="id"
                label="Vendor Account"
                route="json.vendor-accounts.index"
                class="relative col-span-6 xs:col-span-4 md:col-span-2"
                :errors="errors.vendor_account_id"
            >
                <template #searchResult="obj">
                    <span class="block mb-1">Account Number: {{ obj.result.account_number }}</span>
                    <span class="block text-sm font-bold" v-if="obj.result.status !== 'Active'">{{ obj.result.status }}</span>
                    <span class="text-sm">{{ obj.result.vendor?.name  ?? '' }}</span>
                </template>
            </SearchInput>
        </fieldset>

        <div class="w-full flex flex-wrap">
            <loading-button :loading="state === 'saving'" class="btn btn-orange" @click="submitForm">Save</loading-button>
        </div>
    </form>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SearchInput from "@/Shared/SearchInput.vue";
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';

    export default {
        components: {
            Head,
            Icon,
            SearchInput,
            LoadingButton,
            ToggleSwitchInput
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            vendorPortalAccount: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    vendor_account_id: this.vendorPortalAccount.vendor_account_id,
                    subscribed: !!this.vendorPortalAccount.subscribed_at,
                    vendorAccountNumber: this.vendorPortalAccount.vendorAccount?.account_number ?? '',
                },
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm() {
                if (this.state === 'passive') {
                    this.state = 'saving';

                    this.$inertia.put(this.$route('vendor-portal-accounts.update', this.vendorPortalAccount.id), this.form, {
                        onSuccess: (response) => {
                            // The response is really a 303 redirect, but Inertia handles these differently
                            // so this stops navigation if we catch any CouldNotSubscribe errors
                            if(!response.props.error_message) {
                                this.$inertia.visit(this.$route('vendor-portal-accounts.show', this.vendorPortalAccount.id))
                            }
                        },
                        onFinish: () => this.state = 'passive'
                    });
                }
            },

            selectVendorAccount(vendorAccount) {
                this.form.vendor_account_id = vendorAccount?.id;
                this.form.vendorAccountNumber = vendorAccount?.account_number;
            },

            removeVendorAccount() {
                this.form.vendor_account_id = null;
                this.form.vendorAccountNumber = '';
            }
        },
    }
</script>