<template>
    <div class="p-2">
        <div id="cost-basis-line-chart-container"></div>
    </div>
</template>

<script>
    import Highcharts from 'highcharts/highstock';
    import HighchartsMore from 'highcharts/highcharts-more';

    HighchartsMore(Highcharts);

    export default {
        props: {

        },

        data() {
            return {
                chart: null
            }
        },

        mounted() {
            this.drawChart();
        },

        methods: {
            drawChart() {
	            Highcharts.setOptions({
		            colors: ['#012639', '#075076', '#5fa3ec', '#fc9776', '#f4c326']
	            });
                this.chart = Highcharts.chart('cost-basis-line-chart-container', {

                    title: {
                        text: 'Disposal Costs'
                    },

                    subtitle: {
                        text: ''
                    },

                    yAxis: {
                        title: {
                            text: '$'
                        }
                    },

                    xAxis: {
                        tickInterval: 7 * 24 * 3600 * 1000, // one week
                        tickWidth: 0,
                        gridLineWidth: 1,
                        labels: {
                            align: 'left',
                            x: 3,
                            y: -3
                        }
                    },

                    legend: {
                        // layout: 'vertical',
                        align: 'center',
                        verticalAlign: 'bottom',
                        floating: false
                    },

                    plotOptions: {
                        series: {
                            label: {
                                connectorAllowed: false
                            },
                            pointStart: 2010
                        }
                    },

                    series: [{
                        name: 'Total',
                        data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
                    }, {
                        name: 'Cost per Yard',
                        data: [5, 2.2, 4.3, 6.6, 3.4, 4.4, 4.4, 4.4]
                    }, {
                        name: 'Cost Per Pound',
                        data: [35, 32.2, 34.3, 36.6, 33.4, 34.4, 34.4, 34.4]
                    }],

                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom'
                                }
                            }
                        }]
                    }

                });

            }
        },

        watch: {
            loading: function(value) {
                if (this.chart) {
                    value ? this.chart.showLoading() : this.chart.hideLoading();
                }

                if (!value) {
                    this.drawChart();
                }
            }
        },
    }
</script>