<template>
    <Head :title="`Gathered Vendor Invoice File - ${gatheredVendorInvoiceFile.document_name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('gathered-vendor-invoice-files.index')" class="breadcrumb-link">Gather - Vendor Invoices</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ gatheredVendorInvoiceFile.document_name }}</span>
        </nav>
    </Teleport>

    <div class="flex flex-wrap justify-between items-center my-4">
        <horizontal-sub-nav
            :permissions="$page.props.permissions"
            current-tab="gathered-invoice"
            :vendor-invoice-id="ocrVendorInvoice?.vendorInvoice?.id ?? null"
            :ocr-vendor-invoice-id="ocrVendorInvoice?.id ?? null"
            :gathered-vendor-invoice-file-id="gatheredVendorInvoiceFile.id"
        />
    </div>

    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div class="flex-shrink-0">
                <icon name="file-alt" class="h-14 w-14" />
            </div>

            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ gatheredVendorInvoiceFile.document_name }}
                </h1>

                <p class="text-sm font-medium text-gray-500">
                    <span class="text-gray-900">Gathered Vendor Invoice File</span> added <time :datetime="gatheredVendorInvoiceFile.created_at">{{ $filters.format_date_time(gatheredVendorInvoiceFile.created_at) }}</time><br>
                    <span v-if="gatheredVendorInvoiceFile.archived_at">
                        Archived at {{ $filters.format_date_time(gatheredVendorInvoiceFile.archived_at) }}
                        <span v-if="gatheredVendorInvoiceFile.archivedBy"> by {{ gatheredVendorInvoiceFile.archivedBy.name }}</span>
                    </span>
                </p>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
	        <loading-button v-if="$page.props.permissions.extractVendorInvoices" :loading="state === 'extracting'" :disabled="!fileCanBeExtracted" @click.prevent="extractGatheredVendorInvoiceFile" class="btn btn-orange">
		        Extract
	        </loading-button>
            <loading-button v-if="!gatheredVendorInvoiceFile.archived_at" :loading="state === 'archiving'" @click.prevent="archiveGatheredVendorInvoiceFile" class="btn btn-gray">
                Archive
            </loading-button>

            <loading-button v-else :loading="state === 'unarchiving'" @click.prevent="unarchiveGatheredVendorInvoiceFile" class="btn btn-gray">
                Unarchive
            </loading-button>

            <loading-button v-if="invoiceCanBeDeleted"  :loading="state === 'deleting'" @click.prevent="deleteGatheredVendorInvoiceFile" class="btn btn-red">
                Delete
            </loading-button>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Gathered Vendor Invoice File details and records.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Document Name</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ gatheredVendorInvoiceFile.document_name }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Status</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ gatheredVendorInvoiceFile.status }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Document Size</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_file_size(gatheredVendorInvoiceFile.document_size) }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Batch Name</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ gatheredVendorInvoiceFile.batch_name }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Source</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ gatheredVendorInvoiceFile.source }}</dd>
                            </div>

                            <div class="sm:col-span-1" v-if="gatheredVendorInvoiceFile.source === 'File Upload'">
                                <dt class="text-sm font-medium text-gray-500">Uploaded By</dt>
                                <dd v-if="gatheredVendorInvoiceFile.uploadedBy">
                                    <img v-if="gatheredVendorInvoiceFile.uploadedBy.avatar_thumbnail_url" :src="gatheredVendorInvoiceFile.uploadedBy.avatar_thumbnail_url" class="inline border border-gray-400 rounded-full mr-4 h-10 w-10"  :alt="gatheredVendorInvoiceFile.uploadedBy.name"/>

                                    <span v-else class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-400 mr-2">
                                        <span class="text-lg font-medium leading-none text-white uppercase">{{ gatheredVendorInvoiceFile.uploadedBy.first_name[0] }}{{ gatheredVendorInvoiceFile.uploadedBy.last_name[0] }}</span>
                                    </span>
                                    <span>{{ gatheredVendorInvoiceFile.uploadedBy.name }}</span>
                                </dd>
                                <dd v-else></dd>
                            </div>

                            <div class="sm:col-span-1" v-if="gatheredVendorInvoiceFile.source === 'Email'">
                                <dt class="text-sm font-medium text-gray-500">Email Address</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ email.from }}</dd>
                            </div>

                            <div class="sm:col-span-1" v-if="gatheredVendorInvoiceFile.source === 'Vendor Portal'">
                                <dt class="text-sm font-medium text-gray-500">Rover Account</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link class="link" :href="$route('vendor-portal-accounts.show', gatheredVendorInvoiceFile.vendorPortalInvoice.vendor_portal_account_id)"> {{ gatheredVendorInvoiceFile.vendorPortalInvoice.vendorPortalAccount.account_number }}</inertia-link>
                                </dd>
                            </div>
                            <div class="sm:col-span-2">
                                <dt class="text-sm font-medium text-gray-500">Attachments</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                                        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                            <div class="w-0 flex-1 flex items-center">
                                                <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                                                </svg>
                                                <span class="ml-2 flex-1 w-0 truncate"> {{ gatheredVendorInvoiceFile.document_name }}
                                                </span>
                                            </div>
                                            <div class="ml-4 flex-shrink-0">
                                                <a :href="$route('documents.preview', { path: gatheredVendorInvoiceFile.document_path })" class="font-medium link" target="_blank">Download</a>
                                            </div>
                                        </li>
                                    </ul>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from "@/Shared/LoadingButton.vue";
    import HorizontalSubNav from '@/Shared/Audit/HorizontalSubNav.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            LoadingButton,
            HorizontalSubNav,
            Head,
        },

        props: {
            gatheredVendorInvoiceFile: {
                type: Object,
                required: true
            },

            invoiceCanBeDeleted: {
                type: Boolean,
                required: true
            },

            fileCanBeExtracted: {
                type: Boolean,
                required: true
            },

            ocrVendorInvoice: Object,

            errors: {
                type: Object,
                default: () => ({})
            },
            email: {
                type: Object,
                required: false
            }
        },

        data() {
            return {
                state: 'passive',
                mounted: false,
            };
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            archiveGatheredVendorInvoiceFile() {
                if (this.state !== 'passive' || this.gatheredVendorInvoiceFile.archived_at) {
                    return;
                }

                this.state = 'archiving';

                this.$inertia
                    .post(this.$route('gathered-vendor-invoice-files.archive', this.gatheredVendorInvoiceFile.id), {}, {
                        preserveScroll: true,
                        onFinish: () => {
                            this.state = 'passive';
                        }
                    });
            },

            unarchiveGatheredVendorInvoiceFile() {
                if (this.state !== 'passive' || !this.gatheredVendorInvoiceFile.archived_at) {
                    return;
                }

                this.state = 'unarchiving';

                this.$inertia.post(this.$route('gathered-vendor-invoice-files.unarchive', this.gatheredVendorInvoiceFile.id), {}, {
                    preserveScroll: true,
                    onFinish: () => {
                        this.state = 'passive';
                    }
                });
            },

            extractGatheredVendorInvoiceFile() {
                if (this.state !== 'passive' || !this.fileCanBeExtracted) {
                    return;
                }

                this.state = 'extracting';

                this.$inertia.post(this.$route('gathered-vendor-invoice-files.extract', this.gatheredVendorInvoiceFile.id), {}, {
                    preserveScroll: true,
                    onFinish: () => {
                        this.state = 'passive';
                    }
                });
            },

            deleteGatheredVendorInvoiceFile() {
                if (this.state !== 'passive' || !this.invoiceCanBeDeleted) {
                    return;
                }

                if (window.confirm("Are you sure that you want to delete this invoice? NOTE: This will remove the GATHERED, EXTRACTED and AUDITED vendor invoice!")) {

                    this.state = 'deleting';

                    this.$inertia.delete(this.$route('gathered-vendor-invoice-files.destroy', this.gatheredVendorInvoiceFile.id), {}, {
                        preserveScroll: true,
                        onFinish: () => {
                            this.state = 'passive';
                        }
                    });
                }
            },
        },
    }
</script>