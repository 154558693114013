<template>
    <canvas class="pdf-page" ref="pdfRootEl" v-bind="canvasAttrs"></canvas>
</template>
<script setup>
    import {ref, watch, computed, onMounted, onBeforeUnmount, toRaw} from 'vue';

    /** -- Incoming Properties -- **/
    const props = defineProps({
        /** @type {PDFPageProxy} */
        page: {
            type: Object,
            required: true,
        },
        scale: {
            type: Number,
            required: true,
        },
    });

    /** -- Things for the page -- **/
    const pdfRootEl = ref(null);
    const pdfViewport = ref(null);
    const pdfPage = toRaw(props.page);
    const renderTask = ref(null);

    /** -- Computed Values -- **/
    const actualSizeViewport = computed(() => {
        return getViewportSize();
    });

    const canvasStyle = computed(() => {
        const {width: actualSizeWidth, height: actualSizeHeight} = actualSizeViewport.value;
        const pixelRatio = window.devicePixelRatio || 1;
        const [pixelWidth, pixelHeight] = [actualSizeWidth, actualSizeHeight].map(dim => Math.ceil(dim / pixelRatio));
        return `width: ${pixelWidth}px; height: ${pixelHeight}px;`
    });

    const canvasAttrs = computed(() => {
        let {width, height} = pdfViewport.value;
        [width, height] = [width, height].map(dim => Math.ceil(dim));
        const style = canvasStyle.value;
        return {width, height, style};
    });

    const pageNumber = computed(() => pdfPage.pageNumber);

    /** -- Lifecycle Hooks -- **/
    onMounted(() => {
        renderPage();
    });
    onBeforeUnmount(() => {
        destroyPage(pdfPage);
    });

    /** -- Watchers -- **/
    watch(() => pdfPage, (page, oldPage) => {
        destroyPage(oldPage);
    });

    watch(() => props.scale, () => {
        renderPage();
    });

    /** -- Methods -- **/
    // See: PDFPageProxy#render
    function renderPage() {
        if(renderTask.value) return;

        renderTask.value = pdfPage.render({
            canvasContext: pdfRootEl.value.getContext('2d'),
            viewport: pdfViewport.value
        });
    }

    // See: PDFPageProxy#_destroy
    // See: RenderTask#cancel
    function destroyPage(page) {
        if (!page) return;
        page._destroy();

        if (renderTask.value) {
            toRaw(renderTask.value).cancel();
            renderTask.value = null;
        }
    }

    // See: PDFPageProxy#getViewport
    function getViewportSize() {
        return pdfPage.getViewport({ scale: props.scale });
    }

    /** -- Run things similar to the old "created()" lifecycle hook -- **/
    pdfViewport.value = getViewportSize()
</script>

<style>
.pdf-page {
    display: block;
}
</style>