import { createApp, h } from 'vue'
import { createInertiaApp, Link } from '@inertiajs/vue3'
import VueTippy from 'vue-tippy';
import VueToast from 'vue-toast-notification';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import * as Sentry from "@sentry/vue";
import { route as productionRoute } from 'ziggy-js';
import { Ziggy as routes } from './ziggy';
import { filters } from './Shared/Utils/Filters';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import Layout from './Shared/Layout.vue';
import '../css/app.css';
import 'tippy.js/dist/tippy.css' // optional for styling

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT,
    wssPort: import.meta.env.VITE_REVERB_PORT,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    disableStats: true,
});

const appName = import.meta.env.VITE_APP_NAME || 'Discovery';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', {eager: true})
        let page = pages[`./Pages/${name}.vue`].default
        page.layout = page.layout || Layout
        return page
    },
    progress: {
        color: '#fc521d'
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin);

        app.use(VueTippy);

        app.use(VueToast, {
            position: 'top'
        });

        app.use(VueGoogleMaps, {
            load: {
                v: 'weekly',
                key: import.meta.env.VITE_GOOGLE_API_KEY,
                libraries: "places"
            }
        });

        let pageAbilities = {};
        let globalAbilities = {};
        app.config.globalProperties.$setPageAbilities = function(abilities) {
            pageAbilities = {...abilities};
        };
        app.config.globalProperties.$setGlobalAbilities = function(abilities) {
            globalAbilities = {...abilities};
        };
        app.config.globalProperties.$getPageAbilities = function(abilities) { return pageAbilities };
        app.config.globalProperties.$getGlobalAbilities = function(abilities) { return globalAbilities };
        app.component('InertiaLink', Link);
        app.config.globalProperties.$filters = filters;
        app.config.globalProperties.$can = (ability, model) => {
            let modelAbilities = model?.abilities ?? {};
            let abilities = {...globalAbilities, ...pageAbilities, ...modelAbilities};
            return abilities[ability] ?? false;
        };
        app.config.globalProperties.$cannot = (ability, model) => {
            return ! app.config.globalProperties.$can(ability, model);
        };
        app.config.globalProperties.window = window;
        app.config.globalProperties.$filterErrorsByPrefix = function (errors, prefix) {
            return Object.keys(errors)
                .filter(key => key.startsWith(prefix))
                .reduce((errors, key) => {
                    let keyName = key.split('.')[1];
                    errors[keyName] = this.errors[key];

                    return errors;
                }, {});
        }

        app.provide('toast', app.config.globalProperties.$toast);
        app.provide('getPageAbilities', app.config.globalProperties.$getPageAbilities);
        app.provide('setPageAbilities', app.config.globalProperties.$setPageAbilities);
        app.provide('getGlobalAbilities', app.config.globalProperties.$getGlobalAbilities);
        app.provide('setGlobalAbilities', app.config.globalProperties.$setGlobalAbilities);
        app.provide('can', app.config.globalProperties.$can);
        app.provide('cannot', app.config.globalProperties.$cannot);

        if (process.env.NODE_ENV !== 'production') {
            // The route() function is loaded in app.blade.php if app.env === local
            app.config.globalProperties.$route = window.route;  // Still the best way to always make these globally available in all templates.
            app.provide('route', window.route)
        } else {
            // For prod, manually set the imported router and add our imported routes to it.
            app.config.globalProperties.$route = (name, params, absolute) => productionRoute(name, params, absolute, routes);
            app.provide('route', (name, params, absolute) => productionRoute(name, params, absolute, routes));
        }

        Sentry.init({
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,             // we want to see what was done
                    blockAllMedia: false,           // and we generally don't have confidential information
                })
            ],
            logErrors: true,
            trackComponents: true,
            tracesSampleRate: import.meta.env.SENTRY_TRACE_SAMPLE_RATE || 0.1,

            // try this out later...
            // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

            // This sets the sample rate to be 10%. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate: import.meta.env.SENTRY_REPLAY_SESSION_SAMPLE_RATE || 0.1,

            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs. We want to see what happened on an error
            replaysOnErrorSampleRate: 1.0,
        });

        return app.mount(el);
    },
});
