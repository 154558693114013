<template>
    <Head title="Create Location" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('locations.index')" class="breadcrumb-link">Locations</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <form @submit.prevent="submitForm" id="create-location-form" class="space-y-8">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Information</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Location details.</p>
                </div>
            </legend>

            <div class="grid grid-cols-6 gap-y-6 gap-x-4">
                <text-input id="name" v-model="form.name" class="col-span-6 xs:col-span-4 md:col-span-2" placeholder="name" label="Name" :errors="errors.name" mark-as-required />

                <search-input @selected="selectClientCompany" label="Client Company" v-model="form.clientCompanyName" class="relative col-span-6 xs:col-span-4 md:col-span-2" :errors="errors.client_company_id" route="json.client-companies.index" value-property="id" display-property="name" mark-as-required>
                    <template #default="obj">
                        <span class="block">{{ obj.result.name }}</span>
                    </template>
                </search-input>

                <search-input
                    :disabled="!form.client_company_id"
                    @selected="selectClientAccount"
                    label="Client Account"
                    v-model="form.clientAccountName" class="relative col-span-6 xs:col-span-4 md:col-span-2" :errors="errors.client_account_id" @search="searchClientAccounts" value-property="id" display-property="name">
                    <template #default="obj">
                        <span class="block">{{ obj.result.name }}</span>
                    </template>
                </search-input>

                <select-input v-model="form.property_type" class="col-span-6 xs:col-span-4 md:col-span-2" placeholder="Property Type" label="Property Type" :errors="errors.property_type" mark-as-required>
                    <option v-for="propertyType in propertyTypes" :key="propertyType" :value="propertyType">{{ propertyType }}</option>
                </select-input>

                <text-input
                    v-model="form.external_reference_id"
                    label="External Reference Id"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.external_reference_id"
                />

                <address-picker v-model="form.address" class="col-span-6" label="Address" :errors="$filterErrorsByPrefix(errors, 'address')" />
            </div>
        </fieldset>

        <fieldset v-if="metaFields.length > 0" class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Metadata</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Location metadata.</p>
                </div>
            </legend>

            <table class="my-4 table table-striped">
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="field in metaFields" :key="field.key">
                        <td class="capitalize">{{ field.key.replace(/_/g, ' ') }}</td>
                        <td v-if="field.options">
                            <select-input :errors="errors[`metadata.${field.key}`]"  v-model="form.metadata[field.key]">
                                <option value="">Not Selected</option>
                                <option v-for="option in field.options" :key="option" :value="option">{{ option }}</option>
                            </select-input>
                        </td>
                        <td v-else>
                            <text-input :type="field.type" :errors="errors['metadata.' + field.key]" v-model="form.metadata[field.key]" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </fieldset>

        <div>
            <loading-button :loading="state === 'saving'" class="btn btn-orange">Save</loading-button>
        </div>
    </form>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import AddressPicker from '@/Shared/AddressPicker.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import axios from "axios";
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            AddressPicker,
            LoadingButton,
            SelectInput,
            TextInput,
            SearchInput,
            Head,
        },

        props: {
            propertyTypes: {
                type: Array,
                required: true
            },

            metaFields: {
                type: Array,
                required: true,
            },

            errors: {
                type: Object,
                default: () => ({})
            }
        },

        data() {
            return {
                form: {
                    metadata: {},
                    clientCompanyName: null,
                    clientAccountName: null
                },
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            selectClientCompany(clientCompany) {
                this.form.client_company_id = clientCompany?.id;
                this.form.clientCompanyName = clientCompany?.name;
            },

            searchClientAccounts(query, updateSearchResults) {
                axios.get(this.$route('json.client-accounts.index', { search: query.toLowerCase(), client_company_id: this.form.client_company_id })).then(response => {
                    updateSearchResults(response.data.data);
                })
            },

            selectClientAccount(clientAccount) {
                this.form.client_account_id = clientAccount?.id;
                this.form.clientAccountName = clientAccount?.name;
            },

            submitForm() {
                if (this.state === 'passive') {
                    this.state = 'saving';

                    this.$inertia.post(this.$route('locations.store'), this.form, {
                        onFinish: () => this.state = 'passive',
                    });
                }
            },
        }
    }
</script>