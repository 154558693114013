<template>
    <Head title="Accounting Exports - QB Desktop" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.quickbooks-desktop.accounting-exports.index')" class="breadcrumb-link">Quickbooks Desktop</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Exports (iif)</span>
        </nav>
    </Teleport>

    <main class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="quickbooks-desktop" />

                <div class="lg:col-span-9 px-4 sm:px-6">
                    <horizontal-sub-nav current-tab="exports_iif" />

                    <div class="flex my-4 items-center">
                        <div class="sm:flex sm:items-center">
                            <div class="sm:flex-auto">
                                <h1 class="text-xl font-semibold text-gray-900">Exports</h1>
                                <p class="mt-2 text-sm text-gray-700">
                                    Manage your IIF exports from Discovery to Quickbooks Desktop for vendor invoices/credits and journal entries.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div v-if="accountingExports.data.length" class="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                        <div class="overflow-x-auto">
                            <table class="table table-condensed">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                            Status
                                        </th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                                            Created By
                                        </th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            File name
                                        </th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            File size
                                        </th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Number of records
                                        </th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Last Downloaded
                                        </th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Created At
                                        </th>
                                        <th scope="col" class="px-1 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        </th>
                                        <th scope="col" class="px-1 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        </th>
                                    </tr>
                                </thead>

                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr v-for="accountingExport in accountingExports.data" :key="accountingExport.id">
                                            <td>{{ accountingExport.status }}</td>
                                            <td>{{ accountingExport.createdByEmployee.first_name }} {{ accountingExport.createdByEmployee.last_name }}</td>
                                            <td>
                                                <span v-if="accountingExport.file_path">
                                                    {{ basename(accountingExport.file_path)}}
                                                </span>
                                            </td>
                                            <td>{{ accountingExport.file_size ?? '-' }}</td>
                                            <td>{{ accountingExport.number_of_records ?? '-' }}</td>
                                            <td v-if="accountingExport.lastDownloadedByEmployee">{{ accountingExport.lastDownloadedByEmployee.first_name }} {{ accountingExport.lastDownloadedByEmployee.last_name }} @ {{ $filters.format_date_time(accountingExport.last_downloaded_at) }}</td>
                                            <td v-else>-</td>
                                            <td>{{ $filters.format_date_time(accountingExport.created_at) }}</td>
                                            <td>
                                                <a v-if="accountingExport.status === 'Completed'" :href="$route('tenant-settings.quickbooks-desktop.accounting-exports.show', accountingExport.id)" class="text-blue-600 hover:text-blue-900 inline">
                                                    <icon class="h-4 fill-current" name="download-arrow"></icon>
                                                </a>
                                          </td>
                                          <td>
                                              <a :class="{'text-red-600': accountingExport.status === 'Failed', 'hover:text-red-900':  accountingExport.status === 'Failed'}" class="text-blue-600 hover:text-blue-900 inline" href="#" @click="showNotesModal(accountingExport)">
                                                  <icon class="h-4 fill-current" name="sticky-note"></icon>
                                              </a>
                                          </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination :links="accountingExports.links" />
                    </div>

                    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                        <icon name="file-invoice" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Exports Found</span>
                    </div>

                </div>
            </div>
        </div>
    </main>


    <!-- Show Notes Modal !-->
    <modal role="dialog" ref="notesModal" class="max-w-2xl" @closed="emptyModal">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon aria-hidden="true" name="times" class="w-6 h-6 text-gray-500" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="p-4">
                <form>
                    <textarea-input label="Notes" v-model="selectedAccountingExport.notes"></textarea-input>
                </form>
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4">
                <div class="inline-block mr-2">
                    <button type="button" class="btn btn-gray" @click="close">Close</button>
                </div>

                <div class="inline-block">
                    <loading-button :loading="state === 'syncing'" class="inline btn btn-blue" @click="updateNotes">
                        Update
                    </loading-button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SubNav from '../SubNav.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import Modal from '@/Shared/Modal.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import { Head } from '@inertiajs/vue3';
    import HorizontalSubNav from "./HorizontalSubNav.vue";

    export default {
        components: {
          HorizontalSubNav,
            SubNav,
            Pagination,
            Icon,
            LoadingButton,
            SelectInput,
            Modal,
            TextareaInput,
            Head,
        },

        props: {
            accountingExports: {
                type: Object,
                required: true
            }
        },

         data() {
            return {
                state: 'passive',
                selectedAccountingExport: null,
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            showNotesModal(accountingExport) {
                this.selectedAccountingExport = accountingExport;
                this.$refs.notesModal.show();
            },

            basename(path) {
                return path.split('/').reverse()[0];
            },

            updateNotes() {
                if (this.state === 'passive') {
                    this.state = 'syncing';

                    this.$inertia
                        .put(this.$route('tenant-settings.quickbooks-desktop.accounting-exports.update', this.selectedAccountingExport.id), {notes: this.selectedAccountingExport.notes}, {
                            onFinish: () => { this.state = 'passive'; this.$refs.notesModal.close(); }
                        });
                }
            }
        }
    }
</script>