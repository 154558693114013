<style>
    
</style>

<template>
    <div class="cursor-pointer">
        <slot>
            <input 
                v-show="editing == true" 
                type="number" 
                class="form-input" 
                :min="min" step="0.01" 
                pattern="^\d*(\.\d{0,2})?$" 
                :value="modelValue.amount" 
                :disabled="disabled" 
                @keyup.enter="$event.target.blur()" 
                @blur="updateField($event)" 
                ref="inlineInput"
            >

            <span v-show="!editing" @click="toggleEdit($event)">{{ displayValue }}</span>
        </slot>
    </div>
</template>

<script setup>
    import { filters } from '@/Shared/Utils/Filters.js';
    import { computed, ref, watch, nextTick } from 'vue';
    import { v4 as uuid } from 'uuid';

    const emit = defineEmits(['update:modelValue']);

    /**
     * Props
     */
    const props = defineProps({
        id: {
            type: String,
            default() {
                return `inline-text-input-${uuid()}`;
            },
        },

        type: {
            type: String,
            default: 'text',
        },

        modelValue: Object,

        label: String,

        helpText: {
            type: String,
            default: ''
        },

        errors: {
            type: String,
            default: '',
        },

        min: {
            type: Number,
            default: 0
        },

        required: {
            default: true
        },

        filters: {
            type: Array,
            default: [],
        },

        sanitizers: {
            type: Array,
            default: [],
        },

        disabled: {
            type: Boolean,
            default: false
        },
    });

    /**
     * State
     */
    const editing = ref(false);
    const inlineInput = ref(null);
        
    /**
     * Methods
     */
    function updateField(event) {
        if (isValid(event.target.value)) {
            let amount = props.sanitizers.reduce((carry, sanitizer) => {
                carry = sanitizer(carry);

                return carry;
            }, event.target.value);

            emit('update:modelValue', {
                amount: amount,
                currency: props.modelValue.currency
            });
        }

        toggleEdit();
    }

    function isValid(value) {
        if (props.required == true && !value) {
            return false;
        }

        return true;
    }

    function toggleEdit() {
        editing.value = !editing.value;

        if (editing.value) {
            nextTick(() => {
                inlineInput.value.focus();
            })
        }
    }

    /**
     * Computed
     */
    const displayValue = computed(() => {
        return props.filters.reduce((carry, filter) => {
            carry = filters[filter](carry);

            return carry;
        }, props.modelValue);
    });
</script>