let statusComboDisplayValue = function(option) {
    if (Array.isArray(option)) {
        if (option.length > 1) {
            return `${option.length} filters selected`;
        } else if (option.length === 1) {
            return '1 filter selected';
        } else {
            return 'No filters selected';
        }
    }

    return option ?? 'No filters selected'; 
};

export {statusComboDisplayValue};