<style></style>

<template>
    <div class="p-2">
        <h3 class="my-3 p-1 w-full text-d-navy-500 font-medium text-xl">{{ title }}</h3>

        <div class="">
            <div id="ocr-vendor-invoices-by-status-chart-container">

            </div>
        </div>
    </div>
</template>

<script>
    import Highcharts from 'highcharts';

    Highcharts.setOptions({
        lang: {
          decimalPoint: '.',
          thousandsSep: ','
        }
    });

    export default {
        props: {
            title: {
                type: String,
                default: 'OCR Vendor Invoices by Status'
            },

            series: {
                type: Array,
                required: true
            },
        },

        mounted() {
            this.drawChart();
        },

        data()  {
            return {
                seriesData: null,
            }
        },

        methods: {
            getSeriesData() {
                return Object.keys(this.series).map((key) => {
                    return {
                        name: this.series[key].status,
                        y: this.series[key].count,
                        sliced: true,
                    }
                });
            },
            drawChart() {
	            Highcharts.setOptions({
		            colors: ['#012639', '#075076', '#5fa3ec', '#fc9776', '#f4c326']
	            });
                Highcharts.chart('ocr-vendor-invoices-by-status-chart-container', {
                    chart: {
                        type: 'pie',
                    },
                    title: {
                        text: null
                    },

                    // xAxis: {
                    //     categories: this.dateRange.map(date => format(date, 'yyyy-MM-dd'))
                    // },

                    yAxis: {
                        min: 0,
                        title: {
                            text: null
                        },
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold',
                                color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                            },
                            format: "${total:,.2f}"
                        }
                    },

                    // legend: {
                    //     enabled: false
                    // },

                    tooltip: {
                        pointFormatter() {

                            let increaseOverPrevious = 0
                            if (this.series.data[this.x - 1]) {
                                increaseOverPrevious = this.y - this.series.data[this.x - 1].y;
                                if (increaseOverPrevious > 0) {
                                    increaseOverPrevious = '<span class="text-green-500">+' + increaseOverPrevious + '</span>';
                                } else if (increaseOverPrevious < 0) {
                                    increaseOverPrevious = '<span class="text-red-500">' + increaseOverPrevious + '</span>';
                                } else {
                                    increaseOverPrevious = ''
                                }
                            }
                            return 'Count: ' + this.y;
                        }
                    },

                    series: [{
                        name: 'Statuses',
                        data: this.getSeriesData()
                    }]

                });
            }
        },

        watch: {
            series: {
                handler(val) {
                    this.drawChart();
                },
                deep: true
            }
        }
    }
</script>