<template>
    <Head :title="`Edit Line Item Category - ${lineItemCategory.display_name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.edit')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.line-item-categories.index')" class="breadcrumb-link">Line Item Categories</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ lineItemCategory.display_name }}</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <form class="container mx-auto my-4" @submit.prevent="submitForm">
        <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 mt-2 mb-4">
            <text-input v-model="form.name" label="Name" :errors="errors.name" />

            <text-input v-model="form.display_name" label="Display Name" :errors="errors.display_name" />

            <select-input v-model="form.cost_gl_code" label="Cost GL Account" :errors="errors.cost_gl_code">
                <option v-for="account in accountsList" :key="account.id" :value="account.external_accounting_id">{{ account.name }}</option>
            </select-input>

            <select-input v-model="form.revenue_gl_code" label="Revenue GL Account" :errors="errors.revenue_gl_code">
                <option v-for="account in accountsList" :key="account.id" :value="account.external_accounting_id">{{ account.name }}</option>
            </select-input>

            <text-input v-model="form.external_reference_id" label="External Reference Id" :errors="errors.external_reference_id" />
        </div>

        <div class="flex mt-6">
            <inertia-link :href="$route('tenant-settings.line-item-categories.index')" class="btn btn-gray mr-4">
                Cancel
            </inertia-link>

            <loading-button type="submit" :loading="saving" class="btn btn-orange">Save</loading-button>
        </div>
    </form>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';

    export default {
        components: {
            Head,
            Icon,
            LoadingButton,
            SelectInput,
            TextInput
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            lineItemCategory: {
                type: Object,
                required: true
            },

            accountsList: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {...this.lineItemCategory},
                saving: false,
                mounted: false
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm() {
                this.saving = true;

                this.$inertia.put(this.$route('tenant-settings.line-item-categories.update', this.lineItemCategory.id), this.form, {
                    onFinish: () => { this.saving = false; },
                    onSuccess: () => { this.$inertia.visit(this.$route('tenant-settings.line-item-categories.index')) }
                });
            }
        },
    }
</script>