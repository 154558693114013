<template>
    <div class="flex flex-wrap" :class="$attrs.class">
        <span v-if="markAsRequired" class="text-red-500 mr-2">*</span>
        
        <input :id="id" ref="input" v-bind="{...$attrs, class: null}" class="form-checkbox mr-2" :checked="checked" :class="{ error: errors }" type="checkbox" @input="(event) => $emit('update:checked', event.target.checked)">
        
        <label v-if="label" class="form-label mr-2" :for="id">{{ label }}</label>
        
        <div v-if="errors" class="form-error">{{ errors }}</div>
    </div>
</template>

<script>
    import { v4 as uuid } from 'uuid';

    export default {
        inheritAttrs: false,

        props: {
            id: {
                type: String,
                default() {
                    return `checkbox-input-${uuid()}`;
                },
            },

            checked: Boolean,

            label: String,

            errors: {
                type: String,
                default: '',
            },

            markAsRequired: {
                type: Boolean,
                default: false
            },
        },

        methods: {
            focus() {
                this.$refs.input.focus()
            },

            select() {
                this.$refs.input.select()
            },

            setSelectionRange(start, end) {
                this.$refs.input.setSelectionRange(start, end)
            },
        },
    }
</script>
