<template>
    <div class="min-h-screen" :class=" {'border-4': $page.props.impersonatingEmployee, 'border-pink-600': $page.props.impersonatingEmployee }" @keyup.esc="mobileSidebarOpen = false">

        <div v-if="isInactive && !isBillingDashboard" class="w-full bg-red-600 text-white">
            <div class="py-3 text-center">
                <p class="text-xl">Your account is inactive. Please visit your <inertia-link :href="route('tenant-settings.billing-dashboard.index')" class="underline">billing dashboard</inertia-link> to reactivate.</p>
            </div>
        </div>

        <nav class="block bg-d-navy-500 print:bg-transparent print:text-transparent">
            <div class="flex items-center justify-between">
                <nav class="w-full flex items-center justify-between lg:w-auto">
                    <inertia-link href="/">
	                    <logo class="w-10 h-10 mx-2 block print:hidden" name="mark-white" />
                    </inertia-link>

                    <inertia-link href="/" class="hidden font-bold p-0 text-white flex-shrink-0 xl:inline-block">{{ $page.props.authUser.tenant_name }}</inertia-link>
                    <global-search-bar class="lg:hidden block w-full ml-3 lg:ml-0 print:hidden" />

                    <div class="flex-shrink-0 lg:hidden">
                        <button @click="mobileSidebarOpen = true" class="h-16 rounded text-white hover:text-white focus:text-white focus:outline-none px-2 py-4">
                            <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h7" />
                            </svg>

                            <span v-if="unreadNotificationsCount > 0" class="absolute top-4 -right-0.5 inline-flex items-center px-1 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                <span v-if="unreadNotificationsCount < 10">
                                    {{ unreadNotificationsCount }}
                                </span>

                                <span v-else>
                                    9+
                                </span>
                            </span>
                        </button>
                    </div>
                </nav>
                <nav class="hidden font-medium lg:flex lg:flex-wrap xl:mx-3 z-50">
                    <dropdown-link open-class="bg-d-navy-700 print:hidden" v-if="showRecordsNavigation">
                        <template #link>
                            <span data-cy="layout.nav.menu.records" id="layout-nav-menu-records" class="flex items-center font-medium px-4 py-3 text-white hover:text-d-yellow-500">
                                <span class="mx-1">Records</span>
                                <icon name="caret-down" class="inline h-4 w-4 fill-current" />
                            </span>
                        </template>

                        <template #dropdown>
                            <div class="dropdown w-40">
                                <inertia-link data-cy="layout.nav.item.incidents" id="layout-nav-item-incidents" v-if="$page.props.permissions.accessIncidents" :href="route('incidents.index')" class="dropdown-link">
                                    <span class="mr-2">Incidents</span>
                                </inertia-link>

                                <inertia-link data-cy="layout.nav.item.pickups" id="layout-nav-item-pickups" v-if="$page.props.permissions.accessPickups" :href="route('pickups.index')" class="dropdown-link">
                                    <span class="mr-2">Pickups</span>
                                </inertia-link>

                                <inertia-link data-cy="layout.nav.item.work-orders" id="layout-nav-item-work-orders" v-if="$page.props.permissions.accessWorkOrders" :href="route('work-orders.index')" class="dropdown-link">
                                    <span class="mr-2">Work Orders</span>
                                </inertia-link>

                                <div class="border-gray-200 border-t border-b h-0"></div>

                                <inertia-link data-cy="layout.nav.item.client-companies" id="layout-nav-item-client-companies" v-if="$page.props.permissions.accessClientCompanies" :href="route('client-companies.index')" class="dropdown-link">
                                    <span class="mr-2">Companies</span>
                                </inertia-link>

                                <inertia-link data-cy="layout.nav.item.locations" id="layout-nav-item-locations" v-if="$page.props.permissions.accessLocations" :href="route('locations.index')" class="dropdown-link">
                                    <span class="mr-2">Locations</span>
                                </inertia-link>

                                <inertia-link data-cy="layout.nav.item.services" id="layout-nav-item-services" v-if="$page.props.permissions.accessServices" :href="route('services.index')" class="dropdown-link">
                                    <span class="mr-2">Services</span>
                                </inertia-link>

                                <inertia-link data-cy="layout.nav.item.vendors" id="layout-nav-item-vendors" v-if="$page.props.permissions.accessVendors" :href="route('vendors.index')" class="dropdown-link">
                                    <span class="mr-2">Vendors</span>
                                </inertia-link>

                                <div class="border-gray-200 border-t border-b h-0"></div>

                                <inertia-link data-cy="layout.nav.item.compactor-monitors" id="layout-nav-item-compactor-monitors" v-if="$page.props.permissions.accessCompactorMonitors" :href="route('compactor-monitors.index')" class="dropdown-link">
                                    <span class="mr-2">Monitors</span>
                                </inertia-link>

                                <inertia-link data-cy="layout.nav.item.reports" id="layout-nav-item-reports" v-if="$page.props.permissions.accessReports" :href="route('reports.index')" class="dropdown-link">
                                    <span class="mr-2">Reports</span>
                                </inertia-link>
                            </div>
                        </template>
                    </dropdown-link>
                    <dropdown-link open-class="bg-d-navy-700" v-if="$page.props.permissions.accessVendorInvoices || $page.props.permissions.accessRevenueManagement">
                        <template #link>
                            <span data-cy="layout.nav.menu.invoicing" id="layout-nav-menu-invoicing" class="flex items-center font-medium px-4 py-3 text-white hover:text-d-yellow-500">
                                <span class="mx-1">Invoicing</span>
                                <icon name="caret-down" class="inline h-4 w-4 fill-current" />
                            </span>
                        </template>

                        <template #dropdown>
                            <div class="dropdown w-[11rem]">
                                <inertia-link data-cy="layout.nav.item.gathered-vendor-invoice-files" id="layout-nav-item-gathered-vendor-invoice-files" v-if="$page.props.permissions.gatherVendorInvoices" :href="route('gathered-vendor-invoice-files.index')" class="dropdown-link">
                                    <span class="mr-2">Gather</span>
                                </inertia-link>

                                <inertia-link data-cy="layout.nav.item.ocr-vendor-invoices" id="layout-nav-item-ocr-vendor-invoices" v-if="$page.props.permissions.extractVendorInvoices" :href="route('ocr-vendor-invoices.index')" class="dropdown-link">
                                    <span class="mr-2">Extract</span>
                                </inertia-link>

                                <inertia-link data-cy="layout.nav.item.vendor-invoices" id="layout-nav-item-vendor-invoices" v-if="$page.props.permissions.auditVendorInvoices" :href="route('vendor-invoices.index')" class="dropdown-link">
                                    <span class="mr-2">Audit</span>
                                </inertia-link>

                                <inertia-link data-cy="layout.nav.item.client-invoices" id="layout-nav-item-client-invoices" v-if="$page.props.permissions.accessClientInvoices" :href="route('client-invoices.index')" class="dropdown-link">
                                    <span class="mr-2">Revenue</span>
                                </inertia-link>

                                <div v-if="$page.props.permissions.accessVendorAccounts" class="border-gray-200 border-t border-b h-0"></div>

                                <inertia-link data-cy="layout.nav.item.vendor-accounts" id="layout-nav-item-vendor-accounts" v-if="$page.props.permissions.accessVendorAccounts" :href="route('vendor-accounts.index')" class="dropdown-link">
                                    <span class="mr-2">Vendor Accounts</span>
                                </inertia-link>

                                <div v-if="$page.props.permissions.gatherVendorInvoices" class="border-gray-200 border-t border-b h-0"></div>

                                <inertia-link data-cy="layout.nav.item.vendor-portal-credentials" id="layout-nav-item-vendor-portal-credentials" v-if="$page.props.permissions.gatherVendorInvoices" :href="route('vendor-portal-credentials.index')" class="dropdown-link">
                                    <span class="mr-2">Rover Credentials</span>
                                </inertia-link>

                                <inertia-link data-cy="layout.nav.item.vendor-portal-accounts" id="layout-nav-item-vendor-portal-accounts" v-if="$page.props.permissions.gatherVendorInvoices" :href="route('vendor-portal-accounts.index')" class="dropdown-link">
                                    <span class="mr-2">Rover Accounts</span>
                                </inertia-link>

                                <div v-if="$page.props.permissions.accessClientAccounts" class="border-gray-200 border-t border-b h-0"></div>

                                <inertia-link data-cy="layout.nav.item.client-accounts" id="layout-nav-item-client-accounts" v-if="$page.props.permissions.accessClientAccounts" :href="route('client-accounts.index')" class="dropdown-link">
                                    <span class="mr-2">Client Accounts</span>
                                </inertia-link>
                            </div>
                        </template>
                    </dropdown-link>

                    <dropdown-link open-class="bg-d-navy-700" v-if="showContractsNavigation">
                        <template #link>
                            <span data-cy="layout.nav.menu.contracts" id="layout-nav-menu-contracts" class="flex items-center font-medium px-4 py-3 text-white hover:text-d-yellow-500">
                                <span class="mx-1">Contracts</span>
                                <icon name="caret-down" class="inline h-4 w-4 fill-current" />
                            </span>
                        </template>

                        <template #dropdown>
                            <div class="dropdown" style="width: 13rem">
                                <inertia-link data-cy="layout.nav.item.vendor-contracts" id="layout-nav-item-vendor-contracts" v-if="$page.props.permissions.accessVendorContracts" :href="route('vendor-contracts.index')" class="dropdown-link">
                                    <span class="mr-2">Vendor Contracts</span>
                                </inertia-link>

                                <inertia-link data-cy="layout.nav.item.client-contracts" id="layout-nav-item-client-contracts" v-if="$page.props.permissions.accessClientContracts" :href="route('client-contracts.index')" class="dropdown-link">
                                    <span class="mr-2">Client Contracts</span>
                                </inertia-link>
                            </div>
                        </template>
                    </dropdown-link>
                </nav>

                <!-- our global search -->
                <global-search-bar v-if="$page.props.authUser" id="global-search" class="hidden flex-grow lg:block z-50" />

                <nav v-if="$page.props.authUser" class="hidden font-medium ml-4 lg:flex lg:flex-none lg:items-center z-50">
                    <notifications :notifications="$page.props.authUser.notifications" class="mx-2" />

                    <dropdown-link open-class="bg-d-navy-700">
                        <template #link>
                            <span data-cy="layout.nav.menu.settings" class="flex items-center font-medium text-gray-100 py-1 hover:text-d-yellow-500 mx-2">
                                <img v-if="$page.props.authUser.avatar_thumbnail_url" :src="$page.props.authUser.avatar_thumbnail_url" class="inline border border-gray-400 rounded-full mr-4 h-10 w-10" />

                                <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                                    <span class="text-lg font-medium leading-none text-white uppercase">{{ $page.props.authUser.first_name[0] }}{{ $page.props.authUser.last_name[0] }}</span>
                                </span>
                                <span>{{ $page.props.authUser.name }}</span>

                                <icon name="caret-down" class="ml-1 inline h-4 w-4 fill-current" />
                            </span>
                        </template>

                        <template #dropdown>
                            <div class="dropdown">
                                <span class="font-heading font-semibold block px-3 py-1 text-sm text-gray-600 text-center bg-gray-200">Manage Account</span>

                                <inertia-link data-cy="layout.nav.item.user-profile" :href="route('user-settings.index')" class="dropdown-link">
                                    My Profile
                                </inertia-link>

                                <inertia-link data-cy="layout.nav.item.settings" v-if="$page.props.permissions.manageTenantSettings" :href="route('tenant-settings.index')" class="dropdown-link">
                                    Settings
                                </inertia-link>

                                <div class="border-gray-200 border-t-2 h-1"></div>

                                <a href="https://docs.discoveryapp.io/category/395-latest-updates" target="_blank" class="dropdown-link">
                                    Latest Updates
                                </a>

                                <a data-cy="layout.nav.item.logout" href="/logout" class="dropdown-link w-full text-left" as="button">Logout</a>

                                <tenant-switcher v-if="$page.props.authUser.employeesTenants.length > 1" :user="$page.props.authUser" />

                            </div>
                        </template>
                    </dropdown-link>
                </nav>

                <!-- Mobile menu, show/hide this `div` based on menu open/closed state -->
                <transition enter-active-class="transition ease-linear duration-300" leave-active-class="transition ease-linear duration-300">
                    <div v-show="mobileSidebarOpen" class="relative z-40 lg:hidden" role="dialog" aria-modal="true">
                        <transition
                            enter-active-class="transition-opacity ease-linear duration-300"
                            enter-from-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="transition-opacity ease-linear duration-300"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0">

                            <div v-show="mobileSidebarOpen" class="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-600 sm:bg-opacity-75"></div>
                        </transition>

                        <div @click="conditionallyToggleSidebar" class="fixed inset-0 z-40">
                            <transition
                                enter-active-class="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
                                enter-from-class="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
                                enter-to-class="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
                                leave-active-class="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
                                leave-from-class="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
                                leave-to-class="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100">

                                <div v-show="mobileSidebarOpen" ref="mobileSidebar" class="fixed inset-0 z-40 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:w-full sm:max-w-sm sm:shadow-lg" aria-label="Global">
                                    <div class="flex h-16 items-center justify-between px-4 sm:px-6">
                                        <a href="#" class="flex items-center">
                                            <img v-if="$page.props.authUser.tenant_brand_mark_thumbnail_url" class="block h-8 w-auto mr-2" :src="$page.props.authUser.tenant_brand_mark_thumbnail_url" :alt="$page.props.authUser.tenant_name">
                                            <span v-else class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-400 mr-2">
                                                <span class="text-lg font-medium leading-none text-white uppercase">{{ getTenantInitials($page.props.authUser.tenant_name, 2) }}</span>
                                            </span>
                                            {{ $page.props.authUser.tenant_name }}
                                        </a>
                                        <button @click="mobileSidebarOpen = false" type="button" class="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600">
                                            <span class="sr-only">Close main menu</span>
                                            <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>

                                    <div class="max-w-8xl mx-auto mt-2 px-4 sm:px-6">
                                        <div v-if="$page.props.authUser.employeesTenants.length > 1">
                                            <label id="listbox-label" class="block text-sm font-medium leading-6 text-gray-900">Switch Tenants</label>
                                            <div ref="tenantListbox" class="relative mt-2">
                                                <button @blur="handleBlur" @click.prevent="listboxExpanded = !listboxExpanded" type="button" class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
                                                    <span class="block truncate">{{ $page.props.authUser.tenant_name }}</span>
                                                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                                                        </svg>
                                                    </span>
                                                </button>

                                                <transition
                                                    leave-active-class="transition ease-in duration-100"
                                                    leave-from-class="opacity-100"
                                                    leave-to-class="opacity-0">
                                                    <ul v-show="listboxExpanded" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
                                                        <li @click="switchToTenant(tenant)" v-for="tenant in $page.props.authUser.employeesTenants" :key="tenant.id" class="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9" id="listbox-option-0" role="option">

                                                            <span class="block truncate" :class="tenant.id === $page.props.authUser.current_tenant_id ? 'font-semibold' : 'font-normal'">{{ tenant.name }}</span>

                                                            <span v-if="tenant.id === $page.props.authUser.current_tenant_id" class="text-blue-600 absolute inset-y-0 right-0 flex items-center pr-4">
                                                                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                                                </svg>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </transition>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="max-w-8xl mx-auto py-3 px-2 sm:px-4 ">
                                        <a v-if="showRecordsNavigation" href="" @click.prevent.stop="toggleRecords" class="flex items-center justify-between rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100 print:hidden">
                                            Records
                                            <icon :name="expandRecords ? 'chevron-down' : 'chevron-right'" class="fill-current w-3 h-3" />
                                        </a>
                                        <div class="relative" v-if="showRecordsNavigation">
                                            <transition
                                                enter-active-class="transition ease-out duration-100"
                                                enter-from-class="transform opacity-0 scale-95"
                                                enter-to-class="transform opacity-100 scale-100"
                                                leave-active-class="transition ease-in duration-75"
                                                leave-from-class="transform opacity-100 scale-100"
                                                leave-to-class="transform opacity-0 scale-95">
                                                    <div v-show="expandRecords" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <inertia-link v-if="$page.props.permissions.accessIncidents" :href="route('incidents.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Incidents</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.accessPickups" :href="route('pickups.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Pickups</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.accessWorkOrders" :href="route('work-orders.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Work Orders</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.accessClientCompanies" :href="route('client-companies.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Companies</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.accessLocations" :href="route('locations.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Locations</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.accessServices" :href="route('services.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Services</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.accessVendors" :href="route('vendors.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Vendors</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.accessCompactorMonitors" :href="route('compactor-monitors.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Monitors</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.accessReports" :href="route('reports.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Reports</inertia-link>
                                                    </div>
                                            </transition>
                                        </div>

                                        <a href="" @click.prevent.stop="toggleInvoicing" class="flex items-center justify-between rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100">
                                            Invoicing
                                            <icon :name="expandInvoicing ? 'chevron-down' : 'chevron-right'" class="fill-current w-3 h-3" />
                                        </a>
                                        <div class="relative">
                                            <transition
                                                enter-active-class="transition ease-out duration-100"
                                                enter-from-class="transform opacity-0 scale-95"
                                                enter-to-class="transform opacity-100 scale-100"
                                                leave-active-class="transition ease-in duration-75"
                                                leave-from-class="transform opacity-100 scale-100"
                                                leave-to-class="transform opacity-0 scale-95">
                                                    <div v-show="expandInvoicing" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <inertia-link v-if="$page.props.permissions.gatherVendorInvoices" :href="route('gathered-vendor-invoice-files.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Gather</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.extractVendorInvoices" :href="route('ocr-vendor-invoices.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Extract</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.auditVendorInvoices" :href="route('vendor-invoices.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Audit</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.accessClientInvoices" :href="route('client-invoices.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Revenue</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.accessVendorAccounts" :href="route('vendor-accounts.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Vendor Accounts</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.gatherVendorInvoices" :href="route('vendor-portal-credentials.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Rover Credentials</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.gatherVendorInvoices" :href="route('vendor-portal-accounts.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Rover Accounts</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.accessClientAccounts" :href="route('client-accounts.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Client Accounts</inertia-link>
                                                    </div>
                                            </transition>
                                        </div>

                                        <a v-if="showContractsNavigation" href="" @click.prevent.stop="toggleContracts" class="flex items-center justify-between rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100">
                                            Contracts
                                            <icon :name="expandContracts ? 'chevron-down' : 'chevron-right'" class="fill-current w-3 h-3" />
                                        </a>

                                        <div v-if="showContractsNavigation" class="relative">
                                            <transition
                                                enter-active-class="transition ease-out duration-100"
                                                enter-from-class="transform opacity-0 scale-95"
                                                enter-to-class="transform opacity-100 scale-100"
                                                leave-active-class="transition ease-in duration-75"
                                                leave-from-class="transform opacity-100 scale-100"
                                                leave-to-class="transform opacity-0 scale-95">
                                                    <div v-show="expandContracts" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <inertia-link :href="route('vendor-contracts.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Vendor Contracts</inertia-link>
                                                        <inertia-link v-if="$page.props.permissions.accessClientContracts" :href="route('client-contracts.index')" class="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100">Client Contracts</inertia-link>
                                                    </div>
                                            </transition>
                                        </div>
                                    </div>

                                    <div class="border-t border-gray-200 pt-4 pb-3 print:hidden">
                                        <div class="max-w-8xl mx-auto flex items-center px-4 sm:px-6 ">
                                            <div class="flex-shrink-0">
                                                <div class="mx-1">
                                                    <img v-if="$page.props.authUser.avatar_thumbnail_url" class="h-10 w-10 rounded-full" :src="$page.props.authUser.avatar_thumbnail_url" :alt="$page.props.authUser.name">

                                                    <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                                                        <span class="text-lg font-medium leading-none text-white uppercase">{{ $page.props.authUser.first_name[0] }}{{ $page.props.authUser.last_name[0] }}</span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="ml-3 min-w-0 flex-1">
                                                <div class="truncate text-base font-medium text-gray-800">{{ $page.props.authUser.name }}</div>
                                                <div class="truncate text-sm font-medium text-gray-500">{{ $page.props.authUser.email }}</div>
                                            </div>

                                            <a href="/notifications" class="ml-auto flex-shrink-0 bg-white p-2 text-gray-400 hover:text-gray-500">
                                                <span v-if="unreadNotificationsCount > 0" class="absolute top-4 -right-0.5 inline-flex items-center px-1 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                                    <span v-if="unreadNotificationsCount < 10">
                                                        {{ unreadNotificationsCount }}
                                                    </span>

                                                    <span v-else>
                                                        9+
                                                    </span>
                                                </span>
                                                <span class="sr-only">View notifications</span>
                                                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                                                </svg>
                                            </a>
                                        </div>
                                        <div class="max-w-8xl mx-auto mt-3 space-y-1 px-2 sm:px-4">
                                            <inertia-link :href="route('user-settings.index')" class="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50">Profile</inertia-link>

                                            <a href="/logout" as="button" class="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50">Logout</a>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </transition>
            </div>
        </nav>

        <div data-slot="breadcrumbs"></div>

        <main class="max-w-screen-4xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8 print:pb-0">
            <slot />
        </main>

        <div v-if="$page.props.impersonatingEmployee" class="bg-pink-600 sticky bottom-0 text-xl text-center text-white">
            You are currently impersonating user {{ $page.props.authUser.name }}. Please be careful.
        </div>
    </div>

    <span v-if="permissions && false"></span>
    <span v-if="abilities && false"></span>
</template>

<style>
@media print {
	#beacon-container {
		display: none !important;
	}
}
</style>

<script setup>
    import GlobalSearchBar from '@/Shared/GlobalSearchBar.vue';
    import DropdownLink from '@/Shared/DropdownLink.vue';
    import Icon from '@/Shared/Icon.vue';
    import Notifications from '@/Shared/Notifications.vue';
    import TenantSwitcher from '@/Shared/TenantSwitcher.vue';
    import Logo from '@/Shared/Logo.vue';

    import { ref, onMounted, watch, computed, inject } from 'vue';
    import { router, usePage } from '@inertiajs/vue3';

    const props = defineProps({
        title: {
            type: String,
            required: false,
        },
    });

    const route = inject('route');
    const toast = inject('toast');
    const setGlobalAbilities = inject('setGlobalAbilities');
    const setPageAbilities = inject('setPageAbilities');

    const mobileSidebarOpen = ref(false);
    const mobileSidebar = ref(null);
    const expandRecords = ref(false);
    const expandContracts = ref(false);
    const expandInvoicing = ref(false);
    const listboxExpanded = ref(false);
    const tenantListbox = ref([]);

    const page = usePage();

    const isInactive = ref(page.props.tenant_inactive_at !== null);
    const isBillingDashboard = ref(window.location.pathname.includes('billing-dashboard'));

    onMounted(() => {
        const handleNavigate = () => {
            if (mobileSidebarOpen.value) {
                mobileSidebarOpen.value = false;
                expandRecords.value = false;
                expandContracts.value = false;
                expandInvoicing.value = false;
            }
        };

        router.on('navigate', handleNavigate);
    });

    function conditionallyToggleSidebar(e) {
        if (!mobileSidebar.value.contains(e.target)) {
            mobileSidebarOpen.value = false;
        }
    }

    function handleBlur(e) {
        let target = e.relatedTarget;

        if (tenantListbox.value !== target && !tenantListbox.value.contains(target)) {
            listboxExpanded.value = false;
        }
    }

    function toggleRecords() {
        expandRecords.value = !expandRecords.value;
        expandInvoicing.value = false;
        expandContracts.value = false;
    }

    function toggleContracts() {
        expandRecords.value = false;
        expandInvoicing.value = false;
        expandContracts.value = !expandContracts.value;
    }

    function toggleInvoicing() {
        expandRecords.value = false;
        expandInvoicing.value = !expandInvoicing.value;
        expandContracts.value = false;
    }

    function switchToTenant(tenant) {
        listboxExpanded.value = false;
        router.put(route('current-tenant.update'), {tenant_id: tenant.id}, {preserveState: false});
    }

    function getTenantInitials(name, maxLength) {
        let nameParts = name.split(' ');
        let initials = '';

        if (maxLength > nameParts.length) {
            maxLength = nameParts.length;
        }

        for (let i = 0; i < maxLength; i++) {
            initials += nameParts[i][0];
        }

        return initials;
    }

    watch(() => page.props.success_message,
        (newValue, oldValue) => {
            if (newValue) {
                toast.success(newValue);
            }
        },
        { deep: true }
    );

    watch(() => page.props.error_message,
        (newValue, oldValue) => {
            if (newValue) {
                toast.error(newValue);
            }
        },
        { deep: true }
    );

    watch(() => page.props.helpscoutBeaconKey,
        (newValue, oldValue) => {
            if (page.props.appName && page.props.helpscoutBeaconKey) {
                window.Beacon('destroy');
                window.Beacon('init', page.props.helpscoutBeaconKey);
                window.Beacon('session-data', {
                    'User ID': page.props.authUser.id,
                    'Tenant': page.props.authUser.tenant_name,
                    'Role': page.props.authUser.role.name,
                    'Time zone': page.props.authUser.time_zone
                });
            }
        }
    );

    watch(() => page.url,
        (newValue, oldValue) => {
            isBillingDashboard.value = newValue.includes('billing-dashboard');
        }
    );

    const permissions = computed(() => {
        setGlobalAbilities(page.props.permissions);
        return page.props.permissions;
    });

    const abilities = computed(() => {
        setPageAbilities(page.props.abilities);
        return page.props.abilities;
    });

    const unreadNotificationsCount = computed(() => {
        return page.props.authUser.notifications.filter(notification => !notification.read_at).length;
    });

    const showRecordsNavigation = computed(() => {
        return page.props.permissions.accessIncidents ||
            page.props.permissions.accessPickups ||
            page.props.permissions.accessWorkOrders ||
            page.props.permissions.accessClientCompanies ||
            page.props.permissions.accessLocations ||
            page.props.permissions.accessServices ||
            page.props.permissions.accessVendors ||
            page.props.permissions.accessCompactorMonitors ||
            page.props.permissions.accessReports;
    });

    const showContractsNavigation = computed(() => {
        return page.props.permissions.accessClientContracts ||
            page.props.permissions.accessVendorContracts;
    });
</script>