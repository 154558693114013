<template>
    <Head title="Pickups" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Pickups</span>
        </nav>
    </Teleport>

    <index-search-form
        v-model:search="form.search"
        v-model:per-page="form.per_page"
        v-model:selected-headers="form.selected_headers"
        :headers="headers"
        :search-id="'search-pickups'"
        :filters-in-use="filtersInUse"
        :excluded-headers="excludedHeaders"
        :per-page-options="[15, 25, 50, 100, 200]"
        :clear-filters-route="$route('pickups.index', {remember: 'forget'})"
        @show-filters="$refs.searchFilterSlideOver.show()"
    />

    <div class="w-full my-4">
        <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="pickups.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">Showing {{ pickups.from }} - {{ pickups.to }} out of {{ pickups.total }} Pickups</div>

            <div class="flex col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end xl:col-span-1 xl:col-end-5">
                <inertia-link :href="$route('pickups.create')" class="btn btn-orange mr-2">
                    New
                </inertia-link>

                <loading-button :loading="state === 'exporting'" class="btn btn-gray" @click="exportData" :disabled="!pickups.data.length">
                    Export to CSV
                </loading-button>
            </div>
        </div>

        <div>
            <div class="overflow-x-auto">
                <table class="table table-condensed" ref="table">
                    <thead>
                        <tr>
                            <sortable-table-header v-show="form.selected_headers.includes('Client Company')" field="client_companies.name" :filters="filters" route-name="pickups.index" class="px-3 py-3.5 wrap text-left text-sm font-semibold text-gray-900">Client Company</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Location')" field="locations.name" :filters="filters" route-name="pickups.index" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Location</sortable-table-header>
	                        <th v-show="form.selected_headers.includes('Service')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">
		                        <span v-tippy="'This column displays an icon if the associated service is monitored by Pioneer.'">
				                    <icon name="info-circle" class="w-4 h-4 fill-current text-gray-400" />
                                    <span class="sr-only">Monitored by Pioneer</span>
			                    </span>
	                        </th>
	                        <sortable-table-header v-show="form.selected_headers.includes('Service')" field="pickups.service_id" :filters="filters" route-name="pickups.index" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 no-wrap">Service</sortable-table-header>
	                        <th v-show="form.selected_headers.includes('Equipment Label')" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Equipment Label</th>
                            <sortable-table-header v-show="form.selected_headers.includes('Start Date')" field="pickups.start_date" :filters="filters" route-name="pickups.index" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Start Date</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('End Date')" field="pickups.end_date" :filters="filters" route-name="pickups.index" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">End Date</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Times Emptied')" field="pickups.times_emptied" :filters="filters" route-name="pickups.index" class="px-3 py-3.5 wrap text-left text-sm font-semibold text-gray-900">Times Emptied</sortable-table-header>
                            <th v-show="form.selected_headers.includes('Bin Type')" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Bin Type</th>
                            <sortable-table-header v-show="form.selected_headers.includes('Material Type')" field="material_types.name" :filters="filters" route-name="pickups.index" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Material Type</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Weight Value')" field="pickups.weight_value" :filters="filters" route-name="pickups.index" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Weight Value</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Weight Unit')" field="pickups.weight_unit" :filters="filters" route-name="pickups.index" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Weight Unit</sortable-table-header>
                            <th v-show="form.selected_headers.includes('Weight Measurement')" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Weight Measurement</th>
                            <sortable-table-header v-show="form.selected_headers.includes('Container Size')" field="pickups.container_size" :filters="filters" route-name="pickups.index" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Container Size</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Cost')" field="pickups.cost" :filters="filters" route-name="pickups.index" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Cost</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Disposal Method')" field="pickups.disposal_method" :filters="filters" route-name="pickups.index" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Disposal Method</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Estimated Percent Full')" field="pickups.estimated_percent_full" :filters="filters" route-name="pickups.index" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Estimated Percent Full</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Source')" field="pickups.source" :filters="filters" route-name="pickups.index" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Source</sortable-table-header>
                            <th class="font-semibold text-right">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="pickup in pickups.data" :key="pickup.id">
                            <td class="wrap" v-show="form.selected_headers.includes('Client Company')">
                                <inertia-link data-cy="pickups.index.show-link" :href="$route('client-companies.show', pickup.location.client_company_id)" class="link" target="_blank">{{ pickup.location.clientCompany.name }}</inertia-link>
                            </td>

                            <td v-show="form.selected_headers.includes('Location')">
                                <inertia-link :href="$route('locations.show', pickup.location_id)" class="link" target="_blank">{{ pickup.location.name }}</inertia-link>
                            </td>

	                        <td v-show="form.selected_headers.includes('Service')">
								<pioneer-indicator v-if="pickup.service?.compactorMonitor" :compactor-monitor="pickup.service.compactorMonitor" />
	                        </td>

                            <td v-show="form.selected_headers.includes('Service')">
                                <inertia-link v-if="pickup?.service" :href="$route('services.show', pickup.service_id)" class="link" target="_blank">{{ pickup.service_id }}</inertia-link>
                                <template v-else>{{ pickup.service_id }}</template>
                            </td>

	                        <td v-show="form.selected_headers.includes('Equipment Label')">
								{{ pickup?.service?.label }}
	                        </td>

                            <td v-show="form.selected_headers.includes('Start Date')">
                                {{ $filters.format_date(pickup.start_date) }}
                            </td>

                            <td v-show="form.selected_headers.includes('End Date')">
                                {{ $filters.format_date(pickup.end_date) }}
                            </td>

                            <td v-show="form.selected_headers.includes('Times Emptied')">
                                {{ pickup.times_emptied.toFixed(2).replace(/\.0+$/, '') }}
                            </td>

                            <td v-show="form.selected_headers.includes('Bin Type')">
                                {{ pickup.service?.bin.type }}
                            </td>

                            <td v-show="form.selected_headers.includes('Material Type')">
                                {{ pickup.materialType.name }}
                            </td>

                            <td v-show="form.selected_headers.includes('Weight Value')" class="text-right">
                                {{ pickup.weight_value.toFixed(2).replace(/\.0+$/, '') }}
                            </td>

                            <td v-show="form.selected_headers.includes('Weight Unit')">
                                {{ pickup.weight_unit }}
                            </td>

                            <td v-show="form.selected_headers.includes('Weight Measurement')">
                                {{ getWeightMeasurement(pickup) }}
                            </td>

                            <td v-show="form.selected_headers.includes('Container Size')">
                                {{ pickup.container_size }}
                            </td>

                            <td v-show="form.selected_headers.includes('Cost')">
                                {{ pickup.cost ? $filters.format_money(pickup.cost) : null }}
                            </td>

                            <td v-show="form.selected_headers.includes('Disposal Method')">
                                {{ pickup.disposal_method }}
                            </td>

                            <td v-show="form.selected_headers.includes('Estimated Percent Full')">
                                {{ pickup.estimated_percent_full ? pickup.estimated_percent_full + '%' : null }}
                            </td>

                            <td v-show="form.selected_headers.includes('Source')">
                                {{ pickup.source }}
                            </td>

                            <td class="text-right">
                                <div>
                                    <inertia-link :href="$route('pickups.show', pickup.id)" class="link mr-3" title="View This Pickup">
                                        View
                                    </inertia-link>

                                    <inertia-link :href="$route('pickups.edit', pickup.id)" class="link" title="Edit This Pickup">
                                        Edit
                                    </inertia-link>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <pagination :links="pickups.links" />
        </div>

        <div v-if="!pickups.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="truck" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Pickups Found</span>
        </div>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #body>
            <form @submit.prevent id="pickup-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-4 items-center">
                <div class="col-span-1">
                    <label class="form-label">Start Date Between</label>

                    <date-input v-model="form.start_date_start" />

                    <span class="inline-block ml-3 mt-2 text-base text-gray-900">through</span>

                    <date-input v-model="form.start_date_end" />
                </div>
                <div class="col-span-1">
                    <Combobox as="div" v-model="form.weight_measurement" multiple>
                        <ComboboxLabel for="weight_measurement" class="form-label">Weight Measurement</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput
                                    name="weight_measurement"
                                    id="weight_measurement"
                                    class="form-input"
                                    @change="weightMeasurementComboBoxQuery = $event.target.value"
                                    :display-value="comboBoxDisplayValue"
                                />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredWeightMeasurementOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredWeightMeasurementOptions" :key="option.value" :value="option" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ option }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <icon name="heroicon-check" class="size-5 fill-current" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
                <div class="col-span-1">
                    <Combobox as="div" v-model="form.disposal_method" multiple>
                        <ComboboxLabel for="disposal_method" class="form-label">Disposal Method</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput
                                    name="disposal_method"
                                    id="disposal_method"
                                    class="form-input"
                                    @change="disposalMethodComboBoxQuery = $event.target.value"
                                    :display-value="comboBoxDisplayValue"
                                />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredDisposalMethodOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredDisposalMethodOptions" :key="option.value" :value="option" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ option }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <icon name="heroicon-check" class="size-5 fill-current" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
                <div class="col-span-1">
                    <Combobox as="div" v-model="form.material_type" multiple>
                        <ComboboxLabel for="material_type" class="form-label">Material Type</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput
                                    name="material_type"
                                    id="material_type"
                                    class="form-input"
                                    @change="materialTypeComboBoxQuery = $event.target.value"
                                    :display-value="comboBoxDisplayValue"
                                />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredMaterialTypeOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredMaterialTypeOptions" :key="option.value" :value="option" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ option }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <icon name="heroicon-check" class="size-5 fill-current" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
                <div class="col-span-1">
                    <Combobox as="div" v-model="form.bin_type" multiple>
                        <ComboboxLabel for="bin_type" class="form-label">Bin Type</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput
                                    name="bin_type"
                                    id="bin_type"
                                    class="form-input"
                                    @change="binTypeComboBoxQuery = $event.target.value"
                                    :display-value="comboBoxDisplayValue"
                                />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredBinTypeOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredBinTypeOptions" :key="option.value" :value="option" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ option }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <icon name="heroicon-check" class="size-5 fill-current" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
                <div class="col-span-1">
                    <Combobox as="div" v-model="form.source" multiple>
                        <ComboboxLabel for="source" class="form-label">Source</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput
                                name="source"
                                id="source"
                                class="form-input"
                                @change="sourceComboBoxQuery = $event.target.value"
                                :display-value="comboBoxDisplayValue"
                            />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd"/>
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredSourceOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredSourceOptions" :key="option.value" :value="option" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ option }}
                                    </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"/>
                                        </svg>
                                    </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
            </form>
        </template>

    </slide-over>
</template>

<script>
    // Import Methods
    import { throttle } from 'lodash-es';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';

    // Import Components
    import Icon from '@/Shared/Icon.vue';
    import Logo from "@/Shared/Logo.vue";
    import { Head } from '@inertiajs/vue3';
    import DateInput from '@/Shared/DateInput.vue';
    import SlideOver from '@/Shared/SlideOver.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ColumnSelector from '@/Shared/ColumnSelector.vue';
    import IndexSearchForm from "@/Shared/IndexSearchForm.vue";
    import PioneerIndicator from "@/Shared/PioneerIndicator.vue";
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';

    // Tailwind UI combobox
    import {
        Combobox,
        ComboboxInput,
        ComboboxLabel,
        ComboboxButton,
        ComboboxOption,
        ComboboxOptions
    } from "@headlessui/vue";

    export default {
        components: {
            Head,
            Icon,
	        Logo,
            DateInput,
            SlideOver,
            Pagination,
            SelectInput,
            LoadingButton,
            ColumnSelector,
            IndexSearchForm,
	        PioneerIndicator,
            SortableTableHeader,

            // Tailwind UI combobox
            Combobox,
            ComboboxInput,
            ComboboxLabel,
            ComboboxButton,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            pickups: {
                type: Object,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },

            weight_measurements: {
                type: Array,
                required: true
            },

            disposal_methods: {
                type: Array,
                required: true
            },

            material_types: {
                type: Array,
                required: true
            },

            bin_types: {
                type: Array,
                required: true
            },

            sources: {
                type: Array,
                required: true
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    per_page: this.filters.per_page,
                    start_date_start: this.filters.start_date_start,
                    start_date_end: this.filters.start_date_end,
                    selected_headers: this.filters.selected_headers,
                    weight_measurement: this.filters.weight_measurement,
                    disposal_method: this.filters.disposal_method,
                    material_type: this.filters.material_type,
                    bin_type: this.filters.bin_type,
                    source: this.filters.source,
                },
                state: 'passive',
                mounted: false,
                headers: [],
                excludedHeaders: ['Actions', 'Client Company', 'Monitored by Pioneer'],
                disabledHeaders: ['Estimated Percent Full'],
                filtersInUse: 0,
                weightMeasurementComboBoxQuery: '',
                disposalMethodComboBoxQuery: '',
                materialTypeComboBoxQuery: '',
                binTypeComboBoxQuery: '',
                sourceComboBoxQuery: '',
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
                this.form.selected_headers = this.form.selected_headers.filter(header => !this.disabledHeaders.includes(header));
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.state = 'exporting';

                this.$inertia.post(this.$route('csv.pickups.index'), this.queryFilters, {
                    onFinish: () => { this.state = 'passive'; }
                });
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));

                this.headers = headers;
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('pickups.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;

                        }
                    }, 150),
                    {deep: true}
                );
            },

            comboBoxDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

            getWeightMeasurement(pickup) {
                if (pickup.source === 'User Input') {
                    return 'Manual';
                }

                if (pickup.weight_value_is_estimated) {
                    let weightUnit = "";
                    switch (this.$page.props.authUser.tenant_measurement_system) {
                        case 'imperial':
                            weightUnit = 'short tons';
                            break;
                        case 'metric':
                            weightUnit = 'metric tons';
                            break;
                    }

                    if (pickup.workOrder
                        && pickup.workOrder.estimated_quantity === pickup.weight_value
                        && pickup.weight_unit === weightUnit) {
                        return 'Pioneer Monitor';
                    }

                    return 'Estimated';
                }

                return 'Actual';
            }
        },

        computed: {
            queryFilters() {
                let query = { ...this.form}

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.start_date_start,
                    form.start_date_end,
                    form.weight_measurement,
                    form.disposal_method,
                    form.material_type,
                    form.bin_type,
                    form.source,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            },

            filteredWeightMeasurementOptions() {
                return this.weightMeasurementComboBoxQuery === ''
                    ? this.weight_measurements
                    : this.weight_measurements.filter((status) => {
                        return status.toLowerCase().includes(this.weightMeasurementComboBoxQuery.toLowerCase());
                    });
            },

            filteredDisposalMethodOptions() {
                return this.disposalMethodComboBoxQuery === ''
                    ? this.disposal_methods
                    : this.disposal_methods.filter((status) => {
                        return status.toLowerCase().includes(this.disposalMethodComboBoxQuery.toLowerCase());
                    });
            },

            filteredMaterialTypeOptions() {
                return this.materialTypeComboBoxQuery === ''
                    ? this.material_types
                    : this.material_types.filter((status) => {
                        return status.toLowerCase().includes(this.materialTypeComboBoxQuery.toLowerCase());
                    });
            },

            filteredBinTypeOptions() {
                return this.binTypeComboBoxQuery === ''
                    ? this.bin_types
                    : this.bin_types.filter((status) => {
                        return status.toLowerCase().includes(this.binTypeComboBoxQuery.toLowerCase());
                    });
            },

            filteredSourceOptions() {
                return this.sourceComboBoxQuery === ''
                    ? this.sources
                    : this.sources.filter((status) => {
                        return status.toLowerCase().includes(this.sourceComboBoxQuery.toLowerCase());
                    });
            },
        }
    }
</script>
