<template>

    <Head title="Login"/>

    <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <logo class="lg:hidden w-75 h-24 px-6 lg:px-0 mx-auto" name="mark-dark"/>
            <logo class="hidden lg:block w-75 h-24 px-6 lg:px-0 mx-auto" name="full-dark"/>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div v-if="LOGIN_ALERT_MESSAGE" :class="loginErrorClass" class="border-l-4 p-4" role="alert">
                <div>
                    <span>{{ LOGIN_ALERT_MESSAGE }}</span>
                    <span v-if="LOGIN_ALERT_URL"> Click <a class="link"
                                                           :href="LOGIN_ALERT_URL">here</a> for more information.</span>
                </div>
            </div>

            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form action="/login" method="POST">
                    <input type="hidden" name="_token" :value="csrfToken" />
                    <div>
                        <label for="email" class="block text-sm font-medium leading-5 text-gray-700">
                            Email address
                        </label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input id="email" name="email" data-cy="login-input-email" type="email" v-model="form.email" required
                                   autofocus
                                   :class="{ error: errors.email }"
                                   class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
                            <div v-if="errors.email && errors.email.length" class="form-error">{{ errors.email }}</div>
                        </div>
                    </div>

                    <div class="mt-6">
                        <label for="password" class="block text-sm font-medium leading-5 text-gray-700">
                            Password
                        </label>

                        <div class="mt-1 rounded-md shadow-sm">
                            <input id="password" name="password" type="password" data-cy="login-input-password" v-model="form.password"
                                   required
                                   :class="{ error: errors.password }"
                                   class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
                            <div v-if="errors.password && errors.password.length" class="form-error">{{
                                    errors.password
                                }}
                            </div>
                        </div>
                    </div>

                    <div class="mt-7">
                        <span class="block w-full rounded-md shadow-sm">
                            <loading-button data-cy="login-button-submit" :loading="submitting" type="submit"
                                            class="w-full flex justify-center btn btn-orange text-lg lg:text-sm">
                                Sign in
                            </loading-button>
                        </span>
                    </div>

                    <div class="mt-6 flex items-center justify-between">
                        <div class="text-sm lg:text-xs leading-5">
                            <inertia-link :href="$route('password.request')"
                                          class="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                                Forgot your password?
                            </inertia-link>
                        </div>
                    </div>

                    <div class="mt-2 flex items-center justify-between">
                        <div class="text-sm lg:text-xs leading-5">
                            <!-- hiding this temporarily -->
                            <inertia-link v-if="false" :href="$route('signup')"
                                          class="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                                No account? Sign up.
                            </inertia-link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingButton from '@/Shared/LoadingButton.vue';
import TextInput from '@/Shared/TextInput.vue';
import BlankLayout from '@/Shared/BlankLayout.vue'
import {Head} from '@inertiajs/vue3'
import Logo from "../../Shared/Logo.vue";

export default {
    layout: BlankLayout,

    components: {
        Logo,
        LoadingButton,
        TextInput,
        Head,
    },

    props: {
        old_email: {
            type: String,
            default: ''
        },

        csrfToken: String,

        errors: {
            type: Object,
            default: () => ({})
        },

        LOGIN_ALERT_MESSAGE: {
            type: String,
            default: null,
        },

        LOGIN_ALERT_URL: {
            type: String,
            default: null,
        },

        LOGIN_ALERT_COLOR: {
            type: String,
            default: 'yellow',
        }
    },

    mounted() {
        if (this.$page.props.success_message) {
            this.$toast.success(this.$page.props.success_message);
        }
        window.addEventListener('resize', this.isMobile);
    },

    data() {
        return {
            form: {
                email: this.old_email,
                password: null,
            },

            submitting: false
        }
    },

    methods: {
        isMobile() {
            return window.innerWidth < 768;
        }
    },

    computed: {
        loginErrorClass() {
            switch (this.LOGIN_ALERT_COLOR) {
                case 'red':
                    return 'bg-red-100 border-red-500 text-red-700';
                case 'yellow':
                    return 'bg-yellow-100 border-yellow-500 text-yellow-700';
                case 'blue':
                    return 'bg-blue-100 border-blue-500 text-blue-700';
                default:
                    return 'bg-gray-100 border-gray-500 text-gray-700';
            }
        },
    }
}
</script>