<template>
    <Head title="Vendor Invoices by Account per Month" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('reports.index')" class="breadcrumb-link">Reports</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Vendor Invoices By Account Per Month</span>
        </nav>
    </Teleport>

    <form id="filter-report-data-form" action="" class="grid grid-cols-4 gap-4 my-2 mb-4" @submit.prevent>
        <toggle-switch-input v-model="form.only_missing_vendor_invoices" label="Accounts Missing Vendor Invoices" />
        <text-input v-model="form.search" placeholder="Search for account number or vendor name" label="Search" />
        <month-input v-model="form.begin_date" label="Fiscal Period Start" />
        <month-input v-model="form.end_date" label="Fiscal Period End" />

        <div class="col-span-4 md:col-span-2 lg:col-span-1 flex flex-wrap">
            <inertia-link :href="$route('reports.vendor-invoices-by-account-per-month')" class="mt-6 btn btn-gray mr-2">Clear</inertia-link>

            <loading-button :loading="state ==='exporting'" class="mt-6 btn btn-gray" @click="exportData">
                Export to CSV
            </loading-button>
        </div>
    </form>

    <div v-if="reportData.data.length != 0">
        <reporting-table :reportData="reportData.data" :ignore="['first_fiscal_period', 'fiscal_period_date', 'termination_date', 'vendor_id',  'vendor_account_id', 'location_id', 'client_company_id']">
            <template v-slot:account_number="data">
                <inertia-link target="_blank" class="link" :href="$route('vendor-accounts.show', data.row.vendor_account_id)">
                    {{ data.row.account_number }}
                </inertia-link>
            </template>

            <template v-slot:vendor_name="data">
                <inertia-link target="_blank" class="link" :href="$route('vendors.show', data.row.vendor_id)">
                    {{ data.row.vendor_name }}
                </inertia-link>
            </template>

            <template v-slot:location_name="data">
                <inertia-link target="_blank" class="link" :href="$route('locations.show', data.row.location_id)">
                    {{ data.row.location_name }}
                </inertia-link>
            </template>

            <template v-slot:client_company_name="data">
                <inertia-link target="_blank" class="link" :href="$route('client-companies.show', data.row.client_company_id)">
                    {{ data.row.client_company_name }}
                </inertia-link>
            </template>
        </reporting-table>
        <pagination v-if="reportData" :links="reportData.links" />
    </div>
    <div v-else>
        <h5>No results found, please adjust your filters.</h5>
    </div>
</template>

<script>
    import DropdownLink from '@/Shared/DropdownLink.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import Icon from '@/Shared/Icon.vue';
    import MonthInput from '@/Shared/MonthInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import { debounce, pickBy, groupBy } from 'lodash-es';
    import ReportingTable from '@/Shared/ReportingTable.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            DropdownLink,
            MonthInput,
            DateInput,
            SelectInput,
            Pagination,
            ReportingTable,
            TextInput,
            ToggleSwitchInput,
            LoadingButton,
            Head,
        },

        props: {
            reportData: {
                type: Object,
                required: true
            },

            filters: Object
        },

        data() {
            return {
                form: { ...this.filters},
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            exportData() {
                this.state = 'exporting';

                this.$inertia.post(this.$route('csv.vendor-invoices-by-account-per-month'), {...this.form}, {
                    onFinish: () => { this.saving = 'passive'; }
                });
            }
        },

        watch: {
            form: {
                handler: debounce(function() {
                    let query = pickBy(this.form);
                    query = Object.keys(query).length ? query : {remember: 'forget'};

                    this.$inertia.get(this.$route("reports.vendor-invoices-by-account-per-month"), query, {preserveState: true});
                }, 500),

                deep: true
            }
        }
    }
</script>