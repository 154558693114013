<style>
    .modal-enter-from {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter-from .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
</style>

<template>
    <transition name="modal">
        <div v-show="showModal" class="fixed inset-0 h-full w-full overflow-auto z-50" style="transition: opacity .6s ease; background-color: rgba(0, 0, 0, .5);" @keyup.esc="close" tabindex="0">
            <div class="h-screen w-full absolute flex items-baseline justify-center mt-24 mb-24" @mousedown.self="close">
                <div class="w-full bg-white rounded shadow modal-container" v-bind="$attrs" style="transition: all .6s ease;">
                    <slot name="modal-header" :close="close"></slot>
                    <slot name="modal-body"></slot>
                    <slot name="modal-footer" :close="close">
                        <div class="flex justify-center">
                            <button class="flex-no-shrink text-white py-2 px-4 rounded bg-gray-500 hover:bg-gray-700" @click="close">Close</button>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        inheritAttrs: false,

        data () {
            return {showModal: false};
        },

        methods: {
            show() {
                let widthBefore = document.body.offsetWidth;

                this.showModal = true;
                document.documentElement.classList.add('overflow-hidden');                         // Allow the modal to be scrolled if its content is larger than the screen
                document.body.classList.add('overflow-hidden');
                document.body.style.marginRight = document.body.offsetWidth - widthBefore + "px";  // Reduce horizontal scrollbar jerkiness when showing the modal.

                this.$nextTick(() => this.$el.focus());
            },

            close() {
                this.showModal = false;

                // Reduce horizontal scrollbar jerkiness when closing the modal.
                document.documentElement.classList.remove('overflow-hidden');
                document.body.classList.remove('overflow-hidden');
                document.body.style.marginRight = "";

                this.$emit('closed');
            }
        },
    }
</script>