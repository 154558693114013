<style>
   .background-pattern {
        background-color: #ffffff;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%23324556' fill-opacity='0.03'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }
</style>

<template>
    <Head title="Dashboard" />

    <div class="relative">
        <div class="inset-0 background-pattern w-full min-h-screen"></div>

        <div class="absolute inset-0" style="background-image: linear-gradient(rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 1))">
            <div class="w-full md:w-1/2 my-2 mb-6">
                <p class="font-heading mt-3 text-3xl text-gray-700 font-semibold">
                    Good {{ salutation }}, <span class="font-bold text-d-orange-500">{{ $page.props.authUser.first_name }}</span>
                </p>
            </div>

            <div class="w-full my-2">
                <select-input v-if="!$page.props.permissions.auditVendorInvoices" label="Date Range" v-model="metricDateRange" @change="changeDateRange" class="w-full col-span-3 lg:w-1/6">
                    <option v-for="option in dateRangeOptions" :key="option" :value="option">Last {{ option }}</option>
                    <option v-for="option in ['Quarter to Date', 'Year to Date', 'All Time']" :key="option" :value="option">{{ option }}</option>
                </select-input>
                <div :key="metricDateRangeChanged" class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-4">
                    <dashboard-metric-card :key="gatheredVendorInvoiceFilesCountForDateRangeLoading" v-if="$page.props.permissions.gatherVendorInvoices && !$page.props.permissions.auditVendorInvoices" label="Gathered Vendor Invoice Files" :loading="loading" :displayObject="gatheredVendorInvoiceFilesForDateRange" :showTrend="true"/>
                    <dashboard-metric-card :key="vendorPortalInvoicesFetchCountForDateRangeLoading" v-if="$page.props.permissions.gatherVendorInvoices && !$page.props.permissions.auditVendorInvoices" label="Rover Invoices Fetched" :loading="loading" :displayObject="vendorPortalInvoicesFetchedForDateRange" :showTrend="true"/>
                    <dashboard-metric-card :key="subscribedVendorPortalAccountsCountForDateRangeLoading" v-if="$page.props.permissions.gatherVendorInvoices && !$page.props.permissions.auditVendorInvoices" label="Subscribed Rover Accounts" :loading="loading" :displayObject="subscribedVendorPortalAccountsForDateRange" :showTrend="true"/>
                    <dashboard-metric-card v-if="$page.props.permissions.auditVendorInvoices" label="Late or Due Incidents" :displayObject="lateOrDueIncidentCount"/>
                    <dashboard-metric-card v-if="$page.props.permissions.auditVendorInvoices" label="Invoices Awaiting Processing" :displayObject="totalUnprocessedInvoiceCount"/>
                    <dashboard-metric-card v-if="$page.props.permissions.auditVendorInvoices" label="Savings from Invoice Auditing" :displayObject="$filters.format_money(invoiceAuditingSavings)" displayValueClass="text-green-500"/>

                    <div class="bg-white overflow-hidden shadow rounded-lg">
                        <div class="px-4 py-4 sm:p-5">
                            <rover-stats :vendor-portal-credentials="vendorPortalCredentials"  :vendor-portal-accounts="vendorPortalAccounts"/>
                        </div>
                    </div>
                </div>
            </div>

            <h3 v-if="savingsFromAuditsData.length != 0" class="text-xl leading-6 font-medium text-gray-700 mt-8">
                Last 6 Months
            </h3>

            <div  class="grid grid-cols-2 gap-y-6 gap-x-4">
                <invoice-savings-table
                    v-if="savingsFromAuditsData.length != 0 && $page.props.permissions.auditVendorInvoices"
                    :total-savings-from-audits="savingsFromAuditsData.total_savings_from_audits"
                    :total-error-rate="savingsFromAuditsData.total_error_rate"
                    :number-of-invoices-processed="savingsFromAuditsData.number_of_invoices_processed"
                    :number-of-invoices-with-savings="savingsFromAuditsData.number_of_invoices_with_savings"
                    :invoice-savings-by-vendor="savingsFromAuditsData.invoice_savings_by_vendor"
                    class="col-span-2 xl:col-span-1" />

                <template v-if="$page.props.permissions.accessRevenueManagement">
                    <waste-spend-by-category-stacked-bar
                        v-if="savingsFromAuditsData.length != 0 && clientInvoiceWasteSpendByCategory.length != 0"
                        :series="clientInvoiceWasteSpendByCategory"
                        :date-range="dateRange"
                        class="col-span-2 xl:col-span-1" />

                    <div v-else class="col-span-2 xl:col-span-1 px-4 py-4 grid items-center text-2xl font-semibold bg-center h-full" style="height: 400px; background-image: url('/images/column_graph_blur_no_data.png')">
                        <div class="text-center">
                            No waste spend by category data.
                        </div>
                    </div>
                </template>

                <template v-if="$page.props.permissions.gatherVendorInvoices">
                    <gathered-vendor-invoices-by-month
                        v-if="gatheredVendorInvoicesByMonth.length > 0"
                        :series="gatheredVendorInvoicesByMonth"
                        :date-range="dateRange"
                        class="col-span-2 xl:col-span-1" />

                    <div v-else class="col-span-2 xl:col-span-1 px-4 py-4 grid items-center text-2xl font-semibold h-full bg-no-repeat bg-center" style="height: 400px; background-image: url('/images/column_graph_blur_no_data.png')">
                        <div class="text-center">
                            No gathered vendor invoice data.
                        </div>
                    </div>
                </template>

                <template v-if="$page.props.permissions.extractVendorInvoices">
                    <ocr-vendor-invoices-by-status-chart
                            v-if="ocrVendorInvoicesByStatus.length > 0"
                            :series="ocrVendorInvoicesByStatus"
                            class="col-span-2 xl:col-span-1"/>
                    <div v-else class="col-span-2 xl:col-span-1 px-4 py-4 grid items-center text-2xl font-semibold bg-no-repeat bg-center" style="height: 400px; background-image: url('/images/donut_graph_blur_no_data.png')">
                        <div class="text-center">
                            No extraction data.
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import InvoiceSavingsTable from '@/Shared/Reports/InvoiceSavingsTable.vue';
    import WasteSpendByCategoryStackedBar from '@/Shared/Reports/WasteSpendByCategoryStackedBar.vue';
    import { compareDesc, addMonths, startOfMonth, subMonths, format } from 'date-fns';
    import axios from "axios";
    import DashboardMetricCard from "../Shared/DashboardMetricCard.vue";
    import GatheredVendorInvoicesByMonth from "../Shared/Reports/GatheredVendorInvoicesByMonth.vue";
    import SelectInput from "../Shared/SelectInput.vue";
    import OcrVendorInvoicesByStatusChart from "../Shared/Reports/OcrVendorInvoicesByStatusChart.vue";
    import RoverStats from "@/Shared/Reports/RoverStats.vue";

    export default {
        components: {
            RoverStats,
            Head,
            OcrVendorInvoicesByStatusChart,
            SelectInput,
            GatheredVendorInvoicesByMonth,
            DashboardMetricCard,
            Icon,
            InvoiceSavingsTable,
            WasteSpendByCategoryStackedBar
        },

        props: {
            lateOrDueIncidentCount: {
                type: Number,
                required: true
            },

            totalUnprocessedInvoiceCount: {
                type: Number,
                required: true
            },

            invoiceAuditingSavings: {
                type: Object,
                required: true
            },

            savingsFromAuditsData: {
                type: Object,
                required: true
            },

            vendorPortalCredentials: {
                type: Object,
                required: true
            },

            vendorPortalAccounts: {
                type: Object,
                required: true
            },

            clientInvoiceWasteSpendByCategory: {
                type: Array,
                required: true
            },

            gatheredVendorInvoicesByMonth: {
                type: Array,
                required: true
            },

            ocrVendorInvoicesByStatus: {
                type: Array,
                required: true
            },

        },

        data() {
            return {
                beginDate: startOfMonth(subMonths(new Date, 6)),
                endDate: startOfMonth(new Date),
                gatheredVendorInvoiceFilesForDateRange: null,
                vendorPortalInvoicesFetchedForDateRange: null,
                subscribedVendorPortalAccountsForDateRange: null,
                metricDateRange: '30 Days',
                dateRangeOptions: ['30 Days', '60 Days', '90 Days', '6 Months', '12 Months'],
                metricDateRangeChanged: 0,
                loading: false,
                gatheredVendorInvoiceFilesCountForDateRangeLoading: false,
                vendorPortalInvoicesFetchCountForDateRangeLoading: false,
                subscribedVendorPortalAccountsCountForDateRangeLoading: false,
            }
        },

        methods: {
            getGatheredVendorInvoiceFilesCountForDateRange() {
                this.loading = true;
                this.gatheredVendorInvoiceFilesCountForDateRangeLoading++;
                axios.get(this.$route('json.reports.gathered-vendor-invoice-files-by-date'), {
                    params: {
                        date_range: this.metricDateRange,
                    }
                }).then(response => {
                    this.gatheredVendorInvoiceFilesForDateRange = {...response.data};
                    this.loading = false;
                    this.gatheredVendorInvoiceFilesCountForDateRangeLoading++;
                });
            },

            getVendorPortalInvoicesFetchedCountForDateRange() {
                this.loading = true;
                this.vendorPortalInvoicesFetchCountForDateRangeLoading++;
                axios.get(this.$route('json.reports.vendor-portal-invoices-fetched-by-date'), {
                    params: {
                        date_range: this.metricDateRange,
                    }
                }).then(response => {
                    this.vendorPortalInvoicesFetchedForDateRange = {...response.data};
                    this.loading = false;
                    this.vendorPortalInvoicesFetchCountForDateRangeLoading++;
                });
            },
            getSubscribedVendorPortalAccountsCountForDateRange() {
                this.loading = true;
                this.subscribedVendorPortalAccountsCountForDateRangeLoading++;
                axios.get(this.$route('json.reports.subscribed-vendor-portal-accounts-by-date'), {
                    params: {
                        date_range: this.metricDateRange,
                    }
                }).then(response => {
                    this.subscribedVendorPortalAccountsForDateRange = {...response.data};
                }).finally(() => {
                    this.loading = false;
                    this.subscribedVendorPortalAccountsCountForDateRangeLoading++;
                })
            },
            changeDateRange() {
                this.getGatheredVendorInvoiceFilesCountForDateRange();
                this.getVendorPortalInvoicesFetchedCountForDateRange();
                this.getSubscribedVendorPortalAccountsCountForDateRange();
            }
        },

        computed: {
            dateRange() {
                let dates = [];
                let date = this.beginDate;

                dates.push(date);

                while (compareDesc(date, this.endDate) === 1) {
                    date = addMonths(date, 1);
                    dates.push(date);
                }

                return dates;
            },

            salutation() {
                let hour = format(new Date, 'k');

                if (hour < 12) {
                    return 'Morning';
                } else if (hour >= 12 && hour < 17) {
                    return 'Afternoon';
                } else {
                    return 'Evening'
                }
            }
        },

        beforeMount() {
            this.changeDateRange();
        },

        watch: {
            metricDateRange(oldValue, newValue) {
                this.changeDateRange();
            },
        }
    }
</script>