export default function OAuth(url) {
    this.loginPopup = function (parameter) {
        this.loginPopupUri(parameter);
    }
    
    this.loginPopupUri = function (parameter) {
        // Launch Popup
        let parameters = "location=1,width=800,height=650";
        parameters += ",left=" + (screen.width - 800) / 2 + ",top=" + (screen.height - 650) / 2;
        
        let win = window.open(url, 'connectPopup', parameters);
        
        let pollOAuth = window.setInterval(function () {
            try {
                if (win && win.document && win.document.URL.indexOf("code") != -1) {
                    window.clearInterval(pollOAuth);
                    win.close();
                    location.reload();
                }
            } catch (e) {
                console.log(e)
            }
        }, 100);
    }
}