<template>
<div>
	<h3 class="my-3 p-1 w-full text-d-navy-500 text-xl pl-4 whitespace-nowrap flex relative">
		{{ trendTitle }}
	</h3>

	<div class="p-2 py-0 whitespace-nowrap w-auto relative">
		<div :class="background" class="grid grid-cols-2 flex rounded p-4 space-y-1">
			<div class="col-span-2 text-lg">
				<span class="text-xl font-semibold">{{ metricData && trendPrefix && trendPrefix !== '$' ? trendPrefix : '' }}</span>
				<span class="text-xl font-semibold">{{ metricData ? getMetricData() : '--'}} </span>
				<span v-if="metricData && trendSuffix" class="text-sm ml-1">{{ trendSuffix }}</span>
				<span class="text-sm ml-1">{{ metricData ? getMetricTrendDirectionSuffix() : '' }}</span>
			</div>

			<div class="left-3 bottom-0 font-semibold text-lg">
				<icon :name="icon" :class="font" class="fill-current inline w-6 h-6"/>
				{{ Math.abs(deltaPercentage) }}%</div>
		</div>
		<div v-if="showCharts" class="z-50 mt-10 px-1 h-full" :id="`compactor-monitor-reports-dashboard-${this.containerName}`">
			<logo class="inline hidden absolute bottom right-28 w-8 h-8" name="pioneer-full-single-dark"></logo>
		</div>
	</div>
</div>
</template>

<script>
import Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';
import Icon from "../../../Icon.vue";
import Logo from "../../../Logo.vue";

HighchartsMore(Highcharts);

    Highcharts.setOptions({
        lang: {
          decimalPoint: '.',
          thousandsSep: ','
        }
    });

    export default {
	    components: {Logo, Icon},
        props: {
            series: {
                type: Array,
                required: true
            },

	        metricData: {
		        required: false
	        },

	        deltaPercentage: {
		        type: Number,
		        required: false
	        },

	        containerName: {
		        type: String,
		        required: false
	        },

	        trendTitle: {
		        type: String,
		        required: true
	        },

	        trendSuffix: {
		        type: String,
		        required: false
	        },

	        trendPrefix: {
		        type: String,
		        required: false
	        },

			isInverseTrend: {
				type: Boolean,
				required: false,
				default: false,
			},

	        showCharts: {
		        type: Boolean,
		        required: false,
		        default: true,
	        },

			metricType: {
				type: String,
				required: false,
				default: '',
			}
		},

	    data() {
			let metricTrend = this.getMetricTrend();
		    return {
			    font: metricTrend.font,
			    icon: metricTrend.icon,
			    background: metricTrend.background,
			    chart: null
		    };
	    },

        mounted() {
			if (this.showCharts) {
				this.drawChart();
				this.mediaQueryList = window.matchMedia('print');
			}
        },

        methods: {
            drawChart() {
				let trendPrefix = this.trendPrefix;
                Highcharts.setOptions({
                    colors: ['#5fa3ec', '#fc9776', '#f4c326']
                });
                this.chart = Highcharts.chart(`compactor-monitor-reports-dashboard-${this.containerName}`, {
                    chart: {
                        type: 'column',
	                    width: null,
	                    height: 250,
	                    printMaxWidth: 140,
                    },
                    title: {
                        text: null
                    },
					credits: {
						enabled:false,
					},
	                exporting: {
						sourceHeight: 170,
						sourceWidth: 220,
		                enabled: false
	                },
	                responsive: {
		                rules: [{
			                condition: {
				                maxWidth: 200  // Apply these settings for screens under 500 pixels
			                },
			                chartOptions: {
				                chart:
					                {
						                height: null,
						                width:195,
						                animation:false
					                },
			                }
		                }]
	                },
	                events: {
		                load: function() {
			                var chart = this;
			                if (window.matchMedia) {
				                var mediaQueryList = window.matchMedia('print');

				                mediaQueryList.addEventListener('change', function(mqlEvent) {
					                if (mqlEvent.matches) {
						                chart.setSize(50, 300);
						                chart.reflow();
					                } else {
						                chart.setSize(origSize.width, origSize.height);
						                chart.reflow();
					                }
				                });
			                }
		                }
	                },
                    xAxis: {
                        categories: this.series.map(data => data.name)
                    },

                    yAxis: {
                        min: 0,
                        title: {
                            text: null
                        },
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold',
                                color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                            },
                            format: "${total:,.2f}"
                        },
						labels: {
							enabled: false,
						}
                    },

                    legend: {
                        enabled: false
                    },

	                tooltip: {
		                enabled: false
	                },

                    series: [{
	                    data: this.series.map((data, index) => {
		                    return {
			                    y: data.value,
			                    color: index % 2 === 0 ? '#5fa3ec': '#fc521d' // this gives alternating colors
		                    }
	                    }),
	                    dataLabels: {
		                    enabled: true,
		                    crop: false,
		                    overflow: 'none',
		                    formatter: function() {
			                    var formattedY;
			                    if (trendPrefix === '$') {
				                    formattedY = '$' + new Intl.NumberFormat().format(this.y.toFixed(0));
			                    } else {
				                    formattedY = Number.isInteger(this.y) ? new Intl.NumberFormat().format(this.y.toString()) : new Intl.NumberFormat().format(this.y.toFixed(2)); // If not, conditionally display decimals
			                    }
			                    return formattedY;
		                    },
		                    style: {
			                    textOutline: 'none', // Optionally remove text outlines
			                    fontSize: '14px'     // Set font size
		                    },
	                    },
                    }]
                });
            },


	        getMetricTrend() {
		        let font = 'text-gray-500';
		        let icon = 'minus';
		        let background = 'bg-gray-300';

		        if (this.metricData !== null) {
			        switch(Math.sign(this.metricData)) {
				        case -1:
					        if (this.isInverseTrend) {
						        font = 'text-green-500';
						        icon = 'caret-down';
						        background = 'bg-green-100';
					        } else {
						        font = 'text-red-500';
						        icon = 'caret-down';
						        background = 'bg-red-100';
					        }
					        break;
				        case 1:
					        if (this.isInverseTrend) {
						        font = 'text-red-500';
						        icon = 'caret-up';
						        background = 'bg-red-100';
					        } else {
						        font = 'text-green-500';
						        icon = 'caret-up';
						        background = 'bg-green-100';
					        }
					        break;
			        }
		        }

		        return {
			        font,
			        icon,
			        background,
		        };
	        },

	        getMetricTrendDirectionSuffix() {
				switch (this.metricType) {
					case '':
						return Math.sign(this.metricData) === -1 ? 'avoided' : 'incurred';
					break;
					case 'weight':
						return Math.sign(this.metricData) === -1 ? 'decrease' : 'increase';
				}
	        },

			getMetricData() {
				let metricData = Math.abs(this.metricData);
				if (this.trendPrefix === '$') {
					return this.$filters.format_money({amount:metricData, currency: this.$page.props.currency}, true)
				} else {
					return new Intl.NumberFormat().format(metricData);
				}
			}
		},

        watch: {
	        series: {
		        handler(newVal, oldVal) {
			        if (this.showCharts && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
				        this.drawChart();
			        }
		        },
		        deep: true
	        }
        }
    }
</script>