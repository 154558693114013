<template>
    <Head title="Energy Star Meter Usage V2" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('reports.index')" class="breadcrumb-link">Reports</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Energy Star Meter Usage V2</span>
        </nav>
    </Teleport>

    <form id="filter-report-data-form" action="" class="grid grid-cols-4 gap-x-4 my-2 mb-4" @submit.prevent>
        <select-input v-model="form.client_company_id" label="Client Company">
            <option v-for="clientCompany in clientCompanies" :key="clientCompany.id" :value="clientCompany.id">{{ clientCompany.name }}</option>
        </select-input>

        <date-input v-model="form.begin_date" label="Start Date" />
        <date-input v-model="form.end_date" label="End Date" />

        <div class="col-span-4 md:col-span-2 lg:col-span-1 flex flex-wrap">
            <inertia-link :href="$route('reports.energy-star-meter-usage-v2')" class="mt-6 btn btn-gray mr-2">Clear</inertia-link>

            <loading-button :loading="state === 'exporting'" class="mt-6 btn btn-gray" @click="exportData">
                Export to CSV
            </loading-button>
        </div>
    </form>

    <div v-if="reportData.data.length != 0">
        <reporting-table :reportData="reportData.data">
            <template v-slot:quantity="data">
                {{ format_quantity(data.row.quantity) }}
            </template>
        </reporting-table>
        <pagination v-if="reportData" :links="reportData.links" />
    </div>
    <div v-else>
        <h5>No results found, please adjust your filters.</h5>
    </div>
</template>

<script>
import DropdownLink from '@/Shared/DropdownLink.vue';
import Pagination from '@/Shared/Pagination.vue';
import Icon from '@/Shared/Icon.vue';
import MonthInput from '@/Shared/MonthInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import { debounce, pickBy, groupBy } from 'lodash-es';
import ReportingTable from '@/Shared/ReportingTable.vue';
import DateInput from '@/Shared/DateInput.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import { Head } from '@inertiajs/vue3';

export default {
    components: {
        Icon,
        DropdownLink,
        MonthInput,
        SelectInput,
        Pagination,
        ReportingTable,
        LoadingButton,
        DateInput,
        Head,
    },

    props: {
        clientCompanies: {
            type: Array,
            required: true
        },

        reportData: {
            type: Object,
            required: true
        },

        filters: Object
    },

    data() {
        return {
            form: {...this.filters},
            state: 'passive',
            mounted: false,
        }
    },

    mounted() {
        this.mounted = true;
    },

    methods: {
        format_quantity(value) {
            return Math.round(value) == value ? Math.round(value) : value;
        },

        exportData() {
            this.state = 'exporting';

            this.$inertia.post(this.$route('csv.energy-star-meter-usage-v2'), {...this.form}, {
                onFinish: () => { this.state = 'passive'; }
            });
        }
    },

    watch: {
        form: {
            handler: debounce(function() {
                let query = pickBy(this.form);
                query = Object.keys(query).length ? query : {remember: 'forget'};

                this.$inertia.get(this.$route("reports.energy-star-meter-usage-v2"), query, {preserveState: true});
            }, 500),

            deep: true
        }
    }
}
</script>