<template>
    <Head title="Profile" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('user-settings.index')" class="breadcrumb-link">My Account</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Profile</span>
        </nav>
    </Teleport>

    <div class="py-6">
        <horizontal-sub-nav  currentTab="profile" />

        <form class="divide-y divide-gray-200" @submit.prevent="submitForm">
            <div class="px-4 py-5">
                <div>
                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Company Information
                        </h3>

                        <p class="mt-1 text-sm leading-5 text-gray-600">
                            This information will be displayed to the rest of your team members using Discovery.
                        </p>
                    </div>

                    <div class="mt-6 sm:mt-5">
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                            <label for="job_title" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                Job Title
                            </label>

                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="max-w-xs rounded-md shadow-sm">
                                    <text-input id="job_title" v-model="form.job_title" :errors="errors.job_title" />
                                </div>
                            </div>
                        </div>

                        <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="photo" class="block text-sm leading-5 font-medium text-gray-700">
                                Avatar Photo
                            </label>

                            <div class="mt-2 sm:mt-0 sm:col-span-2">
                                <div class="flex items-center">
                                    <img v-if="imagePreview" :src="imagePreview" class="h-12 w-12 rounded-full" alt="">

                                    <span v-else class="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                                        <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                        </svg>
                                    </span>

                                    <label class="cursor-pointer ml-5 mr-2 py-2 px-3 border border-gray-300 text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                                        Change
                                        <input type='file' class="hidden" @change="previewFile" />
                                    </label>

                                    <div v-if="errors.avatar && errors.avatar.length" class="form-error">{{ errors.avatar }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Personal Information
                        </h3>

                        <p class="mt-1 text-sm leading-5 text-gray-600">
                            Use this form to further customize Discovery.
                        </p>
                    </div>

                    <div class="mt-6 sm:mt-5">
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2" aria-required="true">
                                <span class="text-red-500">*</span> First Name
                            </label>

                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="max-w-xs rounded-md shadow-sm">
                                    <text-input id="first_name" type="text" v-model="form.first_name" :errors="errors.first_name" />
                                </div>
                            </div>
                        </div>

                        <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="last_name" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2" aria-required="true">
                                <span class="text-red-500">*</span> Last Name
                            </label>

                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="max-w-xs rounded-md shadow-sm">
                                    <text-input id="last_name" type="text" v-model="form.last_name" :errors="errors.last_name" />
                                </div>
                            </div>
                        </div>

                        <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="phone_number" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2" aria-required="true">
                                Phone Number
                            </label>

                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="max-w-xs rounded-md shadow-sm">
                                    <text-input id="phone_number" type="text" v-model="form.phone_number" :errors="errors.phone_number" />
                                </div>
                            </div>
                        </div>

                        <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="time_zone" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2" aria-required="true">
                                <span class="text-red-500">*</span>
                                Time Zone
                            </label>

                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="max-w-lg rounded-md shadow-sm">
                                    <select-input v-model="form.time_zone" :errors="errors.time_zone">
                                        <option v-for="(timeZone, index) in timeZones" :key="index" :value="index">{{ timeZone }}</option>
                                    </select-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Email Address
                        </h3>

                        <p class="mt-1 text-sm leading-5 text-gray-600">
                            Use an email address that is accessible by you and you alone.
                        </p>
                        <p class="my-1 text-sm leading-5 text-gray-600">
                            You will need to enter your current password to change your email address. A confirmation email will be sent to your new address. The email address on your account will not change until you click through the confirmation link in this message. A notification of this change will also be sent to your current email address.
                        </p>
                    </div>

                    <div class="mt-6 sm:mt-5">
                        <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="email" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2" >
                                <span class="text-red-500">*</span> Email Address
                            </label>

                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="max-w-lg rounded-md shadow-sm">
                                    <text-input id="email" type="email" v-model="form.email" :errors="errors.email" aria-required="true" />
                                </div>
                            </div>
                        </div>

                        <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="email_change_confirmation_password" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2" >
                                <span v-if="form.email !== user.email" class="text-red-500">*</span> Current Password
                            </label>

                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="max-w-lg rounded-md shadow-sm">
                                    <text-input id="email_change_confirmation_password" type="password" v-model="form.email_change_confirmation_password" :errors="errors.email_change_confirmation_password" :disabled="form.email === user.email" :aria-required="form.email !== user.email" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-8 border-t border-gray-200 pt-5">
                <div class="flex justify-start">
                    <span class="ml-3 inline-flex shadow-sm">
                        <loading-button type="submit" :loading="state === 'saving'" class="btn btn-orange">Save</loading-button>
                    </span>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import HorizontalSubNav from "../HorizontalSubNav.vue";
    import { reactive, ref, inject, onMounted } from 'vue';
    import { router, usePage } from '@inertiajs/vue3';

    const route = inject('route');
    const toast = inject('toast');

    /**
     * Props
     */
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },
        
        user: {
            type: Object,
            required: true
        },

        timeZones: {
            type: Object,
            required: true
        }
    });

    /**
     * State
     */
    const form = reactive({ ...props.user, email_change_confirmation_password: null});
    const state = ref('passive');
    const imagePreview = ref(props.user.avatar_thumbnail_url ? props.user.avatar_thumbnail_url : null);
    const pendingAvatar = ref(null);
    const mounted = ref(false);

    onMounted(() => {
        mounted.value = true;

        //  Added to support success and error messages for email change confirmation
        if (usePage().props.success_message) {
            toast.success(usePage().props.success_message);
        }

        if (usePage().props.error_message) {
            toast.error(usePage().props.error_message);
        }
    });

    /**
     * Methods
     */
    function submitForm() {
        state.value = 'saving';

        let formData = new FormData();

        if (form.phone_number) {
            formData.append('phone_number', form.phone_number);
        }

        formData.append('first_name', form.first_name);
        formData.append('last_name', form.last_name);
        formData.append('email', form.email);
        formData.append('time_zone', form.time_zone);
        formData.append('job_title', form.job_title);
        formData.append('_method', 'PUT');

        if (pendingAvatar.value) {
            formData.append('avatar', pendingAvatar.value);
        }

        if (form.email_change_confirmation_password) {
            formData.append('email_change_confirmation_password', form.email_change_confirmation_password);
        }

        router.post(route('user-settings.profiles.update'), formData, {
            preserveScroll: true,
            onFinish: () => state.value = 'passive',
            onSuccess: () => {
                form.email_change_confirmation_password = '';
                form.email = user.email;
            }
        });
    }

    function previewFile(e) {
        let file = e.target.files[0];
        let reader = new FileReader();

        if (file && file.type.startsWith('image/')) {
            pendingAvatar.value = file;
            reader.addEventListener('load', () => imagePreview.value = reader.result, false);
            reader.readAsDataURL(file);
        }
    }
</script>