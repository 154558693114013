<template>
    <Head title="Services" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Services</span>
        </nav>
    </Teleport>

    <index-search-form
        v-model:search="form.search"
        v-model:per-page="form.per_page"
        v-model:selected-headers="form.selected_headers"
        :headers="headers"
        :search-id="'search-services'"
        :filters-in-use="filtersInUse"
        :excluded-headers="excludedHeaders"
        :per-page-options="[15, 25, 50, 100, 200]"
        :clear-filters-route="$route('services.index', {remember: 'forget'})"
        @show-filters="$refs.searchFilterSlideOver.show()"
    />

    <div class="w-full my-4">
        <div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="services.data.length" class="col-span-2 sm:col-span-1 font-medium text-lg text-gray-700">Showing {{ services.from }} - {{ services.to }} out of {{ services.total }} Services</div>

            <div class="flex col-start-1 col-span-2 sm:col-span-1 sm:col-start-2 sm:justify-self-end">
                <inertia-link :href="$route('services.create')" class="btn btn-orange mr-2">
                    New
                </inertia-link>

                <loading-button :loading="saving" class="btn btn-gray" @click="exportData" :disabled="!services.data.length">
                    Export to CSV
                </loading-button>
            </div>
        </div>

        <div>
            <div class="relative w-full overflow-x-auto">
                <table class="table table-condensed" ref="table">
                    <thead class="font-semibold">
                        <tr>
	                        <th scope="col" class="px-0 text-right text-sm font-semibold text-gray-900">
			                    <span v-tippy="'This column displays an icon if the services is monitored by Pioneer.'">
				                    <icon name="info-circle" class="w-4 h-4 fill-current text-gray-400" />
				                    <span class="sr-only">Pioneer</span>
			                    </span>
	                        </th>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">ID</th>
                            <sortable-table-header v-show="form.selected_headers.includes('Status')" field="status" :filters="filters" route-name="services.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Status</sortable-table-header>
                            <th v-show="form.selected_headers.includes('Vendor')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Vendor</th>
                            <th v-show="form.selected_headers.includes('Company')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Company</th>
                            <th v-show="form.selected_headers.includes('Location')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Location</th>
	                        <th v-show="form.selected_headers.includes('Equipment Label')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 truncate">Equipment Label</th>
	                        <th v-show="form.selected_headers.includes('Description')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Description</th>
                            <sortable-table-header v-show="form.selected_headers.includes('Type')" field="type" :filters="filters" route-name="services.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Type</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Effective Date')" field="effective_date" :filters="filters" route-name="services.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Effective Date</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Termination Date')" field="termination_date" :filters="filters" route-name="services.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Termination Date</sortable-table-header>
                            <th scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr :class="{'table-row-deleted': service.deleted_at}" v-for="service in services.data" :key="service.id">
	                        <td>
			                    <pioneer-indicator v-if="service.compactorMonitor" :compactor-monitor="service.compactorMonitor"/>
	                        </td>
                            <td>
                                <inertia-link data-cy="services.index.show-link" v-if="!service.deleted_at" :href="$route('services.show', [service.id])" class="link">
                                    {{ service.id }}
                                </inertia-link>

                                <span v-else>{{ service.id }}</span>
                            </td>

                            <td v-show="form.selected_headers.includes('Status')">
                                <span class="ml-1 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset" :class="getStatusColorClass(service.status)">
                                    {{ service.status }}
                                </span>
                            </td>

                            <td v-show="form.selected_headers.includes('Vendor')">
                                <inertia-link v-if="service.vendor" :href="$route('vendors.show', [service.vendor_id])" class="link">
                                    {{ service.vendor.name }}
                                </inertia-link>

                                <template v-else>N/A</template>
                            </td>

                            <td v-show="form.selected_headers.includes('Company')">
                                <inertia-link v-if="service.location" :href="$route('client-companies.show', [service.location.client_company_id])" class="link">
                                    {{ service.location.clientCompany.name }}
                                </inertia-link>

                                <template v-else>
                                    N/A
                                </template>
                            </td>

                            <td v-show="form.selected_headers.includes('Location')">
                                <inertia-link v-if="service.location" :href="$route('locations.show', [service.location.id])" class="link">
                                    {{ service.location.name }}
                                </inertia-link>

                                <template v-else>N/A</template>
                            </td>

	                        <td v-show="form.selected_headers.includes('Equipment Label')">
		                        {{ service.label }}
	                        </td>

                            <td v-show="form.selected_headers.includes('Description')">
                                {{ service.description }}
                            </td>
                            <td v-show="form.selected_headers.includes('Type')">
                                {{ service.type }}
                            </td>

                            <td v-show="form.selected_headers.includes('Effective Date')">
                                {{ service.effective_date }}
                            </td>

                            <td v-show="form.selected_headers.includes('Termination Date')">
                                <span v-if="service.termination_date">{{ service.termination_date }}</span>
                                <span v-else class="text-gray-600">Not Set</span>
                            </td>

                            <td class="text-right">
                                <template v-if="!service.deleted_at">
                                    <inertia-link :href="$route('services.show', service.id)" class="link mr-3" title="View This Service">
                                        View
                                        <span class="sr-only">, {{ service.id }}</span>
                                    </inertia-link>

                                    <inertia-link :href="$route('services.edit', service.id)" class="link" title="Edit This Service">
                                        Edit
                                        <span class="sr-only">, {{ service.id }}</span>
                                    </inertia-link>

                                </template>
                                <template v-else>Deleted</template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <pagination :links="services.links" />
        </div>

        <div v-if="!services.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="dumpster" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Services Found</span>
        </div>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #body>
            <form @submit.prevent id="service-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-4 items-center">
                <div class="col-span-6 sm:col-span-1">
                    <Combobox as="div" v-model="form.statuses" multiple>
                        <ComboboxLabel for="statuses" class="form-label">Select a Status</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput
                                    name="statuses"
                                    id="statuses"
                                    class="form-input"
                                    @change="statusComboBoxQuery = $event.target.value"
                                    :display-value="comboDisplayValue"
                                    placeholder="select a status"
                                />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredStatusOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredStatusOptions" :key="option.value" :value="option" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ option }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <icon name="heroicon-check" class="size-5 fill-current" />
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <div class="col-span-6 sm:col-span-1">
                    <Combobox as="div" v-model="form.types" multiple>
                        <ComboboxLabel for="types" class="form-label">Select a Type</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput
                                    name="types"
                                    id="types"
                                    class="form-input"
                                    @change="typeComboBoxQuery = $event.target.value"
                                    :display-value="comboDisplayValue"
                                />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredTypeOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredTypeOptions" :key="option.id" :value="option.id" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                        {{ option.name }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <icon name="heroicon-check" class="size-5 fill-current" />
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <select-input id="missing-vendor-account" name="missing_vendor_account" class="col-span-6 sm:col-span-1" v-model="form.missing_vendor_account" label="Missing Vendor Account">
                    <option value="except">Hide</option>
                    <option value="include">Include</option>
                    <option value="only">Only</option>
                </select-input>

                <select-input id="with-trashed" name="with_trashed" class="col-span-6 sm:col-span-1" v-model="form.with_trashed" label="With Deleted">
                    <option value="except">Hide</option>
                    <option value="include">Include</option>
                    <option value="only">Only</option>
                </select-input>

	            <select-input id="monitored-by-pioneer" icon="pioneer" name="monitored_by_pioneer" class="col-span-6 sm:col-span-1" v-model="form.monitored_by_pioneer" label="Monitored By Pioneer">
		            <option value="except">Hide</option>
		            <option value="include">Include</option>
		            <option value="only">Only</option>
	            </select-input>
            </form>
        </template>
    </slide-over>
</template>

<script>
    // Import Methods
    import { throttle } from 'lodash-es';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';

    // Import Components
    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';
    import SlideOver from '@/Shared/SlideOver.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import IndexSearchForm from '@/Shared/IndexSearchForm.vue';
    import PioneerIndicator from '@/Shared/PioneerIndicator.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';

    // Tailwind UI combobox
    import {
        Combobox,
        ComboboxInput,
        ComboboxLabel,
        ComboboxButton,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Head,
            Icon,
            SlideOver,
            Pagination,
            SelectInput,
            LoadingButton,
            IndexSearchForm,
	        PioneerIndicator,
            SortableTableHeader,

            // Tailwind UI combobox
            Combobox,
            ComboboxInput,
            ComboboxLabel,
            ComboboxButton,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({}),
            },

            services: {
                type: Object,
                required: true
            },

            statuses: {
                type: Array,
                required: true
            },

            types: {
                type: Array,
                required: true
            },

            filters: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    statuses: this.filters.statuses,
	                monitored_by_pioneer: this.filters.monitored_by_pioneer,
                    types: this.types.filter((type)=>this.filters.types.includes(type.id)).map((type)=>type.id),
                    sort_direction: this.filters.sort_direction,
                    sort_by: this.filters.sort_by,
                    per_page: this.filters.per_page,
                    missing_vendor_account: this.filters.missing_vendor_account,
                    with_trashed: this.filters.with_trashed,
                    selected_headers: this.filters.selected_headers,
                },
                statusComboBoxQuery: '',
                typeComboBoxQuery: '',
                saving: false,
                headers: [],
                excludedHeaders: ['ID', 'Actions'],
                mounted: false,
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.saving = true;

                this.$inertia.post(this.$route('csv.services.index'), this.queryFilters, {
                    onFinish: () => { this.saving = false; }
                });
            },

            getStatusColorClass(status) {
                switch(status) {
                    case 'Active':
                        return 'bg-green-100 text-green-800 ring-green-600/20';
                    case 'Terminated':
                        return 'bg-red-100 text-red-800 ring-red-600/20';
                    default:
                        return 'bg-gray-100 text-gray-800 ring-gray-600/20';
                }
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} Items Selected`;
                    } else if (option.length === 1) {
                        return '1 Item Selected';
                    } else {
                        return 'No items selected';
                    }
                }

                return option ?? 'No items selected';
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
	                .filter(th => !th.querySelector('img')) // Filter out svg headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));

                this.headers = headers;
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('services.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            }
        },

        computed: {
            queryFilters() {
                let query = { ...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            filteredStatusOptions() {
                return this.statusComboBoxQuery === ''
                ? this.statuses
                : this.statuses.filter((status) => {
                    return status.toLowerCase().includes(this.statusComboBoxQuery.toLowerCase());
                });
            },

            filteredTypeOptions() {
                return this.typeComboBoxQuery === ''
                ? this.types
                : this.types.filter((type) => {
                    return type.name.toLowerCase().includes(this.typeComboBoxQuery.toLowerCase());
                });
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.statuses,
                    form.types,
                    form.missing_vendor_account,
                    form.with_trashed,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>
