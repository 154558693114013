<template>
	<icon name="info-circle" class="hidden md:block inline fill-current h-4 w-4 ml-1 self-center text-gray-500" v-tippy="{...tippyOptions, content: determineTooltipType()}"/>
</template>
<script>
import Icon from "./Icon.vue"

export default {
	name: 'tooltip-icon',
	components: {Icon},
	props: {
		tooltip: {
			default: ''
		},

		type: {
			type: String,
			default: 'text'
		}
	},

	data() {
		return {
			tippyOptions: {
				allowHTML: true,
				theme:'light',
			},
		};
	},

	methods: {
		htmlTooltip(content) {
				return `
	        <div>
	          ${content}
	        </div>
      `;
		},

		listContent(items) {
			let listItems = items.map(item => `<li>${item}</li>`).join('');
			return this.htmlTooltip(`<ul class="list-disc list-inside text-md">${listItems}</ul>`);
		},

		determineTooltipType() {
			if (this.type === 'list') {
				return this.listContent(this.tooltip);
			} else {
				// Default to 'text' type
				return this.htmlTooltip(this.tooltip);
			}
		}
	}
}
</script>