<template>
    <Head :title="`Client Contract - ${clientContract.id}`"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-contracts.index')" class="breadcrumb-link">Client Contracts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ clientContract.id }}</span>
        </nav>
    </Teleport>

    <!-- Page header -->
    <div
        class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div class="flex-shrink-0">
                <icon name="file-signature" class="h-14 w-14" />
            </div>

            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ clientContract.id }}
                    <span v-if="clientContract.external_reference_id" class="text-lg font-semi-bold text-orange-300">{{ clientContract.external_reference_id ?? 'EXT-123ABC' }}</span>
                </h1>

                <p class="text-sm font-medium text-gray-500">
                    <span class="text-gray-900">Client Contract</span> created at <time :datetime="clientContract.created_at">{{ $filters.format_date_time(clientContract.created_at) }}</time>
                </p>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <inertia-link :href="$route('client-contracts.edit', [clientContract.id])" class="btn btn-gray">Edit</inertia-link>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Contract details.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Client Company</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link :href="$route('client-companies.show', [clientContract.client_company_id])" class="link">{{ clientContract.clientCompany.name }}</inertia-link>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Integration Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date(clientContract.integration_date) }}</dd>
                            </div>

                            <!-- This is null in prod right now !-->
                            <div v-if="clientContract.termination_date" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Termination Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date(clientContract.termination_date) }}</dd>
                            </div>

                            <!-- This is null in prod right now !-->
                            <div v-if="clientContract.expiration_date" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Termination Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date(clientContract.expiration_date) }}</dd>
                            </div>

                            <!-- This is null in prod right now !-->
                            <div v-if="clientContract.last_renewal_date" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Last Renewal Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientContract.last_renewal_date }}</dd>
                            </div>

                            <!-- This is null in prod right now !-->
                            <div v-if="clientContract.delivery_date" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Delivery Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date(clientContract.delivery_date) }}</dd>
                            </div>

                            <div v-if="clientContract.term_months" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Term Months</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientContract.term_months }}</dd>
                            </div>

                            <div v-if="clientContract.auto_renew_months" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Auto Renewal Term Months</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientContract.auto_renew_months }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <!-- Locations !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="locations-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="locations-title" class="text-lg leading-6 font-medium text-gray-900">Locations</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Locations serviced by this contract's Vendor</p>
                        </div>

                        <div v-if="locations.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('locations.create')" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="locations.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Name</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Address</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="location in locations.slice(0, 12)" :key="location.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                                    <inertia-link class="link" :href="$route('locations.show', [location.id])">{{ location.name }}</inertia-link>
                                                </td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">{{ location.address.full_address }}</td>

                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('locations.show', [location.id])" class="link mr-2">
                                                        View <span class="sr-only">, {{ location.id }}</span>
                                                    </inertia-link>

                                                    <inertia-link :href="$route('locations.edit', [location.id])" class="link">
                                                        Edit<span class="sr-only">, {{ location.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="map-marker-alt" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No locations</h3>
                            <p class="mt-1 text-sm text-gray-500">Create a location.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('locations.create')" class="btn btn-orange">
                                    <!-- Heroicon name: solid/plus -->
                                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                    </svg>
                                    New Location
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="locations.length">
                        <inertia-link :href="$route('locations.index', {search: clientContract.id})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Services !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="services-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="services-title" class="text-lg leading-6 font-medium text-gray-900">Active Services</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Active services on this contract.</p>
                        </div>

                        <div v-if="services.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('services.create')" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="services.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Id</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Type</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Status</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Effective Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Termination Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Pass Through</th>
	                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Equipment Label</th>
	                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Description</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="service in services.slice(0, 12)" :key="service.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">
                                                    <inertia-link class="link" :href="$route('services.show', [service.id])">{{ service.id }}</inertia-link>
                                                </td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.type }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.status }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ $filters.format_date(service.effective_date) }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.termination_date ? $filters.format_date(service.termination_date) : 'Not Set' }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.pass_through ? 'Yes' : 'No' }}</td>
	                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.label }}</td>
	                                            <td class="py-2 pl-4 pr-3 text-sm sm:pl-6 text-gray-500">{{ service.description }}</td>

                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('services.show', [service.id])" class="link mr-3">
                                                        View<span class="sr-only">, {{ service.id }}</span>
                                                    </inertia-link>

                                                    <inertia-link :href="$route('services.edit', [service.id])" class="link">
                                                        Edit<span class="sr-only">, {{ service.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="dumpster" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Services</h3>
                            <p class="mt-1 text-sm text-gray-500">Create a service.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('services.create')" class="btn btn-orange">
                                    <!-- Heroicon name: solid/plus -->
                                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                    </svg>
                                    New Service
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="services.length">
                        <inertia-link :href="$route('services.index', {search: clientContract.id})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Client Contract Documents !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="vendor-service-fees-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="vendor-service-fees-title" class="text-lg leading-6 font-medium text-gray-900">Contract Documents</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">
                                Any documents that you wish to store for this contract.
                            </p>
                        </div>

                        <div v-if="clientContract.documents.length > 0" class="px-4 py-5 sm:px-6">
                            <input type="file" ref="filePendingDocuments" class="form-input" @change="addPendingFileUploads" multiple />
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="pendingFileUploads.length > 0">
                            <span class="block form-label">Pending Uploads</span>

                            <!-- This example requires Tailwind CSS v2.0+ -->
                            <div v-if="errors?.pendingFileUpload?.file" class="rounded-md bg-red-50 p-4 my-4">
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <!-- Heroicon name: solid/x-circle -->
                                        <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                                        </svg>
                                    </div>

                                    <div class="ml-3">
                                        <h3 class="text-sm font-medium text-red-800">There were errors with your uploads</h3>
                                        <div class="mt-2 text-sm text-red-700">
                                            <ul role="list" class="list-disc pl-5 space-y-1">
                                                <li>{{ errors?.pendingFileUpload?.file }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- This example requires Tailwind CSS v2.0+ -->
                            <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                                <li v-for="(upload, index) in pendingFileUploads" :key="index" class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div class="w-full flex items-center justify-between p-6 space-x-6">
                                        <div class="flex-1 truncate">
                                            <div class="flex items-center space-x-3">
                                                <h3 class="text-gray-900 text-sm font-medium truncate">{{ upload.file.name }}</h3>
                                                <span class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">{{ $filters.format_file_size(upload.file.size) }}</span>
                                            </div>
                                            <p class="mt-1 text-gray-500 text-sm truncate">{{ upload.file.type }}</p>
                                        </div>
                                    </div>

                                    <div class="w-full p-3">
                                        <textarea-input label="Notes" v-model="upload.notes" :errors="errors?.pendingFileUpload?.notes" :autosize="true" />
                                    </div>

                                    <div>
                                        <div class="-mt-px flex divide-x divide-gray-200">
                                            <div class="w-0 flex-1 flex">
                                                <a href="" @click.prevent="storePendingFileUpload(upload)" :loading="upload.uploading" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                                                    <icon v-if="upload.uploading" name="spinner" class="inline fill-current text-gray-400 w-5 h-5 animate-spin" />
                                                    <icon v-else class="inline fill-current w-4 h-4" name="upload" />

                                                    <span class="ml-3">Upload</span>
                                                </a>
                                            </div>

                                            <div class="-ml-px w-0 flex-1 flex">
                                                <a href="" @click.prevent="removePendingFileUpload(file)" class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                                                    <icon name="trash" class="w-4 h-4 fill-current" />
                                                    <span class="ml-3">Remove</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div v-if="clientContract.documents.length > 0" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Filename</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Size</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Type</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Notes</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="document in clientContract.documents" :key="document.id">
                                                <td>
                                                    <div class="flex">
                                                        <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                                                        </svg>
                                                        <a :href="$route('documents.preview', { path: document.file_path })" class="ml-2 font-medium text-blue-600 hover:text-blue-500" target="_blank">
                                                            {{ document.file_name }}
                                                        </a>
                                                    </div>
                                                </td>
                                                <td>{{ $filters.format_file_size(document.file_size) }}</td>
                                                <td>{{ document.file_mime_type }}</td>
                                                <td>{{ document.notes }}</td>
                                                <td>
                                                    <a :href="$route('documents.preview', { path: document.file_path })" class="font-medium link mr-3" target="_blank">
                                                        Download
                                                    </a>

                                                    <a href="" @click.prevent="removeDocument(document)" class="font-medium">
                                                        Remove
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="mt-6 text-center">
                            <label class="flex flex-col items-center justify-center px-4 py-2 cursor-pointer">
                                <icon name="file-alt" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                                <h3 class="mt-2 text-sm font-medium text-gray-900">No documents available</h3>

                                <p class="mt-1 text-sm text-gray-500">Upload a document for this service.</p>

                                <input type="file" ref="filePendingDocuments" class="hidden" @change="addPendingFileUploads" multiple />
                            </label>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import AddressPicker from '@/Shared/AddressPicker.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import Bin from '@/Shared/Services/Bin.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            TextareaInput,
            LoadingButton,
            AddressPicker,
            TextInput,
            DateInput,
            SelectInput,
            Icon,
            Bin,
            Head,
        },

        props: {
            clientContract: {
                required: true
            },

            services: {
                type: Array,
                required: true
            },

            locations: {
                type: Array,
                required: true
            },

            errors: {
                type: Object,
                default: () => ({})
            },

        },

        data() {
            return {
                state: 'passive',
                pendingFileUploads: [],
                mounted: false,
            };
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            addPendingFileUploads(e) {
                [...e.target.files].forEach(file => {
                    let pendingFileUpload = {
                        file: file,
                        name: file.name,
                        notes: '', 
                        uploading: false
                    };

                    this.pendingFileUploads.push(pendingFileUpload);
                    this.$refs.filePendingDocuments.value = '';
                });
            },

            storePendingFileUpload(pendingDocument) {
                if (pendingDocument.uploading !== true) {
                    let formData = new FormData();
                    formData.append('file', pendingDocument.file);
                    formData.append('notes', pendingDocument.notes);
                    pendingDocument.uploading = true;
    
                    this.$inertia
                        .post(this.$route('client-contracts.client-contract-documents.store', this.clientContract.id), formData, {
                            errorBag: 'pendingFileUpload',
                            preserveScroll: true,
                            onSuccess: () => {
                                this.pendingFileUploads.splice(this.pendingFileUploads.indexOf(pendingDocument), 1);
    
                            },
                            onFinish: () => pendingDocument.uploading = false,
                        });
                }
            },

            removePendingFileUpload(pendingFileUpload) {
                this.pendingFileUploads.splice(this.pendingFileUploads.indexOf(pendingFileUpload), 1);
            },

            removeDocument(clientContractDocument) {
                let confirmed = confirm("Are you sure you wish to perform this action?");

                if (confirmed) {
                    this.$inertia
                        .delete(this.$route('client-contract-documents.destroy', clientContractDocument.id), {
                            preserveScroll: true,
                            onSuccess: () => {
                                
                            },
                            onFinish: () => {

                            },
                        });
                }
            },
        }
    }
</script>