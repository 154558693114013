<template>
    <Head title="Create Client Company" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-companies.index')" class="breadcrumb-link">Client Companies</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>New</span>
        </nav>
    </Teleport>

    <form id="create-client-company-form" class="space-y-8" @submit.prevent="submitForm">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Information</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">General information that you wish to make available for this company.</p>
                </div>
            </legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <text-input class="col-span-2 md:col-span-1" v-model="form.name" label="Name" placeholder="Name" :errors="errors.name" mark-as-required />

                <select-input class="col-span-2 md:col-span-1" v-model="form.status" label="Status" :errors="errors.status" mark-as-required>
                    <option v-for="status in statuses" :key="status" :value="status">{{ status }}</option>
                </select-input>

                <select-input class="col-span-2 md:col-span-1" v-model="form.account_representative_employee_id" label="Account Representative" :errors="errors.account_representative_employee_id">
                    <option :value="null">Unsure</option>
                    <option v-for="employee in employees" :key="employee.id" :value="employee.id">{{ employee.name }}</option>
                </select-input>

                <select-input class="col-span-2 md:col-span-1" v-model="form.industry" label="Industry" :errors="errors.industry">
                    <option :value="null">Unsure</option>
                    <option v-for="industry in industries" :key="industry" :value="industry">{{ industry }}</option>
                </select-input>

                <text-input class="col-span-2 md:col-span-1" v-model="form.primary_contact_phone" label="Phone" placeholder="Phone" :errors="errors.primary_contact_phone" />
                <text-input class="col-span-2 md:col-span-1" v-model="form.primary_url" label="URL" placeholder="URL" :errors="errors.primary_url" />
                <text-input
                    v-model="form.external_reference_id"
                    label="External Reference Id"
                    class="col-span-2 sm:col-start-1 sm:col-span-1"
                    :errors="errors.external_reference_id"
                    help-text="Allows for external references IDs"
                />
            </div>
        </fieldset>

        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Contract Document</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">A contract document between this company and your own. This may contain master terms and conditions, etc.</p>
                </div>
            </legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <text-input class="col-span-2 md:col-span-1" type="file" name="contract_document" label="Contract" :errors="errors.contract_document" @change="attachContractDocument" />

                <div class="col-span-2 md:col-span-1" v-if="form.contract_document_path">
                    <span class="form-label">Current Uploaded Document</span>

                    <a :href="$route('documents.preview', {path: form.contract_document_path})" class="flex items-center mt-1 py-2" target="_blank" title="Preview This Document">
                        <icon name="file-contract" class="text-red-600 inline h-5 w-5 fill-current mr-2" /> No filename Available
                    </a>
                </div>
            </div>
        </fieldset>

        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Letter of Authorization</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">A document that this company provides which authorizes others to perform certain duties on their behalf.</p>
                </div>
            </legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <text-input class="col-span-2 md:col-span-1" type="file" v-model="form.letter_of_authorization_document" name="letter_of_authorization_document" label="Letter of Authorization" :errors="errors.letter_of_authorization_document" @change="attachLetterOfAuthorization" />

                <div class="col-span-2 md:col-span-1" v-if="clientCompany.letter_of_authorization_document_path">
                    <span class="form-label">Current LOA Document</span>

                    <a :href="$route('documents.preview', {path: clientCompany.letter_of_authorization_document_path})" class="flex items-center mt-1 py-2" target="_blank" title="Preview This Document">
                        <icon name="file-contract" class="text-red-600 inline h-5 w-5 fill-current mr-2" /> Letter of Authorization
                    </a>
                </div>

                <date-input class="col-span-2 md:col-span-1" v-model="form.letter_of_authorization_signed_date" label="Letter of Authorization Signed Date" :errors="errors.letter_of_authorization_signed_date" />
            </div>
        </fieldset>

        <div class="pt-8">
            <loading-button type="submit" :loading="state === 'saving'" class="btn btn-orange">
                Save Changes
            </loading-button>
        </div>
    </form>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import QuillEditor from '@/Shared/QuillEditor.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            DateInput,
            LoadingButton,
            SelectInput,
            TextInput,
            Icon,
            Head,
            QuillEditor
        },

        props: {
            errors: {
                type: Object,
                default: () => ({
                    contractDocument: {},
                    letterOfAuthorization: {}
                })
            },

            clientCompany: {
                type: Object,
                required: true
            },

            employees: {
                type: Array,
                required: true
            },

            statuses: {
                type: Array,
                required: true
            },

            industries: {
                type: Array,
                required: true
            },
        },

        data() {
            return {
                form: { ...this.clientCompany, letter_of_authorization_document: null},
                pendingContractDocument: null,
                pendingLetterOfAuthorizationDocument: null,
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm() {
                if (this.state === 'passive') {
                    this.state = 'saving';

                    let formData = new FormData();
                    formData.append('name', this.form.name);
                    formData.append('status', this.form.status);
                    formData.append('primary_contact_phone', this.form.primary_contact_phone === null ? '' : this.form.primary_contact_phone);
                    formData.append('primary_url', this.form.primary_url === null ? '' : this.form.primary_url);

                    if (this.form.account_representative_employee_id) {
                        formData.append('account_representative_employee_id', this.form.account_representative_employee_id);
                    }

                    if (this.form.industry) {
                        formData.append('industry', this.form.industry);
                    }

                    if (this.form.external_reference_id) {
                        formData.append('external_reference_id', this.form.external_reference_id)
                    }

                    if (this.pendingContractDocument) {
                        formData.append('contract_document', this.pendingContractDocument)
                    }

                    if (this.pendingLetterOfAuthorizationDocument) {
                        formData.append('letter_of_authorization_document', this.pendingLetterOfAuthorizationDocument)
                    }

                    if (this.form.letter_of_authorization_signed_date) {
                        formData.append('letter_of_authorization_signed_date', this.form.letter_of_authorization_signed_date);
                    }

                    this.$inertia
                        .post(this.$route('client-companies.store', this.clientCompany.id), formData, {
                            preserveScroll: true,
                            onFinish: () => this.state = 'passive'
                        });
                }
            },

            attachContractDocument(e) {
                let file = e.target.files[0];

                if (file && file.type === 'application/pdf') {
                    this.pendingContractDocument = file;
                }
            },

            attachLetterOfAuthorization(e) {
                let file = e.target.files[0];

                if (file && file.type === 'application/pdf') {
                    this.pendingLetterOfAuthorizationDocument = file;
                }
            }
        }
    }
</script>
