<style></style>

<template>
    <div class="p-2">
        <h3 class="my-3 p-1 text-d-navy-500 font-medium text-xl">{{ title }}</h3>

        <div class="">
            <div id="waste-spend-by-category-container">

            </div>
        </div>
    </div>
</template>

<script>
    import Highcharts from 'highcharts';
    import {format} from 'date-fns';

    Highcharts.setOptions({
        lang: {
          decimalPoint: '.',
          thousandsSep: ','
        }
    });

    export default {
        props: {
            title: {
                type: String,
                default: 'Waste Spend by Category'
            },

            series: {
                type: Array,
                required: true
            },

            dateRange: {
                type: Array,
                required: true
            }
        },

        mounted() {
            this.drawChart();
        },

        methods: {
            drawChart() {
                Highcharts.chart('waste-spend-by-category-container', {
                    chart: {
                        type: 'column'
                    },
					colors: ['#012639', '#075076', '#5fa3ec', '#fc9776', '#f4c326'],

                    title: {
                        text: null
                    },

                    xAxis: {
                        categories: this.dateRange.map(date => format(date, 'yyyy-MM-dd'))
                    },

                    yAxis: {
                        min: 0,
                        title: {
                            text: null
                        },
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold',
                                color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray',
                                textOutline: '0',
                            },
                            format: "${total:,.2f}"
                        }
                    },

                    legend: {
                        backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
                        borderColor: '#CCC',
                        borderWidth: 1,
                        shadow: false,
                        itemStyle: {
                            fontWeight: 'bold',
                            color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                        }
                    },

                    tooltip: {
                        pointFormatter() {
                            let y = this.y.toLocaleString('en-US', {style : 'currency', currency : "USD", maximumFractionDigits: 2, minimumFractionDigits: 2});
                            let stackTotal = this.stackTotal.toLocaleString('en-US', {style : 'currency', currency : "USD", maximumFractionDigits: 2, minimumFractionDigits: 2});
                            let percent = parseFloat((this.y / this.stackTotal) * 100).toFixed(2) + '%';

                            return `${this.series.name}: ${y} (${percent})<br/>Total: ${stackTotal}`;
                        }
                    },

                    plotOptions: {
                        column: {
                            stacking: 'normal'
                        }
                    },

                    series: this.series.map(function(record) {
                        return {
                            name: record.name,
                            data: record.data.map(amount => amount.amount / 100)
                        };
                    })
                });
            }
        },

        watch: {
            series: {
                handler() {
                    this.drawChart();
                },
                deep: true
            }
        }
    }
</script>