<template>
    <Head :title="`Custom Report - ${customReport.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            Reports

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('custom-reports.index')" class="breadcrumb-link">Custom Reports</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ customReport.name }}</span>
        </nav>
    </Teleport>

    <div class="mt-4">
        <p class="mt-1 text-lg font-bold">
            {{ customReport.name }}
        </p>

        <p class="mt-1">
            {{ customReport.description }}
        </p>
    </div>

    <div class="w-full, my-4">
        <div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="results.data.length" class="col-span-2 sm:col-span-1 font-medium text-lg text-gray-700">Showing {{ results.from }} - {{ results.to }} out of {{ results.total }} Rows</div>

            <div class="flex col-start-1 col-span-2 sm:col-span-1 sm:col-start-2 sm:justify-self-end">
                <inertia-link :href="$route('custom-reports.edit', [customReport.id])" class="btn btn-blue mr-2 my-1">
                    <icon name="edit" class="inline text-white fill-current h-4 w-4 mr-1" /> Edit Report
                </inertia-link>

                <loading-button :loading="saving" class="btn btn-blue my-1" @click="exportData" :disabled="!results.data.length">
                    <icon name="file-export" class="inline text-white fill-current h-4 w-4 mr-2" />Export to CSV
                </loading-button>
            </div>
        </div>

        <div v-if="results.data.length">
            <div class="overflow-x-auto">
                <table class="table table-striped table-condensed min-w-full">
                    <thead>
                        <tr>
                            <th v-for="property in customReport.definition.properties">{{ property.display_name }}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="result in results.data">
                            <td v-for="property in result">{{ property }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <pagination :links="results.links" />
        </div>

        <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="file-alt" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Rows Found</span>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            LoadingButton,
            Pagination,
            Head,
        },

        props: {
            customReport: {
                type: Object,
                required: true
            },

            results: {
                type: Object,
                required: true
            }
        },

        methods: {
            exportData() {
                this.saving = true;

                this.$inertia.post(this.$route('csv.custom-reports.show', this.customReport.id), {}, {
                    onFinish: () => { this.saving = false; }
                });
            }
        },

        data() {
            return {
                saving: false,
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },
    }
</script>