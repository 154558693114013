<template>
    <Head title="Journal Entries - QB Online" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Journal Entries</span>
        </nav>
    </Teleport>

    <main class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="quickbooks-online" />

                <div class="lg:col-span-9 px-4 sm:px-6">
                    <div class="py-6">
                        <div class="hidden lg:block">
                            <div class="border-b border-gray-200">
                                <nav class="-mb-px flex space-x-8">
                                    <!-- I think we could put something here but we'll see -->
                                    <!-- <inertia-link :href="$route('tenant-settings.quickbooks-online.vendor-invoices')" class="whitespace-nowrap py-4 px-1 border-b-2 border-transparent font-medium text-sm text-gray-500 hover:text-gray-700 hover:border-gray-300">
                                        Vendor Invoices (Bills)
                                    </inertia-link> -->
                                    <inertia-link :href="$route('tenant-settings.quickbooks-online.journal-entries')" class="whitespace-nowrap py-4 px-1 border-b-2 border-blue-500 font-medium text-sm text-blue-600">
                                        Journal Entries
                                    </inertia-link>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div class="flex my-4 items-center">
                        <div class="sm:flex sm:items-center">
                            <div class="sm:flex-auto">
                                <h1 class="text-xl font-semibold text-gray-900">Journal Entries</h1>
                                <p class="mt-2 text-sm text-gray-700">
                                    Journal entries allow line items to be grouped together using their GL codes.
                                    These journal entries can be added to your general ledger in Quickbooks.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-1 lg:grid-cols-3 items-center space-x-10">
                        <div class="lg:col-span-2">
                            <select-input label="Status" v-model="form.status">
                                <option>All</option>
                                <option>Posted</option>
                                <option>Unposted</option>
                            </select-input>
                        </div>
                        <div class="pt-6 lg:row-span-1">
                            <loading-button @click.prevent="syncJournalEntriesToAccountingSystem" type="button" :loading="state === 'saving'" class="btn btn-orange float-right">
                                Send Unposted Entries To Accounting System
                            </loading-button>
                        </div>
                    </div>

                    <div v-if="journalEntryLineItems.data.length" class="-mx-4 mt-8 overflow-x-auto shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                        <table class="table table-condensed">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col">
                                        Journal Entry Number
                                    </th>
                                    <th scope="col">
                                        Source Type
                                    </th>
                                    <th scope="col">
                                        Date
                                    </th>
                                    <th scope="col">
                                        GL Account
                                    </th>
                                    <th scope="col">
                                        Description
                                    </th>
                                    <th scope="col">
                                        Debit
                                    </th>
                                    <th scope="col">
                                        Credit
                                    </th>
                                    <th scope="col">
                                        External Accounting ID
                                    </th>
                                </tr>
                            </thead>

                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="lineItem in journalEntryLineItems.data" :key="lineItem.id">
                                    <td>{{ lineItem.document_number }}</td>
                                    <td>{{ lineItem.document_type }}</td>
                                    <td>{{ lineItem.transaction_date }}</td>
                                    <td>{{ lineItem.gl_code }}</td>
                                    <td>{{ lineItem.description }}</td>
                                    <td>
                                        <div v-if="lineItem.posting_type == 'Debit'" class="text-right">{{ $filters.format_money(lineItem.amount) }}</div>
                                        <div v-else class="text-center">-</div>
                                    </td>
                                    <td>
                                        <div v-if="lineItem.posting_type == 'Credit'" class="text-right">{{ $filters.format_money(lineItem.amount) }}</div>
                                        <div v-else class="text-center">-</div>
                                    </td>
                                    <td class="">{{ lineItem.external_accounting_id }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination :links="journalEntryLineItems.links" />
                    </div>

                    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                        <icon name="file-invoice" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Unsynced Journal Entries Found</span>
                    </div>

                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SubNav from '../SubNav.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import { debounce, pickBy } from 'lodash-es';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Pagination,
            Icon,
            LoadingButton,
            SelectInput,
            SubNav,
            Head,
        },

        props: {
            filters: {
                type: Object,
                required: true
            },

            journalEntryLineItems: {
                type: Object,
                required: true
            }
        },

         data() {
            return {
                form: {
                    'status': this.filters.status ?? 'All'
                },
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            syncJournalEntriesToAccountingSystem() {
                if (this.state === 'passive') {
                    this.state = 'syncing';

                    this.$inertia
                        .post(this.$route('tenant-settings.quickbooks-online.unposted-journal-entries'), {}, {
                            onFinish: () => this.state = 'passive'
                        });
                }
            }
        },

        watch: {
            form: {
                handler: debounce(function() {
                    let query = pickBy(this.form);
                    query = Object.keys(query).length ? query : {remember: 'forget'};

                    this.$inertia.get(this.$route('tenant-settings.quickbooks-online.journal-entries'), query, {preserveState: true});
                }, 250),

                deep: true,
            },
        }
    }
</script>