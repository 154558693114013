<template>
    <Head title="Compactor Monitor Efficiency Improvement" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('reports.index')" class="breadcrumb-link">Reports</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Compactor Monitor Efficiency Improvement</span>
        </nav>
    </Teleport>

    <form id="filter-report-data-form" action="" class="grid grid-cols-4 gap-x-4 my-2 mb-4" @submit.prevent>
        <date-input label="Report Date" v-model="form.report_date" />

        <div class="flex items-center mt-6">
            <button type="button" class="mr-4 inline-flex divide-x divide-gray-400 text-gray-800 bg-white font-semibold items-center border border-gray-400 text-sm leading-5 rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray" @click="$refs.reportFilterSlideOver.show()">
                <span class="inline-flex px-3 py-2">
                    <icon name="filter" class="w-5 h-5 text-gray-400 fill-current" />
                    <span class="ml-2">Filters</span>
                </span>

                <span class="relative px-3 py-2 inline-flex items-center bg-d-accent-100 text-sm font-semibold text-d-accent-900 rounded-r-md">
                    {{ filtersInUse }}
                </span>
            </button>

            <inertia-link :href="$route('reports.compactor-monitor-efficiency-improvement')" class="btn btn-gray">Clear</inertia-link>
        </div>
    </form>

    <div v-if="reportData.data.length !== 0">
        <div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center">
            <div class="col-span-2 sm:col-span-1 font-medium text-lg text-gray-700">Showing {{ reportData.from }} - {{ reportData.to }} out of {{ reportData.total }} Results</div>

            <div class="flex col-start-1 col-span-2 sm:col-span-1 sm:col-start-2 sm:justify-self-end">
                <loading-button :loading="state === 'exporting'" class="mt-6 btn btn-gray" @click="exportData">
                    Export to CSV
                </loading-button>
            </div>
        </div>
        <reporting-table :reportData="reportData.data"></reporting-table>
        <pagination v-if="reportData" :links="reportData.links" />
    </div>
    <div v-else>
        <h5>No results found, please adjust your filters.</h5>
    </div>

    <slide-over ref="reportFilterSlideOver">
        <template #header="{close}">
            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <form @submit.prevent id="compactor-monitor-efficiency-improvement-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
                <div class="col-span-1">
                    <Combobox as="div" v-model="form.client_companies" multiple>
                        <ComboboxLabel class="form-label" for="client-companies">Client Companies</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput id="client-companies" name="client-companies" class="form-input" @change="clientCompanyComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="clientCompanies.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="clientCompany in filteredClientCompaniesOptions" :key="clientCompany.id" :value="clientCompany.id" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ clientCompany.name }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <!-- Heroicon name: solid/check -->
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.locations" multiple>
                        <ComboboxLabel class="form-label" for="locations">Locations</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput id="locations" name="locations" class="form-input" @change="locationsComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="locations.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="location in filteredLocationsOptions" :key="location.id" :value="location.id" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ location.name }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <!-- Heroicon name: solid/check -->
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
            </form>
        </template>
    </slide-over>

</template>

<script>
    import DateInput from '@/Shared/DateInput.vue';
    import DropdownLink from '@/Shared/DropdownLink.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import ReportingTable from '@/Shared/ReportingTable.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import { throttle, debounce, pickBy } from 'lodash-es';
    import { Head } from '@inertiajs/vue3';
    import SlideOver from "@/Shared/SlideOver.vue";

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            DateInput,
            DropdownLink,
            Icon,
            LoadingButton,
            Pagination,
            ReportingTable,
            SelectInput,
            Head,
            SlideOver,

            // Tailwind UI combobox.
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            reportData: {
                type: Object,
                required: true
            },

            clientCompanies: {
                type: Array,
                required: true
            },

            locations: {
                type: Array,
                required: true
            },

            filters: Object
        },

        data() {
            return {
                form: {
                    report_date: this.filters.report_date,
                    client_companies: this.filters.client_companies,
                    locations: this.filters.locations,
                },
                saving: false,
                state: 'passive',
                mounted: false,
                filtersInUse: 0,
                clientCompanyComboBoxQuery: '',
                locationsComboBoxQuery: '',
            }
        },

        mounted() {
            this.mounted = true;

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.saving = 'exporting';

                this.$inertia.post(this.$route('csv.compactor-monitor-efficiency-improvement'), {...this.form}, {
                    onFinish: () => { this.saving = 'passive'; }
                });
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route("reports.compactor-monitor-efficiency-improvement"), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = { ...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            filteredClientCompaniesOptions() {
                return this.clientCompanyComboBoxQuery === ''
                    ? this.clientCompanies
                    : this.clientCompanies.filter((clientCompany) => {
                        return clientCompany.name.toLowerCase().includes(this.clientCompanyComboBoxQuery.toLowerCase());
                    });
            },

            filteredLocationsOptions() {
                return this.locationsComboBoxQuery === ''
                    ? this.locations
                    : this.locations.filter((location) => {
                        return location.name.toLowerCase().includes(this.locationsComboBoxQuery.toLowerCase());
                    });
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.report_date,
                    form.client_companies,
                    form.locations
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        },
    }
</script>