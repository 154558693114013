<template>
    <Head title="Create Client Invoice" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-invoices.index')" class="breadcrumb-link">Client Invoices</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <form @submit.prevent="submitForm" id="create-incident-form" class="space-y-8">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Fiscal Period / Client Account</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Fiscal period and client account for client invoice.</p>
                </div>
            </legend>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-6 my-1">
                <select-input v-model="filters.fiscal_period_id" label="Fiscal Period" :errors="errors['fiscal_period_id']" markAsRequired>
                    <option v-for="fiscalPeriod in fiscalPeriods" :key="fiscalPeriod.id" :value="fiscalPeriod.id">
                        {{ fiscalPeriod.year }}-{{ fiscalPeriod.month }}
                    </option>
                </select-input>

                <search-input
                    display-property="name"
                    value-property="id"
                    label="Client Account"
                    class="relative"
                    :errors="errors['client_account_id']"
                    route="json.client-accounts.index"
                    placeholder="Search"
                    :force-valid="true"
                    v-model="filters.client_account_id"
                    :default-value="clientAccount"
                    markAsRequired
                >
                    <template #searchResult="obj">
                        <span class="block mb-1">{{ obj.result.name }}</span>
                    </template>
                </search-input>
            </div>
        </fieldset>

        <div>
        </div>
        <div v-if="!filters.client_account_id || !filters.fiscal_period_id" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="tasks" class="empty-state-icon h-10 w-10" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">Select a fiscal period and client account to continue</span>
        </div>

        <fieldset v-else-if="!existingInvoice" class="space-y-8 sm:space-y-5">
            <legend v-if="billableLineItems.length" class="pt-8 sm:pt-10 w-full grid grid-cols-2">
                <div>
                    <h3 class="text-xl leading-6 font-medium text-gray-900">
                        Un-billed Line Items
                        <icon v-if="fetching" name="spinner" class="animate-spin fill-current inline h-4 w-4 ml-2" />
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">These are line items that can be added to the client invoice.</p>
                </div>
                <div v-if="billableLineItems.length">
                    <loading-button type="submit" class="float-right btn btn-blue">Create</loading-button>
                </div>
            </legend>

            <div v-if="billableLineItems.length" class="w-full">
                <div class="w-full my-6 items-center flex">
                    <input
                        type="checkbox"
                        :disabled="!props.billableLineItems.length"
                        class="ml-5 h-5 w-5 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500"
                        :checked="props.billableLineItems.length && selectedItems.length === props.billableLineItems.length"
                        @change="selectedItems = $event.target.checked ? props.billableLineItems.map((billableLineItem) => billableLineItem.key) : []"
                    />
                    <span class="pl-4 text-lg">Select All Un-billed Line Items</span>
                </div>

                <template v-for="locationLineItems in groupBy(props.billableLineItems, 'location_id')">
                    <div v-if="locationLineItems[0].location_id" class="bg-white pb-2 text-left sm:pl-3">
                        <span class="block font-medium text-xl text-gray-900">
                            <a target="_blank" :href="$route('locations.show', locationLineItems[0].location_id)">{{ locationLineItems[0].location_name }}</a>
                        </span>
                        <span class="italic text-sm text-gray-500">
                            {{ locationLineItems[0].location_address }}
                        </span>
                    </div>

                    <table class="table table-condensed w-full mb-12">
                        <thead>
                            <tr>
                                <th scope="col" class="!border-gray-500 w-12 whitespace-nowrap"></th>
                                <th scope="col" class="!border-gray-500 w-1/5 whitespace-nowrap px-2 py-3 text-left text-sm text-gray-900 sm:pl-6">Date</th>
                                <th scope="col" class="!border-gray-500 w-1/5 whitespace-nowrap px-2 py-3 text-left text-sm text-gray-900 sm:pl-6">Type</th>
                                <th scope="col" class="!border-gray-500 w-1/5 whitespace-nowrap px-2 py-3 text-left text-sm text-gray-900 sm:pl-6">Origin</th>
                                <th scope="col" class="!border-gray-500 w-1/5 whitespace-nowrap px-2 py-3 text-left text-sm text-gray-900">Description</th>
                                <th scope="col" class="!border-gray-500 w-1/5 whitespace-nowrap px-2 py-3 text-right text-sm text-gray-900">Amount</th>
                            </tr>
                        </thead>

                        <tbody class="bg-white">
                            <template v-for="lineItems in groupBy(locationLineItems, 'service_id')">
                                <tr v-if="lineItems[0].service_id" class="border-t border-gray-200 bg-gray-50">
                                    <td colspan="6" class="py-3 pl-4 text-left">
                                        <span class="text-lg font-medium">
                                            <a target="_blank" :href="$route('services.show', lineItems[0].service_id)">{{ lineItems[0].service_id }}</a>
                                        </span>
                                        <span class="italic pl-2 text-sm text-gray-500">
                                            {{ lineItems[0].service_description }}
                                        </span>
                                    </td>
                                </tr>

                                <tr v-for="lineItem in lineItems" :key="lineItem.key">
                                    <td class="relative">
                                        <div v-if="selectedItems.includes(lineItem.key)" class="absolute inset-y-0 left-0 w-0.5 bg-d-orange-600"></div>
                                        <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :value="lineItem.key" v-model="selectedItems" />
                                    </td>
                                    <td class="px-3 py-4 text-sm text-gray-900">
                                        {{ $filters.format_date(lineItem.date) }}
                                    </td>
                                    <td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:w-auto sm:max-w-none sm:pl-6">
                                        {{ lineItem.origin_type === 'Service' ? 'Service Charge' : lineItem.origin_type }}
                                    </td>
                                    <td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:w-auto sm:max-w-none sm:pl-6">
                                        <a target="_blank" :href="$route(lineItem.origin_route, lineItem.origin_id)">{{ lineItem.origin_label }}</a>
                                    </td>
                                    <td class="px-3 py-4 text-sm text-gray-900">
                                        {{ lineItem.description }}
                                    </td>
                                    <td class="py-4 pl-3 pr-2 text-sm font-medium text-right">
                                        {{ $filters.format_money(lineItem.amount) }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </template>
            </div>

            <div v-else>
                <h1 class="mt-2 text-xl font-medium text-gray-900">There are no billable line items for the selected fiscal period and client account.</h1>
            </div>
        </fieldset>

        <div v-if="existingInvoice && filters.client_account_id">
            <inertia-link :href="$route('client-invoices.show', existingInvoice.id)" class="btn btn-blue">View Existing Invoice</inertia-link>
        </div>
    </form>
</template>

<script setup>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import { Head, router } from '@inertiajs/vue3';
    import { ref, inject, watch, onMounted } from 'vue';
    import { groupBy } from "lodash-es";

    const props = defineProps({
        fiscalPeriod: {
            type: Object,
            required: true
        },

        clientAccount: {
            type: Object,
            required: false
        },

        fiscalPeriods: {
            type: Object,
            required: true
        },

        billableLineItems: {
            type: Array,
            required: true
        },

        existingInvoice: {
            type: Object,
            required: false
        },

        errors: {
            type: Object,
            default: () => ({})
        },
    });

    const saving = ref(false);
    const fetching = ref(false);

    const indeterminate = ref(false);
    const selectedItems = ref([]);

    const mounted = ref(false);
    const filters = ref({
        fiscal_period_id: props.fiscalPeriod.id,
        client_account_id: props.clientAccount?.id ?? null,
    });

    const route = inject('route');

    onMounted(() => {
        mounted.value = true;
    });

    function submitForm() {
        if (saving.value === true) {
            return;
        }

        saving.value = true;

        router.post(route('client-invoices.store'), {
            'fiscal_period_id': filters.value.fiscal_period_id,
            'client_account_id': filters.value.client_account_id,
            'billable_line_items': selectedItems.value.map((item) => {
                let split = item.split('|')
                return {id: split[1], origin_type: split[0]}
            })
        }, {
            onFinish: () => saving.value = false
        });
    }

    watch(() => filters, () => {
        fetching.value = true
        router.get(route('client-invoices.create'), {
            fiscal_period_id: filters.value.fiscal_period_id,
            client_account_id: filters.value.client_account_id
        }, {
            preserveState: true,
            onSuccess: () => {
                fetching.value = false
            }
        });
    }, {deep: true})
</script>

<style scoped>
.search-icon {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
}
.search-animation {
    animation-name: search-animation;
    animation-timing-function: ease;
}
@keyframes search-animation {
    0%   { transform: translateY(0); }
    30%  { transform: translateY(-10px); }
    50%  { transform: translateY(0) translateX(5px); }
    100% { transform: translateY(0) translateX(0); }
}
</style>
