<template>
    <div class="bg-white overflow-hidden shadow rounded-lg">
        <div class="px-4 py-4 sm:p-5">
            <dl>
                <div class="grid grid-cols-3 pb-4">
                    <dt class="col-span-2 text-sm leading-5 font-medium whitespace-normal text-gray-500  inline">
                        {{ label }}<icon v-if="showLoading" name="spinner" class="inline animate-spin fill-current h-4 w-4 ml-3"/>
                    </dt>
                </div>

                <div class="grid grid-cols-3 lg:grid-cols-3 align-middle">
                    <div class="col-span-2 ">
                    <dd class="mt-4 mb-0 font-semibold">
                        <p class="inline text-3xl">{{ displayObject?.count ?? displayObject }} <span v-if="previousCount" class="text-sm truncate font-medium text-gray-500">
                            from: {{ previousCount }}
                        </span></p>

                    </dd>
                    </div>
                    <div class="lg:col-span-1 text-right">
                        <dd v-if="showTrend && trend && count > previousCount" class="w-full inset-y-0 h-3/5 mt-4 text-right text-lg font-medium text-center">
                            <p class="text-sm inline my-4 px-4 py-2 text-right text-green-700 text-center bg-green-200 rounded-full"><icon class="inline w-6 h-5 mb-1 fill-current text-green-700" name="long-arrow-up"/>{{ trend }}% </p>
                        </dd>
                        <dd v-if="showTrend && trend && count < previousCount" class="w-full inset-y-0 h-3/5 mt-4 text-right text-lg font-medium text-center">
                            <p class="text-sm inline my-4 px-4 py-2 text-right text-red-700 text-center rounded-full bg-red-200"><icon class="inline inline w-6 h-5 mb-1 fill-current text-red-700" name="long-arrow-down"/>{{ trend }}%</p>
                        </dd>
                        <dd v-else-if="showTrend && !trend && !previousCount" class="w-full inset-y-0 h-3/5 mt-4 text-right text-lg font-medium text-center ">
                            <p class="text-sm inline px-4 py-2 text-right text-center bg-gray-200 rounded-full"><icon class="inline w-6 h-5 mt-1 my-2 fill-current text-red-700" />--%</p>
                        </dd>
                    </div>
                </div>
            </dl>
        </div>
    </div>
</template>

<script>
    import SelectInput from "./SelectInput.vue";
    import Icon from "./Icon.vue";

    export default {
        components: {Icon, SelectInput},
        inheritAttrs: false,

        props: {
            label: {
                type: String,
            },

            displayObject: {
                default: 'text',
            },

            showTrend: {
                type: Boolean,
                default: false
            },

            modelValue: {
                type: String,
                default: null
            },

            loading: {
                type: Boolean,
                default: false
            },

            options: {
                type: Array,
                default: () => []
            }
        },

        data() {
           return {
               count: this.displayObject?.count ?? this.displayObject,
               previousCount: this.displayObject?.previousCount ?? null,
               trend: this.displayObject?.trend ?? null,
               showLoading: this.loading,
            }
        },

        methods: {
            changeDateRange() {
                this.$emit('update:modelValue', this.modelValue);
            }
        },

        onRenderTriggered() {
            this.showLoading = false;
        }
    }
</script>
