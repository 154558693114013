<template>
    <Head title="Create Service"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="data.mounted">
        <nav aria-label="breadcrumbs" class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Create</span>
        </nav>
    </Teleport>

    <nav aria-label="Progress">
        <ol role="list" class="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
            <li v-for="(step, stepIdx) in data.steps" :key="step.name" class="relative md:flex-1 md:flex">
                <a
                    v-if="step.status === 'complete'"
                    :href="step.href"
                    class="group flex items-center w-full"
                    @click="transitionActiveStep(stepIdx+1)"
                >
                    <span class="px-6 py-4 flex items-center text-sm font-medium">
                        <span class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-d-blue-500 rounded-full group-hover:bg-d-blue-800">
                            <icon name="check" class="w-5 h-5 fill-current text-white" aria-hidden="true"/>
                        </span>
                    <span class="ml-4 text-sm font-medium text-gray-900">{{ step.name }}</span>
                  </span>
                </a>
                <a
                    v-else-if="step.status === 'current'"
                    :href="step.href"
                    class="px-6 py-4 flex items-center text-sm font-medium"
                    aria-current="step"
                    @click="transitionActiveStep(stepIdx+1)"
                >
                    <span class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-d-blue-500 rounded-full">
                         <span class="text-d-blue-500">{{ step.id }}</span>
                    </span>
                    <span class="ml-4 text-sm font-medium text-d-blue-500">{{ step.name }}</span>
                </a>
                <a v-else :href="step.href" class="group flex items-center" @click="transitionActiveStep(stepIdx+1)">
                    <span class="px-6 py-4 flex items-center text-sm font-medium">
                        <span class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                            <span class="text-gray-500 group-hover:text-gray-900">{{ step.id }}</span>
                        </span>
                    <span class="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{{ step.name }}</span>
                    </span>
                </a>
                <template v-if="stepIdx !== data.steps.length - 1">
                    <!-- Arrow separator for lg screens and up -->
                    <div class="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                        <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                            <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </template>
            </li>
        </ol>
    </nav>

    <div class="mt-4 grid grid-cols-2">
        <span class="col-span-2 md:col-span-1" v-if="data.activeStep === 1">Step 1: Select a service type and a location.</span>
        <span class="col-span-2 md:col-span-1" v-if="data.activeStep === 2">Step 2: Enter details for your selected service type.</span>
    </div>

    <div class="mt-8 mb-12">
        <div v-show="data.activeStep === 1" class="space-y-6">
            <div class="rounded-md bg-blue-50 p-4 my-3">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <!-- Heroicon name: solid/information-circle -->
                        <svg
                            class="h-5 w-5 text-d-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </div>
                    <div class="ml-3 flex-1 md:flex md:justify-between">
                        <p class="text-sm text-d-blue-700">Please note: Service creation will be backdated to the effective date if the effective date is in the past.</p>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-1 sm:grid-cols-3 gap-x-4 gap-y-6 my-1">
                <select-input v-model="data.serviceType" label="Service Type" :errors="errors['service_type_id']" markAsRequired>
                    <option v-for="type in serviceTypes" :value="type" :key="type">{{ type.name }}</option>
                </select-input>

                <search-input
                    v-model="form.location_id"
                    display-property="name"
                    value-property="id"
                    label="Location"
                    class="relative"
                    :errors="errors['location_id']"
                    route="json.locations.index"
                    placeholder="Search"
                    :force-valid="true"
                    @updated="locationChanged"
                    markAsRequired
                >
                    <template #searchResult="obj">
                        <span class="block mb-1">{{ obj.result.name }}</span>
                        <span class="block text-sm font-bold">{{ obj.result.clientCompany.name }}</span>
                    </template>
                </search-input>

                <date-input
                    v-model="form.effective_date"
                    class="col-span-2 md:col-span-1"
                    label="Effective Date"
                    placeholder="Effective Date"
                    :errors="errors['effective_date']"
                    @blur="syncEffectiveDateWithPickupStartDate"
                />

                <text-input
                    v-model="form.external_reference_id"
                    label="External Reference Id"
                    :errors="errors.external_reference_id"
                />
            </div>

            <div class="flex w-full mt-8 flex-row-reverse">
                <a class="btn btn-gray" :class="stepLinkEnabled(data.activeStep+1)" @click="transitionActiveStep(data.activeStep+1)">
                    <span class="mr-2">Next Step</span>
                    <icon name="long-arrow-right" class="inline fill-current w-4 h-4"/>
                </a>
            </div>
        </div>

        <div v-show="data.activeStep === 2">
            <div>
                <fieldset>
                    <legend class="font-semibold text-xl text-d-blue-500">Vendor</legend>

                    <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-4">
                        <search-input
                            v-model="form.vendor_id"
                            display-property="name"
                            value-property="id"
                            label="Vendor"
                            class="relative col-span-2 md:col-span-1"
                            :errors="errors['vendor_id']"
                            route="json.vendors.index"
                            placeholder="Search"
                            @updated="vendorChanged"
                            markAsRequired
                        >
                            <template #searchResult="obj">
                                <span class="block mb-1">{{ obj.result.name }}</span>
                            </template>
                        </search-input>

                        <select-input
                            v-model="form.vendor_account_id"
                            class="col-span-2 md:col-span-1"
                            label="Vendor Account"
                            :errors="errors['vendor_account_id']"
                        >
                            <option v-if="form.vendor_id && !data.availableVendorAccounts.length" :value="null">
                                No accounts available for this Vendor
                            </option>
                            <option v-for="vendorAccount in data.availableVendorAccounts" :value="vendorAccount.id">
                                {{ vendorAccount.account_number + " - " + vendorAccount.status }}
                            </option>
                        </select-input>

                        <select-input
                            v-model="form.vendor_contract_selection"
                            class="col-span-2 md:col-span-1"
                            label="Vendor Contract"
                            :errors="errors['vendor_contract_selection']"
                        >
                            <option :value="'missing-contract'">Missing contract</option>
                            <option :value=null>No contract</option>
                            <option v-for="vendorContract in data.availableVendorContracts" :value="vendorContract.id">
                                {{ vendorContract.display_id + " - " + vendorContract.status + " - Effective " + vendorContract.effective_date ?? " - " }}
                            </option>
                        </select-input>

                        <select-input
                            v-model="form.service_account_type"
                            class="col-span-2 md:col-span-1"
                            label="Service Account Type"
                            :errors="errors['service_account_type']"
                            markAsRequired
                        >
                            <option>Permanent</option>
                            <option>Temporary</option>
                        </select-input>

                        <select-input
                            v-if="data.serviceType?.enabled_type_fields?.includes('service_schedule_info')"
                            v-model="form.service_schedule_type"
                            class="col-span-2 md:col-span-1"
                            label="Service Schedule Type"
                            :errors="errors['service_schedule_type']"
                            markAsRequired
                        >
                            <option>Scheduled</option>
                            <option>On Call</option>
                            <option v-if="form.service_account_type === 'Temporary'">One Time</option>
                        </select-input>

                        <div
                            v-if="form.service_schedule_type === 'Scheduled' && data.serviceType?.enabled_type_fields?.includes('service_schedule_info')"
                            class="col-span-2 md:col-span-1"
                        >
                            <span class="form-label">
                                <span class="text-red-500">*</span>
                                Schedule
                            </span>

                            <div class="mb-4">
                                {{ data.rrule_description }}
                            </div>

                            <div>
                                <a href="" @click.prevent="showUpdatePickupRRuleModal" class="btn btn-orange">
                                    Change
                                </a>
                            </div>

                            <div v-if="errors['pickup_rrule'] || errors['average_pickups_per_week']" class="text-red-500 text-sm">
                                The pickup schedule is invalid
                            </div>
                        </div>

                        <money-input
                            v-if="data.serviceType?.enabled_type_fields?.includes('base_charges')"
                            v-model="form.vendor_base_charge"
                            class="col-span-2 md:col-span-1"
                            :defaultCurrency="data.vendorCurrency"
                            label="Vendor Base Charge"
                            :errors="errors['vendor_base_charge']"
                        />

                        <money-input
                            v-if="data.serviceType?.enabled_type_fields?.includes('per_occurrence_charges')"
                            v-model="form.vendor_per_occurrence_charge"
                            class="col-span-2 md:col-span-1"
                            :defaultCurrency="data.vendorCurrency"
                            label="Vendor Per Occurrence Charge"
                            :helpText="perOccurrenceHelpText"
                            :errors="errors['vendor_per_occurrence_charge']"
                        />

                        <money-input
                            v-if="data.serviceType?.enabled_type_fields?.includes('per_unit_charges')"
                            v-model="form.vendor_per_unit_charge"
                            class="col-span-2 md:col-span-1"
                            :defaultCurrency="data.vendorCurrency"
                            label="Vendor Per Unit Charge"
                            :helpText="perUnitHelpText"
                            :errors="errors['vendor_per_unit_charge']"
                        />

                        <text-input
                            v-if="data.serviceType?.enabled_type_fields?.includes('units_included_per_occurrence')"
                            v-model="form.units_included_per_occurrence"
                            class="col-span-2 md:col-span-1"
                            type="number"
                            min="0"
                            label="Units Included Per Occurrence"
                            :helpText="unitsIncludedPerOccurrenceHelpText"
                            :errors="errors['units_included_per_occurrence']"
                        />
                    </div>
                </fieldset>

                <div v-if="data.serviceType?.enabled_type_fields?.includes('bin')" class="mt-4">
                    <span class="font-semibold text-xl text-d-blue-500">Bin</span>
                    <div class="grid gap-x-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-4">
                        <text-input
                            v-model="form.label"
                            maxlength="50"
                            class="col-span-1"
                            :errors="errors['label']"
                            label="Equipment Label"
                        />
                    </div>
                    <bin
                        v-model="form.bin"
                        :property-type="data.locationPropertyType"
                        :bin-types="binTypes"
                        :material-types="materialTypes"
                        :asset-owners="assetOwners"
                        :errors="errors"
                    />
                </div>

                <fieldset v-if="$page.props.permissions.accessRevenueManagement" class="mt-4">
                    <legend class="font-semibold text-xl text-d-blue-500">Client</legend>

                    <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 my-4">
                        <select-input
                            v-model="form.client_contract_id"
                            class="col-span-2 md:col-span-1"
                            label="Client Contract"
                            :errors="errors['client_contract_id']"
                        >
                            <option v-if="form.location_id && !data.availableClientContracts.length" :value="null">
                                No contracts available for this Client
                            </option>
                            <option v-for="clientContract in data.availableClientContracts" :key="clientContract.id" :value="clientContract.id">
                                {{ clientContract.id + " - Integration " + clientContract.integration_date ?? " - " }}
                            </option>
                        </select-input>

                        <select-input
                            v-model="form.is_pass_through"
                            label="Is Pass Through?"
                            class="col-span-2 md:col-span-1"
                            :errors="errors['is_pass_through']"
                            markAsRequired
                        >
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select-input>

                        <select-input
                            v-if="form.is_pass_through === false"
                            v-model="form.client_billing_frequency"
                            markAsRequired
                            class="col-span-2 md:col-span-1"
                            label="Client Billing Frequency"
                            :errors="errors['client_billing_frequency']"
                        >
                            <option
                                v-for="clientBillingFrequency in validClientBillingFrequencies"
                                :key="clientBillingFrequency"
                                :value="clientBillingFrequency"
                            >
                                {{ clientBillingFrequency }}
                            </option>
                        </select-input>

                        <template v-if="form.is_pass_through === false && form.client_billing_frequency !== 'Not Billable'">
                            <money-input
                                v-if="data.serviceType?.enabled_type_fields?.includes('base_charges')"
                                v-model="form.client_base_charge"
                                markAsRequired
                                class="col-span-2 md:col-span-1"
                                label="Client Base Charge"
                                :defaultCurrency="data.clientCurrency"
                                :errors="errors['client_base_charge']"
                            />

                            <money-input
                                v-if="data.serviceType?.enabled_type_fields?.includes('per_occurrence_charges')"
                                v-model="form.client_per_occurrence_charge"
                                markAsRequired
                                class="col-span-2 md:col-span-1"
                                :defaultCurrency="data.clientCurrency"
                                label="Client Per Occurrence Charge"
                                :helpText="perOccurrenceHelpText"
                                :errors="errors['client_per_occurrence_charge']"
                            />

                            <money-input
                                v-if="data.serviceType?.enabled_type_fields?.includes('per_unit_charges')"
                                v-model="form.client_per_unit_charge"
                                markAsRequired
                                class="col-span-2 md:col-span-1"
                                :defaultCurrency="data.clientCurrency"
                                label="Client Per Unit Charge"
                                :helpText="perUnitHelpText"
                                :errors="errors['client_per_unit_charge']"
                            />
                        </template>
                    </div>
                </fieldset>
            </div>

            <div class="flex w-full mt-8 flex-row-reverse">
                <loading-button :loading="data.saving" class="btn btn-orange ml-3" @click="storeService">
                    Save
                </loading-button>

                <a class="btn btn-gray" :class="stepLinkEnabled(data.activeStep-1)" @click="transitionActiveStep(data.activeStep-1)">
                    <icon name="long-arrow-left" class="inline fill-current w-4 h-4"/>
                    <span class="ml-2">Previous Step</span>
                </a>
            </div>
        </div>

        <!-- Pickup RRule Modal -->
        <modal class="max-w-2xl" ref="updatePickupRRuleModal" tabindex="-1" role="dialog">
            <template #modal-header="{close}">
                <div class="p-4 border-b border-gray-400 flex justify-between">
                    <div class="my-1 text-2xl">
                        Update Schedule
                    </div>
                </div>
            </template>

            <template #modal-body>
                <div class="p-4">
                    <r-rule-input
                        ref="rRuleInput"
                        v-model:modelValue="form.pickup_rrule"
                        v-model:weeklyAverage="form.average_pickups_per_week"
                        v-model:description="data.rrule_description"
                        start-date-label="First Pickup"
                        weekly-average-label="Average Pickups Per Week"
                    />
                </div>
            </template>

            <template #modal-footer="{close}">
                <div class="p-4 flex">
                    <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script setup>
import Bin from '@/Shared/Services/Bin.vue';
import DateInput from '@/Shared/DateInput.vue';
import Icon from '@/Shared/Icon.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import Modal from '@/Shared/Modal.vue';
import MoneyInput from '@/Shared/MoneyInput.vue';
import RRuleInput from '@/Shared/RRuleInput.vue';
import SearchInput from '@/Shared/SearchInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import TextInput from '@/Shared/TextInput.vue';
import axios from "axios";
import {ref, reactive, inject, onMounted, watch, computed} from 'vue';
import {Head, router, usePage} from '@inertiajs/vue3';

/**
 * Props
 */
const props = defineProps({
    assetOwners: {
        type: Array,
        required: true
    },

    clientBillingFrequencies: {
        type: Object,
        required: true
    },

    binTypes: {
        type: Array,
        required: true
    },

    materialTypes: {
        type: Array,
        required: true
    },

    serviceTypes: {
        type: Array,
        required: true
    },

    errors: {
        type: Object,
        default: () => ({})
    },

    tenant: {
        type: Object,
        required: true
    },
});

/**
 * Injected
 */
const route = inject('route');
const page = usePage();

/**
 * Lifecycle
 */
onMounted(() => {
    data.mounted = true;
});

/**
 * Data
 */
const data = reactive({
   saving: false,
   steps: [
       {id: 1, name: 'Service Type and Location', href: '#', status: 'current'},
       {id: 2, name: 'Service Details', href: '#', status: 'upcoming'},
   ],
    activeStep: 1,
    availableVendorAccounts: [],
    availableVendorContracts: [],
    availableClientContracts: [],
    vendorCurrency: props.tenant.default_currency,
    clientCurrency: props.tenant.default_currency,
    locationPropertyType: null,
    mounted: false,
    rrule_description: null,
    serviceType: null,
});

const form = reactive({
    average_pickups_per_week: null,
    bin: {
        area_description: null,
        compaction_method: null,
        compaction_ratio: null,
        configuration: null,
        container_access_times: null,
        disposal_method: null,
        enclosure: null,
        has_casters: null,
        has_lids: null,
        has_lock: null,
        is_compacted_or_baled: null,
        keys: null,
        material_type: null,
        owner: null,
        pounds_per_yard: null,
        push_out_service: null,
        quantity: null,
        requires_overhead_instructions: null,
        secured_access: null,
        side_door_to_bin: null,
        size: null,
        size_metric: null,
        type: null,
    },
    client_billing_frequency: null,
    client_base_charge: null,
    client_contract_id: null,
    client_per_occurrence_charge: null,
    client_per_unit_charge: null,
    clientServiceFees: [],
    effective_date: null,
    external_reference_id: null,
    is_pass_through: null,
    label: null,
    service_account_type: null,
    service_schedule_type: null,
    location_id: null,
    monthly_management_fee_amount: null,
    pickup_rrule: null,
    units_included_per_occurrence: null,
    service_type_id: null,
    vendor_account_id: null,
    vendor_base_charge: null,
    vendor_contract_selection: null,
    vendor_id: null,
    vendor_per_occurrence_charge: null,
    vendor_per_unit_charge: null,
});

/**
 * References
 */
const updatePickupRRuleModal = ref(null);
const rRuleInput = ref(null);
const validClientBillingFrequencies = ref([]);

/**
 * Methods
 */
function showUpdatePickupRRuleModal() {
    updatePickupRRuleModal.value.show();
}

function storeService() {
    data.saving = true;

    router.post(route('services.store'), {
        ...form
    }, {
        onFinish: () => data.saving = false
    });
}

function transitionActiveStep(stepNumber) {
    if (canTransitionToStep(stepNumber)) {
        data.activeStep = stepNumber;
    }
}

function canTransitionToStep(stepNumber) {
    switch (stepNumber) {
        case 1:
            return true;
        case 2:
            return (data.serviceType && form.location_id);
        default:
            return false;
    }
}

function stepLinkEnabled(newStepNumber) {
    if (!canTransitionToStep(newStepNumber)) {
        return 'disabled';
    }

    return 'cursor-pointer';
}

function locationChanged(location) {
    form.client_contract_id = null;
    data.clientCurrency = location?.clientCompany?.currency ?? props.tenant.default_currency;
    data.locationPropertyType = location?.property_type;

    if (location?.client_company_id) {
        axios.get(route('json.client-contracts.index', {client_company_id: location.client_company_id, per_page: 50})).then(response => {
            data.availableClientContracts = response.data.data;
        })
    } else {
        data.availableClientContracts = [];
    }
}

function vendorChanged(vendor) {
    form.vendor_account_id = null;
    form.vendor_contract_selection = null;
    data.vendorCurrency = vendor?.currency ?? props.tenant.default_currency;

    if (form.vendor_id) {
        axios.get(route('json.vendor-accounts.index', {vendor_id: form.vendor_id, per_page: 1200})).then(response => {
            data.availableVendorAccounts = response.data.data;
        })

        axios.get(route('json.vendor-contracts.index', {vendor_id: form.vendor_id, per_page: 1200})).then(response => {
            data.availableVendorContracts = response.data.data;
        })
    } else {
        data.availableVendorAccounts = [];
        data.availableVendorContracts = [];
    }
}

function syncEffectiveDateWithPickupStartDate() {
    rRuleInput.value.setStartDate(form.effective_date);
}

/**
 * Computed
 */
const perUnitHelpText = computed(() => {
    return data.serviceType?.waste_generation_type === 'metered' ? "(Disposal Amount Per Ton)" : null;
});

const perOccurrenceHelpText = computed(() => {
    return data.serviceType?.waste_generation_type === 'metered' ? "(Haul Amount)" : null;
});

const unitsIncludedPerOccurrenceHelpText = computed(() => {
    return data.serviceType?.waste_generation_type === 'metered' ? "(Tons Included Per Haul)" : null;
});

    /**
 * Watchers
 */
watch(() => data.activeStep, (newValue, oldValue) => {
    data.steps = data.steps.map(function (step) {
        if (step.id < newValue) {
            step.status = 'complete';
        } else if (step.id === newValue) {
            step.status = 'current';
        } else {
            step.status = 'upcoming';
        }

        return step;
    })
});

watch(() => form.location_id, (newValue, oldValue) => {
    axios.get(route('json.locations.index')).then(response => {
        let location = response.data.data.find(location => location.id === newValue);
        data.clientCurrency = location?.clientCompany?.currency ?? props.tenant.default_currency;
    });
});

watch(() => form.is_pass_through, (newValue, oldValue) => {
    if (newValue === true) {
        form.client_billing_frequency = null;
        form.client_base_charge = null;
        form.client_per_unit_charge = null;
        form.client_per_occurrence_charge = null;

        return;
    }

    if (! data.serviceType.enabled_type_fields.includes('base_charges')) {
        form.client_billing_frequency = 'Per Vendor Invoice';
    }
});

watch(() => form.client_billing_frequency, (newValue, oldValue) => {
    if (newValue === 'Not Billable') {
        form.client_base_charge = null;
        form.client_per_unit_charge = null;
        form.client_per_occurrence_charge = null;
    }
});

watch(() => data.serviceType, (newValue, oldValue) => {
    if (! newValue) {
        return;
    }

    form.service_type_id = newValue.id;

    if (! newValue.enabled_type_fields.includes('base_charges')) {
        form.vendor_base_charge = null;
        form.client_base_charge = null;
    }

    if (! newValue.enabled_type_fields.includes('per_unit_charges')) {
        form.vendor_per_unit_charge = null;
        form.client_per_unit_charge = null;
    }

    if (! newValue.enabled_type_fields.includes('per_occurrence_charges')) {
        form.vendor_per_occurrence_charge = null;
        form.client_per_occurrence_charge = null;
    }

    if (! newValue.enabled_type_fields.includes('units_included_per_occurrence')) {
        form.units_included_per_occurrence = null;
    }

    if (page.props.permissions.accessRevenueManagement === false) {
        return;
    }

    validClientBillingFrequencies.value = newValue.enabled_type_fields.includes('base_charges') ?
        props.clientBillingFrequencies.base_charges_enabled :
        props.clientBillingFrequencies.base_charges_not_enabled;

    if (form.is_pass_through !== false) {
        return;
    }

    if (! newValue.enabled_type_fields.includes('base_charges') && form.client_billing_frequency !== 'Not Billable') {
        form.client_billing_frequency = 'Per Vendor Invoice';
        return;
    }

    form.client_billing_frequency = null;
});
</script>
