<template>
    <Head title="Create Vendor Invoice Sales Tax Rule" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Tenant Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.vendor-sales-taxes.index')" class="breadcrumb-link">Vendor Invoice Sales Taxes</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.vendor-sales-taxes.edit', vendorSalesTax.id)" class="breadcrumb-link">{{ vendorSalesTax.name }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create Rule</span>
        </nav>
    </Teleport>

    <div class="flex my-4">
        <div class="w-1/2">
            <select-input v-model="form.charge_selection" label="Line Item Charge" :errors="errors.charge_selection" mark-as-required>
                <option v-for="chargeOption in chargeOptions" :value="chargeOption">{{ chargeOption.display_name }}</option>
            </select-input>

            <toggle-switch-input v-model="form.apply_to_allocations" label="Apply to Allocations" />

            <div class="mt-6">
                <inertia-link :href="$route('tenant-settings.vendor-sales-taxes.edit', vendorSalesTax.id)" class="btn btn-gray mr-4">
                    Cancel
                </inertia-link>
                <button class="btn btn-blue" @click.prevent="addVendorSalesTaxRule">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { ref, reactive, inject, onMounted } from 'vue';

// Components
import { Head } from '@inertiajs/vue3';
import Icon from '@/Shared/Icon.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import TextInput from '@/Shared/TextInput.vue';
import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';

// Inject
const route = inject('route');

// Props
const props = defineProps({
    errors: {
        type: Object,
        default: () => ({})
    },
    vendorSalesTax: {
        type: Object,
        required: true
    },
    chargeOptions: {
        type: Object,
        required: true
    },
});

// State
const form = ref({
    charge_selection: {},
    apply_to_allocations: true
});

const mounted = ref(false);

// Mount
onMounted(() => {
    mounted.value = true;
});

// Methods
function addVendorSalesTaxRule() {
    router.post(route('tenant-settings.vendor-sales-tax-rules.store', props.vendorSalesTax.id), form.value);
}
</script>