<template>
    <div class="py-2 px-4">
        <div id="compactor-monitor-runs-heatmap-container"></div>
    </div>
</template>

<script>
    import Highcharts from 'highcharts';
    import Heatmap from 'highcharts/modules/heatmap';

    Heatmap(Highcharts);

    function getPointCategoryName(point, dimension) {
        let series = point.series,
            isY = dimension === 'y',
            axis = series[isY ? 'yAxis' : 'xAxis'];
        return axis.categories[point[isY ? 'y' : 'x']];
    }

    export default {
        props: {
            runsSeries: {
                type: Array,
                default: []
            }
        },
        mounted() {
            this.drawChart();
        },

        methods: {
            drawChart() {
                // Create the chart
	            Highcharts.setOptions({
		            colors: ['#fc521d']
	            });
                Highcharts.chart('compactor-monitor-runs-heatmap-container', {
                    chart: {
                        type: 'column',
                        reflow: true,
                    },
                    title: null,
                    xAxis: {
                        categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                    },
                    yAxis: {
                        title: null,
                        reversed: false,
	                    labels: {
		                    enabled: false,
	                    },
	                    gridLineWidth: 0,
	                },
                    colorAxis: {
                        min: 0,
                        minColor: '#FFFFFF',
                        maxColor: Highcharts.getOptions().colors[0],
                        reversed: false
                    },
	                legend: {
		                align: 'center', // Change to 'center' for better alignment when at the bottom
		                layout: 'horizontal', // Change to 'horizontal' for better fit when at the bottom
		                verticalAlign: 'bottom', // This will align it at the bottom
		                x: 0,
		                y: 0,
		                itemStyle: {
			                width: '100%',
		                },
		                symbolWidth: 750,
		                symbolHeight: 20,
	                },
                    tooltip: {
						enabled: false,
                    },
	                series: [{
		                name: 'Runs',
		                borderWidth: 1,
		                data: this.runsSeries.map(element => ({
			                name: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][element[0]],
			                y: element[1]
		                })),
		                dataLabels: {
			                enabled: true,
			                color: '#000000'
		                }
	                }],
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                yAxis: {
                                    labels: {
                                        formatter: function () {
                                            return this.value.charAt(0);
                                        }
                                    }
                                }
                            }
                        }]
                    },
	                credits: false
                });
            },
        },
    }
</script>