<template>
	<Head title="Invitation Not Found"/>

	<div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
		<div class="sm:mx-auto sm:w-full sm:max-w-md">
			<logo name="mark-dark" class="w-auto h-24 mx-auto"/>

			<h2 v-if="!confirmedAt" class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
				Invitation Not Found
			</h2>
		</div>

		<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
			<div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
				<div class="text-center">
					<p class="text-lg text-gray-700">Could not find an invitation for your email or client company.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LoadingButton from '@/Shared/LoadingButton.vue';
import TextInput from '@/Shared/TextInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import BooleanCheckboxInput from "@/Shared/BooleanCheckboxInput.vue";
import BlankLayout from "@/Shared/BlankLayout.vue";
import {Head} from '@inertiajs/vue3'
import Logo from "../../Shared/Logo.vue";

export default {
	layout: BlankLayout,

	components: {
		Logo,
		BooleanCheckboxInput,
		LoadingButton,
		TextInput,
		SelectInput,
		Head,
	},

	props: {
		email: {
			type: String,
			required: true
		},

		clientId: {
			type: String,
			required: true,
		},

		confirmedAt: {
			type: String,
			default: null,
		},

		storeRoute: {
			type: String,
			required: true
		},

		clientPortalLink: {
			type: String,
			required: true,
		},

		errors: {
			type: Object,
			default: () => ({})
		},
	},

	data() {
		return {
			form: {
				email: this.email,
				time_zone: null,
				accept_terms_of_service: false,
			},
			submitting: false,
		}
	},

	methods: {
		submitForm() {
			this.submitting = true;

			this.$inertia.post(this.storeRoute, {...this.form, 'client_id': this.clientId}, {
				onFinish: () => {
					this.submitting = false;
				}
			});
		}
	}
}
</script>