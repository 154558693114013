<template>
    <Head title="Create Sales Tax Rate Override" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Tenant Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.sales-taxes.index')" class="breadcrumb-link">Sales Taxes</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.sales-taxes.edit', salesTax.id)" class="breadcrumb-link">{{ salesTax.name }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create Rate Override</span>
        </nav>
    </Teleport>

    <div class="flex my-4">
        <div class="w-1/2">
            <div class="space-y-3">
                <select-input v-model="form.line_item_category_id" label="Line Item Category" :errors="errors.line_item_category_id">
                    <option v-for="lineItemCategory in lineItemCategories" :key="lineItemCategory.id" :value="lineItemCategory.id">{{ lineItemCategory.display_name }}</option>
                </select-input>

                <text-input v-model="form.rate" label="Override Default Tax Rate" :errors="errors.rate" placeholder="Example: 6.9" />
            </div>

            <div class="mt-6">
                <inertia-link :href="$route('tenant-settings.sales-taxes.edit', salesTax.id)" class="btn btn-gray mr-4">
                    Cancel
                </inertia-link>
                <button class="btn btn-orange" @click.prevent="addSalesTaxRateOverride">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { router } from '@inertiajs/vue3';
    import { ref, reactive, inject, onMounted } from 'vue';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';

    // Inject
    const route = inject('route');

    // Props
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },
        salesTax: {
            type: Object,
            required: true
        },
        salesTaxRateOverride: {
            type: Object,
            required: true
        },
        lineItemCategories: {
            type: Array,
            required: true
        }
    });

    // State
    const form = reactive({...props.salesTaxRateOverride});
    const mounted = ref(false);

    // Mount
    onMounted(() => {
        mounted.value = true;
    });

    // Methods
    function addSalesTaxRateOverride() {
        router.post(route('tenant-settings.sales-tax-rate-overrides.store', props.salesTax.id), form);
    }
</script>