<template>
    <div class="mt-2">
        <div v-if="properties.length > maxPropertyCount" class="flex font-bold">
            This report contains too many columns. Please reduce the number of columns in this report to {{ maxPropertyCount }} or fewer.
        </div>

        <div v-if="!allPropertiesValid" class="flex font-bold">
            Some of the properties below are invalid. Please adjust any properties showing a warning icon (<icon name="exclamation-triangle" class="inline fill-current h-4 w-4 text-red-500 " />) before saving this report.
        </div>

        <div v-if="properties.length" class="w-full grid grid-cols-12">
            <label :id="`${id}-entity-label`" class="col-start-2 col-span-4 hidden lg:inline">
                <span class="text-red-500">*</span> Entity
            </label>

            <label :id="`${id}-property-label`" class="col-span-3 hidden lg:inline">
                <span class="text-red-500">*</span> Property
            </label>

            <label :id="`${id}-display-name-label`" class="col-span-3 hidden lg:inline">
                <span class="text-red-500">*</span> Display Name
            </label>

            <label :id="`${id}-move-up-label`" class="hidden">
                Move Up
            </label>

            <label :id="`${id}-move-down-label`" class="hidden">
                Move Down
            </label>

            <label :id="`${id}-remove-label`" class="hidden">
                Remove
            </label>
        </div>

        <div v-for="(property, index) in properties">
            <div
                :id="`${id}-${index}-before`"
                class="py-2"
                @dragenter="dragEnter($event, property.sortOrder - 0.1)"
                @dragover.prevent
                @drop="drop($event)"></div>
            <fieldset
                :id="`${id}-${index}-fieldset`"
                :key="property.key"
                draggable="true"
                @dragstart="dragStart($event, property)"
                @dragend="dragEnd"
                class="grid grid-cols-12 cursor-move px-3 py-2 border my-1 bg-gray-100 gap-4">

                <div class="mx-2 my-auto">
                    <icon name="grip-vertical" class="inline fill-current h-6 w-6" />
                    <icon v-if="!propertyIsValid(property)" name="exclamation-triangle" class="inline fill-current h-6 w-6 text-red-500 " />
                </div>

                <div class="w-full col-span-10 grid grid-cols-10">
                    <div class="lg:flex col-span-10 lg:col-span-4 mr-1 mb-1">
                        <label :id="`${id}-${property.key}-entity-label-sm`" class="flex px-2 my-auto lg:hidden">
                            <span class="text-red-500 pr-1">*</span> Entity
                        </label>

                        <select-input-2 :id="`${property.key}-entity`" v-model="properties[index].entity" class="w-full" :aria-labelledby="`${id}-entity-label ${id}-${property.key}-entity-label-sm`" @selected="entityChanged(property)" :options="getAvailableEntities(property)">
                            <template #option-search="entity">{{ entity.pathDisplayName }}</template>
                            <template #option-label="entity">{{ entity.pathDisplayName }}</template>
                            <template #option-display="{option, selected}">
                                <div :class="{'font-semibold': selected}" class="block">
                                    <div>{{ option.pathDisplayName }}</div>
                                </div>
                                <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                                    <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                                </span>
                            </template>
                        </select-input-2>
                    </div>

                    <div class="lg:flex col-span-10 lg:col-span-3 mr-1 mb-1">
                        <label :id="`${id}-${property.key}-property-label-sm`" class="flex px-2 my-auto lg:hidden">
                            <span class="text-red-500 pr-1">*</span> Property
                        </label>

                        <select-input-2 :id="`${property.key}-property`" v-model="properties[index].selectedProperty" class="w-full" :aria-labelledby="`${id}-property-label ${id}-${property.key}-property-label-sm`"  @selected="selectedPropertyChanged(property)" :options="getAvailableProperties(property)">
                            <template #option-search="property">{{ property.default_display_name }}</template>
                            <template #option-label="property">{{ property.default_display_name }}</template>
                            <template #option-display="{option, selected}">
                                <div :class="{'font-semibold': selected}" class="block">
                                    <div>{{ option.default_display_name }}</div>
                                </div>
                                <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                                    <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                                </span>
                            </template>
                        </select-input-2>
                    </div>

                    <div class="mt-1 lg:mt-0 lg:flex col-span-10 lg:col-span-3 ml-1">
                        <label :id="`${id}-${property.key}-display-name-label-sm`" class="flex px-2 my-auto lg:hidden">
                            <span class="text-red-500 pr-1">*</span> Display Name
                        </label>

                        <text-input :id="`${property.key}-display-name`" v-model="properties[index].property_display_name" class="w-full" :aria-labelledby="`${id}-display-name-label ${id}-${property.key}-display-name-label-sm`" draggable="true" v-on:dragstart="preventDrag"/>
                    </div>
                </div>

                <div class="grid grid-cols-1 lg:grid-cols-3">
                    <button @click.prevent="moveProperty(property.key, property.sortOrder - 1.1)" class="mx-auto lg:pr-1 my-auto " :aria-labelledby="`${id}-move-up-label`">
                        <icon v-if="index > 0" name="chevron-up" class="inline fill-current h-7 w-7"/>
                    </button>
                    <button @click.prevent="moveProperty(property.key, property.sortOrder + 1.1)" class="mx-auto lg:px-1 my-auto" :aria-labelledby="`${id}-move-down-label`">
                        <icon v-if="index < properties.length - 1" name="chevron-down" class="inline fill-current h-7 w-7"/>
                    </button>
                    <button @click.prevent="removeProperty(index)" class="mx-auto lg:pl-2 my-auto" :aria-labelledby="`${id}-remove-label`">
                        <icon name="trash" class="inline fill-current h-7 w-7 text-red-500"/>
                    </button>
                </div>
            </fieldset>
            <div
                v-if="index === properties.length - 1"
                :id="`${id}-${index}-after`"
                class="py-2"
                @dragenter="dragEnter($event, property.sortOrder + 0.1)"
                @dragover.prevent
                @drop="drop($event)"></div>
        </div>

        <div v-if="errors" class="form-error">{{ errors }}</div>

        <div class="flex float-right items-center">
            <span class="font-bold">
                Columns in use: {{ properties.length }} / {{ maxPropertyCount }}
            </span>

            <button :disabled="!mainEntity || properties.length >= maxPropertyCount" @click.prevent="addProperty()" class="my-2 ml-2 btn btn-gray float-right">
                <icon name="plus" class="inline fill-current h-5 w-5"/>Add Column
            </button>
        </div>
    </div>
</template>

<script>
import Icon from '@/Shared/Icon.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import SelectInput2 from '@/Shared/SelectInput2.vue';
import TextInput from "@/Shared/TextInput.vue";
import {v4 as uuid} from 'uuid';
import axios from "axios";

export default {
    components: {
        Icon,
        SelectInput,
        SelectInput2,
        TextInput
    },

    inheritAttrs: false,

    props: {
        id: {
            type: String,
            default() {
                return `repeatable-custom-report-property-${uuid()}`;
            },
        },

        label: String,

        maxPropertyCount: {
            type: Number,
            required: true
        },

        mainEntity: String,

        entities: {
            type: Array,
            required: true
        },

        initialProperties: {
            type: Object,
        },

        savedProperties: {
            type: Array,
            default: () => []
        },

        errors: {
            type: String,
            default: '',
        }
    },

    data() {
        return {
            counter: 0,
            properties: [],
            availableProperties: {},
            draggedProperty: {key: null, sortOrder: null, dragging: false}
        };
    },

    methods: {
        getAvailableEntities(property) {
            if (property.entity?.isPlaceholder) {
                return [
                    property.entity,
                    ...this.entities
                ];
            }

            return this.entities;
        },

        getAvailableProperties(property) {
            if (!property.entity) {
                return [];
            }

            if (property.selectedProperty?.isPlaceholder) {
                return [
                    property.selectedProperty,
                    ...this.availableProperties[property.entity?.entityClass]?.length ? this.availableProperties[property.entity?.entityClass] : []
                ];
            }

            return this.availableProperties[property.entity?.entityClass]?.length ? this.availableProperties[property.entity?.entityClass] : [];
        },

        propertyIsValid(property) {
            if (!property.entity || !property.property_name || !property.property_display_name) {
                return true;
            }

            const propertyDisplayNameIsUnique = !this.properties
                .filter((singleProperty) => singleProperty.key !== property.key)
                .map((singleProperty) => singleProperty.property_display_name)
                .includes(property.property_display_name);

            return propertyDisplayNameIsUnique && !property.entity.isPlaceholder && !property.selectedProperty.isPlaceholder;
        },

        addProperty(property = null) {
            if (!property) {
                property = {
                    key: `${this.id}-property-${this.counter}`,
                    entity: null,
                    property_name: null,
                    property_display_name: null,
                    selectedProperty: null,
                };
            }

            property.sortOrder = this.properties.length;

            this.properties.push(property);
            this.counter++;
        },

        removeProperty(index) {
            this.properties.splice(index, 1);
        },

        getEntityProperties(entityClass) {
            if (!entityClass || this.availableProperties[entityClass]) {
                return;
            }

            axios.get(this.$route('json.custom-reports.get-entity-properties', {entityName: entityClass}))
                .then(response => {

                    this.availableProperties[entityClass] = response.data.properties.map(property => {
                        return {
                            entity: property.entity,
                            name: property.name,
                            default_display_name: property.default_display_name,
                            type: property.type
                        };
                    });
                });

            if (this.availableProperties[entityClass]) {
                this.availableProperties[entityClass] = this.availableProperties[entityClass].sort(function (a, b) {
                    if (a.default_display_name === null) {
                        return -1;
                    }

                    if (a.default_display_name > b.default_display_name) {
                        return 1;
                    } else if (a.default_display_name === b.default_display_name) {
                        return 0;
                    } else {
                        return -1;
                    }
                });
            }
        },

        selectedPropertyChanged(property) {
            property.property_name = property.selectedProperty?.name;
            property.property_display_name = this.availableProperties[property.entity.entityClass].find(singleProperty => singleProperty.name === property.property_name).default_display_name;
        },

        // Property controls
        dragStart(e, property) {
            e.dataTransfer.dropEffect = 'move';
            e.dataTransfer.effectAllowed = 'move';

            this.draggedProperty.key = property.key;
            this.draggedProperty.sortOrder = property.sortOrder;
            this.draggedProperty.dragging = true;
        },

        dragEnter(e, sortOrder) {
            this.draggedProperty.sortOrder = sortOrder;
            this.moveProperty(this.draggedProperty.key, this.draggedProperty.sortOrder);
        },

        dragOver(e) {
            e.preventDefault();
            e.dataTransfer.dropEffect = 'move';
        },

        dragLeave (e) {

        },

        drop(e) {
            e.preventDefault();     // prevent default action (open as link for some elements)
        },

        dragEnd() {
            this.draggedProperty.key = null;
            this.draggedProperty.sortOrder = null;
            this.draggedProperty.dragging = false;
        },

        preventDrag(e) {
            e.preventDefault();
            e.stopPropagation();
        },

        moveProperty(propertyKey, sortOrder) {
            const property = this.properties.find(property => property.key === propertyKey);
            property.sortOrder = sortOrder;

            this.sortProperties();
        },

        sortProperties() {
            this.properties.sort(function (a, b) {
                if (a.sortOrder > b.sortOrder) {
                    return 1;
                } else if (a.sortOrder === b.sortOrder) {
                    return 0;
                } else {
                    return -1;
                }
            })

            for (const key of this.properties.keys()) {
                this.properties[key].sortOrder = key;
            }
        },

        getEntityFromSavedProperty(property) {
            return this.entities.find((entity) => entity.path === property.entity_path) ?? this.createPlaceholderEntityFromSavedProperty(property);
        },

        getEntityPropertyFromSavedProperty(savedProperty) {
            return this.availableProperties[savedProperty.entity]?.find((property) => property.name === savedProperty.name) ?? this.createPlaceholderPropertyFromSavedProperty(savedProperty);
        },

        createPlaceholderEntityFromSavedProperty(property) {
            return {
                path: property.entity_path,
                pathDisplayName: this.getPlaceholderEntityPathDisplayName(property.entity_path),
                isPlaceholder: true
            }
        },

        createPlaceholderPropertyFromSavedProperty(savedProperty) {
            return {
                name: savedProperty.name,
                display_name: savedProperty.display_name,
                isPlaceholder: true,
            }
        },

        getPlaceholderEntityPathDisplayName(entityPath) {
            let pathParts = entityPath.split('.');

            pathParts = pathParts
                .map(part => part.slice(0, 1).toUpperCase() + part.slice(1))
                .map(part => part.replace(/([a-z])([A-Z])/g, '$1 $2'));
            return pathParts.join(' > ');
        },

        entityChanged(property) {
            this.getEntityProperties(property.entity?.entityClass);
            property.property_name = null;
            property.property_display_name = null;
            property.selectedProperty = null;
        },
    },

    watch: {
        mainEntity() {
            this.properties = [];
        },

        properties: {
            handler(newValue, oldValue) {
                this.$emit('update:modelValue', newValue);
            },
            deep: true
        }
    },

    computed: {
        propertyDropZoneClass() {
            if (!this.draggedProperty.dragging) {
                return "py-2.5";
            }

            return "py-2 border-dashed border-2 border-gray-500";
        },

        allPropertiesValid() {
            for (const property of this.properties) {
                if (!this.propertyIsValid(property)) {
                    return false;
                }
            }

            return true;
        }
    },

    mounted() {
        this.availableProperties = { ...this.initialProperties };

        for (const savedProperty of this.savedProperties) {
            let property = {
                key: `${(savedProperty.entity_path) }.${savedProperty.name}`,
                entity: this.getEntityFromSavedProperty(savedProperty),
                property_name: savedProperty.name,
                property_display_name: savedProperty.display_name,
                selectedProperty: this.getEntityPropertyFromSavedProperty(savedProperty)
            };
            this.addProperty(property);
        }
    }
}
</script>
