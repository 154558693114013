<template>
    <div class="p-2">
        <div id="compactor-monitor-fullness-column-chart-container"></div>
    </div>
</template>

<script>
    import Highcharts from 'highcharts/highstock';
    import HighchartsMore from 'highcharts/highcharts-more';
    import autosize from "autosize/dist/autosize";

    HighchartsMore(Highcharts);

    export default {
        props: {
            loggedFullness: {
                type: Array,
                required: true
            },

            forecastedFullness: {
                type: Array,
                required: true
            },

            predictedPickupFlag: {
                type: Array,
                required: true
            },

            pickups: {
                type: Array,
                required: true
            },

            targetPercentageFull: {
                type: Number,
                required:true
            },

            loading: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                chart: null
            }
        },

        mounted() {
            if (this.loggedFullness.length) {
                this.drawChart();
            }
        },

        methods: {
            drawChart() {
				let vueContext = this;
	            Highcharts.setOptions({
		            colors: ['#012639', '#075076', '#5fa3ec', '#fc9776', '#f4c326']
	            });
                this.chart = Highcharts.stockChart('compactor-monitor-fullness-column-chart-container', {
                    chart: {
                        type: 'column',
                        zoomType: 'x',
                        reflow: true,
                    },
                    legend: {
                        enabled: true
                    },
                    plotOptions: {
                        series: {
                            animation: false
                        },

                        column: {
                            pointPlacement: 'on',
                            pointPadding: 0,
                            borderWidth: 0
                        }
                    },
	                credits: {
		                enabled: false
	                },
                    loading: {
                        labelStyle: {
                            color: 'white'
                        },
                        style: {
                            backgroundColor: 'gray'
                        }
                    },
                    title: 'Compactor Fullness',
                    xAxis: {
                        type: 'datetime',
                        dateTimeLabelFormats: {
                            day: '%b %e',
                            week: '%b %e'
                        }
                    },
                    yAxis: {
                        min: 0,
                        softMax: 100,
                        opposite: false,
                        showLastLabel: true,
                        tickPositions: [0, 25, 50, 75, 100],
                        plotLines: [{
                            color: '#FF0000',
                            width: 1,
                            value: this.targetPercentageFull,
                            label: {
                                text: `Target Fullness: ${this.targetPercentageFull}%`,
                                style: {
                                    opacity: 0.5
                                }
                            },
                            zIndex: 5
                        }]
                    },
                    tooltip: {
                        shared: true
                    },
                    series: [
                        {
                            name: 'Percentage Full',
                            data: this.loggedFullness,
                            crisp: false,
                            dataGrouping: {
                                enabled: false
                            },
	                        color: '#5fa3ec'
                        },

                        {
                            type: 'flags',
                            name: 'Pickups',
	                        data: this.pickups.filter(pickup => pickup.title !== '-'),
	                        shape: 'square',
                            showInLegend: false,
                            allowOverlapX: true,
	                        // color: '#012639',
	                        marker: {
		                        fillColor: '#007bff', // button color
		                        lineColor: '#007bff', // border color
		                        lineWidth: 1,          // border width
		                        symbol: 'square',
		                        radius: 5,             // button round corners
		                        states: {
			                        hover: {
				                        fillColor: '#0056b3'
			                        }
		                        }
	                        },
	                        tooltip: {
								useHTML: true,
		                        positioner: function (labelWidth, labelHeight, point) {
			                        // Adjust the pixel values to fit the tooltip below the point
			                        return { x: point.plotX, y: point.plotY - labelHeight }
		                        },
		                        formatter: function () {
			                        return `<div class="tooltip">${this.point.text}</div>`;
		                        },
	                        },
	                        point: {
			                        events: {
			                            mouseOver: function () {
											this.series.chart.container.style.cursor = 'pointer'
			                            },
                                        mouseOut: function () {
											this.series.chart.container.style.cursor = 'default'
										},
		                        }
	                        }
                        },

	                    {
		                    name: 'Forecasted Fullness',
		                    data: this.forecastedFullness,
		                    crisp: false,
		                    dataGrouping: {
			                    enabled: false
		                    },
		                    color: '#fc521d',
	                    },

                        {
                            type: 'flags',
                            name: 'Predicted Pickup',
                            data: this.predictedPickupFlag,
                            shape: 'square',
                            width: autosize,
                            showInLegend: false,
                            color: 'red'
                        }
                    ],
                    rangeSelector: {
                        labelStyle: {
                            display: 'none'
                        },
                        inputEnabled: false,
                        enabled: true,
                        buttons: [
                            {
                                type: 'day',
                                count: 1,
                                text: '1d'
                            },
                            {
                                type: 'week',
                                count: 1,
                                text: '1w'
                            },
                            {
                                type: 'week',
                                count: 2,
                                text: '2w',
                            },
                            {
                                type: 'week',
                                count: 3,
                                text: '3w'
                            },

                            {
                                type: 'all',
                                text: 'All'
                            }
                        ],
                    },
                    navigator: {
                        enabled: false
                    },
                    scrollbar: {
                        enabled: false
                    },
                    time: {
                        useUTC: false
                    },
                });
            }
        },

        watch: {
            loading: function(value) {
                if (this.chart) {
                    value ? this.chart.showLoading('Fetching Fullness...') : this.chart.hideLoading();
                }

                if (!value) {
                    this.drawChart();
                }
            }
        },
    }
</script>