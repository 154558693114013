<template>
	<Head title="Create Client Contract" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-contracts.index')" class="breadcrumb-link">Client Contracts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <form id="create-client-contract-form" class="grid grid-cols-4 gap-4" @submit.prevent="submitForm">
        <select-input v-model="form.client_company_id" class="col-span-4 md:col-span-2" label="Attach to Client Company:" :errors="errors.client_company_id" mark-as-required>
            <option v-for="clientCompany in clientCompanies" :key="clientCompany.id" :value="clientCompany.id">{{ clientCompany.name }} ({{ clientCompany.status }})</option>
        </select-input>

        <text-input
            v-model="form.external_reference_id"
            label="External Reference Id"
            class="col-span-4 md:col-span-2"
            :errors="errors.external_reference_id"
            help-text="Allows for external references IDs"
        />

        <date-input class="col-span-4 md:col-span-2" v-model="form.integration_date" label="Integration Date:" :errors="errors.integration_date" mark-as-required />

        <text-input class="col-span-4 md:col-span-2" type="number" v-model="form.term_months" label="Term (months)" :errors="errors.term_months" mark-as-required />

        <text-input class="col-span-4 md:col-span-2" type="number" v-model="form.auto_renew_months" label="Auto Renew (months)" :errors="errors.auto_renew_months" mark-as-required />

        <div>
            <loading-button class="btn btn-orange" :loading="state === 'submitting-form'">Save</loading-button>
        </div>
    </form>
</template>

<script>
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';

	export default {
		components: {
            LoadingButton,
            TextInput,
            DateInput,
            SelectInput,
            Icon,
            Head,
		},

		props: {
			errors: {
                type: Object,
                default: () => ({}),
			},
			
			clientContract: {
				required: true
			},

			clientCompanies: {
				required: true,
				type: Array
			},

			employees: {
				required: true,
				type: Array
			}
		},

		data() {
			return {
				form: {...this.clientContract},
				state: 'passive',
                mounted: false,
			}
		},

        mounted() {
            this.mounted = true;
        },

		methods: {
			submitForm() {
				this.state = 'submitting-form';

				this.$inertia.post(this.$route('client-contracts.store'), this.form, {
                    onFinish: () => { this.strate = 'passive'; },
					onError: () => { this.state = 'passive'; }
                });
			}
		}
	}
</script>