<template>
    <Head :title="`Client Invoice Reminder - ${ clientInvoiceReminder.email_subject }`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('client-invoices.index')" class="breadcrumb-link">Client Invoices</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('client-invoices.show', clientInvoiceReminder.client_invoice_id)" class="breadcrumb-link">Invoice #{{ clientInvoiceReminder.clientInvoice.invoice_number }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>{{ clientInvoiceReminder.email_subject }}</span>
        </nav>
    </Teleport>

    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ clientInvoiceReminder.email_subject }}
                </h1>
            </div>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2">
        <div class="space-y-6">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Reminder Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Client Invoice Reminder details.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Status</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ clientInvoiceReminder.status }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Sent At</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ clientInvoiceReminder.last_emailed_at ? $filters.format_date_time(clientInvoiceReminder.last_emailed_at) : '' }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">To</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ clientInvoiceReminder.email_to }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Cc</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ clientInvoiceReminder.email_cc }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Subject</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ clientInvoiceReminder.email_subject }}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <div>
            <div class="flex flex-wrap justify-between">
                <div class="px-4 py-5 sm:px-6">
                    <h2 id="lineItems-title" class="text-xl leading-6 font-medium text-gray-900">Message</h2>
                </div>
            </div>

            <div>
                <iframe style="width: 100%; height: 60vh" :src="$route('client-invoice-reminders.preview', {'clientInvoiceId': clientInvoiceReminder.client_invoice_id, 'client_invoice_reminder_id': clientInvoiceReminder.id})"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import { groupBy, truncate } from "lodash-es";
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            Head,
        },

        props: {
            clientInvoiceReminder: {
                type: Object,
                required: true
            },
        },

        data() {
           return {
               state: 'passive',
               mounted: false,
           }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            groupBy,
            truncate,
        },

        computed: {

        },
    }
</script>