<template>
    <Head :title="`Edit Sales Tax - ${salesTax.display_name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Tenant Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.sales-taxes.index')" class="breadcrumb-link">Sales Taxes</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ salesTax.name }}</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <form id="edit-sales-tax-form">
        <div class="grid grid-cols-2 gap-6">
            <text-input v-model="form.name" label="Name" :errors="errors.name" mark-as-required/>

            <text-input v-model="form.display_name" label="Display Name (on client invoices)" :errors="errors.display_name" mark-as-required />

            <text-input v-model="form.default_rate" label="Default Tax Rate" :errors="errors.default_rate" placeholder="Example: 6.9" />

            <text-input v-model="form.external_reference_id" label="External Reference Id" :errors="errors.external_reference_id" />

            <select-input v-model="form.gl_code" label="GL Account" :errors="errors.gl_code">
                <option v-for="account in accountsList" :key="account.id" :value="account.external_accounting_id">{{ account.name }}</option>
            </select-input>

            <textarea-input v-model="form.internal_notes" label="Internal Notes" :errors="errors.internal_notes"></textarea-input>
        </div>

        <div class="my-6">
            <inertia-link :href="$route('tenant-settings.sales-taxes.index')" class="btn btn-gray mr-4">
                Cancel
            </inertia-link>
            <button class="btn btn-orange mr-4" @click.prevent="updateSalesTax">
                Save
            </button>
            <button class="btn-gray w-full md:w-auto action-link text-red-500" :class="{disabled: utilized}"  @click.prevent="deleteSalesTax" :disabled="utilized" :title="deleteHelpText">
                Delete
            </button>
        </div>
    </form>

    <!-- Rate Overrides !-->
    <div class="space-y-6 lg:col-start-1 lg:col-span-3">
        <section aria-labelledby="rate-override-title">
            <div class="bg-white shadow sm:rounded-lg">
                <div class="flex flex-wrap justify-between">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="rate-override-title" class="text-lg leading-6 font-medium text-gray-900">Rate Overrides</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Override the default rate for a specific line item category.</p>
                    </div>

                    <div v-if="salesTax.rateOverrides.length > 0" class="px-4 py-5 sm:px-6">
                        <inertia-link :href="$route('tenant-settings.sales-tax-rate-overrides.create', [salesTax.id])" class="btn btn-orange">New</inertia-link>
                    </div>
                </div>

                <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div v-if="salesTax.rateOverrides.length" class="flex flex-col">
                        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Category</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Rate</th>
                                            <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span class="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="rateOverride in salesTax.rateOverrides" :key="rateOverride.id">
                                            <!-- @TODO need to look into this further, something weird is going on here and the mapper is not bringing in the category relationship correctly... :( -->
                                            <td class="whitespace-nowrap px-2 py-2 text-sm font-medium">{{ rateOverride.category.display_name }}</td>
                                            <td class="">{{ rateOverride.rate }}%</td>
                                            <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <button @click.prevent="removeSalesTaxRateOverride(rateOverride.id)" class="link">
                                                    Delete <span class="sr-only">, {{ rateOverride.category.display_name }}</span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!-- This example requires Tailwind CSS v2.0+ -->
                    <div v-else class="text-center">
                        <icon name="file-signature" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                        <h3 class="mt-2 text-sm font-medium text-gray-900">No Rate Override</h3>
                        <p class="mt-1 text-sm text-gray-500">Create a new rate override.</p>
                        <div class="mt-6">
                            <inertia-link :href="$route('tenant-settings.sales-tax-rate-overrides.create', [salesTax.id])" class="btn btn-orange">
                                <!-- Heroicon name: solid/plus -->
                                <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                </svg>
                                New Rate Override
                            </inertia-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from "@/Shared/TextareaInput.vue";

    export default {
        components: {
            Head,
            Icon,
            SelectInput,
            TextInput,
            TextareaInput
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            lineItemCategories: {

            },

            salesTax: {
                type: Object,
                required: true
            },
            
            accountsList: {
                type: Array,
                required: true
            },
            
            utilized: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                form: {...this.salesTax},
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        computed: {
            deleteHelpText() {
                return this.utilized ? 'This Sales Tax has client line items' : ''
            }
        },

        methods: {
            updateSalesTax() {
                this.$inertia
                    .put(this.$route('tenant-settings.sales-taxes.update', this.salesTax.id), this.form);
            },

            removeSalesTaxRateOverride(rateOverrideId) {
                this.$inertia
                    .delete(this.$route('tenant-settings.sales-tax-rate-overrides.destroy', rateOverrideId));
            },

            deleteSalesTax() {
                if (this.utilized) {
                    return;
                }

                if (window.confirm("Are you sure that you want to delete this sales tax?")) {
                    this.$inertia
                        .delete(this.$route('tenant-settings.sales-taxes.destroy', this.salesTax.id));
                }
            }
        },
    }
</script>