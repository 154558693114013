<template>
	<Head :title="`Edit Client Contact - ${client.name}`"/>

	<Teleport v-if="data.mounted" to="[data-slot='breadcrumbs']">
		<nav class="breadcrumbs">
			<inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

			<icon class="inline text-gray-600 fill-current h-6 w-6" name="angle-right"/>

			<inertia-link :href="$route('clients.index')" class="breadcrumb-link">Client Contacts</inertia-link>

			<icon class="inline text-gray-600 fill-current h-6 w-6" name="angle-right"/>

			<span>Edit</span>
		</nav>
	</Teleport>

	<div v-if="data.mounted" class="py-6">
		<form id="edit-client-contact-form" class="space-y-8" @submit.prevent="submitForm">
			<fieldset class="space-y-8 sm:space-y-5">
				<legend class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
					<h3 class="font-semibold text-xl text-d-blue-500">Contact</h3>
				</legend>

				<div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-4">
						<text-input id="first-name" v-model="form.first_name" :errors="errors.first_name" class="col-span-2 md:col-span-1 relative" label="First Name" mark-as-required placeholder="John/Jane"/>
						<text-input id="last-name" v-model="form.last_name" :errors="errors.last_name" class="col-span-2 md:col-span-1" label="Last Name" mark-as-required placeholder="Doe"/>
						<text-input id="email" v-model="form.email" :errors="errors.email" class="col-span-2 md:col-span-1" label="Email Address" placeholder="johndoe@unknown.com" type="email"/>
						<text-input id="primary-phone-number" v-model="form.primary_phone_number" :errors="errors.primary_phone_number" class="col-span-2 md:col-span-1" label="Primary Phone" type="text"/>
						<text-input id="mobile-phone-number" v-model="form.mobile_phone_number" :errors="errors.mobile_phone_number" class="col-span-2 md:col-span-1" label="Mobile Phone" type="text"/>
						<toggle-switch-input v-model="form.send_sms" :disabled="!form.mobile_phone_number" class="col-span-2 md:col-span-1" label="Send SMS (Mobile Phone Required)"/>

				</div>

				<button type="button" @click="toggleAdditionalContactInfo" class="w-full bg-gray-50 font-semibold text-d-blue-400 py-3 flex items-center justify-center gap-x-2 rounded-lg">
                    Additional Info <icon :name="data.showAdditionalContactInfo ? 'chevron-down' : 'chevron-right'" class="inline h-4 w-4 fill-current cursor-pointer"/>
                </button>

				<div v-if="data.showAdditionalContactInfo" class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-4">
					<text-input type="text" id="office-phone-number" v-model="form.office_phone_number" class="" label="Office Phone" :errors="errors.office_phone_number" />
					<text-input type="text" id="fax-phone-number" v-model="form.fax_phone_number" class="" label="Fax Number" :errors="errors.fax_phone_number" />
				</div>
			</fieldset>
			<fieldset class="space-y-8 sm:space-y-5">
				<legend class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
					<h3 class="font-semibold text-xl text-d-blue-500">Company</h3>
				</legend>

				<div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
					<div class="col-span-6 md:col-span-2">
						<search-input
                            v-model="form.clientCompanyName"
                            :default-value="client.clientCompany"
                            :errors="errors.client_company_id"
                            class="relative col-span-6 xs:col-span-4 md:col-span-2"
                            display-property="name"
							label="Client Company"
							mark-as-required
							route="json.client-companies.index"
							value-property="id"
							@focus="$event.target.select()"
							@selected="selectClientCompany"
                        >
							<template #default="obj">
								<span class="block">{{ obj.result.name }}</span>
							</template>
						</search-input>
					</div>
					<text-input id="title" v-model="form.title" :errors="errors.title" class="col-span-6 md:col-span-1" label="Title" placeholder="Client Success Champion"/>
					<text-input id="external-reference-id" v-model="form.external_reference_id" :errors="errors.external_reference_id" class="col-span-6 md:col-span-1" label="External Reference Id" placeholder="EXT-ABCD-12343"/>
					<toggle-switch-input id="is-decision-maker" v-model="form.is_decision_maker" class="col-span-6 md:col-span-3" label="Decision Maker?"/>
				</div>
			</fieldset>

			<fieldset>
				<legend class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
					<h3 class="font-semibold text-xl text-d-blue-500">Locations</h3>
				</legend>
				<div class="mb-16">
					<div class="mb-6">
                        <text-input
                            v-model="data.searchTerm"
                            type="text"
                            class="pb-2"
                            placeholder="Search options"
                        />
						<filter-multi-select :key="data.clientCompanyChanged" :options="data.locations" :selected-options="form.location_ids" @updated="updateLocationSelections" class="grid md:grid-cols-2 text-lg">
							<template #option="obj">
								<div class="text-base md:text-lg items-center">
									<div>{{ obj.option.name }}</div>
									<div class="text-base md:text-lg text-gray-500">{{ data.locations.find((location) => location.id === obj.option.id)?.address?.full_address }}</div>
								</div>
							</template>
						</filter-multi-select>
					</div>
				</div>
			</fieldset>

            <div class="mt-16">
                <textarea-input id="notes" v-model="form.notes" :errors="errors.notes" autosize class="grid-cols-1 md:col-span-4" label="Notes"/>
            </div>

			<loading-button :loading="form.state === 'saving'" class="btn btn-orange" type="submit">Save</loading-button>
		</form>
	</div>
</template>

<script setup>
    // Inertia/Vue Imports
    import { Head, router } from '@inertiajs/vue3';
    import { onMounted, reactive, watch, inject } from 'vue';

    // Libraries
    import axios from 'axios';
    import { debounce } from 'lodash-es';

    // Components
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import FilterMultiSelect from '@/Shared/FilterMultiSelect.vue';

    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },

        client: {
            type: Object,
            required: true,
        },
    });

    const form = reactive({
        state: 'passive',
        ...props.client,
        location_ids: props.client.locations,
        clientCompanyName: props.client.clientCompany.name,
    });

    // State
    const data = reactive({
        mounted: false,
        showAdditionalContactInfo: false,
        searchTerm: '',
        locations: [],
        clientCompanyChanged: 0,
    });

    const route = inject('route');

    onMounted(() => {
        searchLocations();
        data.mounted = true;
    });

    function submitForm() {
        if (form.state === 'passive') {
            form.state = 'saving';

			let locationIds = form.location_ids.map((location) => location.id);

            router.put(route('clients.update', props.client.id), {...form, location_ids: locationIds}, {
                onFinish: () => form.state = 'passive'
            });
        }
    }

    function selectClientCompany(clientCompany) {
        form.client_company_id = clientCompany?.id;
        form.clientCompanyName = clientCompany?.name;
        form.location_ids = [];

        data.clientCompanyChanged++;
    }

    function toggleAdditionalContactInfo() {
        data.showAdditionalContactInfo = !data.showAdditionalContactInfo;
    }

    const searchLocations = debounce(function() {
        let jsonParams = {
            client_company_id: form.client_company_id,
            sort_by: 'locations.name',
            sort_direction: 'desc',
            search: data.searchTerm,
            per_page: 50,
        };

        axios.get(route('json.locations.index', jsonParams))
            .then(response => {
                data.locations = response.data?.data.sort((a, b) => {
                    const isASelected = form.location_ids.some(location => location.id === a.id);
                    const isBSelected = form.location_ids.some(location => location.id === b.id);

                    if (isASelected && !isBSelected) {
                        return -1;
                    } else if (!isASelected && isBSelected) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            })
            .finally(() => {
                form.state = 'passive';
                data.clientCompanyChanged++;
            })
    }, 300);

    function updateLocationSelections(selections) {
        form.location_ids = selections;
    }

    watch(() => form.client_company_id, searchLocations);
    watch(() => data.searchTerm, searchLocations);

</script>

<style scoped>
    .search-icon {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
    }

    .search-animation {
        animation-name: search-animation;
        animation-timing-function: ease;
    }

    @keyframes search-animation {
        0% {
            transform: translateY(0);
        }
        30% {
            transform: translateY(-10px);
        }
        50% {
            transform: translateY(0) translateX(5px);
        }
        100% {
            transform: translateY(0) translateX(0);
        }
    }
</style>
