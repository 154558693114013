<template>
	<Head :title="`Edit Client Contract - ${clientContract.id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-contracts.index')" class="breadcrumb-link">Client Contracts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Editing {{ clientContract.id }}</span>
        </nav>
    </Teleport>

    <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <inertia-link :href="$route('client-contracts.create')" class="btn btn-gray">New Contract</inertia-link>
        <button class="btn btn-gray" :class="{disabled: clientContract.services.length > 0}" @click.prevent="removeClientContract">
            <icon name="ban" class="inline w-4 h-4 mr-2" />
            Cancel Contract
        </button>
    </div>

    <form id="edit-client-contract-form" class="" @submit.prevent="submitForm">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Information</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Contract settings and info.</p>
                </div>

                <div class="grid grid-cols-4 gap-6">
                    <date-input class="col-span-4 md:col-span-2" v-model="form.integration_date" label="Integration Date:" :errors="errors.integration_date" mark-as-required />
                    <text-input class="col-span-4 md:col-span-2" type="number" v-model="form.term_months" label="Term (months)" :errors="errors.term_months" mark-as-required />
                    <text-input class="col-span-4 md:col-span-2" type="number" v-model="form.auto_renew_months" label="Auto Renew (months)" :errors="errors.auto_renew_months" mark-as-required />
                    <text-input class="col-span-4 md:col-span-1"
                        v-model="form.external_reference_id"
                        label="External Reference Id"
                        :errors="errors.external_reference_id"
                    />
                </div>

                <div class="pt-8">
                    <loading-button type="submit" :loading="state === 'saving'" class="btn btn-orange">
                        Save Changes
                    </loading-button>
                </div>
            </div>

            <div class="pt-8 space-y-6 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Documents</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Your documents for this contract.</p>
                </div>

                <div v-if="clientContract.documents.length > 0">
                    <text-input type="file" ref="filePendingDocuments" class="md:w-1/3" @change="addPendingFileUploads" multiple />
                </div>

                <div v-if="pendingFileUploads.length > 0">
                    <span class="block form-label">Pending Uploads</span>

                    <!-- This example requires Tailwind CSS v2.0+ -->
                    <div v-if="errors?.pendingFileUpload?.file" class="rounded-md bg-red-50 p-4 my-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <!-- Heroicon name: solid/x-circle -->
                                <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                                </svg>
                            </div>

                            <div class="ml-3">
                                <h3 class="text-sm font-medium text-red-800">There were errors with your uploads</h3>
                                <div class="mt-2 text-sm text-red-700">
                                    <ul role="list" class="list-disc pl-5 space-y-1">
                                        <li>{{ errors?.pendingFileUpload?.file }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- This example requires Tailwind CSS v2.0+ -->
                    <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                        <li v-for="(upload, index) in pendingFileUploads" :key="index" class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                            <div class="w-full flex items-center justify-between p-6 space-x-6">
                                <div class="flex-1 truncate">
                                    <div class="flex items-center space-x-3">
                                        <h3 class="text-gray-900 text-sm font-medium truncate">{{ upload.file.name }}</h3>
                                        <span class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">{{ $filters.format_file_size(upload.file.size) }}</span>
                                    </div>
                                    <p class="mt-1 text-gray-500 text-sm truncate">{{ upload.file.type }}</p>
                                </div>
                            </div>

                            <div class="w-full p-3">
                                <textarea-input label="Notes" v-model="upload.notes" :errors="errors?.pendingFileUpload?.notes" :autosize="true" />
                            </div>

                            <div>
                                <div class="-mt-px flex divide-x divide-gray-200">
                                    <div class="w-0 flex-1 flex">
                                        <a href="" @click.prevent="storePendingFileUpload(upload)" :loading="upload.uploading" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                                            <icon v-if="upload.uploading" name="spinner" class="inline fill-current text-gray-400 w-5 h-5 animate-spin" />
                                            <icon v-else class="inline fill-current w-4 h-4" name="upload" />

                                            <span class="ml-3">Upload</span>
                                        </a>
                                    </div>

                                    <div class="-ml-px w-0 flex-1 flex">
                                        <a href="" @click.prevent="removePendingFileUpload(file)" class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                                            <icon name="trash" class="w-4 h-4 fill-current" />
                                            <span class="ml-3">Remove</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div v-if="clientContract.documents.length > 0">
                    <span class="block form-label">Existing Documents</span>

                    <div class="overflow-x-auto">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Filename</th>
                                    <th>Size</th>
                                    <th>Type</th>
                                    <th>Notes</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="document in clientContract.documents" :key="document.id">
                                    <td>
                                        <div class="flex">
                                            <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                                            </svg>
                                            <a :href="$route('documents.preview', { path: document.file_path })" class="ml-2 font-medium link" target="_blank">
                                                {{ document.file_name }}
                                            </a>
                                        </div>
                                    </td>
                                    <td>{{ $filters.format_file_size(document.file_size) }}</td>
                                    <td>{{ document.file_mime_type }}</td>
                                    <td>{{ document.notes }}</td>
                                    <td>
                                        <a :href="$route('documents.preview', { path: document.file_path })" class="font-medium link mr-3" target="_blank">
                                            Download
                                        </a>

                                        <a href="" @click.prevent="removeDocument(document)" class="font-medium">
                                            Remove
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div v-else class="mt-6 text-center">
                    <label class="flex flex-col items-center justify-center px-4 py-2 cursor-pointer">
                        <icon name="file-alt" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                        <h3 class="mt-2 text-sm font-medium text-gray-900">No documents available</h3>

                        <p class="mt-1 text-sm text-gray-500">Upload a document for this contract.</p>

                        <input type="file" ref="filePendingDocuments" class="hidden" @change="addPendingFileUploads" multiple />
                    </label>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import DropdownLink from '@/Shared/DropdownLink.vue';
    import { Head } from '@inertiajs/vue3';

	export default {
        components: {
            LoadingButton,
            TextInput,
            TextareaInput,
            DateInput,
            SelectInput,
            SearchInput,
            Icon,
            DropdownLink,
            Head,
        },

		props: {
            errors: {
                type: Object,
                default: () => ({}),
            },

            clientContract: {
				required: true
            },
		},

		data() {
			return {
                form: {
                    ...this.clientContract,
                },
                replaceContractDocument: !this.clientContract.contract_document_path,
                state: 'passive',
                pendingFileUploads: [],
                mounted: false,
			}
		},

        mounted() {
            this.mounted = true;
        },

		methods: {
			submitForm() {
                this.state = 'saving';
                
                this.$inertia
                    .put(this.$route('client-contracts.update', this.clientContract.id), this.form, {
                        onFinish: () => this.state = 'passive',
                    });
            },

            removeClientContract() {
                if (this.clientContract.services.length > 0) {
                    return;
                }

                let confirmed = confirm(`Are you sure you want to remove this contract? This will permanently delete all your work you have done, including all the new services added to this contract.`)

                if (confirmed) {
                    this.state = 'deleting';

                    this.$inertia.delete(this.$route('client-contracts.destroy', this.clientContract.id), {
                        preserveState: true,
                        preserveScroll:true,
                        onFinish: () => this.state = 'passive',
                    });
                }
            },

            addPendingFileUploads(e) {
                [...e.target.files].forEach(file => {
                    let pendingFileUpload = {
                        file: file,
                        notes: '', 
                        uploading: false
                    };

                    this.pendingFileUploads.push(pendingFileUpload);
                    this.$refs.filePendingDocuments.value = '';
                });
            },

            removePendingFileUpload(pendingFileUpload) {
                this.pendingFileUploads.splice(this.pendingFileUploads.indexOf(pendingFileUpload), 1);
            },

            storePendingFileUpload(pendingDocument) {
                if (pendingDocument.uploading !== true) {
                    let formData = new FormData();
                    formData.append('file', pendingDocument.file);
                    formData.append('name', pendingDocument.name);
                    formData.append('notes', pendingDocument.notes);
                    pendingDocument.uploading = true;
    
                    this.$inertia
                        .post(this.$route('client-contracts.client-contract-documents.store', this.clientContract.id), formData, {
                            errorBag: 'pendingFileUpload',
                            preserveScroll: true,
                            onSuccess: () => {
                                this.pendingFileUploads.splice(this.pendingFileUploads.indexOf(pendingDocument), 1);
    
                            },
                            onFinish: () => pendingDocument.uploading = false,
                        });
                }
            },

            removeDocument(clientContractDocument) {
                let confirmed = confirm("Are you sure you wish to perform this action?");

                if (confirmed) {
                    this.$inertia
                        .delete(this.$route('client-contract-documents.destroy', clientContractDocument.id), {
                            preserveScroll: true,
                            onSuccess: () => {
                                
                            },
                            onFinish: () => {

                            },
                        });
                }
            },
		},

        computed: {
            deletedFileUploadIds() {
                return this.clientContract
                    .documents
                    .filter(document => document.marked_for_deletion)
                    .map(document => document.id);
            }
        }
	}
</script>