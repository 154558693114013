<template>
    <Head :title="`Add Sales Tax - ${location.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('locations.index')" class="breadcrumb-link">Locations</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('locations.show', [location.id])" class="breadcrumb-link">{{ location.name }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Add Sales Tax</span>
        </nav>
    </Teleport>

    <form @submit.prevent="submitForm" id="create-location-sales-tax-form" class="space-y-8">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Choose the sales tax to add to this location</h3>
                    <div class="mt-1 max-w-2xl text-sm text-gray-500">
                        <p>Sales taxes already assigned to a location will not show up in the drop down list.</p>
                        <p>To create new sales taxes in the system, visit the <a class="link" :href="$route('tenant-settings.sales-taxes.index')">sales taxes</a> settings page.</p>
                    </div>
                </div>
            </legend>

            <div class="grid grid-cols-6 gap-y-6 gap-x-4">
                <select-input v-model="form.sales_tax_id" class="col-span-6 xs:col-span-4 md:col-span-2" placeholder="Sales Tax" label="Sales Tax" :errors="errors.sales_tax_id" mark-as-required>
                    <option :value="null">Select a sales tax</option>
                    <option v-for="salesTax in salesTaxes" :key="salesTax" :value="salesTax.id">{{ salesTax.name }} - {{ salesTax.default_rate }}% default rate</option>
                </select-input>
            </div>
        </fieldset>

        <div>
            <loading-button :loading="state === 'saving'" class="btn btn-orange">Save</loading-button>
        </div>
    </form>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import AddressPicker from '@/Shared/AddressPicker.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            AddressPicker,
            LoadingButton,
            SelectInput,
            TextInput,
            SearchInput,
            Head,
        },

        props: {
            location: {
                type: Object,
                required: true,
            },

            salesTaxes: {
                type: Array,
                required: true,
            },

            errors: {
                type: Object,
                default: () => ({})
            }
        },

        data() {
            return {
                form: {
                    sales_tax_id: null
                },
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm() {
                if (this.state === 'passive') {
                    this.state = 'saving';

                    this.$inertia.post(this.$route('locations.sales-taxes.store', [this.location.id, this.form.sales_tax_id]), {}, {
                        onFinish: () => this.state = 'passive',
                    });
                }
            },
        }
    }
</script>