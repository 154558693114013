<template>
    <div>
        <div class="flex flex-wrap justify-between">
            <span class="w-full font-medium sm:w-auto">
                <img v-if="comment.author.avatar_thumbnail_url" :src="comment.author.avatar_thumbnail_url" class="inline border border-gray-400 rounded-full mr-4 h-10 w-10" alt="User avatar" />

                <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                    <span class="text-lg font-medium leading-none text-white uppercase">{{ comment.author.first_name[0] }}{{ comment.author.last_name[0] }}</span>
                </span>
                
                <span class="text-blue-700">{{ comment.author.first_name }} {{ comment.author.last_name }}</span> @
                <span :content="formattedCreatedAt" v-tippy>{{ $filters.diff_for_humans(comment.created_at) }}</span>
            </span>

            <div class="w-full text-right sm:w-auto sm:text-left">
                <a href="" class="mr-2" title="Toggle editing of this Comment" @click.prevent="editing = !editing">
                    <icon :name="editing ? 'pencil': 'edit'" class="w-4 h-4 fill-current inline" />
                </a>

                <a href="" class="text-red-500" title="Remove this Comment" @click.prevent="$emit('comment-was-removed', comment)">
                    <icon name="trash" class="w-4 h-4 fill-current inline" />
                </a>
            </div>
        </div>

        <div v-if="editing" class="flex flex-wrap -mx-4 my-2">
            <div class="w-5/6 px-4">
                <textarea-input name="" class="whitespace-pre-wrap" v-model="comment.comment" @keyup.shift.enter="$emit('comment-was-updated', comment)" :autosize="true" />
            </div>

            <div class="w-1/6 px-4">
                <a href="" class="link hover:font-semibold" title="Save" @click.prevent="$emit('comment-was-updated', comment)">
                    <icon name="save" class="w-6 h-6 fill-current inline" />
                </a>
            </div>
        </div>
        <p v-else class="whitespace-pre-wrap p-3 bg-gray-100 text-gray-900 rounded my-2 lg:leading-tight">{{ comment.comment }}</p>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import {format, parseISO} from "date-fns";

    export default {
        components: {
            Icon,
            TextareaInput
        },
        
        props: {
            comment: {
                required: true,
                type: Object
            }
        },

        data() {
           return {
                editing: false,
                form: {...this.comment}
           };
        },

        watch: {
            comment(newValue, oldValue) {
                this.form = {...newValue};
            }
        },

        computed: {
            formattedCreatedAt() {
                return format(parseISO(this.comment.created_at), 'yyyy-LL-dd pp');
            }
        }
    }
</script>