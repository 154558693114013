<template>
    <Head :title="`Vendor - ${vendorContact.first_name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('vendor-contacts.index')" class="breadcrumb-link">Vendor Contacts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>{{ vendorContact.first_name }}</span>
        </nav>
    </Teleport>

    <div class="mt-8 max-w-3xl mx-auto gap-6 sm:px-6 lg:max-w-7xl ">
        <div
            class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div class="flex items-center space-x-5">
                <div class="flex-shrink-0">
                    <icon name="user" class="h-14 w-14"/>
                </div>

                <div>
                    <h1 class="text-2xl font-bold text-gray-900">
                        {{ vendorContact.name }}
                        <span v-if="vendorContact.external_reference_id" class="text-lg font-semi-bold text-orange-300">
                            {{ vendorContact.external_reference_id ?? 'EXT-123ABC' }}
                        </span>
                    </h1>

                    <p class="text-sm font-medium text-gray-500">
                        <span class="text-gray-900"> Contact </span> added
                        <time :datetime="vendorContact.created_at">{{ $filters.format_date_time(vendorContact.created_at) }}</time>
                    </p>
                </div>
            </div>

            <div
                class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                <inertia-link :href="$route('vendor-contacts.edit', [vendorContact.id])" class="btn btn-gray">Edit</inertia-link>
                <button @click.prevent="deleteVendorContact" class="btn btn-red">Delete</button>
            </div>
        </div>

        <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                <!-- Description list-->
                <section aria-labelledby="applicant-information-title">
                    <div class="bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">
                                Contact Information
                            </h2>
                        </div>

                        <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Company</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        <inertia-link :href="$route('vendors.show', [vendorContact.vendor_id])" class="link">
                                            {{ vendorContact.vendor.name }}
                                        </inertia-link>
                                    </dd>
                                </div>

                                <div v-if="vendorContact.email" class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Email</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ vendorContact.email }}</dd>
                                </div>

                                <div v-if="vendorContact.title" class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Title</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ vendorContact.title }}</dd>
                                </div>

                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Is Decision Maker</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{ vendorContact.is_decision_maker ? 'Yes' : 'No' }}
                                    </dd>
                                </div>

                                <div v-if="vendorContact.primary_phone_number" class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Primary Phone Number</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_phone(vendorContact.primary_phone_number) }}</dd>
                                </div>

                                <div v-if="vendorContact.alternative_phone_number" class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Alternative Phone Number</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_phone(vendorContact.alternative_phone_number) }}</dd>
                                </div>

                                <div v-if="vendorContact.office_phone_number" class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Office Phone Number</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_phone(vendorContact.office_phone_number) }}</dd>
                                </div>

                                <div v-if="vendorContact.mobile_phone_number" class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Mobile Phone Number</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_phone(vendorContact.mobile_phone_number) }}</dd>
                                </div>

	                            <div class="sm:col-span-1">
									<dt class="text-sm font-medium text-gray-500">Send SMS</dt>
									<dd class="mt-1 text-sm text-gray-900">{{ vendorContact.send_sms ? 'Yes' : 'No' }}</dd>
                                </div>

	                            <div v-if="vendorContact.smsSubscribedPhoneNumber" class="sm:col-span-1">
		                            <dt class="text-sm font-medium text-gray-500">SMS Opt-In?</dt>
		                            <dd class="mt-1 text-sm text-gray-900">{{ vendorContact.smsSubscribedPhoneNumber.status }}</dd>
	                            </div>

                                <div v-if="vendorContact.fax_phone_number" class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Fax Phone Number</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_phone(vendorContact.fax_phone_number) }}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </section>
            </div>

            <div class="lg:col-start-3 lg:col-span-1 space-y-6">

                <section aria-labelledby="timeline-title">
                    <div class="my-2 mb-6">
                        <div class="col-span-2 sm:col-span-1">
                            <div class="rounded shadow-md px-4 py-5 sm:px-6 space-y-6">
                                <h2 id="sticky-notes-title" class="text-lg leading-6 font-medium text-gray-900">Notes</h2>

                                <p v-if="vendorContact.notes" class="leading-tight italic text-gray-500" v-html="vendorContact.notes" />
                                <p v-else class="leading-tight italic text-gray-500">There are no notes for this contact.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/Shared/Icon.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import { Head } from '@inertiajs/vue3';

export default {
    components: {
        Icon,
        LoadingButton,
        Head,
    },

    props: {
        vendorContact: {
            type: Object,
            required: true
        },
    },

    data() {
        return {
            mounted: false,
        }
    },

    mounted() {
        this.mounted = true;
    },

    methods: {
        deleteVendorContact() {
            let confirmed = confirm('Are you sure you want to remove this contact?');
            if (confirmed) {
                this.$inertia.delete(this.$route('vendor-contacts.destroy', this.vendorContact.id));
            }
        },
    }
}
</script>