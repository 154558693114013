<template>
    <Head title="Create Work Order" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('work-orders.index')" class="breadcrumb-link">Work Orders</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <div v-if="openWorkOrders.length" class="grid grid-cols-1 gap-y-6 sm:items-baseline my-2">
        <div class="col-span-1">
            <div class="grid grid-cols-1 items-center border-b border-gray-200 mb-4">
                <h3 class="text-2xl leading-6 font-medium text-blue-700">Open Work Orders</h3>
            </div>

            <div v-for="workOrder in openWorkOrders" :key="workOrder.id" class="">
                <div class="grid sm:grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-6 items-center">
                    <h4>
                        <inertia-link :href="$route('work-orders.edit', workOrder.id)" class="link text-lg" title="Edit">
                            <span v-if="workOrder.acknowledged_by_id">Acknowledged</span>
                            <span v-else>Unacknowledged</span>
                        </inertia-link>
                    </h4>

                    <h5><small>Client Requests Completion By: </small><br/>
                        {{ $filters.format_date(workOrder.requested_completion_date) }}
                    </h5>

                    <h5>
                        <small>Scheduled With Vendor For:</small><br/>

                        <template v-if="workOrder.scheduled_with_vendor_date">
                            {{ $filters.format_date(workOrder.scheduled_with_vendor_date) }}
                        </template>
                        <template v-else>
                            Not set
                        </template>
                    </h5>
                </div>
            </div>
        </div>
    </div>

    <form id="create-work-order-form" class="space-y-8" @submit.prevent="submitForm">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Work Order Service Details.</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Service Details for {{ service.id }}</p>
                </div>
            </legend>

            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Status</dt>
                        <dd class="mt-1 text-sm" :class="[service.status === 'Active' ? 'text-green-600' : 'text-gray-500']">{{ service.status }}</dd>
                    </div>

                    <div class="sm:col-span-1 md:col-span-2">
                        <dt class="text-sm font-medium text-gray-500">Description</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                            <p>{{ service.description }}</p>
                        </dd>
                    </div>

                    <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Vendor</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                            <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                                <span class="text-lg font-medium leading-none text-white uppercase"></span>
                            </span>
                            <inertia-link class="link" :href="$route('vendors.show', [service.vendor_id])">{{ service.vendor.name ?? '' }}</inertia-link>
                        </dd>
                    </div>

                    <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Vendor Account</dt>
                        <dd v-if="service.vendorAccount?.account_number" class="mt-1 text-sm text-gray-900">
                            <inertia-link class="link" :href="$route('vendor-accounts.show', [service.vendor_account_id])">{{ service.vendorAccount.account_number }}</inertia-link>
                        </dd>
                        <dd v-else class="mt-1 text-sm text-gray-900">
                            Not Set
                        </dd>
                    </div>

                    <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Vendor Contract</dt>
                        <dd v-if="service.vendor_contract_id" class="mt-1 text-sm text-gray-900">
                            <inertia-link class="link" :href="$route('vendor-contracts.show', [service.vendor_contract_id])">{{ service.vendor_contract_id }}</inertia-link>

                        </dd>
                        <dd v-else class="mt-1 text-sm text-gray-900">
                            Not Set
                        </dd>
                    </div>

                    <div class="sm:col-span-1 md:col-span-2">
                        <dt class="text-sm font-medium text-gray-500">Location</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                            <p>
                                <inertia-link :href="$route('locations.show', [service.location_id])" class="link">{{ service.location.name }}</inertia-link>
                                <br />{{ service.location.address.full_address }}
                            </p>
                        </dd>
                    </div>
                </dl>
            </div>
        </fieldset>

        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Scheduling Info.</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Details regarding how this Work Order is scheduled.</p>
                </div>
            </legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <date-input
                    v-model="form.requested_completion_date"
                    name="requested_completion_date"
                    class="col-span-1"
                    label="Client Requests Completion By"
                    :errors="errors.requested_completion_date"
                />

                <date-input
                    v-model="form.scheduled_with_vendor_date"
                    class="col-span-1"
                    label="Scheduled with Vendor for"
                    name="scheduled_with_vendor_date"
                    :errors="errors.scheduled_with_vendor_date"
                />

                <date-time-local-input
                    v-model="form.occurred_at"
                    class="col-span-1"
                    label="Occurred At"
                    name="occurred_at"
                    disabled
                    :errors="errors.occurred_at"
                />

                <select-input v-model="form.source" name="source" label="Source" :errors="errors.source" mark-as-required>
                    <option :value="null">Please Select</option>
                    <option v-for="source in sources" :key="source" :value="source">{{ source }}</option>
                </select-input>

                <text-input
                    v-model="form.hauler_reference_number"
                    class="col-span-1"
                    label="Hauler Reference Number"
                    name="hauler_reference_number"
                    :errors="errors.hauler_reference_number"
                />

                <text-input
                    class="col-span-1"
                    v-model="form.external_reference_id"
                    label="External Reference Id"
                    name="external_reference_id"
                    :errors="errors.external_reference_id"
                />

                <select-input v-model="form.requested_action" class="col-span-1" label="Requested Action" name="requested_action" :errors="errors.requested_action">
                    <option v-for="(requestedAction, index) in sortedRequestedActions" :key="index" :value="requestedAction">{{ requestedAction }}</option>
                </select-input>

                <text-input
                    v-model="form.confirmed_quantity"
                    class="col-span-1"
                    name="confirmed_quantity"
                    label="Confirmed Quantity"
                    type="number"
                    step="0.01"
                    :errors="errors.confirmed_quantity"
                />

                <textarea-input
                    v-model="form.additional_instructions"
                    class="col-span-1 md:col-start-1 md:col-span-2"
                    name="additional_instructions"
                    label="Additional Instructions"
                    :errors="errors.additional_instructions"
                />
            </div>
        </fieldset>

        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Contact Info.</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Who's opening this work order.</p>
                </div>
            </legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <select-input v-model="form.requested_by_id" name="requested_by_id" label="Requested By" :errors="errors.requested_by_id">
                    <option :value="null">Unknown</option>
                    <option v-for="contact in sortedContacts" :key="contact.id" :value="contact.id">{{ contact.name }}</option>
                </select-input>

                <text-input v-model="form.on_site_contact_number" label="On-Site Contact Number" :errors="errors.on_site_contact_number" />

                <text-input v-model="form.on_site_contact_name" label="On-Site Contact Name" :errors="errors.on_site_contact_name" />
            </div>
        </fieldset>

        <div class="pt-8">
            <loading-button type="submit" :loading="state === 'saving'" class="btn btn-orange">
                Save Changes
            </loading-button>
        </div>
    </form>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import DateTimeLocalInput from '@/Shared/DateTimeLocalInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';

    export default {
        components: {
            Head,
            Icon,
            TextInput,
            DateInput,
            DateTimeLocalInput,
            TextareaInput,
            SelectInput,
            LoadingButton
        },

        props: {
            errors: {
                type: Object,
                default: () => ({}),
            },
            
            service: {
                type: Object
            },

            clientCompany: {
                type: Object,
                required: true
            },

            openWorkOrders: {
                type: Array,
                required: true
            },

            requestedActions: {
                type: Array,
                required: true
            },

            sources: {
                type: Array,
                required: true
            }
        },

        data() {
           return {
               form: {
                   service_id: this.service ? this.service.id : null,
                   requested_by_id: null,
                   source: null,
                   requested_action: null
               },
               state: 'passive',
               mounted: false,
           }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm() {
                this.state = 'saving';

                this.$inertia.post(this.$route('services.work-orders.store', this.service.id), this.form, {
                    onFinish: () => this.state = 'passive'
                });
            }
        },

        computed: {
            sortedContacts: function () {
                return this.clientCompany.contacts?.sort((a,b) => a.name.localeCompare(b.name));
            },

            sortedRequestedActions: function () {
                return this.requestedActions.toSorted();
            }
        }
    }
</script>