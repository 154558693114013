<template>
    <Head :title="`Location - ${location.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('locations.index')" class="breadcrumb-link">Locations</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>{{ location.name }}</span>
        </nav>
    </Teleport>

    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div class="flex-shrink-0">
                <icon name="map-marker-alt" class="h-14 w-14" />
            </div>

            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ location.name }}
                    <span v-if="location.external_reference_id" class="text-lg font-semi-bold text-orange-300">{{ location.external_reference_id ?? 'EXT-123ABC' }}</span>
                    <p v-if="location.deleted_at !== null" class=" text-2xl font-bold text-red-700">(Deleted)</p>
                </h1>

                <p class="text-sm font-medium text-gray-500">
                    <span class="text-gray-900"> Location</span> added <time :datetime="location.created_at">{{ $filters.format_date_time(location.created_at) }}</time>
                    <p v-if="location.archived_at">
                        <span class="text-gray-900">Archived</span> at {{ $filters.format_date_time(location.archived_at) }}
                        <span v-if="location.archivedBy"> by {{ location.archivedBy.name }}</span>
                    </p>
                </p>
            </div>
        </div>

        <div class="mt-6 flex flex-col justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <loading-button v-if="location.archived_at" type="button" :disabled="state !== 'passive'" :loading="state === 'unarchiving'" @click="unarchiveLocation" class="btn btn-gray">
                Unarchive
            </loading-button>
            <loading-button v-else type="button" :disabled="state !== 'passive'" :loading="state === 'archiving'" @click="archiveLocation" class="btn btn-gray">
                Archive
            </loading-button>

	        <inertia-link :href="$route('locations.edit', [location.id])" class="btn btn-gray my-2 lg:my-0">Edit</inertia-link>

	        <button v-if="!location.deleted_at" @click.prevent="deleteLocation" class="btn btn-red">Delete</button>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Location details and records.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Name</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ location.name }}</dd>
                            </div>

                            <div v-if="location.general_email_address" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">General Email Address</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ location.general_email_address }}</dd>
                            </div>

                            <div v-if="location.address" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Address</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ location.address.full_address }}</dd>
                            </div>

                            <div v-if="location.clientCompany" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Client Company</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link :href="$route('client-companies.show', [location.client_company_id])" class="link">
                                        {{ location.clientCompany.name }}
                                    </inertia-link>
                                </dd>
                            </div>

                            <div v-if="location.clientAccount" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Client Account</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link :href="$route('client-accounts.show', [location.client_account_id])" class="link">
                                        {{ location.clientAccount.name }}
                                    </inertia-link>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <div class="lg:col-start-3 lg:col-span-1 space-y-6 ">

            <!-- Sticky Notes !-->

            <sticky-note
                :content="location.sticky_notes"
                :hideLabel="true"
                :savingRoute="$route('locations.sticky-notes-changes.store', [location.id])"
                @saved="location.sticky_notes = $event"
            />
        </div>



        <!-- Client Contacts !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="clients-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="clients-title" class="text-lg leading-6 font-medium text-gray-900">Contacts</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Client contacts for this location.</p>
                        </div>

                        <div v-if="clientContacts.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('clients.create', {'client_company_id': location.clientCompany.id})" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="clientContacts.length > 0" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">First Name</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Title</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Email</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Phone</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">View</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="contact in clientContacts.slice(0, 12)" :key="contact.id">
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium">{{ contact.first_name }} {{ contact.last_name }}</td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium">{{ contact.title }}</td>
                                                <td class="">{{ contact.email }}</td>
                                                <td class="">{{ $filters.format_phone(contact.primary_phone_number) }}</td>
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <a :href="$route('app.impersonate-client', contact.id)" class="link mr-2" title="Log into the Client Portal as this Person" target="_blank">
                                                        Impersonate
                                                    </a>

                                                    <inertia-link :href="$route('clients.show', [contact.id])" class="link">
                                                        View<span class="sr-only">, {{ contact.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="address-book" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No contacts</h3>
                            <p class="mt-1 text-sm text-gray-500">You haven't added any contacts to this location yet.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('clients.create')" class="btn btn-orange">
                                    <icon name="plus" class="mr-2 h-4 w-4 fill-current" />
                                    New Contact
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="clientContacts.length">
                        <inertia-link :href="$route('clients.index', {search: location.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Incidents !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="incidents-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="incidents-title" class="text-lg leading-6 font-medium text-gray-900">Active Incidents</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Active Incidents for this service.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="incidents.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">#</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Status</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Priority</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Reason</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Reported</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Source</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Assigned To</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="incident in incidents.slice(0, 12)" :key="incident.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                    <inertia-link class="link" :href="$route('incidents.show', [incident.id])">
                                                        {{ incident.display_id }}
                                                    </inertia-link>
                                                </td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm">{{ incident.status }}</td>
                                                <td>{{ incident.priority }}</td>
                                                <td>{{ incident.reason.name }}</td>
                                                <td>{{ $filters.format_date_time(incident.reported_at) }}</td>
                                                <td>{{ incident.source }}</td>
                                                <td>
                                                    <template v-if="incident.assignedTo" class="mt-1 text-sm text-gray-900">
                                                        <img v-if="incident.assignedTo?.avatar_thumbnail_url" :src="incident.assignedTo?.avatar_thumbnail_url" class="inline border border-gray-400 rounded-full mr-4 h-10 w-10" />

                                                        <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                                                            <span class="text-lg font-medium leading-none text-white uppercase">{{ incident.assignedTo?.first_name[0] }}{{ incident.assignedTo?.last_name[0] }}</span>
                                                        </span>
                                                        <span>{{ incident.assignedTo?.name }}</span>
                                                    </template>
                                                    <template v-else>Unassigned</template>
                                                </td>
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('incidents.edit', [incident.id])" class="link">
                                                        Edit<span class="sr-only">, {{ incident.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="tasks" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No active incidents</h3>
                            <p class="mt-1 text-sm text-gray-500">There are no active incidents for this location.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('incidents.create', {location_id: location.id})" class="btn btn-orange">
                                    <icon name="plus" class="mr-2 h-4 w-4 fill-current" />
                                    New Incident
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="incidents.length">
                        <inertia-link :href="$route('incidents.index', {search: location.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="services-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="services-title" class="text-lg leading-6 font-medium text-gray-900">Services</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Services at this location.</p>
                        </div>

                        <div v-if="services.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('services.create')" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="services.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
	                                            <th scope="col" class="px-0 text-right text-sm font-semibold text-gray-900">
								                    <span v-tippy="'This column displays an icon if the services is monitored by Pioneer.'">
									                    <icon name="info-circle" class="w-4 h-4 fill-current text-gray-400" />
									                    <span class="sr-only"></span>
								                    </span>
	                                            </th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Id</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Type</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Status</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Effective Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Termination Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Pass Through</th>
	                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Equipment Label</th>
	                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Description</th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="service in services.slice(0, 12)" :key="service.id">
	                                            <td>
								                    <pioneer-indicator v-if="service.compactorMonitor" :compactor-monitor="service.compactorMonitor" />
	                                            </td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">
                                                    <inertia-link class="link" :href="$route('services.show', [service.id])">{{ service.id }}</inertia-link>
                                                </td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.type }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.status }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.effective_date ? $filters.format_date(service.effective_date) : 'Not Set' }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.termination_date ? $filters.format_date(service.termination_date) : 'Not Set' }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.is_pass_through ? 'Yes' : 'No' }}</td>
	                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.label }}</td>
	                                            <td class="py-2 pl-4 pr-3 text-sm sm:pl-6 text-gray-500">{{ service.description }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="dumpster" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Services</h3>
                            <p class="mt-1 text-sm text-gray-500">Create a service.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('services.create')" class="btn btn-orange">
                                    <icon name="plus" class="mr-2 h-4 w-4 fill-current" />
                                    New Service
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="services.length">
                        <inertia-link :href="$route('services.index', {search: location.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>


        <!-- Location Fees !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3" v-if="$page.props.permissions.accessRevenueManagement">
            <section aria-labelledby="location-fees-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="location-fees-title" class="text-lg leading-6 font-medium text-gray-900">Fees</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Fees charged to your clients serviced at this location.</p>
                        </div>

                        <div v-if="locationFees.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('locations.location-fees.create', [location.id])" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>

                        <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <div v-if="locationFees.length" class="flex flex-col">
                                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Id</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Billing Style</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Header Label</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Label</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Start Date</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">End Date</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Frequency</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Amount</th>
                                                    <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                        <span class="sr-only">View</span>
                                                    </th>
                                                    <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                        <span class="sr-only">Edit</span>
                                                    </th>
                                                </tr>

                                            </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="fee in locationFees.slice(0, 12)" :key="fee.id">
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-500">{{ fee.id }}</td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium">{{ fee.billing_style }}</td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium">{{ fee.header_label }}</td>
                                                <td class="">{{ fee.label }}</td>
                                                <td class="">{{ $filters.format_date(fee.start_date) }}</td>
                                                <td class="">{{ $filters.format_date(fee.end_date) }}</td>
                                                <td class="">{{ fee.frequency }}</td>
                                                <td class="">{{ fee.billing_style === 'Fixed' ? $filters.format_money(fee.amount) : 'N/A' }}</td>
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('location-fees.edit', [fee.id])" class="link">
                                                        Edit<span class="sr-only">, {{ fee.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="file-signature" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Fees</h3>
                            <p class="mt-1 text-sm text-gray-500">Create a new fee.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('locations.location-fees.create', [location.id])" class="btn btn-orange">
                                    <icon name="plus" class="mr-2 h-4 w-4 fill-current" />
                                    New Fee
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                        <div v-if="locationFees.length">
                            <inertia-link :href="$route('location-fees.index', {search: location.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                        </div>
                    </div>
                </section>
            </div>


        <!-- Sales Taxes !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3" v-if="$page.props.permissions.accessRevenueManagement">
            <section aria-labelledby="sales-taxes-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="sales-taxes-title" class="text-lg leading-6 font-medium text-gray-900">Sales Taxes</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">
                                Client line items for this location will have these
                                <a class="link" :href="$route('tenant-settings.sales-taxes.index')">sales taxes</a>
                                generated.
                            </p>
                        </div>

                        <div v-if="location.salesTaxes.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('locations.sales-taxes.create', location.id)" class="btn btn-orange">Assign Sales Tax</inertia-link>
                        </div>
                    </div>


                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="location.salesTaxes.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Name</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Rate</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="salesTax in location.salesTaxes" :key="salesTax.id">
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                    <a class="link" :href="$route('tenant-settings.sales-taxes.edit', salesTax.id)">{{ salesTax.name }}</a>
                                                </td>
                                                <td>{{ salesTax.default_rate }}%</td>
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <button @click="removeLocationSalesTax(salesTax)" class="link">
                                                        Remove<span class="sr-only">, Location Sales Tax {{ salesTax.name }}</span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="percent" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Sales Taxes</h3>
                            <p class="mt-1 text-sm text-gray-500">You haven't added any sales taxes to this location yet.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('locations.sales-taxes.create', [location.id])" class="btn btn-orange">
                                    <icon name="plus" class="mr-2 h-4 w-4 fill-current" />
                                    Assign Sales Tax
                                </inertia-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>


        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="metadata-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="metadata-title" class="text-lg leading-6 font-medium text-gray-900">Metadata</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Metadata for this location.</p>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="Object.keys(location.metadata).length > 0" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table-condensed table-striped">
                                        <thead>
                                            <tr>
                                                <th>Key</th>
                                                <th>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(value, key) in location.metadata" :key="key">
                                                <td class="capitalize">{{ key.replace(/_/g, ' ') }}</td>
                                                <td>{{ value }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="database" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Metadata</h3>
                            <p class="mt-1 text-sm text-gray-500">No Metadata has been added yet.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import PioneerIndicator from "../../Shared/PioneerIndicator.vue";
    import { Head, router } from '@inertiajs/vue3';
    import { ref, inject, onMounted, reactive } from 'vue';
    import StickyNote from "@/Shared/StickyNote.vue";

    /**
     * Props
     */
    const props = defineProps({
        location: {
            type: Object,
            required: true
        },

        locationFees: {
            type: Object,
            required: true,
        },

        services: {
            type: Array,
            required: true,
        },

        incidents: {
            type: Array,
            required: true,
        },

        metaFields: {
            type: Array,
            required: true,
        },

        clientContacts: {
            type: Array,
            required: true
        },

        filters: {
            type: Object,
            required: true
        },

        errors: {
            type: Object,
            default: () => ({})
        },
    });

    const route = inject('route');
    const location = reactive({...props.location});

    /**
     * State
     */
    const mounted = ref(false);
    const state = ref('passive');

    onMounted(() => {
        mounted.value = true;
    });

    /**
     * Methods
     */
    function deleteLocation() {
        if (state.value !== 'passive') {
            return;
        }

        let confirmed = confirm('Are you sure you want to remove this location?');

        if (confirmed) {
            state.value = 'deleting';

            router.delete(route('locations.destroy', props.location.id), {
                onFinish: () => {
                    state.value = 'passive';
                }
            });
        }
    }

    function removeLocationSalesTax(salesTax) {
        if (state.value !== 'passive') {
            return;
        }

        let confirmed = confirm('Are you sure you want to remove this sales tax from this location?');

        if (confirmed) {
            state.value = 'removing';

            router.delete(route('locations.sales-taxes.destroy', [props.location.id, salesTax.id]), {
                onFinish: () => {
                    state.value = 'passive';
                }
            });
        }
    }

    function archiveLocation() {
        if (state.value !== 'passive' || props.location.archived_at) {
            return;
        }

        state.value = 'archiving';

        router.patch(route('locations.archive', props.location.id), {}, {
            preserveScroll: true,
            onFinish: () => {
                state.value = 'passive';
            }
        });
    }

    function unarchiveLocation() {
        if (state.value !== 'passive' || !props.location.archived_at) {
            return;
        }

        state.value = 'unarchiving';

        router.patch(route('locations.unarchive', props.location.id), {}, {
            preserveScroll: true,
            onFinish: () => {
                state.value = 'passive';
            }
        });
    }
</script>
