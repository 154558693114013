<template>
    <div class="bg-gray-100 min-h-screen">
        <div class="bg-white">
            <div class="max-w-screen-lg mx-auto px-4 py-3">
                <div class="flex flex-row justify-around items-center md:space-x-8">
                    <div class="flex flex-col items-center space-x-3 md:flex-row">
                        <img v-if="tenant.brand_mark_url" :src="tenant.brand_mark_url" :alt="`${tenant.name} Brand Mark`" class="mx-auto">
                        <div class="text-center text-2xl mt-2">
                            <span class="font-semibold text-gray-900">{{ tenant.name }}</span>
                        </div>
                    </div>

                    <div class="flex flex-col space-y-4 md:flex-row md:space-y-0 md:flex-grow md:justify-end md:space-x-8">
                        <div class="text-center text-sm">
                            <span v-if="tenant.support_phone_number">
                                <span class="font-medium text-gray-900">Support Phone</span><br><a :href="`tel:${this.tenant.support_phone_number}`" class="link">{{ $filters.format_phone(tenant.support_phone_number) }}</a>
                            </span>
                        </div>

                        <div class="text-center text-sm">
                            <span v-if="tenant.support_email_address">
                                <span class="font-medium text-gray-900">Support Email</span><br><a :href="`mailto:${this.tenant.support_email_address}`" class="link">{{ tenant.support_email_address }}</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full relative h-1" :style="bgBrandColor"></div>

        <main class="max-w-screen-lg mx-auto pt-0 space-y-8 sm:pt-8 sm:px-4 sm:divide-y sm:divide-gray-900/10">
<!--	         Scheduling-->
	        <div class="grid grid-cols-1 gap-x-8 gap-y-3 sm:pt-8 md:grid-cols-3">
		        <div class="px-4 sm:px-0">
			        <h2 class="text-2xl font-semibold leading-7 text-gray-900">Scheduling</h2>
			        <p class="mt-1 text-sm leading-6 text-gray-600"></p>
		        </div>

		        <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
			        <div class="p-5 sm:p-8 divide-y sm:divide-none">
				        <div class="grid grid-cols-1 gap-y-4 sm:gap-x-4 sm:grid-cols-2 lg:gap-y-8">
					        <div class="grid grid-cols-2 gap-2 items-center leading-5 text-gray-600 pt-4 sm:block sm:pt-0">
						        <div class="font-medium text-gray-900 text-sm">Requested Action</div>
						        <div class="mt-1 text-sm">
							        {{ workOrder.requested_action }}
						        </div>
					        </div>

					        <div class="grid grid-cols-2 gap-2 items-center leading-5 text-gray-600 pt-4 sm:block sm:pt-0">
						        <div class="font-medium text-sm text-gray-900">Requested Completion Date</div>
						        <div class="mt-1 text-sm">
							        {{ formattedRequestedDate }}
						        </div>
					        </div>
				        </div>

				        <form class="mt-8 pt-8 sm:pt-0" method="post" action="" @submit.prevent="schedule">
					        <template v-if="workOrderSchedulingStatuses.includes(workOrder.status)">
						        <div class="space-y-6">
							        <div class="grid grid-cols-1 items-center sm:grid-cols-2 sm:gap-4 lg:gap-4">
								        <label for="scheduled_with_vendor_date" class="block text-sm font-medium leading-5 text-gray-900">
									        Scheduled Completion Date <span class="text-red-500">*</span>
								        </label>

								        <div class="rounded-md shadow-sm">
									        <date-input :disabled="workOrder.scheduled_with_vendor_date" name="scheduled_with_vendor_date" v-model="form.scheduled_with_vendor_date" placeholder="Scheduled with Vendor for" :errors="errors.scheduled_with_vendor_date" />
								        </div>
							        </div>

							        <div class="grid grid-cols-1 items-center sm:grid-cols-2 sm:gap-4 lg:gap-4">
								        <label for="hauler_reference_number" class="block text-sm font-medium leading-5 text-gray-900">
									        Vendor Reference Number
								        </label>

								        <div class="rounded-md shadow-sm">
									        <text-input name="hauler_reference_number" v-model="form.hauler_reference_number" placeholder="Vendor Reference Number" :errors="errors.hauler_reference_number" />
								        </div>
							        </div>

							        <div v-if="form.scheduled_with_vendor_date !== workOrder.requested_completion_date && form.scheduled_with_vendor_date !== null" class="w-full border border-amber-200 px-3 py-2 bg-amber-50 text-amber-900 font-bold text-sm text-center">
								        Notice: The date you selected is different than the date requested.
							        </div>

							        <div class="md:flex md:flex-row md:justify-between md:items-start">
								        <div v-if="form.scheduled_with_vendor_date" class="flex flex-row max-w-md mx-auto md:mr-8 md:max-w-full">
									        <boolean-checkbox-input id="acknowledgementAccepted" :disabled="!form.scheduled_with_vendor_date" :value="true" v-model:checked="form.acknowledgementAccepted" :errors="errors.acknowledgementAccepted" />

									        <label for="acknowledgementAccepted" class="text-sm font-medium leading-5 text-gray-900">
										        <span class="text-red-500">*</span>
										        I confirm that this service is scheduled to be completed on {{ formattedScheduledDate }}.
									        </label>
								        </div>
							        </div>
							        <div class="mt-4 md:mt-0 md:flex-grow md:flex-shrink-0 text-center">
								        <loading-button :disabled="scheduleButtonDisabled" :loading="saving" class="btn btn-orange inline-flex justify-center px-4 py-3 w-full rounded-md text-xl md:w-auto md:text-base md:px-3 md:py-2 disabled:opacity-75 disabled:cursor-not-allowed disabled:shadow-none">
									        Confirm Schedule
								        </loading-button>
							        </div>

						        </div>
					        </template>

					        <template v-else-if="workOrder.scheduled_with_vendor_date && !workOrderIsCancelled">
						        <div class="py-2 italic">
							        This work order is scheduled to be completed on <strong>{{ $filters.format_date(workOrder.scheduled_with_vendor_date) }}</strong>.
						        </div>

						        <div v-if="confirmedEvent" class="py-2">
							        Scheduling was confirmed by {{ confirmedEvent.email }} at {{ formattedConfirmedEventWhen }}
						        </div>
					        </template>

					        <template v-else-if="workOrderIsCancelled">
						        <div class="py-2 italic">
							        This work order has been <strong>cancelled</strong>.
						        </div>
					        </template>

					        <template v-else>
						        <div class="py-2 italic">
							        Scheduling for this work order cannot be updated through this form because the work order is in the <strong>{{ workOrder.decorated_status }}</strong> status.
						        </div>
					        </template>
				        </form>
			        </div>
		        </div>
	        </div>
<!--	         Service Details-->
            <div class="grid grid-cols-1 gap-x-8 gap-y-3 sm:pt-8 md:grid-cols-3">
                <div class="px-4 sm:px-0">
                    <h2 class="text-2xl font-semibold leading-7 text-gray-900">Service Details</h2>
                    <p class="mt-1 text-sm leading-6 text-gray-600"></p>
                </div>

                <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                    <div class="p-5 sm:p-8 text-sm">
                        <div class="grid grid-cols-1 gap-y-4 sm:gap-x-4 sm:grid-cols-2 divide-y sm:divide-none md:grid-cols-1 lg:grid-cols-2 lg:gap-y-8">
                            <div class="grid grid-cols-2 items-center gap-2 leading-5 text-gray-600 sm:block">
                                <div class="font-medium text-gray-900">Service Location</div>
                                <div>
                                    <div class="mt-1 text-sm">{{ locationName }}</div>
                                    <div class="mt-1">
                                        <a :href="`https://www.google.com/maps/preview/?q=${encodeURIComponent(address.full_address)}&amp;z=14`" target="_blank" class="block text-xs link">
                                            {{ address.street_number }} {{ address.street_address }}<br>
                                            {{ address.city }}, {{ address.state }}, {{ address.postal_code }}
                                            <span v-if="address.country_code.toUpperCase() !== 'US'">{{ address.country_code }}</span>
                                            <icon name="external-link-alt" class="relative inline w-3 h-3 fill-current ml-1 -top-0.5" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div v-if="service.vendor_account_id" class="grid grid-cols-2 items-center gap-2 leading-5 text-gray-600 pt-4 sm:block sm:pt-0">
                                <div class="font-medium text-gray-900">Account Number</div>
                                <div class="mt-1 text-sm">
                                    {{ vendorAccountNumber }}
                                </div>
                            </div>

                            <div v-if="service.bin.type" class="grid grid-cols-2 items-center gap-2 leading-5 text-gray-600 pt-4 sm:block sm:pt-0">
                                <div class="font-medium text-gray-900">Equipment</div>
                                <div class="mt-1 text-sm">
                                    ({{ service.bin.quantity }}) {{ service.bin.size }} {{ service.bin.size_metric }} {{ service.bin.type }} {{ service.bin.material_type }}
                                </div>
                            </div>


                            <div v-if="service.label" class="grid grid-cols-2 items-center gap-2 leading-5 text-gray-600 pt-4 sm:block sm:pt-0">
                                <div class="font-medium text-gray-900">Equipment Label</div>
                                <div class="mt-1 text-sm">
                                    {{ service.label }}
                                </div>
                            </div>

                            <div v-if="workOrder.on_site_contact_number || tenant.support_phone_number" class="grid grid-cols-2 items-center gap-2 leading-5 text-gray-600 pt-4 sm:block sm:pt-0">
                                <div class="font-medium text-gray-900 mb-1">On-site Contact</div>
                                <div v-if="workOrder.on_site_contact_name && workOrder.on_site_contact_number">
                                    <span class="text-sm">{{ workOrder.on_site_contact_name }}</span>
                                    <a :href="`tel:${workOrder.on_site_contact_number}`" class="block mt-1 link text-sm">{{ $filters.format_phone(workOrder.on_site_contact_number) }}</a>
                                </div>

                                <div v-else-if="workOrder.on_site_contact_number">
                                    <a :href="`tel:${workOrder.on_site_contact_number}`" class="link text-sm">{{ $filters.format_phone(workOrder.on_site_contact_number) }}</a>
                                </div>

                                <div v-else>
	                                <span class="text-sm">{{ tenant.name }}</span><br>
                                    <a :href="`tel:${tenant.support_phone_number}`" class="link text-sm">{{ $filters.format_phone(tenant.support_phone_number) }}</a>
                                </div>
                            </div>

                            <div v-if="workOrder.additional_instructions" class="grid grid-cols-2 items-center gap-2 leading-5 text-gray-600 pt-4 sm:block sm:pt-0">
                                <div class="font-medium text-gray-900">Additional Instructions</div>
                                <div class="mt-1 text-sm">
                                    {{ workOrder.additional_instructions }}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main>

        <div class="py-8 w-full text-sm">
            <div class="flex flex-col items-center justify-center flex-shrink-1">
                <div class="flex justify-center flex-row space-x-1 text-gray-600/50">
                    <span>Powered by</span>
                    <a href="https://www.discoveryapp.io?utm_source=work-order-external-scheduling" target="_blank" class="link opacity-80 hover:opacity-100">Discovery</a>
                    <span>| Making Waste Manageable</span>
                </div>
                <div class="text-gray-600/50 mt-2">
                    &copy; DSQ Technology, LLC {{ new Date().getFullYear() }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BooleanCheckboxInput from '@/Shared/BooleanCheckboxInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import BlankLayout from "@/Shared/BlankLayout.vue";
    import { format, parseISO } from 'date-fns';
    export default {
        layout: BlankLayout,

        components: {
            BooleanCheckboxInput,
            DateInput,
            Icon,
            LoadingButton,
            TextInput,
        },

        props: {
            service: {
                type: Object,
                required: true
            },

            vendorAccountNumber: {
                type: String,
            },

            locationName: {
                type: String,
                required: true
            },

            address: {
                type: Object,
                required: true
            },

            confirmedEvent: {
                type: Object
            },

            tenant: {
                type: Object,
                required: true
            },

            updateSignedRoute: {
                type: String,
                required: true
            },

            workOrder: {
                type: Object,
                required: true
            },

            workOrderIsCancelled: {
                type: Boolean,
                required: true
            },

	        workOrderSchedulingStatuses: {
                type: Array,
                required: true
            },

            errors: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                saving: false,

                form: {
                    ...this.workOrder,
                    scheduled_with_vendor_date: this.workOrder.scheduled_with_vendor_date ? this.workOrder.scheduled_with_vendor_date : null,
                    acknowledgementAccepted: false
                },
            }
        },

	    mounted() {
		    document.title = `Scheduling Request: ${this.workOrder.requested_action} | ${this.$page.props.appName}`;
		    window.addEventListener('beforeunload', this.beforeUnload);
	    },

        methods: {
            schedule() {
                this.$inertia.patch(this.updateSignedRoute, this.form, {
                    onFinish: () => this.saving = false,
                });
            },

            convertDateToUTC: function(date) {
                return new Date(new Date(date).toUTCString().substring(0,25));
            },

	        beforeUnload(event) {
				if (!this.workOrder.scheduled_with_vendor_date) {
					event.preventDefault();
					event.returnValue = '';
				}
	        }
        },

        watch: {
            '$page.props': function(newValue, oldValue) {
                if (newValue.success_message) {
                    this.$toast.success(newValue.success_message)
                }

                if (newValue.error_message) {
                    this.$toast.error(newValue.error_message)
                }
            }
        },

        computed: {
            bgBrandColor: function() {
                return 'background-color: ' + this.tenant.brand_primary_color;
            },

            formattedConfirmedEventWhen: function() {
                if (!this.confirmedEvent) {
                    return null;
                }

                return format(parseISO(new Date(this.confirmedEvent.when + 'Z').toISOString()), 'MM/dd/yyyy h:mm a');
            },

            formattedScheduledDate: function() {
                if (!this.form.scheduled_with_vendor_date) {
                    return null;
                }

                return format(parseISO(this.convertDateToUTC(new Date(this.form.scheduled_with_vendor_date)).toISOString()), 'MM/dd/yyyy');
            },

            formattedRequestedDate: function() {
                if (!this.workOrder.requested_completion_date) {
                    return null;
                }

                return format(parseISO(this.convertDateToUTC(new Date(this.workOrder.requested_completion_date)).toISOString()), 'MM/dd/yyyy');
            },

            scheduleButtonDisabled: function() {
                return (!this.form.acknowledgementAccepted || !this.workOrderSchedulingStatuses.includes(this.workOrder.status));
            }
        }
    }
</script>
