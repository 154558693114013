<template>
    <Head title="Create Vendor Invoice Sales Tax" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Tenant Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.vendor-sales-taxes.index')" class="breadcrumb-link">Vendor Invoice Sales Taxes</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <div class="container mx-auto">
        <div class="my-4">
            <form id="create-vendor-sales-tax-form" class="w-full">
                <div class="grid grid-cols-2 gap-6">
                    <select-input v-model="form.country" label="Country" :errors="errors.country" mark-as-required>
                        <option value="United States">United States</option>
                        <option value="Canada">Canada</option>
                    </select-input>

                    <select-input v-if="form.country" v-model="form.state_province" label="State/Province" :errors="errors.state_province" mark-as-required>
                        <option v-if="form.country === 'United States'" v-for="state in states">{{ state }}</option>
                        <option v-if="form.country === 'Canada'" v-for="province in provinces" >{{ province }}</option>
                    </select-input>
                    <select-input v-else label="State/Province" v-model="form.state_province" :errors="errors.state_province" mark-as-required>
                        <option disabled value="null">Please Select A Country First</option>
                    </select-input>

                    <select-input v-if="form.country" v-model="form.vendor_sales_tax_type_id" label="Type" :errors="errors.vendor_sales_tax_type_id" mark-as-required>
                        <option v-for="type in typesByCountry[form.country]" :value="type.id">{{ type.name }}</option>
                    </select-input>
                    <select-input v-else label="Type" v-model="form.vendor_sales_tax_type_id" :errors="errors.vendor_sales_tax_type_id" mark-as-required>
                        <option disabled value="null">Please Select A Country First</option>
                    </select-input>

                    <text-input v-model="form.name" label="Name" :errors="errors.name" mark-as-required/>

                    <text-input v-model="form.external_reference_id" label="External Reference Id" :errors="errors.external_reference_id" />

                    <toggle-switch-input v-model="form.applied_by_default" label="Apply to Locations by Default" />
                </div>

                <div class="mt-6">
                    <inertia-link :href="$route('tenant-settings.vendor-sales-taxes.index')" class="btn btn-gray mr-4">
                        Cancel
                    </inertia-link>
                    <button class="btn btn-blue" @click.prevent="addVendorSalesTax">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { computed, ref, reactive, inject, onMounted, watch } from 'vue';

// Components
import { Head } from '@inertiajs/vue3';
import Icon from '@/Shared/Icon.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import { states, provinces } from '@/Shared/StatesAndProvinces.js';
import TextInput from '@/Shared/TextInput.vue';
import TextareaInput from '@/Shared/TextareaInput.vue';
import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';

// Inject
const route = inject('route');

// Props
const props = defineProps({
    errors: {
        type: Object,
        default: () => ({})
    },
    vendorSalesTax: {
        type: Object,
        required: true
    },
    typesByCountry: {
        type: Object,
        required: true
    }
});

// State
const form = reactive({...props.vendorSalesTax, applied_by_default: true});
const mounted = ref(false);

// Mount
onMounted(() => {
    mounted.value = true;
});

// Watchers
watch(
    () => form.country,
    () => {
        form.state_province = null;
        form.type = null;
    }
);

// Methods
function addVendorSalesTax() {
    router.post(route('tenant-settings.vendor-sales-taxes.store'), form);
}
</script>