<template>
    <Head title="Create Client Account" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-accounts.index')" class="breadcrumb-link">Client Accounts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <form @submit.prevent="submitForm" id="edit-client-account-form" class="space-y-8">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Info</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Basic settings and info for this account.</p>
                </div>
            </legend>

            <div class="grid grid-cols-4 gap-y-6 gap-x-4">
                <select-input
	                data-cy="client-accounts.create.client-company-select"
                    class="col-span-4 sm:col-span-2"
                    v-model="form.client_company_id"
                    label="Client Company"
                    :errors="errors.client_company_id"
                    mark-as-required
                >
                    <template v-if="clientCompanies.length">
                        <option v-for="clientCompany in clientCompanies" :key="clientCompany.id" :value="clientCompany.id">
                            {{ clientCompany.name }}
                        </option>
                    </template>
                </select-input>

                <text-input
	                data-cy="client-accounts.create.name-input"
                    class="col-span-4 sm:col-span-2 md:col-start-1"
                    v-model="form.name"
                    label="Name" placeholder="Name"
                    :errors="errors.name"
                    mark-as-required
                />
                <text-input
	                data-cy="client-accounts.create.account-number-input"
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    v-model="form.account_number" label="Account Number"
                    :model-value="form.account_number"
                    :errors="errors.account_number"
                    mark-as-required
                />
                <text-input
	                data-cy="client-accounts.create.primary-contact-name-input"
                    v-model="form.primary_contact_name"
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    label="Primary Contact Name"
                    placeholder="Primary Contact Name"
                    :errors="errors.primary_contact_name"
                    mark-as-required
                />
                <text-input
	                data-cy="client-accounts.create.primary-telephone-number-input"
                    v-model="form.primary_telephone_number"
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    label="Primary Telephone Number"
                    placeholder="(123) 456-7890"
                    :errors="errors.primary_telephone_number"
                />
                <text-input
	                data-cy="client-accounts.create.primary-email-address-input"
                    v-model="form.primary_email_address"
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    label="Primary Email Address"
                    placeholder="Primary Email Address"
                    :errors="errors.primary_email_address"
                    mark-as-required
                />
                <div class="col-span-4 sm:col-span-2 md:col-span-1">
                    <select-input
	                    data-cy="client-accounts.create.default-payment-method-select"
                        v-model="form.default_payment_method"
                        class="col-span-4 sm:col-span-2 md:col-span-1"
                        label="Default Payment Method"
                        :errors="errors.default_payment_method"
                    >
                        <option v-for="paymentMethod in paymentMethods" :key="paymentMethod" :value="paymentMethod">
                            {{ paymentMethod }}
                        </option>
                    </select-input>
                </div>

                <text-input
	                data-cy="client-accounts.create.purchase-order-input"
                    v-model="form.purchase_order"
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    label="Purchase Order"
                    placeholder="Purchase Order"
                    :errors="errors.purchase_order"
                />
                <text-input
	                data-cy="client-accounts.create.payment-terms-input"
                    v-model="form.payment_terms"
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    label="Payment Terms"
                    placeholder="Payment Terms"
                    :errors="errors.payment_terms"
                    mark-as-required
                />
                
                <text-input
	                data-cy="client-accounts.create.external-reference-id-input"
                    v-model="form.external_reference_id"
                    label="External Reference Id"
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    :errors="errors.external_reference_id"
                />
                <textarea-input
	                data-cy="client-accounts.create.billing-address-input"
                    class="col-span-4 md:col-span-3"
                    v-model="form.billing_address"
                    label="Billing Address"
                    :errors="errors.billing_address"
                    mark-as-required
                />
            </div>
        </fieldset>

        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Client Invoicing</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Client invoice settings for this account.</p>
                </div>
            </legend>

            <div class="grid grid-cols-4 gap-y-6 gap-x-4">
                <select-input
	                data-cy="client-accounts.create.invoice-template-select"
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    v-model="form.invoice_template_view"
                    label="Invoice Template View"
                    :errors="errors.invoice_template_view"
                >
                    <template v-if="templateViews">
                        <option v-for="templateView in templateViews" :key="templateView" :value="templateView">
                            {{ templateView }}
                        </option>
                    </template>
                </select-input>

                <text-input
	                data-cy="client-accounts.create.other-invoice-recipients-input"
                    v-model="form.other_invoice_recipients"
                    class="col-span-4 sm:col-start-1 sm:col-span-2"
                    label="Other Invoice Recipients"
                    placeholder="johndoe@fake.com, jane@somedomain.com"
                    :errors="errors.other_invoice_recipients"
                />
                <text-input
	                data-cy="client-accounts.create.other-delinquent-notice-recipients-input"
                    v-model="form.other_delinquent_notice_recipients"
                    class="col-span-4 sm:col-span-2"
                    label="Other Delinquent Notice Recipients"
                    placeholder="johndoe@fake.com, jane@somedomain.com"
                    :errors="errors.other_delinquent_notice_recipients"
                />

                <div class="col-span-4 sm:col-span-2 md:col-span-1 md:col-start-1">
                    <toggle-switch-input data-cy="client-accounts.create.show-account-name-in-invoice-input" label="Show Group Name In Invoice" v-model="form.show_account_name_in_invoice" />
                </div>

                <div class="col-span-4 sm:col-span-2 md:col-span-1">
                    <toggle-switch-input data-cy="client-accounts.create.show-account-name-in-invoice-input" label="Show Service Location Names In Invoice" v-model="form.show_service_location_names_in_invoice" />
                </div>

                <div class="col-span-4 sm:col-span-2 md:col-span-1">
                    <toggle-switch-input data-cy="client-accounts.create.show-account-name-in-invoice-input" label="Single Invoice Per Period" v-model="form.single_invoice_per_period" />
                </div>

                <div class="col-span-4 sm:col-span-2 md:col-span-1">
                    <toggle-switch-input data-cy="client-accounts.create.cancellation-email-hold-input" label="Cancellation Email" v-model="form.cancellation_email_hold" />
                </div>

                <div class="col-span-4 sm:col-span-2 md:col-span-1">
                    <toggle-switch-input data-cy="client-accounts.create.send-collection-notices-to-all-billing-contacts-input" label="Send Collection Notices To All Billing Contacts" v-model="form.send_collection_notices_to_all_billing_contacts" />
                </div>

                <div class="col-span-4 sm:col-span-2 md:col-span-1">
                    <toggle-switch-input data-cy="client-accounts.create.use-initial-line-items-for-client-billing-input" label="Use Initial Line Items for Client Billing" v-model="form.use_initial_line_items_for_client_billing" />
                </div>

                <div class="col-span-4 sm:col-span-2 md:col-span-1">
                    <toggle-switch-input data-cy="client-accounts.create.invoices_due_upon_receipt" label="Set Invoices as Due Upon Receipt" v-model="form.invoices_due_upon_receipt" />
                </div>
            </div>
        </fieldset>

        <div>
            <loading-button data-cy="client-accounts.create.save-button" :loading="state === 'saving'" class="btn btn-orange">
                Save
            </loading-button>
        </div>
    </form>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            LoadingButton,
            Pagination,
            SelectInput,
            TextareaInput,
            TextInput,
            ToggleSwitchInput,
            Head,
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({})
            },
            clientAccount: Object,
            clientCompanies: Array,
            templateViews: Object,
            paymentMethods: Object,
            tenant: Object,
        },

        data() {
            return {
                form: {
                    ...this.clientAccount,
                },
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm() {
                if (this.state === 'passive') {
                    this.state = 'saving';

                    this.$inertia
                        .post(this.$route('client-accounts.store'), this.form, {
                            onFinish: () => this.state = 'passive'
                        });
                }
            }
        },
    }
</script>