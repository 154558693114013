<template>
    <Head :title="`Edit Incident - ${incident.display_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('incidents.index')" class="breadcrumb-link">Incidents</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('incidents.show', [incident.id])" class="breadcrumb-link">{{ incident.display_id }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="flex flex-wrap justify-between items-center ">
        <horizontal-sub-nav current-tab="edit" :incident-id="incident.id" />

        <div v-if="otherUsers.length > 0" class="flex -space-x-2 overflow-hidden mb-1">
            <template v-for="user in otherUsers" :key="user.id">
                <img v-if="user.avatar_thumbnail_url" :src="user.avatar_thumbnail_url" class="inline-block h-10 w-10 rounded-full ring-2 ring-white" :alt="user.name">
                <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                    <span class="text-lg font-medium leading-none text-white uppercase">{{ user.first_name[0] }}{{ user.last_name[0] }}</span>
                </span>
            </template>
        </div>
    </div>

    <div class="mt-4 mb-12">
        <span class="text-xl font-medium text-gray-600">
            <span class="fal fa-list-alt"></span>
            To-Do Checklist
        </span>

        <div class="flex flex-wrap -mx-4 my-2 mb-4">
            <div class="w-5/6 px-4">
                <textarea v-model="newChecklistItem" class="form-textarea" @keyup.shift.enter="storeChecklistItem"></textarea>
            </div>

            <div class="w-1/6 px-4">
                <a href="" class="link text-lg" title="Add new Checklist Item" @click.prevent="storeChecklistItem">
                    <icon name="plus" class="w-4 h-4 fill-current inline" />
                </a>
            </div>
        </div>

        <ul class="my-2 mb-4">
            <li v-for="checklistItem in sortedChecklistItems"
                :key="checklistItem.id"
                class="cursor-move px-3 py-2 border my-2 bg-gray-100 grid grid-cols-3 gap-4"
                draggable="true"
                @dragstart="dragStart($event, checklistItem)"
                @dragenter="dragEnter($event, checklistItem)"
                @dragover="dragOver($event)"
                @dragleave="dragLeave($event)"
                @drop="drop($event)"
                @dragend="dragEnd($event)">

                <div :class="{'line-through' : checklistItem.completed_at}" class="col-span-3 sm:col-span-2 flex items-center">
                    <input type="checkbox" :checked="checklistItem.completed_at" class="form-checkbox mr-4" @change.prevent="toggleMarkedAsComplete(checklistItem)">

                    <inline-text-input
                        v-if="!checklistItem.completed_at"
                        v-model="checklistItem.text"
                        @change="updateChecklistItem"
                        class="inline-block w-full"
                        :data-id="checklistItem.id"
                    />

                    <span v-else class="text-sm sm:text-base">{{ checklistItem.text }}</span>
                </div>

                <div class="col-span-3 sm:col-span-1">
                    <div v-if="checklistItem.completed_at && checklistItem.completedBy" class="text-right">
                        <div class="text-sm">
                            Marked complete by <span class="font-semibold">{{ checklistItem.completedBy.name }}</span>
                        </div>

                        <span class="">{{ $filters.format_date_time(checklistItem.completed_at) }}</span>
                    </div>

                    <div v-else class="text-right">
                        <a class="text-red-500 cursor-pointer" @click.prevent="removeChecklistItem(checklistItem)">
                            <icon name="trash" class="w-4 h-4 fill-current inline" />
                        </a>
                    </div>
                </div>
            </li>
        </ul>

        <div class="shadow-md px-4 py-3 border">
            <form @submit.prevent="updateIncident" id="edit-incident-form">
                <div class="flex flex-wrap items-center mb-6 sm:justify-between">
                    <div class="w-full sm:w-auto">
                        <inertia-link v-if="incident.location.clientCompany" :href="$route('client-companies.show', incident.location.clientCompany.id)" class="text-d-blue-500 font-semibold text-lg md:text-2xl">
                            {{ incident.location.clientCompany.name }}
                        </inertia-link>

                        <p v-else class="my-2 text-lg font-semibold text-center">
                            <span class="fal fa-warning text-warning"></span> There is no Client Company set for this location.
                        </p>
                    </div>

                    <div class="w-full my-2 sm:w-auto sm:my-0">
                        <loading-button :loading="state === 'saving'" class="ml-auto btn btn-orange">
                            Save Changes
                        </loading-button>
                    </div>
                </div>

                <div class="grid grid-cols-1 gap-x-4 gap-y-6 lg:grid-cols-2">
                    <div>
                        <fieldset class="mt-4 mb-8">
                            <legend class="font-semibold text-xl md:text-2xl text-gray-600 mb-4">Assignment / Due Date</legend>

                            <div class="grid gap-x-4 gap-y-6 grid-cols-2">
                                <select-input v-model="form.assigned_to_id" class="col-span-2 md:col-span-1" name="assigned_to_id" id="assigned-to-id" label="Assigned To">
                                    <option :value="null">Unassigned</option>
                                    <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }}</option>
                                </select-input>

                                <date-input v-model="form.due_on" class="col-span-2 md:col-span-1" label="Due On" name="due_on" id="due-on" :errors="errors.due_on" />

                                <div class="col-span-2">
                                    <a href="" class="flex items-baseline text-xl" @click.prevent="toggleAssignmentHistoryDisplay">
                                        <span class="mr-2 text-gray-700">Assignment History</span>
                                        <icon class="h-4 w-4 fill-current" :name="showingAssignmentHistories ? 'chevron-down' : 'chevron-right'" />
                                    </a>

                                    <transition
                                        enter-active-class="transition ease-out duration-200"
                                        enter-from-class="opacity-0"
                                        enter-to-class="opacity-100"
                                        leave-active-class="transition ease-in duration-200"
                                        leave-from-class="opacity-100"
                                        leave-to-class="opacity-0">

                                        <ul v-show="showingAssignmentHistories" class="my-2 p-2 border border-gray-400">
                                            <li v-for="(entry, index) in assignmentHistories" :key="index" class="px-4 py-2 m-1 bg-white">
                                                <p v-if="entry.data.assigned_to" class="">
                                                    Assigned to <span class="font-medium">{{ entry.data.assigned_to.first_name }} {{ entry.data.assigned_to.last_name }}</span> <br>
                                                    by <span class="font-medium">{{ entry.who.first_name }} {{ entry.who.last_name }}</span>
                                                    on <span class="font-medium">{{ $filters.format_date_time(entry.when) }}</span>
                                                </p>

                                                <p v-else>
                                                    Unassigned by <span class="font-medium">{{ entry.who.first_name }} {{ entry.who.last_name }}</span>
                                                    on <span class="font-medium">{{ $filters.format_date_time(entry.when) }}</span>
                                                </p>
                                            </li>
                                        </ul>
                                    </transition>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset class="mb-8">
                            <legend class="font-semibold text-xl md:text-2xl text-gray-600 mb-4">Location Information</legend>

                            <a :href="$route('locations.show', [incident.location_id])" class="text-lg text-orange-400 font-medium" target="_blank">
                                {{ incident.location.name }}
                            </a>

                            <div class="grid gap-x-4 gap-y-6 grid-cols-2">
                                <div class="col-span-2 flex items-center text-gray-700">
                                    <copy-to-clipboard-button class="mr-2 text-italic text-gray-500" :value="incident.location.address.full_address">
                                        {{ incident.location.address.full_address }}
                                    </copy-to-clipboard-button>
                                </div>

                                <select-input name="location_id" v-model="form.location_id" label="Select Location" class="col-span-2">
                                    <option v-for="location in assignableLocations" :key="location.id" :value="location.id">{{ location.name }}</option>
                                </select-input>
                            </div>
                        </fieldset>

                        <fieldset class="mb-8">
                            <legend class="font-semibold text-xl md:text-2xl text-gray-600 mb-4">Service Information</legend>

                            <div v-if="incident.service">
                                <div class="mb-2">

	                                <div class="flex">
		                                <pioneer-indicator v-if="incident.service.compactorMonitor" :compactor-monitor="incident.service.compactorMonitor"/>
	                                    <a :href="$route('services.show', incident.service.id)" class="text-lg md:text-xl text-d-blue-500 block" target="_blank">
	                                        {{ incident.service.id }}
	                                    </a>
	                                </div>

                                    <span class="text-gray-700 italic text-sm md:text-base flex">
                                        {{ incident.service.description }}
                                    </span>
                                </div>

	                            <div v-if="incident.service.label" class="my-4">
		                            <dt class="text-sm font-medium text-gray-500">Equipment Label</dt>
		                            <dd class="mt-1 text-sm text-wrap text-gray-900">
			                            {{ incident.service.label }}
		                            </dd>
	                            </div>

                                <div class="mb-2">
                                    <span v-if="incident.service.is_pass_through" class="text-orange-500">
                                        Pass Through
                                    </span>

                                    <span v-else class="text-orange">
                                        Not Pass Through
                                    </span>
                                </div>

                                <div v-if="incident?.service?.vendorAccount" class="mb-4">
                                    <span class="text-gray-700 font-semibold">Account Number </span>

                                    <inertia-link :href="$route('vendor-invoices.index', { search: incident.service.vendorAccount.account_number })" class="link">
                                        {{ incident.service.vendorAccount.account_number }}
                                    </inertia-link>
                                </div>
                            </div>

                            <select-input-2 v-model="form.service_id" :options="assignableServices" name="service_id" id="service-id" class="mb-4">
	                            <template #option-display="{option, selected}">
	                                <span class="font-medium flex"><pioneer-indicator v-if="option.compactorMonitor" :compactor-monitor="option.compactorMonitor"/>{{ option.id }} </span>
                                    <small>{{ option.description }} ({{ option.status }})</small>
	                            </template>
                            </select-input-2>

                            <div v-if="incident?.service?.sticky_notes" class="rounded shadow-md p-6 bg-yellow-50">
                                <h3 class="font-semibold text-lg">Service Sticky Notes</h3>
                                <hr class="separator" />
                                <p class="leading-tight italic" v-html="incident.service.sticky_notes" />
                            </div>
                        </fieldset>
                    </div>

                    <div>
                        <fieldset class="mt-4 mb-8">
                            <legend class="font-semibold text-xl md:text-2xl text-gray-600 mb-4">Basic Info.</legend>

                            <div class="grid grid-cols-2 gap-x-4 gap-y-6">
                                <select-input v-model="form.status" name="status" id="status" class="col-span-2 md:col-span-1" label="Status">
                                    <option disabled value="0">Select a Status</option>
                                    <option v-for="status in statuses" :key="status" :value="status" placeholder="status" :errors="errors.status" mark-as-required>{{ status }}</option>
                                </select-input>

                                <select-input v-model="form.client_contact_id" name="type_id" id="type_id" class="col-span-2 md:col-span-1"  label="Client Contact" :errors="errors.client_contact_id" placeholder="John Doe">
                                    <option disabled value="0">Select a Client Contact</option>
                                    <option v-for="contact in contacts" :key="contact.id" :value="contact.id">{{ contact.name }}</option>
                                </select-input>

                                <select-input v-model="form.type_id" name="type_id" id="type_id" label="Type" class="col-span-2 md:col-span-1">
                                    <option disabled value="0">Select a Type</option>
                                    <option v-for="type in types" :key="type.id" :value="type.id">{{ type.name }}</option>
                                </select-input>

                                <select-input v-if="form.type_id" v-model="form.reason_id" name="reason" id="reason" class="col-span-2 md:col-span-1" label="Reason" :errors="errors.reason_id" placeholder="Select a reason" mark-as-required>
                                    <option disabled value="0">Select a Reason</option>
                                    <option v-for="reason in typeReasons" :key="reason.id" :value="reason.id">{{ reason.name }}</option>
                                </select-input>

                                <select-input v-else v-model="form.reason_id" name="reason_id" id="reason_id" class="col-span-2 md:col-span-1" label="Reason" :errors="errors.reason" placeholder="reason">
                                    <option disabled value="0">Select a Reason</option>
                                </select-input>

                                <date-time-local-input v-model="form.reported_at" name="reported_at" id="reported-at" class="col-span-2 md:col-span-1" label="Reported At" placeholder="2022-01-01" :errors="errors.reported_at" mark-as-required />
                                <date-time-local-input v-model="form.occurred_at" name="occurred_at" id="occurred-at" class="col-span-2 md:col-span-1" label="Occurred At" placeholer="2022-01-01" :errors="errors.occurred_at" />

                                <text-input v-model="form.hauler_case_number" name="hauler_case_number" id="hauler-case-number" class="col-span-2 md:col-span-1" label="Hauler Case Number" placeholder="12344-abcde" :errors="errors.hauler_case_number" />
                                <date-input v-model="form.expected_completion_date" name="expected_completion_date" id="expected-completion-date" class="col-span-2 md:col-span-1" label="Expected Completion Date" placeholder="2022-01-01" />

                                <select-input v-model="form.source" name="source" id="source" class="col-span-2 md:col-span-1" label="Source" placeholder="Source" :errors="errors.source" mark-as-required>
                                    <option disabled value="0">Select a Source</option>
                                    <option v-for="source in sources" :key="source" :value="source">{{ source }}</option>
                                </select-input>

                                <select-input v-model="form.priority" name="priority" id="priority" class="col-span-2 md:col-span-1" label="Priority" placeholder="Priority" :errors="errors.priority" mark-as-required>
                                    <option disabled value="0">Select a Priority</option>
                                    <option v-for="priority in priorities" :key="priority" :value="priority">{{ priority }}</option>
                                </select-input>

                                <money-input v-model="form.money_saved" class="col-span-2 md:col-span-1" :default-currency="incident.location.clientCompany.currency" name="money_saved" id="money-saved" label="Money Saved" :errors="errors.money_saved" mark-as-required />

                                <div class="col-span-2 md:col-span-1">
                                    <div>
                                        <label for="time-saved" class="form-label">Time Saved (minutes)</label>

                                        <div class="input-group">
                                            <span class="input-group-item-left">
                                                <icon name="clock" class="fill-current text-gray-500 size-4" />
                                            </span>

                                            <input type="number" name="time_saved" id="time-saved" v-model="form.minutes_saved" min="0" step="1" class="input-group-field">
                                        </div>
                                    </div>
                                </div>

                                <text-input
                                    v-model="form.external_reference_id"
                                    label="External Reference Id"
                                    class="col-span-2 md:col-span-1"
                                    :errors="errors.external_reference_id"
                                    help-text="Allows for external references IDs"
                                />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </form>

            <form @submit.prevent="addExternalUrl" class="mt-6">
                <fieldset class="mb-8 overflow-x-auto">
                    <legend class="font-semibold text-xl md:text-2xl text-gray-600 mb-4">
                        External URLs
                    </legend>

                    <div v-for="(url, index) in incident.external_urls" :key="index" class="flex justify-between items-center my-4">
                        <a :href="url" class="break-all max-w-full text-blue-500 underline" target="_blank">
                            {{ url }}
                        </a>

                        <a href="" title="Remove this URL" @click.prevent="removeExternalUrl(index)">
                            <icon name="trash" class="w-4 h-4 inline fill-current text-red-500 mx-4" />
                        </a>
                    </div>

                    <div class="">
                        <text-input v-model="newExternalUrl" placeholder="Add URL" :errors="errors.external_url" />
                    </div>
                </fieldset>
            </form>

            <!-- START: This needs to be moved to the new show/details page. !-->
            <div class="mt-6">
                <div class="font-semibold text-xl md:text-2xl text-gray-600 mb-4">Contact Info.</div>

                <div>
                    <div class="my-4">
                        <span class="block font-semibold my-1 text-lg text-d-blue-500">Vendor Contacts</span>

                        <ul class=" my-1 p-1 border border-gray-400">
                            <li v-for="vendor in vendors" :key="vendor.id" class="px-2 py-3">
                                <span class="my-1 font-medium flex items-center text-sm md:text-base">
                                    <icon class="fill-current w-4 h-4 mr-2" name="building" />
                                    {{ vendor.name ?? '' }}
                                </span>

                                <span class="block my-1">
                                    <a :href="`tel:${vendor.general_phone_number}`" class="link">{{ $filters.format_phone(vendor.general_phone_number) }}</a>
                                </span>

                                <copy-to-clipboard-button class="block my-1" :value="vendor.general_email_address">{{ vendor.general_email_address }}</copy-to-clipboard-button>
                            </li>
                        </ul>
                    </div>

                    <div v-if="selectedClientContact" class="my-4">
                        <span class="block font-semibold my-1 text-lg text-d-blue-500">Client Contact</span>

                        <ul class=" my-1 p-1 border border-gray-400">
                            <li class="px-2 py-3">
                                <span class="block my-1 font-semibold">
                                    <span class="fal fa-user"></span>
                                    {{ selectedClientContact.name }}
                                </span>

                                <span class="block my-1">{{ $filters.format_phone(selectedClientContact.primary_phone_number) }}</span>

                                <span class="block my-1">{{ selectedClientContact.email }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- END: This needs to be moved to the new show/details page. !-->

            <div class="pt-8 space-y-6 sm:pt-10">
                <div class="font-semibold text-xl md:text-2xl text-gray-600 mb-4">Documents</div>

                <div v-if="incident.documents.length > 0">
                    <text-input type="file" ref="filePendingDocuments" class="md:w-1/3" @change="addPendingFileUploads" multiple />
                </div>

                <div v-if="pendingFileUploads.length > 0">
                    <span class="block form-label">Pending Uploads</span>

                    <!-- This example requires Tailwind CSS v2.0+ -->
                    <div v-if="errors?.pendingFileUpload?.file" class="rounded-md bg-red-50 p-4 my-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <!-- Heroicon name: solid/x-circle -->
                                <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                                </svg>
                            </div>

                            <div class="ml-3">
                                <h3 class="text-sm font-medium text-red-800">There were errors with your uploads</h3>
                                <div class="mt-2 text-sm text-red-700">
                                    <ul role="list" class="list-disc pl-5 space-y-1">
                                        <li>{{ errors?.pendingFileUpload?.file }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- This example requires Tailwind CSS v2.0+ -->
                    <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                        <li v-for="(upload, index) in pendingFileUploads" :key="index" class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                            <div class="w-full flex items-center justify-between p-6 space-x-6">
                                <div class="flex-1 truncate">
                                    <div class="flex items-center space-x-3">
                                        <h3 class="text-gray-900 text-sm font-medium truncate">{{ upload.file.name }}</h3>
                                        <span class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">{{ $filters.format_file_size(upload.file.size) }}</span>
                                    </div>
                                    <p class="mt-1 text-gray-500 text-sm truncate">{{ upload.file.type }}</p>
                                </div>
                            </div>

                            <div class="w-full p-3">
                                <textarea-input label="Notes" v-model="upload.notes" :errors="errors?.pendingFileUpload?.notes" :autosize="true" />
                            </div>

                            <div>
                                <div class="-mt-px flex divide-x divide-gray-200">
                                    <div class="w-0 flex-1 flex">
                                        <a href="" @click.prevent="storePendingFileUpload(upload)" :loading="upload.uploading" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                                            <icon v-if="upload.uploading" name="spinner" class="inline fill-current text-gray-400 w-5 h-5 animate-spin" />
                                            <icon v-else class="inline fill-current w-4 h-4" name="upload" />

                                            <span class="ml-3">Upload</span>
                                        </a>
                                    </div>

                                    <div class="-ml-px w-0 flex-1 flex">
                                        <a href="" @click.prevent="removePendingFileUpload(file)" class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                                            <icon name="trash" class="w-4 h-4 fill-current" />
                                            <span class="ml-3">Remove</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div v-if="incident.documents.length > 0">
                    <span class="block form-label">Existing Documents</span>

                    <div class="overflow-x-auto">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Filename</th>
                                    <th>Size</th>
                                    <th>Type</th>
                                    <th>Notes</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="document in incident.documents" :key="document.id">
                                    <td>
                                        <div class="flex">
                                            <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                                            </svg>
                                            <a :href="$route('documents.preview', { path: document.file_path })" class="ml-2 font-medium text-d-blue-600 hover:text-d-blue-500" target="_blank">
                                                {{ document.file_name }}
                                            </a>
                                        </div>
                                    </td>
                                    <td>{{ $filters.format_file_size(document.file_size) }}</td>
                                    <td>{{ document.file_mime_type }}</td>
                                    <td>{{ document.notes }}</td>
                                    <td>
                                        <a :href="$route('documents.preview', { path: document.file_path })" class="font-medium link mr-3" target="_blank">
                                            Download
                                        </a>

                                        <a href="" @click.prevent="removeDocument(document)" class="font-medium">
                                            Remove
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div v-else class="mt-6 text-center">
                    <label class="flex flex-col items-center justify-center px-4 py-2 cursor-pointer">
                        <icon name="file-alt" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                        <h3 class="mt-2 text-sm font-medium text-gray-900">No documents available</h3>

                        <p class="mt-1 text-sm text-gray-500">Upload a document for this contract.</p>

                        <input type="file" ref="filePendingDocuments" class="hidden" @change="addPendingFileUploads" multiple />
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import InlineTextInput from '@/Shared/InlineTextInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import DateTimeLocalInput from '@/Shared/DateTimeLocalInput.vue';
    import { orderBy } from 'lodash-es';
    import { parse, compareDesc } from 'date-fns';
    import axios from 'axios';
    import CopyToClipboardButton from '@/Shared/CopyToClipboardButton.vue';
    import HorizontalSubNav from './HorizontalSubNav.vue';
    import { Head } from '@inertiajs/vue3';
    import Logo from "../../Shared/Logo.vue";
    import SelectInput2 from "../../Shared/SelectInput2.vue";
    import PioneerIndicator from "../../Shared/PioneerIndicator.vue";

    export default {
        components: {
	        PioneerIndicator,
	        SelectInput2,
	        Logo,
            CopyToClipboardButton,
            HorizontalSubNav,
            LoadingButton,
            InlineTextInput,
            DateTimeLocalInput,
            DateInput,
            SelectInput,
            TextInput,
            TextareaInput,
            MoneyInput,
            Icon,
            Head,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({}),
            },

            incident: {
                type: Object,
                required: true
            },

            types : {
                type: Array,
                required: true
            },

            statuses : {
                type: Array,
                required: true
            },

            sources : {
                type: Array,
                required: true
            },

            priorities : {
                type: Array,
                required: true
            },

            users: {
                type: Array,
                required: true
            }
        },

        data() {
           return {
               incidentDocuments: this.incident.documents,
               incidentDocumentErrors: {name: [], file: []},
               newExternalUrl: '',
               newChecklistItem: '',
               pendingFileUploads: [],
               dragging: null,
               state: 'passive',
               showingAssignmentHistories: false,
               form: {... this.incident},
               otherUsers: [],
               mounted: false,
           }
        },

        mounted() {
            this.mounted = true;
        },

        created() {
            Echo.join(`incidents.${this.incident.id}`)
                .here((users) => {
                    this.otherUsers = users;
                })
                .joining((user) => {
                    this.otherUsers.push(user);
                })
                .leaving((user) => {
                    this.otherUsers = this.otherUsers.filter(otherUser => otherUser.id !== user.id);
                });
        },

        unmounted() {
            Echo.leave(`incidents.${this.incident.id}`);
        },

        methods: {
            // incidents
            updateIncident(e) {
                this.state = 'saving';

                this.$inertia.put(this.$route('incidents.update', this.incident.id), this.form, {
                    preserveScroll: true,
                    onFinish: () => this.state = 'passive'
                });
            },

            toggleAssignmentHistoryDisplay() {
                this.showingAssignmentHistories = !this.showingAssignmentHistories;
            },

            // checklist items
            dragStart(e, checkListItem) {
                this.dragging = checkListItem;
                e.dataTransfer.effectAllowed = 'move';
            },

            dragEnter(e, checklistItem) {
                let tempNumber = this.dragging.sort_order;
                this.dragging.sort_order = checklistItem.sort_order;
                checklistItem.sort_order = tempNumber;
            },

            dragOver(e) {
                e.preventDefault();
                e.dataTransfer.dropEffect = 'move';
            },

            dragLeave (e) {

            },

            drop(e) {
                e.preventDefault();     // prevent default action (open as link for some elements)

                this.dragging = null;

                axios.put(this.$route('incidents.checklist-items.re-order', this.incident.id), {checklistItems: this.sortedChecklistItems})
                    .then(response => {

                    })
                    .catch(error => {

                    });
            },

            dragEnd() {

            },

            updateChecklistItem(event) {
                let checkListItem = this.incident.checklistItems.find(checkListItem => checkListItem.id == event.target.dataset.id);

                axios.put(this.$route('incidents.checklist-items.update', [this.incident.id, checkListItem.id]), {text: event.target.value})
                    .then(response => {

                    })
                    .catch(error => {
                        if (error.response.data.error_message) {
                            this.$toast.error(error.response.data.error_message);
                        } else {
                            this.$toast.error('Unable to complete your request. Please check the server logs for more information.');
                        }
                    });
            },

            toggleMarkedAsComplete(checklistItem) {
                axios.put(this.$route('incidents.checklist-items.toggle-marked-as-complete', [this.incident.id, checklistItem.id]), {})
                    .then(response => {
                        let index = this.incident.checklistItems.indexOf(checklistItem);
                        this.incident.checklistItems[index] = response.data.checklistItem;
                    })
                    .catch(error => {
                        if (error.response.data.error_message) {
                            this.$toast.error(error.response.data.error_message);
                        } else {
                            this.$toast.error('Unable to complete your request. Please check the server logs for more information.');
                        }
                    });
            },

            storeChecklistItem() {
                if (this.newChecklistItem) {
                    axios.post(this.$route('incidents.checklist-items.store', this.incident.id), {sort_order: this.incident.checklistItems.length + 1, text: this.newChecklistItem})
                        .then(response => {
                            this.incident.checklistItems.push(response.data.checklistItem);
                            this.newChecklistItem = '';
                        })
                        .catch(error => {
                            if (error.response.data.error_message) {
                                this.$toast.error(error.response.data.error_message);
                            } else {
                                this.$toast.error('Unable to complete your request. Please check the server logs for more information.');
                            }
                        });
                }
            },

            removeChecklistItem(checklistItem) {
                let confirmed = confirm('Are you sure you wish to perform this action?');

                if (confirmed) {
                    axios.delete(this.$route('incidents.checklist-items.destroy', [this.incident.id, checklistItem.id]))
                        .then(response => {
                            this.incident.checklistItems = response.data.checklistItems
                            this.newExternalUrl = '';
                        })
                        .catch(error => {
                            if (error.response.data.error_message) {
                                this.$toast.error(error.response.data.error_message);
                            } else {
                                this.$toast.error('Unable to complete your request. Please check the server logs for more information.');
                            }
                        });
                }
            },

            addPendingFileUploads(e) {
                [...e.target.files].forEach(file => {
                    let pendingFileUpload = {
                        file: file,
                        name: file.name,
                        notes: '',
                        uploading: false
                    };

                    this.pendingFileUploads.push(pendingFileUpload);
                    this.$refs.filePendingDocuments.value = '';
                });
            },

            removePendingFileUpload(pendingFileUpload) {
                this.pendingFileUploads.splice(this.pendingFileUploads.indexOf(pendingFileUpload), 1);
            },

            storePendingFileUpload(pendingDocument) {
                if (pendingDocument.uploading !== true) {
                    let formData = new FormData();
                    formData.append('file', pendingDocument.file);
                    formData.append('notes', pendingDocument.notes);
                    pendingDocument.uploading = true;

                    this.$inertia
                        .post(this.$route('incidents.incident-documents.store', this.incident.id), formData, {
                            errorBag: 'pendingFileUpload',
                            preserveScroll: true,
                            onSuccess: () => {
                                this.pendingFileUploads.splice(this.pendingFileUploads.indexOf(pendingDocument), 1);

                            },
                            onFinish: () => pendingDocument.uploading = false,
                        });
                }
            },

            removeDocument(incidentDocument) {
                let confirmed = confirm("Are you sure you wish to perform this action?");

                if (confirmed) {
                    this.$inertia
                        .delete(this.$route('incident-documents.destroy', incidentDocument.id), {
                            preserveScroll: true,
                            onSuccess: () => {

                            },
                            onFinish: () => {

                            },
                        });
                }
            },

            addExternalUrl(e) {
                if (this.newExternalUrl) {
                    let externalUrls = this.incident.external_urls.slice();

                    if (externalUrls.indexOf(this.newExternalUrl) >= 0) {
                        this.$toast.error('The external URL you are trying to add already exists.');
                        return;
                    }

                    externalUrls.push(this.newExternalUrl);

                    axios.patch(this.$route('incidents.patch', this.incident.id), {external_urls: externalUrls})
                        .then(response => {
                            this.incident.external_urls = externalUrls;
                            this.newExternalUrl = '';
                            this.$toast.success(response.data.success_message);
                        })
                        .catch(error => {

                        });
                }
            },

            removeExternalUrl(index) {
                let confirmed = confirm("Are you sure you wish to perform this action?");

                if (confirmed) {
                    let externalUrls = [...this.incident.external_urls];
                    if (index >= 0 && index < externalUrls.length) {
                        externalUrls.splice(index, 1);
                    }

                    axios.patch(this.$route('incidents.patch', this.incident.id), {external_urls: externalUrls})
                        .then(response => {
                            this.incident.external_urls = externalUrls;
                            this.$toast.success(response.data.success_message);
                        })
                        .catch(error => {

                        });
                }
            }
        },

        computed: {
            selectedClientContact() {
                let selected = this.contacts.filter(contact => contact.id === this.incident.client_contact_id);

                return selected.length === 0 ? null : selected[0];
            },

            vendors() {
                if (this.incident?.service?.vendor) {
                    return [this.incident.service.vendor];
                } else {
                    const uniqueVendor = (vendor, index, self) => self.findIndex(t => t.id === vendor.id) === index;

                    return this.incident
                        .location
                        .services
                        .filter(service => service.status === 'Active' || service.status === 'Executed')
                        .map(service =>  service.vendor)
                        .filter(Boolean)
                        .filter(uniqueVendor);
                }
            },

            contacts() {
                return this.incident
                    .location
                    .contacts.sort((a, b) => a.name.localeCompare(b.name));
            },

            assignableServices() {
                return this.incident
                    .location
                    .services
                    .filter(service => service.type !== 'Device' && service.status === 'Active')
                    .sort((a, b) => a.id.localeCompare(b.id));
            },

            assignableLocations() {
                return this.incident
                    .location
                    .clientCompany
                    .locations.sort((a, b) => a.name.localeCompare(b.name));
            },

            sortedChecklistItems() {
                return orderBy(this.incident.checklistItems, 'sort_order');
            },

            assignmentHistories() {
                return this.incident
                    .history
                    .filter(history => history.what == 'Assignment')
                    .sort((a, b) => compareDesc(parse(a.when, 'yyyy-MM-dd HH:mm:ss', new Date), parse(b.when, 'yyyy-MM-dd HH:mm:ss', new Date)));
            },

            decimalMoneySaved: {
                get() {
                    let value = parseInt(this.incident.money_saved.amount, 10) / 100;
                    return value.toLocaleString('en-US', {style : 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2});
                },

                set(newValue) {
                    this.incident.money_saved.amount = newValue * 100;
                }
            },

            typeReasons() {
                if (this.form.type_id) {
                    return this.types.find(type => type.id === this.form.type_id).reasons;
                } else {
                    return [];
                }
            },
        }
    }
</script>
