<template>
    <div class="relative" ref="dropdownMenu">
        <div
            role="button"
            tabindex="0"
            class="inline-block select-none outline-none p-2"
            :class="[ open ? openClass : null]"
            @click="open = !open"
            @blur="hideDropdown"
            @keydown.esc="open = false"
        >
            <slot name="link"></slot>
        </div>

        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <div class="absolute pin-l w-full z-10" v-show="open" @click="open = false">
                <slot name="dropdown"></slot>
            </div>
        </transition>
    </div>
</template>

<script setup>
    import { ref } from 'vue'

    const props = defineProps({
        openClass: {
            type: String,
            default: '',
            required: false
        },
    })

    const open = ref(false)
    const dropdownMenu = ref(null)

    function hideDropdown(e) {
        let target = e.relatedTarget ;

        if (dropdownMenu.value !== target && !dropdownMenu.value.contains(target)) {
            open.value = false
        }
    }
</script>