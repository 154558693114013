<template>
    <Head title="Create Vendor Account" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendor-accounts.index')" class="breadcrumb-link">Vendor Accounts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <nav class="flex items-center justify-center" aria-label="Progress">
        <p class="text-sm font-medium">Step {{ currentStep }} of {{ steps.length }}</p>

        <ol role="list" class="ml-8 flex items-center space-x-5">
            <li v-for="step in steps" :key="step">
                <!-- Completed Step -->
                <a v-if="currentStep > step" @click.prevent="transitionToStep(step)" class="block w-2.5 h-2.5 bg-blue-600 rounded-full hover:bg-blue-900">
                    <span class="sr-only">{{ step }}</span>
                </a>

                <!-- Current Step -->
                <a v-else-if="currentStep === step" href="" class="relative flex items-center justify-center" aria-current="step">
                    <span class="absolute w-5 h-5 p-px flex" aria-hidden="true">
                        <span class="w-full h-full rounded-full bg-blue-200"></span>
                    </span>
                    <span class="relative block w-2.5 h-2.5 bg-blue-600 rounded-full" aria-hidden="true"></span>
                    <span class="sr-only">{{ step }}</span>
                </a>

                <!-- Upcoming Step -->
                <a v-else href="#" @click.prevent="transitionToStep(step)" class="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400">
                    <span class="sr-only">{{ step }}</span>
                </a>
            </li>
        </ol>
    </nav>

    <form id="edit-vendor-account-form" @submit.prevent="submitForm">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Info</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Account number, Vendor and contact settings</p>
                </div>
            </legend>

            <div class="grid grid-cols-4 gap-6">
                <div v-if="currentStep === 1" class="col-span-4 md:col-span-2">
                    <Combobox as="div" v-model="selectedVendor">
                        <ComboboxLabel class="form-label" for="due_times">
                            Vendor
                        </ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput id="due_times" name="due_times" class="form-input" :class="{ error: errors.vendor_id }" @change="searchVendors" :display-value="vendorComboDisplayValue" autocomplete="off" placeholder="Search for a Vendor..." />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                            </ComboboxButton>

                            <div v-if="state === 'searching-vendors'" class="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white px-2 py-3 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <span class="mr-2">Searching...</span>
                                <icon name="search" class="w-3 h-3 fill-current inline search-icon search-animation" />
                            </div>

                            <ComboboxOptions v-if="vendors.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="vendor in vendors" :key="vendor.id" :value="vendor" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ vendor.name ?? '' }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>

                </div>

                <template v-if="currentStep === 2">
                    <dl class="col-span-4 md:col-span-2">
                        <div class="">
                            <dt class="text-sm font-medium text-gray-500">Vendor</dt>
                            <dd class="mt-1 text-sm text-gray-900">{{ selectedVendor.name }}</dd>
                        </div>
                        <div v-if="errors.vendor_id" class="form-error">{{ errors.vendor_id }}</div>
                    </dl>
                    <text-input
                        v-model="form.account_number"
                        label="Account Number"
                        placeholder="Account Number"
                        mark-as-required
                        class="col-span-4 md:col-span-2 md:col-start-1"
                        :errors="errors.account_number"
                        helpText="A placeholder can be used here if the account number is not known.">
                      </text-input>
                  <div class="col-span-4 md:col-span-2">
                    <Combobox as="div" v-model="selectedVendorContact" class="col-span-4 md:col-span-2 md:col-start-1">
                      <ComboboxLabel class="form-label" for="due_times">
                        Vendor Contact
                        <small>(can't find contact? <a href="" class="link" title="Add a New Contact" @click.prevent="showAddContactModal">Add New Contact</a>)</small>
                      </ComboboxLabel>

                      <p class="mt-2 text-sm text-gray-600">
                        This is the contact at the vendor for any billing questions on this vendor account.
                      </p>

                      <div class="relative">
                        <ComboboxInput id="vendor_contact_id" name="vendor_contact_id" class="form-input" @change="vendorContactComboBoxQuery = $event.target.value" :display-value="vendorContactComboDisplayValue" placeholder="Search for a Vendor Contact..." />

                        <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                        </ComboboxButton>

                        <div v-if="state === 'searching-vendor-contacts'" class="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white px-2 py-3 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          <span class="mr-2">Searching...</span>
                          <icon name="search" class="w-3 h-3 fill-current inline search-icon search-animation" />
                        </div>

                        <ComboboxOptions v-if="vendorContacts.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          <ComboboxOption v-for="vendorContact in vendorContacts" :key="vendorContact" :value="vendorContact" as="template" v-slot="{ active, selected }">
                            <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                        {{ vendorContact.name }}
                                        </span>

                              <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <!-- Heroicon name: solid/check -->
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                            </li>
                          </ComboboxOption>
                        </ComboboxOptions>
                      </div>
                    </Combobox>
                  </div>
                </template>
            </div>
        </fieldset>

        <template v-if="currentStep === 2">
            <fieldset class="space-y-8 sm:space-y-5">
                <legend class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Billing/Invoice Info</h3>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Billing info, frequency, and invoice settings</p>
                    </div>
                </legend>

                <div class="grid grid-cols-4 gap-6">
                    <select-input
                        v-model="form.billing_frequency"
                        class="col-span-4 md:col-span-2"
                        label="Billing Frequency"
                        placeholder="Billing Frequency"
                        :errors="errors.billing_frequency"
                        help-text="This is how often we should expect this account to be billed by the Vendor"
                    >
                        <option v-for="billingFrequency in billingFrequencies" :key="billingFrequency" :value="billingFrequency">
                            {{ billingFrequency }}
                        </option>
                    </select-input>

                    <month-input
                        v-if="form.billing_frequency != 'Not Billable'"
                        v-model="form.first_fiscal_period"
                        class="col-span-4 md:col-span-2"
                        label="First Fiscal Period"
                        help-text="The first fiscal period we expect to start receiving invoices"
                        :errors="errors.first_fiscal_period"
                        :mark-as-required="true"
                    />

                    <select-input
                        v-if="form.billing_frequency != 'Not Billable'"
                        class="col-span-4 md:col-span-2"
                        label="Invoice Retrieval Method"
                        v-model="form.primary_invoice_retrieval_method"
                        help-text="This is how the invoice is retrieved."
                        :errors="errors.primary_invoice_retrieval_method"
                        :mark-as-required="true"
                    >
                        <option v-for="retrievalMethod in retrievalMethods" :key="retrievalMethod" :value="retrievalMethod">
                            {{ retrievalMethod }}
                        </option>
                    </select-input>

                    <text-input
                      v-if="form.primary_invoice_retrieval_method == 'Email Attachment'"
                      class="col-span-4 md:col-span-2"
                      label="Billing Email Address"
                      v-model="form.primary_billing_email_address"
                      help-text="The client email address that the invoice is sent to as an email attachment"
                      :errors="errors.primary_billing_email_address"
                    />
                    <text-input
                        v-else-if="form.primary_invoice_retrieval_method == 'Physical Mail'"
                        class="col-span-4 md:col-span-2"
                        label="Billing Mailing Address"
                        v-model="form.primary_billing_mailing_address"
                        help-text="Client billing mailing address that the invoice is sent to"
                        :errors="errors.primary_billing_mailing_address"
                    />

                    <text-input
                        class="col-span-4 md:col-span-2"
                        label="Auto Approval Invoice Total Tolerance"
                        type="number"
                        v-model="form.auto_approval_allowed_percent_difference"
                        help-text="The percent difference from the previous invoice to allow auto approval."
                        min="0"
                        max="100"
                    />

                    <text-input
                      v-model="form.external_reference_id"
                      label="External Reference Id"
                      help-text="This is the external reference id for this account"
                      class="col-span-4 md:col-span-2"
                      :errors="errors.external_reference_id"
                    />

                    <toggle-switch-input
                        v-model="form.tax_exempt"
                        label="Tax Exempt"
                        help-text="If tax exempt mode is enabled, this account will reject all sales tax line items on its vendor invoices."
                        class="col-span-4 md:col-span-2"
                        :errors="errors.tax_exempt" />
                </div>
            </fieldset>

            <div class="mt-8">
                <loading-button :loading="state === 'saving'" class="btn btn-orange">
                    Save Changes
                </loading-button>
            </div>
        </template>
    </form>
</template>

<script>
    import axios from 'axios';
    import { format } from 'date-fns';
    import { debounce } from 'lodash-es';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import AddressPicker from '@/Shared/AddressPicker.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import Modal from '@/Shared/Modal.vue';
    import MonthInput from '../../Shared/MonthInput.vue';
    import QuillEditor from '@/Shared/QuillEditor.vue';

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';
    import ToggleSwitchInput from "@/Shared/ToggleSwitchInput.vue";

    let emptyContact = {
        first_name: '',
        last_name: '',
        title: '',
        email: '',
        primary_phone_number: '',
        alternative_phone_number: '',
        mobile_phone_number: '',
        office_phone_number: '',
        type: 'General',
        is_decision_maker: null,
        notes: '',
    };

    export default {
        components: {
            ToggleSwitchInput,
            Head,
            Icon,
            AddressPicker,
            LoadingButton,
            Pagination,
            SelectInput,
            TextInput,
            TextareaInput,
            Modal,
            MonthInput,
            QuillEditor,
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
    },
        
        props: {
            errors: {
                type: Object,
                default: () => ({}),
            },

            vendorAccount: {
                type: Object,
                required: true
            },

            billingFrequencies: {
                type: Array,
                required: true,
            },

            filters: {
                type: Object,
                required: true
            },

            tenant: {
                type: Object,
                required: true
            },

            retrievalMethods: {
                type: Array,
                required: true
            },
        },

        data() {
            return {
                form: {
                  ...this.vendorAccount,
                },
                addContactForm: {... emptyContact},
                state: 'passive',
                steps: [1, 2],
                currentStep: 1,
                addContactFormErrors: {},
                contactComboBoxQuery: '',
                vendors: [],
                vendorContacts: [],
                selectedVendor: null,
                selectedVendorContact: null,
                vendorContactComboBoxQuery: '',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm(e) {
                this.state = 'saving';

                this.$inertia
                    .post(this.$route('vendor-accounts.store'), { ...this.form }, {
                        onFinish: () => {
                            this.state = 'passive';
                        }
                });
            },

            searchVendors: debounce(function(event) {
                if (event.target.value && this.state === 'passive') {
                    this.state = 'searching-vendors';

                    axios.get(this.$route('json.vendors.index', {search: event.target.value.toLowerCase()})).then(response => {
                        this.vendors = response.data?.data.map(vendor => ({'id': vendor.id, 'name': vendor.name})).sort((a, b) => a.name.localeCompare(b.name));
                    })
                    .finally(() => this.state = 'passive');
                }
            }, 800),

            searchVendorContacts: debounce(function(event) {
                if (event.target.value && this.state === 'passive') {
                    this.state = 'searching-vendor-contacts';

                    axios.get(this.$route('json.vendor-contacts.index', {vendor_id: this.form.vendor_id, search: event.target.value.toLowerCase()})).then(response => {
                        this.vendorContacts = response.data?.data.map(client => ({'id': client.id, 'name': `${client.first_name} ${client.last_name}`}));
                    })
                    .finally(() => this.state = 'passive');
                }
            }, 800),

            vendorComboDisplayValue(option) {
                return option ? `${option.name}` : '';
            },

            vendorContactComboDisplayValue(option) {
                return option ? `${option.name}` : '';
            },

            transitionToStep(step) {
                if (step === 1) {
                    this.currentStep = step;
                }

                if (step === 2 && this.form.vendor_id) {
                    this.currentStep = step;
                }
            }
        },

        computed: {
            filteredVendorContacts() {
                return this.vendorContactComboBoxQuery === ''
                    ? this.vendorContacts
                    : this.vendorContacts.filter((vendorContact) => {
                        return vendorContact.name.toLowerCase().includes(this.vendorContactComboBoxQuery.toLowerCase());
                    });
            },
        },

        watch: {
            'selectedVendor': {
                handler(newValue, oldValue) {
                    this.form.vendor_id = newValue?.id ?? null;
                    
                    this.form.vendor_contact_id = null;
                    this.selectedVendorContact = null;

                    if (newValue && this.state === 'passive') {
                        this.state = 'searching-vendor-contacts';

                        axios.get(this.$route('json.vendor-contacts.index', {vendor_id: this.form.vendor_id}))
                            .then(response => {
                                this.vendorContacts = response.data?.data.map(client => ({'id': client.id, 'name': `${client.first_name} ${client.last_name}`}));
                                this.transitionToStep(2);
                            })
                            .finally(() => this.state = 'passive');
                    }
                }
            },

            'selectedVendorContact': {
                handler(newValue, oldValue) {
                    this.form.vendor_contact_id = newValue.id ?? null;
                }
            },

            'form.billing_frequency': {
                handler(newValue, oldValue) {
                    if (newValue === 'Not Billable') {
                        this.form.primary_invoice_retrieval_method = 'Other'
                        this.form.first_fiscal_period = format(new Date, 'yyyy-MM');      
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .search-icon {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
    }
    .search-animation {
        animation-name: search-animation;
        animation-timing-function: ease;
    }
    @keyframes search-animation {
        0%   { transform: translateY(0); }
        30%  { transform: translateY(-10px); }
        50%  { transform: translateY(0) translateX(5px); }
        100% { transform: translateY(0) translateX(0); }
    }
</style>
