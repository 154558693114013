<template>
    <Head title="Billable Expenses - Revenue" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Revenue - Billable Expenses</span>
        </nav>
    </Teleport>

    <horizontal-sub-nav current-tab="dashboard" />

    <div class="my-4 grid grid-cols-4 gap-6">
        <sub-nav class="col-span-4 sm:block md:col-span-1" current-nav="billable-expenses" :fiscal-period="fiscalPeriod" :numberOf="numberOf" />

        <div class="col-span-4 md:col-span-3">
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="px-4 sm:px-6 lg:px-8">
                <div class="sm:flex sm:items-center">
                    <div class="sm:flex-auto">
                        <h1 class="text-xl font-semibold text-gray-900">Billable Expenses</h1>
                        <p class="mt-2 text-sm text-gray-700">
                            Charges from billable expenses that haven't been billed on a Client Invoice yet.
                        </p>
                    </div>
                </div>

                <form action="" id="fiscal-period-form" class="w-full mt-4 grid md:grid-cols-2 gap-y-6 gap-x-4">
                    <select-input name="current-fiscal-period" id="current-fiscal-period" v-model="current_fiscal_period_id" label="Fiscal Period">
                        <option v-for="fiscalPeriod in fiscalPeriods" :key="fiscalPeriod.id" :value="fiscalPeriod.id">
                            {{ fiscalPeriod.year }}-{{ fiscalPeriod.month }}
                        </option>
                    </select-input>

                    <text-input v-model="mass_markup_percentage" type="number" label="Bulk Update Markup Percentage" placeholder="% to markup"></text-input>
                </form>

                <div v-if="billableExpenses.data.length" class="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                    <div class="relative overflow-x-auto">
                        <div v-if="selected_billable_expenses.length > 0" class="absolute bg-white top-1.5 left-12 flex h-8 items-center space-x-3 sm:left-16">
                            <loading-button
                                type="button"
                                @click="bulkUpdateAllCheckedBillableExpenses"
                                :loading="state === 'saving'"
                                class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-d-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                            >
                                Apply Bulk Markup %
                            </loading-button>

                            <loading-button
                                type="button"
                                @click="saveCheckedWithClientRate"
                                :loading="state === 'saving'"
                                class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-d-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                            >
                                Apply Client Rate
                            </loading-button>
                        </div>

                        <table class="table table-condensed">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 relative">
                                        <input
                                            type="checkbox"
                                            :checked="indeterminate || selected_billable_expenses.length === billableExpenses.data.length"
                                            :indeterminate="indeterminate"
                                            @change="checkAllChanged"
                                            class="rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500"
                                        />
                                    </th>
                                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        Service
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Charge Type
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-2/12">
                                        Markup
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-4/12">
                                        Amount
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-2/12">
                                        Vendor Cost
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-2/12">
                                        Quantity
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-2/12">
                                        Client Rate
                                    </th>
                                    <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                        Vendor Invoice Source
                                    </th>
                                    <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                                        Service Date
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Description
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="(billableExpense, index) in billableExpenses.data" :key="billableExpense.id">
                                    <!-- Checkbox -->
                                    <td class="px-3 py-3 text-sm text-gray-600">
                                        <span>
                                            <input
                                                type="checkbox"
                                                :value="index"
                                                v-model="selected_billable_expenses"
                                                @change="$event.target.checked ? formCheckboxChecked(index) : cancelFormEdit(index)"
                                                class="rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500"
                                            />
                                        </span>
                                    </td>

                                    <!-- Service & Status-->
                                    <td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm sm:w-auto sm:max-w-none sm:pl-6 text-gray-500">
                                        <!-- Service -->
                                        <a class="font-medium text-gray-900" target="_blank" :href="$route('services.show', billableExpense.service_id)">
                                            {{ billableExpense.service_id }}
                                        </a>
                                        <!-- Status -->
                                        <p>{{ billableExpense.service.is_pass_through ? 'Pass Through' : billableExpense.decorated_status }}</p>
                                    </td>

                                    <!-- Charge Type -->
                                    <td :class="['px-3 py-3 text-sm', billableExpense.vendor_fee_label ? 'text-gray-500' : 'text-gray-900']">
                                        {{ billableExpense.vendor_fee_label ?? billableExpense.vendor_invoice_line_item_type }}
                                    </td>

                                    <!-- Markup -->
                                    <td v-if="selected_billable_expenses.includes(index)" class="px-3 py-3 text-sm text-gray-900">
                                        {{ $filters.format_percentage(mass_markup_percentage ? mass_markup_percentage : 0, 0) }}
                                    </td>
                                    <td v-else-if="form[index].amount_editing" class="px-3 py-3 text-sm text-gray-900">
                                        {{ $filters.format_percentage(form[index].markup_percentage, 0) }}
                                    </td>
                                    <td v-else-if="form[index].percentage_editing">
                                        <div class="p-3 grid grid-cols-1 gap-y-2">
                                            <text-input class="w-14 p-0 m-0" v-model="form[index].markup_percentage" type="number" @update:modelValue="(value) => updateNewAmountFromPercentage(index, value)"/>
                                            <div class="flex flex-row-reverse">
                                                <loading-button type="button" class="inline btn btn-blue px-2.5 py-1.5 text-sm" :class="{'pl-2': form[index].state === 'saving' || state === 'saving'}" :loading="form[index].state === 'saving' || state === 'saving'" @click="saveBillableExpenseCost(form[index])" title="Save the Billable Expense">
                                                    <span v-if="form[index].state === 'passive' || state === 'passive'">Save</span>
                                                </loading-button>
                                                <button type="button" class="inline-flex items-center rounded-md border border-gray-300 bg-white mr-2 px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="cancelFormEdit(index)">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td v-else-if="billableExpense.decorated_status === 'Needs Markup'" class="px-3 py-3 text-sm text-gray-500 cursor-pointer" @click="form[index].percentage_editing = true">
                                        - %
                                    </td>
                                    <td v-else class="px-3 py-3 text-sm text-gray-900 cursor-pointer" @click="form[index].percentage_editing = true">
                                        {{ $filters.format_percentage(form[index].markup_percentage, 0) }}
                                    </td>

                                    <!-- Amount -->
                                    <td v-if="form[index].percentage_editing || selected_billable_expenses.includes(index)" class="px-3 py-3 text-sm text-gray-900">
                                        {{ $filters.format_money(form[index].new_amount) }}
                                    </td>
                                    <td v-else-if="form[index].amount_editing">
                                        <div class="p-3 grid grid-cols-1 gap-y-2">
                                            <text-input class="p-0 m-0" :modelValue="billableExpense.customer_cost.amount / 100" type="number" :step=".01" @update:modelValue="(value) => updateFormValuesFromAmountInput(index, value)"></text-input>
                                            <div class="flex flex-row-reverse">
                                                <loading-button type="button" class="inline btn btn-blue px-2.5 py-1.5 text-sm" :class="{'pl-2': form[index].state === 'saving' || state === 'saving'}" :loading="form[index].state === 'saving' || state === 'saving'" @click="saveBillableExpenseCost(form[index])" title="Save the Billable Expense">
                                                    <span v-if="form[index].state === 'passive' || state === 'passive'">Save</span>
                                                </loading-button>
                                                <button type="button" class="inline-flex items-center rounded-md border border-gray-300 bg-white mr-2 px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="cancelFormEdit(index)">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td v-else-if="billableExpense.decorated_status === 'Needs Markup'" class="cursor-pointer text-gray-500" @click ="form[index].amount_editing = true">
                                        $ -
                                    </td>
                                    <td v-else class="cursor-pointer" @click="form[index].amount_editing = true">
                                        {{ $filters.format_money(billableExpense.customer_cost) }}
                                        <span class="line-through text-gray-500" v-if="billableExpense.customer_cost.amount != billableExpense.all_in_cost.amount">
                                            {{ $filters.format_money(billableExpense.all_in_cost) }}
                                        </span>
                                    </td>

                                    <!-- Vendor Cost -->
                                    <td class="px-3 py-3 text-sm text-gray-500">
                                        {{ $filters.format_money(billableExpense.all_in_cost) }}
                                    </td>

                                    <!-- Quantity -->
                                    <td class="px-3 py-3 text-sm text-gray-500">
                                        {{ billableExpense.quantity }}
                                    </td>

                                    <!-- Client Rate -->
                                    <td v-if="form[index].client_rate" class="px-3 py-3 text-sm text-right text-gray-900">
                                        {{ $filters.format_money(form[index].client_rate) }}
                                    </td>
                                    <td v-else class="px-3 py-3 text-sm text-center text-gray-500">
                                        -
                                    </td>

                                    <!-- Vendor Invoice Source -->
                                    <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                        <a target="_blank" :href="$route('vendor-invoices.edit', billableExpense.vendor_invoice_id)">
                                            #{{ billableExpense.vendor_invoice_number }}
                                        </a>
                                    </td>

                                    <!-- Service Date -->
                                    <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                        {{ $filters.format_date(billableExpense.service_date) }}
                                    </td>

                                    <!-- Description -->
                                    <td class="px-3 py-3 text-sm text-gray-500">
                                        {{ billableExpense.description }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <pagination :links="billableExpenses.links" />
                </div>

                <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                    <icon name="file-invoice" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                    <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Billable Expenses Found</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SubNav from '@/Pages/Revenue/SubNav.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import InlineTextInput from '@/Shared/InlineTextInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import HorizontalSubNav from '../Revenue/HorizontalSubNav.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            HorizontalSubNav,
            Icon,
            Pagination,
            SubNav,
            SelectInput,
            MoneyInput,
            TextInput,
            InlineTextInput,
            LoadingButton,
            Head,
        },

        props: {
            filters: {
                type: Object,
                required: true
            },

            fiscalPeriods: {
                type: Array,
                required: true
            },

            fiscalPeriod: {
                type: Object,
                required: true
            },

            numberOf: {
                type: Object,
                required: true
            },

            billableExpenses: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                form: this.billableExpenses.data.map((billableExpense) => {
                    return {
                        id: billableExpense.id,
                        new_amount: {
                            amount: billableExpense.customer_cost.amount,
                            currency: billableExpense.customer_cost.currency
                        },
                        client_rate: this.getClientRate(billableExpense),
                        markup_percentage: this.calculateCurrentMarkupPercentage(billableExpense),
                        state: 'passive',
                        percentage_editing: false,
                        amount_editing: false
                    }
                }),
                current_fiscal_period_id: this.fiscalPeriod.id,
                state: 'passive',
                mass_markup_percentage: null,
                selected_billable_expenses: [],
                mounted: false,
            };
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            saveBillableExpenseCost(billableExpenseForm) {
                billableExpenseForm.state = 'saving'

                return this.$inertia.put(
                    this.$route('revenue.markup-billable-expense', billableExpenseForm.id),
                    billableExpenseForm,
                    {
                        onFinish: () => {
                            billableExpenseForm.state = 'passive';
                            billableExpenseForm.percentage_editing = false;
                            billableExpenseForm.amount_editing = false;
                            billableExpenseForm.markup_percentage ||= 0;
                        }
                    }
                );
            },

            bulkUpdateAllCheckedBillableExpenses() {
                let selected = this.form.filter((expense, index) => {
                    if (this.selected_billable_expenses.includes(index)) {
                        this.updateMarkupPercentageFromNewAmount(index);
                        return true;
                    }
                    return false;
                });

                this.state = 'saving';

                return this.$inertia.put(
                    this.$route('revenue.markup-selected-billable-expenses'),
                    {'billableExpenses': selected},
                    {onFinish: () => {
                        this.state = 'passive';
                        this.selected_billable_expenses = [];
                    }}
                );
            },

            saveCheckedWithClientRate() {
                let selected = this.form.filter((expense, index) => {
                    if (this.selected_billable_expenses.includes(index) && expense.client_rate) {
                        this.form[index].new_amount.amount = this.form[index].client_rate.amount;
                        this.updateMarkupPercentageFromNewAmount(index);
                        this.selected_billable_expenses.splice(this.selected_billable_expenses.indexOf(index), 1);
                        return true;
                    }
                    return false;
                });

                this.state = 'saving';

                return this.$inertia.put(
                    this.$route('revenue.markup-selected-billable-expenses'),
                    {'billableExpenses': selected},
                    {onFinish: () => {
                        this.state = 'passive';
                    }}
                );
            },

            getClientRate(billableExpense) {
                let moneyZero = {amount: 0, currency: billableExpense.all_in_cost.currency};

                switch (billableExpense.vendor_invoice_line_item_type) {
                    case 'Base Charge':
                        return billableExpense.service.client_base_charge ?? billableExpense.service.vendor_base_charge ?? moneyZero;
                    case 'Per Occurrence Charge':
                        let clientOccurrenceRate = billableExpense.service.client_per_occurrence_charge ?? billableExpense.service.vendor_per_occurrence_charge ?? moneyZero;

                        return {
                            amount: Math.round(clientOccurrenceRate.amount * billableExpense.quantity),
                            currency: clientOccurrenceRate.currency
                        };
                    case 'Per Unit Charge':
                        let clientUnitRate = billableExpense.service.client_per_unit_charge ?? billableExpense.service.vendor_per_unit_charge ?? moneyZero;

                        return {
                            amount: Math.round(clientUnitRate.amount * billableExpense.quantity),
                            currency: clientUnitRate.currency
                        };
                    default:
                        return null;
                }
            },

            calculateCurrentMarkupPercentage(billableExpense) {
                return Math.round((billableExpense.customer_cost.amount - billableExpense.all_in_cost.amount) / billableExpense.all_in_cost.amount * 100);
            },

            cancelFormEdit(index) {
                this.form[index].percentage_editing = false;
                this.form[index].amount_editing = false;
                this.form[index].markup_percentage = this.calculateCurrentMarkupPercentage(this.billableExpenses.data[index]);
                this.form[index].new_amount.amount = this.billableExpenses.data[index].customer_cost.amount;
            },

            updateFormValuesFromAmountInput(index, value) {
                value ||= 0;
                this.form[index].new_amount.amount = Math.round(value * 100);
                this.updateMarkupPercentageFromNewAmount(index);
            },

            updateMarkupPercentageFromNewAmount(index) {
                this.form[index].markup_percentage = Math.round((this.form[index].new_amount.amount - this.billableExpenses.data[index].all_in_cost.amount) / this.billableExpenses.data[index].all_in_cost.amount * 100);
            },

            updateNewAmountFromPercentage(index, value) {
                this.form[index].new_amount.amount = Math.round(this.billableExpenses.data[index].all_in_cost.amount * (1 + value/100));
            },

            checkAllChanged(event) {
                if (event.target.checked) {
                    this.form.forEach((value, index) => this.formCheckboxChecked(index));
                    this.selected_billable_expenses = this.form.map((value, index) => index);
                } else {
                    this.selected_billable_expenses.forEach(formIndex => this.cancelFormEdit(formIndex));
                    this.selected_billable_expenses = [];
                }
            },

            formCheckboxChecked(index) {
                let bulkMarkupPercentage = this.mass_markup_percentage ? this.mass_markup_percentage : 0;
                this.form[index].percentage_editing = false;
                this.form[index].amount_editing = false;
                this.updateNewAmountFromPercentage(index, bulkMarkupPercentage);
            }
        },

        computed: {
            indeterminate() {
                return this.selected_billable_expenses.length > 0 && this.selected_billable_expenses.length < this.billableExpenses.data.length;
            }
        },

        watch: {
            current_fiscal_period_id(newValue) {
                this.state = 'loading';

                this.$inertia.get(this.$route('revenue.billable-expenses', newValue), {
                    preserveState: true,
                    onFinish: () => this.state = 'passive',
                });
            },

            mass_markup_percentage(newValue) {
                newValue ||= 0;

                for (var index = 0; index < this.form.length; index++) {
                    if (this.selected_billable_expenses.includes(index)) {
                        this.updateNewAmountFromPercentage(index, newValue);
                    }
                }
            },
        }
    }
</script>
