<template>
    <Head title="Uh Oh" />

    <main class="flex flex-col items-center text-center justify-center h-64">
        <h1 class="text-6xl">{{ heading }}</h1>

        <span class="block text-xl">{{ message }}</span>

        <hr class="separator">

        <inertia-link href="/" class="btn btn-orange text-xl flex items-center" role="button">
            <icon name="home" class="fill-current w-6 h-6 mr-2" /> Take me home
        </inertia-link>
    </main>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import ErrorLayout from "@/Shared/ErrorLayout.vue";

    export default {
        layout: ErrorLayout,

        components: {
            Head,
            Icon
        },

        props: {
            status: {
                required: true,
                type: Number
            }
        },

        data() {
            return {
                errorRegistry: {
                    403: {heading: 'Forbidden', message: "We're sorry, but you don't have the permissions necessary to access the requested resource"},
                    404: {heading: 'Page Not Found', message: "We're sorry, but we can't seem to find the page you're looking for."},
                    405: {heading: 'Method Not Allowed', message: "We're sorry, but the resource you're attempting to access cannot be reached using the given method."},
                    500: {heading: 'Internal Server Error', message: "We're sorry, but we are unable to complete your request."},
                    503: {heading: 'Be Right Back', message: "We're sorry, but this application is currently unavailable due to maintenance."}
                }
            }
        },

        computed: {
            heading() {
                return this.errorRegistry[this.status].heading;
            },

            message() {
                return this.errorRegistry[this.status].message;
            }
        }
    }
</script>