<template>
    <Head title="SignUp" />

    <div class="pb-20 md:min-h-screen md:flex md:pb-0">
        <div class="relative overflow-hidden bg-gray-50 border-b md:w-1/2 md:border-r md:border-b-0">
            <img src="/images/signup_bg.png" class="absolute inset-0 h-full w-full object-cover object-top brightness-150" />
            <div class="absolute inset-0 bg-gray-900/50 mix-blend-multiply"></div>

            <div class="relative flex flex-col gap-0 space-y-6 md:gap-8 p-12">
                <div id="logo">
                    <logo class="block w-96 h-auto" name="full-white" />
                </div>

                <div class="flex-grow space-y-4 text-white">
                    <div class="text-3xl md:text-4xl font-bold">Stop Downloading Invoices Manually</div>
                    <div class="text-2xl md:text-3xl font-medium">Let Discovery Gather them for you!</div>

                    <div class="mt-8 p-10 rounded bg-d-blue-600 border-d-blue-700">
                        <h3 class="text-3xl leading-6 text-orange-500">Start your Free Trial:</h3>
                        <ul class="mt-4 px-6 text-white list-disc space-y-2">
                            <li>See all of your invoices in one place</li>
                            <li>Validate up to three hauling vendor logins</li>
                            <li>Eliminate your frustrations with manual invoice downloads</li>
                        </ul>
                    </div>
                </div>

                <div class="w-full block">
                    <div class="p-10 text-gray-900 bg-white rounded">
                        <img class="h-8 w-auto" src="/images/finetune.svg" alt="Finetune logo" />
                        <figure>
                            <blockquote class="mt-6 italic font-light sm:text-xl sm:leading-8">
                                <p class="text-3xl">"We value your willingness &amp; flexibility to partner with us from the beginning."</p>
                            </blockquote>
                            <figcaption class="mt-6 text-base">
                                <div class="font-semibold">Ben Miller</div>
                                <div class="mt-1">Chief Information Officer</div>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-white p-8 md:flex md:flex-col md:gap-8 md:w-1/2 md:px-12 md:py-8">
            <nav id="nav" class="mb-8 md:mb-12" aria-label="Progress">
                <ol role="list" class="divide-y p-3 sm:divide-none sm:flex sm:space-x-8 sm:space-y-0">
                    <li class="py-2 sm:flex-1 sm:py-0" v-for="(step, i) in steps" :key="step.id">
                        <span :class="stepStatus(step.id)">
                            <span class="text-sm font-medium text-d-blue-500">Step {{ i+1 }}</span>
                            <span class="text-sm font-medium text-d-blue-800">{{ step.id }}</span>
                        </span>
                    </li>
                </ol>
            </nav>

            <!-- Step 1 - Account -->
            <div id="register-step-1" class="grid grid-cols-2 gap-8" v-if="step === 'Account'">
                <div class="col-span-2">
                    <div class="flex justify-between">
                        <h3 id="Account-step" class="text-2xl font-medium">Account Creation</h3>
                        <button @click=clearForm class="btn btn-gray">Clear Form</button>
                    </div>
                </div>

                <div class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1">
                    <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">
                        <span class="text-red-500">*</span>
                        First Name
                    </label>
                    <div class="mt-1">
                        <input id="first_name" placeholder="John" data-cy="login-input-first_name" type="text" v-model="form.first_name" required autofocus :class="{ error: formErrors.first_name }" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                        <div v-if="formErrors.first_name && formErrors.first_name.length" class="form-error">{{ formErrors.first_name }}</div>
                    </div>
                </div>

                <div class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1">
                    <label for="last_name" class="block text-sm font-medium leading-5 text-gray-700">
                        <span class="text-red-500">*</span>
                        Last Name
                    </label>
                    <div class="mt-1">
                        <input id="last_name" placeholder="Smith" data-cy="login-input-last_name" type="text" v-model="form.last_name" required autofocus :class="{ error: formErrors.last_name }" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                        <div v-if="formErrors.last_name && formErrors.last_name.length" class="form-error">{{ formErrors.last_name }}</div>
                    </div>
                </div>

                <div class="col-span-2">
                    <label for="email" class="block text-sm font-medium leading-5 text-gray-700">
                        <span class="text-red-500">*</span>
                        Email address
                    </label>
                    <div class="mt-1">
                        <input id="email" placeholder="johnsmith@example.com" data-cy="login-input-email" type="email" v-model="form.email" required autofocus :class="{ error: formErrors.email }" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                        <div v-if="formErrors.email && formErrors.email.length" class="form-error">{{ formErrors.email }}</div>
                    </div>
                </div>

                <div class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1">
                    <label for="tenant_name" class="block text-sm font-medium leading-5 text-gray-700">
                        <span class="text-red-500">*</span>
                        Company Name
                    </label>
                    <div class="mt-1">
                        <input id="tenant_name" placeholder="Your Company Name" data-cy="login-input-tenant_name" type="text" v-model="form.tenant_name" required autofocus :class="{ error: formErrors.tenant_name }" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                        <div v-if="formErrors.tenant_name && formErrors.tenant_name.length" class="form-error">{{ formErrors.tenant_name }}</div>
                    </div>
                </div>

                <div class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1">
                    <label for="phone_number" class="block text-sm font-medium leading-5 text-gray-700">
                        Phone Number
                    </label>
                    <div class="mt-1">
                        <input id="phone_number" placeholder="555-555-5555" data-cy="login-input-phone-number" type="text" v-model="form.phone_number" required autofocus :class="{ error: formErrors.phone_number }" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                        <div v-if="formErrors.phone_number && formErrors.phone_number.length" class="form-error">{{ formErrors.phone_number }}</div>
                    </div>
                </div>

                <div class="col-span-2">
                    <label for="password" class="block text-sm font-medium leading-5 text-gray-700">
                        <span class="text-red-500">*</span>
                        Password
                    </label>

                    <div class="mt-1">
                        <input id="password" placeholder="******" type="password" data-cy="login-input-password" v-model="form.password" required :class="{ error: formErrors.password }" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                        <div v-if="formErrors.password && formErrors.password.length" class="form-error">{{ formErrors.password }}</div>
                    </div>
                    <p class="mt-2 text-sm text-gray-500" id="email-description">Minimum 8 characters and contain a symbol, number, uppercase and lowercase letter</p>
                </div>

                <div class="col-span-2">
                    <label for="password_confirmation" class="block text-sm font-medium leading-5 text-gray-700">
                        <span class="text-red-500">*</span>
                        Confirm Password
                    </label>

                    <div class="mt-1">
                        <input id="password_confirmation" placeholder="******" type="password" data-cy="login-input-password_confirmation" v-model="form.password_confirmation" required :class="{ error: formErrors.password_confirmation }" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                        <div v-if="formErrors.password_confirmation && formErrors.password_confirmation.length" class="form-error">{{ formErrors.password_confirmation }}</div>
                    </div>
                </div>

                <div class="col-span-2">
                    <div class="flex items-center">
                        <input id="accept_tos" name="accept_tos" type="checkbox" v-model="form.accept_tos" class="h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500" />
                        <label for="accept_tos" class="ml-3 block text-sm leading-6 text-gray-900">
                            I agree to the
                            <a class="link underline" href="https://www.discoveryapp.io/terms-of-service" target="_blank">Terms of Service</a>,
                            <a class="link underline" href="https://www.discoveryapp.io/end-user-license-agreement" target="_blank">EULA</a>,
                            and <a class="link underline" href="https://www.discoveryapp.io/privacy-policy" target="_blank">Privacy Policy</a>.
                        </label>
                    </div>
                    <div v-if="formErrors.accept_tos && formErrors.accept_tos.length" class="form-error">{{ formErrors.accept_tos }}</div>
                </div>

                <div class="col-span-2 flex justify-center md:justify-end">
                    <loading-button data-cy="continue-button-1" :loading="saving" type="button" @click="nextStep" class="btn flex btn-orange justify-center text-lg w-full md:w-auto">
                        Continue
                    </loading-button>
                </div>
            </div>

            <!-- Step 2 Verify -->
            <div id="register-step-email-verification" class="" v-else-if="step === 'Verify'">
                <div>
                    <h3 id="Account-step" class="text-2xl font-medium">Verify</h3>
                    <p class="text-lg">Let's verify your email address.</p>
                    <p v-if="emailLastSentAt !== false" class="text-sm italic text-gray-400 mt-1">We just sent a verification email to {{ form.email }}. Enter the 6-digit code below.</p>
                </div>
                <div class="mt-8 p-4 rounded bg-gray-100 border">
                    <div class="mt-1">
                        <input v-model="form.email_verification_code" placeholder="000000" :class="{ error: formErrors.email_verification_code }" maxlength="6" minlength="6" class="appearance-none inline-block px-3 py-2 w-20 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                        <button :disabled="emailLastSentAt > 0" @click="sendVerificationEmail" class="btn btn-gray inline-block ml-2">
                            <span v-if="emailLastSentAt > 0">
                              Sent ({{ emailLastSentAt }}s)
                            </span>
                            <span v-else>Send Email</span>
                        </button>
                        <div v-if="formErrors.email_verification_code && formErrors.email_verification_code.length" class="form-error">
                            {{ formErrors.email_verification_code }}
                        </div>
                    </div>
                </div>
                <div class="flex flex-col justify-center space-y-6 mt-8 md:flex-row md:space-y-0 md:space-x-3 md:justify-between">
                    <loading-button data-cy="back-button-3" :loading="saving" type="button" @click="previousStep" class="btn flex btn-blue justify-center text-xl w-full py-4 md:w-auto md:py-2 md:text-lg">
                        <icon name="long-arrow-left" class="inline fill-current h-4 w-4 mr-2" /> Back
                    </loading-button>
                    <loading-button v-if="form.email_verification_code.length === 6" data-cy="continue-button-3" :loading="saving" type="button" @click="submit" class="btn flex btn-orange justify-center text-xl w-full py-4 md:w-auto md:py-2 md:text-lg">
                        Get Started
                    </loading-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import BlankLayout from '@/Shared/BlankLayout.vue'

    export default {
        layout: BlankLayout,
    }
</script>

<script setup>

import { inject, ref, onMounted, computed } from 'vue'
import { Head, router, useForm } from '@inertiajs/vue3'
import Icon from "../../Shared/Icon.vue";
import Logo from "../../Shared/Logo.vue";
import LoadingButton from "../../Shared/LoadingButton.vue";

const props = defineProps({
    errors: {
        type: Object,
        default: () => ({})
    }
})

const route = inject('route');
const toast = inject('toast');

const form = useForm({
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    password: '',
    password_confirmation: '',
    tenant_name: '',
    email_verification_code: '',
    accept_tos: false,
});

const formErrors = ref({
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    password: '',
    password_confirmation: '',
    tenant_name: '',
    email_verification_code: '',
    accept_tos: '',
    ...props.errors
});

const steps = [
    {
        id: 'Account',
    },
    // {
    //     id: 'Review',
    // },
    {
        id: 'Verify',
    },
];

const statusClasses = {
    completed: 'group flex flex-col border-l-4 border-green-500 py-2 pl-4 sm:border-l-0 sm:border-t-4 sm:pb-0 sm:pl-0 sm:pt-4',
    current: 'group flex flex-col border-l-4 border-d-orange-500 py-2 pl-4 sm:border-l-0 sm:border-t-4 sm:pb-0 sm:pl-0 sm:pt-4',
    upcoming: 'group flex flex-col border-l-4 border-gray-200 py-2 pl-4 sm:border-l-0 sm:border-t-4 sm:pb-0 sm:pl-0 sm:pt-4',
};

const stepStatuses = ref([
    { id: 'Account', status: 'current' },
    // { id: 'Review', status: 'upcoming' },
    { id: 'Verify', status: 'upcoming' },
]);

const saving = ref(false);
const step = ref('Account');
const transitionType = ref('slide-up');

const currentStep = ref(steps.findIndex(s => s.id === step.value));

onMounted(() => {
    fillFromLocalStorage();
});

function validateStep(step) {
    let errors = 0;

    if(step === 'Account') {
        formErrors.value.first_name = form.first_name === '' ? 'First name is required' : '';
        formErrors.value.last_name = form.last_name === '' ? 'Last name is required' : '';
        formErrors.value.email = form.email === '' ? 'Email is required' : '';
        formErrors.value.tenant_name = form.tenant_name === '' ? 'Company name is required' : '';
        formErrors.value.password = form.password === '' ? 'Password is required' : '';
        formErrors.value.password = form.password.length < 8 ? 'Password must be at least 8 characters' : '';
        if (!/\d/.test(form.password)) {formErrors.value.password = 'Password must contain at least one number'}
        if (!/[a-z]/.test(form.password)) {formErrors.value.password = 'Password must contain at least one lowercase letter'}
        if (!/[A-Z]/.test(form.password)) {formErrors.value.password = 'Password must contain at least one uppercase letter'}
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(form.password)) {formErrors.value.password = 'Password must contain at least one symbol'}
        formErrors.value.password_confirmation = form.password_confirmation === '' ? 'Password confirmation is required' : (form.password !== form.password_confirmation ? 'Passwords do not match' : '');
        formErrors.value.accept_tos = !form.accept_tos ? 'You must accept the TOS to continue.' : '';

        errors = Object.values(formErrors.value).filter(e => e !== '').length;
    }

    return errors === 0;
}

const emailLastSentAt = ref(false);
const emailOriginallySent = ref(false);

function countdown(timer) {
    if (!timer.value) {
        return;
    }

    if (timer.value > 0) {
        timer.value--
        setTimeout(() => { countdown(timer) }, 1000)
    }
}

function sendVerificationEmail() {
    emailLastSentAt.value = 60;
    countdown(emailLastSentAt)

    router.post(route('signup.verify'), {"email": form.email}, {
        preserveState: true,
        onError: (e) => {
            var msg = Object.keys(e).map(key => e[key]).join(' ');
            toast.error('There was an error sending the verification email: '+msg+' Please double-check your email address, and try again.');
        }
    });
}

function nextStep() {
    if( ! validateStep(step.value)) {
        return;
    }

    transitionType.value = 'slide-up';
    stepStatuses.value[steps.findIndex(s => s.id === step.value)].status = 'completed';
    step.value = steps[steps.findIndex(s => s.id === step.value) + 1].id;
    stepStatuses.value[steps.findIndex(s => s.id === step.value)].status = 'current';

    saveToLocalStorage();

    window.scrollTo(0,0)

    if (emailOriginallySent.value === false) {
        sendVerificationEmail();
        emailOriginallySent.value = true
    }
}

function previousStep() {
    transitionType.value = 'slide-down';
    stepStatuses.value[steps.findIndex(s => s.id === step.value)].status = 'upcoming';
    step.value = steps[steps.findIndex(s => s.id === step.value) - 1].id;
    stepStatuses.value[steps.findIndex(s => s.id === step.value)].status = 'current';
}

function stepStatus(stepId) {
    return statusClasses[stepStatuses.value[steps.findIndex(s => s.id === stepId)].status];
}

function submit() {
    if( ! validateStep(step.value)) {
        return;
    }
    saveToLocalStorage();
    saving.value = true;
    router.post(route('signup.store'), form, {
        preserveState: true,
        onSuccess: () => {
            saving.value = false;
            localStorage.removeItem('register-form');
            localStorage.removeItem('verification-code');
        },
        onError: (e) => {
            saving.value = false;
            var msg = Object.keys(e).map(key => e[key]).join(' ');
            // todo this error message needs to be updated, need to find out from colin what he wants...
            toast.error('There was an error creating your account. '+msg+' Please correct the error and try again.');
            // TODO this should kick back to the step the error is on
            window.history.replaceState(null,null,route('signup')); // reset route so refreshes don't cause problems
        }
    });
}

function saveToLocalStorage() {
    localStorage.setItem('register-form', JSON.stringify(form));
}

function fillFromLocalStorage() {
    let data = JSON.parse(localStorage.getItem('register-form'));
    if (data) {
        for (let key in data) {
            if (form.hasOwnProperty(key)) {
                form[key] = data[key];
            }
        }
    }
}

function clearForm() {
    localStorage.removeItem('register-form');
    window.location.reload();
}

</script>