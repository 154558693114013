<template>
    <Head :title="`Client Invoice Reminder - ${clientInvoice.invoice_number}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-invoices.index')" class="breadcrumb-link">Client Invoices</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-invoices.show', clientInvoice.id)" class="breadcrumb-link">{{ clientInvoice.invoice_number }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create Reminder</span>
        </nav>
    </Teleport>

    <div class="grid grid-cols-6 mt-2 mx-16">
        <div class="col-span-3">
            <h3 class="text-xl pt-4">Invoice Reminder</h3>
            <div class="pt-4 pr-8">
                <text-input class="py-3" v-model="form.email_to" label="Email to" :errors="errors.email_to" :markAsRequired="true"></text-input>
                <text-input class="py-3" v-model="form.email_recipient_name" label="Email recipient name" :errors="errors.email_recipient_name" :markAsRequired="true"></text-input>
                <text-input class="py-3" v-model="form.email_cc" label="Email cc" :errors="errors.email_cc"></text-input>
                <text-input class="py-3" v-model="form.email_subject" label="Subject" :errors="errors.email_subject" :markAsRequired="true"></text-input>
                <div class="py-2">
                    <label class="form-label" aria-required="true">
                        <span class="text-red-500">*</span>
                        Message
                    </label>

                    <div v-if="hasEmptyTemplateMessage()" class="bg-blue-100 border-l-4 border-blue-500 text-blue-700 px-2 py-1" role="alert">
                        Tired of typing the same message over and over again? <a class="link" :href="route('tenant-settings.email-templates.index')">Create a template</a>
                    </div>

                    <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                        <ul class="flex flex-wrap -mb-px">
                            <li class="mr-2">
                                <span href="#"
                                   :class="{
                                        'text-blue-600 border-blue-600 active dark:text-blue-500': submenu['draft'],
                                        'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300': !submenu['draft']
                                    }"
                                   class="cursor-pointer inline-block p-4 border-b-2 rounded-t-lg"
                                   @click="changeSubmenu('draft')">Draft</span>
                            </li>
                            <li class="mr-2">
                                <span href="#"
                                   :class="{
                                        'text-blue-600 border-blue-600 active dark:text-blue-500': submenu['preview'],
                                        'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300': !submenu['preview']
                                    }"
                                   class="cursor-pointer inline-block p-4 border-b-2 rounded-t-lg"
                                   @click="changeSubmenu('preview')">Preview</span>
                            </li>
                        </ul>
                    </div>

                    <quill-editor v-if="submenu['draft']" style="min-height:200px;" v-model="form.email_message" :errors="errors.email_message" />
                    <iframe v-else-if="submenu['preview']" style="width: 100%; min-height:400px" :src="$route('client-invoice-reminders.preview', {'clientInvoiceId': clientInvoice.id, 'clientInvoiceReminderId': null, 'email_message':  form.encoded_email_message})"></iframe>
                </div>

                <div class="flex flex-row-reverse my-4">
                    <loading-button type="button" :disabled="state !== 'passive'" :loading="state === 'sending'" @click="sendReminder" class="btn btn-orange mr-2">Send</loading-button>
                    <inertia-link :href="route('client-invoices.show', clientInvoice.id)" type="button" class="btn btn-red mr-2">Cancel</inertia-link>
                </div>
            </div>
        </div>
        <div class="col-span-3">
            <div>
                <div class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
                    <p>This client invoice will be attached with this reminder</p>
                </div>
            </div>
            <div class="p-2 bg-gray-200">
                <pdf-document :url="$route('documents.preview', {path: clientInvoice.current_pdf_document})" style="height: 80vh" />
            </div>
        </div>
    </div>
</template>

<script setup>
import Icon from '@/Shared/Icon.vue';
import { reactive, ref, inject, watch, computed, onMounted, onUnmounted } from 'vue';
import { router, Head, usePage } from '@inertiajs/vue3';
import PdfDocument from "@/Shared/PDF/PdfDocument.vue";
import TextInput from "@/Shared/TextInput.vue";
import QuillEditor from "@/Shared/QuillEditor.vue";
import LoadingButton from "../../Shared/LoadingButton.vue";

const route = inject('route');
const toast = inject('toast');

/**
 * Props
 */
const props = defineProps({
    clientInvoice: {
        type: Object,
        required: true,
    },

    clientInvoiceReminder: {
        type: Object,
        required: true
    },

    errors: {
        type: Object,
        default: () => ({

        }),
    }
});


/**
 * State
 */

const Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9\+\/\=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}
const clientInvoice = reactive({...props.clientInvoice});
const form = reactive({
    ...props.clientInvoiceReminder,
    encoded_email_message: Base64.encode(props.clientInvoiceReminder.email_message)
});

// These are pure data objects that we initialize so to ensure their reactivity.
const state = ref('passive');
const mounted = ref(false);
const submenu = ref({
    draft: true,
    preview: false
});

onMounted(() => {
    mounted.value = true;
});

function sendReminder() {
    state.value = 'sending';

    router.post(route('client-invoice-reminders.store', clientInvoice.id), form, {
        preserveScroll: true,
        onFinish() {
            state.value = 'passive';
        },
    });
}

function changeSubmenu(submenuName) {
    submenu.value = {
        draft: false,
        preview: false
    };

    submenu.value[submenuName] = true;
}

function hasEmptyTemplateMessage() {
    let htmlStrippedMessage = props.clientInvoiceReminder.email_message.replace(/(<([^>]+)>)/gi, "");
    return ! props.clientInvoiceReminder.email_message || ! htmlStrippedMessage;
}

watch(() => form.email_message, (newValue, oldValue) => {
    form.encoded_email_message = Base64.encode(newValue);

});

</script>