<template>
    <Head title="Notifications" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Notifications</span>
        </nav>
    </Teleport>

    <div class="w-full my-4">
        <div v-if="notifications.data.length" class="overflow-x-auto overflow-y-hidden">
            <div class="my-3">
                <span class="font-medium text-lg text-gray-700">Showing {{ notifications.data.length }} out of {{ notifications.total }}</span>
            </div>

            <div v-for="notification in notifications.data" :key="notification.id" class="px-4 py-2 rounded shadow border mb-4">
                <div class="grid">
                    <inertia-link :href="notification.data.url" class="link text-lg font-medium">
                        {{ notification.data.title }}
                    </inertia-link>

                    <p class="mt-1">
                        {{ notification.data.body }}
                    </p>

                    <p class="mt-1 font-medium">
                        {{ $filters.diff_for_humans(notification.created_at) }}
                    </p>
                </div>
            </div>

            <pagination :links="notifications.links" />
        </div>

        <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="bell" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">You have no notifications currently.</span>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            Pagination,
            SortableTableHeader,
            Head,
        },

        props: {
            notifications: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },
    }
</script>