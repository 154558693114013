<template>
    <Head :title="`Edit Client Credit Memo - ${clientCreditMemo.id}`"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-credit-memos.index')" class="breadcrumb-link">Client Credit Memos</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <form id="edit-client-credit-memo-form" class="" @submit.prevent="submitForm">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
                <div class="col-span-4 sm:col-span-2">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Information</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Memo details and info.</p>
                </div>

                <div class="flex col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end xl:col-span-1 xl:col-end-5">
                    <inertia-link v-if="canDeleteCreditMemo" @click.prevent="remove" class="btn btn-red mr-2">Remove</inertia-link>
                </div>
            </div>

            <div class="grid grid-cols-3 gap-6">
                <div class="">
                    <label for="client-account" class="form-label">Client Account</label>

                    <inertia-link id="client-account" :href="$route('client-accounts.edit', clientCreditMemo.clientAccount.id)" class="link">
                        {{ clientCreditMemo.clientAccount.name }}
                    </inertia-link>
                </div>

                <div class="">
                    <label for="client-account" class="form-label">Client Name</label>

                    <inertia-link id="client-name" :href="$route('client-companies.edit', clientCreditMemo.clientAccount.client_company_id)" class="link">
                        {{ clientCreditMemo.client_name }}
                    </inertia-link>
                </div>

                <text-input v-model="form.external_reference_id" label="External Reference Id" :errors="errors.external_reference_id" />

                <div class="">
                    <label for="status" class="form-label">Status</label>
                    <span id="status">{{ clientCreditMemo.status }}</span>
                </div>

                <div class="my-4 flex items-center">
                    <label class="control-label">PDF Document</label>
                    <a class="btn btn-orange mx-2" target="_blank" :href="`/documents/preview?path=${encodeURI(clientCreditMemo.current_pdf_document)}`" v-if="clientCreditMemo.current_pdf_document"><i class="far fa-file-pdf"></i> View PDF</a>
                    <a v-else class="btn btn-orange mx-2" target="_blank" :href="`/client-credit-memos/${clientCreditMemo.id}/preview-pdf`"><i class="far fa-file-pdf"></i> Preview PDF</a>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-6">
                <div class="my-4">
                    <label class="control-label">Total Amount </label>
                    <span>{{ $filters.format_money(clientCreditMemo.total_amount) }}</span>
                </div>

                <div class="my-4">
                    <label class="control-label">Applied Amount </label>
                    <span>{{ $filters.format_money(clientCreditMemo.applied_amount) }}</span>
                </div>

                <div class="my-4">
                    <label class="control-label">Document Number </label>
                    <span>{{ clientCreditMemo.document_number }}</span>
                </div>

                <div class="my-4">
                    <label class="control-label">Override Settings </label>
                    <span v-if="clientCreditMemo.override_settings.length">{{ clientCreditMemo.override_settings }}</span>
                </div>
            </div>

            <div class="grid grid-cols-3 gap-6">
                <div class="my-4">
                    <label class="control-label">Document Date </label>
                    <span>{{ $filters.format_date(clientCreditMemo.document_date) }}</span>
                </div>

                <div class="my-4">
                    <label class="control-label">History </label>
                    <div v-for="history in clientCreditMemo.history" :key="history.when">
                        <p v-if="history.what == 'Status Changed'">Status changed to {{ history.data.status }} on {{ $filters.format_date_time(history.when) }} by {{ history.who.first_name }} {{ history.who.last_name }}</p>
                        <p v-else>{{ history.what }} on {{ $filters.format_date_time(history.when) }} by {{ history.who.first_name }} {{ history.who.last_name }}</p>
                    </div>
                </div>

                <div class="my-4">
                    <label class="control-label">Notes </label>
                    <span>{{ clientCreditMemo.notes }}</span>
                </div>

                <div class="my-4">
                    <loading-button :loading="saving" class="btn btn-orange" @click.prevent="emailClientCreditMemo">
                        Email Credit Memo
                    </loading-button>
                </div>

                <div class="my-4">
                    <inertia-link class="btn btn-orange" :href="$route('client-credit-memos.print', clientCreditMemo.id)">
                        Print To Send Credit Memo
                    </inertia-link>
                </div>
            </div>

            <div class="flex flex-wrap mx-4 my-2">
                <h3 class="mt-4 text-lg font-bold">Line Items</h3>

                <hr class="w-full separator">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Service</th>
                            <th>Service Date</th>
                            <th>Description</th>
                            <th>Fee Category</th>
                            <th>Unit Count</th>
                            <th>Unit Cost</th>
                            <th>Total Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="lineItem in clientCreditMemo.lineItems" :key="lineItem.id">
                            <td>
                                <inertia-link v-if="lineItem.service_id" :href="$route('services.show', lineItem.service_id)" class="link">
                                    {{ lineItem.service_id }}
                                </inertia-link>

                                {{ lineItem.service_description }}
                            </td>
                            <td>{{ $filters.format_date(lineItem.service_date) }}</td>
                            <td>{{ lineItem.description }}</td>
                            <td>{{ lineItem.category }}</td>
                            <td>{{ lineItem.unit_count }}</td>
                            <td>
                                <div class="float-right text-right">{{
                                        $filters.format_money(lineItem.unit_cost)
                                    }}
                                </div>
                            </td>
                            <td>
                                <div class="float-right text-right">{{
                                        $filters.format_money(lineItem.total_amount)
                                    }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colspan="5"></td>
                        <td>Subtotal:</td>
                        <td>
                            <div class="float-right text-right">{{
                                    $filters.format_money({
                                        'amount': clientCreditMemo.lineItems.reduce(function (carry, lineItem) {
                                            return carry + parseInt(lineItem.total_amount.amount)
                                        }, 0), 'currency': clientCreditMemo.lineItems[0].total_amount.currency
                                    })
                                }}
                            </div>
                        </td>
                        <td colspan="1"></td>
                    </tr>
                    </tfoot>
                </table>

                <template v-if="hasSalesTaxes">
                    <h3 class="mt-4 text-lg font-bold">Sales Taxes</h3>

                    <hr class="w-full separator">

                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Tax</th>
                                <th>Display Name</th>
                                <th class="text-right">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="tax in salesTaxes" :key="tax.id">
                            <td>
                                <inertia-link :href="$route('tenant-settings.sales-taxes.edit', tax.id)" class="link">
                                    {{ tax.name }}
                                </inertia-link>
                            </td>
                            <td>
                                {{ tax.display_name }}
                            </td>
                            <td class="text-right">
                                {{ $filters.format_money(tax.amount) }}
                            </td>
                        </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="1"></td>
                                <td class="text-right">Total Sales Taxes:</td>
                                <td>
                                    <div class="float-right text-right">{{
                                            $filters.format_money({
                                                'amount': salesTaxes.reduce(function (carry, tax) {
                                                    return carry + parseInt(tax.amount.amount)
                                                }, 0), 'currency': salesTaxes[0].amount.currency
                                            })
                                        }}
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </template>

                <h3 class="mt-4 text-lg font-bold">Amounts Applied To Invoices</h3>

                <hr class="w-full separator">

                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Client Invoice Number</th>
                            <th>Applied Amount</th>
                            <th>Applied Date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="applied in clientCreditMemo.applied" :key="applied.id">
                            <td>
                                <inertia-link :href="$route('client-invoices.show', applied.client_invoice_id)" class="link">
                                    {{ applied.clientInvoice.invoice_number }}
                                </inertia-link>
                            </td>
                            <td>
                                {{ $filters.format_money(applied.applied_amount) }}
                            </td>
                            <td>
                                {{ $filters.format_date(applied.applied_date) }}
                            </td>
                            <td>
                                <button type="button" @click.prevent="removeAppliedCreditMemo(applied.id)" class="danger-link text-lg p-1" title="Remove This Applied Payment Amount">
                                    <icon name="times" class="inline text-red-600 fill-current h-5 w-5" />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <text-input type="text" v-model="form.client_invoice_number" placeholder="Client Invoice Number" :errors="errors.client_invoice_number" class="form-control" />
                            </td>
                            <td>
                                <money-input type="text" v-model="form.applied_amount" :defaultCurrency="clientCreditMemo.clientAccount.clientCompany.currency" :errors="errors['applied_amount.amount']" class="form-control" />
                            </td>
                            <td>
                                <date-input v-model="form.applied_date" :errors="errors.applied_date" class="form-control" />
                            </td>
                            <td>
                                <button type="button" @click.prevent="addAppliedCreditMemo()" class="text-blue text-lg p-1" title="Add New Applied Amount">
                                    <icon name="plus" class="inline text-blue-600 fill-current h-5 w-5" />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
</template>

<script>
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import { format } from 'date-fns';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            DateInput,
            Icon,
            LoadingButton,
            MoneyInput,
            Pagination,
            TextInput,
            Head,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            canDeleteCreditMemo: {
                type: Boolean,
                default: () => false
            },

            clientCreditMemo: {
                type: Object,
                required: true
            },

            salesTaxes: {
                type: Object,
                required: true
            },

            hasSalesTaxes: {
                type: Boolean,
                required: true
            }
        },

        data() {
            return {
                form: {
                        client_invoice_number: null,
                        applied_amount: null,
                        applied_date: format(new Date, 'yyyy-LL-dd')
                },
                saving: false,
                mounted: false
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            addAppliedCreditMemo() {
                // get rid of commas and dollar signs
                if (this.form.applied_amount) {
                    this.form.applied_amount.amount = this.form.applied_amount.amount.replace(',', '').replace('$', '');
                }

                this.$inertia.post(this.$route('client-credit-memos.apply-amount', this.clientCreditMemo.id), this.form, {
                    onFinish: () => {
                        this.form = {
                            client_invoice_number: null,
                            applied_amount: null,
                            applied_date: format(new Date, 'yyyy-LL-dd')
                        };
                    }
                });
            },

            removeAppliedCreditMemo(appliedClientCreditMemoId) {
                this.$inertia
                    .delete(this.$route('client-credit-memos.unapply-amount', [this.clientCreditMemo.id, appliedClientCreditMemoId]));

            },

            emailClientCreditMemo() {
                this.saving = true;

                this.$inertia.post(this.$route('client-credit-memos.email', this.clientCreditMemo.id), {}, {
                    onFinish: () => this.saving = false
                })
            },

            remove() {
                let confirmed = confirm('Are you sure you wish to perform this action?');

                if (confirmed) {
                    this.$inertia.delete(this.$route('client-credit-memos.destroy', [this.clientCreditMemo.id]));
                }
            },
        }
    }
</script>