<template>
	<div class="p-2">
        <div id="particle-vitals-area-chart-container"></div>
    </div>
</template>
<script>
    import Highcharts from 'highcharts/highstock';
    import HighchartsMore from 'highcharts/highcharts-more';

    HighchartsMore(Highcharts);

    export default {
        props: {
            signalStrength: {
                type: Array,
                required: true
            },
            signalQuality: {
                type: Array,
                required: true
            },
            fetching: {
                type: Boolean,
                default: false
            },
            refreshing: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                chart: null
            }
        },

        mounted() {
            this.drawChart();
        },

        methods: {
            drawChart() {
                this.chart = Highcharts.stockChart('particle-vitals-area-chart-container', {
                    chart: {
                        type: 'area',
                        zoomType: 'x',
                        reflow: true,
                    },
                    legend: {
                        enabled: true
                    },
                    plotOptions: {
                        series: {
                            animation: false,
                            gapSize: 0,
                            turboThreshold: 10000,
                            fillOpacity: 0.4
                        },
                    },
                    loading: {
                        labelStyle: {
                            color: 'white'
                        },
                        style: {
                            backgroundColor: 'gray'
                        }
                    },
                    title: 'Device Vitals',
                    xAxis: {
                        type: 'datetime',
                        dateTimeLabelFormats: {
                            day: '%b %e',
                            week: '%b %e'
                        },
                        ordinal: false,
                    },
                    yAxis: {
                        min: 0,
                        softMax: 100,
                        opposite: false,
                        showLastLabel: true,
                        tickPositions: [0, 25, 50, 75, 100],
                    },
                    tooltip: {
                        shared: true,
                        valueDecimals: 2,
                    },
                    series: [
                        {
                            name: 'Signal Quality',
                            data: this.signalQuality,
                            shape: 'square',
                            showInLegend: true,
                            fillOpacity: 0.6,
                            allowOverlap: true,
                            color: '#7cb5ec',
                            dataGrouping: {
                                approximation: 'average',
                                enabled: true,
                                units: [
                                    ['hour', [1]],
                                    ['hour', [2]],
                                    ['hour', [6]],
                                ],
                                anchor: 'end',
                            }
                        },
                        {
                            name: 'Signal Strength',
                            id: 'dataseries',
                            data: this.signalStrength,
                            crisp: false,
                            color: 'rgb(67,67,72)',
                            fillOpacity: 0.0,
                            dataGrouping: {
                                approximation: 'average',
                                enabled: true,
                                units: [
                                    ['hour', [1]],
                                    ['hour', [2]],
                                    ['hour', [6]],                                ],
                                anchor: 'end',
                            }
                        },
                    ],
                    rangeSelector: {
                        labelStyle: {
                            display: 'none'
                        },
                        inputEnabled: false,
                        buttons: [
                            {
                                type: 'day',
                                count: 1,
                                text: '1d'
                            },
                            {
                                type: 'week',
                                count: 1,
                                text: '1w'
                            },
                            {
                                type: 'week',
                                count: 2,
                                text: '2w',
                            },
                            {
                                type: 'week',
                                count: 3,
                                text: '3w'
                            },

                            {
                                type: 'all',
                                text: 'All'
                            }
                        ],
                    },
                    navigator: {
                        enabled: false
                    },
                    scrollbar: {
                        enabled: false
                    },
                    zoomType: 'x',
                    time: {
                        useUTC: false
                    },
                });
            }
        },

        watch: {
            refreshing: function(value) {
                if (this.chart) {
                    value ? this.chart.showLoading('Refreshing Vitals...') : this.chart.hideLoading();
                }

                if (!value) {
                    this.drawChart();
                }
            },
            fetching: function(value) {
                if (this.chart) {
                    value ? this.chart.showLoading('Fetching Vitals...') : this.chart.hideLoading();
                }

                if (!value) {
                    this.drawChart();
                }
            }
        },
    }
</script>