<template>
    <Head title="Client Line Item Adjustments - Revenue" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Revenue - Line Item Adjustments</span>
        </nav>
    </Teleport>

    <horizontal-sub-nav current-tab="dashboard" />

    <div class="my-4 grid grid-cols-4 gap-6">
        <sub-nav class="col-span-4 sm:block md:col-span-1" current-nav="client-line-item-adjustments" :fiscal-period="fiscalPeriod" :numberOf="numberOf" />

        <div class="col-span-4 md:col-span-3">
            <div class="px-4 sm:px-6 lg:px-8">
                <div class="sm:flex sm:items-center">
                    <div class="sm:flex-auto">
                        <h1 class="text-xl font-semibold text-gray-900">Client Line Item Adjustments</h1>
                        <p class="mt-2 text-sm text-gray-700">
                            Charges from client line item adjustments that haven't been billed on a Client Invoice yet.
                        </p>
                    </div>
                </div>
                <form action="" id="fiscal-period-form" class="w-full mt-4 md:w-1/2">
                    <select-input name="current-fiscal-period" id="current-fiscal-period" v-model="curent_fiscal_period_id" label="Fiscal Period">
                        <option v-for="fiscalPeriod in fiscalPeriods" :key="fiscalPeriod.id" :value="fiscalPeriod.id">
                            {{ fiscalPeriod.year }}-{{ fiscalPeriod.month }}
                        </option>
                    </select-input>
                </form>
                <div v-if="clientLineItemAdjustments.data.length" class="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Description
                                </th>
                                <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                    Adjusted Line Item
                                </th>
                                <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                                    Date
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Amount
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Created By
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Internal Notes
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="adjustment in clientLineItemAdjustments.data" :key="adjustment.id">
                                <td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                    {{ adjustment.description }}
                                </td>
                                <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                    <a target="_blank" v-if="adjustment.clientLineItem.clientInvoice" :href="$route('client-invoices.show', adjustment.clientLineItem.client_invoice_id)">
                                        Invoice #{{ adjustment.clientLineItem.clientInvoice.invoice_number }}
                                    </a>
                                </td>
                                <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                    {{ $filters.format_date(adjustment.adjustment_date) }}
                                </td>
                                <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    {{ $filters.format_money(adjustment.total_amount) }}
                                </td>
                                <td class="px-3 py-4 text-sm text-gray-500">{{ adjustment.created_by }}</td>
                                <td class="px-3 py-4 text-sm text-gray-500">{{ adjustment.internal_notes }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination :links="clientLineItemAdjustments.links" />
                </div>

                <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                    <icon name="file-invoice" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                    <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Client Line Item Adjustments Found</span>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SubNav from '@/Pages/Revenue/SubNav.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import HorizontalSubNav from '../Revenue/HorizontalSubNav.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            HorizontalSubNav,
            Icon,
            Pagination,
            SubNav,
            SelectInput,
            Head,
        },

        props: {
            filters: {
                type: Object,
                required: true
            },

            fiscalPeriods: {
                type: Array,
                required: true
            },

            fiscalPeriod: {
                type: Object,
                required: true
            },

            numberOf: {
                type: Object,
                required: true
            },

            clientLineItemAdjustments: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                curent_fiscal_period_id: this.fiscalPeriod.id,
                state: 'passive',
                mounted: false,
            };
        },

        mounted() {
            this.mounted = true;
        },

        watch: {
            curent_fiscal_period_id(newValue, oldValue) {
                this.state = 'loading';

                this.$inertia.get(this.$route('revenue.client-line-item-adjustments', newValue), {
                    preserveState: true,
                    onFinish: () => this.state = 'passive',
                });
            }
        }
    }
</script>