<template>
    <div class="flex flex-col min-h-screen" :class=" { 'border-4': $page.props.impersonatingEmployee, 'border-pink-600': $page.props.impersonatingEmployee }">
        <nav class="block border-b-2 border-orange-300 border-solid bg-gray-800">
            <div class="flex items-center justify-between">
                <nav class="mt-1 w-full flex items-center justify-between lg:w-auto">
                    <inertia-link href="/">
	                    <logo name="mark-white" class="w-12 h-12 m-2" />
                    </inertia-link>
                </nav>
            </div>
        </nav>

        <!-- Main content goes in the slot -->
        <slot />

        <div v-if="$page.props.impersonatingEmployee" class="bg-pink-600 sticky bottom-0 text-xl text-center text-white">
            You are currently impersonating user {{ $page.props.authUser.name }}. Please be careful.
        </div>
    </div>
</template>

<script>
    import GlobalSearchBar from '@/Shared/GlobalSearchBar.vue';
    import DropdownLink from '@/Shared/DropdownLink.vue';
    import Icon from '@/Shared/Icon.vue';
    import Modal from '@/Shared/Modal.vue';
    import Notifications from '@/Shared/Notifications.vue';
    import TenantSwitcher from '@/Shared/TenantSwitcher.vue';
    import Logo from "./Logo.vue";

    export default {
        components: {
	        Logo,
            Notifications,
            GlobalSearchBar,
            DropdownLink,
            Icon,
            Modal,
            TenantSwitcher,
        },

        data() {
            return {
                showMobileNav: false,
                expandRecords: false,
                expandContracts: false,
                expandInvoicing: false
            }
        },

        watch: {
            '$page.props.success_message': {
                handler: function (newValue, oldValue) {
                    if (newValue) {
                        this.$toast.success(newValue);
                    }
                },
                deep: true,
            },

            '$page.props.error_message': {
                handler: function(newValue, oldValue) {
                    if (newValue) {
                        this.$toast.error(newValue);
                    }
                },
                deep: true,
            },

            '$page.props.helpscoutBeaconKey': function(newValue, oldValue) {
                if (this.$page.props.appName && this.$page.props.helpscoutBeaconKey) {
                    window.Beacon('destroy');
                    window.Beacon('init', this.$page.props.helpscoutBeaconKey);
                    if (this.$page.props.authUser) {
                        window.Beacon('session-data', {
                            'User ID': this.$page.props.authUser.id,
                            'Tenant': this.$page.props.authUser.tenant_name,
                            'Role': this.$page.props.authUser.role.name,
                            'Time zone': this.$page.props.authUser.time_zone
                        });
                    }
                }
            },

            showMobileNav: {
                immediate: true,
                handler(showMobileNav) {
                    if (showMobileNav) {
                        document.body.style.setProperty('overflow', 'hidden')
                    } else {
                        document.body.style.removeProperty('overflow')
                    }
                }
            }
        },
    }
</script>
