<style>

</style>

<template>
    <div>
        <div v-if="isWritable">
            <div v-if="model.amount === 0">
                {{ $filters.format_money(model) }}
            </div>

            <div v-else>
                <a href="" class="flex items-center" @click.prevent="strikeFromInvoice">
                    <icon name="ban" class="fill-current text-red-500 w-4 h-4 mr-2" />
                    {{ $filters.format_money(calculatedValue) }}
                </a>
            </div>
        </div>

        <div v-else>
            <div>
                {{ $filters.format_money(calculatedValue) }}
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';

    export default {
        emits: ['allocatable-fee-was-modified'],
        
        components: {
            Icon,
        },

        props: {
            model: {
                type: Object,
                required: true
            },

            isWritable: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                modified: false,
                amount: {...this.model.amount},
                has_been_struck: false
            };
        },

        methods: {
            strikeFromInvoice() {
                this.has_been_struck = true;
                this.$emit('allocatable-fee-was-modified', this.model.line_item_fee_id, this.calculatedValue);
            }
        },

        computed: {
            calculatedValue() {
                if (this.has_been_struck) {
                    return {
                        amount: 0,
                        currency: this.model.currency,
                    };
                }

                return this.model.amount;
            }
        }
    }
</script>
