<template>
    <Head title="Extracted Vendor Invoices" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Extract - OCR Vendor Invoices</span>
        </nav>
    </Teleport>

    <div class="rounded-md bg-yellow-50 p-4" v-if="$can('accessAccountingIntegration') && $can('accessRevenueManagement') && missingGlCodes>0">
        <div class="flex items-center">
            <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM8.28 7.22a.75.75 0 0 0-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 1 0 1.06 1.06L10 11.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L11.06 10l1.72-1.72a.75.75 0 0 0-1.06-1.06L10 8.94 8.28 7.22Z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="ml-3 mr-3">
                <h3 class="text-md font-medium text-yellow-800">It looks like some of your line item categories are missing GL codes. Before continuing, you should ensure that your GL codes are properly set up.</h3>
            </div>
            <inertia-link :href="$route('tenant-settings.line-item-categories.index', {'missing_gl_codes': '1'})" class="btn btn-orange mr-2">Edit Line Item Categories</inertia-link>
        </div>
    </div>

    <index-search-form
        v-model:search="form.search"
        v-model:per-page="form.per_page"
        v-model:selected-headers="form.selected_headers"
        :headers="headers"
        :filters-in-use="filtersInUse"
        :search-id="'search-ocr-vendor-invoices'"
        :excluded-headers="excludedHeaders"
        :per-page-options="[15, 25, 50, 100, 200]"
        :clear-filters-route="$route('ocr-vendor-invoices.index', {remember: 'forget'})"
        @show-filters="$refs.searchFilterSlideOver.show()"
    />

    <div v-if="otherUsers.length > 0" class="flex -space-x-2 overflow-hidden mb-1">
        <template v-for="user in otherUsers" :key="user.id">
            <img v-if="user.avatar_thumbnail_url" :src="user.avatar_thumbnail_url" class="inline-block h-10 w-10 rounded-full ring-2 ring-white" :alt="user.name">
            <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                <span class="text-lg font-medium leading-none text-white uppercase">{{ user.first_name[0] }}{{ user.last_name[0] }}</span>
            </span>
        </template>
    </div>

    <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
        <div v-if="ocrVendorInvoices.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">Showing {{ ocrVendorInvoices.from }} - {{ ocrVendorInvoices.to }} out of {{ ocrVendorInvoices.total }} OCR Vendor Invoices</div>

        <div class="col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end">
            <div class="flex items-center">
                <loading-button v-if="$page.props.permissions.auditVendorInvoices" class="btn btn-orange mr-2" :loading="is_bulk_processing" :class="{disabled: is_bulk_processing}" @click.native="processAllInvoices">
                   <icon name="check" class="inline text-white fill-current h-4 w-4 mr-1" /> Process Invoices ({{ numberOfOCRInvoicesWaitingToBeSentToAudit }})
                </loading-button>

                <loading-button :loading="saving" class="btn btn-gray" @click="exportData" :disabled="!ocrVendorInvoices.data.length">
                    Export to CSV
                </loading-button>
            </div>
        </div>
    </div>

    <div class="mt-6">
        <div class="overflow-x-auto">
            <transition
                enter-active-class="transition-height ease-in-out duration-500"
                enter-from-class="h-0"
                enter-to-class="h-full"
                leave-active-class="transition-height ease-in-out duration-500"
                leave-from class="h-full"
                leave-to-class="h-0">
                <div v-show="ocrVendorInvoices.data.length && selectedOCRVendorInvoiceIds.length > 0" class="bg-white flex h-10 items-center sm:left-14">
                    <span v-if="selectedOCRVendorInvoiceIds.length === 1" class="mr-8">{{  `${selectedOCRVendorInvoiceIds.length} item selected` }}</span>
                    <span v-else class="mr-8">{{  `${selectedOCRVendorInvoiceIds.length} items selected` }}</span>

                    <div class="flex space-x-3">
                        <button type="button" @click="archiveSelectedFiles" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyNonArchivedSelected">Archive</button>
                        <button type="button" @click="unarchiveSelectedFiles" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyArchivedSelected">Unarchive</button>
                        <button type="button" @click="processSelectedInvoices" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyReadyForProcessingSelected">Process</button>
                        <button type="button" @click="reprocessSelectedInvoices" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyProcessingCompleteSelected">Reprocess</button>

                        <!--Used a form action here because we don't want inertia to handle the redirect for the zip download-->
                        <form :action="$route('ocr-vendor-invoices.bulk-download-selected-pdf')" method="POST">
                            <input type="hidden" name="_token" :value="csrfToken">
                            <input v-for="(ocr_vendor_invoice_id, index) in selectedOCRVendorInvoiceIds" type="hidden" :name="`ocr_vendor_invoice_ids[${index}]`" :value="ocr_vendor_invoice_id">
                            <button type="submit" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="selectedOCRVendorInvoiceIds.length === 0">Download</button>
                        </form>
                    </div>
                </div>
            </transition>

            <table class="table table-condensed overflow-x-scroll" ref="table">
                <thead>
                    <tr>
                        <th scope="col" class="relative px-7 sm:w-12 sm:px-6">
                            <input type="checkbox" :disabled="!ocrVendorInvoices.data.length" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :checked="ocrVendorInvoices.data.length && (indeterminate || selectedOCRVendorInvoiceIds.length === ocrVendorInvoices.data.length)" :indeterminate="indeterminate" @change="selectedOCRVendorInvoiceIds = $event.target.checked ? ocrVendorInvoices.data.map((ocrVendorInvoice) => ocrVendorInvoice.id) : []" />
                        </th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1">PDF</th>
                        <th v-show="form.selected_headers.includes('File Name')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">File Name</th>
                        <sortable-table-header v-show="form.selected_headers.includes('Status')" scope="col" field="ocr_vendor_invoices.status" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="ocr-vendor-invoices.index">Status</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Batch Name')" scope="col" field="gathered_vendor_invoice_files.batch_name" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="ocr-vendor-invoices.index">Batch Name</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Vendor Name')" scope="col" field="ocr_vendor_invoices.vendor_name" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="ocr-vendor-invoices.index">Vendor Name</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Service Address')" scope="col" field="ocr_vendor_invoices.service_address" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="ocr-vendor-invoices.index">Service Address</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Account Number')" scope="col" field="ocr_vendor_invoices.account_number" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="ocr-vendor-invoices.index">Account Number</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Invoice Date')" scope="col" field="ocr_vendor_invoices.invoice_date" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="ocr-vendor-invoices.index">Invoice Date</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Invoice Number')" scope="col" field="ocr_vendor_invoices.invoice_number" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="ocr-vendor-invoices.index">Invoice Number</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Invoice Total')" scope="col" field="ocr_vendor_invoices.invoice_total" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900" :filters="filters" route-name="ocr-vendor-invoices.index">Invoice Total</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Amount Due')" scope="col" field="ocr_vendor_invoices.amount_due" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900" :filters="filters" route-name="ocr-vendor-invoices.index">Amount Due</sortable-table-header>
                        <th v-show="form.selected_headers.includes('File Size')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">File Size</th>
                        <th v-show="form.selected_headers.includes('Pages')" scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Pages</th>
                        <sortable-table-header v-show="form.selected_headers.includes('Created At')" scope="col" field="ocr_vendor_invoices.created_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="ocr-vendor-invoices.index">Created At</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Archived At')" scope="col" field="ocr_vendor_invoices.archived_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="ocr-vendor-invoices.index">Archived At</sortable-table-header>
                        <th scope="col" class="w-36 px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="ocrVendorInvoice in ocrVendorInvoices.data" :key="ocrVendorInvoice.id">
                        <td class="relative px-7 sm:w-12 sm:px-6">
                            <div v-if="selectedOCRVendorInvoiceIds.includes(ocrVendorInvoice.id)" class="absolute inset-y-0 left-0 w-0.5 bg-d-orange-600"></div>
                            <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :value="ocrVendorInvoice.id" v-model="selectedOCRVendorInvoiceIds" />
                        </td>
                        <td>
                            <a :href="$route('documents.preview', {path: ocrVendorInvoice.gatheredVendorInvoiceFile.document_path})" class="flex items-center p-1 text-2xl" target="_blank">
                                <icon name="file-pdf" class="inline fill-current text-red-500 hover:text-red-500 w-5 h-5" />
                            </a>
                        </td>
                        <td v-show="form.selected_headers.includes('File Name')">
                            <inertia-link data-cy="ocr-vendor-invoices.index.show-link" :href="$route('ocr-vendor-invoices.edit', ocrVendorInvoice.id)" class="link" title="Edit This OCR Vendor Invoice">
                                {{ ocrVendorInvoice.gatheredVendorInvoiceFile.document_name }}
                            </inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('Status')">
                            {{ ocrVendorInvoice.status }}
                        </td>
                        <td v-show="form.selected_headers.includes('Batch Name')">{{ ocrVendorInvoice.gatheredVendorInvoiceFile.batch_name }}</td>
                        <td v-show="form.selected_headers.includes('Vendor Name')">{{ truncate(ocrVendorInvoice.vendor_name) }}</td>
                        <td v-show="form.selected_headers.includes('Service Address')">{{ ocrVendorInvoice.service_address }}</td>
                        <td v-show="form.selected_headers.includes('Account Number')">
                            <a class="link" v-if="ocrVendorInvoice.vendor_account_id" :href="$route('vendor-accounts.show', ocrVendorInvoice.vendor_account_id)">{{ ocrVendorInvoice.vendorAccount.account_number }}</a>
                            <span v-else>{{ ocrVendorInvoice.account_number }}</span>
                        </td>
                        <td v-show="form.selected_headers.includes('Invoice Date')">
                            {{ $filters.format_date(ocrVendorInvoice.invoice_date) }}
                        </td>
                        <td v-show="form.selected_headers.includes('Invoice Number')">
                            {{ ocrVendorInvoice.invoice_number }}
                        </td>
                        <td v-show="form.selected_headers.includes('Invoice Total')" class="text-right">
                            <span v-if="ocrVendorInvoice.invoice_total">{{ $filters.format_money(ocrVendorInvoice.invoice_total) }}</span>
                        </td>
                        <td v-show="form.selected_headers.includes('Amount Due')" class="text-right">
                            <span v-if="ocrVendorInvoice.amount_due">{{ $filters.format_money(ocrVendorInvoice.amount_due) }}</span>
                        </td>
                        <td v-show="form.selected_headers.includes('File Size')">{{ toHumanSize(ocrVendorInvoice.gatheredVendorInvoiceFile.document_size) }}</td>
                        <td v-show="form.selected_headers.includes('Pages')" class="text-right">{{ ocrVendorInvoice.page_count }}</td>
                        <td v-show="form.selected_headers.includes('Created At')">
                            {{ $filters.format_date_time(ocrVendorInvoice.created_at) }}
                        </td>
                        <td v-show="form.selected_headers.includes('Archived At')">
                            {{ $filters.format_date_time(ocrVendorInvoice.archived_at) }}
                        </td>
                        <td class="text-right">
                            <span class="inline-block">
                                <inertia-link :href="$route('ocr-vendor-invoices.edit', ocrVendorInvoice.id)" class="link" title="Edit This OCR Vendor Invoice">
                                    Edit
                                </inertia-link>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <pagination :links="ocrVendorInvoices.links" />
    </div>

    <div v-if="!ocrVendorInvoices.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
        <icon name="money-check-alt" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No OCR Vendor Invoices Found</span>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #body>
            <form @submit.prevent id="ocr-vendor-invoice-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
                <div class="mt-1 col-span-6 sm:col-span-2 md:col-span-1">
                    <select-input label="Filter by Date Type" v-model="form.date_type">
                        <option value="created_at">Created Date</option>
                        <option value="invoice_date">Invoice Date</option>
                    </select-input>
                </div>

                <div class="mt-1 col-span-6 sm:col-span-2 md:col-span-1">
                    <date-input v-if="form.date_type === 'created_at'" label="After Created Date" v-model="form.after_created_date"></date-input>
                    <date-input v-if="form.date_type === 'invoice_date'" label="After Invoice Date" v-model="form.after_invoice_date"></date-input>
                </div>

                <div class="mt-1 col-span-6 sm:col-span-2 md:col-span-1">
                    <date-input v-if="form.date_type === 'created_at'" label="And Before Created Date" v-model="form.before_created_date"></date-input>
                    <date-input v-if="form.date_type === 'invoice_date'" label="And Before Invoice Date" v-model="form.before_invoice_date"></date-input>
                </div>

                <select-input id="archived" name="archived" class="col-span-1" v-model="form.archived" label="Archived">
                    <option value="except">Hide Archived</option>
                    <option value="include">Include Archived</option>
                    <option value="only">Only Archived</option>
                </select-input>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.statuses" multiple>
                        <ComboboxLabel class="form-label" for="statuses">Statuses</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput id="statuses" name="statuses" class="form-input" @change="statusComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="statuses.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="status in filteredStatusOptions" :key="status" :value="status" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ status }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <icon name="heroicon-check" class="size-5 fill-current" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
            </form>
        </template>
    </slide-over>
</template>

<script>
    // Import Methods
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import { isEmpty, pickBy, throttle, truncate } from "lodash-es";

    // Import Components
    import Icon from '@/Shared/Icon.vue';
    import Modal from '@/Shared/Modal.vue';
    import { Head } from '@inertiajs/vue3';
    import TextInput from '@/Shared/TextInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import SlideOver from '@/Shared/SlideOver.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import ColumnSelector from "@/Shared/ColumnSelector.vue";
    import IndexSearchForm from "@/Shared/IndexSearchForm.vue";
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import BooleanCheckboxInput from '@/Shared/BooleanCheckboxInput.vue';
    import CopyToClipboardButton from '@/Shared/CopyToClipboardButton.vue';

    // Tailwind UI combobox
    import {
        Combobox,
        ComboboxInput,
        ComboboxLabel,
        ComboboxButton,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Icon,
            Head,
            Modal,
            DateInput,
            SlideOver,
            TextInput,
            Pagination,
            SearchInput,
            SelectInput,
            LoadingButton,
            TextareaInput,
            ColumnSelector,
            IndexSearchForm,
            ToggleSwitchInput,
            SortableTableHeader,
            BooleanCheckboxInput,
            CopyToClipboardButton,

            // Tailwind UI combobox
            Combobox,
            ComboboxInput,
            ComboboxLabel,
            ComboboxButton,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            filters: Object,

            csrfToken: {
                type: String,
                required: true,
            },

            errors: {
                type: Object,
                default: () => ({}),
            },

            isBulkProcessing: {
                type: Boolean,
                required: true,
            },

            ocrVendorInvoices: {
                type: Object,
                required: true,
            },

            numberOfOCRInvoicesWaitingToBeSentToAudit: {
                type: Number,
                required: true,
            },

            missingGlCodes: {
                type: Number,
                required: true,
            },

            statuses: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                form: {
                    after_created_date: this.filters.after_created_date,
                    after_invoice_date: this.filters.after_invoice_date,
                    before_created_date: this.filters.before_created_date,
                    before_invoice_date: this.filters.before_invoice_date,
                    date_type: this.filters.date_type ?? 'invoice_date',
                    search: this.filters.search,
                    per_page: this.filters.per_page,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    statuses: this.filters.statuses,
                    archived: this.filters.archived,
                    selected_headers: this.filters.selected_headers,
                },
                statusComboBoxQuery: '',
                is_bulk_processing: this.isBulkProcessing,
                otherUsers: [],
                saving: false,
                selectedOCRVendorInvoiceIds: [],
                mounted: false,
                headers: [],
                excludedHeaders: ['PDF', 'Actions'],
                filtersInUse: 0,
            }
        },

        mounted() {
            Echo.join(`ocr_vendor_invoices.index.${this.$page.props.authUser.current_tenant_id}`)
                .here((users) => {
                    this.otherUsers = users;
                })
                .joining((user) => {
                    this.otherUsers.push(user);
                })
                .leaving((user) => {
                    this.otherUsers = this.otherUsers.filter(otherUser => otherUser.id !== user.id);
                })
                .listen('.App\\Events\\OCRVendorInvoice\\BulkProcessingOCRVendorInvoicesHasStarted', (e) => {
                    this.$toast.success('Bulk processing has started.');
                    this.is_bulk_processing = true;
                })
                .listen('.App\\Events\\OCRVendorInvoice\\BulkProcessingOCRVendorInvoicesHasFinished', (e) => {
                    this.$toast.success('Bulk processing has finished.');
                    this.is_bulk_processing = false;
                })
                .listen('.App\\Events\\OCRVendorInvoice\\BulkReprocessingOCRVendorInvoicesHasStarted', (e) => {
                    this.$toast.success('Bulk reprocessing has started.');
                    this.is_bulk_processing = true;
                })
                .listen('.App\\Events\\OCRVendorInvoice\\BulkReprocessingOCRVendorInvoicesHasFinished', (e) => {
                    this.$toast.success('Bulk reprocessing has finished.');
                    this.is_bulk_processing = false;
                });

            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        unmounted() {
            Echo.leave(`ocr_vendor_invoices.index.${this.$page.props.authUser.current_tenant_id}`);
        },

        methods: {
            isEmpty,
            truncate,
            processAllInvoices() {
                this.is_bulk_processing = true;
                this.$inertia.put(this.$route('ocr-vendor-invoices.bulk-process'), {})
            },

            processSelectedInvoices() {
                this.is_bulk_processing = true;
                this.$inertia.put(this.$route('ocr-vendor-invoices.bulk-process'), {
                    ocr_vendor_invoice_ids: this.selectedOCRVendorInvoiceIds,
                }, {
                    onSuccess: () => this.selectedOCRVendorInvoiceIds = []
                })
            },

            reprocessSelectedInvoices() {
                this.is_bulk_processing = true;
                this.$inertia.put(this.$route('ocr-vendor-invoices.bulk-reprocess'), {
                    ocr_vendor_invoice_ids: this.selectedOCRVendorInvoiceIds,
                }, {
                    onSuccess: () => this.selectedOCRVendorInvoiceIds = []
                })
            },

            toHumanSize(fileSize) {
                let megabytes = Math.round(fileSize/(1024*1024) * 100) / 100
                let kilobytes = Math.round(fileSize/(1024) * 100) / 100

                if (megabytes > 1) return megabytes + 'MB';
                return kilobytes + 'KB';
            },

            exportData() {
                this.saving = true;

                this.$inertia.post(this.$route('csv.ocr-vendor-invoices.index'), this.queryFilters, {
                    onFinish: () => { this.saving = false; }
                });
            },

            archiveSelectedFiles() {
                this.$inertia.post(this.$route('ocr-vendor-invoices.archive-selected'), {
                    ocr_vendor_invoice_ids: this.selectedOCRVendorInvoiceIds,
                }, {
                    onSuccess: () => this.selectedOCRVendorInvoiceIds = []
                });
            },

            unarchiveSelectedFiles() {
                this.$inertia.post(this.$route('ocr-vendor-invoices.unarchive-selected'), {
                    ocr_vendor_invoice_ids: this.selectedOCRVendorInvoiceIds,
                }, {
                    onSuccess: () => this.selectedOCRVendorInvoiceIds = []
                });
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));

                this.headers = headers;
            },

            applyFormWatcher() {
                let query = pickBy(this.form);
                query = Object.keys(query).length ? query : {remember: 'forget'};
                this.$watch(
                    'form',
                    throttle(function () {
                        if (this.mounted) {
                            this.$inertia.get(this.$route('ocr-vendor-invoices.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },
        },

        computed: {
            queryFilters() {
                let query = { ...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            filteredStatusOptions() {
                return this.statusComboBoxQuery === ''
                    ? this.statuses
                    : this.statuses.filter((status) => {
                        return status.toLowerCase().includes(this.statusComboBoxQuery.toLowerCase());
                    });
            },

            indeterminate() {
                return this.selectedOCRVendorInvoiceIds.length > 0 && this.selectedOCRVendorInvoiceIds.length < this.ocrVendorInvoices.data.length;
            },

            onlyNonArchivedSelected() {
                return this.selectedOCRVendorInvoiceIds.every((id) => {
                    return !this.ocrVendorInvoices.data.find((invoice) => invoice.id === id)?.archived_at;
                });
            },

            onlyArchivedSelected() {
                return this.selectedOCRVendorInvoiceIds.every((id) => {
                    return this.ocrVendorInvoices.data.find((invoice) => invoice.id === id)?.archived_at;
                });
            },

            onlyReadyForProcessingSelected() {
                return this.selectedOCRVendorInvoiceIds.every((id) => {
                    return this.ocrVendorInvoices.data.find((invoice) => invoice.id === id)?.status === 'Ready For Processing';
                });
            },

            onlyProcessingCompleteSelected() {
                return this.selectedOCRVendorInvoiceIds.every((id) => {
                    return this.ocrVendorInvoices.data.find((invoice) => invoice.id === id)?.status === 'Processing Complete' &&
                        this.ocrVendorInvoices.data.find((invoice) => invoice.id === id)?.vendorInvoice?.can_be_reprocessed === true;
                });
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.date_type,
                    form.after_invoice_date,
                    form.before_invoice_date,
                    form.status,
                    form.archived,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        },

        watch: {
            'form.date_type'(newValue, oldValue) {
                if (newValue === 'created_at') {
                    this.form.after_created_date = this.form.after_invoice_date;
                    this.form.before_created_date = this.form.before_invoice_date;
                    this.form.after_invoice_date = null;
                    this.form.before_invoice_date = null;
                } else if (newValue === 'invoice_date') {
                    this.form.after_invoice_date = this.form.after_created_date;
                    this.form.before_invoice_date = this.form.before_created_date;
                    this.form.after_created_date = null;
                    this.form.before_created_date = null;
                }
            },
        },
    }
</script>
