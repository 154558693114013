<template>
    <Head title="Create Receipt Location" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.receipt-locations.index')" class="breadcrumb-link">Receipt Locations</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <div class="container mx-auto">
        <div class="flex my-4">
            <div class="w-1/2">
                <div class="my-2">
                    <text-input v-model="form.name" label="Name" :errors="errors.name" />
                </div>

                <div class="my-2">
                    <select-input v-model="form.gl_code" label="GL Account" :errors="errors.gl_code">
                        <option v-for="account in accountsList" :key="account.id" :value="account.external_accounting_id">{{ account.name }}</option>
                    </select-input>
                </div>

                <div class="mt-6">
                    <inertia-link :href="$route('tenant-settings.receipt-locations.create')" class="btn btn-gray mr-4">
                        Cancel
                    </inertia-link>
                    <button class="btn btn-orange" @click.prevent="addReceiptLocation">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { router } from '@inertiajs/vue3';
    import { reactive, ref, inject, onMounted } from 'vue';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';

    // Inject
    const route = inject('route');

    // Props
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },
        receiptLocation: {
            type: Object,
            required: true
        },
        accountsList: {
            type: Object,
            required: true
        }
    });

    // State
    const form = reactive({...props.receiptLocation});
    const mounted = ref(false);

    // Mount
    onMounted(() => {
        mounted.value = true;
    });

    // Methods
    function addReceiptLocation() {
        router.post(route('tenant-settings.receipt-locations.store'), form);
    }
</script>