<style></style>

<template>
    <table class="table table-sm table-no-head-padding text-sm">
        <thead>
            <tr>
                <th class="font-medium text-gray-500">{{ title }}</th>
                <th class="font-normal head-right"><div class="flex flex-row-reverse items-baseline"><icon name="check" class="h-5 w-5 mr-2.5 text-green-700 fill-current"></icon></div></th>
                <th class="font-normal head-right"><div class="flex flex-row-reverse items-baseline"><icon name="times" class="h-5 w-5 mr-2.5 text-red-700 fill-current"></icon></div></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">Credentials</td>

                <td><div class="flex flex-row-reverse items-baseline">
                    <div class="inline-block text-green-800 bg-green-100 text-center font-bold rounded-full px-2 w-10">{{vendorPortalCredentials.validCount}}</div>
                </div></td>

                <td><div class="flex flex-row-reverse items-baseline">
                    <div class="inline-block text-red-800 bg-red-100 text-center font-bold rounded-full px-2 w-10">{{vendorPortalCredentials.invalidCount}}</div>
                </div></td>

                <td><div class="flex items-baseline">
                    <inertia-link :href="$route('vendor-portal-credentials.index')+'?statuses[]=Discovering%20Accounts%20Failed&statuses[]=Authentication%20Failed%20-%20Invalid%20Credentials&statuses[]=Authentication%20Failed%20-%20Other'" class="btn btn-sm btn-gray" title="Edit Invalid Rover Credentials" v-if="vendorPortalCredentials.invalidCount>0">
                        Fix
                    </inertia-link>
                </div></td>
            </tr>
            <tr>
                <td class="">Accounts</td>

                <td><div class="flex flex-row-reverse items-baseline">
                    <div class="inline-block text-green-800 bg-green-100 text-center font-bold rounded-full px-2 w-10">{{vendorPortalAccounts.healthyCount}}</div>
                </div></td>

                <td><div class="flex flex-row-reverse items-baseline">
                    <div class="inline-block text-red-800 bg-red-100 text-center font-bold rounded-full px-2 w-10">{{vendorPortalAccounts.unhealthyCount}}</div>
                </div></td>

                <td><div class="flex items-baseline">
                    <inertia-link :href="$route('vendor-portal-accounts.index')+'?statuses[]=Searching%20for%20Invoices%20Failed'" class="btn btn-sm btn-gray" title="Edit Unhealthy Rover Accounts" v-if="vendorPortalAccounts.unhealthyCount>0">
                        Fix
                    </inertia-link>
                </div></td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import Icon from "@/Shared/Icon.vue";

    export default {
        components: {Icon},
        props: {
            title: {
                type: String,
                default: 'Rover'
            },

            vendorPortalCredentials: {
                type: Object,
                required: true
            },

            vendorPortalAccounts: {
                type: Object,
                required: true
            },
        },
    }
</script>
