<template>
    <Head title="Create Employee Account" />

    <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <logo name="mark-dark" class="w-auto h-24 mx-auto" />

            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                Create a New Account
            </h2>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form v-if="!this.$page.props.authUser" action="#" method="POST" @submit.prevent="submitForm">
                    <div class="grid grid-cols-2 gap-2">
                        <text-input v-model="form.first_name" class="col-span-2 md:col-span-1"  label="First Name" placeholder="First Name" :errors="errors.first_name" mark-as-required />

                        <text-input v-model="form.last_name" class="col-span-2 md:col-span-1"  label="Last Name" placeholder="Last Name" :errors="errors.last_name" mark-as-required />

                        <text-input disabled v-model="form.email" class="col-span-2 md:col-span-1" label="Email" placeholder="Email" :errors="errors.email" />

                        <text-input v-model="form.phone_number" class="col-span-2 md:col-span-1" label="Phone Number" placeholder="Phone Number" :errors="errors.phone_number" />

                        <text-input v-model="form.job_title" class="col-span-2 md:col-span-1" label="Job Title" placeholder="Job Title" :errors="errors.job_title" />

                        <select-input v-model="form.time_zone" class="col-span-2 md:col-span-1" label="Time Zone" placeholder="Time Zone" :errors="errors.time_zone" mark-as-required>
                            <option :value="null">
                                Select an Option
                            </option>
                            <option v-for="(timeZone, index) in timeZones" :key="index" :value="index">
                                {{timeZone}}
                            </option>
                        </select-input>
                        <text-input 
                            v-model="form.external_reference_id" 
                            label="External Reference Id" 
                            class="sm:col-span-2" 
                            :errors="errors.external_reference_id" 
                            help-text="Allows for external references IDs"
                        />
                        <text-input v-model="form.password" type="password" class="col-span-2" label="Password" help-text="Minimum of 8 characters - include an uppercase, number, and special character." placeholder="Password" :errors="errors.password" mark-as-required />

                        <text-input v-model="form.password_confirmation" type="password" class="col-span-2" label="Password Confirmation" help-text="Must match your password" placeholder="Password Confirmation" :errors="errors.password_confirmation" mark-as-required />

                        <div class="flex items-center form-label flex-wrap col-span-2 mt-6">
                            <boolean-checkbox-input v-model:checked="form.accept_terms_of_service" :value="true" mark-as-required />

                            <span class="mr-2">I agree to the <a href="https://www.discoveryapp.io/terms-of-service" target="_blank" class="link">Terms of Service</a>,</span>
                            <a href="https://www.discoveryapp.io/privacy-policy" target="_blank" class="link">Privacy Policy</a>, and
                            <a href="https://www.discoveryapp.io/eula" target="_blank" class="link">End User License Agreement</a>

                            <span v-if="errors.accept_terms_of_service" class="form-error">
                                {{ errors.accept_terms_of_service }}
                            </span>
                        </div>
                    </div>

                    <div class="mt-6">
                        <span class="block w-full rounded-md shadow-sm">
                            <loading-button :loading="submitting" type="submit" class="w-full flex justify-center btn btn-orange">
                                Register
                            </loading-button>
                        </span>
                    </div>
                </form>

                <div v-else>
                    <div class="py-8">
                        You are currently logged in as {{this.$page.props.authUser.first_name}} {{this.$page.props.authUser.last_name}}. You must log out before creating a new employee account.
                    </div>

                    <div class="mt-6">
                        <inertia-link href="/logout" class="w-full flex justify-center btn btn-orange" as="button" method="post">Logout</inertia-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import BooleanCheckboxInput from "@/Shared/BooleanCheckboxInput.vue";
    import BlankLayout from "@/Shared/BlankLayout.vue";
    import { Head } from '@inertiajs/vue3'
    import Logo from "../../Shared/Logo.vue";

    export default {
        layout: BlankLayout,

        components: {
	        Logo,
            BooleanCheckboxInput,
            LoadingButton,
            TextInput,
            SelectInput,
            Head,
        },

        props: {
            email: {
                type: String,
                required: true
            },

            timeZones: {
                type: Object,
                required: true
            },

            storeRoute: {
                type: String,
                required: true
            },

            errors: {
                type: Object,
                default: () => ({})
            },
        },

        data() {
            return {
                form: {
                    email: this.email,
                    time_zone: null,
                    accept_terms_of_service: false,
                },
                submitting: false,
            }
        },

        methods: {
            submitForm() {
                this.submitting = true;

                this.$inertia.post(this.storeRoute, this.form, {
                    onFinish: () => {
                        this.submitting = false;
                    }
                });
            }
        }
    }
</script>