<template>
    <Head title="Billing Dashboard" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Billing Dashboard</span>
        </nav>
    </Teleport>

    <div class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="billing-dashboard" />

                <div class="lg:col-span-9 pt-5">
                    <div class="sm:px-6 pb-8">

                        <div class="mt-8 lg:mt-0 lg:flex lg:gap-x-8 lg:justify-between">
                            <div class="flex-grow">
                                <div class="flex justify-between items-center">
                                    <div>
                                        <h2 class="text-lg leading-6 font-medium text-gray-900">Current Plan</h2>
                                        <h3 class="text-3xl mt-1">
                                            <span class="uppercase">Gather</span> (Pay as you go)
                                        </h3>
                                    </div>
                                </div>
                                <div class="mt-8 max-w-3xl mx-auto">
                                    <form class="border flex flex-col overflow-hidden bg-white md:rounded-lg md:shadow md:border-0 lg:mt-0">
                                        <div class="flex-grow p-5 sm:p-6">
                                            <div class="mb-8 flex justify-between items-start space-x-3 lg:block lg:justify-normal lg:space-x-0 xl:flex xl:justify-between xl:items-start xl:space-x-3">
                                                <div v-if="billingConfig?.current_prepaid_balance">
                                                    <h2 class="text-lg leading-6 font-medium text-gray-900">Current Balance</h2>
                                                    <h3 class="text-3xl mt-1">{{ filters.format_money(billingConfig.current_prepaid_balance) }}</h3>
                                                </div>

                                                <button @click.prevent="stripeCustomerPortal" class="btn btn-blue mt-0 lg:mt-3 xl:mt-0">
                                                    Payment Settings <icon name="external-link-alt" class="inline fill-current text-white h-4 ml-2" />
                                                </button>
                                            </div>

                                            <div class="md:flex md:justify-around">
                                                <div class="my-6">
                                                    <label for="floor" class="block font-medium leading-6 text-gray-900">When balance reaches</label>
                                                    <div class="relative mt-1 rounded-md shadow-sm max-w-xl">
                                                        <money-input
                                                            :min="props.restrictions.floor"
                                                            :max="floorMax"
                                                            step="1"
                                                            v-model="form.floor"
                                                            name="floor"
                                                            id="floor"
                                                            aria-required="true"
                                                            :defaultCurrency="props.restrictions.currency"
                                                            :forceWholeNumbers="true"
                                                            @updated="ceilingMinChanged"
                                                        />
                                                    </div>
                                                    <div v-if="formErrors.floor" class="form-error">{{ formErrors.floor }}</div>
                                                    <p class="text-sm mt-1 leading-6 text-gray-600">Enter an amount between ${{ parseInt(props.restrictions.floor / 100) }} and ${{ parseInt(floorMax / 100) }}</p>
                                                </div>

                                                <div class="my-6">
                                                    <label for="ceiling" class="block font-medium leading-6 text-gray-900">Bring my balance back up to</label>
                                                    <div class="relative mt-1 rounded-md shadow-sm max-w-xl">
                                                        <money-input
                                                            :min="ceilingMin"
                                                            :max="props.restrictions.ceiling"
                                                            step="1"
                                                            v-model="form.ceiling"
                                                            name="ceiling"
                                                            id="ceiling"
                                                            aria-required="true"
                                                            :defaultCurrency="props.restrictions.currency"
                                                            :forceWholeNumbers="true"
                                                        />
                                                    </div>
                                                    <div v-if="formErrors.ceiling" class="form-error">{{ formErrors.ceiling }}</div>
                                                    <p class="text-sm mt-1 leading-6 text-gray-600">Enter an amount between ${{ ceilingMin / 100 }} and ${{ parseInt(props.restrictions.ceiling / 100) }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex justify-between bg-gray-50 px-4 py-4 sm:px-6">
                                            <div class="flex items-center">
                                                <button type="button" @click="toggleReplenish" :class="[form.replenish ? 'bg-d-orange-500' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']" role="switch" aria-checked="false" aria-labelledby="replenish-label">
                                                    <span aria-hidden="true" :class="[form.replenish ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']"></span>
                                                </button>
                                                <span class="ml-3 text-sm" id="replenish-label">
                                            <span class="font-medium text-gray-900">Auto-replenish</span>
                                        </span>
                                            </div>
                                            <loading-button type="submit" @click="submitForm" :loading="saving" class="btn btn-orange text-base py-1 px-2.5">Save</loading-button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="mt-10 flex items-center gap-x-4">
                                <h4 class="flex-none text-sm font-semibold leading-6 text-d-blue-600">Available Plans</h4>
                                <div class="h-px flex-auto bg-gray-100" />
                            </div>

                            <div v-for="(details, plan) in plans" :key="plan" class="flex flex-col p-8 border md:border-0 md:ring-1 md:ring-gray-900/10 md:shadow-md md:rounded-md mt-8 mb-16" :class="plan === 'Gather' ? 'ring-d-orange-500/50' : ''">
                                <div class="flex flex-col items-center justify-between md:flex-row cursor-pointer"  @click="expandPlan(plan)">
                                    <div class="w-full md:mr-4 space-y-2">
                                        <h3 class="text-3xl font-bold uppercase" :class="plan === 'Gather' ? 'text-d-orange-500' : ''">{{ plan }}</h3>
                                        <p class="p-0 m-0 text-sm leading-6 text-gray-600">{{ details.description }}</p>
                                    </div>
                                    <div class="w-full md:w-1/2 flex items-center justify-between space-x-4">
                                        <ul class="mt-1 space-y-1">
                                            <li v-for="(name, key) in details.features" class="flex items-center rounded-full text-xs font-medium text-gray-800">
                                                <icon name="check" v-if="features[key]" class="inline fill-current text-green-600 w-3 h-3 mr-2" />
                                                <icon name="lock" v-else class="inline fill-current text-red-600 w-3 h-3 mr-2" />
                                                {{ name }}
                                            </li>
                                        </ul>
                                        <div class="flex-shrink-0 mx-8 lg:mx-0">
                                            <div v-if="!details.pricing.length" class="link text-sm underline">
                                                <a href="https://www.discoveryapp.io/request-a-demo" target="_blank">Contact Sales</a>
                                            </div>
                                            <div v-else>
                                                <div class="flex items-center justify-center bg-gray-100 rounded-full w-10 h-10">
                                                    <icon v-if="openPlan === plan" name="chevron-down" class="inline fill-current text-gray-500 h-6" />
                                                    <icon v-else name="chevron-right" class="inline fill-current text-gray-500 h-6" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="details.pricing.length && openPlan === plan">
                                    <div class="isolate mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl">
                                        <div class="rounded-3xl p-8 ring-1 ring-gray-300" v-for="catalogItem in details.pricing" :key="catalogItem.id">
                                            <h3 id="tier-hobby" class="text-lg font-semibold leading-8 text-gray-900">
                                                {{ catalogItem.tier }}
                                            </h3>
                                            <p class="mt-4 text-sm leading-6 text-gray-600">
                                                {{ JSON.parse(catalogItem.display_settings).description }}
                                            </p>
                                            <p class="mt-6 flex items-baseline gap-x-1">
                                                <!-- Price, update based on frequency toggle state -->
                                                <span class="text-4xl font-bold tracking-tight text-gray-900">
                                                    ${{ $filters.format_decimal(catalogItem.base_price, 0) }}
                                                </span>
                                                <!-- Payment frequency, update based on frequency toggle state -->
                                                <span v-if="catalogItem.frequency === 'Monthly'" class="text-sm font-semibold leading-6 text-gray-600">/{{ catalogItem.frequency }}</span>
                                                <span v-else class="text-sm font-semibold leading-6 text-gray-600"> To Get Started</span>
                                            </p>
                                            <span v-if="catalogItem.frequency === 'Monthly'" class="text-xs">
                                                Annual contract
                                            </span>
                                            <span v-else class="text-xs">
                                                No contract
                                            </span>
                                            <button type="button" v-if="!tierIsCurrent(catalogItem)" @click="newSubscription(catalogItem.tier, catalogItem.id)" aria-describedby="tier-hobby" class="appearance-none mt-6 block w-full rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-d-orange-600 text-d-orange-600 ring-1 ring-inset ring-d-orange-200 hover:ring-d-orange-300 hover:bg-d-orange-100">
                                                Upgrade
                                            </button>
                                            <div v-if="tierIsCurrent(catalogItem)" class="appearance-none mt-6 block w-full rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-gray-600 ring-1 ring-inset ring-gray-200">
                                                Current Plan
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, inject, getCurrentInstance } from 'vue';
    import { filters } from '@/Shared/Utils/Filters.js';

    // Components
    import { Head, router, usePage } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SubNav from '../SubNav.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';

    const page = usePage();
    const instance = getCurrentInstance();
    const toast = inject('toast');
    const route = inject('route');

    // Props
    const props = defineProps({
        tenant: Object,
        features: Object,
        plans: Object,
        pricingPlans: Object,
        billingConfig: Object,
        restrictions: Object,
        errors: {
            type: Object,
            default: () => ({})
        },
    });

    // State
    const mounted = ref(false);
    const saving = ref(false);
    const openPlan = ref('');
    const form = ref({
        floor:
            props.billingConfig.prepaid_balance_floor ?
            props.billingConfig.prepaid_balance_floor :
            {amount:(Number(props.restrictions.floor)), currency:props.restrictions.currency},
        ceiling:
            props.billingConfig.prepaid_balance_ceiling ?
            props.billingConfig.prepaid_balance_ceiling :
            {amount:(Number(props.restrictions.floor + props.restrictions.delta)), currency:props.restrictions.currency},
        replenish: !props.billingConfig.prepaid_auto_replenish_disabled_at,
    });
    const formErrors = ref(props.errors);

    const floorMax = props.restrictions.ceiling - props.restrictions.delta;
    const ceilingMin = ref(Number(form.value.floor.amount) + Number(props.restrictions.delta));

    onMounted(() => {
        mounted.value = true;
    });

    // Comparison
    function tierIsCurrent(catalogItem) {
        return props.billingConfig.tenant_billing_catalog_id === catalogItem.id;
    }

    // Toggles
    function expandPlan(plan) {
        if(openPlan.value === plan) {
            openPlan.value = '';
        } else {
            openPlan.value = plan;
        }
    }

    // Buttons
    function newSubscription(tier, pricingId) {
        let confirmed = confirm('Are you sure you want to upgrade to a subscription plan? Your balance will be applied as a credit on your next billing cycle.');

        if (confirmed) {
            router.post(route('tenant-settings.subscriptions.new', pricingId));
        }
    }

    function stripeCustomerPortal() {
        router.post(route('tenant-settings.subscriptions.portal'));
    }

    function toggleReplenish() {
        form.value.replenish = !form.value.replenish;
    }

    // Form stuff
    function submitForm() {
        saving.value = true;

        router.post(route('tenant-settings.pay-as-you-go.update'), form.value, {
            onFinish: () => { saving.value = false; },
        });
    }

    function ceilingMinChanged() {
        ceilingMin.value = Number(form.value.floor.amount) + Number(props.restrictions.delta);

        if (form.value.ceiling.amount < ceilingMin.value) {
            form.value.ceiling = {amount:ceilingMin.value, currency:form.value.ceiling.currency};
        }
    }
</script>
