<style>

</style>

<template>
    <tr :class="{'bg-gray-100': modified}">
        <td class="sticky left-0 w-1/5" :class="{ 'font-bold': salesTaxApplication.applicationType === 'charge' }">{{ salesTaxApplication.type }}</td>
        <td class="w-1/5" :class="{ 'font-bold': salesTaxApplication.applicationType === 'charge' }">{{ salesTaxApplication.description }}</td>
        <td class="w-1/5" :class="{ 'font-bold': salesTaxApplication.applicationType === 'charge' }">{{ $filters.format_money(salesTaxApplication.cost_basis) }}</td>
        <template v-for="(salesTax) in salesTaxApplication.salesTaxes">
            <td>
                <boolean-checkbox-input v-model:checked="salesTax.is_applied" @change="appliedSalesTaxWasModified(salesTax)"/>
            </td>
        </template>
    </tr>
</template>

<script>
    import BooleanCheckboxInput from '@/Shared/BooleanCheckboxInput.vue';
    import InlineMoneyInput from '@/Shared/InlineMoneyInput.vue';
    import InlineTextInput from '@/Shared/InlineTextInput.vue';
    import {EventBus} from '@/Shared/EventBus.js';
    import {subtract_money} from "@/Shared/Utils/Money.js";
    import { cloneDeep } from 'lodash-es';

    export default {
        emits: ['applied-sales-tax-was-modified'],

        components: {
            BooleanCheckboxInput,
            InlineMoneyInput,
            InlineTextInput,
        },
        
        props: {
            model: {
                type: Object,
                required: true
            },

            estimatedSalesTaxAmounts: {
                type: Object,
                default: {}
            },
        },

        data() {
            return {
                modified: false,
                salesTaxApplication: cloneDeep(this.model),
            };
        },

        created() {
            EventBus.on('userSalesTaxRevisionsWereApplied', updatedState => {
                this.modified = false;
            });
        },

        methods: {

            /**
             * Emit an event containing the updated state of our checkbox.
             */
            appliedSalesTaxWasModified(salesTax) {
                this.modified = this.checkIfSalesTaxIsModified();
                this.$emit('applied-sales-tax-was-modified', salesTax);
            },

            checkIfSalesTaxIsModified() {
                let modificationExists = false;

                for (let salesTax of this.salesTaxApplication.salesTaxes) {
                    let modelSalesTax = this.model.salesTaxes.find(modelSalesTax => modelSalesTax.applied_sales_tax_id === salesTax.applied_sales_tax_id);

                    if (modelSalesTax.is_applied !== salesTax.is_applied) {
                        modificationExists = true;
                        break;
                    }
                }

                return modificationExists;
            },
        },

        computed: {
        },

        watch: {
            'model': function(newValue, oldValue) {
                this.salesTaxApplication = cloneDeep(newValue);
            },
        }
    }
</script>