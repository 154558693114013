<template>
    <Head title="Vendor Invoices - QB Desktop" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.quickbooks-desktop.accounting-exports.index')" class="breadcrumb-link">Quickbooks Desktop</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Vendor Invoices</span>
        </nav>
    </Teleport>

    <main class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="quickbooks-desktop" />

                <div class="lg:col-span-9 px-1 sm:px-6">
                  <horizontal-sub-nav current-tab="vendor_invoices" />
                  <div class="flex my-4 items-center">
                        <div class="sm:flex sm:items-center w-full">
                            <div class="sm:flex-auto">
                                <h1 class="text-xl font-semibold text-gray-900">Vendor Invoice Exports</h1>
                                <p class="mt-2 text-sm text-gray-700">
                                    View vendor invoices that have been posted but need to be exported to Quickbooks Desktop.
                                </p>
                            </div>
                            <div class="w-1/2 pt-6">
                                <loading-button @click.prevent="createAccountingExportForPostedVendorInvoices" type="button" v-if="vendorInvoices.total > 0" :loading="state === 'saving'" class="btn btn-orange lg:float-right">
                                    Create New Export ({{ vendorInvoices.total }})
                                </loading-button>
                            </div>
                        </div>
                    </div>

                    <div v-if="vendorInvoices.data.length" class="mx-4 mt-8 overflow-x-auto shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                        <table class="table table-condensed overflow-auto">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                        Vendor Id
                                    </th>
                                    <th scope="col" class=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                        Invoice Number
                                    </th>
                                    <th scope="col" class=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                        Fiscal Date
                                    </th>
                                    <th scope="col" class=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                        Total Charges
                                    </th>
                                </tr>
                            </thead>

                            <tbody class="">
                                <tr v-for="vendorInvoice in vendorInvoices.data" :key="vendorInvoice.id">
                                    <td class="">
                                        {{ vendorInvoice.vendorAccount.vendor.external_accounting_id }}
                                    </td>
                                    <td class="">
                                        <inertia-link :href="$route('vendor-invoices.edit', vendorInvoice.id)" class="link">
                                            {{ vendorInvoice.invoice_number }}
                                        </inertia-link>
                                    </td>
                                    <td class="">
                                        {{ vendorInvoice.fiscal_period }}
                                    </td>
                                    <td class="">
                                        {{ $filters.format_money(vendorInvoice.adjusted_total_current_charges) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination :links="vendorInvoices.links" />
                    </div>

                    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                        <icon name="file-invoice" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Vendor Invoices Found</span>
                    </div>

                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SubNav from '../SubNav.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import { Head } from '@inertiajs/vue3';
    import HorizontalSubNav from "./HorizontalSubNav.vue";

    export default {
        components: {
          HorizontalSubNav,
            Pagination,
            Icon,
            LoadingButton,
            SelectInput,
            SubNav,
            Head,
        },

        props: {
            vendorInvoices: {
                type: Object,
                required: true
            }
        },

         data() {
            return {
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            createAccountingExportForPostedVendorInvoices() {
                if (this.state === 'passive') {
                    this.state = 'syncing';

                    this.$inertia
                        .post(this.$route('tenant-settings.quickbooks-desktop.export-vendor-invoices'), {}, {
                            onFinish: () => this.state = 'passive'
                        });
                }
            }
        },
    }
</script>