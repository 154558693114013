<template>
    <Head :title="`Edit Vendor Account - ${vendorAccount.account_number}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendor-accounts.index')" class="breadcrumb-link">Vendor Accounts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendor-accounts.show', vendorAccount.id)" class="breadcrumb-link">{{vendorAccount.account_number}} ({{ vendorAccount.vendor.name }})</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <form id="edit-vendor-account-form" @submit.prevent="submitForm">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 space-y-6 sm:pt-10 sm:space-y-5 mb-3">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Info</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Account number, Vendor and contact settings</p>
                </div>
            </legend>

            <div class="grid grid-cols-4 gap-6">
                <div class="col-span-4 md:col-span-2">
                    <label class="form-label">
                        <span class="text-red-500">*</span> Vendor
                    </label>

                    <inertia-link v-if="vendorAccount.vendor" :href="$route('vendors.show', vendorAccount.vendor_id)" class="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                        {{ vendorAccount.vendor.name ?? '' }}
                    </inertia-link>
                    <span v-else>No Vendor</span>
                </div>

                <text-input
                    v-model="form.account_number"
                    label="Account Number"
                    placeholder="Account Number"
                    mark-as-required
                    class="col-span-4 md:col-span-2 md:col-start-1"
                    :errors="errors.account_number"
                    helpText="A placeholder can be used here if the account number is not known.">
                </text-input>

                <div class="col-span-4 md:col-span-2">
                    <Combobox as="div" v-model="form.vendor_contact_id">
                        <ComboboxLabel class="form-label" for="vendor_contact_id">
                            Vendor Contact
                        </ComboboxLabel>
                        <p class="mt-2 text-sm text-gray-600">
                            This is the contact at the vendor for any billing questions on this vendor account.
                        </p>

                        <div class="relative">
                            <ComboboxInput id="filter-vendor-contacts" class="form-input" @change="contactComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" autocomplete="off" placeholder="Search for a Vendor Contact..." />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="vendorContacts.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="contact in filteredVendorContactOptions" :key="contact.id" :value="contact" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ contact.name }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
            </div>
        </fieldset>

        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Billing/Invoice Info</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Billing info, frequency, and invoice settings</p>
                </div>
            </legend>

            <div class="grid grid-cols-4 gap-6">
                <select-input
                    class="col-span-4 md:col-span-2"
                    v-model="form.billing_frequency"
                    label="Billing Frequency"
                    placeholder="Billing Frequency"
                    :errors="errors.billing_frequency"
                    help-text="This is how often we should expect this account to be billed by the Vendor"
                >
                    <option v-for="billingFrequency in billingFrequencies" :key="billingFrequency" :value="billingFrequency">
                        {{ billingFrequency }}
                    </option>
                </select-input>

                <month-input
                    class="col-span-4 md:col-span-2"
                    v-if="form.billing_frequency != 'Not Billable'"
                    v-model="form.first_fiscal_period"
                    label="First Fiscal Period"
                    help-text="The first fiscal period we expect to start receiving invoices"
                    :errors="errors.first_fiscal_period"
                    :mark-as-required="true"
                />

                <select-input
                    class="col-span-4 md:col-span-2"
                    label="Invoice Retrieval Method"
                    v-model="form.primary_invoice_retrieval_method"
                    help-text="This is how the invoice is retrieved."
                    :errors="errors.primary_invoice_retrieval_method"
                    :mark-as-required="true"
                >
                    <option v-for="retrievalMethod in retrievalMethods" :key="retrievalMethod" :value="retrievalMethod">
                        {{ retrievalMethod }}
                    </option>
                </select-input>

                <text-input
                    class="col-span-4 md:col-span-2"
                    v-if="form.primary_invoice_retrieval_method == 'Email Attachment'"
                    label="Billing Email Address"
                    v-model="form.primary_billing_email_address"
                    help-text="The client email address that the invoice is sent to as an email attachment"
                    :errors="errors.primary_billing_email_address"
                />
                <text-input
                    class="col-span-4 md:col-span-2"
                    v-else-if="form.primary_invoice_retrieval_method == 'Physical Mail'"
                    label="Billing Mailing Address"
                    v-model="form.primary_billing_mailing_address"
                    help-text="Client billing mailing address that the invoice is sent to"
                    :errors="errors.primary_billing_mailing_address"
                />

                <text-input
                    class="col-span-4 md:col-span-2"
                    label="Auto Approval Invoice Total Tolerance"
                    type="number"
                    v-model="form.auto_approval_allowed_percent_difference"
                    help-text="The percent difference from the previous invoice to allow auto approval."
                    min="0"
                    max="100"
                />

                <text-input
                    v-model="form.external_reference_id"
                    label="External Reference Id"
                    help-text="Optional external reference id for this account."
                    class="col-span-4 md:col-span-2"
                    :errors="errors.external_reference_id"
                />

                <toggle-switch-input
                    v-model="form.tax_exempt"
                    label="Tax Exempt"
                    help-text="If tax exempt mode is enabled, this account will reject all sales tax line items on its vendor invoices."
                    class="col-span-4 md:col-span-2"
                    :errors="errors.tax_exempt" />

                <div class="col-span-4 md:col-start-1 md:col-span-1" v-if="form.billing_frequency != 'Not Billable'">
                    <label class="form-label">
                        Status
                    </label>

                    <div>
                        <span v-if="vendorAccount.status === 'Active'" class='text-green-600'>
                            {{ vendorAccount.status }}
                        </span>
                        <span v-if="vendorAccount.status === 'Terminated'" class="text-red-600">
                            {{ vendorAccount.status }} on {{ $filters.format_date(vendorAccount.termination_date) }}
                        </span>
                        <span v-if="vendorAccount.status === 'New'">
                            {{ vendorAccount.status }}
                        </span>
                    </div>
                </div>

                <div class="col-span-4 md:col-span-1" v-if="form.billing_frequency != 'Not Billable'">
                    <label class="form-label">
                        Expected Next Invoice Date
                    </label>

                    <p>{{ $filters.format_date(vendorAccount.expected_next_invoice_date) }}</p>
                </div>

                <div class="col-span-4 md:col-span-1" v-if="form.billing_frequency != 'Not Billable'">
                    <label class="form-label">
                        Last Invoice Date
                    </label>

                    <div v-if="vendorAccount.last_invoice_date">{{ $filters.format_date(vendorAccount.last_invoice_date) }}</div>
                    <div v-else>N/A</div>
                </div>

                <hr class="col-span-4" v-if="form.billing_frequency != 'Not Billable'" />
            </div>
        </fieldset>

        <div class="mt-8">
            <loading-button :loading="state === 'saving'" class="btn btn-orange">
                Save Changes
            </loading-button>
        </div>
    </form>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import Modal from '@/Shared/Modal.vue';
    import MonthInput from '@/Shared/MonthInput.vue';
    import QuillEditor from '@/Shared/QuillEditor.vue';

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';
    import ToggleSwitchInput from "@/Shared/ToggleSwitchInput.vue";

    let emptyContact = {
        first_name: '',
        last_name: '',
        title: '',
        email: '',
        primary_phone_number: '',
        alternative_phone_number: '',
        mobile_phone_number: '',
        office_phone_number: '',
        type: 'General',
        is_decision_maker: null,
        notes: '',
    };

    export default {
        components: {
            ToggleSwitchInput,
            Head,
            Icon,
            LoadingButton,
            Pagination,
            SelectInput,
            TextInput,
            TextareaInput,
            Modal,
            MonthInput,
            QuillEditor,
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({}),
            },

            vendorAccount: {
                type: Object,
                required: true
            },

            billingFrequencies: {
                type: Array,
                required: true,
            },

            vendorContacts: {
                type: Array,
                required: true,
            },

            tenant: {
                type: Object,
                required: true,
            },

            retrievalMethods: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                form: {
                    ...this.vendorAccount,
                },
                state: 'passive',
                addContactFormErrors: {},
                contactComboBoxQuery: '',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm(e) {
                if (this.state === 'passive') {
                    this.state = 'saving';

                    this.$inertia.put(this.$route('vendor-accounts.update', this.vendorAccount.id), this.form, {
                        onFinish: () => {
                            this.state = 'passive';
                        }
                    });
                }
            },
            
            comboDisplayValue(option) {
                return this.vendorContacts.find(vendorContact => vendorContact.id === this.vendorAccount.vendor_contact_id)?.name ?? '';
            },

            activate() {
                if (this.state !== 'passive' || this.vendorAccount.status === 'Active') {
                    return;
                }

                this.state = 'activating';

                this.$inertia
                    .post(this.$route('vendor-accounts.activate', this.vendorAccount.id), {}, {
                        preserveScroll: true,
                        onFinish: () => {
                            this.state = 'passive';
                        }
                    });
            },

            terminate() {
                if (this.state !== 'passive' || this.vendorAccount.status === 'Terminated') {
                    return;
                }

                this.state = 'terminating';

                this.$inertia.post(this.$route('vendor-accounts.terminate', this.vendorAccount.id), {}, {
                    preserveScroll: true,
                    onFinish: () => {
                        this.state = 'passive';
                    }
                });
            }
        },

        computed: {
            filteredVendorContactOptions() {
                return this.contactComboBoxQuery === ''
                    ? this.vendorContacts
                    : this.vendorContacts.filter((contact) => {
                        return contact.first_name.toLowerCase().includes(this.contactComboBoxQuery.toLowerCase());
                    });
            }
        },
    }
</script>
