<template>
    <div class="print:hidden">
        <form class="w-full flex md:ml-0" action="#" method="GET" id="global-search-form">
            <label for="search_field" class="sr-only">Search</label>
            <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                    <icon name="search" class="w-4 h-4 text-gray-400 fill-current m-2" />
                </div>
                <input @click="showSearchDialog" autocomplete="off" name="globa-search-field" id="global-search-field" class="rounded-md block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm" placeholder="Search" type="search" role="combobox" aria-expanded="false" aria-controls="options">
            </div>
        </form>
    
        <transition leave-active-class="duration-300">
            <div v-show="searchShown" class="fixed inset-0 z-20 overflow-y-auto p-4 sm:p-6 md:p-20" role="dialog" aria-modal="true">
                <!--
                    Background overlay, show/hide based on modal state.
    
                    Entering: "ease-out duration-300"
                    From: "opacity-0"
                    To: "opacity-100"
                    Leaving: "ease-in duration-200"
                    From: "opacity-100"
                    To: "opacity-0"
                -->
                <transition enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="ease-in duration-200"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="opacity-0">
                    
                    <div @click="hideSearchDialog" class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" aria-hidden="true" v-show="searchShown"></div>
                </transition>
    
                <!--
                    Command palette, show/hide based on modal state.
    
                    Entering: "ease-out duration-300"
                    From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    To: "opacity-100 translate-y-0 sm:scale-100"
                    Leaving: "ease-in duration-200"
                    From: "opacity-100 translate-y-0 sm:scale-100"
                    To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                -->
                <transition enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    
                    <div v-show="searchShown" @keyup.esc="hideSearchDialog" class="mx-auto max-w-xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                        <div class="relative">
                            <!-- Heroicon name: solid/search -->
                            <svg class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                            </svg>

                            <input ref="globalSearchInput" v-model="searchString" type="text" class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm" placeholder="Quick search..." role="combobox" aria-expanded="false" aria-controls="options">
                        </div>
    
                        <!-- Default state, show/hide based on command palette state -->
                        <div v-if="searchString === ''" class="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14">
                            <!-- Heroicon name: outline/globe -->
                            <svg class="mx-auto h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <p class="mt-4 font-semibold text-gray-900">Find anything</p>
                            <p class="mt-2 text-gray-500">Quickly access your service records, invoices, and more.</p>
                        </div>
    
                        <!-- Results, show/hide based on command palette state -->
                        <ul class="max-h-80 scroll-pt-11 scroll-pb-2 overflow-y-auto pb-2" id="options" role="listbox">
                            <li v-for="[entity_type, items] in Object.entries(groups)" :key="entity_type">
                                <h2 class="bg-gray-100 py-2.5 px-4 text-sm font-semibold text-gray-900">
                                    {{ entityTitle(entity_type) }}
                                </h2>
                                
                                <ul class="text-gray-800">
                                    <li v-for="item in items" :key="item.id" :value="item" class="cursor-default select-none hover:bg-d-accent-200 hover:text-gray-800">
                                        <inertia-link class="block px-4 py-2" v-if="item.entity_type === 'ClientAccount'" :href="$route('client-accounts.show', [item.entity.id])">
                                            {{ item.entity.account_number }} - {{ item.entity.name }}
                                        </inertia-link>

                                        <inertia-link class="block px-4 py-2" v-else-if="item.entity_type === 'Client'" :href="$route('clients.show', [item.entity.id])">
                                            {{ item.entity.name }}
                                        </inertia-link>

                                        <template v-else-if="item.entity_type === 'ClientCompany'">
                                            <inertia-link class="block px-4 py-2" :href="$route('client-companies.show', [item.entity.id])">
                                                {{ item.entity.name }}
                                            </inertia-link>
                                        </template>

                                        <template v-else-if="item.entity_type === 'ClientContract'">
                                            <inertia-link class="block px-4 py-2" :href="$route('client-contracts.show', [item.entity.id])">
                                                {{ item.entity.id }} - {{ item.entity.clientCompany.name }}
                                            </inertia-link>
                                        </template>

                                        <template v-else-if="item.entity_type === 'ClientInvoice'">
                                            <inertia-link class="block px-4 py-2" :href="$route('client-invoices.show', [item.entity.id])">
                                                {{ item.entity.invoice_number }}
                                            </inertia-link>
                                        </template>

                                        <template v-else-if="item.entity_type === 'ClientPayment'">
                                            <inertia-link class="block px-4 py-2" :href="$route('client-payments.edit', [item.entity.id])">
                                                {{ item.entity.batch_name }}
                                            </inertia-link>
                                        </template>

                                        <template v-else-if="item.entity_type === 'ClientCreditMemo'">
                                            <inertia-link class="block px-4 py-2" :href="$route('client-credit-memos.edit', [item.entity.id])">
                                                {{ item.entity.document_number }}
                                            </inertia-link>
                                        </template>

                                        <inertia-link class="block px-4 py-2" v-else-if="item.entity_type === 'Incident'" :href="$route('incidents.edit', [item.entity.id])">
                                            {{ item.entity.type.name }} - {{ item.entity.display_id }} - {{ item.entity.status }}
                                            <p>{{item.entity.location.name}}</p>
                                        </inertia-link>

                                        <inertia-link class="block px-4 py-2" v-else-if="item.entity_type === 'Location'" :href="$route('locations.show', [item.entity.id])">
                                            {{ item.entity.name }}
                                        </inertia-link>

                                        <div v-else-if="item.entity_type === 'Service'">
                                            <inertia-link class="block px-4 py-2" :href="$route('services.show', [item.entity.id])">
                                                <span class="block font-medium">{{ item.entity.id }} - {{item.entity.location.name}}</span>
                                                <span class="text-sm">{{ item.entity.description }}</span>
                                            </inertia-link>
                                        </div>

                                        <template v-else-if="item.entity_type === 'Vendor'">
                                            <inertia-link class="block px-4 py-2" :href="$route('vendors.show', [item.entity.id])">
                                                {{ item.entity.name }}
                                            </inertia-link>
                                        </template>

                                        <template v-else-if="item.entity_type === 'VendorContract'">
                                            <inertia-link class="block px-4 py-2" :href="$route('vendor-contracts.show', [item.entity.id])">
                                                {{ item.entity.display_id }} - {{ item.entity.vendor.name ?? '' }} - {{ item.entity.status }}
                                                <p>{{ item.entity.external_reference_id }}</p>
                                            </inertia-link>
                                        </template>

                                        <template v-else-if="item.entity_type === 'VendorAccount'">
                                            <inertia-link class="block px-4 py-2" :href="$route('vendor-accounts.show', [item.entity.id])">
                                                {{ item.entity.account_number }}
                                            </inertia-link>
                                        </template>

                                        <template v-else-if="item.entity_type === 'VendorInvoice'">
                                            <inertia-link class="block px-4 py-2" :href="$route('vendor-invoices.edit', [item.entity.id])">
                                                {{ item.entity.invoice_number }}
                                            </inertia-link>
                                        </template>

                                        <template v-else-if="item.entity_type === 'VendorPortalCredential'">
                                            <inertia-link class="block px-4 py-2" :href="$route('vendor-portal-credentials.show', [item.entity.id])">
                                                <span class="font-medium">{{ item.entity.username }}</span> <span class="italic">({{ item.entity.status }})</span>
                                                <p class="text-sm">{{ item.entity.vendorPortal.vendor_name }}</p>
                                            </inertia-link>
                                        </template>

                                        <template v-else-if="item.entity_type === 'VendorPortalAccount'">
                                            <inertia-link class="block px-4 py-2" :href="$route('vendor-portal-accounts.show', [item.entity.id])">
                                                <span class="font-medium">{{ item.entity.account_number }}</span> <span class="italic">({{ item.entity.status }} - {{ item.entity.subscribed_at ? 'Subscribed' : 'Not Subscribed' }})</span>
                                                <p class="text-sm">{{ item.entity.vendorPortalCredential.vendorPortal.vendor_name }}</p>
                                            </inertia-link>
                                        </template>

                                        <template v-else-if="item.entity_type === 'VendorPortalInvoice'">
                                            <inertia-link class="block px-4 py-2" :href="$route('vendor-portal-invoices.show', [item.entity.id])">
                                                <span class="font-medium">{{ item.entity.invoice_number ?? 'N/A' }}</span> <span class="italic">({{ item.entity.status }})</span>
                                                <p class="text-sm">{{ item.entity.vendorPortalAccount.account_number }} - {{ item.entity.vendorPortalAccount.vendorPortalCredential.vendorPortal.vendor_name }}</p>
                                            </inertia-link>
                                        </template>

                                        <template v-else-if="item.entity_type === 'WorkOrder'">
                                            <inertia-link class="block px-4 py-2" :href="$route('work-orders.edit', [item.entity.id])">
                                                {{ item.entity.display_id }} - {{item.entity.service_id}}
                                            </inertia-link>
                                        </template>
                                    </li>
                                </ul>
                            </li>
                        </ul>
    
                        <!-- Empty state, show/hide based on command palette state -->
                        <div v-if="!searching && searchString !== '' && searchResults.length === 0" class="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14">
                            <!-- Heroicon name: outline/emoji-sad -->
                            <svg class="mx-auto h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <p class="mt-4 font-semibold text-gray-900">No results found</p>
                            <p class="mt-2 text-gray-500">We couldn't find anything with that term. Please try again.</p>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
    import axios from 'axios';
    import { debounce } from 'lodash-es';
    import { Link } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';

    export default {
        components: {
            Icon,
            'inertia-link': Link,
        },

        data() {
            return {
                searchString: '',
                searchShown: false,
                searching: false,
                searchResults: [],
                groups: [],
                entityTypes: {
                    'Client': 'Client',
                    'ClientAccount': 'Client Account',
                    'ClientCompany': 'Client Company',
                    'ClientContract': 'Client Contract',
                    'ClientInvoice': 'Client Invoice',
                    'ClientPayment': 'Client Payment',
                    'ClientCreditMemo': 'Client Credit Memo',
                    'Incident': 'Incident',
                    'Location': 'Location',
                    'Service': 'Service',
                    'Vendor': 'Vendor',
                    'VendorAccount': 'Vendor Account',
                    'VendorContract': 'Vendor Contract',
                    'VendorInvoice': 'Vendor Invoice',
                    'VendorPortalCredential': 'Rover Credential',
                    'VendorPortalAccount': 'Rover Account',
                    'VendorPortalInvoice': 'Rover Invoice',
                    'WorkOrder': 'Work Order'
                }
            }
        },

        created() {
            window.addEventListener('keydown', (event) => {
                if (event.key === '/' && event.target.tagName === 'BODY' && !this.searchShown) {
                    event.preventDefault();
                    this.showSearchDialog();
                }
            });
        },

        mounted() {
            this.$inertia.on('navigate', () => {
                if (this.searchShown) {
                    this.searchShown = false;
                    this.searchString = '';
                }
            });
        },

        methods: {
            showSearchDialog() {
                this.searchShown = true;
                this.$nextTick(() => this.$refs.globalSearchInput.focus());
            },

            hideSearchDialog() {
                this.searchShown = false;
                this.searchString = '';
            },

            entityTitle(entityType) {
                return this.entityTypes[entityType];
            }
        },
        watch: {
            searchString: debounce(function(searchString) {
                this.searching = true;

                axios.get(this.$route('search', {for: this.searchString})).then((response) => {
                    this.searchResults = response.data && response.data.length ? response.data : []
                    
                    this.groups = this.searchResults.reduce((groups, item) => {
                        return { ...groups, [item.entity_type]: [...(groups[item.entity_type] || []), item] }
                    }, {});
                })
                .catch(error => {

                })
                .finally(response => {
                    this.searching = false;
                })
            }, 500)
        }
    }
</script>