<template>
    <Head :title="`Vendor Contract - ${vendorContract.display_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendor-contracts.index')" class="breadcrumb-link">Vendor Contracts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ vendorContract.display_id }}</span>
        </nav>
    </Teleport>

    <!-- Page header -->
    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div class="flex-shrink-0">
                <icon name="file-signature" class="h-14 w-14" />
            </div>

            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ vendorContract.display_id }}
                    <span v-if="vendorContract.external_reference_id" class="text-lg font-semi-bold text-orange-300">{{ vendorContract.external_reference_id ?? 'EXT-123ABC' }}</span>
                </h1>

                <p class="text-sm font-medium text-gray-500">
                    <span class="text-gray-900">Vendor Contract</span> created at <time :datetime="vendorContract.created_at">{{ $filters.format_date_time(vendorContract.created_at) }}</time>
                </p>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <inertia-link :href="$route('vendor-contracts.edit', [vendorContract.id])" class="btn btn-gray">Edit</inertia-link>
            <inertia-link :href="$route('vendor-contracts.create')" class="btn btn-gray">New Contract</inertia-link>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Contract details.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Vendor</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link :href="$route('vendors.show', [vendorContract.vendor_id])" class="link">{{ vendorContract.vendor.name ?? '' }}</inertia-link>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Status</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendorContract.status }}</dd>
                            </div>

                            <div v-if="vendorContract.effective_date" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Current Effective Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date(vendorContract.effective_date) }}</dd>
                            </div>

                            <div v-if="vendorContract.expiration_date" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Current Expiration Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date(vendorContract.expiration_date) }}</dd>
                            </div>

                            <div v-if="vendorContract.check_remittance_address" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Remittance Address</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendorContract.check_remittance_address }}</dd>
                            </div>

                            <div v-if="vendorContract.termination_date" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Termination Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date(vendorContract.termination_date) }}</dd>
                            </div>

                            <div v-if="vendorContract.term_months" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Term Months</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendorContract.term_months }}</dd>
                            </div>

                            <div v-if="vendorContract.auto_renewal_term_months" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Auto Renewal Term Months</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendorContract.auto_renewal_term_months }}</dd>
                            </div>

                            <div v-if="vendorContract.annual_price_increase_percentage" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">API Percentage</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendorContract.annual_price_increase_percentage }}</dd>
                            </div>

                            <div v-if="vendorContract.days_until_payment_remittance" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Days until Payment Remittance</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendorContract.days_until_payment_remittance }}</dd>
                            </div>

                            <div v-if="vendorContract.days_to_notify_min" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Days to notify min</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendorContract.days_to_notify_min }}</dd>
                            </div>

                            <div v-if="vendorContract.days_to_notify_max" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Days to notify max</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendorContract.days_to_notify_max }}</dd>
                            </div>

                            <div v-if="vendorContract.cancellation_multiplier" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Cancellation Multiplier</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendorContract.cancellation_multiplier }}</dd>
                            </div>

                            <div class="sm:col-span-2" v-if="vendorContract.contract_document_path || vendorContract.auto_renew_document_path || vendorContract.auto_renew_mail_receipt_proof_document_path">
                                <dt class="text-sm font-medium text-gray-500">Attachments</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                                        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm" v-if="vendorContract.contract_document_path">
                                            <div class="w-0 flex-1 flex items-center">
                                                <!-- Heroicon name: solid/paper-clip -->
                                                <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                                                </svg>
                                                <span class="ml-2 flex-1 w-0 truncate"> 
                                                    <span v-if="vendorContract.contract_document_name">{{ vendorContract.contract_document_name }}</span>
                                                    <span v-else>Contract Document</span>
                                                </span>
                                            </div>
                                            <div class="ml-4 flex-shrink-0">
                                                <a :href="$route('vendor-contracts.pdf', vendorContract.id)" class="font-medium link" target="_blank">Download</a>
                                            </div>
                                        </li>
                                        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"  v-if="vendorContract.auto_renew_document_path">
                                            <div class="w-0 flex-1 flex items-center">
                                                <!-- Heroicon name: solid/paper-clip -->
                                                <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                                                </svg>
                                                <span class="ml-2 flex-1 w-0 truncate"> 
                                                    <span v-if="vendorContract.auto_renew_document_name">{{ vendorContract.auto_renew_document_name }}</span>
                                                    <span v-else>Auto Renew Document Proof</span>
                                                </span>
                                            </div>
                                            <div class="ml-4 flex-shrink-0">
                                                <a :href="$route('vendor-contracts.auto-renew-pdf', vendorContract.id)" class="font-medium link" target="_blank">Download</a>
                                            </div>
                                        </li>
                                        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"  v-if="vendorContract.auto_renew_mail_receipt_proof_document_path">
                                            <div class="w-0 flex-1 flex items-center">
                                                <!-- Heroicon name: solid/paper-clip -->
                                                <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                                                </svg>
                                                <span class="ml-2 flex-1 w-0 truncate"> 
                                                    <span v-if="vendorContract.auto_renew_mail_receipt_proof_document_name">{{ vendorContract.auto_renew_mail_receipt_proof_document_name }}</span>
                                                    <span v-else>Auto Renew Mail Receipt Proof</span>
                                                </span>
                                            </div>
                                            <div class="ml-4 flex-shrink-0">
                                                <a :href="$route('vendor-contracts.auto-renew-receipt-pdf', vendorContract.id)" class="font-medium link" target="_blank">Download</a>
                                            </div>
                                        </li>
                                    </ul>
                                </dd>
                            </div>                            
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <!-- Services !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="services-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="services-title" class="text-lg leading-6 font-medium text-gray-900">Active Services</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Services on this contract with a status of active or pending activation.</p>
                        </div>

                        <div v-if="activeServices.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('services.create')" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="activeServices.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Id</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Type</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Status</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Effective Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Termination Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Pass Through</th>
	                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Equipment Label</th>
	                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Description</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="service in activeServices.slice(0, 12)" :key="service.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">
                                                    <inertia-link class="link" :href="$route('services.show', [service.id])">{{ service.id }}</inertia-link>
                                                </td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.type }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.status }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ $filters.format_date(service.effective_date) }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.termination_date ? $filters.format_date(service.termination_date) : 'Not Set' }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.pass_through ? 'Yes' : 'No' }}</td>
	                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.label }}</td>
	                                            <td class="py-2 pl-4 pr-3 text-sm sm:pl-6 text-gray-500">{{ service.description }}</td>
                                                
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('services.show', [service.id])" class="link mr-3">
                                                        View<span class="sr-only">, {{ service.id }}</span>
                                                    </inertia-link>

                                                    <inertia-link :href="$route('services.edit', [service.id])" class="link">
                                                        Edit<span class="sr-only">, {{ service.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="dumpster" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Services</h3>
                            <p class="mt-1 text-sm text-gray-500">Create a service.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('services.create')" class="btn btn-orange">
                                    <!-- Heroicon name: solid/plus -->
                                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                    </svg>
                                    New Service
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="activeServices.length">
                        <inertia-link :href="$route('services.index', {vendor_contract: vendorContract.id, statuses: ['Active', 'Pending Activation']})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Vendor Fees !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="vendor-contact-fees-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="vendor-contact-fees-title" class="text-lg leading-6 font-medium text-gray-900">Fees</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Fees on this contract.</p>
                        </div>

                        <div v-if="vendorContract.fees.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('vendor-contracts.vendor-contract-fees.create', [vendorContract.id])" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="vendorContract.fees.length > 0" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Name</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Percentage</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Amount</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Restriction</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="fee in vendorContract.fees" :key="fee.id">
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                    <inertia-link class="link" :href="$route('vendor-contract-fees.edit', [fee.id])">{{ fee.name }}</inertia-link>
                                                </td>
                                                <td :class="{'text-gray-500': !fee.percentage}">{{ fee.percentage ?? 'N/A' }}</td>
                                                <td :class="{'text-gray-500': !fee.amount}">{{ fee.amount ? $filters.format_money(fee.amount) : 'N/A' }}</td>
                                                <td class="">{{ fee.restriction }}</td>
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('vendor-contract-fees.edit', [fee.id])" class="link">
                                                        Edit<span class="sr-only">, {{ fee.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="badge-dollar" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No fees</h3>
                            <p class="mt-1 text-sm text-gray-500">Add a fee to this contract.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('vendor-contracts.vendor-contract-fees.create', [vendorContract.id])" class="btn btn-orange">
                                    <!-- Heroicon name: solid/plus -->
                                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                    </svg>
                                    Add Fee
                                </inertia-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- Locations !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="locations-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="locations-title" class="text-lg leading-6 font-medium text-gray-900">Locations</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Locations serviced by this contract's Vendor</p>
                        </div>

                        <div v-if="locations.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('locations.create')" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="locations.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Name</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Address</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="location in locations.slice(0, 12)" :key="location.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                                    <inertia-link class="link" :href="$route('locations.show', [location.id])">{{ location.name }}</inertia-link>
                                                </td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">{{ location.address.full_address }}</td>
                                                
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('locations.show', [location.id])" class="link mr-2">
                                                        View<span class="sr-only">, {{ location.id }}</span>
                                                    </inertia-link>

                                                    <inertia-link :href="$route('locations.edit', [location.id])" class="link">
                                                        Edit<span class="sr-only">, {{ location.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="map-marker-alt" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No locations</h3>
                            <p class="mt-1 text-sm text-gray-500">Create a location.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('locations.create')" class="btn btn-blue">
                                    <!-- Heroicon name: solid/plus -->
                                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                    </svg>
                                    New Location
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="locations.length">
                        <inertia-link :href="$route('locations.index', {vendor_contract: vendorContract.id, sort_by: 'locations.name', sort_direction: 'asc'})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, onMounted } from 'vue';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';

    // Props
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },
        vendorContract: {
            type: Object,
            required: true
        },
        locations: {
            type: Array,
            required: true
        },
    });

    // State
    const mounted = ref(false);

    // Mount
    onMounted(() => {
        mounted.value = true;
    });

    // Computed
    const activeServices = computed(() => {
        return props.vendorContract.services.filter(service => service.status === 'Active' || service.status === 'Pending Activation');
    });
</script>
